import React, { useState, useEffect, useRef } from "react";

import ChkBox from "components/Custom/ChkBox";
import ExcelGrid from "components/Custom/ExcelGrid";
import Loader from "components/Custom/Loader";

const dataJson = require("../gridData.json");
interface defaultProps {
  childRef: any;
  editRows: any[];
}

const Part1 = (props: defaultProps) => {
  const { childRef, editRows } = props;
  const [healthAndSAfetyCols, setHealthAndSAfetyCols] = useState<any[]>([
    {
      key: "question",
      name: "Question",
      type: "text",
      editable: false,
      width: "65%",
    },
    {
      key: "checkBoxYes",
      name: "YES",
      inputValue: "true",
      type: "radiobutton",
      sortable: true,
      editable: true,
      width: "5%",
      className: "textCenter",
      otherInputs: ["checkBoxNo"],
    },
    {
      key: "checkBoxNo",
      name: "NO",
      inputValue: "true",
      type: "radiobutton",
      sortable: true,
      editable: true,
      width: "5%",
      className: "textCenter",
      otherInputs: ["checkBoxYes"],
    },
    {
      key: "comments",
      name: "Comments",
      type: "text",
      editable: true,
      width: "25%",
    },
  ]);

  const tmpHealthAndSafetyRows = dataJson["1"]["1.1"];
  const tmpCompetenceAndCapabilityRows = dataJson["2"]["2.1"];
  for (let key in tmpHealthAndSafetyRows) {
    tmpHealthAndSafetyRows[key].comments = "";
    tmpHealthAndSafetyRows[key].checkBoxYes = "";
    tmpHealthAndSafetyRows[key].checkBoxNo = "";
  }
  for (let key in tmpCompetenceAndCapabilityRows) {
    tmpCompetenceAndCapabilityRows[key].comments = "";
    tmpCompetenceAndCapabilityRows[key].checkBoxYes = "";
    tmpCompetenceAndCapabilityRows[key].checkBoxNo = "";
  }

  const [healthAndSAfetyRows, setHealthAndSAfetyRows] = useState<any[]>(
    tmpHealthAndSafetyRows
  );
  const [competenceAndCapabilityCols, setCompetenceAndCapabilityCols] =
    useState<any[]>([
      {
        key: "question",
        name: "Question",
        type: "text",
        editable: false,
        width: "65%",
      },
      {
        key: "checkBoxYes",
        name: "YES",
        inputValue: "true",
        type: "radiobutton",
        className: "textCenter",
        sortable: true,
        editable: true,
        width: "5%",
        renderCell: ({
          competenceAndCapabilityRows,
          onRowChange,
          tabIndex,
        }) => {
          return (
            <div className="excelChkWrapper">
              <ChkBox
                checked={competenceAndCapabilityRows.check}
                onChange={(val: any) => {
                  onRowChange({
                    ...competenceAndCapabilityRows,
                    check: !competenceAndCapabilityRows.check,
                  });
                }}
              />
            </div>
          );
        },
      },
      {
        key: "checkBoxNo",
        name: "NO",
        inputValue: "true",
        type: "radiobutton",
        className: "textCenter",
        sortable: true,
        editable: true,
        width: "5%",
        renderCell: ({
          competenceAndCapabilityRows,
          onRowChange,
          tabIndex,
        }) => {
          return (
            <div className="excelChkWrapper">
              <ChkBox
                checked={competenceAndCapabilityRows.check}
                onChange={(val: any) => {
                  onRowChange({
                    ...competenceAndCapabilityRows,
                    check: !competenceAndCapabilityRows.check,
                  });
                }}
              />
            </div>
          );
        },
      },
      {
        key: "comments",
        name: "Comments",
        type: "text",
        editable: true,
        width: "25%",
      },
    ]);
  const [competenceAndCapabilityRows, setCompetenceAndCapabilityRows] =
    useState<any[]>(tmpCompetenceAndCapabilityRows);

  const grid1Ref: any = useRef();
  const grid2Ref: any = useRef();

  useEffect(() => {
    childRef.saveRecord = saveRecord;
    return () => {
      childRef.saveRecord = null;
    };
  }, []);

  const saveRecord = () => {
    const section1 = grid1Ref.getRowData().map((s1) => {
      return {
        id: s1.id,
        checkBoxYes: s1.checkBoxYes,
        checkBoxNo: s1.checkBoxNo,
        comments: s1.comments,
      };
    });
    const section2 = grid2Ref.getRowData().map((s2) => {
      return {
        id: s2.id,
        checkBoxYes: s2.checkBoxYes,
        checkBoxNo: s2.checkBoxNo,
        comments: s2.comments,
      };
    });
    const items = [...section1, ...section2];
    return { items };
  };

  return (
    <>
      <h4 className="ehsHeading1">{dataJson["1"]["heading1"]}</h4>
      <h5 className="ehsHeading2">{dataJson["1"]["heading2"]}</h5>
      {healthAndSAfetyRows ? (
        <ExcelGrid
          childRef={grid1Ref}
          cols={healthAndSAfetyCols}
          rows={healthAndSAfetyRows}
          noTotalRows={true}
        />
      ) : (
        <div className="pageCenter">
          <Loader />
        </div>
      )}
      <h4 className="ehsHeading1">{dataJson["2"]["heading1"]}</h4>
      <h5 className="ehsHeading2">{dataJson["2"]["heading2"]}</h5>
      {competenceAndCapabilityRows ? (
        <ExcelGrid
          childRef={grid2Ref}
          cols={competenceAndCapabilityCols}
          rows={competenceAndCapabilityRows}
          noTotalRows={true}
        />
      ) : (
        <div className="pageCenter">
          <Loader />
        </div>
      )}
    </>
  );
};

export default Part1;
