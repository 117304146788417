import React, { useEffect, useRef, useState } from "react";
import { withSnackbar } from "notistack";
import { IconButton, Tooltip } from "@mui/material";
import {
  Replay as IconReload,
  AddBox as IconAdd,
  Edit as IconEdit,
  Delete as IconDelete,
  Check as IconCheck,
  Close as IconClose,
} from "@mui/icons-material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

import TxtBox from "components/Custom/TxtBox";
import UiDialog from "components/Custom/UiDialog";
import Btn from "components/Custom/Btn";
import SwitchBtn from "components/Custom/SwitchBtn";
import AutoComplete from "components/Custom/AutoComplete";
import ConfirmDialog from "components/Custom/ConfirmDialog";
import Loader from "components/Custom/Loader";

import {
  COLORS,
  CONFIG,
  CONFIRMS,
  ERRORS,
  LABELS,
  NOTIFY,
  SUCCESS,
  WARNINGS,
} from "common/config";
import { ApiLogin } from "apihandlers/login";
import { ApiHome } from "apihandlers/home";
import { COMMON_JS } from "common/scripts";
import ExcelGrid from "components/Custom/ExcelGrid";

const API_HANDLER_HOME = new ApiHome();

let _timer = null;

const UsersPage = (props: any) => {
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState<any>(null);
  const [filteredRows, setFilteredRows] = useState([]);
  const [userDialog, setAddUserDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [organizations, setOrganizations] = useState([]);
  const [editRows, setEditRows] = useState<any[]>(null);

  let userTypes = [];
  for (let key in CONFIG.USER_ROLES) {
    userTypes.push({
      label: key,
      id: CONFIG.USER_ROLES[key],
    });
  }

  useEffect(() => {
    if (loading) {
      getOrgs();
    }
  }, [loading]);

  const getOrgs = () => {
    const args = { dropdown: true };
    API_HANDLER_HOME.getOrganizations(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        setOrganizations(response.records);
        getUsers();
      }
    });
  };

  const getUsers = () => {
    const args = {};
    API_HANDLER_HOME.getUsers(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        for (let i = 0; i < response.records.length; i++) {
          response.records[i]["active"]
            ? (response.records[i]["active"] = "Yes")
            : (response.records[i]["active"] = "No");
        }
        setRows(response.records);
        setLoading(false);
      }
    });
  };

  const onEditUser = (row: any) => {
    setSelectedRow(row);
    setAddUserDialog(true);
  };

  const onDeleteUser = (row: any) => {
    setSelectedRow({ row, delete: true });
  };

  const columns = [
    {
      key: "slNo",
      name: "Sl No.",
      width: "5%",
      type: "number",
      align: "center",
      editable: false,
      sortable: true,
      searchable: true,
      headerAlign: "center",
    },
    {
      key: "firstName",
      name: "First name",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      width: "10%",
    },
    {
      key: "lastName",
      name: "Last name",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      width: "10%",
    },
    {
      key: "userName",
      name: "User name",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      width: "10%",
    },
    {
      key: "emailId",
      name: "Email",
      width: "15%",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "phoneNumber",
      name: "Phone",
      width: "10%",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "userType",
      name: "User Type",
      width: "15%",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "orgName",
      name: "Organization",
      width: "10%",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "active",
      name: "Active",
      type: "dropdown",
      filters: [
        { id: "yes", name: "Yes" },
        { id: "no", name: "No" },
      ],
      editable: false,
      sortable: true,
      searchable: true,
      width: "10%",
      align: "center",
      headerAlign: "center",
      renderCell: (params: any) =>
        params.row.active ? (
          <div className="activeColumn" style={{ height: "100%" }}>
            <Tooltip title={LABELS.ACTIVE} placement="bottom">
              <IconCheck className="green" />
            </Tooltip>
          </div>
        ) : (
          <div className="activeColumn" style={{ height: "100%" }}>
            <Tooltip title={LABELS.INACTIVE} placement="bottom">
              <IconClose className="dim" />
            </Tooltip>
          </div>
        ),
    },
    {
      key: "options",
      name: "Options",
      width: "5%",
      renderCell: (params) => (
        <div className="options">
          <Tooltip title={LABELS.EDIT} placement="bottom">
            <IconButton
              className="iconBtn"
              onClick={() => onEditUser(params.row)}
            >
              <IconEdit />
            </IconButton>
          </Tooltip>
          {/* <Tooltip title={LABELS.DELETE} placement="bottom">
            <IconButton
              className="iconBtn"
              onClick={() => onDeleteUser(params.row)}
            >
              <IconDelete />
            </IconButton>
          </Tooltip> */}
        </div>
      ),
    },
  ];

  const manageUser = () => {
    const firstName = (
      document.getElementById("firstName") as HTMLInputElement
    ).value.trim();
    const lastName = (
      document.getElementById("lastName") as HTMLInputElement
    ).value.trim();
    const userName = (
      document.getElementById("userName") as HTMLInputElement
    ).value.trim();
    const password = (
      document.getElementById("password") as HTMLInputElement
    ).value.trim();
    const emailId = (
      document.getElementById("emailId") as HTMLInputElement
    ).value.trim();
    const phoneNumber = (
      document.getElementById("phoneNumber") as HTMLInputElement
    ).value.trim();
    const orgId = orgRef.current.selectedValue.id;
    const userTypeId = userTypeRef.current.selectedValue.id;
    const active = (document.getElementById("isActive") as HTMLInputElement)
      .checked;

    // Validations
    if (!firstName) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.FIRST_NAME_IS_EMPTY);
      return;
    }
    if (!userName) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.USER_NAME_IS_EMPTY);
      return;
    }
    if (!selectedRow.id) {
      if (!password) {
        COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.PASSWORD_IS_EMPTY);
        return;
      }
    }
    if (!userTypeId) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.USER_TYPE_EMPTY);
      return;
    }
    if (!orgId) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.ORG_EMPTY);
      return;
    }

    const args = {
      userId: selectedRow.id,
      firstName,
      lastName,
      userName,
      password,
      emailId,
      phoneNumber,
      userTypeId,
      active,
      orgId,
    };
    console.log("manageUser: ", args);
    API_HANDLER_HOME.manageUser(args).then((res) => {
      if (res.status === 200) {
        COMMON_JS.showNotify(props, NOTIFY.SUCCESS, SUCCESS.USER_SUCCESS);
        setSelectedRow({});
        setAddUserDialog(false);
        onReload();
      } else {
        COMMON_JS.showNotify(props, NOTIFY.ERROR, res.error);
      }
    });
  };

  const deleteUser = ({ id }) => {
    console.log("deleteUser: ", id);
    setRows(null);
    // getUsers();
  };

  const onReload = () => {
    setRows(null);
    getUsers();
  };

  const onAdd = () => {
    setAddUserDialog(!userDialog);
    setEditRows([]);
    setSelectedRow({});
  };

  const onSearch = (e: any) => {
    const qry = e.target.value.trim().toUpperCase();
    clearTimeout(_timer);
    _timer = setTimeout(() => {
      let flist = rows.filter((r: any) => {
        return r.userName.toUpperCase().indexOf(qry) > -1;
      });
      console.log("flist: ", rows, flist, qry);
      setFilteredRows(flist);
    }, 150);
  };

  const orgRef: any = useRef();
  const userTypeRef: any = useRef();

  console.log("Users: ", loading, selectedRow.active);

  return (
    <React.Fragment>
      <div className="pageInfo">
        <ul className="breadcrumbs">
          <li>{LABELS.HOME}</li>
          <li className="dim">{" / "}</li>
          <li className="active">{LABELS.USERS}</li>
        </ul>
        <h4>{LABELS.USERS}</h4>
        {/* <p>{SUB_TITLES.USERS}</p> */}
      </div>

      {rows ? (
        <ExcelGrid
          id={"users"}
          height={window.innerHeight * 0.6}
          cols={columns}
          rows={rows}
          exportOption={true}
          //newRowOption={false}
          exportName={LABELS.USERS}
          onReload={onReload}
          onAdd={onAdd}
        />
      ) : (
        <div className="pageCenter">
          <Loader />
        </div>
      )}

      {userDialog && (
        <UiDialog
          title={
            selectedRow.id
              ? "Edit User: " + selectedRow.userName
              : "Add New User"
          }
          open={true}
          size={"sm"}
          onClose={() => {
            setAddUserDialog(false);
          }}
        >
          <div className="formGroup">
            <label style={{ color: "red" }}>{LABELS.FIRST_NAME} *</label>
            <TxtBox
              id={"firstName"}
              placeholder={LABELS.FIRST_NAME}
              defaultValue={selectedRow.firstName}
            />
          </div>
          <div className="formGroup">
            <label>{LABELS.LAST_NAME}</label>
            <TxtBox
              id={"lastName"}
              placeholder={LABELS.LAST_NAME}
              defaultValue={selectedRow.lastName}
            />
          </div>
          <div className="formGroup">
            <label style={{ color: "red" }}>{LABELS.USER_NAME} *</label>
            <TxtBox
              id={"userName"}
              placeholder={LABELS.USER_NAME}
              defaultValue={selectedRow.userName}
            />
          </div>
          <div className="formGroup">
            <label style={{ color: "red" }}>{LABELS.PASSWORD} *</label>
            <TxtBox id={"password"} placeholder={LABELS.PASSWORD} />
          </div>
          <div className="formGroup">
            <label>{LABELS.EMAIL_ID}</label>
            <TxtBox
              id={"emailId"}
              placeholder={LABELS.EMAIL_ID}
              defaultValue={selectedRow.emailId}
            />
          </div>
          <div className="formGroup">
            <label>{LABELS.PHONE}</label>
            <TxtBox
              id={"phoneNumber"}
              placeholder={LABELS.PHONE}
              defaultValue={selectedRow.phoneNumber}
            />
          </div>
          <div className="formGroup">
            <label style={{ color: "red" }}>{LABELS.USER_TYPE} *</label>
            <AutoComplete
              id={"userRole"}
              list={userTypes}
              childRef={userTypeRef}
              defaultValue={
                selectedRow.userType
                  ? {
                      id: selectedRow.userTypeId,
                      label: selectedRow.userType,
                    }
                  : null
              }
            />
          </div>
          <div className="formGroup">
            <label style={{ color: "red" }}>{LABELS.ORGANIZATION} *</label>
            <AutoComplete
              id={"organization"}
              childRef={orgRef}
              list={organizations}
              defaultValue={
                selectedRow.orgId
                  ? {
                      id: selectedRow.orgId,
                      label: selectedRow.orgName,
                    }
                  : null
              }
            />
          </div>
          <div className="formGroup">
            <SwitchBtn
              text={LABELS.ACTIVE}
              id={"isActive"}
              checked={selectedRow.id ? selectedRow.active : true}
            />
          </div>
          <div
            className="formGroup"
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                width: "45%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Btn text={LABELS.SAVE} onClick={manageUser} />
              <Btn
                text={LABELS.CANCEL}
                bgColor={COLORS.DIM_DARK_GRAY}
                onClick={() => setAddUserDialog(false)}
              />
            </div>
          </div>
        </UiDialog>
      )}

      {selectedRow.delete && (
        <ConfirmDialog
          title={"Delete User: " + selectedRow.row.userName}
          msg={CONFIRMS.DELETE_USER}
          open={true}
          onClose={() => {
            setSelectedRow({});
          }}
          onOk={() => deleteUser(selectedRow.row)}
        />
      )}
    </React.Fragment>
  );
};

export default withSnackbar(UsersPage);
