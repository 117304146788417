/*
 * Safety Monitoring & Measurement Form
 */

import React, { useRef, useEffect, useState } from "react";
import { withSnackbar } from "notistack";
import { IconButton, Tooltip } from "@mui/material";
import { Edit as IconEdit } from "@mui/icons-material";

import UiDialog from "components/Custom/UiDialog";
import ExcelGrid from "components/Custom/ExcelGrid";
import AutoComplete from "components/Custom/AutoComplete";
import ModalBtmBtns from "components/Custom/ModalBtmBtns";
import Loader from "components/Custom/Loader";
import HGraph from "components/Custom/HGraph";
import Btn from "components/Custom/Btn";
import Part1 from "./sections/Part1";
import Part2 from "./sections/Part2";
import Part3 from "./sections/Part3";
import Part4 from "./sections/Part4";
import Part5 from "./sections/Part5";
import Part6 from "./sections/Part6";
import Part7 from "./sections/Part7";
import Part7_2 from "./sections/Part7_2";
import Part7_3 from "./sections/Part7_3";
import Part7_3_2 from "./sections/Part7_3_2";
import Part7_3_3 from "./sections/Part7_3_3";
import Part7_3_4 from "./sections/Part7_3_4";
import Part7_3_5 from "./sections/Part7_3_5";
import Part7_3_6 from "./sections/Part7_3_6";

import PartLast from "./sections/PartLast";

import { LABELS, NOTIFY, SUCCESS, WARNINGS } from "common/config";
import { ApiHome } from "apihandlers/home";
import { ApiEhs } from "apihandlers/ehs";
import { COMMON_JS } from "common/scripts";
import TxtBox from "components/Custom/TxtBox";

const API_HANDLER_HOME = new ApiHome();
const API_HANDLER_EHS = new ApiEhs();

const OccupationalHealth = (props: any) => {
  const {} = props;
  const mainGridCols = [
    {
      key: "slNo",
      name: "Sl No.",
      type: "number",
      width: "5%",
      editable: false,
      sortable: true,
      align: "center",
      searchable: true,
    },
    {
      key: "contractorNme",
      name: "Contractor Name",
      type: "text",
      width: "40%",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "createdOn",
      name: "Created On",
      type: "text",
      width: "20%",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "createdBy",
      name: "Created By",
      type: "text",
      width: "20%",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "options",
      name: "Options",
      type: "html",
      width: "15%",
      editable: false,
      sortable: false,
      renderCell: ({ row }: any) => (
        <div className="options">
          <Tooltip title={LABELS.EDIT} placement="bottom">
            <IconButton className="iconBtn" onClick={() => onEditRow(row)}>
              <IconEdit />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];
  const [loading, setLoading] = useState<boolean>(true);
  const [contractors, setContractors] = useState<any[]>([]);
  const [cols, setCols] = useState<any[]>(mainGridCols);
  const [rows, setRows] = useState<any[]>(null);
  const [columnChart, setColumnChart] = useState<any>({});
  const [pieChart, setPieChart] = useState<any>({});
  const [manageDialog, setManageDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [editRows, setEditRows] = useState<any[]>([]);
  const [pageNo, setPageNo] = useState(1);
  const [totalPages, setTotalPages] = useState(6);
  const [formData, setFormData] = useState<any>({});
  const [uploads, setUploads] = useState<any[]>([]);

  const projectsRef: any = useRef();
  const part1Ref: any = useRef();
  const part2Ref: any = useRef();
  const part3Ref: any = useRef();
  const part4Ref: any = useRef();
  const part5Ref: any = useRef();
  const partLastRef: any = useRef();
  const part6Ref: any = useRef();
  const part7Ref: any = useRef();
  const Part7_2Ref: any = useRef();
  const Part7_3Ref: any = useRef();
  const Part7_3_2Ref: any = useRef();
  const Part7_3_3Ref: any = useRef();
  const Part7_3_4Ref: any = useRef();
  const Part7_3_5Ref: any = useRef();
  const Part7_3_6Ref: any = useRef();

  useEffect(() => {
    return () => {
      cleanUp();
    };
  }, []);

  useEffect(() => {
    if (loading) {
      getContractors();
    }
  }, [loading]);

  const getContractors = () => {
    const args = { dropdown: true };
    API_HANDLER_EHS.getContractor(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        const list = response.records.map((c: any) => {
          return {
            id: c.id,
            label: c.nameOfContractor,
          };
        });
        setContractors(list);
        getRows();
      }
    });
  };

  const cleanUp = () => {
    setRows(null);
    setLoading(true);
    setManageDialog(false);
    setSelectedRow({});
    console.log("Cleaned up");
  };

  const getRows = () => {
    setRows(null);
    const args = {};
    API_HANDLER_EHS.getOccupationalSafeties(args).then((res: any) => {
      const { status, response } = res;
      if (status === 200) {
        setRows(response.records);
        setColumnChart(response.columnChart);
        setPieChart(response.pieChart);
        setLoading(false);
      }
    });
  };

  const onAdd = () => {
    const projectId = projectsRef.current.selectedValue.id;
    if (!projectId) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        "Please select contractor..!"
      );
      return;
    }
    setManageDialog(!manageDialog);
    setEditRows([]);
    setPageNo(1);
    setSelectedRow({});
    setUploads([]);
  };

  const onEditRow = (row: any) => {
    setPageNo(1);
    setSelectedRow(row);
    setManageDialog(true);
    setEditRows([]);
    getRowInfo(row.id);
  };

  const getRowInfo = (occSafetyId) => {
    const args = {
      occSafetyId,
    };
    setUploads(null);
    API_HANDLER_EHS.getOccupationalSafetyInfo(args).then((res: any) => {
      const { status, response } = res;
      if (status === 200) {
        setUploads(response.files);
      }
    });
  };

  const getProjectInfo = () => {
    const projectId = projectsRef.current.selectedValue.id;
    const args = {
      projectId,
    };
    API_HANDLER_EHS.getWasteManagements(args).then((res: any) => {
      const { status, response } = res;
      if (status === 200) {
        setColumnChart(response.columnChart);
        setPieChart(response.pieChart);
      }
    });
  };

  const manageOccupationalSafety = () => {
    let args: any = updateForm();
    const employerName = (
      document.getElementById("employerName") as HTMLInputElement
    ).value;
    const establishment = (
      document.getElementById("establishment") as HTMLInputElement
    ).value;
    const headquarters = (
      document.getElementById("headquarters") as HTMLInputElement
    ).value;
    const fiscalNo = (document.getElementById("fiscalNo") as HTMLInputElement)
      .value;
    const phone = (document.getElementById("phone") as HTMLInputElement).value;
    const cell = (document.getElementById("cell") as HTMLInputElement).value;
    const email = (document.getElementById("email") as HTMLInputElement).value;
    const fax = (document.getElementById("fax") as HTMLInputElement).value;
    const sector = (document.getElementById("sector") as HTMLInputElement)
      .value;
    const workplaceAddress = (
      document.getElementById("workplaceAddress") as HTMLInputElement
    ).value;

    const menWorkers = (
      document.getElementById("menWorkers") as HTMLInputElement
    ).value;
    const womenWorkers = (
      document.getElementById("womenWorkers") as HTMLInputElement
    ).value;
    const under18Years = (
      document.getElementById("under18Years") as HTMLInputElement
    ).value;
    const above50Years = (
      document.getElementById("above50Years") as HTMLInputElement
    ).value;
    const numOfPregnantWorkers = (
      document.getElementById("numOfPregnantWorkers") as HTMLInputElement
    ).value;
    const numOfUndeclaredWorkers = (
      document.getElementById("numOfUndeclaredWorkers") as HTMLInputElement
    ).value;

    args = {
      ...args,
      occSafetyId: selectedRow.id || -1,
      contractorId: selectedRow.id
        ? selectedRow.contractorId
        : projectsRef.current.selectedValue.id,
      genInformation: {
        employerName,
        establishment,
        headquarters,
        fiscalNo,
        phone,
        cell,
        email,
        fax,
        sector,
      },
      workPlaceInfoDeails: {
        workplaceAddress,
        menWorkers,
        womenWorkers,
        under18Years,
        above50Years,
        numOfPregnantWorkers,
        numOfUndeclaredWorkers,
      },
      files: uploads,
    };

    console.log("manageOccupationalSafety: ", args);

    // Validations
    if (!args.contractorId) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        "Please select contractor..!"
      );
      return;
    }

    setEditRows(null);
    API_HANDLER_EHS.manageOccupationalSafety(args).then((res) => {
      setEditRows([]);
      if (res.status === 200) {
        COMMON_JS.showNotify(props, NOTIFY.SUCCESS, SUCCESS.RECORD_SAVED);
        setSelectedRow({});
        setManageDialog(false);
        getRows();
      } else {
        COMMON_JS.showNotify(props, NOTIFY.ERROR, res.error);
      }
    });
  };

  const updateForm = () => {
    let args: any = {
      files: [],
      occSafetyDetails: [],
      riskAssessmentDetails: [],
      accidentAnalysisDetails: [],
      healthSurvDetails: [],
      consultDetails: [],
      emergencyDetails: [],
      riskAssessmentDetails2: [],
      riskPreventDetails: [],
      riskControlDetails: [],
      physicalDistance: [],
      hygieneDetails: [],
      cleaningDetails: [],
      trainingDetails: [],
      responsMechanism: [],
      ppe_Details: [],
    };
    if (part1Ref.saveRecord) {
      let tmp = part1Ref.saveRecord();
      if (tmp.items) {
        args.occSafetyDetails = Object.values(tmp.items);
      }
    }
    if (part2Ref.saveRecord) {
      let tmp = part2Ref.saveRecord();
      if (tmp.items) {
        args.riskAssessmentDetails = Object.values(tmp.items);
      }
    }
    if (part3Ref.saveRecord) {
      let tmp = part3Ref.saveRecord();
      if (tmp.items) {
        args.accidentAnalysisDetails = Object.values(tmp.items);
      }
    }
    if (part4Ref.saveRecord) {
      let tmp = part4Ref.saveRecord();
      if (tmp.items) {
        args.healthSurvDetails = Object.values(tmp.items);
      }
    }
    if (part5Ref.saveRecord) {
      let tmp = part5Ref.saveRecord();
      if (tmp.items) {
        args.consultDetails = Object.values(tmp.items);
      }
    }
    if (part6Ref.saveRecord) {
      let tmp = part6Ref.saveRecord();
      if (tmp.items) {
        args.emergencyDetails = Object.values(tmp.items);
      }
    }
    if (part7Ref.saveRecord) {
      let tmp = part7Ref.saveRecord();
      if (tmp.items) {
        args.riskAssessmentDetails2 = Object.values(tmp.items);
      }
    }
    if (Part7_2Ref.saveRecord) {
      let tmp = Part7_2Ref.saveRecord();
      if (tmp.items) {
        args.riskPreventDetails = Object.values(tmp.items);
      }
    }
    if (Part7_3Ref.saveRecord) {
      let tmp = Part7_3Ref.saveRecord();
      if (tmp.items) {
        args.physicalDistance = Object.values(tmp.items);
      }
    }
    if (Part7_3_2Ref.saveRecord) {
      let tmp = Part7_3_2Ref.saveRecord();
      if (tmp.items) {
        args.hygieneDetails = Object.values(tmp.items);
      }
    }
    if (Part7_3_3Ref.saveRecord) {
      let tmp = Part7_3_3Ref.saveRecord();
      if (tmp.items) {
        args.cleaningDetails = Object.values(tmp.items);
      }
    }
    if (Part7_3_4Ref.saveRecord) {
      let tmp = Part7_3_4Ref.saveRecord();
      if (tmp.items) {
        args.trainingDetails = Object.values(tmp.items);
      }
    }
    if (Part7_3_5Ref.saveRecord) {
      let tmp = Part7_3_5Ref.saveRecord();
      if (tmp.items) {
        args.responsMechanism = Object.values(tmp.items);
      }
    }
    if (Part7_3_6Ref.saveRecord) {
      let tmp = Part7_3_6Ref.saveRecord();
      if (tmp.items) {
        args.ppe_Details = Object.values(tmp.items);
      }
    }
    if (partLastRef.saveRecord) {
      let tmp = partLastRef.saveRecord();
      if (tmp.files) {
        args.files = tmp.files;
      }
    }
    setFormData(args);
    return args;
  };

  const onReload = () => {
    getRows();
  };

  const onCloseForm = () => {
    setManageDialog(false);
  };

  let workPlaceInfoDeails: any = {};
  let genInformation: any = {};
  let occSafetyDetails: any = [];
  let riskAssessmentDetails: any = [];
  let accidentAnalysisDetails: any = [];
  let healthSurvDetails: any = [];
  let consultDetails: any = [];
  let emergencyDetails: any = [];
  let riskAssessmentDetails2: any = [];
  let riskPreventDetails: any = [];
  let physicalDistance: any = [];
  let hygieneDetails: any = [];
  let cleaningDetails: any = [];
  let trainingDetails: any = [];
  let responsMechanism: any = [];
  let ppe_Details: any = [];

  if (selectedRow.id) {
    if (selectedRow.physicalDistance) {
      physicalDistance = JSON.parse(selectedRow.physicalDistance);
    }
    if (selectedRow.hygieneDetails) {
      hygieneDetails = JSON.parse(selectedRow.hygieneDetails);
    }
    if (selectedRow.cleaningDetails) {
      cleaningDetails = JSON.parse(selectedRow.cleaningDetails);
    }
    if (selectedRow.trainingDetails) {
      trainingDetails = JSON.parse(selectedRow.trainingDetails);
    }
    if (selectedRow.responsMechanism) {
      responsMechanism = JSON.parse(selectedRow.responsMechanism);
    }
    if (selectedRow.ppe_Details) {
      ppe_Details = JSON.parse(selectedRow.ppe_Details);
    }

    if (selectedRow.genInformation) {
      genInformation = JSON.parse(selectedRow.genInformation);
    }
    if (selectedRow.workPlaceInfoDeails) {
      workPlaceInfoDeails = JSON.parse(selectedRow.workPlaceInfoDeails);
    }
    if (selectedRow.occSafetyDetails) {
      occSafetyDetails = JSON.parse(selectedRow.occSafetyDetails);
    }
    if (selectedRow.riskAssessmentDetails) {
      riskAssessmentDetails = JSON.parse(selectedRow.riskAssessmentDetails);
    }
    if (selectedRow.accidentAnalysisDetails) {
      accidentAnalysisDetails = JSON.parse(selectedRow.accidentAnalysisDetails);
    }
    if (selectedRow.healthSurvDetails) {
      healthSurvDetails = JSON.parse(selectedRow.healthSurvDetails);
    }
    if (selectedRow.consultDetails) {
      consultDetails = JSON.parse(selectedRow.consultDetails);
    }
    if (selectedRow.emergencyDetails) {
      emergencyDetails = JSON.parse(selectedRow.emergencyDetails);
    }
    if (selectedRow.riskAssessmentDetails2) {
      riskAssessmentDetails2 = JSON.parse(selectedRow.riskAssessmentDetails2);
    }
    if (selectedRow.riskPreventDetails) {
      riskPreventDetails = JSON.parse(selectedRow.riskPreventDetails);
    }
  }

  console.log("occSafetyDetails:", selectedRow);

  return (
    <React.Fragment>
      <div className="pageInfo">
        <ul className="breadcrumbs">
          <li>{LABELS.HOME}</li>
          <li className="dim">{" / "}</li>
          <li onClick={() => props.setDashMenu(LABELS.HOME)}>{LABELS.EHS}</li>
          <li className="dim">{" / "}</li>
          <li className="active">{LABELS.OCCUPATIONAL_HEALTH}</li>
        </ul>
        <h4>{LABELS.OCCUPATIONAL_HEALTH}</h4>
      </div>
      <div className="formGroup" style={{ width: "100%" }}>
        <label>{LABELS.CONTRACTOR_NAME}</label>
        <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
          <div style={{ width: "30%", marginRight: 10 }}>
            <AutoComplete
              list={contractors}
              childRef={projectsRef}
              defaultValue={
                selectedRow.id
                  ? {
                      id: selectedRow.projectId,
                      label: selectedRow.projectName,
                    }
                  : null
              }
            />
          </div>
          <Btn text={LABELS.LOAD_GRAPH} onClick={getProjectInfo} />
        </div>
      </div>

      {/* Graphs */}
      {pieChart.series && (
        <div className="graphs">
          <HGraph
            width={"50%"}
            type={"column"}
            xAxis={columnChart.xAxis}
            yAxis={columnChart.yAxis}
            title={" "}
            exportTitle={" "}
          />
          <HGraph
            width={"50%"}
            type={"pie"}
            float={"right"}
            series={pieChart.series}
            title={" "}
            exportTitle={" "}
          />
        </div>
      )}

      {/* Master Grid */}
      {rows ? (
        <ExcelGrid
          id={"occupationalHealth"}
          height={window.innerHeight * 0.6}
          cols={cols}
          rows={rows}
          exportOption={true}
          newRowOption={false}
          exportName={LABELS.OCCUPATIONAL_HEALTH}
          onReload={onReload}
          onAdd={onAdd}
        />
      ) : (
        <div className="pageCenter">
          <Loader />
        </div>
      )}

      {/* Form Dialog Box */}
      {manageDialog && (
        <UiDialog
          title={
            selectedRow.id
              ? "Edit " +
                LABELS.OCCUPATIONAL_HEALTH +
                ": " +
                selectedRow.contractorNme
              : "Add New " + LABELS.OCCUPATIONAL_HEALTH
          }
          open={true}
          size={"xl"}
          onClose={() => {
            setManageDialog(false);
            setFormData({});
            setPageNo(1);
          }}
        >
          <>
            <div style={{ width: "100%", flexDirection: "column" }}>
              {/* Employer Info */}
              <div style={{ display: "flex", flexDirection: "column" }}>
                <h4 className="ehsHeading1 borderBottom mB15">
                  {"General information on the employer"}
                </h4>
                <div className="flexRowJustify">
                  <div className="formGroup" style={{ width: "30%" }}>
                    <label>{"Name of the employer"}</label>
                    <TxtBox
                      type={"text"}
                      id="employerName"
                      defaultValue={
                        selectedRow.id ? genInformation.employerName : ""
                      }
                    />
                  </div>
                  <div className="formGroup" style={{ width: "30%" }}>
                    <label>{"Establishment"}</label>
                    <TxtBox
                      type={"text"}
                      id="establishment"
                      defaultValue={
                        selectedRow.id ? genInformation.establishment : ""
                      }
                    />
                  </div>
                  <div className="formGroup" style={{ width: "30%" }}>
                    <label>{"Headquarters"}</label>
                    <TxtBox
                      type={"text"}
                      id="headquarters"
                      defaultValue={
                        selectedRow.id ? genInformation.headquarters : ""
                      }
                    />
                  </div>
                </div>

                <div className="flexRowJustify">
                  <div className="formGroup" style={{ width: "30%" }}>
                    <label>{"Fiscal No."}</label>
                    <TxtBox
                      type={"text"}
                      id="fiscalNo"
                      defaultValue={
                        selectedRow.id ? genInformation.fiscalNo : ""
                      }
                    />
                  </div>
                  <div className="formGroup" style={{ width: "30%" }}>
                    <label>{"Phone"}</label>
                    <TxtBox
                      type={"text"}
                      id="phone"
                      defaultValue={selectedRow.id ? genInformation.phone : ""}
                    />
                  </div>
                  <div className="formGroup" style={{ width: "30%" }}>
                    <label>{"Cell"}</label>
                    <TxtBox
                      type={"text"}
                      id="cell"
                      defaultValue={selectedRow.id ? genInformation.cell : ""}
                    />
                  </div>
                </div>

                <div className="flexRowJustify">
                  <div className="formGroup" style={{ width: "30%" }}>
                    <label>{"E-mail"}</label>
                    <TxtBox
                      type={"text"}
                      id="email"
                      defaultValue={selectedRow.id ? genInformation.email : ""}
                    />
                  </div>
                  <div className="formGroup" style={{ width: "30%" }}>
                    <label>{"Fax"}</label>
                    <TxtBox
                      type={"text"}
                      id="fax"
                      defaultValue={selectedRow.id ? genInformation.fax : ""}
                    />
                  </div>
                  <div className="formGroup" style={{ width: "30%" }}>
                    <label>{"Sector of activity"}</label>
                    <TxtBox
                      type={"text"}
                      id="sector"
                      defaultValue={selectedRow.id ? genInformation.sector : ""}
                    />
                  </div>
                </div>
              </div>

              {/* Workplace Info */}
              <div style={{ display: "flex", flexDirection: "column" }}>
                <h4 className="ehsHeading1 borderBottom">
                  {"General information on the workplace"}
                </h4>

                <div className="flexRowJustify">
                  <div className="formGroup" style={{ width: "100%" }}>
                    <label>{"Workplace address"}</label>
                    <TxtBox
                      type={"text"}
                      id="workplaceAddress"
                      defaultValue={
                        selectedRow.id
                          ? workPlaceInfoDeails.workplaceAddress
                          : ""
                      }
                    />
                  </div>
                </div>

                <div className="flexRowJustify">
                  <div className="formGroup" style={{ width: "30%" }}>
                    <label>{"Total number of workers:"}</label>
                    <div className="flexRow">
                      <div
                        className="formGroup"
                        style={{ width: "30%", marginRight: 15 }}
                      >
                        <TxtBox
                          type={"text"}
                          id="menWorkers"
                          placeholder={"Men"}
                          defaultValue={
                            selectedRow.id ? workPlaceInfoDeails.menWorkers : ""
                          }
                        />
                      </div>
                      <div className="formGroup" style={{ width: "30%" }}>
                        <TxtBox
                          placeholder={"Women"}
                          type={"text"}
                          id="womenWorkers"
                          defaultValue={
                            selectedRow.id
                              ? workPlaceInfoDeails.womenWorkers
                              : ""
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="formGroup" style={{ width: "30%" }}>
                    <label>{"No. of workers under 18 years old"}</label>
                    <TxtBox
                      type={"text"}
                      id="under18Years"
                      defaultValue={
                        selectedRow.id ? workPlaceInfoDeails.under18Years : ""
                      }
                    />
                  </div>
                  <div className="formGroup" style={{ width: "30%" }}>
                    <label>{"No. of workers over 50 years old"}</label>
                    <TxtBox
                      type={"text"}
                      id="above50Years"
                      defaultValue={
                        selectedRow.id ? workPlaceInfoDeails.above50Years : ""
                      }
                    />
                  </div>
                </div>

                <div className="flexRowJustify">
                  <div className="formGroup" style={{ width: "30%" }}>
                    <label>
                      {
                        "No. of pregnant workers and workers who have recently given birth or are breastfeeding"
                      }
                    </label>
                    <TxtBox
                      type={"text"}
                      id="numOfPregnantWorkers"
                      defaultValue={
                        selectedRow.id
                          ? workPlaceInfoDeails.numOfPregnantWorkers
                          : ""
                      }
                    />
                  </div>
                  <div className="formGroup" style={{ width: "30%" }}>
                    <label>{"No. of undeclared workers"}</label>
                    <TxtBox
                      type={"text"}
                      id="numOfUndeclaredWorkers"
                      defaultValue={
                        selectedRow.id
                          ? workPlaceInfoDeails.numOfUndeclaredWorkers
                          : ""
                      }
                    />
                  </div>
                  <div className="formGroup" style={{ width: "30%" }}></div>
                </div>
              </div>

              <Part1 childRef={part1Ref} editRows={occSafetyDetails} />
              <Part2 childRef={part2Ref} editRows={riskAssessmentDetails} />
              <Part3 childRef={part3Ref} editRows={accidentAnalysisDetails} />
              <Part4 childRef={part4Ref} editRows={healthSurvDetails} />
              <Part5 childRef={part5Ref} editRows={consultDetails} />
              <Part6 childRef={part6Ref} editRows={emergencyDetails} />
              <Part7 childRef={part7Ref} editRows={riskAssessmentDetails2} />
              <Part7_2 childRef={Part7_2Ref} editRows={riskPreventDetails} />
              <Part7_3 childRef={Part7_3Ref} editRows={physicalDistance} />
              <Part7_3_2 childRef={Part7_3_2Ref} editRows={hygieneDetails} />
              <Part7_3_3 childRef={Part7_3_3Ref} editRows={cleaningDetails} />
              <Part7_3_4 childRef={Part7_3_4Ref} editRows={trainingDetails} />
              <Part7_3_5 childRef={Part7_3_5Ref} editRows={responsMechanism} />
              <Part7_3_6 childRef={Part7_3_6Ref} editRows={ppe_Details} />

              {uploads ? (
                <PartLast
                  childRef={partLastRef}
                  selectedRow={selectedRow}
                  uploads={uploads}
                  setUploads={(val) => {
                    setUploads(val);
                  }}
                />
              ) : (
                <div>
                  <Loader />
                </div>
              )}

              <ModalBtmBtns
                onSave={manageOccupationalSafety}
                onCancel={onCloseForm}
                disabled={editRows === null}
              />
            </div>
          </>
        </UiDialog>
      )}
    </React.Fragment>
  );
};

export default withSnackbar(OccupationalHealth);
