import { Radio } from "@mui/material";
import React from "react";
import "../styles.css";

const Row = ({ data }) => {
  console.log("row1: ", data);
  return (
    <>
      <tr>
        <td>{"4.1"}</td>
        <td>{"Organizational Context"}</td>
        <td>{"1"}</td>
        <td className="htmlText">
          {
            "Has your organization determined external and internal issues relevant to its purpose and its strategic direction that affect its ability to achieve the intended result(s) of its quality management system?"
          }
        </td>
        <td>
          <p>
            {
              "Sources of evidence could come from SWOT or PESTLE analysis results, business strategy plans; quality plans; information provided on your organization’s website; annual reports; management meeting minutes; documented procedure; and lists of external and internal issues and conditions."
            }
          </p>
          <p>
            {
              "Records of meetings where context is routinely discussed and monitored, e.g. as part of the structured management review process or within each of the respective function of the organization (Purchase, HR, Engineering, Sales, Finance etc.)."
            }
          </p>
          <p>
            {
              "Interviews with relevant top management in relation to the organization’s context and its strategic direction are also a good source of compliance evidence, such as: individual strategy or tactical plan documents written to underpin the organization’s policies and provide a road map for achieving future goals."
            }
          </p>
        </td>
        <td>
          <div className="radio-button">
            <input
              type="radio"
              id="radio5"
              name="1.1"
              value={"Conforms"}
              defaultChecked={data["1"] === "Conforms"}
            />
            <label htmlFor="radio5">Conforms</label>
            <input
              type="radio"
              id="radio6"
              name="1.1"
              value={"Minor NC"}
              defaultChecked={data["1"] === "Minor NC"}
            />
            <label htmlFor="radio6">Minor NC</label>
            <input
              type="radio"
              id="radio7"
              name="1.1"
              value={"Major NC"}
              defaultChecked={data["1"] === "Major NC"}
            />
            <label htmlFor="radio7">Major NC</label>
            <input
              type="radio"
              id="radio8"
              name="1.1"
              value={"OFI"}
              defaultChecked={data["1"] === "OFI"}
            />
            <label htmlFor="radio8">OFI</label>
          </div>
        </td>
        <td>
          <textarea id="eve2" defaultValue={data["27"]} rows={21}></textarea>
        </td>
        <td>
          <textarea id="opp2" defaultValue={data["51"]} rows={21}></textarea>
        </td>
      </tr>
      <tr>
        <td>{"4.1"}</td>
        <td>{"Organizational Context"}</td>
        <td>{"2"}</td>
        <td className="htmlText">
          {
            "Does your organization monitor and review information about these external and internal issues?"
          }
        </td>
        <td>
          <body>
            <h2>External issues, examples could include:</h2>
            <ol>
              <li>
                Reports relating to the your organization's competitive
                environment, new technologies, new markets, customer
                expectations, supplier intelligence, economic conditions,
                political considerations, investment opportunities, social
                factors;
              </li>
              <li>
                Identification of factors relating to changing legislation and
                regulation;
              </li>
              <li>
                Feedback relating to product/service performance and lessons
                learned;
              </li>
              <li>
                Register of identified external risks and their treatment.
              </li>
            </ol>

            <h2>Internal issues, examples could include:</h2>
            <ol>
              <li>
                Organizational structure, identification of
                roles/responsibilities and governance arrangements;
              </li>
              <li>
                Reports on how well the organization is performing, statements
                relating to mission, vision and core values;
              </li>
              <li>Feedback obtained from employees, e.g. survey results;</li>
              <li>
                Information and processes for capturing and sharing knowledge
                and lessons learned;
              </li>
              <li>
                Organizational capability studies: load/capacity, resource
                requirements to achieve demand;
              </li>
              <li>
                Register of identified internal risks and their treatment.
              </li>
            </ol>
          </body>
        </td>
        <td>
          <div className="radio-button">
            <input
              type="radio"
              id="radio1"
              name="1.2"
              value={"Conforms"}
              defaultChecked={data["2"] === "Conforms"}
            />
            <label htmlFor="radio1">Conforms</label>
            <input
              type="radio"
              id="radio2"
              name="1.2"
              value={"Minor NC"}
              defaultChecked={data["2"] === "Minor NC"}
            />
            <label htmlFor="radio2">Minor NC</label>
            <input
              type="radio"
              id="radio3"
              name="1.2"
              value={"Major NC"}
              defaultChecked={data["2"] === "Major NC"}
            />
            <label htmlFor="radio3">Major NC</label>
            <input
              type="radio"
              id="radio4"
              name="1.2"
              value={"OFI"}
              defaultChecked={data["2"] === "OFI"}
            />
            <label htmlFor="radio4">OFI</label>
          </div>
        </td>
        <td>
          <textarea id="eve3" defaultValue={data["28"]} rows={28}></textarea>
        </td>
        <td>
          <textarea id="opp3" defaultValue={data["52"]} rows={28}></textarea>
        </td>
      </tr>
      <tr>
        <td>{"4.2"}</td>
        <td>{"Relevant Interested Parties"}</td>
        <td>{"3"}</td>
        <td className="htmlText">
          {
            "Does your organization determine the interested parties that are relevant to the quality management system?"
          }
        </td>
        <td>
          {
            "Examples of interested parties include: customers, partners, end users, external providers, owners, shareholders, employees, trade unions, government agencies, regulatory authorities, and the local community."
          }
        </td>
        <td>
          <div className="radio-button">
            <input
              type="radio"
              id="radio9"
              name="1.3"
              value={"Conforms"}
              defaultChecked={data["3"] === "Conforms"}
            />
            <label htmlFor="radio9">Conforms</label>
            <input
              type="radio"
              id="radio10"
              name="1.3"
              value={"Minor NC"}
              defaultChecked={data["3"] === "Minor NC"}
            />
            <label htmlFor="radio10">Minor NC</label>
            <input
              type="radio"
              id="radio11"
              name="1.3"
              value={"Major NC"}
              defaultChecked={data["3"] === "Major NC"}
            />
            <label htmlFor="radio11">Major NC</label>
            <input
              type="radio"
              id="radio12"
              name="1.3"
              value={"OFI"}
              defaultChecked={data["3"] === "OFI"}
            />
            <label htmlFor="radio12">OFI</label>
          </div>
        </td>
        <td>
          <textarea id="eve4" defaultValue={data["29"]} rows={10}></textarea>
        </td>
        <td>
          <textarea id="opp4" defaultValue={data["53"]} rows={10}></textarea>
        </td>
      </tr>
      <tr>
        <td>{"4.2"}</td>
        <td>{"Relevant Interested Parties"}</td>
        <td>{"4"}</td>
        <td className="htmlText">
          {
            "Does your organization determine the requirements of these interested parties that are relevant to the quality management system?"
          }
        </td>
        <td>
          {
            "Include those parties that add direct value to your organisation, or who are affected by your organisation's the activities. Use of surveys, networking, face-to-face meetings, association membership, attending conferences, lobbying, participation in benchmarking, etc., in order to gain stakeholder information and their requirements. "
          }
        </td>
        <td>
          <div className="radio-button">
            <input
              type="radio"
              id="radio13"
              name="1.4"
              value={"Conforms"}
              defaultChecked={data["4"] === "Conforms"}
            />
            <label htmlFor="radio13">Conforms</label>
            <input
              type="radio"
              id="radio14"
              name="1.4"
              value={"Minor NC"}
              defaultChecked={data["4"] === "Minor NC"}
            />
            <label htmlFor="radio14">Minor NC</label>
            <input
              type="radio"
              id="radio15"
              name="1.4"
              value={"Major NC"}
              defaultChecked={data["4"] === "Major NC"}
            />
            <label htmlFor="radio15">Major NC</label>
            <input
              type="radio"
              id="radio16"
              name="1.4"
              value={"OFI"}
              defaultChecked={data["4"] === "OFI"}
            />
            <label htmlFor="radio16">OFI</label>
          </div>
        </td>
        <td>
          <textarea id="eve5" defaultValue={data["30"]} rows={10}></textarea>
        </td>
        <td>
          <textarea id="opp5" defaultValue={data["54"]} rows={10}></textarea>
        </td>
      </tr>
      <tr>
        <td>{"4.2"}</td>
        <td>{"Relevant Interested Parties"}</td>
        <td>{"5"}</td>
        <td className="htmlText">
          {
            "Does your organization monitor and review information about these interested parties and their relevant requirements?"
          }
        </td>
        <td>
          {
            "Records of meetings where interested parties and their requirements are routinely discussed and monitored, e.g. as part of the structured management review process, or within each of the respective function of the organization (Purchase, HR, Engineering, Sales, and Finance etc.)."
          }
        </td>
        <td>
          <div className="radio-button">
            <input
              type="radio"
              id="radio17"
              name="1.5"
              value={"Conforms"}
              defaultChecked={data["5"] === "Conforms"}
            />
            <label htmlFor="radio17">Conforms</label>

            <input
              type="radio"
              id="radio18"
              name="1.5"
              value={"Minor NC"}
              defaultChecked={data["5"] === "Minor NC"}
            />
            <label htmlFor="radio18">Minor NC</label>

            <input
              type="radio"
              id="radio19"
              name="1.5"
              value={"Major NC"}
              defaultChecked={data["5"] === "Major NC"}
            />
            <label htmlFor="radio19">Major NC</label>

            <input
              type="radio"
              id="radio20"
              name="1.5"
              value={"OFI"}
              defaultChecked={data["5"] === "OFI"}
            />
            <label htmlFor="radio20">OFI</label>
          </div>
        </td>
        <td>
          <textarea id="eve6" defaultValue={data["31"]} rows={10}></textarea>
        </td>
        <td>
          <textarea id="opp6" defaultValue={data["55"]} rows={10}></textarea>
        </td>
      </tr>
    </>
  );
};

export default Row;
