import React, { useEffect, useRef, useState } from "react";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { withSnackbar } from "notistack";
import { IconButton, Tooltip } from "@mui/material";
import {
  Replay as IconReload,
  AddBox as IconAdd,
  Edit as IconEdit,
  Check as IconCheck,
  Close as IconClose,
  Download as IconDownload,
} from "@mui/icons-material";

import TxtBox from "components/Custom/TxtBox";
import UiDialog from "components/Custom/UiDialog";
import RadioBtn from "components/Custom/RadioBtn";
import Btn from "components/Custom/Btn";
import SwitchBtn from "components/Custom/SwitchBtn";
import AutoComplete from "components/Custom/AutoComplete";
import ConfirmDialog from "components/Custom/ConfirmDialog";
import Loader from "components/Custom/Loader";
import UsersSkeleton from "./UsersSkeleton";

import "./styles/file.css";

import {
  COLORS,
  CONFIG,
  CONFIRMS,
  DEFAULTS,
  ERRORS,
  FORMATS,
  LABELS,
  NOTIFY,
  SUB_TITLES,
  SUCCESS,
  WARNINGS,
} from "common/config";
import { ApiLogin } from "apihandlers/login";
import { ApiHome } from "apihandlers/home";
import { COMMON_JS } from "common/scripts";
import { DateFunctions } from "common/datefunctions";

const API_HANDLER_HOME = new ApiHome();

let _timer = null;

const ProjectHistory = (props: any) => {
  const [loading, setLoading] = useState(true);
  const [uploading, setUploading] = useState(false);
  const [orgs, setOrgs] = useState([]);
  const [projects, setProjects] = useState([]);
  const [rows, setRows] = useState<any>([]);
  const [manageDialog, setManageDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [apiArgs, setApiArgs] = useState<any>({});
  const [gridType, setGridType] = useState("file");
  const [filteredRows, setFilteredRows] = useState([]);
  const [cols, setColumns] = useState([]);

  useEffect(() => {
    if (loading) {
      getProjects();
    }
  }, [loading]);

  const getProjects = () => {
    const args = { dropdown: true };
    API_HANDLER_HOME.getProjects(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        setProjects(response.records);
        setLoading(false);
      }
    });
  };

  const getProjectHistory = () => {
    const projectId = projectRef.current.selectedValue.id;
    if (!projectId) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        "Please select the project..!"
      );
      return;
    }
    const args = {
      projectId,
      fdt: (document.getElementById("uploadFDt") as HTMLInputElement).value,
      tdt: (document.getElementById("uploadTDt") as HTMLInputElement).value,
    };
    setRows(null);
    API_HANDLER_HOME.getProjectHistory(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list: any = response.records.map((r: any, i: number) => {
          r.slNo = i + 1;
          r.id = i + 1;
          return r;
        });
        let c = columns;
        setColumns(c);
        setRows(list);
        setLoading(false);
      }
    });
  };

  const onEditRow = (row: any) => {
    setSelectedRow(row);
    setManageDialog(true);
  };

  const onDeleteRow = (row: any) => {
    setSelectedRow({ row, delete: true });
  };

  const columns: GridColDef[] = [
    {
      field: "slNo",
      headerName: "Sl No.",
      width: 100,
      type: "number",
      align: "center",
    },
    { field: "upload_dt", headerName: "Upload Date", width: 350 },
    {
      field: "options",
      headerName: "Options",
      width: 300,
      renderCell: (params: any) => (
        <div className="options">
          <Tooltip title={LABELS.VIEW} placement="bottom">
            <IconButton
              className="iconBtn"
              onClick={() => downloadHistoryFile(params.row.upload_dt)}
            >
              <IconDownload />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  const downloadHistoryFile = (dt = "") => {
    const projectId = projectRef.current.selectedValue.id;
    if (!projectId) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        "Please select the project..!"
      );
      return;
    }
    const args = {
      projectId,
      dt,
    };
    API_HANDLER_HOME.downloadHistory(args).then((res: any) => {
      setLoading(false);
      let csvContent = "data:text/csv;charset=utf-8," + res;
      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute(
        "download",
        "HiSTORY-" + projectRef.current.selectedValue.label + ".csv"
      );
      document.body.appendChild(link);
      link.click();
    });
  };

  const reloadGrid = () => {
    setRows(null);
    setTimeout(() => {
      getProjectHistory();
    }, 2000);
  };

  const onSearch = (e: any) => {
    const qry = e.target.value.trim().toUpperCase();
    clearTimeout(_timer);
    _timer = setTimeout(() => {
      let flist = rows.filter((r: any) => {
        return r.upload_dt.toUpperCase().indexOf(qry) > -1;
      });
      if (flist.length === 0) {
        flist = null;
      }
      setFilteredRows(flist);
    }, 150);
  };

  const onChangeGridType = (val) => {
    setGridType(val);
  };

  const projectRef: any = useRef();

  return (
    <React.Fragment>
      <div className="pageInfo">
        <ul className="breadcrumbs">
          <li>{LABELS.HOME}</li>
          <li className="dim">{" / "}</li>
          <li className="active">{LABELS.PROJECT_HISTORY}</li>
        </ul>
        <h4>{LABELS.PROJECT_HISTORY}</h4>
      </div>

      <div id="fileUploadForm" style={{ marginTop: 15 }}>
        <div className="formGroup" style={{ width: "60%" }}>
          <label>{LABELS.PROJECT_NAME}</label>
          <AutoComplete id={"projects"} list={projects} childRef={projectRef} />
        </div>
        <div
          style={{
            width: "60%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div className="formGroup" style={{ width: "45%" }}>
            <label>{"From Date:"}</label>
            <TxtBox
              type={"date"}
              id="uploadFDt"
              defaultValue={DateFunctions.getDate(
                "today",
                "from",
                FORMATS.SQL_DATE_ONLY
              )}
            />
          </div>
          <div className="formGroup" style={{ width: "45%" }}>
            <label>{"To Date:"}</label>
            <TxtBox
              type={"date"}
              id="uploadTDt"
              defaultValue={DateFunctions.getDate(
                "today",
                "from",
                FORMATS.SQL_DATE_ONLY
              )}
            />
          </div>
        </div>

        <div
          className="formGroup"
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: 10,
            marginBottom: 15,
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <Btn
              text={LABELS.LOAD}
              onClick={getProjectHistory}
              loading={loading}
            />
          </div>
        </div>
      </div>

      {rows && cols.length > 0 && (
        <div className="gridOption">
          <TxtBox placeholder={LABELS.SEARCH} onKeyPress={onSearch} />
          <IconButton className="iconBtn" onClick={reloadGrid}>
            <IconReload />
          </IconButton>
        </div>
      )}

      {cols.length > 0 && (
        <div
          className="grid"
          style={{
            width: "50%",
            height: "55%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {rows ? (
            <DataGrid
              rows={
                filteredRows
                  ? filteredRows.length > 0
                    ? filteredRows
                    : rows
                  : []
              }
              columns={cols}
              // pageSize={5}
              // rowsPerPageOptions={[5]}
              // pagination={false}
              disableColumnMenu={true}
              disableSelectionOnClick={true}
            />
          ) : (
            <Loader />
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default withSnackbar(ProjectHistory);
