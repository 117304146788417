import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import DataGrid, {
  SortColumn,
  textEditor,
  SelectColumn,
} from "react-data-grid";
import {
  ArrowDropDown as IconAsc,
  ArrowDropUp as IconDesc,
} from "@mui/icons-material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import { Chip } from "@mui/material";

import Btn from "../../../components/Custom/Btn";
import TxtBox from "../../../components/Custom/TxtBox";
import ChkBox from "../../../components/Custom/ChkBox";
import AutoComplete from "../AutoComplete";
import CellExpanderFormatter from "./CellExpanderFormatter";
import UiDialog from "../../../components/Custom/UiDialog";

import { exportToCsv, exportToPdf } from "./export";
import {
  CONFIG,
  DEFAULTS,
  LABELS,
  FORMATS,
  COLORS,
} from "../../../common/config";
import { DateFunctions } from "../../../common/datefunctions";

import "react-data-grid/lib/styles.css";
import "./styles.css";

const ExcelGrid = (props: any) => {
  const {
    childRef,
    cols,
    rows,
    exportOption,
    exportName,
    height,
    noHeight,
    width,
    headerRowHeight,
    onReload,
    onAdd,
    getDefaultNewRow,
    noTotalRows,
    hideUnhide,
    id,
    leftSideWidth,
    leftSideBtns,
    rightSideWidth,
    rightSideBtns,
    isSubGrid,
    needAllRows,
    freezeOption,
    orientation,
    cellNavigation = "",
    secondRowBtns,
  } = props;

  let cachedCols: any = localStorage.getItem(id);
  if (cachedCols) {
    cachedCols = JSON.parse(cachedCols);
  }

  let inputRef: any = useRef<any>({});

  const [selectedCell, setSelectedCell] = useState({ rowIdx: 0, idx: 0 });
  const [showHideUnhide, setShowHideUnhide] = useState<boolean>(false);
  const [filterValue, setFilterValue] = useState<any>("");
  const fJson = {};
  for (let c = 0; c < cols.length; c++) {
    fJson[cols[c]["key"]] = "";
  }
  const [filters, setFilters] = useState<any>(() => fJson);

  // Context is needed to read filter values otherwise columns are
  // re-created when filters are changed and filter loses focus
  const FilterContext = createContext<any>(undefined);

  const inputStopPropagation = (event: any) => {
    if (["ArrowLeft", "ArrowRight"].includes(event.key)) {
      event.stopPropagation();
    }
  };

  const selectStopPropagation = (event: any) => {
    if (
      ["ArrowLeft", "ArrowRight", "ArrowUp", "ArrowDown"].includes(event.key)
    ) {
      event.stopPropagation();
    }
  };

  const generateCols = (data: any[]) => {
    let childGrids = data.filter((d) => d.key === "expanded");
    return data.map((c: any, i: number) => {
      c.headerCellClass = "excelHeader";
      if (c.selectColumn) {
        c = {
          ...SelectColumn,
          ...c,
          headerCellClass: "selectHeaderCell dontprint",
          cellClass: "selectCell dontprint",
        };
      }
      if (c.key === "expanded") {
        c.headerCellClass = "selectHeaderCell dontprint";
        c.cellClass = "detailColumn dontprint";
        c.renderCell = ({ row, tabIndex, onRowChange }) => {
          if (row._type === "subgrid") {
            return row._subGrid();
          }
          return (
            <CellExpanderFormatter
              expanded={row._expanded}
              tabIndex={tabIndex}
              onCellExpand={() => {
                onRowChange({ ...row, _expanded: !row._expanded });
              }}
            />
          );
        };
        c.colSpan = (args) => {
          return args.type === "ROW" && args.row._type === "subgrid"
            ? data.length
            : undefined;
        };
        c.minWidth = 30;
        c.width = 30;
        c.name = "";
      } else if (c.key === "id") {
        c.editable = false;
        c.frozen = true;
        c.sortable = true;
        c.headerCellClass = "excelHeader dontprint";
        c.cellClass = "dontprint";
      } else if (c.key === "options") {
        c.cellClass = "dontprint";
        c.headerCellClass = "excelHeader dontprint";
      } else if (c.type === "text" || c.type === "number") {
        c.renderCell = ({ row, column, tabIndex, onRowChange }) => {
          if (c.isDisabled) {
            if (c.isDisabled(row)) {
              return <div className="dimGray">{row[c.key]}</div>;
            }
          }
          return <div>{row[c.key]}</div>;
        };
        c.renderEditCell = (p) => {
          const { row, column } = p;
          if (column.isDisabled) {
            if (column.isDisabled(row)) {
              return <div className="dimGray">{p.row[p.column.key]}</div>;
            } else {
              return textEditor(p);
            }
          }
          return textEditor(p);
        };
      } else if (c.type === "date") {
        c.renderEditCell = (p: any) => (
          <div className="excelDate">
            <TxtBox
              id={p.row.id}
              type="date"
              defaultValue={p.row[c.key]}
              onChange={(e) => {
                p.onRowChange(
                  {
                    ...p.row,
                    [c.key]: e.target.value,
                  },
                  true
                );
              }}
            />
          </div>
        );
      } else if (c.type === "datetime") {
        c.renderEditCell = (p: any) => (
          <div className="excelDate">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                format={FORMATS.SQL_DATE}
                ampm={false}
                defaultValue={
                  p.row[c.key] ? dayjs(p.row[c.key].replace(" ", "T")) : ""
                }
                onAccept={(v: any) => {
                  const dt = DateFunctions.getFormatedDate(
                    new Date(v.$d),
                    FORMATS.SQL_DATE,
                    "YYYY-MM-DD HH:mm:00"
                  );
                  console.log("dt:", dt);
                  p.onRowChange(
                    {
                      ...p.row,
                      [c.key]: dt,
                    },
                    true
                  );
                }}
              />
            </LocalizationProvider>
            {/* <DatePicker  onChange={(date) => console.log(date)} /> */}
            {/* <TxtBox
              id={p.row.id}
              type="datetime-local"
              defaultValue={p.row[c.key]}
              onFocusOut={(e) => {
                console.log("date:onBlur:", e);
              }}
              // onChange={(e) => {
              //   console.log("date:onChange:", e);
              // }}
              onChange={(e) => {
                p.onRowChange(
                  {
                    ...p.row,
                    [c.key]: e.target.value.replace("T", " "),
                  },
                  true
                );
              }}
            /> */}
          </div>
        );
      } else if (c.type === "image") {
        c.renderCell = ({ row }: any) => {
          return (
            <div
              className="excelPhoto"
              onClick={() => window.open(row.photo, "new")}
            >
              <img width={"50%"} src={row.photo} className={""} />
            </div>
          );
        };
      } else if (c.type === "dropdown") {
        c.renderCell = ({ row, column, tabIndex, onRowChange }) => {
          if (c.isDisabled) {
            if (c.isDisabled(row)) {
              return <div className="dimGray">{row[c.key]}</div>;
            }
          }
          return <div>{row[c.key]}</div>;
        };
        c.renderEditCell = (p: any) => {
          if (p.column.isDisabled) {
            if (p.column.isDisabled(p.row)) {
              return <div className="dimGray">{p.row[p.column.key]}</div>;
            } else {
              return (
                <select
                  autoComplete={"on"}
                  autoFocus={true}
                  className={"excelDropDown"}
                  value={p.row[c.key] || ""}
                  onChange={(e) => {
                    console.log("onChange: ", e);
                    p.onRowChange(
                      {
                        ...p.row,
                        [c.key]: c.list[e.target.selectedIndex - 1].label,
                        [c.key + "Id"]: c.list[e.target.selectedIndex - 1].id,
                      },
                      true
                    );
                  }}
                >
                  <option value="" hidden>
                    Select
                  </option>
                  {c.list.map((d: any) => (
                    <option key={d.id} value={d.id}>
                      {d.label}
                    </option>
                  ))}
                </select>
              );
            }
          } else {
            return (
              <select
                autoComplete={"on"}
                autoFocus={true}
                className={"excelDropDown"}
                value={p.row[c.key] || ""}
                onChange={(e) => {
                  console.log("onChange: ", e);
                  p.onRowChange(
                    {
                      ...p.row,
                      [c.key]: c.list[e.target.selectedIndex - 1].label,
                      [c.key + "Id"]: c.list[e.target.selectedIndex - 1].id,
                    },
                    true
                  );
                }}
              >
                <option value="" hidden>
                  Select
                </option>
                {c.list.map((d: any) => (
                  <option key={d.id} value={d.id}>
                    {d.label}
                  </option>
                ))}
              </select>
            );
          }
        };
      } else if (c.type === "dropdown2") {
        c.renderEditCell = (p) => {
          return (
            <AutoComplete
              id={p.row.id}
              className={"excelGridAutoComplete"}
              list={c.list}
              defaultValue={p.row[c.key]}
              onChange={(e, val) => {
                if (!val) {
                  val = {
                    id: "",
                    label: "",
                  };
                }
                p.onRowChange(
                  {
                    ...p.row,
                    [c.key]: val.label,
                    [c.key + "Id"]: val.id,
                  },
                  true
                );
              }}
            />
          );
        };
      } else if (c.type === "radiobutton") {
        c.renderCell = ({ row, onRowChange, tabIndex }) => {
          return (
            <div className="excelChkWrapper">
              <input
                type="radio"
                name={row.id}
                value={c.inputValue}
                checked={row[c.key] === c.inputValue}
                disabled={c.editable === false}
                color={"red"}
                onChange={(e: any) => {
                  for (let i in data) {
                    if (data[i].type === "radiobutton") {
                      row[data[i].key] = "";
                    }
                  }
                  const rowChange = {
                    ...row,
                    [c.key]: e.target.value,
                  };
                  console.log("rowChange: ", c.key, rowChange);
                  onRowChange(rowChange);
                }}
              />
            </div>
          );
        };
      } else if (c.type === "checkbox") {
        c.renderCell = ({ row, onRowChange, tabIndex }) => {
          return (
            <div className="excelChkWrapper">
              <ChkBox
                checked={row[c.key]}
                onChange={(val: any) => {
                  onRowChange({ ...row, [c.key]: !row[c.key] });
                }}
              />
            </div>
          );
        };
      } else if (c.type === "htmltext") {
        c.renderCell = ({ row, onRowChange, tabIndex }) => {
          return (
            <div
              className="html"
              dangerouslySetInnerHTML={{ __html: row[c.key] }}
            />
          );
        };
      }
      if (c.className) {
        c.headerCellClass += " " + c.className;
      }
      if (c.searchable) {
        c.renderHeaderCell = (p: any) => {
          return (
            <div
              className="flexColumn"
              style={{ width: "100%", position: "relative" }}
            >
              <label
                style={{
                  marginBottom: "-0.9rem",
                  marginTop: "-1.1rem",
                  color: "#fff",
                  cursor: p.column.sortable ? "pointer" : "default",
                }}
                onClick={() => {
                  if (!p.column.sortable) {
                    return;
                  }
                  let sortObj: any = [
                    {
                      columnKey: p.column.key,
                      direction: "ASC",
                    },
                  ];
                  if (sortColumns.length > 0) {
                    if (sortColumns[0].direction === "ASC") {
                      sortObj[0].direction = "DESC";
                    } else {
                      sortObj = [];
                    }
                  }
                  onSortColumnsChange(sortObj);
                }}
              >
                {p.column.name}
              </label>
              {p.column.searchable &&
                (p.column.type === "text" ||
                  p.column.type === "number" ||
                  p.column.type === "date" ||
                  p.column.type === "datetime" ||
                  p.column.type === "dropdown2") && (
                  <input
                    ref={(ref) => (inputRef[p.column.key] = ref)}
                    key={p.column.key}
                    tabIndex={0}
                    autoFocus={p.column.key === filterValue}
                    className={"filterColumn"}
                    defaultValue={filters[p.column.key]}
                    onChange={(e) => {
                      setTimeout(() => {
                        const exgridJson =
                          (
                            document.getElementById(id) as HTMLElement
                          ).getAttribute("data") || JSON.stringify({});
                        const filterJson = {
                          ...JSON.parse(exgridJson),
                          [p.column.key]: e.target.value,
                        };
                        (
                          document.getElementById(id) as HTMLElement
                        ).setAttribute("data", JSON.stringify(filterJson));
                        setFilters(filterJson);
                        setFilterValue(p.column.key);
                      }, 1000);
                    }}
                    onKeyDown={inputStopPropagation}
                  />
                )}
              {p.column.searchable && p.column.type === "dropdown" && (
                // <AutoComplete
                //   id={p.column.key}
                //   className={"filterColumn"}
                //   list={c.list}
                //   defaultValue={filters[p.column.key]}
                //   onChange={(val) => {
                //     if (!val) {
                //       val = {
                //         id: "",
                //         label: "",
                //       };
                //     }
                //     const exgridJson =
                //       (document.getElementById(id) as HTMLElement).getAttribute(
                //         "data"
                //       ) || JSON.stringify({});
                //     const filterJson = {
                //       ...JSON.parse(exgridJson),
                //       [p.column.key]: val,
                //     };
                //     (document.getElementById(id) as HTMLElement).setAttribute(
                //       "data",
                //       JSON.stringify(filterJson)
                //     );
                //     setFilters(filterJson);
                //   }}
                // />
                <select
                  className={""}
                  value={filters[p.column.key] || ""}
                  onChange={(e) => {
                    const exgridJson =
                      (document.getElementById(id) as HTMLElement).getAttribute(
                        "data"
                      ) || JSON.stringify({});
                    const filterJson = {
                      ...JSON.parse(exgridJson),
                      [p.column.key]: e.target.value,
                    };
                    (document.getElementById(id) as HTMLElement).setAttribute(
                      "data",
                      JSON.stringify(filterJson)
                    );
                    setFilters(filterJson);
                  }}
                  onKeyDown={selectStopPropagation}
                >
                  <option value="">{"All"}</option>
                  {p.column.filters.map((f: any) => {
                    return (
                      <option value={f.id} key={"f-" + f.id}>
                        {f.name || f.label}
                      </option>
                    );
                  })}
                </select>
              )}
              <div className="sortWrapper">
                <>
                  <IconDesc
                    style={{ position: "relative", right: -24, top: -5 }}
                    className={
                      "sortingIcon " +
                      (sortColumns.length > 0 &&
                        sortColumns[0].columnKey === p.column.key &&
                        sortColumns[0].direction === "ASC" &&
                        "active")
                    }
                  />
                  <IconAsc
                    className={
                      "sortingIcon " +
                      (sortColumns.length > 0 &&
                        sortColumns[0].columnKey === p.column.key &&
                        sortColumns[0].direction === "DESC" &&
                        "active")
                    }
                  />
                </>
              </div>
            </div>
          );
        };
      }
      if (childGrids.length > 0 && c.totalRowsSummary) {
        c.renderSummaryCell = ({ row }) => {
          return <strong>{"Total: " + row["totalRows"] + " records"}</strong>;
        };
      } else if (i === 1 && childGrids.length === 0) {
        c.renderSummaryCell = ({ row }) => {
          return <strong>{"Total: " + row["totalRows"] + " records"}</strong>;
        };
      } else if (c.renderSum) {
        c.renderSummaryCell = ({ row }) => {
          return <strong>{row[c.key]}</strong>;
        };
      }
      return c;
    });
  };

  // todo: hide unhide onhold for now
  // const [gridCols, setGridCols] = useState<any>(
  //   generateCols(cachedCols ? cachedCols : cols)
  // );
  const [gridCols, setGridCols] = useState<any>(generateCols(cols));
  let colJson = {};
  for (let i in gridCols) {
    colJson[gridCols[i].key] = gridCols[i].type;
  }

  const [gridRows, setGridRows] = useState<any>(rows);
  const [rowHeight, setRowHeight] = useState(props.rowHeight || 50);
  const [sortColumns, setSortColumns] = useState<readonly SortColumn[]>([]);
  const onSortColumnsChange = useCallback((sortColumns: SortColumn[]) => {
    setSortColumns(sortColumns.slice(-1));
  }, []);
  const [selectedRows, setSelectedRows] = useState<any>(
    (): ReadonlySet<string> => new Set()
  );

  const filteredRows = useMemo(() => {
    let exgridJson: any = JSON.stringify({});
    const ele = document.getElementById(id) as HTMLElement;
    if (ele) {
      exgridJson = ele.getAttribute("data");
    }
    exgridJson = JSON.parse(exgridJson);
    return (
      gridRows &&
      gridRows.filter((r) => {
        let rslt = true;
        for (let key in exgridJson) {
          // if (key == "enabled") {
          //   continue;
          // }
          if (!r[key]) {
            r[key] = "";
          }
          /*
           * >
           * >=
           * <
           * <=
           * -
           */
          let filterValue: any = exgridJson[key];
          const isGreater = filterValue.indexOf(">") > -1;
          const isGreaterThanEqual = filterValue.indexOf(">=") > -1;
          const isLesser = filterValue.indexOf("<") > -1;
          const isLesserThanEqual = filterValue.indexOf("<=") > -1;
          const isBetween = filterValue.indexOf("-") > -1;
          const hasSpl =
            isGreater ||
            isGreaterThanEqual ||
            isLesser ||
            isLesserThanEqual ||
            isBetween;
          if (colJson[key] === "number" && hasSpl) {
            let cellValue = Number(r[key]);
            if (isNaN(cellValue)) {
              cellValue = 0;
            }
            if (isGreaterThanEqual) {
              let filterNumValue = Number(filterValue.replace(">=", ""));
              if (isNaN(filterNumValue)) {
                filterNumValue = 0;
              }
              rslt = rslt && cellValue >= filterNumValue;
            } else if (isGreater) {
              let filterNumValue = Number(filterValue.replace(">", ""));
              if (isNaN(filterNumValue)) {
                filterNumValue = 0;
              }
              rslt = rslt && cellValue > filterNumValue;
            } else if (isLesserThanEqual) {
              let filterNumValue = Number(filterValue.replace("<=", ""));
              if (isNaN(filterNumValue)) {
                filterNumValue = 0;
              }
              rslt = rslt && cellValue <= filterNumValue;
            } else if (isLesser) {
              let filterNumValue = Number(filterValue.replace("<", ""));
              if (isNaN(filterNumValue)) {
                filterNumValue = 0;
              }
              rslt = rslt && cellValue < filterNumValue;
            } else if (isBetween) {
              const filterValueArr = filterValue.split("-");
              if (filterValueArr.length === 2) {
                let minVal = Number(filterValueArr[0]);
                if (isNaN(minVal)) {
                  minVal = 0;
                }
                let maxVal = Number(filterValueArr[1]);
                if (isNaN(maxVal)) {
                  maxVal = 0;
                }
                rslt = rslt && cellValue >= minVal && cellValue <= maxVal;
              }
            } else {
              let filterNumValue = Number(filterValue);
              rslt = rslt && cellValue === filterNumValue;
            }
          } else {
            rslt =
              rslt &&
              r[key]
                .toString()
                .toUpperCase()
                .includes(exgridJson[key].toString().toUpperCase());
          }
        }
        return rslt;
      })
    );
  }, [gridRows, filters]);

  useEffect(() => {
    return () => {
      // setGridCols(cols);
      // setGridRows(rows);
    };
  }, []);

  type Comparator = (a: any, b: any) => number;

  const getComparator = (sortColumn: string, sortInfo: any): Comparator => {
    switch (colJson[sortColumn]) {
      case "dropdown":
      case "text":
      case "radiobutton":
      case "checkbox":
      case "date":
      case "datetime":
      case "html":
      case "files":
        return (a, b) => {
          return a[sortColumn].localeCompare(b[sortColumn]);
        };
      case "boolean":
        return (a, b) => {
          return a[sortColumn] === b[sortColumn] ? 0 : a[sortColumn] ? 1 : -1;
        };
      case "number":
        return (a, b) => {
          return a[sortColumn] - b[sortColumn];
        };
      default:
        throw new Error(`unsupported sortColumn: "${sortColumn}"`);
    }
  };

  const sortedRows = useMemo((): readonly any[] => {
    if (sortColumns.length === 0) return filteredRows;
    return [...filteredRows].sort((a, b) => {
      for (const sort of sortColumns) {
        const comparator = getComparator(sort.columnKey, sort);
        const compResult = comparator(a, b);
        if (compResult !== 0) {
          return sort.direction === "ASC" ? compResult : -compResult;
        }
      }
      return 0;
    });
  }, [filteredRows, sortColumns]);

  if (childRef) {
    childRef.getRowData = () => {
      if (needAllRows) {
        return sortedRows;
      }
      return sortedRows.filter((s) => s.edited);
    };
    childRef.setData = (rows: any) => {
      setGridRows(rows);
    };
    childRef.deleteRow = (rowId: any) => {
      const fRows = sortedRows.filter((s) => s.id !== rowId);
      setGridRows(fRows);
      return true;
    };
    childRef.getSummary = () => {
      return summaryRows;
    };
    childRef.getSelectedRows = () => {
      const sRows = Array.from(selectedRows);
      return sortedRows.filter((s) => sRows.indexOf(s.id) > -1);
    };
  }

  const summaryRows = useMemo(() => {
    const rslt = [
      {
        id: "total_0",
        totalRows: 0,
      },
    ];
    let totalRows = 0;
    if (!isSubGrid) {
      totalRows = sortedRows.filter((s) => {
        if (s.subGrid) {
          return s._type === "maingrid" ? true : false;
        }
        return true;
      }).length;
      // Other col sum
      for (let c = 0; c < gridCols.length; c++) {
        let cl = gridCols[c];
        if (cl.renderSum) {
          let sum = 0;
          sortedRows.forEach((s) => {
            let val = Number(s[cl.key]);
            if (!isNaN(val)) {
              sum += val;
            }
          });
          let prefix = cl.renderSumPrefix || "";
          rslt[0][cl.key] = prefix + sum.toFixed(2);
        }
      }
    }
    rslt[0]["totalRows"] = totalRows;
    return rslt;
  }, [sortedRows]);

  const onCellClick = ({ rowIdx, idx }, e) => {
    // setSelectedCell({ rowIdx, idx });
  };

  const gridRef: any = useRef(null);
  const GridElement = (props: any) => {
    console.log("selectedCell: ", selectedCell);
    // <FilterContext.Provider value={filters}>
    return (
      <DataGrid
        ref={gridRef}
        rowKeyGetter={(row: any) => {
          // row.amount = Number(row.qty) * Number(row.price);
          return row.id;
        }}
        style={{
          width: width || "100%",
          maxHeight: height ? height : noHeight ? "auto" : 400,
          height: "auto",
          fontSize: "0.8rem",
          fontWeight: "300",
          borderRadius: "0.8rem",
          marginTop: "0.7rem",
        }}
        bottomSummaryRows={noTotalRows || isSubGrid ? [] : summaryRows}
        // headerRowHeight={headerRowHeight}
        rowHeight={(row: any) => {
          if (!isSubGrid) {
            if (row._type === "subgrid") {
              return DEFAULTS.SUB_GRID_ROW_HEIGHT;
            } else {
              return DEFAULTS.GRID_ROW_HEIGHT;
            }
          }
          return DEFAULTS.GRID_ROW_HEIGHT;
        }}
        columns={gridCols}
        rows={sortedRows}
        sortColumns={sortColumns}
        defaultColumnOptions={{
          sortable: true,
          resizable: true,
        }}
        direction={"ltr"}
        summaryRowHeight={30}
        onSortColumnsChange={onSortColumnsChange}
        onRowsChange={(recs: any, { indexes, column }: any) => {
          let row = recs[indexes[0]];
          if (column.formula) {
            let tmpRow = column.formula(row);
            row = tmpRow;
          }
          recs[indexes[0]].edited = true;
          if (row._type === "maingrid") {
            if (row._expanded) {
              recs.splice(indexes[0] + 1, 0, {
                _type: "subgrid",
                _subGrid: row._subGrid,
                id: row.id + 100,
                parentId: row.id,
              });
            } else {
              recs.splice(indexes[0] + 1, 1);
            }
          }
          if (column.formula) {
            if (column.onChangeFormula) {
              column.onChangeFormula();
            }
          }
          setGridRows(recs);
        }}
        onSelectedRowsChange={setSelectedRows}
        selectedRows={selectedRows}
        rowClass={(row, index) => {
          return row._className || undefined;
        }}
        // selected={selectedCell}
        onCellKeyDown={(e, f) => {
          if (f.keyCode === 13) {
            if (cellNavigation === "row") {
              setTimeout(() => {
                if (gridRef.current) {
                  // Scroll to Cell ( Not Focus )
                  // gridRef.current.scrollToCell({
                  //   rowIdx: e.rowIdx,
                  //   colIdx: e.column.idx,
                  // });
                  let nxtRowIndx = e.rowIdx + 1;
                  if (e.rowIdx === sortedRows.length - 1) {
                    // last row
                    nxtRowIndx = e.rowIdx;
                  }
                  gridRef.current.selectCell(
                    {
                      rowIdx: nxtRowIndx,
                      idx: e.column.idx,
                    },
                    false
                  );
                }
              }, 500);
            } else if (cellNavigation === "column") {
              setTimeout(() => {
                if (gridRef.current) {
                  let nxtColIndx = e.column.idx + 1;
                  if (e.column.idx === gridCols.length - 1) {
                    // last column
                    nxtColIndx = e.column.idx;
                  }
                  gridRef.current.selectCell(
                    {
                      rowIdx: e.rowIdx,
                      idx: nxtColIndx,
                    },
                    false
                  );
                }
              }, 500);
            } else {
              // Don't move cell navigation
              setTimeout(() => {
                if (gridRef.current) {
                  gridRef.current.selectCell(
                    {
                      rowIdx: e.rowIdx,
                      idx: e.column.idx,
                    },
                    false
                  );
                }
              }, 500);
            }
          }
        }}
        onCellClick={(args, e) => {
          // console.log("onCellClick: ", gridRef.current);
        }}
        // onCellClick={(args, event) => {
        //   console.log("onCellClick: ", args, event);
        //   if (args.row.disabled) {
        //     return false;
        //     // event.preventGridDefault();
        //     // args.selectCell(true);
        //   }
        // }}
        // renderers={formulaRenderer}
        // onCellSelected = ({ rowIdx, idx }) => {
        //   console.log("onCellSelected:",rowIdx, idx);
        // }
      />
    );
  };

  const insertNewRow = () => {
    const newRow: any = getDefaultNewRow();
    console.log("newRow:", newRow);
    if (newRow) {
      setGridRows([...gridRows, newRow]);
    }
  };

  const onFreeze = (e) => {
    let endIndex = Number(e.target.value);
    const tmpCols = gridCols;
    for (let i = 0; i < tmpCols.length; i++) {
      if (i <= endIndex) {
        tmpCols[i].frozen = true;
      } else {
        delete tmpCols[i].frozen;
      }
    }
    setGridCols([...tmpCols]);
    if (id) {
      localStorage.setItem(id, JSON.stringify(tmpCols));
    }
  };

  const btmRows = noTotalRows || isSubGrid ? [] : summaryRows;

  console.log("selectedRows:", sortedRows, selectedRows);

  return (
    <div className="excelGrid" id={id}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 5,
          marginTop: 10,
          width: "100%",
        }}
      >
        <div
          className="flexRow width100"
          style={{ justifyContent: "space-between" }}
        >
          <div
            className="flexRow"
            style={{
              width: leftSideWidth || "50%",
              alignItems: "center",
              justifyContent: "flex-start",
              flexWrap: "wrap",
            }}
          >
            {freezeOption && cols && (
              <div
                className="flexRow"
                style={{
                  alignItems: "center",
                  marginRight: 15,
                }}
              >
                <label
                  style={{
                    fontSize: "0.8rem",
                    fontWeight: "500",
                    marginLeft: 5,
                  }}
                >
                  {LABELS.FREEZE_COLS + " : "}
                </label>
                <select
                  className="simpleDropdown"
                  style={{ paddingLeft: 5 }}
                  onChange={onFreeze}
                >
                  {gridCols.map((c, i) => {
                    return (
                      <option value={i} selected={c.frozen || false}>
                        {i + 1}
                      </option>
                    );
                  })}
                </select>
              </div>
            )}
            {onAdd && (
              // privilege === "0" &&
              <Btn
                text={LABELS.ADD_NEW}
                marginRight={15}
                onClick={onAdd}
                // bgColor={COLORS.GRID_ADD_NEW}
                // color={COLORS.WHITE}
              />
            )}
            {getDefaultNewRow && (
              //  && privilege === "0"
              <Btn
                text={LABELS.ADD_NEW_ROW}
                onClick={insertNewRow}
                // bgColor={COLORS.GRID_ADD_NEW}
                // color={COLORS.WHITE}
              />
            )}
            {onReload && (
              <Btn
                text={LABELS.RELOAD}
                marginRight={15}
                onClick={onReload}
                // bgColor={COLORS.RELOAD_EXPORT}
                // color={COLORS.RELOAD_EXPORT_TEXT}
              />
            )}
            {/* todo: commented for now */}
            {/* {hideUnhide && (
              <Btn
                text={LABELS.HIDE_UNHIDE}
                marginRight={15}
                onClick={() => setShowHideUnhide(true)}
                bgColor={COLORS.RELOAD_EXPORT}
                color={COLORS.RELOAD_EXPORT_TEXT}
              />
            )} */}
            {!secondRowBtns && leftSideBtns}
          </div>
          <div
            className="flexRow"
            style={{
              width: rightSideWidth || "50%",
              justifyContent: "flex-end",
              flexWrap: "wrap",
              alignItems: "flex-end",
            }}
          >
            {/* {rightSideBtns} */}
            {exportOption && (
              <div className="exportOptions">
                <Btn
                  text={"Export to PDF"}
                  marginRight={15}
                  onClick={() =>
                    exportToPdf(
                      exportName,
                      gridCols,
                      sortedRows,
                      btmRows,
                      orientation
                    )
                  }
                  // bgColor={COLORS.RELOAD_EXPORT}
                  // color={COLORS.RELOAD_EXPORT_TEXT}
                />
                <Btn
                  text={"Export to CSV"}
                  onClick={() =>
                    exportToCsv(exportName, gridCols, sortedRows, btmRows)
                  }
                  // bgColor={COLORS.RELOAD_EXPORT}
                  // color={COLORS.RELOAD_EXPORT_TEXT}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {secondRowBtns && (
        <div
          className="flexRow width100"
          style={{ justifyContent: "space-between" }}
        >
          <div
            className="flexRow"
            style={{
              width: leftSideWidth || "50%",
              alignItems: "center",
              justifyContent: "flex-start",
              flexWrap: "wrap",
            }}
          >
            {freezeOption && cols && (
              <div
                className="flexRow"
                style={{
                  alignItems: "center",
                  marginRight: 15,
                }}
              >
                <label
                  style={{
                    fontSize: "0.8rem",
                    fontWeight: "500",
                    marginLeft: 5,
                  }}
                >
                  {LABELS.FREEZE_COLS + " : "}
                </label>
                <select
                  className="simpleDropdown"
                  style={{ paddingLeft: 5 }}
                  onChange={onFreeze}
                >
                  {gridCols.map((c, i) => {
                    return (
                      <option value={i} selected={c.frozen || false}>
                        {i + 1}
                      </option>
                    );
                  })}
                </select>
              </div>
            )}
            {/* todo: commented for now */}
            {/* {hideUnhide && (
              <Btn
                text={LABELS.HIDE_UNHIDE}
                marginRight={15}
                onClick={() => setShowHideUnhide(true)}
                bgColor={COLORS.RELOAD_EXPORT}
                color={COLORS.RELOAD_EXPORT_TEXT}
              />
            )} */}
            {leftSideBtns}
          </div>
        </div>
      )}

      <GridElement />

      {showHideUnhide && (
        <UiDialog
          open={true}
          title={LABELS.HIDE_UNHIDE}
          draggable={true}
          onClose={() => setShowHideUnhide(false)}
        >
          <div className="hideUnhideCols">
            {cols.map((c: any, i: number) => {
              const filterCol = gridCols.find((g) => g.key === c.key);
              console.log("c.key: ", c.key, filterCol);
              return (
                c.key !== "expanded" && (
                  <Chip
                    label={c.name}
                    variant={!filterCol ? "outlined" : "filled"}
                    onClick={() => {
                      if (!filterCol) {
                        // Add to grid
                        const tmpCol: any = cols.find((t) => t.key === c.key);
                        if (tmpCol) {
                          let tmpColsList: any = [];
                          for (let t = 0; t < cols.length; t++) {
                            if (cols[t].key === c.key) {
                              tmpColsList.push(tmpCol);
                            } else {
                              const isSelected = gridCols.find(
                                (g) => g.key === cols[t].key
                              );
                              if (isSelected) {
                                tmpColsList.push(cols[t]);
                              }
                            }
                          }
                          if (id) {
                            localStorage.setItem(
                              id,
                              JSON.stringify(tmpColsList)
                            );
                          }
                          setGridCols(generateCols(tmpColsList));
                        }
                      } else {
                        // Hide from grid
                        let tmpColsList = [...gridCols];
                        tmpColsList = tmpColsList.filter((d: any) => {
                          return d.key !== c.key;
                        });
                        if (id) {
                          localStorage.setItem(id, JSON.stringify(tmpColsList));
                        }
                        setGridCols(tmpColsList);
                      }
                    }}
                  />
                )
              );
            })}
          </div>
        </UiDialog>
      )}
    </div>
  );
};

export default ExcelGrid;
