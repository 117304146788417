import React, { useState, useEffect, useRef } from "react";

import ChkBox from "components/Custom/ChkBox";
import ExcelGrid from "components/Custom/ExcelGrid";
import Loader from "components/Custom/Loader";
import { FORMATS, LABELS, NOTIFY } from "common/config";
import TxtBox from "components/Custom/TxtBox";
import AutoComplete from "components/Custom/AutoComplete";
import { DateFunctions } from "common/datefunctions";

interface defaultProps {
  childRef: any;
  editRows: any[];
  selectedRow: any;
  orgList: any;
  showNotify: Function;
}

const Part1 = (props: defaultProps) => {
  const { childRef, editRows, orgList, selectedRow, showNotify } = props;

  const orgRef: any = useRef(null);

  useEffect(() => {
    childRef.saveRecord = saveRecord;
    return () => {
      childRef.saveRecord = null;
    };
  }, []);

  const saveRecord = () => {
    const haccpDt = (
      document.getElementById("haccpDt") as HTMLInputElement
    ).value.trim();
    const stepDetails = (
      document.getElementById("stepDetails") as HTMLInputElement
    ).value.trim();
    const hbpcDetails = (
      document.getElementById("hbpcDetails") as HTMLInputElement
    ).value.trim();
    const hazardlikelinessDetails = (
      document.getElementById("hazardlikelinessDetails") as HTMLInputElement
    ).value.trim();
    const basisDetails = (
      document.getElementById("basisDetails") as HTMLInputElement
    ).value.trim();
    const measuresDetails = (
      document.getElementById("measuresDetails") as HTMLInputElement
    ).value.trim();
    const ccpDetails = (
      document.getElementById("ccpDetails") as HTMLInputElement
    ).value.trim();
    const orgId = orgRef.current.selectedValue.id;

    if (ccpDetails === "") {
      showNotify(NOTIFY.WARNING, "Please enter ccp details..!");
      return null;
    }
    if (measuresDetails === "") {
      showNotify(NOTIFY.WARNING, "Please enter mesurement details..!");
      return null;
    }
    if (basisDetails === "") {
      showNotify(NOTIFY.WARNING, "Please enter basic details..!");
      return null;
    }
    if (!orgId) {
      showNotify(NOTIFY.WARNING, "Please select Organization..!");
      return null;
    }
    if (hazardlikelinessDetails === "") {
      showNotify(NOTIFY.WARNING, "Please enter hazard like liness details..!");
      return null;
    }
    if (hbpcDetails === "") {
      showNotify(NOTIFY.WARNING, "Please enter hbpc details..!");
      return null;
    }
    if (stepDetails === "") {
      showNotify(NOTIFY.WARNING, "Please enter processing step..!");
      return null;
    }
    if (haccpDt === "") {
      showNotify(NOTIFY.WARNING, "Please enter haccp date..!");
      return null;
    }

    return {
      haccpDt,
      stepDetails,
      hbpcDetails,
      orgId,
      hazardlikelinessDetails,
      basisDetails,
      measuresDetails,
      ccpDetails,
      // observedCategory,
    };
  };

  return (
    <div>
      <div className="formRow">
        <div className="formGroup" style={{ width: "30%" }}>
          <label className="red">{" Haccp Date *"}</label>
          <TxtBox
            type={"date"}
            id="haccpDt"
            defaultValue={selectedRow.id ? selectedRow.haccpDt : null}
          />
        </div>
        <div className="formGroup" style={{ width: "30%" }}>
          <label className="red">{"Processing Step *"}</label>
          <TxtBox
            type={"text"}
            id="stepDetails"
            defaultValue={selectedRow.id ? selectedRow.stepDetails : ""}
          />
        </div>
        <div className="formGroup" style={{ width: "30%" }}>
          <label className="red">
            {"Hazard Biological (B),Physical(P),or Chemical(C) *"}
          </label>
          <TxtBox
            type={"text"}
            id="hbpcDetails"
            defaultValue={selectedRow.id ? selectedRow.hbpcDetails : ""}
          />
        </div>
      </div>
      <div className="formRow">
        <div className="formGroup" style={{ width: "30%" }}>
          <label className="red">
            {"Is the Hazard Reasonably Likely to Occur *"}
          </label>
          <TxtBox
            type={"text"}
            id="hazardlikelinessDetails"
            defaultValue={
              selectedRow.id ? selectedRow.hazardlikelinessDetails : ""
            }
          />
        </div>
        <div className="formGroup" style={{ width: "30%" }}>
          <label className="red">{"Basis *"}</label>
          <TxtBox
            type={"text"}
            id="basisDetails"
            defaultValue={selectedRow.id ? selectedRow.basisDetails : ""}
          />
        </div>

        <div className="formGroup" style={{ width: "30%" }}>
          <label className="red">{"Organization *"}</label>
          <AutoComplete
            list={orgList}
            childRef={orgRef}
            defaultValue={
              selectedRow.orgId
                ? {
                    id: selectedRow.orgId,
                    label: selectedRow.orgName,
                  }
                : null
            }
          />
        </div>
      </div>

      <div className="formGroup" style={{ width: "60%" }}>
        <label className="red">
          {
            "If yes in column 3, what measures could be applied to prevent, eliminate, or reduce the hazard to an acceptable level *"
          }
        </label>
        <TxtBox
          type={"text"}
          id="measuresDetails"
          defaultValue={selectedRow.id ? selectedRow.measuresDetails : ""}
        />
      </div>

      <div className="formGroup" style={{ width: "60%" }}>
        <label className="red">{"CCP *"}</label>
        <TxtBox
          type={"text"}
          id="ccpDetails"
          rows={3}
          defaultValue={selectedRow.id ? selectedRow.ccpDetails : ""}
        />
      </div>
    </div>
  );
};

export default Part1;
