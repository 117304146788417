/*
 * Safety Monitoring & Measurement Form
 */

import React, { useRef, useEffect, useState } from "react";
import { withSnackbar } from "notistack";
import { IconButton, Tooltip } from "@mui/material";
import { Edit as IconEdit } from "@mui/icons-material";

import UiDialog from "components/Custom/UiDialog";
import ExcelGrid from "components/Custom/ExcelGrid";
import AutoComplete from "components/Custom/AutoComplete";
import SkeletonBox from "components/Custom/SkeletonBox";
import ModalBtmBtns from "components/Custom/ModalBtmBtns";
import Loader from "components/Custom/Loader";
import HGraph from "components/Custom/HGraph";
import PartLast from "./sections/PartLast";

import { LABELS, NOTIFY, SUCCESS, WARNINGS } from "common/config";
import { ApiHome } from "apihandlers/home";
import { ApiEhs } from "apihandlers/ehs";
import { COMMON_JS } from "common/scripts";
import Btn from "components/Custom/Btn";
import TxtBox from "components/Custom/TxtBox";

const API_HANDLER_HOME = new ApiHome();
const API_HANDLER_EHS = new ApiEhs();

const dataJson = require("./gridData.json");

const EmployeeData = (props: any) => {
  const {} = props;
  const mainGridCols = [
    {
      key: "slNo",
      name: "Sl No.",
      type: "number",
      width: "5%",
      editable: false,
      sortable: true,
      align: "center",
      searchable: true,
    },
    {
      key: "empName",
      name: "Employee Name",
      type: "text",
      width: "20%",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "contractorNme",
      name: "Contractor Name",
      type: "text",
      width: "20%",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "trainingNum",
      name: "Training No",
      type: "text",
      width: "20%",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "createdOn",
      name: "Created On",
      type: "text",
      width: "20%",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "createdBy",
      name: "Created By",
      type: "text",
      width: "10%",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "options",
      name: "Options",
      type: "html",
      width: "5%",
      editable: false,
      sortable: false,
      renderCell: ({ row }: any) => (
        <div className="options">
          <Tooltip title={LABELS.EDIT} placement="bottom">
            <IconButton className="iconBtn" onClick={() => onEditRow(row)}>
              <IconEdit />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];
  const [loading, setLoading] = useState<boolean>(true);
  const [projects, setProjects] = useState<any[]>([]);
  const [cols, setCols] = useState<any[]>(mainGridCols);
  const [rows, setRows] = useState<any[]>(null);
  const [columnChart, setColumnChart] = useState<any>({});
  const [pieChart, setPieChart] = useState<any>({});
  const [manageDialog, setManageDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [editRows, setEditRows] = useState<any[]>(null);
  const [pageNo, setPageNo] = useState(1);
  const [totalPages, setTotalPages] = useState(6);
  const [formData, setFormData] = useState<any>({});
  const [uploads, setUploads] = useState<any[]>([]);
  const [orgList, setOrgList] = useState<any[]>([]);
  const [contractorList, setContractorList] = useState<any[]>([]);
  const [trainingList, setTrainingList] = useState([
    {
      id: "true",
      label: "Yes",
    },
    {
      id: "false",
      label: "No",
    },
  ]);
  const [safepassList, setSafepassList] = useState([
    {
      id: "true",
      label: "Yes",
    },
    {
      id: "false",
      label: "No",
    },
  ]);
  const [safetyList, setSafetyList] = useState([
    {
      id: "true",
      label: "Yes",
    },
    {
      id: "false",
      label: "No",
    },
  ]);
  const [statusList, setStatusList] = useState([
    {
      id: "true",
      label: "Active",
    },
    {
      id: "false",
      label: "InActive",
    },
  ]);
  const [insurenceList, setinsurenceList] = useState([
    {
      id: "true",
      label: "Active",
    },
    {
      id: "false",
      label: "InActive",
    },
  ]);
  const [safetyTrainList, setSafetyTrainList] = useState([
    {
      id: "true",
      label: "Active",
    },
    {
      id: "false",
      label: "InActive",
    },
  ]);

  const trainingRef: any = useRef();
  const safepassRef: any = useRef();
  const safetyRef: any = useRef();
  const statusRef: any = useRef();
  const safetyTrainRef: any = useRef();
  const insurenceRef: any = useRef();
  const orgRef: any = useRef();
  const contractorRef: any = useRef();
  const projectsRef: any = useRef();
  const part1Ref: any = useRef();
  const part2Ref: any = useRef();
  const part3Ref: any = useRef();
  const part4Ref: any = useRef();
  const part5Ref: any = useRef();
  const part6Ref: any = useRef();

  useEffect(() => {
    return () => {
      cleanUp();
    };
  }, []);

  useEffect(() => {
    if (loading) {
      getContractors();
    }
  }, [loading]);

  const getContractors = () => {
    const args = { dropdown: true };
    API_HANDLER_EHS.getContractor(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        const list = response.records.map((c: any) => {
          return {
            id: c.id,
            label: c.nameOfContractor,
          };
        });
        console.log("formData: list:", list);
        setContractorList(list);
        getRows();
      }
    });
  };

  const getOrgs = () => {
    const args = {};
    API_HANDLER_HOME.getOrganizations(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        setOrgList(
          response.records.map((o: any) => {
            return {
              id: o.id,
              label: o.orgName,
            };
          })
        );
        getRows();
      }
    });
  };

  const getProjects = () => {
    const args = { dropdown: true };
    API_HANDLER_HOME.getProjects(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        setProjects(response.records);
        getRows();
      }
    });
  };

  const cleanUp = () => {
    setRows(null);
    setLoading(true);
    setManageDialog(false);
    setSelectedRow({});
    console.log("Cleaned up");
  };

  const getRows = () => {
    setRows(null);
    const args = {};
    API_HANDLER_EHS.getEmployeeData(args).then((res: any) => {
      const { status, response } = res;
      if (status === 200) {
        setRows(response.records);
        setColumnChart(response.columnChart);
        setPieChart(response.pieChart);
        setLoading(false);
      }
    });
  };

  const onAdd = () => {
    setManageDialog(!manageDialog);
    setEditRows([]);
    setPageNo(1);
    setSelectedRow({});
  };

  const onEditRow = (row: any) => {
    setPageNo(1);
    setSelectedRow(row);
    setManageDialog(true);
    setEditRows(null);
  };

  const getRowInfo = (empDataId) => {
    const args = { empDataId: selectedRow.id || -1 };

    API_HANDLER_EHS.getEmployeeDataInfo(args).then((res: any) => {
      const { status, response } = res;
      if (status === 200) {
        setEditRows(response);
      }
    });
  };

  const getProjectInfo = () => {
    const projectId = projectsRef.current.selectedValue.id;
    if (!projectId) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.PROJECT_EMPTY);
      return;
    }
    const args = {
      projectId,
    };
    API_HANDLER_EHS.getSafetyMonitoring(args).then((res: any) => {
      const { status, response } = res;
      if (status === 200) {
        setColumnChart(response.columnChart);
        setPieChart(response.pieChart);
      }
    });
  };

  const manageEmpData = () => {
    const trainingCompanyName = (
      document.getElementById("trainingCompanyName") as HTMLInputElement
    ).value.trim();
    const contractorCompanyName = (
      document.getElementById("contractorCompanyName") as HTMLInputElement
    ).value.trim();
    const companyName = (
      document.getElementById("companyName") as HTMLInputElement
    ).value.trim();
    const companyRegNum = (
      document.getElementById("companyRegNum") as HTMLInputElement
    ).value.trim();
    const companyLicense = (
      document.getElementById("companyLicense") as HTMLInputElement
    ).value.trim();
    const companyDate = (
      document.getElementById("companyDate") as HTMLInputElement
    ).value.trim();
    const awards = (
      document.getElementById("awards") as HTMLInputElement
    ).value.trim();
    const contractorId = contractorRef.current.selectedValue.id;
    const empName = (
      document.getElementById("empName") as HTMLInputElement
    ).value.trim();
    const isInductionTraining = trainingRef.current.selectedValue.id;
    const isSafePassTraining = safepassRef.current.selectedValue.id;
    const trainingNum = (
      document.getElementById("trainingNum") as HTMLInputElement
    ).value.trim();
    const trainingDt = (
      document.getElementById("trainingDt") as HTMLInputElement
    ).value.trim();
    const safePassTrainingNum = (
      document.getElementById("safePassTrainingNum") as HTMLInputElement
    ).value.trim();
    const safePassTrainingDt = (
      document.getElementById("safePassTrainingDt") as HTMLInputElement
    ).value.trim();
    const safePassValidity = (
      document.getElementById("safePassValidity") as HTMLInputElement
    ).value.trim();
    const statusOfSafePass = statusRef.current.selectedValue.id;
    const insurenceType = (
      document.getElementById("insurenceType") as HTMLInputElement
    ).value.trim();
    const insuranceDt = (
      document.getElementById("insuranceDt") as HTMLInputElement
    ).value.trim();
    const issuedDate = (
      document.getElementById("issuedDate") as HTMLInputElement
    ).value.trim();
    const validityPeriod = (
      document.getElementById("validity") as HTMLInputElement
    ).value.trim();
    const insuranceExpiry = (
      document.getElementById("insuranceExpiry") as HTMLInputElement
    ).value.trim();
    const insuranceValue = (
      document.getElementById("insuranceValue") as HTMLInputElement
    ).value.trim();
    const currency = currencyRef.current.selectedValue.id;
    const insuranceComp = (
      document.getElementById("insuranceComp") as HTMLInputElement
    ).value.trim();
    const statusOfInsurance = insurenceRef.current.selectedValue.id;
    const isSafetyTraining = safetyRef.current.selectedValue.id;
    const safetyTrainingNum = (
      document.getElementById("safetyTrainingNum") as HTMLInputElement
    ).value.trim();
    const safetyTrainingDt = (
      document.getElementById("safetyTrainingDt") as HTMLInputElement
    ).value.trim();
    const safetyTrainingValidity = (
      document.getElementById("safetyTrainingValidity") as HTMLInputElement
    ).value.trim();
    const statusOfsafetyTraining = safetyTrainRef.current.selectedValue.id;

    // if (empName === "") {
    //   COMMON_JS.showNotify(
    //     props,
    //     NOTIFY.WARNING,
    //     "Please enter employee name..!"
    //   );
    //   return null;
    // }
    // if (!contractorId) {
    //   COMMON_JS.showNotify(
    //     props,
    //     NOTIFY.WARNING,
    //     "Please select contractor..!"
    //   );
    //   return null;
    // }
    // if (!isInductionTraining) {
    //   COMMON_JS.showNotify(
    //     props,
    //     NOTIFY.WARNING,
    //     "Please select induction training..!"
    //   );
    //   return null;
    // }
    // if (trainingDt === "") {
    //   COMMON_JS.showNotify(
    //     props,
    //     NOTIFY.WARNING,
    //     "Please enter induction training date..!"
    //   );
    //   return null;
    // }
    // if (trainingNum === "") {
    //   COMMON_JS.showNotify(
    //     props,
    //     NOTIFY.WARNING,
    //     "Please enter induction training number..!"
    //   );
    //   return null;
    // }
    // if (!isSafePassTraining) {
    //   COMMON_JS.showNotify(
    //     props,
    //     NOTIFY.WARNING,
    //     "Please select safepass training..!"
    //   );
    //   return null;
    // }
    // if (safePassTrainingDt === "") {
    //   COMMON_JS.showNotify(
    //     props,
    //     NOTIFY.WARNING,
    //     "Please enter safepass training date..!"
    //   );
    //   return null;
    // }
    // if (safePassTrainingNum === "") {
    //   COMMON_JS.showNotify(
    //     props,
    //     NOTIFY.WARNING,
    //     "Please enter safepass training number..!"
    //   );
    //   return null;
    // }
    // if (safePassValidity === "") {
    //   COMMON_JS.showNotify(
    //     props,
    //     NOTIFY.WARNING,
    //     "Please enter safepass validity details..!"
    //   );
    //   return null;
    // }
    // if (!statusOfSafePass) {
    //   COMMON_JS.showNotify(
    //     props,
    //     NOTIFY.WARNING,
    //     "Please select status of safepass..!"
    //   );
    //   return null;
    // }
    // if (insuranceComp === "") {
    //   COMMON_JS.showNotify(
    //     props,
    //     NOTIFY.WARNING,
    //     "Please enter insurence company..!"
    //   );
    //   return null;
    // }
    // if (insuranceDt === "") {
    //   COMMON_JS.showNotify(
    //     props,
    //     NOTIFY.WARNING,
    //     "Please enter insurence date..!"
    //   );
    //   return null;
    // }
    // if (insuranceExpiry === "") {
    //   COMMON_JS.showNotify(
    //     props,
    //     NOTIFY.WARNING,
    //     "Please enter insurence expiry date..!"
    //   );
    //   return null;
    // }
    // if (insuranceValue === "") {
    //   COMMON_JS.showNotify(
    //     props,
    //     NOTIFY.WARNING,
    //     "Please enter insurence value..!"
    //   );
    //   return null;
    // }
    // if (!statusOfInsurance) {
    //   COMMON_JS.showNotify(
    //     props,
    //     NOTIFY.WARNING,
    //     "Please select status of insurance..!"
    //   );
    //   return null;
    // }
    // if (!isSafetyTraining) {
    //   COMMON_JS.showNotify(
    //     props,
    //     NOTIFY.WARNING,
    //     "Please select safety training..!"
    //   );
    //   return null;
    // }
    // if (safetyTrainingNum === "") {
    //   COMMON_JS.showNotify(
    //     props,
    //     NOTIFY.WARNING,
    //     "Please enter safety training number..!"
    //   );
    //   return null;
    // }
    // if (safetyTrainingDt === "") {
    //   COMMON_JS.showNotify(
    //     props,
    //     NOTIFY.WARNING,
    //     "Please enter safety training date..!"
    //   );
    //   return null;
    // }
    // if (safetyTrainingValidity === "") {
    //   COMMON_JS.showNotify(
    //     props,
    //     NOTIFY.WARNING,
    //     "Please enter safety training validity..!"
    //   );
    //   return null;
    // }
    // if (!statusOfsafetyTraining) {
    //   COMMON_JS.showNotify(
    //     props,
    //     NOTIFY.WARNING,
    //     "Please select status of safety training..!"
    //   );
    //   return null;
    // }
    let args: any = updateForm();
    args = {
      ...args,
      empDataId: selectedRow.id || -1,
      contractorId,
      isInductionTraining,
      isSafePassTraining,
      empName,
      trainingNum,
      trainingDt,
      safePassTrainingNum,
      safePassTrainingDt,
      safePassValidity,
      statusOfSafePass,
      insuranceComp,
      insuranceDt,
      insuranceExpiry,
      insuranceValue,
      currency,
      statusOfInsurance,
      safetyTrainingNum,
      isSafetyTraining,
      safetyTrainingDt,
      safetyTrainingValidity,
      statusOfsafetyTraining,
      files: uploads,
      contractorData: {
        "1.1.1": contractorCompanyName,
        "1.1.2": companyRegNum,
        "1.1.3": companyLicense,
        "1.1.4": companyDate,
        "1.1.5": awards,
      },
      companyName,
      insurenceType,
      issuedDate,
      validityPeriod,
      trainingCompanyName,
    };

    console.log("manageEmpData: ", args);

    // Validations
    setEditRows(null);
    API_HANDLER_EHS.manageEmpData(args).then((res) => {
      if (res.status === 200) {
        COMMON_JS.showNotify(props, NOTIFY.SUCCESS, SUCCESS.RECORD_SAVED);
        setSelectedRow({});
        setManageDialog(false);
        getRows();
      } else {
        COMMON_JS.showNotify(props, NOTIFY.ERROR, res.error);
      }
    });
  };

  const updateForm = () => {
    let args: any = {};
    setFormData(args);
    return args;
  };

  const onReload = () => {
    getRows();
  };

  const onCloseForm = () => {
    setManageDialog(false);
  };

  let contractorData = {};
  if (selectedRow.id) {
    contractorData = JSON.parse(selectedRow.contractorData);
  }

  const curList = [
    {
      id: "USD - United States Dollar",
      label: "USD - United States Dollar",
    },
    {
      id: "EUR - Euro",
      label: "EUR - Euro",
    },
    {
      id: "CHf - Franc CHF",
      label: "CHf - Franc CHF",
    },
    {
      id: "INR - Indian Rupee",
      label: "INR - Indian Rupee",
    },
    {
      id: "JPY - Japanese Yen",
      label: "JPY - Japanese Yen",
    },
    {
      id: "GBP - British Pound Sterling",
      label: "GBP - British Pound Sterling",
    },
    {
      id: "CNY - Chinese Yuan Renminbi",
      label: "CNY - Chinese Yuan Renminbi",
    },
  ];

  const currencyRef: any = useRef(null);

  console.log("selectedRow:", selectedRow);

  return (
    <React.Fragment>
      <div className="pageInfo">
        <ul className="breadcrumbs">
          <li>{LABELS.HOME}</li>
          <li className="dim">{" / "}</li>
          <li onClick={() => props.setDashMenu(LABELS.HOME)}>{LABELS.EHS}</li>
          <li className="dim">{" / "}</li>
          <li className="active">{LABELS.EMPLOYEE_DATA}</li>
        </ul>
        <h4>{LABELS.EMPLOYEE_DATA}</h4>
      </div>

      {/* Graphs */}
      {pieChart.series && (
        <div className="graphs">
          <HGraph
            width={"50%"}
            type={"column"}
            xAxis={columnChart.xAxis}
            yAxis={columnChart.yAxis}
            title={" "}
            exportTitle={" "}
          />
          <HGraph
            width={"50%"}
            type={"pie"}
            float={"right"}
            series={pieChart.series}
            title={" "}
            exportTitle={" "}
          />
        </div>
      )}

      {/* Master Grid */}
      {rows ? (
        <ExcelGrid
          id={"empData"}
          height={window.innerHeight * 0.6}
          cols={cols}
          rows={rows}
          exportOption={true}
          newRowOption={false}
          exportName={LABELS.EMPLOYEE_DATA}
          onReload={onReload}
          onAdd={onAdd}
        />
      ) : (
        <div className="pageCenter">
          <Loader />
        </div>
      )}

      {/* Form Dialog Box */}
      {manageDialog && (
        <UiDialog
          title={
            selectedRow.id
              ? "Edit " + LABELS.EMPLOYEE_DATA + ": " + selectedRow.empName
              : "Add New " + LABELS.EMPLOYEE_DATA
          }
          open={true}
          size={"xl"}
          onClose={() => {
            setManageDialog(false);
            setFormData({});
            setPageNo(1);
          }}
        >
          <>
            <div style={{ width: "100%", flexDirection: "column" }}>
              {/* 1.1 */}
              <>
                <h4 className="ehsHeading1">{"1.1 Contractor Data"}</h4>
                <div className="formRow">
                  <div className="formGroup" style={{ width: "30%" }}>
                    <label>{"1.1.1 Company Name"}</label>
                    <TxtBox
                      type={"text"}
                      id="contractorCompanyName"
                      defaultValue={
                        selectedRow.id ? contractorData["1.1.1"] : ""
                      }
                    />
                  </div>
                  <div className="formGroup" style={{ width: "30%" }}>
                    <label>{"1.1.2 Company Registration No."}</label>
                    <TxtBox
                      type={"text"}
                      id="companyRegNum"
                      defaultValue={
                        selectedRow.id ? contractorData["1.1.2"] : ""
                      }
                    />
                  </div>
                  <div className="formGroup" style={{ width: "30%" }}>
                    <label>{"1.1.3 Company License"}</label>
                    <TxtBox
                      type={"text"}
                      id="companyLicense"
                      defaultValue={
                        selectedRow.id ? contractorData["1.1.3"] : ""
                      }
                    />
                  </div>
                </div>
                <div className="formRow">
                  <div className="formGroup" style={{ width: "30%" }}>
                    <label>{"1.1.4 Company License Issue Date"}</label>
                    <TxtBox
                      type={"date"}
                      id="companyDate"
                      defaultValue={
                        selectedRow.id ? contractorData["1.1.4"] : ""
                      }
                    />
                  </div>
                  <div className="formGroup" style={{ width: "30%" }}>
                    <label>{"1.1.5 Awards / Certifications"}</label>
                    <TxtBox
                      type={"text"}
                      id="awards"
                      defaultValue={
                        selectedRow.id ? contractorData["1.1.5"] : ""
                      }
                      rows={3}
                    />
                  </div>
                  <div className="formGroup" style={{ width: "30%" }}></div>
                </div>
              </>

              {/* 1.2 */}
              <>
                <h4 className="ehsHeading1">{"1.2 Employee Data *"}</h4>
                <div className="formRow">
                  <div className="formGroup" style={{ width: "30%" }}>
                    <label className="red">{"1.2.1 Contractor Name*"}</label>
                    <AutoComplete
                      list={contractorList}
                      childRef={contractorRef}
                      defaultValue={
                        selectedRow.id
                          ? {
                              id: selectedRow.contractorId,
                              label: selectedRow.contractorNme,
                            }
                          : null
                      }
                    />
                  </div>
                  <div className="formGroup" style={{ width: "30%" }}>
                    <label className="red">{"1.2.2 Employee Name *"}</label>
                    <TxtBox
                      type={"text"}
                      id="empName"
                      defaultValue={selectedRow.id ? selectedRow.empName : ""}
                    />
                  </div>
                  <div className="formGroup" style={{ width: "30%" }}>
                    <label className="red">
                      {"1.2.3 Induction Training *"}
                    </label>
                    <AutoComplete
                      list={trainingList}
                      childRef={trainingRef}
                      defaultValue={
                        selectedRow.id
                          ? selectedRow.isInductionTraining
                            ? trainingList[0]
                            : trainingList[1]
                          : null
                      }
                    />
                  </div>
                </div>
                <div className="formRow">
                  <div className="formGroup" style={{ width: "30%" }}>
                    <label className="red">
                      {"1.2.4 Induction Training No*"}
                    </label>
                    <TxtBox
                      type={"text"}
                      id="trainingNum"
                      defaultValue={
                        selectedRow.id ? selectedRow.trainingNum : ""
                      }
                    />
                  </div>
                  <div className="formGroup" style={{ width: "30%" }}>
                    <label className="red">
                      {"1.2.5 Induction Training Date *"}
                    </label>
                    <TxtBox
                      type={"date"}
                      id="trainingDt"
                      defaultValue={
                        selectedRow.id ? selectedRow.trainingDt : ""
                      }
                    />
                  </div>
                  <div className="formGroup" style={{ width: "30%" }}>
                    <label className="red">{"1.2.6 Safepass Training *"}</label>
                    <AutoComplete
                      list={safepassList}
                      childRef={safepassRef}
                      defaultValue={
                        selectedRow.id
                          ? selectedRow.isSafePassTraining
                            ? safepassList[0]
                            : safepassList[1]
                          : null
                      }
                    />
                  </div>
                </div>
                <div className="formRow">
                  <div className="formGroup" style={{ width: "30%" }}>
                    <label className="red">
                      {"1.2.7 Safepass Training No *"}
                    </label>
                    <TxtBox
                      type={"text"}
                      id="safePassTrainingNum"
                      defaultValue={
                        selectedRow.id ? selectedRow.safePassTrainingNum : ""
                      }
                    />
                  </div>
                  <div className="formGroup" style={{ width: "30%" }}>
                    <label className="red">
                      {"1.2.8 Safepass Training Date *"}
                    </label>
                    <TxtBox
                      type={"date"}
                      id="safePassTrainingDt"
                      defaultValue={
                        selectedRow.id ? selectedRow.safePassTrainingDt : ""
                      }
                    />
                  </div>
                  <div className="formGroup" style={{ width: "30%" }}>
                    <label className="red">
                      {"1.2.9 Safepass Exipry Date *"}
                    </label>
                    <TxtBox
                      type={"date"}
                      id="safePassValidity"
                      defaultValue={
                        selectedRow.id ? selectedRow.safePassValidity : ""
                      }
                    />
                  </div>
                </div>
                <div className="formRow">
                  <div className="formGroup" style={{ width: "30%" }}>
                    <label className="red">{"1.2.10 Safepass Status *"}</label>
                    <AutoComplete
                      list={statusList}
                      childRef={statusRef}
                      defaultValue={
                        selectedRow.id
                          ? selectedRow.statusOfSafePass
                            ? statusList[0]
                            : statusList[1]
                          : null
                      }
                    />
                  </div>
                </div>
              </>
              <>
                <h4 className="ehsHeading1">{"1.3 Insurance Data *"}</h4>
                <div className="formRow">
                  <div className="formGroup" style={{ width: "30%" }}>
                    <label>{"1.3.1 Company name"}</label>
                    <TxtBox
                      type={"text"}
                      id="companyName"
                      defaultValue={
                        selectedRow.id ? selectedRow.insurenceType : ""
                      }
                    />
                  </div>
                  <div className="formGroup" style={{ width: "30%" }}>
                    <label>{"1.3.2 Insurance Type"}</label>
                    <TxtBox
                      type={"text"}
                      id="insurenceType"
                      defaultValue={
                        selectedRow.id ? selectedRow.insurenceType : ""
                      }
                    />
                  </div>
                  <div className="formGroup" style={{ width: "30%" }}>
                    <label className="red">{"1.3.3 Date of Insurance *"}</label>
                    <TxtBox
                      type={"date"}
                      id="insuranceDt"
                      defaultValue={
                        selectedRow.id ? selectedRow.insuranceDt : ""
                      }
                    />
                  </div>
                </div>
                <div className="formRow">
                  <div className="formGroup" style={{ width: "30%" }}>
                    <label>{"1.3.4 Validity Period "}</label>
                    <TxtBox
                      type={"text"}
                      id="validity"
                      defaultValue={
                        selectedRow.id ? selectedRow.validityPeriod : ""
                      }
                    />
                  </div>
                  <div className="formGroup" style={{ width: "30%" }}>
                    <label className="red">{"1.3.5 Insurance Value *"}</label>
                    <TxtBox
                      type={"number"}
                      id="insuranceValue"
                      defaultValue={
                        selectedRow.id ? selectedRow.insuranceValue : ""
                      }
                    />
                  </div>
                  <div className="formGroup" style={{ width: "30%" }}>
                    <label className="red">{" Currency *"}</label>
                    <AutoComplete
                      list={curList}
                      childRef={currencyRef}
                      defaultValue={
                        selectedRow.id
                          ? {
                              id: selectedRow.currency,
                              label: selectedRow.currency,
                            }
                          : curList[0]
                      }
                    />
                    {/* <TxtBox
                      type={"text"}
                      id="currency"
                      defaultValue={selectedRow.id ?: ""}
                    /> */}
                  </div>
                </div>
                <div className="formRow">
                  <div className="formGroup" style={{ width: "30%" }}>
                    <label className="red">{"1.3.6 Insurance company *"}</label>
                    <TxtBox
                      type={"text"}
                      id="insuranceComp"
                      defaultValue={
                        selectedRow.id ? selectedRow.insuranceComp : ""
                      }
                    />
                  </div>
                  <div className="formGroup" style={{ width: "30%" }}>
                    <label>{"1.3.7 Issued date"}</label>
                    <TxtBox
                      type={"date"}
                      id="issuedDate"
                      defaultValue={
                        selectedRow.id ? selectedRow.issuedDate : ""
                      }
                    />
                  </div>
                  <div className="formGroup" style={{ width: "30%" }}>
                    <label className="red">{"1.3.8 Expiry date *"}</label>
                    <TxtBox
                      type={"date"}
                      id="insuranceExpiry"
                      defaultValue={
                        selectedRow.id ? selectedRow.insuranceExpiry : ""
                      }
                    />
                  </div>
                </div>
                <div className="formRow">
                  <div className="formGroup" style={{ width: "30%" }}>
                    <label className="red">{"1.3.9 Insurance Status *"}</label>
                    <AutoComplete
                      list={insurenceList}
                      childRef={insurenceRef}
                      defaultValue={
                        selectedRow.id
                          ? selectedRow.statusOfInsurance
                            ? insurenceList[0]
                            : insurenceList[1]
                          : null
                      }
                    />
                  </div>
                </div>
              </>
              <>
                <h4 className="ehsHeading1">{"1.4 Training Data *"}</h4>
                <div className="formRow">
                  <div className="formGroup" style={{ width: "30%" }}>
                    <label>{"1.4.1 Company Name"}</label>
                    <TxtBox
                      type={"text"}
                      id="trainingCompanyName"
                      defaultValue={
                        selectedRow.id ? selectedRow.trainingCompanyName : ""
                      }
                    />
                  </div>
                  <div className="formGroup" style={{ width: "30%" }}>
                    <label>{"1.4.2 Employee Name "}</label>
                    <TxtBox
                      type={"text"}
                      id="empName"
                      defaultValue={selectedRow.id ? selectedRow.empName : ""}
                    />
                  </div>
                  <div className="formGroup" style={{ width: "30%" }}>
                    <label className="red">{"1.4.3 Safety Training *"}</label>
                    <AutoComplete
                      list={safetyList}
                      childRef={safetyRef}
                      defaultValue={
                        selectedRow.id
                          ? selectedRow.isSafetyTraining
                            ? safetyList[0]
                            : safetyList[1]
                          : null
                      }
                    />
                  </div>
                </div>
                <div className="formRow">
                  <div className="formGroup" style={{ width: "30%" }}>
                    <label className="red">
                      {"1.4.4 Safety Training No *"}
                    </label>
                    <TxtBox
                      type={"text"}
                      id="safetyTrainingNum"
                      defaultValue={
                        selectedRow.id ? selectedRow.safetyTrainingNum : ""
                      }
                    />
                  </div>
                  <div className="formGroup" style={{ width: "30%" }}>
                    <label className="red">
                      {"1.4.5 Safety Training Date *"}
                    </label>
                    <TxtBox
                      type={"date"}
                      id="safetyTrainingDt"
                      defaultValue={
                        selectedRow.id ? selectedRow.safetyTrainingDt : ""
                      }
                    />
                  </div>
                  <div className="formGroup" style={{ width: "30%" }}>
                    <label className="red">
                      {"1.4.6 Safety Exipry Date *"}
                    </label>
                    <TxtBox
                      type={"date"}
                      id="safetyTrainingValidity"
                      defaultValue={
                        selectedRow.id ? selectedRow.safetyTrainingValidity : ""
                      }
                    />
                  </div>
                </div>
                <div className="formRow">
                  <div className="formGroup" style={{ width: "30%" }}>
                    <label className="red">
                      {"1.4.7 Safety Training Status *"}
                    </label>
                    <AutoComplete
                      list={safetyTrainList}
                      childRef={safetyTrainRef}
                      defaultValue={
                        selectedRow.id
                          ? selectedRow.statusOfsafetyTraining
                            ? safetyTrainList[0]
                            : safetyTrainList[1]
                          : null
                      }
                    />
                  </div>
                </div>
              </>

              <ModalBtmBtns
                onSave={manageEmpData}
                onCancel={onCloseForm}
                //disabled={editRows === null}
              />
            </div>
          </>
        </UiDialog>
      )}
    </React.Fragment>
  );
};

export default withSnackbar(EmployeeData);
