import React, { useEffect, useState } from "react";
import { IconButton } from "@mui/material";
import { Close as IconClose } from "@mui/icons-material";

import TxtBox from "components/Custom/TxtBox";
import SkeletonBox from "components/Custom/SkeletonBox";

import { API_CONFIG, LABELS, NOTIFY, WARNINGS } from "common/config";
import { ApiHome } from "apihandlers/home";
import { COMMON_JS } from "common/scripts";

const API_HANDLER_HOME = new ApiHome();

const pdfImg = require("assets/images/pdf.png");

interface comProps {
  childRef: any;
  selectedRow: any;
  uploads: any[];
  setUploads: Function;
}

const PartLast = (props: comProps) => {
  const { childRef, selectedRow, uploads, setUploads } = props;
  const [uploading, setUploading] = useState<boolean>(false);

  useEffect(() => {
    childRef.saveRecord = saveRecord;
    return () => {
      childRef.saveRecord = null;
    };
  }, []);

  const saveRecord = () => {
    const args = {};
    return args;
  };

  const onUploadFile = (e: any, f: any) => {
    console.log("onUploadFile: ", e, f);
    const file: any = e.target.files[0];
    if (file.size / 1024 / 1024 > 5) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.FILE_SIZE_EXCEEDED);
      return;
    }
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let base64: any = reader.result;
      setUploading(true);
      uploadFile(file, base64).then((res) => {
        console.log("res: ", res);
        setUploading(false);
      });
    };
    reader.onerror = (error) => {
      console.log("uploadFile: Error:", error);
    };
  };

  const uploadFile = async (file: any, base64 = "") => {
    const args = {
      recordRefId: 1,
      file: {
        name: file.name,
        data: base64.replace("data:", "").replace(/^.+,/, ""),
        type: file.name.split(".")[1],
        size: file.size,
      },
      category: "ehs",
    };
    let fileInfo = null;
    try {
      const res = await API_HANDLER_HOME.fileUpload(args);
      console.log("uploadFile: ", args, res);
      const response: any = res.response;
      if (res.status === 200) {
        fileInfo = response;
        setUploads([...uploads, fileInfo]);
        (document.getElementById("uploadFile") as HTMLInputElement).value =
          null;
      }
    } catch (error) {}
    return fileInfo;
  };

  return (
    <div>
      <div className="formGroup" style={{ width: "30%" }}>
        <label>
          {"1.6 Attachments"}
          {/* <small>{" ( Max File Size: 5 MB )"}</small> */}
        </label>
        <TxtBox
          type={"file"}
          id="uploadFile"
          className="uploadFile"
          accept="image/*,.pdf"
          onChange={onUploadFile}
        />
      </div>
      <div
        className="formGroup"
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
        }}
      >
        {uploads.map((u, i) => {
          return (
            <div className="thumbnailBox width20">
              <IconButton
                className="thumbnailBoxIcon"
                onClick={() => {
                  const list = uploads.filter((a, j) => j !== i);
                  setUploads(list);
                }}
              >
                <IconClose />
              </IconButton>
              <img
                onClick={() =>
                  window.open(API_CONFIG.API_URL + u.path, "_blank")
                }
                src={
                  u.mimeType === "pdf" ? pdfImg : API_CONFIG.API_URL + u.path
                }
                className="responsive cursor"
              />
            </div>
          );
        })}
        {uploading && (
          <div className="thumbnailBox width20">
            <SkeletonBox height={300} />
          </div>
        )}
      </div>
    </div>
  );
};

export default PartLast;
