import React, { useState, useEffect, useRef } from "react";

import ChkBox from "components/Custom/ChkBox";
import ExcelGrid from "components/Custom/ExcelGrid";
import Loader from "components/Custom/Loader";

const dataJson = require("../gridData.json");

interface defaultProps {
  childRef: any;
  editRows: any[];
}

const Part1 = (props: defaultProps) => {
  const { childRef, editRows } = props;
  const [firstAidCols, setFirstAidCols] = useState<any[]>([
    {
      key: "firstAid",
      name: "First Aid",
      type: "text",
      editable: false,
      width: "60%",
    },
    {
      key: "checkBox",
      name: "Check Box",
      type: "checkbox",
      sortable: true,
      width: "10%",
      className: "textCenter",
    },
    {
      key: "action",
      name: "Action",
      type: "text",
      width: "30%",
    },
  ]);

  let tmpFirstAidRows: any = dataJson["1.1"]["1.1.1"];
  let tmpFirstSafetyRows: any = dataJson["1.2"]["1.2.1"];
  for (let key in tmpFirstAidRows) {
    tmpFirstAidRows[key].checkBox = tmpFirstAidRows[key].checkBox || false;
    tmpFirstAidRows[key].action = tmpFirstAidRows[key].action || "";
    tmpFirstAidRows[key].safetyMonitorItemId =
      tmpFirstAidRows[key].safetyMonitorItemId || -1;
  }
  for (let key in tmpFirstSafetyRows) {
    tmpFirstSafetyRows[key].checkBox =
      tmpFirstSafetyRows[key].checkBox || false;
    tmpFirstSafetyRows[key].action = tmpFirstSafetyRows[key].action || "";
    tmpFirstSafetyRows[key].safetyMonitorItemId = -1;
  }

  if (!editRows) {
    tmpFirstAidRows = null;
    tmpFirstSafetyRows = null;
  } else if (editRows["records"]) {
    for (let key in tmpFirstAidRows) {
      if (editRows["records"][tmpFirstAidRows[key].id]) {
        const details = JSON.parse(
          editRows["records"][tmpFirstAidRows[key].id].safetyDetails
        );
        const jsonData = {
          ...tmpFirstAidRows[key],
          ...details,
          safetyMonitorItemId:
            editRows["records"][tmpFirstAidRows[key].id].safetyMonitorItemId,
        };
        tmpFirstAidRows[key] = jsonData;
      }
    }
    for (let key in tmpFirstSafetyRows) {
      if (editRows["records"][tmpFirstSafetyRows[key].id]) {
        const details = JSON.parse(
          editRows["records"][tmpFirstSafetyRows[key].id].safetyDetails
        );
        const jsonData = {
          ...tmpFirstSafetyRows[key],
          ...details,
          safetyMonitorItemId:
            editRows["records"][tmpFirstSafetyRows[key].id].safetyMonitorItemId,
        };
        tmpFirstSafetyRows[key] = jsonData;
      }
    }
  } else {
  }
  const [firstAidRows, setFirstAidRows] = useState<any[]>(tmpFirstAidRows);
  const [fireSafetyCols, setFireSafetyCols] = useState<any[]>([
    {
      key: "fireSafety",
      name: "Fire Safety",
      type: "text",
      editable: false,
      width: "60%",
    },
    {
      key: "checkBox",
      name: "Check Box",
      type: "checkbox",
      className: "textCenter",
      sortable: true,
      width: "10%",
    },
    {
      key: "action",
      name: "Action",
      type: "text",
      width: "30%",
    },
  ]);
  const [fireSafetyRows, setFireSafetyRows] =
    useState<any[]>(tmpFirstSafetyRows);

  const grid1Ref: any = useRef();
  const grid2Ref: any = useRef();

  useEffect(() => {
    childRef.saveRecord = saveRecord;
    return () => {
      childRef.saveRecord = null;
    };
  }, []);

  const saveRecord = () => {
    const section1 = grid1Ref.getRowData().map((s1) => {
      return {
        action: s1.action,
        id: s1.id,
        checkBox: s1.checkBox,
        safetyMonitorItemId: s1.safetyMonitorItemId || -1,
      };
    });
    const section2 = grid2Ref.getRowData().map((s1) => {
      return {
        action: s1.action,
        id: s1.id,
        checkBox: s1.checkBox,
        safetyMonitorItemId: s1.safetyMonitorItemId || -1,
      };
    });
    const items = [...section1, ...section2];
    return { items };
  };

  console.log("firstAidRows: ", editRows, tmpFirstAidRows);

  return (
    <>
      <h4 className="ehsHeading1">{dataJson["1.1"]["heading1"]}</h4>
      <h5 className="ehsHeading2">{dataJson["1.1"]["heading2"]}</h5>
      {tmpFirstAidRows ? (
        <ExcelGrid
          childRef={grid1Ref}
          cols={firstAidCols}
          rows={tmpFirstAidRows}
          noTotalRows={true}
        />
      ) : (
        <div className="pageCenter">
          <Loader />
        </div>
      )}
      <h4 className="ehsHeading1">{dataJson["1.2"]["heading1"]}</h4>
      <h5 className="ehsHeading2">{dataJson["1.2"]["heading2"]}</h5>
      {tmpFirstSafetyRows ? (
        <ExcelGrid
          childRef={grid2Ref}
          cols={fireSafetyCols}
          rows={tmpFirstSafetyRows}
          noTotalRows={true}
        />
      ) : (
        <div className="pageCenter">
          <Loader />
        </div>
      )}
    </>
  );
};

export default Part1;
