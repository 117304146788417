import React, { useEffect, useReducer, useRef, useState } from "react";
import { withSnackbar } from "notistack";

import LeftBar from "components/Layouts/LeftBar";
import FileUploadPage from "components/FileUploadPage";
import GanttChartPage from "components/GanttChartPage";
import ProjectsPage from "components/ProjectsPage";
import UsersPage from "components/UsersPage";
import OrgsPage from "components/OrgsPage";
import Reports from "components/Reports";
import ApproveFile from "components/ApproveFile";
import Settings from "components/Settings";
import HomeSkeleton from "./HomeSkeleton";
import ProjectStatus from "components/ProjectStatus";
import Rfi from "components/Rfi";
import RisksRegister from "components/RisksRegister";
import LessonsLearned from "components/LessonsLearned";
import ReportConstraintLog from "components/ReportConstraintLog";
import ProjectHistory from "components/ProjectHistory";
import Ehs from "components/Ehs";
import Cqv from "components/Cqv";
import EquipmentRegister from "components/EquipmentRegister";
import Audit from "components/Audits";
import Evaluation from "components/Evaluation";
import PreTender from "components/PreTender";
import Lototo from "components/Ehs/Lototo";
import PunchlistDetails from "components/PunchlistDetails";

import { ApiLogin } from "apihandlers/login";
import { CONFIG, LABELS, LOCAL_STORAGE, TITLES } from "common/config";

import "./styles/home.css";

const API_HANDLER = new ApiLogin();

const imgBanner = require("assets/images/banner.png");
const imgClearSphere = require("assets/images/clearsphere.jpg");

const HomePage = (props: any) => {
  const [loading, setLoading] = useState(true);
  const [userInfo, setUserInfo] = useState<any>({});
  const [page, setPage] = useState(LABELS.HOME);

  useEffect(() => {
    console.log("Home: userEffect: state: ", loading);
    if (loading) {
      checkLogin();
    }
  }, [loading]);

  const checkLogin = () => {
    const args = {};
    API_HANDLER.checkLogin(args).then((res) => {
      const response: any = res.response;
      const { status } = res;
      console.log("checkLogin: ", res);
      if (status === 200) {
        if (response.status) {
          let userInfo = localStorage.getItem(LOCAL_STORAGE.USER_INFO);
          if (userInfo) {
            userInfo = JSON.parse(userInfo);
            setUserInfo(userInfo);
          }
          setLoading(false);
          setPage(LABELS.HOME);
        } else {
          window.open("/login", "_self");
        }
      }
    });
  };

  const reportsRef: any = useRef({});
  const ehsRef: any = useRef({});
  const cqvRef: any = useRef({});
  const evaluationRef: any = useRef({});
  const auditRef: any = useRef({});

  const onSetPage = (val = "") => {
    setPage(val);
    if (val === LABELS.REPORTS) {
      if (reportsRef.setMenu) {
        reportsRef.setMenu();
      }
    } else if (val === LABELS.EHS) {
      if (ehsRef.setMenu) {
        ehsRef.setMenu();
      }
    } else if (val === LABELS.EVALUATION) {
      if (evaluationRef.setMenu) {
        evaluationRef.setMenu();
      }
    } else if (val === LABELS.AUDITS) {
      if (auditRef.setMenu) {
        auditRef.setMenu();
      }
    } else if (val === TITLES.CQV) {
      if (cqvRef.setMenu) {
        cqvRef.setMenu();
      }
    }
  };

  console.log("page: ", userInfo);

  return (
    <div
      className={"homeContainer " + (page === LABELS.HOME ? "bgBanner" : "")}
      style={{
        overflow: page === LABELS.GANTT_CHART ? "none" : "auto",
      }}
    >
      {loading ? (
        <HomeSkeleton />
      ) : (
        <React.Fragment>
          <LeftBar setPage={onSetPage} page={page} userInfo={userInfo} />
          <div className="content">
            {page === LABELS.HOME && (
              <div style={{ position: "relative" }}>
                <h4
                  className="shadowTxt fontXL"
                  style={{
                    marginTop: 35,
                    textAlign: "left",
                  }}
                >
                  Welcome {userInfo.fullName + "!"}
                  {/* {CONFIG.SITE_NAME + " "} */}
                  {/* <small className="fontM">{CONFIG.VERSION}</small> */}
                </h4>
                {/* <h4
                  className="shadowTxt fontL"
                  style={{ marginTop: 0, textAlign: "left", paddingTop: 10 }}
                >
                  Welcome {userInfo.fullName + "!"}
                </h4> */}
                {/* <div style={{ width: "80%", margin: "auto" }}>
                  <img src={imgBanner} className="responsive" />
                </div> */}
                {userInfo.role.id === CONFIG.USER_ROLES.ClearSphere && (
                  <img
                    src={imgClearSphere}
                    className="responsive"
                    style={{
                      position: "absolute",
                      right: 20,
                      width: 200,
                      top: 10,
                      borderRadius: 5,
                      filter: "drop-shadow(1px 2px 6px #000)",
                    }}
                  />
                )}
              </div>
            )}

            {page === LABELS.ORGANIZATION && <OrgsPage />}

            {page === LABELS.PROJECTS && <ProjectsPage />}

            {page === LABELS.REPORTS && (
              <Reports childRef={reportsRef} userInfo={userInfo} />
            )}

            {page === LABELS.GANTT_CHART && (
              <GanttChartPage userInfo={userInfo} />
            )}

            {page === LABELS.FILE_UPLOAD && (
              <FileUploadPage userInfo={userInfo} />
            )}

            {page === LABELS.USERS && <UsersPage />}

            {page === LABELS.APPROVE_FILE && <ApproveFile />}

            {page === LABELS.SETTINGS && <Settings userInfo={userInfo} />}

            {page === LABELS.PROJECT_STATUS && (
              <ProjectStatus userInfo={userInfo} />
            )}

            {page === LABELS.RFI && <Rfi userInfo={userInfo} />}

            {page === LABELS.RISKS_REGISTER && (
              <RisksRegister userInfo={userInfo} />
            )}

            {page === LABELS.LESSONS_LEARNED && (
              <LessonsLearned userInfo={userInfo} />
            )}

            {page === LABELS.CONSTRAINT_LOG_REPORT && (
              <ReportConstraintLog userInfo={userInfo} />
            )}

            {page === LABELS.PROJECT_HISTORY && (
              <ProjectHistory userInfo={userInfo} />
            )}

            {page === LABELS.EHS && (
              <Ehs childRef={ehsRef} userInfo={userInfo} />
            )}

            {page === LABELS.EVALUATION && (
              <Evaluation childRef={evaluationRef} userInfo={userInfo} />
            )}

            {page === LABELS.AUDIT && (
              <Audit childRef={auditRef} userInfo={userInfo} />
            )}

            {page === LABELS.PRE_TENDER && <PreTender userInfo={userInfo} />}

            {page === LABELS.LOTOTO && <Lototo userInfo={userInfo} />}

            {page === TITLES.CQV && (
              <Cqv childRef={cqvRef} userInfo={userInfo} />
            )}

            {page === TITLES.EQUIPMENT_REGISTER && (
              <EquipmentRegister userInfo={userInfo} />
            )}
            {page === TITLES.PUNCHLIST_DETAILS && (
              <PunchlistDetails userInfo={userInfo} />
            )}
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default withSnackbar(HomePage);
