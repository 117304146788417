import React, { useState, useEffect, useRef } from "react";

import ChkBox from "components/Custom/ChkBox";
import ExcelGrid from "components/Custom/ExcelGrid";
import Loader from "components/Custom/Loader";

const dataJson = require("../gridData.json");

interface defaultProps {
  childRef: any;
  editRows: any[];
}

const Part3 = (props: defaultProps) => {
  const { childRef, editRows } = props;

  const [loading, setLoading] = useState<boolean>(true);
  const [personalProtectCols, setPersonalProtecCols] = useState<any[]>([
    {
      key: "personalProtect",
      name: "Personnel Protective Equipment",
      type: "text",
      editable: false,
      width: "60%",
    },
    {
      key: "checkBox",
      name: "Check Box",
      type: "checkbox",
      sortable: true,
      width: "10%",
      className: "textCenter",
    },
    {
      key: "action",
      name: "Action",
      type: "text",
      width: "30%",
    },
  ]);

  let tmpPersonnelProtectRows = dataJson["1.5"]["1.5.1"];
  let tmpGasCylinderRows = dataJson["1.6"]["1.6.1"];
  for (let key in tmpPersonnelProtectRows) {
    tmpPersonnelProtectRows[key].checkBox =
      tmpPersonnelProtectRows[key].checkBox || false;
    tmpPersonnelProtectRows[key].action =
      tmpPersonnelProtectRows[key].action || "";
    tmpPersonnelProtectRows[key].safetyMonitorItemId =
      tmpPersonnelProtectRows[key].safetyMonitorItemId || -1;
  }
  for (let key in tmpGasCylinderRows) {
    tmpGasCylinderRows[key].checkBox =
      tmpGasCylinderRows[key].checkBox || false;
    tmpGasCylinderRows[key].action = tmpGasCylinderRows[key].action || "";
    tmpGasCylinderRows[key].safetyMonitorItemId =
      tmpGasCylinderRows[key].safetyMonitorItemId || -1;
  }

  if (!editRows) {
    tmpPersonnelProtectRows = null;
    tmpGasCylinderRows = null;
  } else if (editRows["records"]) {
    for (let key in tmpPersonnelProtectRows) {
      if (editRows["records"][tmpPersonnelProtectRows[key].id]) {
        const details = JSON.parse(
          editRows["records"][tmpPersonnelProtectRows[key].id].safetyDetails
        );
        const jsonData = {
          ...tmpPersonnelProtectRows[key],
          ...details,
          safetyMonitorItemId:
            editRows["records"][tmpPersonnelProtectRows[key].id]
              .safetyMonitorItemId,
        };
        tmpPersonnelProtectRows[key] = jsonData;
      }
    }
    for (let key in tmpGasCylinderRows) {
      if (editRows["records"][tmpGasCylinderRows[key].id]) {
        const details = JSON.parse(
          editRows["records"][tmpGasCylinderRows[key].id].safetyDetails
        );
        const jsonData = {
          ...tmpGasCylinderRows[key],
          ...details,
          safetyMonitorItemId:
            editRows["records"][tmpGasCylinderRows[key].id].safetyMonitorItemId,
        };
        tmpGasCylinderRows[key] = jsonData;
      }
    }
  }
  const [personalProtectRows, setPersonalProtectRows] = useState<any[]>(
    tmpPersonnelProtectRows
  );
  const [gasCylinderCols, setGasCylinderCols] = useState<any[]>([
    {
      key: "gasCylindder",
      name: "Gas Cylinders",
      type: "text",
      editable: false,
      width: "60%",
    },
    {
      key: "checkBox",
      name: "Check Box",
      type: "checkbox",
      sortable: true,
      width: "10%",
    },
    {
      key: "action",
      name: "Action",
      type: "text",
      width: "30%",
    },
  ]);
  const [gasCylinderRows, setGasCylinderRows] =
    useState<any[]>(tmpGasCylinderRows);

  const grid5Ref: any = useRef();
  const grid6Ref: any = useRef();

  useEffect(() => {
    childRef.saveRecord = saveRecord;
    return () => {
      childRef.saveRecord = null;
    };
  }, []);

  const saveRecord = () => {
    const section5 = grid5Ref.getRowData().map((s1) => {
      return {
        action: s1.action,
        id: s1.id,
        checkBox: s1.checkBox,
        safetyMonitorItemId: s1.safetyMonitorItemId || -1,
      };
    });
    const section6 = grid6Ref.getRowData().map((s1) => {
      return {
        action: s1.action,
        id: s1.id,
        checkBox: s1.checkBox,
        safetyMonitorItemId: s1.safetyMonitorItemId || -1,
      };
    });
    const items = [...section5, ...section6];
    return { items };
  };

  return (
    <>
      <h4 className="ehsHeading1">{dataJson["1.5"]["heading1"]}</h4>
      <h5 className="ehsHeading2">{dataJson["1.5"]["heading2"]}</h5>
      {tmpPersonnelProtectRows ? (
        <ExcelGrid
          childRef={grid5Ref}
          cols={personalProtectCols}
          rows={tmpPersonnelProtectRows}
          noTotalRows={true}
        />
      ) : (
        <div className="pageCenter">
          <Loader />
        </div>
      )}
      <h4 className="ehsHeading1">{dataJson["1.6"]["heading1"]}</h4>
      <h5 className="ehsHeading2">{dataJson["1.6"]["heading2"]}</h5>
      {tmpGasCylinderRows ? (
        <ExcelGrid
          childRef={grid6Ref}
          cols={gasCylinderCols}
          rows={tmpGasCylinderRows}
          noTotalRows={true}
        />
      ) : (
        <div className="pageCenter">
          <Loader />
        </div>
      )}
    </>
  );
};

export default Part3;
