import React, { useState, useEffect, useRef } from "react";

import ChkBox from "components/Custom/ChkBox";
import ExcelGrid from "components/Custom/ExcelGrid";
import Loader from "components/Custom/Loader";

const dataJson = require("../gridData.json");

interface defaultProps {
  childRef: any;
  editRows: any[];
}

const Part3 = (props: defaultProps) => {
  const { childRef, editRows } = props;
  const [ehsPolicyCols, setEhsPolicyCols] = useState<any[]>([
    {
      key: "question",
      name: "Question",
      type: "text",
      editable: false,
      width: "65%",
    },
    {
      key: "checkBoxYes",
      name: "YES",
      inputValue: "true",
      type: "radiobutton",
      sortable: true,
      width: "5%",
      className: "textCenter",
    },
    {
      key: "checkBoxNo",
      name: "NO",
      inputValue: "false",
      type: "radiobutton",
      sortable: true,
      width: "5%",
      className: "textCenter",
    },
    {
      key: "comments",
      name: "Comments",
      type: "text",
      editable: true,
      width: "25%",
    },
  ]);

  const tmpEhsPolicyRows = dataJson["5"]["5.1"];
  const tmpPlanningRows = dataJson["6"]["6.1"];
  for (let key in tmpEhsPolicyRows) {
    tmpEhsPolicyRows[key].comments = "";
    tmpEhsPolicyRows[key].checkBoxYes = "";
    tmpEhsPolicyRows[key].checkBoxNo = "";
  }
  for (let key in tmpPlanningRows) {
    tmpPlanningRows[key].comments = "";
    tmpPlanningRows[key].checkBoxYes = "";
    tmpPlanningRows[key].checkBoxNo = "";
  }

  const [ehsPolicyRows, setEhsPolicyRows] = useState<any[]>(tmpEhsPolicyRows);
  const [planningCols, setPlanningCols] = useState<any[]>([
    {
      key: "question",
      name: "Question",
      type: "text",
      editable: false,
      width: "65%",
    },
    {
      key: "checkBoxYes",
      name: "YES",
      inputValue: "true",
      type: "radiobutton",
      className: "textCenter",
      sortable: true,
      width: "5%",
      renderCell: ({ planningRows, onRowChange, tabIndex }) => {
        return (
          <div className="excelChkWrapper">
            <ChkBox
              checked={planningRows.check}
              onChange={(val: any) => {
                onRowChange({
                  ...planningRows,
                  check: !planningRows.check,
                });
              }}
            />
          </div>
        );
      },
    },
    {
      key: "checkBoxNo",
      name: "NO",
      inputValue: "true",
      type: "radiobutton",
      className: "textCenter",
      sortable: true,
      width: "5%",
      renderCell: ({ planningRows, onRowChange, tabIndex }) => {
        return (
          <div className="excelChkWrapper">
            <ChkBox
              checked={planningRows.check}
              onChange={(val: any) => {
                onRowChange({
                  ...planningRows,
                  check: !planningRows.check,
                });
              }}
            />
          </div>
        );
      },
    },
    {
      key: "comments",
      name: "Comments",
      type: "text",
      editable: true,
      width: "25%",
    },
  ]);
  const [planningRows, setPlanningRows] = useState<any[]>(tmpPlanningRows);

  const grid1Ref: any = useRef();
  const grid2Ref: any = useRef();

  useEffect(() => {
    childRef.saveRecord = saveRecord;
    return () => {
      childRef.saveRecord = null;
    };
  }, []);

  const saveRecord = () => {
    const section1 = grid1Ref.getRowData().map((s1) => {
      return {
        id: s1.id,
        checkBoxYes: s1.checkBoxYes,
        checkBoxNo: s1.checkBoxNo,
        comments: s1.comments,
      };
    });
    const section2 = grid2Ref.getRowData().map((s1) => {
      return {
        id: s1.id,
        checkBoxYes: s1.checkBoxYes,
        checkBoxNo: s1.checkBoxNo,
        comments: s1.comments,
      };
    });
    const items = [...section1, ...section2];
    return { items };
  };

  return (
    <>
      <h4 className="ehsHeading1">{dataJson["5"]["heading1"]}</h4>
      <h5 className="ehsHeading2">{dataJson["5"]["heading2"]}</h5>
      {ehsPolicyRows ? (
        <ExcelGrid
          childRef={grid1Ref}
          cols={ehsPolicyCols}
          rows={ehsPolicyRows}
          noTotalRows={true}
        />
      ) : (
        <div className="pageCenter">
          <Loader />
        </div>
      )}
      <h4 className="ehsHeading1">{dataJson["6"]["heading1"]}</h4>
      <h5 className="ehsHeading2">{dataJson["6"]["heading2"]}</h5>
      {planningRows ? (
        <ExcelGrid
          childRef={grid2Ref}
          cols={planningCols}
          rows={planningRows}
          noTotalRows={true}
        />
      ) : (
        <div className="pageCenter">
          <Loader />
        </div>
      )}
    </>
  );
};

export default Part3;
