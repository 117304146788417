import React, { useState, useEffect, useRef } from "react";

import ChkBox from "components/Custom/ChkBox";
import ExcelGrid from "components/Custom/ExcelGrid";
import Loader from "components/Custom/Loader";
import { DEFAULTS } from "common/config";

const dataJson = require("components/Evaluation/ContractForm/gridData.json");

interface defaultProps {
  childRef: any;
  editRows: any[];
}

const Part4 = (props: defaultProps) => {
  const { childRef, editRows } = props;
  const [personalProtectCols, setPersonalProtectCols] = useState<any[]>([
    {
      key: "question",
      name: "question",
      type: "text",
      editable: false,
      width: "65%",
    },
    {
      key: "checkBoxYes",
      name: "YES",
      inputValue: "true",
      type: "radiobutton",
      sortable: true,
      editable: false,
      width: "5%",
      className: "textCenter",
    },
    {
      key: "checkBoxNo",
      name: "NO",
      inputValue: "true",
      type: "radiobutton",
      sortable: true,
      editable: false,
      width: "5%",
      className: "textCenter",
    },
    {
      key: "comments",
      name: "Comments",
      type: "text",
      editable: false,
      width: "20%",
    },
    {
      key: "score",
      name: "Score",
      type: "dropdown",
      list: DEFAULTS.SCORES,
      editable: true,
      width: "5%",
    },
  ]);

  let tmpPersonalProtectRows = dataJson["7"]["7.1"];
  let tmpEquipmentRows = dataJson["8"]["8.1"];
  let tmpSubContractRows = dataJson["9"]["9.1"];
  for (let key in tmpPersonalProtectRows) {
    tmpPersonalProtectRows[key].checkBoxYes = "";
    tmpPersonalProtectRows[key].checkBoxNo = "";
    tmpPersonalProtectRows[key].score = "";
    tmpPersonalProtectRows[key].comments = "";
  }
  for (let key in tmpEquipmentRows) {
    tmpEquipmentRows[key].checkBoxYes = "";
    tmpEquipmentRows[key].checkBoxNo = "";
    tmpEquipmentRows[key].score = "";
    tmpEquipmentRows[key].comments = "";
  }
  for (let key in tmpSubContractRows) {
    tmpSubContractRows[key].checkBoxYes = "";
    tmpSubContractRows[key].checkBoxNo = "";
    tmpSubContractRows[key].score = "";
    tmpSubContractRows[key].comments = "";
  }

  if (!editRows) {
    tmpPersonalProtectRows = null;
    tmpEquipmentRows = null;
    tmpSubContractRows = null;
  } else if (editRows["records"]) {
    const safetyDetails = JSON.parse(
      editRows["records"][0]["protectiveDetails"]
    );
    for (let i = 0; i < tmpPersonalProtectRows.length; i++) {
      const jsonData = {
        question: tmpPersonalProtectRows[i].question,
        ...safetyDetails[i],
      };
      tmpPersonalProtectRows[i] = jsonData;
    }
    let safetyLen = tmpPersonalProtectRows.length;
    for (let i = 0; i < tmpEquipmentRows.length; i++) {
      const jsonData = {
        question: tmpEquipmentRows[i].question,
        ...safetyDetails[safetyLen + i],
      };
      tmpEquipmentRows[i] = jsonData;
    }
    safetyLen = tmpEquipmentRows.length + tmpPersonalProtectRows.length;
    for (let i = 0; i < tmpSubContractRows.length; i++) {
      const jsonData = {
        question: tmpSubContractRows[i].question,
        ...safetyDetails[safetyLen + i],
      };
      tmpSubContractRows[i] = jsonData;
    }
  }

  const [personalProtectRows, setPersonalProtectRows] = useState<any[]>(
    tmpPersonalProtectRows
  );
  const [equipmentCols, setEquipmentCols] = useState<any[]>([
    {
      key: "question",
      name: "question",
      type: "text",
      editable: false,
      width: "65%",
    },
    {
      key: "checkBoxYes",
      name: "YES",
      inputValue: "true",
      type: "radiobutton",
      className: "textCenter",
      sortable: true,
      editable: false,
      width: "5%",
      renderCell: ({ equipmentRows, onRowChange, tabIndex }) => {
        return (
          <div className="excelChkWrapper">
            <ChkBox
              checked={equipmentRows.check}
              onChange={(val: any) => {
                onRowChange({
                  ...equipmentRows,
                  check: !equipmentRows.check,
                });
              }}
            />
          </div>
        );
      },
    },
    {
      key: "checkBoxNo",
      name: "NO",
      inputValue: "true",
      type: "radiobutton",
      className: "textCenter",
      sortable: true,
      editable: false,
      width: "5%",
      renderCell: ({ equipmentRows, onRowChange, tabIndex }) => {
        return (
          <div className="excelChkWrapper">
            <ChkBox
              checked={equipmentRows.check}
              onChange={(val: any) => {
                onRowChange({
                  ...equipmentRows,
                  check: !equipmentRows.check,
                });
              }}
            />
          </div>
        );
      },
    },
    {
      key: "comments",
      name: "Comments",
      type: "text",
      editable: false,
      width: "20%",
    },
    {
      key: "score",
      name: "Score",
      type: "dropdown",
      list: DEFAULTS.SCORES,
      editable: true,
      width: "5%",
    },
  ]);
  const [equipmentRows, setEquipmentRows] = useState<any[]>(tmpEquipmentRows);
  const [subConntractCols, setSubConntractCols] = useState<any[]>([
    {
      key: "question",
      name: "Question",
      type: "text",
      editable: false,
      width: "65%",
    },
    {
      key: "checkBoxYes",
      name: "YES",
      inputValue: "true",
      type: "radiobutton",
      className: "textCenter",
      sortable: true,
      editable: false,
      width: "5%",
      renderCell: ({ subConntractRows, onRowChange, tabIndex }) => {
        return (
          <div className="excelChkWrapper">
            <ChkBox
              checked={subConntractRows.check}
              onChange={(val: any) => {
                onRowChange({
                  ...subConntractRows,
                  check: !subConntractRows.check,
                });
              }}
            />
          </div>
        );
      },
    },
    {
      key: "checkBoxNo",
      name: "NO",
      inputValue: "true",
      type: "radiobutton",
      className: "textCenter",
      sortable: true,
      editable: false,
      width: "5%",
      renderCell: ({ subConntractRows, onRowChange, tabIndex }) => {
        return (
          <div className="excelChkWrapper">
            <ChkBox
              checked={subConntractRows.check}
              onChange={(val: any) => {
                onRowChange({
                  ...subConntractRows,
                  check: !subConntractRows.check,
                });
              }}
            />
          </div>
        );
      },
    },
    {
      key: "comments",
      name: "Comments",
      type: "text",
      editable: false,
      width: "20%",
    },
    {
      key: "score",
      name: "Score",
      type: "dropdown",
      list: DEFAULTS.SCORES,
      editable: true,
      width: "5%",
    },
  ]);
  const [subConntractRows, setSubConntractRows] =
    useState<any[]>(tmpSubContractRows);

  const grid1Ref: any = useRef();
  const grid2Ref: any = useRef();
  const grid3Ref: any = useRef();

  useEffect(() => {
    childRef.saveRecord = saveRecord;
    return () => {
      childRef.saveRecord = null;
    };
  }, []);

  const saveRecord = () => {
    const section1 = grid1Ref.getRowData().map((s1) => {
      return {
        id: s1.id,
        checkBoxYes: s1.checkBoxYes,
        checkBoxNo: s1.checkBoxNo,
        comments: s1.comments,
        score: s1.score,
      };
    });
    const section2 = grid2Ref.getRowData().map((s1) => {
      return {
        id: s1.id,
        checkBoxYes: s1.checkBoxYes,
        checkBoxNo: s1.checkBoxNo,
        comments: s1.comments,
        score: s1.score,
      };
    });
    const section3 = grid3Ref.getRowData().map((s1) => {
      return {
        id: s1.id,
        checkBoxYes: s1.checkBoxYes,
        checkBoxNo: s1.checkBoxNo,
        comments: s1.comments,
        score: s1.score,
      };
    });
    const items = [...section1, ...section2, ...section3];
    return { items };
  };

  return (
    <>
      <h4 className="ehsHeading1">{dataJson["7"]["heading1"]}</h4>
      <h5 className="ehsHeading2">{dataJson["7"]["heading2"]}</h5>
      {tmpPersonalProtectRows ? (
        <ExcelGrid
          childRef={grid1Ref}
          cols={personalProtectCols}
          rows={tmpPersonalProtectRows}
          noTotalRows={true}
        />
      ) : (
        <div className="pageCenter">
          <Loader />
        </div>
      )}

      <h4 className="ehsHeading1">{dataJson["8"]["heading1"]}</h4>
      <h5 className="ehsHeading2">{dataJson["8"]["heading2"]}</h5>
      {tmpEquipmentRows ? (
        <ExcelGrid
          childRef={grid2Ref}
          cols={equipmentCols}
          rows={tmpEquipmentRows}
          noTotalRows={true}
        />
      ) : (
        <div className="pageCenter">
          <Loader />
        </div>
      )}

      <h4 className="ehsHeading1">{dataJson["9"]["heading1"]}</h4>
      <h5 className="ehsHeading2">{dataJson["9"]["heading2"]}</h5>
      {tmpSubContractRows ? (
        <ExcelGrid
          childRef={grid3Ref}
          cols={subConntractCols}
          rows={tmpSubContractRows}
          noTotalRows={true}
        />
      ) : (
        <div className="pageCenter">
          <Loader />
        </div>
      )}
    </>
  );
};

export default Part4;
