import React, { useEffect, useRef, useState } from "react";
import { withSnackbar } from "notistack";
import {
  DataGrid,
  GridColDef,
  GridToolbar,
  GridValueGetterParams,
  GridToolbarExportContainer,
  GridToolbarExport,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import { IconButton, Tooltip } from "@mui/material";
import {
  Edit as IconEdit,
  AccountCircle as IconUser,
  Chat as IconReply,
} from "@mui/icons-material";

import Btn from "components/Custom/Btn";
import AutoComplete from "components/Custom/AutoComplete";
import ConfirmDialog from "components/Custom/ConfirmDialog";
import Loader from "components/Custom/Loader";
import UiDialog from "components/Custom/UiDialog";
import TxtBox from "components/Custom/TxtBox";

import {
  DEFAULTS,
  ERRORS,
  LABELS,
  NOTIFY,
  SUCCESS,
  WARNINGS,
  API_CONFIG,
} from "common/config";
import { ApiHome } from "apihandlers/home";
import { ApiDashboard } from "apihandlers/dashboard";
import { COMMON_JS } from "common/scripts";
import ExcelGrid from "components/Custom/ExcelGrid";

const API_HANDLER_HOME = new ApiHome();
const API_HANDLER_DASHBOARD = new ApiDashboard();

const imagePdf = require("assets/images/pdf.png");

const LessonsLearned = (props: any) => {
  const { userInfo } = props;

  const [isLoaded, setIsLoaded] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showDialog, setShowDialog] = useState(false);
  const [rows, setRows] = useState<any>([]);
  const [selectedRow, setSelectedRow] = useState<any>({});

  const [users, setUsers] = useState([]);
  const [projects, setProjects] = useState([]);
  const [disciplines, setDisciplines] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [selectedRowFiles, setSelectedRowFiles] = useState([]);
  const [responses, setResponses] = useState<any>(null);

  const [history, setHistory] = useState<any>(null);
  const [apiArgs, setApiArgs] = useState<any>({});
  const [markup, setMarkup] = useState("");
  const [historyId, setHistoryId] = useState("");
  const [htmlLoading, setHtmlLoading] = useState(false);
  const jRef = useRef(null);

  const options = {
    data: [[]],
    minDimensions: [10, 10],
  };

  const columns = [
    {
      key: "slNo",
      name: "Sl No.",
      type: "number",
      editable: false,
      sortable: true,
      searchable: true,
      width: "5%",
    },
    {
      key: "discipline",
      name: "Discipline",
      width: "20%",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "taskOwner",
      name: "Task Owner",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      width: "10%",
    },
    {
      key: "dt",
      name: "Date",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      width: "10%",
    },
    {
      key: "topic",
      name: "Topic",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      width: "20%",
    },
    {
      key: "details",
      name: "Details",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      width: "30%",
    },
    {
      key: "options",
      name: "Options",
      width: "5%",
      disableExport: true,
      renderCell: (params: any) => (
        <div className="options">
          <Tooltip title={"Edit"} placement="bottom">
            <IconButton
              className="iconBtn"
              onClick={() => onEditRow(params.row)}
            >
              <IconEdit />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (loading) {
      getUsers();
    }
  }, [users]);

  const onEditRow = (row: any) => {
    setSelectedRow(row);
    setShowDialog(true);
  };

  const getUsers = () => {
    const args = { dropdown: true };
    API_HANDLER_HOME.getUsers(args).then((res) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        getProjects(response.records);
      }
    });
  };

  const getProjects = (users = []) => {
    const args = { dropdown: true };
    API_HANDLER_HOME.getProjects(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        setUsers(users);
        setProjects(response.records);
        setLoading(false);
      }
    });
  };

  const onConfirmApprove = () => {
    setLoading(true);
    API_HANDLER_DASHBOARD.saveRisksRegister(apiArgs).then((res) => {
      if (res.status === 200) {
        COMMON_JS.showNotify(props, NOTIFY.SUCCESS, "Saved Successfully..!");
        setLoading(false);
        setApiArgs({});
        getData();
      } else {
        setLoading(false);
        COMMON_JS.showNotify(props, NOTIFY.ERROR, "Not Saved Successfully..!");
      }
    });
  };

  const getData = () => {
    const projectId = projectRef.current.selectedValue.id;
    if (!projectId) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.PROJECT_EMPTY);
      return;
    }
    const args = {
      projectId,
    };
    setRows(null);
    API_HANDLER_HOME.getProjectLessons(args).then((res: any) => {
      if (res.status === 200) {
        setRows(res.response.records);
        setIsLoaded(true);
      }
    });
  };

  const saveData = () => {
    const projectId = projectRef.current.selectedValue.id;
    const discipline = disciplineRef.current.selectedValue.label;
    const taskOwner = (
      document.getElementById("taskOwner") as HTMLInputElement
    ).value.trim();
    const details = (
      document.getElementById("details") as HTMLInputElement
    ).value.trim();
    const topic = (
      document.getElementById("topic") as HTMLInputElement
    ).value.trim();

    if (!taskOwner) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, "Please enter task owner..!");
      return;
    }
    if (!discipline) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        "Please select discipline..!"
      );
      return;
    }
    if (!topic) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, "Please enter topic..!");
      return;
    }
    if (!details) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, "Please enter details..!");
      return;
    }

    const args = {
      id: selectedRow.id || -1,
      projectId,
      taskOwner,
      discipline,
      topic,
      details,
    };
    API_HANDLER_HOME.manageProjectLesson(args).then((res) => {
      console.log("manageProjectLesson: ", args, res);
      if (res.status === 200) {
        COMMON_JS.showNotify(
          props,
          NOTIFY.SUCCESS,
          "Record saved successfully..!"
        );
        setShowDialog(false);
        getData();
      } else {
        COMMON_JS.showNotify(
          props,
          NOTIFY.ERROR,
          "Record not saved successfully..!"
        );
      }
    });
  };

  const onChangeProject = (e: any, val: any) => {
    let projectId = val.id;
    const args = { dropdown: true, projectId };
    API_HANDLER_HOME.getMainTasks(args).then((res) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        setDisciplines(response.records);
      }
    });
  };

  const getRfiResponses = (id: any) => {
    const args = { id };
    API_HANDLER_HOME.getRfiResponses(args).then((res: any) => {
      if (res.status === 200) {
        setResponses(res.response.records);
        setSelectedRowFiles(res.response.files);
      }
    });
  };

  const onChangeFile = (e: any) => {
    const projectId = projectRef.current.selectedValue.id;
    if (!projectId) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.PROJECT_EMPTY);
      return;
    }
    const file = e.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let base64: any = reader.result;
      const args: any = {
        file: {
          name: file.name,
          data: base64.replace("data:", "").replace(/^.+,/, ""),
          type: file.type,
          size: file.size,
        },
        category: "rfi",
        projectId,
      };
      setLoading(true);
      API_HANDLER_HOME.fileUpload(args).then((res: any) => {
        if (res.status === 200) {
          COMMON_JS.showNotify(props, NOTIFY.SUCCESS, SUCCESS.FILE_UPLOADED);
          let files = uploadedFiles;
          files.push(res.response);
          setUploadedFiles(files);
        } else {
          COMMON_JS.showNotify(props, NOTIFY.ERROR, "Error: " + res.error);
        }
        setLoading(false);
      });
    };
    reader.onerror = (error) => {
      console.log("onChangeFile: Error:", error);
    };
  };

  const onAddNew = () => {
    const projectId = projectRef.current.selectedValue.id;
    if (!projectId) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.PROJECT_EMPTY);
      return;
    }
    setSelectedRow({});
    setShowDialog(!showDialog);
  };

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
      </GridToolbarContainer>
    );
  };

  const saveResponse = () => {
    const response = (
      document.getElementById("response") as HTMLInputElement
    ).value.trim();
    if (!response) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, "Please enter response..!");
      return;
    }
    const args = {
      id: selectedRow.id,
      response,
      files: uploadedFiles,
    };
    API_HANDLER_HOME.manageRfiResponse(args).then((res) => {
      if (res.status === 200) {
        COMMON_JS.showNotify(
          props,
          NOTIFY.SUCCESS,
          "Response Saved Successfully..!"
        );
        setSelectedRow({});
        setUploadedFiles([]);
        setSelectedRowFiles([]);
      } else {
        COMMON_JS.showNotify(props, NOTIFY.ERROR, "Error: " + res.error);
      }
    });
  };

  const projectRef: any = useRef();
  const disciplineRef: any = useRef();

  let selectedDiscipline = null;
  if (selectedRow.discipline) {
    selectedDiscipline = disciplines.find(
      (v) => v.label === selectedRow.discipline
    );
  }

  console.log("lessons:", userInfo);

  return (
    <React.Fragment>
      <div className="pageInfo">
        <ul className="breadcrumbs">
          <li>{LABELS.HOME}</li>
          <li className="dim">{" / "}</li>
          <li className="active">{LABELS.LESSONS_LEARNED}</li>
        </ul>
        <h4>{LABELS.LESSONS_LEARNED}</h4>
      </div>

      <div id="fileUploadForm" style={{ marginTop: 15 }}>
        <div className="formGroup">
          <label>{LABELS.PROJECT_NAME}</label>
          <AutoComplete
            id={"projects"}
            list={projects}
            childRef={projectRef}
            onChange={onChangeProject}
          />
        </div>
        <div
          className="formGroup"
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: 0,
            marginBottom: 15,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              marginRight: 15,
            }}
          >
            <Btn text={LABELS.LOAD} onClick={getData} loading={loading} />
          </div>
        </div>
      </div>

      {loading ? (
        <div className="flexCenter">
          <Loader />
        </div>
      ) : (
        isLoaded && (
          <div
            id={"gridBox"}
            style={{
              width: "98%",
              height: "70%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            {rows ? (
              <ExcelGrid
                id={"lessonLearned"}
                height={window.innerHeight * 0.6}
                cols={columns}
                rows={rows}
                exportOption={true}
                newRowOption={false}
                exportName={LABELS.LESSONS_LEARNED}
                onReload={getData}
                onAdd={onAddNew}
              />
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  height: 300,
                  width: "100%",
                }}
              >
                <Loader />
              </div>
            )}
          </div>
        )
      )}

      {showDialog && (
        <UiDialog
          open={true}
          title={
            selectedRow.id
              ? "Edit: " + selectedRow.discipline
              : "Add New Lesson: "
          }
          size={DEFAULTS.DIALOG_SIZE}
          onClose={() => {
            setShowDialog(false);
          }}
        >
          <div className="formGroup">
            <label>{"Task Owner"}</label>
            <TxtBox
              placeholder={"Task Owner"}
              id={"taskOwner"}
              type={"text"}
              defaultValue={selectedRow.taskOwner || userInfo.fullName}
              disabled={true}
            />
          </div>

          <div className="formGroup">
            <label>{"Discipline"}</label>
            <AutoComplete
              id={"discipline"}
              list={disciplines}
              childRef={disciplineRef}
              defaultValue={selectedDiscipline}
            />
          </div>

          <div className="formGroup">
            <label>{"Topic"}</label>
            <TxtBox
              placeholder={"Topic"}
              id={"topic"}
              type={"text"}
              defaultValue={selectedRow.topic}
            />
          </div>

          <div className="formGroup">
            <label>{"Details"}</label>
            <TxtBox
              placeholder={"Details"}
              id={"details"}
              type={"text"}
              defaultValue={selectedRow.details}
              multiline={true}
              rows={4}
              className={"multiLine"}
            />
          </div>

          <div className="formGroup">
            <Btn text={LABELS.SAVE} onClick={saveData} />
          </div>
        </UiDialog>
      )}
    </React.Fragment>
  );
};

export default withSnackbar(LessonsLearned);
