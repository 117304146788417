import axios from "axios";
import jQuery from "jquery";

import { API_CONFIG, LOCAL_STORAGE, NOTIFY } from "common/config";
import { IApiResponse } from "common/models";

const $ = jQuery;

export const COMMON_JS = {
  getSortedObj: (arr: any, key: string) => {
    return arr.sort((a: any, b: any) => {
      if (a[key] < b[key]) {
        return -1;
      }
      if (a[key] > b[key]) {
        return 1;
      }
      return 0;
    });
  },

  showNotify: (
    props: any,
    variant: string,
    text: string,
    autoHideDuration = NOTIFY.AUTO_HIDE_DURATION
  ) => {
    props.enqueueSnackbar(text, {
      variant: variant,
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "right",
      },
      // action: <button className="snackbar-btn">{LABELS.DISMISS}</button>,
      autoHideDuration: autoHideDuration,
    });
  },

  addAlphaToColor: (color: string, opacity = 1) => {
    // coerce values so ti is between 0 and 1.
    const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
    return color + _opacity.toString(16).toUpperCase();
  },

  // Call API
  callApi: async (
    path = "/",
    method = API_CONFIG.METHODS.GET,
    args = {},
    dump = false
  ): Promise<IApiResponse> => {
    try {
      const bearerToken = localStorage[LOCAL_STORAGE.ACCESS_TOKEN] || "";
      let config = {
        timeout: API_CONFIG.TIMEOUT,
        headers: {
          Authorization: "Bearer " + bearerToken,
        },
        params: {},
      };
      console.log("API: req:", path, args);

      let result: IApiResponse = {
        status: 500,
        response: {},
        error: "",
      };
      let res = {
        data: {
          status: 500,
          response: {},
          error: "",
        },
        status: 500,
      };
      switch (method) {
        case API_CONFIG.METHODS.POST:
          res = await axios.post(API_CONFIG.API_URL + path, args, config);
          break;
        case API_CONFIG.METHODS.PUT:
          break;
        case API_CONFIG.METHODS.DELETE:
          break;
        default:
          // GET
          config.params = args;
          res = await axios.get(API_CONFIG.API_URL + path, config);
          break;
      }
      console.log("API: res:", path, res);
      if (dump) {
        return res.data;
      }

      // 403 => Redirect to login
      if (res.status === 200) {
        const { status, response, error } = res.data;
        result = { status, response, error };
        return result;
      }

      // Failed due to some other reason
      return {
        status: 500,
        response: res,
        error: "Request Failed..!",
      };
    } catch (err) {
      console.warn("API: ", err);
      if (err.response.data.status === 403) {
        // Authentication Failed
        if (window.location.pathname != "/login") {
          window.open("/login", "_self");
        }
        return {
          status: 403,
          response: {},
          error: "Authentication Failed: Please try login to continue..!",
        };
      }
      let rslt = {
        status: 500,
        response: {},
        error: err,
      };
      if (err.response) {
        if (err.response.data) {
          rslt = err.response.data;
        }
      }
      return rslt;
    }
  },

  getExportPrintCss: (win) => {
    $(win.document.body).find("h1").remove();
    $(win.document.body).find("table").attr("cellpadding", "3");
    $(win.document.body).find("table").css("font-size", "10px");
    $(win.document.body)
      .find("table")
      .css(
        "cssText",
        "border-bottom: none;border-left: none;border-right: none;"
      );
    $(win.document.body).find("td").css("font-size", "8px");
    $(win.document.body).find("th").css("font-size", "8px");
    $(win.document.body)
      .find("tbody td")
      .css("cssText", "border: 1px solid #000;border-top: 0;border-right: 0;");
    $(win.document.body)
      .find("tbody td:last-child")
      .css("cssText", "border: 1px solid #000;border-top: 0;");
    $(win.document.body)
      .find("table th")
      .css("cssText", "border: 1px solid #000;border-right: 0;");
    $(win.document.body)
      .find("table th:last-child")
      .css("cssText", "border: 1px solid #000;");
  },
};
