import React, { useState } from "react";
import { MenuList, MenuItem } from "@mui/material";

import Regulation from "components/Evaluation/Regulations";
import ContractForm from "components/Evaluation/ContractForm";
import ContractorList from "components/Evaluation/ContractorList";

import { CONFIG, LABELS } from "common/config";

import "devextreme/dist/css/dx.light.css";

const listIcon = require("assets/images/reports/list.png");
const questionnaireIcon = require("assets/images/reports/questionnaire.png");
const regulationsIcon = require("assets/images/reports/regulations-book.png");

const Evaluation = (props: any = {}) => {
  const [dashMenu, setDashMenu] = useState(LABELS.HOME);
  const { childRef, userInfo } = props;
  const setMenu = () => {
    setDashMenu(LABELS.HOME);
  };
  childRef.setMenu = setMenu;

  let menuUserLog = false;
  let consolidatedReport = false;
  if (userInfo.role.id === CONFIG.USER_ROLES.Admin) {
    menuUserLog = true;
    consolidatedReport = true;
  }

  return (
    <React.Fragment>
      {dashMenu == LABELS.HOME && (
        <div className="userDashboard">
          <div className="pageInfo">
            <ul className="breadcrumbs">
              <li>{LABELS.HOME}</li>
              <li className="dim">{" / "}</li>
              <li className="active">{LABELS.EVALUATION}</li>
            </ul>
            <h4>{LABELS.EVALUATION}</h4>
          </div>
          <MenuList dense className="dashboardMenuWrap">
            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.REGULATIONS);
              }}
            >
              <img src={regulationsIcon} />
              <h4>{"Company Regulations"}</h4>
            </MenuItem>

            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.QUESTIONNAIRE);
              }}
            >
              <img src={questionnaireIcon} />
              <h4>{"Contractor Questionnaire"}</h4>
            </MenuItem>

            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.CONTRACT_LIST);
              }}
            >
              <img src={listIcon} />
              <h4>{"Contractor List"}</h4>
            </MenuItem>
          </MenuList>
        </div>
      )}

      {dashMenu == LABELS.REGULATIONS && (
        <Regulation setDashMenu={setDashMenu} />
      )}

      {dashMenu == LABELS.QUESTIONNAIRE && (
        <ContractForm setDashMenu={setDashMenu} />
      )}

      {dashMenu == LABELS.CONTRACT_LIST && (
        <ContractorList setDashMenu={setDashMenu} />
      )}
    </React.Fragment>
  );
};

export default Evaluation;
