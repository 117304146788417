import React, { useEffect, useRef, useState } from "react";
import { withSnackbar } from "notistack";
import { IconButton } from "@mui/material";
import { Replay as IconReload, AddBox as IconAdd } from "@mui/icons-material";

import TxtBox from "components/Custom/TxtBox";
import RadioBtn from "components/Custom/RadioBtn";
import Btn from "components/Custom/Btn";
import AutoComplete from "components/Custom/AutoComplete";
import Loader from "components/Custom/Loader";

import "./styles/file.css";

import {
  COLORS,
  CONFIG,
  CONFIRMS,
  ERRORS,
  LABELS,
  NOTIFY,
  SUB_TITLES,
  SUCCESS,
  WARNINGS,
} from "common/config";
import { ApiLogin } from "apihandlers/login";
import { ApiHome } from "apihandlers/home";
import { COMMON_JS } from "common/scripts";

const API_HANDLER_HOME = new ApiHome();

const FileUploadPage = (props: any) => {
  const [loading, setLoading] = useState(true);
  const [uploading, setUploading] = useState(false);
  const [projects, setProjects] = useState([]);
  const [rows, setRows] = useState<any>(null);
  const [manageDialog, setManageDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [type, setType] = useState("actual");

  useEffect(() => {
    if (loading) {
      getProjects();
    }
  }, [loading]);

  const getProjects = () => {
    const args = { dropdown: true };
    API_HANDLER_HOME.getProjects(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        setProjects(response.records);
      }
    });
  };

  const onEditRow = (row: any) => {
    setSelectedRow(row);
    setManageDialog(true);
  };

  const manageFileUpload = () => {
    const projectId = projectRef.current.selectedValue.id;
    const uploadType = (
      document.querySelector(
        'input[name="uploadType"]:checked'
      ) as HTMLInputElement
    ).value;
    const file = (document.getElementById("uploadFile") as HTMLInputElement)
      .files[0];
    let uploadDt: any = document.getElementById("uploadDt") as HTMLInputElement;
    if (uploadDt) {
      uploadDt = uploadDt.value;
    }

    if (!projectId) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.PROJECT_EMPTY);
      return;
    }
    if (!file) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.FILE_EMPTY);
      return;
    }
    if (!uploadDt && uploadType != "holidays") {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.DATE_EMPTY);
      return;
    }

    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let base64: any = reader.result;
      const args: any = {
        projectId,
        file: {
          name: file.name,
          data: base64.replace("data:", "").replace(/^.+,/, ""),
          type: file.name.split(".")[1],
          size: file.size,
        },
        category: uploadType,
        uploadDt,
      };
      setUploading(true);
      API_HANDLER_HOME.fileUpload(args).then((res: any) => {
        console.log("manageFileUpload: ", args, res);
        if (res.status === 200) {
          let total = res.response.newCount + res.response.updateCount;
          COMMON_JS.showNotify(
            props,
            NOTIFY.SUCCESS,
            "Rows: " + total + ", " + SUCCESS.FILE_UPLOADED
          );
          (document.getElementById("uploadFile") as HTMLInputElement).value =
            "";
        } else {
          COMMON_JS.showNotify(props, NOTIFY.ERROR, "Error: " + res.error);
        }
        setUploading(false);
      });
    };
    reader.onerror = (error) => {
      console.log("uploadFile: Error:", error);
    };
  };

  const reloadGrid = () => {
    setRows(null);
  };

  const onSearch = (e) => {
    console.log("onSearch: ", e.target.value);
  };

  // const orgRef: any = useRef();
  const projectRef: any = useRef();

  let uploadTypes = [
    {
      id: "actual",
      label: "Schedule Update",
      checked: true,
    },
    {
      id: "progress",
      label: "Site Progress Upload",
      checked: false,
    },
  ];
  if (props.userInfo.role.id === CONFIG.USER_ROLES.Supervisor) {
    uploadTypes = [
      {
        id: "progress",
        label: "Site Progress Upload",
        checked: true,
      },
    ];
  }
  if (
    props.userInfo.role.id === CONFIG.USER_ROLES.Admin ||
    props.userInfo.role.id === CONFIG.USER_ROLES.Director
  ) {
    uploadTypes.push({
      id: "holidays",
      label: "Project Holidays List",
      checked: false,
    });
  }
  uploadTypes.push({
    id: "constraints",
    label: "Project Constraints",
    checked: false,
  });

  console.log("projects: ", type);

  return (
    <React.Fragment>
      <div className="pageInfo">
        <ul className="breadcrumbs">
          <li>{LABELS.HOME}</li>
          <li className="dim">{" / "}</li>
          <li className="active">{LABELS.FILE_UPLOAD}</li>
        </ul>
        <h4>{LABELS.FILE_UPLOAD}</h4>
      </div>

      <div id="fileUploadForm" style={{ marginTop: 15 }}>
        <div className="formGroup" style={{ width: "50%" }}>
          <label>{LABELS.PROJECT_NAME}</label>
          <AutoComplete id={"projects"} list={projects} childRef={projectRef} />
        </div>

        <div className="formGroup">
          <label>{LABELS.FILE_UPLOAD_TYPE}</label>
          <RadioBtn
            name={"uploadType"}
            list={uploadTypes}
            row={true}
            onChange={(val: any) => {
              setType(val);
            }}
          />
        </div>
        <div className="formGroup" style={{ width: "50%" }}>
          <label>{LABELS.SELECT_FILE}</label>
          <TxtBox
            type={"file"}
            id="uploadFile"
            className="uploadFile"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          />
        </div>
        {type !== "holidays" && (
          <div className="formGroup" style={{ width: "20%" }}>
            <label>{LABELS.SELECT_DATE}</label>
            <TxtBox type={"date"} id="uploadDt" />
          </div>
        )}
        <div
          className="formGroup"
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: 25,
            marginBottom: 30,
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <Btn
              text={LABELS.UPLOAD}
              onClick={manageFileUpload}
              loading={uploading}
            />
          </div>
        </div>
      </div>

      {rows && (
        <div className="gridOption">
          <TxtBox placeholder={LABELS.SEARCH} onKeyPress={onSearch} />
          <IconButton className="iconBtn" onClick={reloadGrid}>
            <IconReload />
          </IconButton>
          <IconButton
            className="iconBtn"
            onClick={() => {
              setManageDialog(!manageDialog);
            }}
          >
            <IconAdd />
          </IconButton>
        </div>
      )}

      {/* <div
        className="grid"
        style={{
          width: "100%",
          height: 300,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {rows ? (
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            disableColumnMenu={true}
            disableSelectionOnClick={true}
          />
        ) : (
          <Loader />
        )}
      </div> */}
    </React.Fragment>
  );
};

export default withSnackbar(FileUploadPage);
