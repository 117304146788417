import React, { useEffect, useRef, useState } from "react";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { withSnackbar } from "notistack";
import { IconButton, Tooltip } from "@mui/material";
import {
  Replay as IconReload,
  AddBox as IconAdd,
  Edit as IconEdit,
  Check as IconCheck,
  Close as IconClose,
  PictureAsPdf as IconPdf,
  Article as IconFile,
} from "@mui/icons-material";

import ExcelGrid from "components/Custom/ExcelGrid";
import TxtBox from "components/Custom/TxtBox";
import UiDialog from "components/Custom/UiDialog";
import RadioBtn from "components/Custom/RadioBtn";
import Btn from "components/Custom/Btn";
import SwitchBtn from "components/Custom/SwitchBtn";
import AutoComplete from "components/Custom/AutoComplete";
import ConfirmDialog from "components/Custom/ConfirmDialog";
import Loader from "components/Custom/Loader";
import UsersSkeleton from "./UsersSkeleton";

import {
  API_CONFIG,
  COLORS,
  CONFIG,
  ERRORS,
  LABELS,
  NOTIFY,
  SUCCESS,
  WARNINGS,
} from "common/config";
import { ApiLogin } from "apihandlers/login";
import { ApiHome } from "apihandlers/home";
import { COMMON_JS } from "common/scripts";

const fileIcon = require("assets/images/icons/file.png");
const boqIcon = require("assets/images/icons/boq.png");

const API_HANDLER_HOME = new ApiHome();

let _timer = null;

const ProjectsPage = (props: any) => {
  const [loading, setLoading] = useState(true);
  const [fileId, setFileId] = useState("");
  const [fileId2, setFileId2] = useState("");
  const [users, setUsers] = useState([]);
  const [orgs, setOrgs] = useState([]);
  const [rows, setRows] = useState<any>(null);
  const [filteredRows, setFilteredRows] = useState([]);
  const [manageDialog, setManageDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [editRows, setEditRows] = useState<any[]>(null);

  useEffect(() => {
    if (loading) {
      getUsers();
    }
  }, [loading]);

  const getUsers = () => {
    const args = { dropdown: true };
    API_HANDLER_HOME.getUsers(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        setUsers(response.records);
        getOrgs();
      }
    });
  };

  const getOrgs = () => {
    const args = {
      dropdown: true,
    };
    API_HANDLER_HOME.getOrganizations(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        setOrgs(response.records);
        getProjects();
      }
    });
  };

  const getProjects = () => {
    const args = {};
    API_HANDLER_HOME.getProjects(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        setRows(response.records);
        setLoading(false);
      }
    });
  };

  const getFile = (
    localFileId: any,
    updateFileId: any,
    fileName = "",
    mimeType = "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
  ) => {
    let fileRefId = localFileId;
    if (!fileRefId) {
      fileRefId = updateFileId;
    }
    if (!fileRefId) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.FILE_NOT_FOUND);
      return;
    }
    const args = { fileId: fileRefId };
    API_HANDLER_HOME.getFile(args).then((res: any) => {
      let base64 = "data:" + mimeType + ";base64, " + res;
      fetch(base64).then((r) => {
        r.blob().then((data) => {
          let objUrl = window.URL.createObjectURL(data);
          // window.open(objUrl, "_blank");
          let downloadLink = document.createElement("a");
          downloadLink.href = objUrl;
          downloadLink.download = fileName;
          downloadLink.click();
          window.URL.revokeObjectURL(objUrl);
        });
      });
      return;
    });
  };

  const getFilePath = (localFileId: any, updateFileId: any, fileName = "") => {
    let fileRefId = localFileId;
    if (!fileRefId) {
      fileRefId = updateFileId;
    }
    if (!fileRefId) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.FILE_NOT_FOUND);
      return;
    }
    const args = { fileId: fileRefId };
    API_HANDLER_HOME.getFilePath(args).then((res: any) => {
      if (res.status === 200) {
        window.open(API_CONFIG.API_URL + res.response.filePath, "_blank");
      }
    });
  };

  const onEditRow = (row: any) => {
    setSelectedRow(row);
    if (!row.fileId) {
      setFileId("");
    } else {
      setFileId(row.fileId);
    }
    setManageDialog(true);
  };

  const columns = [
    {
      key: "slNo",
      name: "Sl No.",
      width: "5%",
      type: "number",
      align: "center",
      headerAlign: "center",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "projId",
      name: "Project Id",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      width: "10%",
    },
    {
      key: "projectName",
      name: "Project Name",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      width: "15%",
    },
    {
      key: "frequency",
      name: LABELS.SCHEDULE_UPDATE_FREQUENCY,
      type: "text",
      width: "20%",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "orgName",
      name: "Organization",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      width: "10%",
    },
    {
      key: "supervisor",
      name: LABELS.PROJECT_MANAGER,
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      width: "15%",
    },
    {
      key: "options",
      name: "Options",
      width: "5%",
      renderCell: (params) => (
        <div className="options">
          <Tooltip title={LABELS.EDIT} placement="bottom">
            <IconButton
              className="iconBtn"
              onClick={() => onEditRow(params.row)}
            >
              <IconEdit />
            </IconButton>
          </Tooltip>
          {/* {params.row.fileId && (
            <Tooltip title={LABELS.VIEW_PROJECT_CHARTER} placement="bottom">
              <IconButton
                className="iconBtn"
                onClick={() => getFile(null, params.row.fileId)}
              >
                <IconFile />
              </IconButton>
            </Tooltip>
          )} */}
        </div>
      ),
    },
    {
      key: "pcharter",
      name: "Project Charter",
      width: "10%",
      renderCell: (params: any) => (
        <div className="options">
          {params.row.fileId && (
            <Tooltip title={LABELS.VIEW_PROJECT_CHARTER} placement="bottom">
              <IconButton
                className="iconBtn"
                onClick={() =>
                  getFile(null, params.row.fileId, params.row.fileName1)
                }
              >
                <img
                  src={fileIcon}
                  style={{
                    width: "100%",
                    height: 95,
                  }}
                />
              </IconButton>
            </Tooltip>
          )}
        </div>
      ),
    },
    {
      key: "boq",
      name: LABELS.BOQ,
      width: "10%",
      renderCell: (params: any) => {
        return (
          <div className="options">
            {params.row.fileId2 && (
              <Tooltip title={LABELS.BOQ} placement="bottom">
                <IconButton
                  className="iconBtn"
                  onClick={() =>
                    getFilePath(
                      null,
                      params.row.fileId2,
                      params.row.projectName + " - BOQ"
                    )
                  }
                >
                  <img
                    src={boqIcon}
                    style={{
                      width: "100%",
                      height: 95,
                    }}
                  />
                </IconButton>
              </Tooltip>
            )}
          </div>
        );
      },
    },
  ];

  const manageProject = () => {
    const projectName = (
      document.getElementById("projectName") as HTMLInputElement
    ).value.trim();
    const address1 = (
      document.getElementById("address1") as HTMLInputElement
    ).value.trim();
    const address2 = (
      document.getElementById("address2") as HTMLInputElement
    ).value.trim();
    const zipcode = (
      document.getElementById("zipcode") as HTMLInputElement
    ).value.trim();
    const orgId = orgRef.current.selectedValue.id;
    const supervisorId = userRef.current.selectedValue.id;
    const frequency = frequencyRef.current.selectedValue.id;

    // Validations
    if (!projectName) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.PROJECT_NAME_EMPTY);
      return;
    }
    if (!orgId) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.ORG_EMPTY);
      return;
    }
    if (!supervisorId) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.SUPERVISOR_EMPTY);
      return;
    }
    if (!address1) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.ADDRESS_IS_EMPTY);
      return;
    }
    if (!zipcode) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.ZIPCODE_IS_EMPTY);
      return;
    }

    const args = {
      projectId: selectedRow.id || -1,
      orgId,
      projectName,
      supervisorId,
      address1,
      address2,
      zipcode,
      fileId,
      fileId2,
      frequency,
    };
    API_HANDLER_HOME.manageProject(args).then((res) => {
      if (res.status === 200) {
        COMMON_JS.showNotify(props, NOTIFY.SUCCESS, SUCCESS.PROJECT_SUCCESS);
        setSelectedRow({});
        setManageDialog(false);
        onReload();
      } else {
        COMMON_JS.showNotify(props, NOTIFY.ERROR, ERRORS.PROJECT_FAILED);
      }
    });
  };

  const deleteUser = ({ id }) => {
    console.log("deleteUser: ", id);
    setRows(null);
    // getUsers();
  };

  const onReload = () => {
    setRows(null);
    getProjects();
    //setTimeout(() => {
    // }, 2000);
  };

  const onAdd = () => {
    setManageDialog(!manageDialog);
    setEditRows([]);
    setSelectedRow({});
  };

  const onSearch = (e: any) => {
    const qry = e.target.value.trim().toUpperCase();
    clearTimeout(_timer);
    _timer = setTimeout(() => {
      let flist = rows.filter((r: any) => {
        return r.projectName.toUpperCase().indexOf(qry) > -1;
      });
      console.log("flist: ", rows, flist, qry);
      setFilteredRows(flist);
    }, 150);
  };

  const onChangeFile = (e: any, category = "project") => {
    const file = e.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let base64: any = reader.result;
      let fileType = file.name.split(".")[1];
      const args: any = {
        file: {
          name: file.name,
          data: base64.replace("data:", "").replace(/^.+,/, ""),
          type: file.type,
          fileType,
          size: file.size,
        },
        category,
      };
      console.log("onChangeFile: ", args);
      if (category !== "project") {
        setFileId2(null);
      } else {
        setFileId(null);
      }
      API_HANDLER_HOME.fileUpload(args).then((res: any) => {
        if (res.status === 200) {
          if (category !== "project") {
            setFileId2(res.response.id);
          } else {
            setFileId(res.response.id);
          }
          COMMON_JS.showNotify(props, NOTIFY.SUCCESS, SUCCESS.FILE_UPLOADED);
        } else {
          if (category !== "project") {
            setFileId2("");
          } else {
            setFileId("");
          }
          COMMON_JS.showNotify(props, NOTIFY.ERROR, "Error: " + res.error);
        }
      });
    };
    reader.onerror = (error) => {
      console.log("onChangeFile: Error:", error);
    };
  };

  const orgRef: any = useRef();
  const userRef: any = useRef();
  const frequencyRef: any = useRef();

  console.log("Users: ", loading, selectedRow.active);

  return (
    <React.Fragment>
      <div className="pageInfo">
        <ul className="breadcrumbs">
          <li>{LABELS.HOME}</li>
          <li className="dim">{" / "}</li>
          <li className="active">{LABELS.PROJECTS}</li>
        </ul>
        <h4>{LABELS.PROJECTS}</h4>
        {/* <p>{SUB_TITLES.USERS}</p> */}
      </div>

      {/* {rows && (
        <div className="gridOption">
          <TxtBox placeholder={LABELS.SEARCH} onKeyPress={onSearch} />
          <IconButton className="iconBtn" onClick={onReload}>
            <IconReload />
          </IconButton>
        </div>
      )} */}

      {/* <div
        className="grid"
        style={{
          width: "98%",
          height: "80%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {rows ? (
          <DataGrid
            rows={filteredRows.length > 0 ? filteredRows : rows}
            columns={columns}
            rowHeight={120}
            // pageSize={5}
            // rowsPerPageOptions={[5]}
            disableColumnMenu={true}
            disableSelectionOnClick={true}
          />
        ) : (
          <Loader />
        )}
      </div> */}

      {rows ? (
        <ExcelGrid
          id={"projects"}
          height={window.innerHeight * 0.6}
          cols={columns}
          rows={rows}
          exportOption={true}
          // newRowOption={false}
          exportName={LABELS.EMPLOYEE_DATA}
          onReload={onReload}
          onAdd={onAdd}
        />
      ) : (
        <div className="pageCenter">
          <Loader />
        </div>
      )}

      {manageDialog && (
        <UiDialog
          title={
            selectedRow.id
              ? "Edit Project: " + selectedRow.projectName
              : "Add New Project"
          }
          open={true}
          size={"sm"}
          onClose={() => {
            setManageDialog(false);
          }}
        >
          <div className="formGroup">
            <label style={{ color: "red" }}>{LABELS.PROJECT_NAME} *</label>
            <TxtBox
              id={"projectName"}
              placeholder={LABELS.PROJECT_NAME}
              defaultValue={selectedRow.projectName}
            />
          </div>
          <div className="formGroup">
            <label>{LABELS.SCHEDULE_UPDATE_FREQUENCY}</label>
            <AutoComplete
              id={"frequency"}
              list={[
                {
                  id: "Weekly",
                  label: "Weekly",
                },
                {
                  id: "Monthly",
                  label: "Monthly",
                },
              ]}
              defaultValue={
                selectedRow.frequency
                  ? {
                      id: selectedRow.frequency,
                      label: selectedRow.frequency,
                    }
                  : {
                      id: "Monthly",
                      label: "Monthly",
                    }
              }
              childRef={frequencyRef}
            />
          </div>
          <div className="formGroup">
            <label style={{ color: "red" }}>{LABELS.ORGANIZATION} *</label>
            <AutoComplete
              id={"orgs"}
              list={orgs}
              defaultValue={
                selectedRow.orgId
                  ? {
                      id: selectedRow.orgId,
                      label: selectedRow.orgName,
                    }
                  : null
              }
              childRef={orgRef}
            />
          </div>
          <div className="formGroup">
            <label style={{ color: "red" }}>{LABELS.PROJECT_MANAGER} *</label>
            <AutoComplete
              id={"supervisor"}
              list={users}
              defaultValue={
                selectedRow.supervisorId
                  ? {
                      id: selectedRow.supervisorId,
                      label: selectedRow.supervisor,
                    }
                  : null
              }
              childRef={userRef}
            />
          </div>
          <div className="formGroup">
            <label style={{ color: "red" }}>{LABELS.ADDRESS_1} *</label>
            <TxtBox
              id={"address1"}
              placeholder={LABELS.ADDRESS_1}
              defaultValue={selectedRow.address1}
            />
          </div>
          <div className="formGroup">
            <label>{LABELS.ADDRESS_2}</label>
            <TxtBox
              id={"address2"}
              placeholder={LABELS.ADDRESS_2}
              defaultValue={selectedRow.address2}
            />
          </div>
          <div className="formGroup">
            <label style={{ color: "red" }}>{LABELS.ZIPCODE} *</label>
            <TxtBox
              id={"zipcode"}
              placeholder={LABELS.ZIPCODE}
              defaultValue={selectedRow.zipcode}
            />
          </div>
          <div className="formGroup">
            <label>
              {LABELS.PROJECT_CHARTER}
              <small>
                {" ( Max. File Size: " + CONFIG.MAX_FILE_SIZE + "MB )"}
              </small>
            </label>
            {fileId === null ? (
              <div className="uploadDiv">
                <div style={{ display: "flex", marginTop: 5 }}>
                  <Loader />
                </div>
              </div>
            ) : (
              fileId !== "" && (
                <div
                  className="uploadDiv"
                  onClick={() => getFile(fileId, selectedRow.fileId)}
                >
                  <IconFile fontSize="large" />
                </div>
              )
            )}
            <TxtBox
              id={"projectCharter"}
              type="file"
              className="uploadFile"
              accept=".doc, .docx"
              onChange={onChangeFile}
            />
          </div>
          <div className="formGroup">
            <label>
              {LABELS.BOQ}
              <small>
                {" ( Max. File Size: " + CONFIG.MAX_FILE_SIZE + "MB )"}
              </small>
            </label>
            {fileId2 === null ? (
              <div className="uploadDiv">
                <div style={{ display: "flex", marginTop: 5 }}>
                  <Loader />
                </div>
              </div>
            ) : (
              fileId2 !== "" && (
                <div className="uploadDiv">
                  <IconFile fontSize="large" />
                </div>
              )
            )}
            <TxtBox
              id={"boq"}
              type="file"
              className="uploadFile"
              accept=".pdf, .doc, .docx, .xlsx, .exf"
              onChange={(e: any) => onChangeFile(e, "projectboq")}
            />
          </div>
          <div
            className="formGroup"
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 25,
            }}
          >
            <div
              style={{
                width: "45%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Btn text={LABELS.SAVE} onClick={manageProject} />
              <Btn
                text={LABELS.CANCEL}
                bgColor={COLORS.DIM_DARK_GRAY}
                onClick={() => setManageDialog(false)}
              />
            </div>
          </div>
        </UiDialog>
      )}
    </React.Fragment>
  );
};

export default withSnackbar(ProjectsPage);
