import React, { useEffect, useRef, useState } from "react";
import { withSnackbar } from "notistack";
import { IconButton, Tooltip } from "@mui/material";
import {
  AddBox as IconAdd,
  Folder as IconFolder,
  ArrowBack as IconBack,
  Close as IconDelete,
} from "@mui/icons-material";

import Btn from "components/Custom/Btn";
import AutoComplete from "components/Custom/AutoComplete";
import Loader from "components/Custom/Loader";
import TxtBox from "components/Custom/TxtBox";
import UiDialog from "components/Custom/UiDialog";

import {
  API_CONFIG,
  CONFIG,
  DEFAULTS,
  LABELS,
  NOTIFY,
  SUCCESS,
  WARNINGS,
} from "common/config";
import { ApiHome } from "apihandlers/home";
import { ApiDashboard } from "apihandlers/dashboard";
import { COMMON_JS } from "common/scripts";

const API_HANDLER_HOME = new ApiHome();
const API_HANDLER_DASHBOARD = new ApiDashboard();

const vdoImg = require("assets/images/vdo.png");

const docImg = require("assets/images/document.png");

const ProjectStatus = (props: any) => {
  const [loading, setLoading] = useState(true);
  const [projects, setProjects] = useState([]);
  const [folders, setFolders] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [data, setData] = useState<any>(null);
  const [apiArgs, setApiArgs] = useState<any>({});
  const [selectedFolder, setSelectedFolder] = useState<any>({});
  const jRef = useRef(null);
  const options = {
    data: [[]],
    minDimensions: [10, 10],
  };

  useEffect(() => {
    if (projects.length === 0) {
      getProjects();
    }
  }, [projects]);

  const getProjects = () => {
    const args = { dropdown: true };
    API_HANDLER_HOME.getProjects(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        setProjects(response.records);
        setLoading(false);
      }
    });
  };

  const onConfirmApprove = () => {
    setLoading(true);
    API_HANDLER_DASHBOARD.saveRisksRegister(apiArgs).then((res) => {
      if (res.status === 200) {
        COMMON_JS.showNotify(props, NOTIFY.SUCCESS, "Saved Successfully..!");
        setLoading(false);
        setApiArgs({});
        getData();
      } else {
        setLoading(false);
        COMMON_JS.showNotify(props, NOTIFY.ERROR, "Not Saved Successfully..!");
      }
    });
  };

  const getData = () => {
    const projectId = projectRef.current.selectedValue.id;
    if (!projectId) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.PROJECT_EMPTY);
      return;
    }
    const args = {
      projectId,
    };
    setLoading(true);
    API_HANDLER_HOME.getProjectFolders(args).then((res: any) => {
      if (res.status === 200) {
        setFolders(res.response.records);
        setSelectedFolder({});
        setData(null);
        setLoading(false);
      }
    });
  };

  const saveData2 = () => {
    const html = document.querySelector(".ql-editor").innerHTML;
    const projectId = projectRef.current.selectedValue.id;
    if (!projectId) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.PROJECT_EMPTY);
      return;
    }
    const args = {
      projectId,
      html,
    };
    setApiArgs(args);
  };

  const onChangeFile = (e: any) => {
    const projectId = projectRef.current.selectedValue.id;
    if (!projectId) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.PROJECT_EMPTY);
      return;
    }
    const file = e.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let base64: any = reader.result;
      const args: any = {
        file: {
          name: file.name,
          data: base64.replace("data:", "").replace(/^.+,/, ""),
          type: file.type,
          size: file.size,
        },
        category: "projectstatus",
        projectId,
        folderId: selectedFolder.id,
      };
      console.log("type: ", args.file);
      if (args.file.type.indexOf("video/") > -1) {
        // Video File => Only MP4, Less than 20MB
        if (args.file.type !== "video/mp4") {
          COMMON_JS.showNotify(
            props,
            NOTIFY.WARNING,
            "Please select .mp4 video only..!"
          );
          return;
        }
        let mbSize = args.file.size / (1024 * 1024);
        if (mbSize > CONFIG.MAX_VDO_FILE_SIZE) {
          COMMON_JS.showNotify(
            props,
            NOTIFY.WARNING,
            "File size exceeded more than " + CONFIG.MAX_VDO_FILE_SIZE + "MB..!"
          );
          return;
        }
      }
      setLoading(true);
      API_HANDLER_HOME.fileUpload(args).then((res: any) => {
        if (res.status === 200) {
          COMMON_JS.showNotify(props, NOTIFY.SUCCESS, SUCCESS.FILE_UPLOADED);
          openFolder(selectedFolder);
        } else {
          COMMON_JS.showNotify(props, NOTIFY.ERROR, "Error: " + res.error);
        }
        setLoading(false);
      });
    };
    reader.onerror = (error) => {
      console.log("onChangeFile: Error:", error);
    };
  };

  const getFile = (fileId = "", mimeType = "") => {
    console.log("getFile: ", fileId, mimeType);
    const args = { fileId };
    API_HANDLER_HOME.getFile(args).then((res: any) => {
      let base64 = "data:" + mimeType + ";base64, " + res;
      fetch(base64).then((r) => {
        r.blob().then((data) => {
          let objUrl = window.URL.createObjectURL(data);
          window.open(objUrl, "_blank");
          window.URL.revokeObjectURL(objUrl);
        });
      });
    });
  };

  const saveData = () => {
    const projectId = projectRef.current.selectedValue.id;
    const folderName = (
      document.getElementById("folderName") as HTMLInputElement
    ).value.trim();

    if (!folderName) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        "Please enter folder name..!"
      );
      return;
    }

    const args = {
      id: selectedRow.id || -1,
      projectId,
      folderName,
    };
    API_HANDLER_HOME.manageFolder(args).then((res) => {
      console.log("manageFolder: ", args, res);
      if (res.status === 200) {
        COMMON_JS.showNotify(
          props,
          NOTIFY.SUCCESS,
          "Record saved successfully..!"
        );
        setShowDialog(false);
        getData();
      } else {
        COMMON_JS.showNotify(
          props,
          NOTIFY.ERROR,
          "Record not saved successfully..!"
        );
      }
    });
  };

  const openFolder = (folder: any = {}) => {
    setSelectedFolder(folder);
    const args = {
      folderId: folder.id,
    };
    setLoading(true);
    API_HANDLER_HOME.getProjectFiles(args).then((res: any) => {
      if (res.status === 200) {
        setData(res.response);
        setLoading(false);
      }
    });
  };

  const onAddNewFolder = () => {
    let projectId = projectRef.current.selectedValue.id;
    if (!projectId) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, "Please select project..!");
      return;
    }
    setShowDialog(true);
    setSelectedRow({});
  };

  const onBackPress = () => {
    setData(null);
    setSelectedFolder({});
  };

  const removeFile = (file: any) => {
    let projectId = projectRef.current.selectedValue.id;
    const args = {
      id: file.id,
      projectId,
    };
    API_HANDLER_HOME.manageFile(args).then((res) => {
      if (res.status === 200) {
        COMMON_JS.showNotify(
          props,
          NOTIFY.SUCCESS,
          "File deleted successfully..!"
        );
        openFolder(selectedFolder);
      } else {
        COMMON_JS.showNotify(
          props,
          NOTIFY.ERROR,
          "File deleted successfully..!"
        );
      }
    });
  };

  const projectRef: any = useRef();
  const htmlEditor = useRef(null);

  console.log("Status: ", data);

  return (
    <React.Fragment>
      <div className="pageInfo">
        <ul className="breadcrumbs">
          <li>{LABELS.HOME}</li>
          <li className="dim">{" / "}</li>
          <li className="active">{LABELS.PROJECT_STATUS}</li>
        </ul>
        <h4>{LABELS.PROJECT_STATUS}</h4>
      </div>

      <div id="fileUploadForm" style={{ marginTop: 15 }}>
        <div className="formGroup">
          <label>{LABELS.PROJECT_NAME}</label>
          <AutoComplete id={"projects"} list={projects} childRef={projectRef} />
        </div>
        <div
          className="formGroup"
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            marginTop: 0,
            marginBottom: 15,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              marginRight: 15,
            }}
          >
            <Btn text={LABELS.LOAD} onClick={getData} loading={loading} />
          </div>
          <IconButton onClick={onAddNewFolder}>
            <Tooltip title={LABELS.ADD} placement="bottom">
              <IconAdd />
            </Tooltip>
          </IconButton>
        </div>
      </div>

      {folders && (
        <div className="drive">
          {selectedFolder.id && (
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                marginBottom: 15,
                background: "#ebebeb",
                cursor: "pointer",
              }}
              onClick={onBackPress}
            >
              <IconButton>
                <Tooltip title={LABELS.BACK} placement="bottom">
                  <IconBack />
                </Tooltip>
              </IconButton>
              <h4 style={{ marginTop: 0, marginBottom: 0 }}>{LABELS.BACK}</h4>
              <h4
                style={{
                  marginTop: 0,
                  marginBottom: 0,
                  textAlign: "center",
                  width: "100%",
                  textTransform: "uppercase",
                }}
              >
                {selectedFolder.folderName}
              </h4>
            </div>
          )}

          {!data && (
            <div className="folders">
              {folders.map((f: any, i) => {
                return (
                  <div
                    key={"folder-" + i}
                    className="folder"
                    onClick={() => {
                      openFolder(f);
                    }}
                  >
                    <IconFolder />
                    <h4>{f.folderName}</h4>
                  </div>
                );
              })}
            </div>
          )}

          {selectedFolder.id && (
            <div style={{ width: "100%", paddingLeft: 20, marginBottom: 20 }}>
              {data && data.photos.length > 0 && (
                <React.Fragment>
                  <h4 style={{ margin: 0 }}>{"Photos :"}</h4>
                  <div className="projectFiles">
                    {data.photos.map((d: any) => {
                      return (
                        <div
                          key={"file-" + d.id}
                          style={{ position: "relative" }}
                          // onClick={() => getFile(d.id, d.mimeType)}
                        >
                          {d.mimeType.indexOf("image/") > -1 ? (
                            <>
                              <img
                                style={{ overflow: "hidden" }}
                                id={d.id}
                                src={API_CONFIG.API_URL + d.filePath}
                                width={"100%"}
                              />
                              <IconButton
                                style={{
                                  position: "absolute",
                                  right: -2,
                                  top: -4,
                                }}
                                onClick={() => removeFile(d)}
                              >
                                <Tooltip
                                  title={LABELS.DELETE}
                                  placement="bottom"
                                >
                                  <IconDelete />
                                </Tooltip>
                              </IconButton>
                              <div
                                className="viewBtn"
                                onClick={() =>
                                  window.open(
                                    API_CONFIG.API_URL + d.filePath,
                                    "_blank"
                                  )
                                }
                              >
                                View
                              </div>
                            </>
                          ) : (
                            <img src={vdoImg} width={"30%"} />
                          )}
                        </div>
                      );
                    })}
                  </div>
                </React.Fragment>
              )}

              {data && data.videos.length > 0 && (
                <React.Fragment>
                  <h4 style={{ margin: 0 }}>{"Videos :"}</h4>
                  <div className="projectFiles">
                    {data.videos.map((d: any) => {
                      return (
                        <div
                          key={"file-" + d.id}
                          style={{ position: "relative" }}
                        >
                          <>
                            <img src={vdoImg} width={"30%"} />
                            <IconButton
                              style={{
                                position: "absolute",
                                right: -2,
                                top: -4,
                              }}
                              onClick={() => removeFile(d)}
                            >
                              <Tooltip title={LABELS.DELETE} placement="bottom">
                                <IconDelete />
                              </Tooltip>
                            </IconButton>
                            <div
                              className="viewBtn"
                              onClick={() =>
                                window.open(
                                  API_CONFIG.API_URL + d.filePath,
                                  "_blank"
                                )
                              }
                            >
                              View
                            </div>
                          </>
                        </div>
                      );
                    })}
                  </div>
                </React.Fragment>
              )}

              {data && data.docs.length > 0 && (
                <React.Fragment>
                  <h4 style={{ margin: 0 }}>{"Documents :"}</h4>
                  <div className="projectFiles">
                    {data.docs.map((d: any) => {
                      return (
                        <div
                          key={"file-" + d.id}
                          style={{ position: "relative" }}
                        >
                          <>
                            <img src={docImg} width={"30%"} />
                            <IconButton
                              style={{
                                position: "absolute",
                                right: -2,
                                top: -4,
                              }}
                              onClick={() => removeFile(d)}
                            >
                              <Tooltip title={LABELS.DELETE} placement="bottom">
                                <IconDelete />
                              </Tooltip>
                            </IconButton>
                            <div
                              className="viewBtn"
                              onClick={() =>
                                window.open(
                                  API_CONFIG.API_URL + d.filePath,
                                  "_blank"
                                )
                              }
                            >
                              View
                            </div>
                          </>
                        </div>
                      );
                    })}
                  </div>
                </React.Fragment>
              )}

              {data &&
                data.photos.length === 0 &&
                data.videos.length === 0 &&
                data.docs.length === 0 && (
                  <div className="projectFiles">
                    <h4>No Files Found..!</h4>
                  </div>
                )}

              {loading ? (
                <div className="flexCenter">
                  <Loader />
                </div>
              ) : (
                <div
                  style={{
                    width: "95%",
                    marginTop: 10,
                  }}
                >
                  <label
                    style={{
                      fontWeight: "500",
                    }}
                  >
                    {"Upload File"}
                  </label>
                  <TxtBox
                    id={"dataFile"}
                    style={{ height: 50, backgroundColor: "red" }}
                    type="file"
                    className="uploadFile"
                    accept="image/*, .mp4, .pdf, .xls, .xlsx"
                    onChange={onChangeFile}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      )}

      {showDialog && (
        <UiDialog
          open={true}
          title={selectedRow.id ? "Edit Folder: " : "Add Folder: "}
          size={DEFAULTS.DIALOG_SIZE}
          onClose={() => {
            setShowDialog(false);
          }}
        >
          <div className="formGroup">
            <label>{"Folder Name"}</label>
            <TxtBox
              placeholder={"Folder Name"}
              id={"folderName"}
              type={"text"}
              defaultValue={selectedRow.folderName}
            />
          </div>

          <div className="formGroup">
            <Btn text={LABELS.ADD} onClick={saveData} />
          </div>
        </UiDialog>
      )}
    </React.Fragment>
  );
};

export default withSnackbar(ProjectStatus);
