import { IApiResponse } from "common/models";
import { API_CONFIG } from "common/config";
import { COMMON_JS } from "common/scripts";

interface IApiAudit {}

export class ApiAudit implements IApiAudit {
  manageAudit2 = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = {
        status: 200,
        response: {},
        error: "",
      };
      resObj = await COMMON_JS.callApi(
        "/v1/audit/manageaudit2",
        API_CONFIG.METHODS.POST,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };

  printAudit = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/audit/printaudit1",
        API_CONFIG.METHODS.POST,
        args,
        true
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };

  getAudits = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/audit/audit1",
        API_CONFIG.METHODS.GET,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };

  manageAudit = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = {
        status: 200,
        response: {},
        error: "",
      };
      resObj = await COMMON_JS.callApi(
        "/v1/audit/manageaudit1",
        API_CONFIG.METHODS.POST,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };
}
