import React, { useEffect, useRef, useState } from "react";
import { withSnackbar } from "notistack";
import Highcharts from "highcharts";
import { connect } from "react-redux";

import AutoComplete from "components/Custom/AutoComplete";
import Btn from "components/Custom/Btn";
import UiDialog from "components/Custom/UiDialog";
import TxtBox from "components/Custom/TxtBox";

import { CONFIG, DEFAULTS, LABELS, NOTIFY, SUCCESS } from "common/config";
import { COMMON_JS } from "common/scripts";
import { ApiHome } from "apihandlers/home";
import { CHART_ACTIONS } from "actions/ChartActions";

import "./styles/chart.css";

const API_HANDLER_HOME = new ApiHome();

const hOptions: any = {
  global: {
    useUTC: false,
  },
};
Highcharts.setOptions(hOptions);

const EditChart = (props: any) => {
  const [loading, setLoading] = useState<any>(true);
  const [chartInfoLoading, setChartInfoLoading] = useState<any>(false);
  const [users, setUsers] = useState([]);
  const { chartInfo } = props.chartReducer;

  useEffect(() => {
    if (loading) {
      getUsers();
    }
  }, [loading]);

  const getUsers = () => {
    // const orgId = orgRef.current.selectedValue.id;
    const args = { dropdown: true };
    API_HANDLER_HOME.getUsers(args).then((res) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        setUsers(response.records);
        setLoading(false);
      }
    });
  };

  const usersRef: any = useRef();

  console.log("chartInfo: ", chartInfo);

  const saveChartInfo = () => {
    const startDate = (document.getElementById("startDate") as HTMLInputElement)
      .value;
    const endDate = (document.getElementById("finishDate") as HTMLInputElement)
      .value;
    const percentage = Number(
      (document.getElementById("percentage") as HTMLInputElement).value
    );
    const qty = Number(
      (document.getElementById("qty") as HTMLInputElement).value
    );
    const unit = (document.getElementById("unit") as HTMLInputElement).value;
    const cost = Number(
      (document.getElementById("cost") as HTMLInputElement).value
    );
    const currency = (document.getElementById("currency") as HTMLInputElement)
      .value;
    const spentHrs = (document.getElementById("spentHrs") as HTMLInputElement)
      .value;

    let users = [];
    let user = usersRef.current.selectedValue.id;
    let taskOwner = usersRef.current.selectedValue.label;
    if (user) {
      users = [user];
    } else {
      users = [];
    }
    let assignUsersMenu = false;
    if (
      props.userInfo.role.id === CONFIG.USER_ROLES.Admin ||
      props.userInfo.role.id === CONFIG.USER_ROLES.Director
    ) {
      assignUsersMenu = true;
    }
    if (!assignUsersMenu) {
      users = null;
    }
    const args = {
      startDt: startDate,
      endDt: endDate,
      wbs: chartInfo.wbs,
      projectId: chartInfo.projectId,
      refId: chartInfo.refId,
      taskName: chartInfo.taskName,
      percentage,
      users,
      qty,
      unit,
      cost,
      currency,
      spentHrs,
      taskOwner,
    };
    setChartInfoLoading(true);
    API_HANDLER_HOME.editChart(args).then((res) => {
      setChartInfoLoading(false);
      if (res.status === 200) {
        props.setChartInfo({ chartInfo: {} });
        COMMON_JS.showNotify(props, NOTIFY.SUCCESS, SUCCESS.CHART_INFO_UPDATED);
        props.getChartData(props.chartType);
      } else {
        COMMON_JS.showNotify(props, NOTIFY.ERROR, "Error: " + res.error);
      }
    });
  };

  let assignUsersMenu = false;
  if (
    props.userInfo.role.id === CONFIG.USER_ROLES.Admin ||
    props.userInfo.role.id === CONFIG.USER_ROLES.Director
  ) {
    assignUsersMenu = true;
  }

  return (
    chartInfo.startDate && (
      <UiDialog
        open={true}
        title={
          LABELS.EDIT_CHART +
          ": " +
          chartInfo.taskName +
          " (" +
          chartInfo.wbs +
          ")"
        }
        size={DEFAULTS.DIALOG_SIZE}
        onClose={() => {
          props.setChartInfo({
            chartInfo: {},
          });
        }}
      >
        <div className="formGroup">
          <label>{LABELS.START_DATE}</label>
          <TxtBox
            placeholder={LABELS.START_DATE}
            id={"startDate"}
            type={"date"}
            defaultValue={chartInfo.startDate}
          />
        </div>
        <div className="formGroup">
          <label>{LABELS.FINISH_DATE}</label>
          <TxtBox
            placeholder={LABELS.FINISH_DATE}
            id={"finishDate"}
            type={"date"}
            defaultValue={chartInfo.finishDate}
          />
        </div>
        <div className="formGroup">
          <label>{LABELS.PERCENTAGE}</label>
          <TxtBox
            placeholder={LABELS.PERCENTAGE}
            id={"percentage"}
            defaultValue={chartInfo.percentage}
          />
        </div>
        {assignUsersMenu && (
          <div className="formGroup">
            <label>{LABELS.USERS}</label>
            <AutoComplete
              id={"users"}
              list={users}
              childRef={usersRef}
              multiple={false}
              defaultValue={chartInfo.users.id ? chartInfo.users : null}
            />
          </div>
        )}
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div className="formGroup" style={{ marginRight: 15 }}>
            <label>{LABELS.QTY}</label>
            <TxtBox
              placeholder={LABELS.QTY}
              id={"qty"}
              defaultValue={chartInfo.qty || ""}
            />
          </div>
          <div className="formGroup">
            <label>{LABELS.UNIT}</label>
            <TxtBox
              placeholder={LABELS.UNIT}
              id={"unit"}
              defaultValue={chartInfo.unit || ""}
            />
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div className="formGroup" style={{ marginRight: 15 }}>
            <label>{LABELS.COST}</label>
            <TxtBox
              placeholder={LABELS.COST}
              id={"cost"}
              defaultValue={chartInfo.spentCost}
            />
          </div>
          <div className="formGroup">
            <label>{LABELS.CURRENCY}</label>
            <TxtBox
              placeholder={LABELS.CURRENCY}
              id={"currency"}
              defaultValue={chartInfo.spentCostCurrency}
            />
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div className="formGroup">
            <label>{LABELS.SPENT_HRS}</label>
            <TxtBox
              placeholder={LABELS.SPENT_HRS}
              id={"spentHrs"}
              defaultValue={chartInfo.spentHours || ""}
            />
          </div>
        </div>
        <div className="formGroup">
          <Btn
            text={LABELS.SAVE}
            onClick={saveChartInfo}
            loading={chartInfoLoading}
          />
        </div>
      </UiDialog>
    )
  );
};

const mapStateToProps = (state) => {
  return {
    chartReducer: state.chartReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setChartInfo: (values: any) => {
      dispatch({
        type: CHART_ACTIONS.SET_CHART_INFO,
        values,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(EditChart));
