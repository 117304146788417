import React, { Component } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Helmet } from "react-helmet";

import LoginPage from "components/LoginPage";
import HomePage from "components/HomePage";
import Error404 from "components/Error/Error404";

import { CONFIG } from "common/config";

import "./common/styles.css";

class App extends Component {
  render() {
    const siteTitle = CONFIG.SITE_NAME + " " + CONFIG.VERSION;
    return (
      <React.Fragment>
        <Helmet>
          <title>{siteTitle}</title>
        </Helmet>
        <BrowserRouter>
          <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/" element={<HomePage />} />
            <Route element={<Error404 />} />
          </Routes>
        </BrowserRouter>
      </React.Fragment>
    );
  }
}

export default App;
