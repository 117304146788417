/*
 * Safety Monitoring & Measurement Form
 */

import React, { useRef, useEffect, useState } from "react";
import { withSnackbar } from "notistack";
import { IconButton, Tooltip } from "@mui/material";
import { Edit as IconEdit } from "@mui/icons-material";

import UiDialog from "components/Custom/UiDialog";
import ExcelGrid from "components/Custom/ExcelGrid";
import AutoComplete from "components/Custom/AutoComplete";
import SkeletonBox from "components/Custom/SkeletonBox";
import ModalBtmBtns from "components/Custom/ModalBtmBtns";
import Loader from "components/Custom/Loader";
import HGraph from "components/Custom/HGraph";
import Part1 from "./sections/Part1";
import Part2 from "./sections/Part2";
import Part3 from "./sections/Part3";
import Part4 from "./sections/Part4";
import Part5 from "./sections/Part5";
import PartLast from "./sections/PartLast";

import { LABELS, NOTIFY, SUCCESS, WARNINGS } from "common/config";
import { ApiHome } from "apihandlers/home";
import { ApiEhs } from "apihandlers/ehs";
import { COMMON_JS } from "common/scripts";
import Btn from "components/Custom/Btn";

const API_HANDLER_HOME = new ApiHome();
const API_HANDLER_EHS = new ApiEhs();

const SafetyMonitoring = (props: any) => {
  const {} = props;
  const mainGridCols = [
    // {
    //   key: "id",
    //   name: "ID",
    //   type: "number",
    //   width: "5%",
    // },
    {
      key: "slNo",
      name: "Sl No.",
      type: "number",
      width: "5%",
      editable: false,
      sortable: true,
      align: "center",
      searchable: true,
    },
    {
      key: "projectName",
      name: "Project Name",
      type: "text",
      width: "40%",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "createdOn",
      name: "Created On",
      type: "text",
      width: "20%",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "createdBy",
      name: "Created By",
      type: "text",
      width: "20%",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "options",
      name: "Options",
      type: "html",
      width: "15%",
      editable: false,
      sortable: false,
      renderCell: ({ row }: any) => (
        <div className="options">
          <Tooltip title={LABELS.EDIT} placement="bottom">
            <IconButton className="iconBtn" onClick={() => onEditRow(row)}>
              <IconEdit />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];
  const [loading, setLoading] = useState<boolean>(true);
  const [projects, setProjects] = useState<any[]>([]);
  const [cols, setCols] = useState<any[]>(mainGridCols);
  const [rows, setRows] = useState<any[]>(null);
  const [columnChart, setColumnChart] = useState<any>({});
  const [pieChart, setPieChart] = useState<any>({});
  const [manageDialog, setManageDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [editRows, setEditRows] = useState<any[]>(null);
  const [pageNo, setPageNo] = useState(1);
  const [totalPages, setTotalPages] = useState(6);
  const [formData, setFormData] = useState<any>({});
  const [uploads, setUploads] = useState<any[]>([]);

  const projectsRef: any = useRef();
  const part1Ref: any = useRef();
  const part2Ref: any = useRef();
  const part3Ref: any = useRef();
  const part4Ref: any = useRef();
  const part5Ref: any = useRef();
  const part6Ref: any = useRef();

  useEffect(() => {
    return () => {
      cleanUp();
    };
  }, []);

  useEffect(() => {
    if (loading) {
      getProjects();
    }
  }, [loading]);

  const getProjects = () => {
    const args = { dropdown: true };
    API_HANDLER_HOME.getProjects(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        setProjects(response.records);
        getRows();
      }
    });
  };

  const cleanUp = () => {
    setRows(null);
    setLoading(true);
    setManageDialog(false);
    setSelectedRow({});
    console.log("Cleaned up");
  };

  const getRows = () => {
    setRows(null);
    const args = {};
    API_HANDLER_EHS.getSafetyMonitoring(args).then((res: any) => {
      const { status, response } = res;
      if (status === 200) {
        setRows(response.records);
        setColumnChart(response.columnChart);
        setPieChart(response.pieChart);
        setLoading(false);
      }
    });
  };

  const onAdd = () => {
    const projectId = projectsRef.current.selectedValue.id;
    if (!projectId) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.PROJECT_EMPTY);
      return;
    }
    setManageDialog(!manageDialog);
    setEditRows([]);
    setPageNo(1);
    setSelectedRow({});
    setUploads([]);
  };

  const onEditRow = (row: any) => {
    setPageNo(1);
    setSelectedRow(row);
    setManageDialog(true);
    setEditRows(null);
    getRowInfo(row.id);
  };

  const getRowInfo = (safetyMonitorId) => {
    const args = {
      safetyMonitorId,
    };
    API_HANDLER_EHS.getSafetyMonitorInfo(args).then((res: any) => {
      const { status, response } = res;
      if (status === 200) {
        setUploads(response.files);
        setEditRows(response);
      }
    });
  };

  const getProjectInfo = () => {
    const projectId = projectsRef.current.selectedValue.id;
    if (!projectId) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.PROJECT_EMPTY);
      return;
    }
    const args = {
      projectId,
    };
    API_HANDLER_EHS.getSafetyMonitoring(args).then((res: any) => {
      const { status, response } = res;
      if (status === 200) {
        setColumnChart(response.columnChart);
        setPieChart(response.pieChart);
      }
    });
  };

  const manageSafetyMonitoring = () => {
    let args: any = updateForm();
    args = {
      ...args,
      safetyMonitorId: selectedRow.id || -1,
      projectId: selectedRow.id
        ? selectedRow.projectId
        : projectsRef.current.selectedValue.id,
      files: uploads,
    };

    console.log("manageSafetyMonitoring: ", args);

    // Validations
    if (!args.projectId) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.PROJECT_EMPTY);
      return;
    }
    if (
      !args.items[0].checkBox ||
      !args.items[1].checkBox ||
      !args.items[2].checkBox ||
      !args.items[3].checkBox
    ) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        "Please answer all the question of First Aid..!"
      );
      return;
    }
    if (
      !args.items[4].checkBox ||
      !args.items[5].checkBox ||
      !args.items[6].checkBox ||
      !args.items[7].checkBox ||
      !args.items[8].checkBox ||
      !args.items[9].checkBox
    ) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        "Please answer all the question of Fire Safety..!"
      );
      return;
    }
    if (
      !args.items[10].checkBox ||
      !args.items[11].checkBox ||
      !args.items[12].checkBox ||
      !args.items[13].checkBox
    ) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        "Please answer all the question of Working Areas..!"
      );
      return;
    }
    if (
      !args.items[14].checkBox ||
      !args.items[15].checkBox ||
      !args.items[16].checkBox ||
      !args.items[17].checkBox ||
      !args.items[18].checkBox
    ) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        "Please answer all the question of Electrical Safety in working Areas..!"
      );
      return;
    }
    if (
      !args.items[19].checkBox ||
      !args.items[20].checkBox ||
      !args.items[21].checkBox ||
      !args.items[22].checkBox
    ) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        "Please answer all the question of Personnel Protective Equipment..!"
      );
      return;
    }
    if (
      !args.items[23].checkBox ||
      !args.items[24].checkBox ||
      !args.items[25].checkBox ||
      !args.items[26].checkBox
    ) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        "Please answer all the question of Gas Cylinders..!"
      );
      return;
    }
    if (
      !args.items[27].checkBox ||
      !args.items[28].checkBox ||
      !args.items[29].checkBox ||
      !args.items[30].checkBox ||
      !args.items[31].checkBox ||
      !args.items[32].checkBox ||
      !args.items[33].checkBox
    ) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        "Please answer all the question of Chemicals..!"
      );
      return;
    }
    if (
      args.items[34].observation == "" ||
      args.items[34].actionRequired == "" ||
      args.items[34].recordDate == "" ||
      args.items[34].signature == ""
    ) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        "Please fill the details of Safety Monitoring Record..!"
      );
      return;
    }
    setEditRows(null);
    API_HANDLER_EHS.manageSafetyMonitoring(args).then((res) => {
      if (res.status === 200) {
        COMMON_JS.showNotify(
          props,
          NOTIFY.SUCCESS,
          SUCCESS.SAFETY_MONITOR_SAVED
        );
        setSelectedRow({});
        setManageDialog(false);
        getRows();
      } else {
        COMMON_JS.showNotify(props, NOTIFY.ERROR, res.error);
      }
    });
  };

  const updateForm = () => {
    let args: any = {
      items: [],
      files: [],
      comments: "",
    };
    if (part1Ref.saveRecord) {
      let tmp = part1Ref.saveRecord();
      if (tmp.items) {
        let tmpRows = Object.values(tmp.items);
        for (let i in tmpRows) {
          args.items.push(tmpRows[i]);
        }
      }
    }
    if (part2Ref.saveRecord) {
      let tmp = part2Ref.saveRecord();
      if (tmp.items) {
        let tmpRows = Object.values(tmp.items);
        for (let i in tmpRows) {
          args.items.push(tmpRows[i]);
        }
      }
    }
    if (part3Ref.saveRecord) {
      let tmp = part3Ref.saveRecord();
      if (tmp.items) {
        let tmpRows = Object.values(tmp.items);
        for (let i in tmpRows) {
          args.items.push(tmpRows[i]);
        }
      }
    }
    if (part4Ref.saveRecord) {
      let tmp = part4Ref.saveRecord();
      if (tmp.items) {
        let tmpRows = Object.values(tmp.items);
        for (let i in tmpRows) {
          args.items.push(tmpRows[i]);
        }
      }
    }
    if (part5Ref.saveRecord) {
      let tmp = part5Ref.saveRecord();
      if (tmp.items) {
        let tmpRows = Object.values(tmp.items);
        for (let i in tmpRows) {
          args.items.push(tmpRows[i]);
        }
      }
    }
    if (part6Ref.saveRecord) {
      let tmp = part6Ref.saveRecord();
      if (tmp.files) {
        args.files = tmp.files;
      }
      if (tmp.comments) {
        args.comments = tmp.comments;
      }
      if (tmp.items) {
        let tmpRows = Object.values(tmp.items);
        for (let i in tmpRows) {
          args.items.push(tmpRows[i]);
        }
      }
    }
    setFormData(args);
    return args;
  };

  const onReload = () => {
    getRows();
  };

  const onCloseForm = () => {
    setManageDialog(false);
  };

  console.log("formData:", formData);

  return (
    <React.Fragment>
      <div className="pageInfo">
        <ul className="breadcrumbs">
          <li>{LABELS.HOME}</li>
          <li className="dim">{" / "}</li>
          <li onClick={() => props.setDashMenu(LABELS.HOME)}>{LABELS.EHS}</li>
          <li className="dim">{" / "}</li>
          <li className="active">{LABELS.SAFETY_MONITORING}</li>
        </ul>
        <h4>{LABELS.SAFETY_MONITORING}</h4>
      </div>
      <div className="formGroup" style={{ width: "100%" }}>
        <label>{LABELS.PROJECT_NAME}</label>
        <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
          <div style={{ width: "30%", marginRight: 10 }}>
            <AutoComplete
              id={"projects"}
              list={projects}
              childRef={projectsRef}
              defaultValue={
                selectedRow.id
                  ? {
                      id: selectedRow.projectId,
                      label: selectedRow.projectName,
                    }
                  : null
              }
            />
          </div>
          <Btn text={LABELS.LOAD_GRAPH} onClick={getProjectInfo} />
        </div>
      </div>

      {/* Graphs */}
      {pieChart.series && (
        <div className="graphs">
          <HGraph
            width={"50%"}
            type={"column"}
            xAxis={columnChart.xAxis}
            yAxis={columnChart.yAxis}
            title={" "}
            exportTitle={" "}
          />
          <HGraph
            width={"50%"}
            type={"pie"}
            float={"right"}
            series={pieChart.series}
            title={" "}
            exportTitle={" "}
          />
        </div>
      )}

      {/* Master Grid */}
      {rows ? (
        <ExcelGrid
          id={"safetyMonitoring"}
          height={window.innerHeight * 0.6}
          cols={cols}
          rows={rows}
          exportOption={true}
          newRowOption={false}
          exportName={LABELS.SAFETY_MONITORING}
          onReload={onReload}
          onAdd={onAdd}
        />
      ) : (
        <div className="pageCenter">
          <Loader />
        </div>
      )}

      {/* Form Dialog Box */}
      {manageDialog && (
        <UiDialog
          title={
            selectedRow.id
              ? "Edit " +
                LABELS.SAFETY_MONITORING +
                ": " +
                selectedRow.projectName
              : "Add New " + LABELS.SAFETY_MONITORING
          }
          open={true}
          size={"xl"}
          onClose={() => {
            setManageDialog(false);
            setFormData({});
            setPageNo(1);
          }}
        >
          <>
            <div style={{ width: "100%", flexDirection: "column" }}>
              <Part1
                childRef={part1Ref}
                editRows={editRows}
                // showNotify={(variant, text) =>
                //   COMMON_JS.showNotify(props, variant, text)
                // }
              />

              <Part2 childRef={part2Ref} editRows={editRows} />

              <Part3 childRef={part3Ref} editRows={editRows} />

              <Part4 childRef={part4Ref} editRows={editRows} />

              <Part5 childRef={part5Ref} editRows={editRows} />

              <PartLast
                childRef={part6Ref}
                selectedRow={selectedRow}
                uploads={uploads}
                editRows={editRows}
                setUploads={(val) => {
                  setUploads(val);
                }}
              />

              <ModalBtmBtns
                onSave={manageSafetyMonitoring}
                onCancel={onCloseForm}
                disabled={editRows === null}
              />
            </div>
          </>
        </UiDialog>
      )}
    </React.Fragment>
  );
};

export default withSnackbar(SafetyMonitoring);
