import React, { useEffect, useRef, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { withSnackbar } from "notistack";
import { GetApp as IconExport } from "@mui/icons-material";
import Guid from "devextreme/core/guid";
import highcharts3d from "highcharts/highcharts-3d";
import HtmlEditor, {
  TableContextMenu,
  TableResizing,
  Toolbar as RtbToolbar,
  Item as RtbItem,
} from "devextreme-react/html-editor";
import DataGrid, {
  Column,
  Editing,
  Paging,
  Scrolling,
  GroupPanel,
  SearchPanel,
  Grouping,
  Toolbar,
  Item,
  Button,
  Lookup,
} from "devextreme-react/data-grid";

import { COLORS, LABELS, NOTIFY, WARNINGS } from "common/config";
import { ApiDashboard } from "apihandlers/dashboard";
import { ApiHome } from "apihandlers/home";
import { COMMON_JS } from "common/scripts";
import { IconButton, Tooltip } from "@mui/material";

const API_HANDLER_DASHBOARD = new ApiDashboard();
const API_HANDLER_HOME = new ApiHome();
highcharts3d(Highcharts);

const Part2 = (props: any) => {
  const { data, setData, childRef } = props;

  const [loading, setLoading] = useState(true);
  const [projects, setProjects] = useState([]);
  const [chartData, setChartData] = useState<any>({});
  const [markup, setMarkup] = useState("");

  let rowsList = [
    {
      ID: "1",
      slNo: "1",
      auditType: "ENVIRONMENTAL",
      auditQuestion: "Site left clean?",
    },
    {
      ID: "2",
      slNo: "2",
      auditType: "ENVIRONMENTAL",
      auditQuestion: "Potential for erosion minimised?",
    },
    {
      ID: "3",
      slNo: "3",
      auditType: "ENVIRONMENTAL",
      auditQuestion: "Gutters Clean?",
    },
    {
      ID: "4",
      slNo: "4",
      auditType: "JOB FOLDER / PAPERWORK",
      auditQuestion:
        "Certificate of Completion - Electrical Works received and signed by Site Manager?",
    },
    {
      ID: "5",
      slNo: "5",
      auditType: "JOB FOLDER / PAPERWORK",
      auditQuestion:
        "As constructed drawings completed and marked up as required",
    },
    {
      ID: "6",
      slNo: "6",
      auditType: "JOB FOLDER / PAPERWORK",
      auditQuestion:
        "Documentation as per Pre-Start meeting document list and relevant specifications",
    },
    {
      ID: "7",
      slNo: "7",
      auditType: "JOB FOLDER / PAPERWORK",
      auditQuestion:
        "**Registered Easement Documents received (If required) DNR Statutory approvals have been received (Check notes in Ellipse for confirmation)",
    },
    {
      ID: "8",
      slNo: "8",
      auditType: "JOB FOLDER / PAPERWORK",
      auditQuestion:
        "**Council Public Lighting approvals have been received. (Check notes in Ellipse for confirmation)",
    },
  ];
  if (data.checklist) {
    const checklist = data.checklist;
    for (let i in rowsList) {
      console.log("rowsList: row:", checklist, rowsList[i]["ID"]);
      if (checklist[rowsList[i]["ID"]]) {
        rowsList[i] = { ...rowsList[i], ...checklist[rowsList[i]["ID"]] };
      }
    }
  }

  const [rows, setRows] = useState<any>(rowsList);
  const [newRowPosition, setNewRowPosition] = React.useState("viewportTop");
  const [changes, setChanges] = React.useState([]);
  const [editRowKey, setEditRowKey] = React.useState(null);
  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    childRef.saveRecord = saveRecord;
    return () => {
      childRef.saveRecord = null;
    };
  }, []);

  const onAddButtonClick = React.useCallback((e: any) => {
    const key = new Guid().toString();
    setChanges([
      {
        key,
        type: "insert",
        insertAfterKey: e.row.key,
      },
    ]);
    setEditRowKey(key);
  }, []);

  const isAddButtonVisible = React.useCallback(({ row }) => !row.isEditing, []);

  const onRowInserted = React.useCallback((e: any) => {
    e.component.navigateToRow(e.key);
  }, []);

  const onSaving = React.useCallback(
    (e: any) => {
      e.cancel = true;

      console.log("onSaving: ", changes);

      let rowId = changes[0].key;
      let saveType = changes[0].type;
      let chng = changes;
      if (saveType === "update") {
        let changesRow = rows.filter((r: any) => {
          return r.ID === rowId;
        });
        if (changesRow.length > 0) {
          for (let key in changesRow[0]) {
            if (key !== "ID") {
              if (!(key in chng[0]["data"])) {
                chng[0]["data"][key] = changesRow[0][key];
              }
            }
          }
        }
      }

      console.log("onSaving: ", changes, rowId);

      let tmpRows = rows;
      let data = changes[0].data;
      if (rowId.indexOf("ID-") === -1) {
        // New Record
        let parentRowId = changes[0].insertAfterKey;
        if (!parentRowId) {
          parentRowId = changes[0].key;
        }
        // ReGenerate Rows
        let tRows = [];
        for (let indx in rows) {
          tRows.push(rows[indx]);
          if (tRows[indx]["ID"] === parentRowId) {
            if (saveType === "update") {
              for (let key in data) {
                tRows[indx][key] = data[key];
              }
            } else {
              // let empArr = employees.filter((e) => {
              //   return e.name === tRows[indx]["resource"];
              // });
              let row = {
                ID: rowId,
                // resource: empArr.length > 0 ? empArr[0].name : "",
              };
              for (let key in data) {
                row[key] = data[key];
              }
              tRows.push(row);
            }
          }
        }
        setRows(tRows);
      } else {
        // Existing Record Update
        for (let indx in rows) {
          if (tmpRows[indx]["ID"] === rowId) {
            for (let key in data) {
              tmpRows[indx][key] = data[key];
            }
            break;
          }
        }
        setRows(tmpRows);
      }
      // console.log("final: ", rows, tmpRows, rowId);
      setChanges([]);
      setEditRowKey(null);
      setHasChanges(true);
      // e.promise = saveChange(dispatch, e.changes[0]);
    },
    [changes, rows]
  );

  const saveRecord = () => {
    const args = {
      checklist: rows,
    };
    return args;
  };

  // const orgRef: any = useRef();
  const projectRef: any = useRef();
  let chartRef: any = useRef();
  const taskRef: any = useRef();
  const gridRef: any = useRef(null);
  let projectName = "";
  if (projectRef.current) {
    projectName = projectRef.current.selectedValue.label;
  }

  const htmlEditor = useRef(null);

  console.log("rows: ", rows);

  return (
    <div style={{ flexDirection: "column", display: "flex" }}>
      <div className="formGroup">
        <h4 className="formGroupName">{"UDC Construction Audit Checklist"}</h4>
        {rows && (
          <DataGrid
            id="gridContainer"
            className="resourcePlanning"
            style={{
              overflow: "auto",
            }}
            ref={gridRef}
            dataSource={rows}
            keyExpr="ID"
            showBorders={true}
            allowColumnResizing={true}
            columnMinWidth={150}
            columnAutoWidth={true}
            onRowInserted={onRowInserted}
            onSaving={onSaving}
          >
            <Scrolling columnRenderingMode="virtual" />
            <Paging enabled={false} />
            <Editing
              mode="row"
              allowAdding={true}
              allowDeleting={true}
              allowUpdating={true}
              confirmDelete={true}
              useIcons={true}
              newRowPosition={newRowPosition}
              changes={changes}
              onChangesChange={setChanges}
              editRowKey={editRowKey}
              onEditRowKeyChange={setEditRowKey}
            />
            <Column dataField="auditType" caption="" groupIndex={0} />
            <Column
              type="buttons"
              caption="Options"
              alignment={"left"}
              width={100}
              minWidth={100}
            >
              <Button
                icon="add"
                onClick={onAddButtonClick}
                visible={isAddButtonVisible}
              />
              <Button name="edit" />
              {/* <Button name="delete" /> */}
              <Button name="save" />
              <Button name="cancel" />
            </Column>
            <Column
              dataField="slNo"
              caption={"Item"}
              dataType="text"
              minWidth={100}
              width={100}
            />
            <Column
              dataField="auditQuestion"
              caption={"Audit Question"}
              dataType="text"
              minWidth={250}
              width={250}
              cssClass={"WrappedColumnClass"}
            />
            <Column
              dataField="catNo"
              caption={"Cat No."}
              dataType="text"
              minWidth={120}
              width={120}
            />
            <Column
              dataField="audit1"
              caption={"Audit 1"}
              dataType="boolean"
              minWidth={100}
              width={100}
            />
            <Column
              dataField="audit2"
              caption={"Audit 2"}
              dataType="boolean"
              minWidth={100}
              width={100}
            />
            <Column
              dataField="audit3"
              caption={"Audit 3"}
              dataType="boolean"
              minWidth={100}
              width={100}
            />
            <Column
              dataField="finalAudit"
              caption={"Final Audit"}
              dataType="boolean"
              minWidth={150}
              width={150}
            />
            <Column
              dataField="results"
              caption={"Results"}
              dataType="text"
              minWidth={120}
              width={120}
            />
            <GroupPanel visible={true} />
            <SearchPanel visible={true} />
            <Grouping autoExpandAll={true} />
          </DataGrid>
        )}
      </div>
    </div>
  );
};

export default withSnackbar(Part2);
