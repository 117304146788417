import React from "react";

interface docProps {
  file: string;
  height?: string | number;
}

const DocView = (props: docProps) => {
  const { file, height } = props;
  return (
    <div
      style={{ width: "100%", height: (height || 60) + "vh", marginBottom: 10 }}
    >
      <embed
        src={file}
        type="application/pdf"
        style={{ width: "100%", height: (height || 60) + "vh" }}
      />
    </div>
  );
};

export default DocView;
