import React, { useState } from "react";
import { withSnackbar } from "notistack";

import DocView from "components/Custom/DocView";

import { LABELS } from "common/config";

const pdfFile = require("./regulations.pdf");

const Regulation = (props: any) => {
  const [dashMenu, setDashMenu] = useState(LABELS.HOME);
  const { childRef } = props;
  const setMenu = () => {
    setDashMenu(LABELS.HOME);
    childRef.setMenu = setMenu;
  };

  return (
    <React.Fragment>
      <div className="pageInfo">
        <ul className="breadcrumbs">
          <li>{LABELS.HOME}</li>
          <li className="dim">{" / "}</li>
          <li onClick={() => props.setDashMenu(LABELS.HOME)}>
            {LABELS.EVALUATION}
          </li>
          <li className="dim">{" / "}</li>
          <li className="active">{LABELS.REGULATIONS}</li>
        </ul>
        <h4>{LABELS.REGULATIONS}</h4>
      </div>
      <div className="formRow">
        <DocView file={pdfFile} height={90} />
      </div>
    </React.Fragment>
  );
};

export default withSnackbar(Regulation);
