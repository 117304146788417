/*
 * Contractor Approval
 */

import React, { useRef, useEffect, useState } from "react";
import { withSnackbar } from "notistack";
import { IconButton, Tooltip } from "@mui/material";
import {
  Edit as IconEdit,
  Replay as IconReload,
  AddBox as IconAdd,
} from "@mui/icons-material";

import UiDialog from "components/Custom/UiDialog";
import ExcelGrid from "components/Custom/ExcelGrid";
import AutoComplete from "components/Custom/AutoComplete";
import ModalBtmBtns from "components/Custom/ModalBtmBtns";
import Loader from "components/Custom/Loader";
import HGraph from "components/Custom/HGraph";
import Part1 from "./sections/Part1";
import Part2 from "./sections/Part2";
import Part3 from "./sections/Part3";
import Part4 from "./sections/Part4";
import Part5 from "./sections/Part5";
import TxtBox from "components/Custom/TxtBox";

import { LABELS, NOTIFY, SUCCESS, WARNINGS } from "common/config";
import { ApiHome } from "apihandlers/home";
import { ApiEhs } from "apihandlers/ehs";
import { COMMON_JS } from "common/scripts";

const API_HANDLER_HOME = new ApiHome();
const API_HANDLER_EHS = new ApiEhs();

let _timer = null;

const EXCEL_GRID_ID = "contractorApproval";

const ContractorList = (props: any) => {
  const mainGridCols = [
    {
      key: "slNo",
      name: "Sl No.",
      type: "number",
      width: "5%",
      editable: false,
      sortable: true,
      align: "center",
      searchable: true,
    },
    {
      key: "applicationNo",
      name: "Application No",
      type: "text",
      width: "10%",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "applicationDt",
      name: "Application Date",
      type: "text",
      width: "10%",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "nameOfContractor",
      name: "Contractor Name",
      type: "text",
      width: "20%",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "contractorType",
      name: "Contractor Type",
      type: "text",
      width: "15%",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "inchargePerson",
      name: "Incharge Person",
      type: "text",
      width: "15%",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "phoneNo",
      name: "Phone Number",
      type: "text",
      width: "15%",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "options",
      name: "Options",
      type: "html",
      width: "10%",
      editable: false,
      sortable: false,
      renderCell: ({ row }: any) => (
        <div className="options">
          <Tooltip title={LABELS.EDIT} placement="bottom">
            <IconButton className="iconBtn" onClick={() => onEditRow(row)}>
              <IconEdit />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];
  const [loading, setLoading] = useState<boolean>(true);
  const [projects, setProjects] = useState<any[]>([]);
  const [cols, setCols] = useState<any[]>(mainGridCols);
  const [rows, setRows] = useState<any[]>(null);
  const [filteredRows, setFilteredRows] = useState([]);
  const [columnChart, setColumnChart] = useState<any>({});
  const [pieChart, setPieChart] = useState<any>({});
  const [manageDialog, setManageDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [editRows, setEditRows] = useState<any[]>(null);
  const [pageNo, setPageNo] = useState(1);
  const [totalPages, setTotalPages] = useState(6);
  const [formData, setFormData] = useState<any>({});
  const [uploads, setUploads] = useState<any[]>([]);
  const [orgList, setOrgList] = useState<any[]>([]);
  const [applicationNo, setApplicationNo] = useState("");

  const projectsRef: any = useRef();
  const contractorTypeRef: any = useRef(null);
  const part1Ref: any = useRef();
  const part2Ref: any = useRef();
  const part3Ref: any = useRef();
  const part4Ref: any = useRef();
  const part5Ref: any = useRef();
  const part6Ref: any = useRef();
  const orgsRef: any = useRef();

  useEffect(() => {
    return () => {
      cleanUp();
    };
  }, []);

  useEffect(() => {
    if (loading) {
      getOrgs();
    }
  }, [loading]);

  const getOrgs = () => {
    const args = {};
    API_HANDLER_HOME.getOrganizations(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        setOrgList(
          response.records.map((o: any) => {
            return {
              id: o.id,
              label: o.orgName,
            };
          })
        );
        getRows();
      }
    });
  };

  const cleanUp = () => {
    setRows(null);
    setLoading(true);
    setManageDialog(false);
    setSelectedRow({});
    console.log("Cleaned up");
  };

  const getRows = () => {
    setRows(null);
    const args = {};
    API_HANDLER_EHS.getContractor(args).then((res: any) => {
      const { status, response } = res;
      if (status === 200) {
        setRows(response.records);
        setColumnChart(response.columnChart);
        setPieChart(response.pieChart);
        setLoading(false);
      }
    });
  };

  const onAdd = () => {
    setManageDialog(!manageDialog);
    setEditRows([]);
    setPageNo(1);
    setSelectedRow({});
    const args = {};
    setApplicationNo("");
    API_HANDLER_EHS.getNewContractorRegNo(args).then((res: any) => {
      const { status, response } = res;
      if (status === 200) {
        setApplicationNo(response.applicationNo);
      }
    });
  };

  const onEditRow = (row: any) => {
    setApplicationNo(row.applicationNo);
    setPageNo(1);
    setSelectedRow(row);
    setManageDialog(true);
    setEditRows(null);
    getRowInfo(row.id);
  };

  const getRowInfo = (contractorId) => {
    const args = {
      contractorId,
    };
    API_HANDLER_EHS.getContractorInfo(args).then((res: any) => {
      const { status, response } = res;
      if (status === 200) {
        setEditRows(response);
      }
    });
  };

  const manageContractor = () => {
    const applicationNo = (
      document.getElementById("applicationNo") as HTMLInputElement
    ).value.trim();
    const nameOfContractor = (
      document.getElementById("nameOfContractor") as HTMLInputElement
    ).value.trim();
    const inchargePerson = (
      document.getElementById("inchargePerson") as HTMLInputElement
    ).value.trim();
    const phoneNo = (
      document.getElementById("phoneNo") as HTMLInputElement
    ).value.trim();
    const orgId = orgsRef.current.selectedValue.id;
    const contractorAddress = (
      document.getElementById("contractorAddress") as HTMLInputElement
    ).value.trim();
    const evalName = (
      document.getElementById("evalName") as HTMLInputElement
    ).value.trim();
    const comments = (
      document.getElementById("comments") as HTMLInputElement
    ).value.trim();
    const contractorType = contractorTypeRef.current.selectedValue.id;

    // Validations

    if (!contractorType) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        "Please select contractor type..!"
      );
      return;
    }

    if (!orgId) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.ORG_EMPTY);
      return;
    }
    if (!evalName) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        "Evaluator name is mandatory..!"
      );
      return;
    }
    if (!comments) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, "Comments is mandatory..!");
      return;
    }

    let args: any = updateForm();
    args = {
      ...args,
      id: selectedRow.id || -1,
      files: uploads,
      applicationNo,
      nameOfContractor,
      contractorType,
      inchargePerson,
      phoneNo,
      orgId,
      contractorAddress,
      evalName,
      comments,
    };
    console.log("manageContractor: ", args);
    API_HANDLER_EHS.manageContractor(args).then((res) => {
      if (res.status === 200) {
        COMMON_JS.showNotify(props, NOTIFY.SUCCESS, SUCCESS.RECORD_SAVED);
        setManageDialog(false);
        setEditRows([]);
        setSelectedRow({});
        getRows();
      } else {
        COMMON_JS.showNotify(props, NOTIFY.ERROR, res.error);
      }
    });
  };

  const updateForm = () => {
    let args: any = {
      items: [],
      files: [],
      comments: "",
      safetyDetails: [],
      leadershipDetails: [],
      ehsobjDetails: [],
      protectiveDetails: [],
      ramDetails: [],
    };
    if (part1Ref.saveRecord) {
      let tmp = part1Ref.saveRecord();
      if (tmp.items) {
        let tmpRows = Object.values(tmp.items);
        for (let i in tmpRows) {
          args.safetyDetails.push(tmpRows[i]);
        }
      }
    }
    if (part2Ref.saveRecord) {
      let tmp = part2Ref.saveRecord();
      if (tmp.items) {
        let tmpRows = Object.values(tmp.items);
        for (let i in tmpRows) {
          args.leadershipDetails.push(tmpRows[i]);
        }
      }
    }
    if (part3Ref.saveRecord) {
      let tmp = part3Ref.saveRecord();
      if (tmp.items) {
        let tmpRows = Object.values(tmp.items);
        for (let i in tmpRows) {
          args.ehsobjDetails.push(tmpRows[i]);
        }
      }
    }
    if (part4Ref.saveRecord) {
      let tmp = part4Ref.saveRecord();
      if (tmp.items) {
        let tmpRows = Object.values(tmp.items);
        for (let i in tmpRows) {
          args.protectiveDetails.push(tmpRows[i]);
        }
      }
    }
    if (part5Ref.saveRecord) {
      let tmp = part5Ref.saveRecord();
      if (tmp.items) {
        let tmpRows = Object.values(tmp.items);
        for (let i in tmpRows) {
          args.ramDetails.push(tmpRows[i]);
        }
      }
    }
    if (part6Ref.saveRecord) {
      let tmp = part6Ref.saveRecord();
      if (tmp.files) {
        args.files = tmp.files;
      }
      if (tmp.comments) {
        args.comments = tmp.comments;
      }
      if (tmp.items) {
        let tmpRows = Object.values(tmp.items);
        for (let i in tmpRows) {
          args.items.push(tmpRows[i]);
        }
      }
    }
    setFormData(args);
    return args;
  };

  const onReload = () => {
    getRows();
  };

  const onCloseForm = () => {
    setManageDialog(false);
  };

  const onSearch = (e: any) => {
    const qry = e.target.value.trim().toUpperCase();
    clearTimeout(_timer);
    _timer = setTimeout(() => {
      let flist = rows.filter((r: any) => {
        return r.nameOfContractor.toUpperCase().indexOf(qry) > -1;
      });
      console.log("flist: ", flist, qry, excelRef);
      if (qry === "") {
        excelRef.setData(rows);
      } else {
        excelRef.setData(flist);
      }
    }, 150);
  };

  const excelRef: any = useRef(null);
  let cachedCols = null;
  const localCache = localStorage.getItem(EXCEL_GRID_ID);
  if (localCache) {
    cachedCols = JSON.parse(localCache);
  }

  console.log("editRows:", editRows);

  return (
    <React.Fragment>
      <div className="pageInfo">
        <ul className="breadcrumbs">
          <li>{LABELS.HOME}</li>
          <li className="dim">{" / "}</li>
          <li onClick={() => props.setDashMenu(LABELS.HOME)}>
            {LABELS.EVALUATION}
          </li>
          <li className="dim">{" / "}</li>
          <li className="active">{LABELS.CONTRACT_LIST}</li>
        </ul>
        <h4>{LABELS.CONTRACT_LIST}</h4>
      </div>

      {/* Graphs */}
      {pieChart.series && (
        <div className="graphs">
          <HGraph
            width={"50%"}
            type={"bar"}
            float={"left"}
            xAxis={columnChart.xAxis}
            yAxis={columnChart.yAxis}
            title={"Progress"}
            exportTitle={"Bar Graph Record "}
            xAxisTitle=""
            yAxisTitle=""
          />
          <HGraph
            width={"50%"}
            type={"pie"}
            float={"right"}
            series={pieChart.series}
            title={" "}
            exportTitle={" "}
          />
        </div>
      )}
      {/* {rows && (
        <div
          className="gridOption"
          style={{
            width: "45%",
            marginBottom: -35,
          }}
        >
          <TxtBox placeholder={LABELS.SEARCH} onKeyPress={onSearch} />
          <Btn text={LABELS.RELOAD} marginRight={15} onClick={onReload} />
        </div>
      )} */}

      {/* Master Grid */}
      {rows ? (
        <ExcelGrid
          id={EXCEL_GRID_ID}
          cols={cols}
          cachedCols={cachedCols}
          rows={rows}
          exportOption={true}
          newRowOption={false}
          exportName={LABELS.CONTRACT_LIST}
          childRef={excelRef}
          hideUnhide={true}
          onReload={onReload}
        />
      ) : (
        <div className="pageCenter">
          <Loader />
        </div>
      )}

      {/* Form Dialog Box */}
      {manageDialog && (
        <UiDialog
          title={
            selectedRow.id
              ? "Edit " +
                LABELS.QUESTIONNAIRE +
                ": " +
                selectedRow.applicationNo
              : "Add New " + LABELS.QUESTIONNAIRE
          }
          open={true}
          size={"xl"}
          onClose={() => {
            setManageDialog(false);
            setFormData({});
            setPageNo(1);
          }}
        >
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              <div
                className="formGroup"
                style={{ width: "30%", marginRight: 20 }}
              >
                <label className="ehsHeading1 red">
                  {"Application Number * "}
                </label>
                {applicationNo ? (
                  <TxtBox
                    id={"applicationNo"}
                    disabled={true}
                    defaultValue={applicationNo}
                  />
                ) : (
                  <div className="flexCenter">
                    <Loader size={20} />
                  </div>
                )}
              </div>
              <div
                className="formGroup"
                style={{ width: "30%", marginRight: 20 }}
              >
                <label className="ehsHeading1 red">{"Contractor Name *"}</label>
                <TxtBox
                  type={"text"}
                  id="nameOfContractor"
                  defaultValue={
                    selectedRow.id ? selectedRow.nameOfContractor : ""
                  }
                  disabled={selectedRow.id ? true : false}
                />
              </div>
              <div
                className="formGroup"
                style={{ width: "30%", marginRight: 20 }}
              >
                <label className="ehsHeading1 red">{"Incharge Person *"}</label>
                <TxtBox
                  type={"text"}
                  id="inchargePerson"
                  defaultValue={
                    selectedRow.id ? selectedRow.inchargePerson : ""
                  }
                  disabled={selectedRow.id ? true : false}
                />
              </div>
              <div
                className="formGroup"
                style={{ width: "30%", marginRight: 20 }}
              >
                <label className="ehsHeading1 red">{"Phone Number *"}</label>
                <TxtBox
                  type={"text"}
                  id="phoneNo"
                  defaultValue={selectedRow.id ? selectedRow.phoneNo : ""}
                  disabled={selectedRow.id ? true : false}
                />
              </div>
              <div
                className="formGroup"
                style={{ width: "30%", marginRight: 20 }}
              >
                <label className="red">
                  {"Select " + LABELS.ORGANIZATION + " *"}
                </label>
                <AutoComplete
                  id="ogrId"
                  list={orgList}
                  childRef={orgsRef}
                  defaultValue={
                    selectedRow.id
                      ? {
                          id: selectedRow.orgId,
                          label: selectedRow.orgName,
                        }
                      : null
                  }
                  disabled={selectedRow.id ? true : false}
                />
              </div>
              <div className="formGroup" style={{ width: "30%" }}>
                <label className="red">{"Evaluator Name *"}</label>
                <TxtBox
                  type={"text"}
                  id="evalName"
                  defaultValue={selectedRow.id ? selectedRow.evalName : ""}
                />
              </div>

              <div
                className="formGroup"
                style={{ width: "30%", marginRight: 20 }}
              >
                <label className="red">{"Contractor Type *"}</label>
                <AutoComplete
                  list={[
                    {
                      id: "CSA",
                      label: "CSA",
                    },
                    {
                      id: "Electrical",
                      label: "Electrical",
                    },
                    {
                      id: "Mechanical",
                      label: "Mechanical",
                    },
                    {
                      id: "Sprinkler",
                      label: "Sprinkler",
                    },
                  ]}
                  childRef={contractorTypeRef}
                  defaultValue={
                    selectedRow.contractorType
                      ? {
                          id: selectedRow.contractorType,
                          label: selectedRow.contractorType,
                        }
                      : null
                  }
                  disabled={selectedRow.id ? true : false}
                />
              </div>
              <div
                className="formGroup"
                style={{ width: "30%", marginRight: 20 }}
              >
                <label className="ehsHeading1 red">
                  {"Contractor Address *"}
                </label>
                <TxtBox
                  type={"text"}
                  id="contractorAddress"
                  rows={3}
                  defaultValue={
                    selectedRow.id ? selectedRow.contractorAddress : ""
                  }
                  disabled={selectedRow.id ? true : false}
                />
              </div>
              <div
                className="formGroup"
                style={{ width: "30%", marginRight: 20 }}
              >
                <label className="ehsHeading1 red">{"Comments *"}</label>
                <TxtBox
                  type={"text"}
                  id="comments"
                  rows={3}
                  defaultValue={selectedRow.id ? selectedRow.comments : ""}
                />
              </div>
            </div>
            <p style={{ fontSize: 14, color: "#000", fontWeight: "500" }}>
              {
                "Note: Score 1 to 5 ( 1-Poor, 2-Satisfactory, 3- Good, 4- Very Good, 5- Excellent)"
              }
            </p>
            {editRows && (
              <div style={{ width: "100%", flexDirection: "column" }}>
                <Part1 childRef={part1Ref} editRows={editRows} />

                <Part2 childRef={part2Ref} editRows={editRows} />

                <Part3 childRef={part3Ref} editRows={editRows} />

                <Part4 childRef={part4Ref} editRows={editRows} />

                <Part5 childRef={part5Ref} editRows={editRows} />

                <ModalBtmBtns
                  onSave={manageContractor}
                  onCancel={onCloseForm}
                  disabled={editRows == null}
                />
              </div>
            )}
          </>
        </UiDialog>
      )}
    </React.Fragment>
  );
};

export default withSnackbar(ContractorList);
