import React, { useEffect, useRef, useState } from "react";
import { AppBar, Toolbar, Button, IconButton } from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { withSnackbar } from "notistack";
import { GetApp as IconExport } from "@mui/icons-material";

// Data Tables
import jQuery from "jquery";
import "datatables.net-bs4";
import "datatables.net-buttons-bs4";
import "datatables.net-buttons/js/buttons.html5";
import "datatables.net-buttons/js/buttons.print";
import "datatables.net-buttons/js/buttons.flash";
import "pdfmake/build/pdfmake";
import "pdfmake/build/vfs_fonts";

import Loader from "components/Custom/Loader";
import AutoComplete from "components/Custom/AutoComplete";
import Btn from "components/Custom/Btn";

import { LABELS, NOTIFY, WARNINGS } from "common/config";
import { ApiDashboard } from "apihandlers/dashboard";
import { ApiHome } from "apihandlers/home";
import { COMMON_JS } from "common/scripts";

import "./styles.css";

const API_HANDLER_DASHBOARD = new ApiDashboard();
const API_HANDLER_HOME = new ApiHome();

const $ = jQuery;

const ResourceTable = (props: any) => {
  const [loading, setLoading] = useState(true);
  const [projects, setProjects] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [chartData, setChartData] = useState<any>({});

  useEffect(() => {
    if (loading) {
      getProjects();
    }
  }, [loading]);

  const getProjects = () => {
    const args = { dropdown: true };
    API_HANDLER_HOME.getProjects(args).then((res) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        setProjects(response.records);
      }
      setLoading(false);
    });
  };

  const onChangeProject = (e: any, val: any) => {
    getMainTasks(val.id);
  };

  const getMainTasks = (projectId: number) => {
    const args = { projectId };
    setTasks(null);
    API_HANDLER_HOME.getMainTasks(args).then((res) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        setTasks(response.records);
      }
    });
  };

  const generateReportGrid = (res: any) => {
    let title =
      LABELS.RESOURCE_TABLE +
      " - " +
      projectRef.current.selectedValue.label.toUpperCase();
    if (taskRef.current.selectedValue.label) {
      title += " ( " + taskRef.current.selectedValue.label + " )";
    }
    $("#reportGrid").DataTable({
      dom: "Bfrtip",
      buttons: [
        // {
        //   extend: "copy",
        //   className: "reportBtn",
        //   title: title,
        // },
        // {
        //   extend: "pdf",
        //   className: "reportBtn",
        //   title: title,
        // },
        // {
        //   extend: "excel",
        //   className: "reportBtn",
        //   title: title,
        // },
        {
          extend: "csv",
          className: "reportBtn",
          title: title,
          // text: "CSV Export",
          text: "<i class='fa fa-download'></i>",
        },
        // {
        //   extend: "print",
        //   className: "reportBtn",
        //   title: title,
        //   customize: function (win) {
        //     COMMON_JS.getExportPrintCss(win);
        //   },
        // },
      ],
      paging: false,
      pageLength: 15,
      columns: res.cols,
      data: res.rows,
      order: [],
    });
  };

  const loadChart = () => {
    const projectId = projectRef.current.selectedValue.id;
    const wbs = taskRef.current.selectedValue.id;
    if (!projectId) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.PROJECT_EMPTY);
      return;
    }
    if ($.fn.dataTable.isDataTable("#reportGrid")) {
      let table = $("#reportGrid").DataTable();
      table.destroy();
    }
    if ($("#reportGrid")) {
      $("#reportGrid").empty();
    }
    const args = {
      projectId,
      wbs,
    };
    setChartData(null);
    API_HANDLER_DASHBOARD.getResourceTable(args).then((res) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        setChartData(response);
        setTimeout(() => {
          generateReportGrid(response);
        }, 500);
      } else {
        setChartData({});
        COMMON_JS.showNotify(props, NOTIFY.ERROR, res.error);
      }
    });
  };

  const projectRef: any = useRef();
  const taskRef: any = useRef();

  return (
    <React.Fragment>
      <div className="pageInfo">
        <ul className="breadcrumbs">
          <li>{LABELS.HOME}</li>
          <li className="dim">{" / "}</li>
          <li onClick={() => props.setDashMenu(LABELS.HOME)}>
            {LABELS.DASHBOARD}
          </li>
          <li className="dim">{" / "}</li>
          <li className="active">{LABELS.RESOURCE_TABLE}</li>
        </ul>
        <h4>{LABELS.RESOURCE_TABLE}</h4>
      </div>
      {loading ? (
        <div className="flexCenter" style={{ minHeight: 450 }}>
          <Loader />
        </div>
      ) : (
        <div className="hChart">
          <div id="chartForm">
            <div className="formGroup">
              <label>{LABELS.PROJECT_NAME}</label>
              <AutoComplete
                id={"projects"}
                list={projects}
                childRef={projectRef}
                disabled={!chartData}
                onChange={onChangeProject}
              />
            </div>

            {tasks ? (
              <div className="formGroup">
                <label>{LABELS.TASK_NAME}</label>
                <AutoComplete
                  id={"tasks"}
                  list={tasks}
                  childRef={taskRef}
                  disabled={!chartData}
                />
              </div>
            ) : (
              <div className="flexCenter">
                <Loader />
              </div>
            )}

            <div
              className="formGroup"
              style={{
                display: "flex",
                marginTop: 15,
                marginBottom: 20,
              }}
            >
              <div
                style={{
                  display: "flex",
                }}
              >
                <Btn
                  text={LABELS.LOAD}
                  onClick={loadChart}
                  loading={!chartData}
                />
              </div>
            </div>
          </div>

          {chartData ? (
            <div className="highchartBox">
              <div className="reportGridWrapper">
                <table
                  id="reportGrid"
                  className="mdl-data-table width100 purpleBtm"
                  cellSpacing="0"
                  cellPadding="3"
                />
              </div>
            </div>
          ) : (
            <div
              className="flexCenter"
              style={{ minHeight: 400, width: "100%" }}
            >
              <Loader />
            </div>
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default withSnackbar(ResourceTable);
