import React, { useEffect, useRef, useState } from "react";
import { withSnackbar } from "notistack";
import { GetApp as IconExport } from "@mui/icons-material";
import Guid from "devextreme/core/guid";
import HtmlEditor, {
  TableContextMenu,
  TableResizing,
  Toolbar as RtbToolbar,
  Item as RtbItem,
} from "devextreme-react/html-editor";
import DataGrid, {
  Column,
  Editing,
  Paging,
  Scrolling,
  GroupPanel,
  SearchPanel,
  Grouping,
  Toolbar,
  Item,
  Button,
  Lookup,
} from "devextreme-react/data-grid";
import { IconButton, Tooltip } from "@mui/material";

import Loader from "components/Custom/Loader";
import AutoComplete from "components/Custom/AutoComplete";
import Btn from "components/Custom/Btn";

import { COLORS, LABELS, NOTIFY, WARNINGS } from "common/config";
import { ApiDashboard } from "apihandlers/dashboard";
import { ApiHome } from "apihandlers/home";
import { COMMON_JS } from "common/scripts";

const API_HANDLER_DASHBOARD = new ApiDashboard();
const API_HANDLER_HOME = new ApiHome();

const Part1 = (props: any) => {
  const { data, setData, childRef, projects, selectedRow } = props;
  const [loading, setLoading] = useState(true);
  const [chartData, setChartData] = useState<any>({});
  const [markup, setMarkup] = useState(data.notes || "");

  // const [rows, setRows] = useState(null);
  const [rows, setRows] = useState<any>(data.actionPlan || []);
  const [newRowPosition, setNewRowPosition] = React.useState("viewportTop");
  const [changes, setChanges] = React.useState([]);
  const [editRowKey, setEditRowKey] = React.useState(null);
  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    childRef.saveRecord = saveRecord;
    return () => {
      childRef.saveRecord = null;
    };
  }, []);

  const onAddButtonClick = React.useCallback((e: any) => {
    const key = new Guid().toString();
    setChanges([
      {
        key,
        type: "insert",
        insertAfterKey: e.row.key,
      },
    ]);
    setEditRowKey(key);
  }, []);

  const isAddButtonVisible = React.useCallback(({ row }) => !row.isEditing, []);

  const onRowInserted = React.useCallback((e: any) => {
    e.component.navigateToRow(e.key);
  }, []);

  const onSaving = React.useCallback(
    (e: any) => {
      e.cancel = true;

      console.log("onSaving: ", changes);

      let rowId = changes[0].key;
      let saveType = changes[0].type;
      let chng = changes;
      if (saveType === "update") {
        let changesRow = rows.filter((r: any) => {
          return r.ID === rowId;
        });
        if (changesRow.length > 0) {
          for (let key in changesRow[0]) {
            if (key !== "ID") {
              if (!(key in chng[0]["data"])) {
                chng[0]["data"][key] = changesRow[0][key];
              }
            }
          }
        }
      }

      console.log("onSaving: ", changes, rowId);

      let tmpRows: any = rows;
      let data = changes[0].data;
      if (saveType === "insert") {
        data.ID = rowId;
        tmpRows.push(data);
      }
      if (rowId.indexOf("ID-") === -1) {
        // New Record
        let parentRowId = changes[0].insertAfterKey;
        if (!parentRowId) {
          parentRowId = changes[0].key;
        }
        // ReGenerate Rows
        let tRows = [];
        for (let indx in rows) {
          tRows.push(rows[indx]);
          // if (tRows[indx]["ID"] === parentRowId) {
          //   if (saveType === "update") {
          //     for (let key in data) {
          //       tRows[indx][key] = data[key];
          //     }
          //   } else {
          //     // let empArr = employees.filter((e) => {
          //     //   return e.name === tRows[indx]["resource"];
          //     // });
          //     let row = {
          //       ID: rowId,
          //       // resource: empArr.length > 0 ? empArr[0].name : "",
          //     };
          //     for (let key in data) {
          //       row[key] = data[key];
          //     }
          //     tRows.push(row);
          //   }
          // }
        }
        setRows(tRows);
      } else {
        // Existing Record Update
        for (let indx in rows) {
          if (tmpRows[indx]["ID"] === rowId) {
            for (let key in data) {
              tmpRows[indx][key] = data[key];
            }
            break;
          }
        }
        setRows(tmpRows);
      }
      // console.log("final: ", rows, tmpRows, rowId);
      setChanges([]);
      setEditRowKey(null);
      setHasChanges(true);
      // e.promise = saveChange(dispatch, e.changes[0]);
    },
    [changes, rows]
  );

  const saveRecord = () => {
    const projectId = projectRef.current.selectedValue.id;
    if (!projectId) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        "Please select the project..!"
      );
      return false;
    }
    const args = {
      notes: document.querySelector("#rtbEditor1 .ql-editor").innerHTML,
      actionPlan: rows,
      projectId,
    };
    return args;
  };

  const projectRef: any = useRef();
  const gridRef: any = useRef(null);
  const htmlEditor = useRef(null);
  let selectedProject: any = null;
  if (selectedRow.projectId) {
    selectedProject = {
      id: selectedRow.projectId,
      label: selectedRow.projectName,
    };
  }

  console.log("part1: ", rows);

  return (
    <div style={{ flexDirection: "column", display: "flex" }}>
      <div className="formGroup" style={{ width: "45%" }}>
        <label>{LABELS.PROJECT_NAME}</label>
        <AutoComplete
          id={"projects"}
          list={projects}
          childRef={projectRef}
          defaultValue={selectedProject}
        />
      </div>
      <div className="formGroup">
        <h4 className="formGroupName">{"Audit Notes"}</h4>
        <HtmlEditor id="rtbEditor1" ref={htmlEditor} defaultValue={markup}>
          <TableContextMenu enabled={true} />
          <TableResizing enabled={true} />
          <RtbToolbar>
            <RtbItem name="undo" />
            <RtbItem name="redo" />
            <RtbItem name="bold" />
            <RtbItem name="italic" />
            <RtbItem name="strike" />
            <RtbItem name="underline" />
            <RtbItem name="color" />
            <RtbItem name="background" />
            <RtbItem name="separator" />
            <RtbItem
              name="size"
              acceptedValues={[
                "8pt",
                "10pt",
                "12pt",
                "14pt",
                "18pt",
                "24pt",
                "36pt",
              ]}
            />
            <RtbItem name="alignLeft" />
            <RtbItem name="alignCenter" />
            <RtbItem name="alignRight" />
            <RtbItem name="alignJustify" />
            <RtbItem name="separator" />
            <RtbItem name="orderedList" />
            <RtbItem name="bulletList" />
            <RtbItem name="separator" />
            <RtbItem name="insertTable" />
            <RtbItem name="insertHeaderRow" />
            <RtbItem name="insertRowAbove" />
            <RtbItem name="insertRowBelow" />
            <RtbItem name="separator" />
            <RtbItem name="insertColumnLeft" />
            <RtbItem name="insertColumnRight" />
            <RtbItem name="separator" />
            <RtbItem name="deleteColumn" />
            <RtbItem name="deleteRow" />
            <RtbItem name="deleteTable" />
            <RtbItem name="separator" />
            <RtbItem name="cellProperties" />
            <RtbItem name="tableProperties" />
          </RtbToolbar>
        </HtmlEditor>
      </div>
      <div className="formGroup">
        <h4 className="formGroupName">{"Action Plan"}</h4>
        {rows && (
          <DataGrid
            id="gridContainer"
            className="resourcePlanning"
            style={{
              overflow: "auto",
            }}
            ref={gridRef}
            dataSource={rows}
            keyExpr="ID"
            showBorders={true}
            allowColumnResizing={true}
            columnMinWidth={150}
            columnAutoWidth={true}
            onRowInserted={onRowInserted}
            onSaving={onSaving}
          >
            <Scrolling columnRenderingMode="virtual" />
            <Paging enabled={false} />
            <Editing
              mode="row"
              allowAdding={true}
              allowDeleting={true}
              allowUpdating={true}
              confirmDelete={true}
              useIcons={true}
              newRowPosition={newRowPosition}
              changes={changes}
              onChangesChange={setChanges}
              editRowKey={editRowKey}
              onEditRowKeyChange={setEditRowKey}
            />
            <Column
              type="buttons"
              caption="Options"
              alignment={"left"}
              width={80}
            >
              <Button
                icon="add"
                onClick={onAddButtonClick}
                visible={isAddButtonVisible}
              />
              <Button name="edit" />
              {/* <Button name="delete" /> */}
              <Button name="save" />
              <Button name="cancel" />
            </Column>

            {/* <Column
              dataField="classifications"
              caption="Hazard Classification"
              width={200}
            >
              <Lookup
                dataSource={[
                  {
                    id: "Fire",
                    name: "Fire",
                  },
                  {
                    id: "Falling",
                    name: "Falling",
                  },
                  {
                    id: "Chemical Sustance",
                    name: "Chemical Sustance",
                  },
                  {
                    id: "Electricity",
                    name: "Electricity",
                  },
                  {
                    id: "Noise",
                    name: "Noise",
                  },
                  {
                    id: "Chemical Sustance",
                    name: "Chemical Sustance",
                  },
                  {
                    id: "Electricity",
                    name: "Electricity",
                  },
                  {
                    id: "Noise",
                    name: "Noise",
                  },
                ]}
                displayExpr="name"
                valueExpr="id"
              />
            </Column> */}

            <Column
              dataField="slNo"
              caption={"No."}
              dataType="text"
              minWidth={100}
              width={100}
            />
            <Column
              dataField="action"
              caption={"Action"}
              dataType="text"
              minWidth={200}
              width={200}
            />
            <Column
              dataField="responsibility"
              caption={"responsibility"}
              dataType="text"
              minWidth={200}
              width={200}
            />
            <Column
              dataField="targetDate"
              caption={"Target Date"}
              dataType="date"
              minWidth={200}
              width={200}
              format={"dd/MM/yyyy"}
            />
            <Column
              dataField="signOn"
              caption={"Sign On Completion"}
              dataType="text"
              minWidth={250}
              width={250}
            />
            <GroupPanel visible={true} />
            <SearchPanel visible={true} />
            <Grouping autoExpandAll={true} />
          </DataGrid>
        )}
      </div>
    </div>
  );
};

export default withSnackbar(Part1);
