import React, { useRef } from "react";
import HtmlEditor, {
  TableContextMenu,
  TableResizing,
  Toolbar as RtbToolbar,
  Item as RtbItem,
} from "devextreme-react/html-editor";

import { COLORS } from "common/config";

const RichTxtBox = (props: any) => {
  const htmlEditor = useRef(null);
  const { id, defaultValue } = props;

  return (
    <HtmlEditor
      id={id}
      ref={htmlEditor}
      defaultValue={defaultValue}
      valueType="html"
    >
      <TableContextMenu enabled={true} />
      <TableResizing enabled={true} />
      <RtbToolbar>
        <RtbItem name="undo" />
        <RtbItem name="redo" />
        <RtbItem name="bold" />
        <RtbItem name="italic" />
        {/* <RtbItem name="strike" /> */}
        <RtbItem name="underline" />
        {/* <RtbItem name="color" />
        <RtbItem name="background" /> */}
        <RtbItem name="separator" />
        {/* <RtbItem
          name="size"
          acceptedValues={[
            "8pt",
            "10pt",
            "12pt",
            "14pt",
            "18pt",
            "24pt",
            "36pt",
          ]}
        /> */}
        <RtbItem name="alignLeft" />
        <RtbItem name="alignCenter" />
        <RtbItem name="alignRight" />
        <RtbItem name="alignJustify" />
        <RtbItem name="separator" />
        <RtbItem name="orderedList" />
        <RtbItem name="bulletList" />
        {/* <RtbItem name="separator" />
        <RtbItem name="insertTable" />
        <RtbItem name="insertHeaderRow" />
        <RtbItem name="insertRowAbove" />
        <RtbItem name="insertRowBelow" />
        <RtbItem name="separator" />
        <RtbItem name="insertColumnLeft" />
        <RtbItem name="insertColumnRight" />
        <RtbItem name="separator" />
        <RtbItem name="deleteColumn" />
        <RtbItem name="deleteRow" />
        <RtbItem name="deleteTable" />
        <RtbItem name="separator" />
        <RtbItem name="cellProperties" />
        <RtbItem name="tableProperties" /> */}
      </RtbToolbar>
    </HtmlEditor>
  );
};

export default RichTxtBox;
