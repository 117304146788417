/*
 * Contractor Form
 */

import React, {
  useRef,
  useEffect,
  useState,
  useContext,
  createContext,
  useMemo,
} from "react";
import { withSnackbar } from "notistack";

import UiDialog from "components/Custom/UiDialog";
import ExcelGrid from "components/Custom/ExcelGrid";
import AutoComplete from "components/Custom/AutoComplete";
import ModalBtmBtns from "components/Custom/ModalBtmBtns";
import Loader from "components/Custom/Loader";
import HGraph from "components/Custom/HGraph";
import Part1 from "./sections/Part1";
import Part2 from "./sections/Part2";
import Part3 from "./sections/Part3";
import Part4 from "./sections/Part4";
import Part5 from "./sections/Part5";
import TxtBox from "components/Custom/TxtBox";

import { LABELS, NOTIFY, SUCCESS, WARNINGS } from "common/config";
import { ApiHome } from "apihandlers/home";
import { ApiEhs } from "apihandlers/ehs";
import { COMMON_JS } from "common/scripts";

const API_HANDLER_HOME = new ApiHome();
const API_HANDLER_EHS = new ApiEhs();

const ContractForm = (props: any) => {
  const mainGridCols = [
    {
      key: "slNo",
      name: "Sl No.",
      type: "number",
      width: "5%",
      align: "center",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "applicationNo",
      name: "Application No",
      type: "text",
      width: "15%",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "applicationDt",
      name: "Application Date",
      type: "text",
      width: "15%",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "nameOfContractor",
      name: "Contractor Name",
      type: "text",
      width: "20%",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "contractorType",
      name: "Contractor Type",
      type: "text",
      width: "15%",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "inchargePerson",
      name: "Incharge Person",
      type: "text",
      width: "15%",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "phoneNo",
      name: "Phone Number",
      type: "text",
      width: "15%",
      editable: false,
      sortable: true,
      searchable: true,
    },
  ];
  const [loading, setLoading] = useState<boolean>(true);
  const [cols, setCols] = useState<any[]>(mainGridCols);
  const [rows, setRows] = useState<any[]>(null);
  const [columnChart, setColumnChart] = useState<any>({});
  const [pieChart, setPieChart] = useState<any>({});
  const [manageDialog, setManageDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [editRows, setEditRows] = useState<any[]>(null);
  const [pageNo, setPageNo] = useState(1);
  const [totalPages, setTotalPages] = useState(6);
  const [formData, setFormData] = useState<any>({});
  const [uploads, setUploads] = useState<any[]>([]);
  const [orgList, setOrgList] = useState<any[]>([]);
  const [applicationNo, setApplicationNo] = useState("");

  const projectsRef: any = useRef();
  const contractorTypeRef: any = useRef(null);
  const part1Ref: any = useRef();
  const part2Ref: any = useRef();
  const part3Ref: any = useRef();
  const part4Ref: any = useRef();
  const part5Ref: any = useRef();
  const part6Ref: any = useRef();
  const orgsRef: any = useRef();

  useEffect(() => {
    return () => {
      cleanUp();
    };
  }, []);

  useEffect(() => {
    if (loading) {
      getOrgs();
    }
  }, [loading]);

  const getOrgs = () => {
    const args = {};
    API_HANDLER_HOME.getOrganizations(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        setOrgList(
          response.records.map((o: any) => {
            return {
              id: o.id,
              label: o.orgName,
            };
          })
        );
        getRows();
      }
    });
  };

  const cleanUp = () => {
    setRows(null);
    setLoading(true);
    setManageDialog(false);
    setSelectedRow({});
    console.log("Cleaned up");
  };

  const getRows = () => {
    setRows(null);
    const args = {};
    API_HANDLER_EHS.getContractor(args).then((res: any) => {
      const { status, response } = res;
      if (status === 200) {
        setRows(
          response.records.map((r, i) => {
            r.isActive = i % 2 === 0 ? "true" : "false";
            return r;
          })
        );
        setColumnChart(response.columnChart);
        setPieChart(response.pieChart);
        setLoading(false);
      }
    });
  };

  const onAdd = () => {
    setManageDialog(!manageDialog);
    setEditRows([]);
    setPageNo(1);
    setSelectedRow({});
    const args = {};
    setApplicationNo("");
    API_HANDLER_EHS.getNewContractorRegNo(args).then((res: any) => {
      const { status, response } = res;
      if (status === 200) {
        setApplicationNo(response.applicationNo);
      }
    });
  };

  const onEditRow = (row: any) => {
    setPageNo(1);
    setSelectedRow(row);
    setManageDialog(true);
    setEditRows(null);
    getRowInfo(row.id);
  };

  const getRowInfo = (contractorId) => {
    const args = {
      contractorId,
    };
    API_HANDLER_EHS.getContractorInfo(args).then((res: any) => {
      const { status, response } = res;
      if (status === 200) {
        setEditRows(response);
      }
    });
  };

  const manageContractor = () => {
    const applicationNo = (
      document.getElementById("applicationNo") as HTMLInputElement
    ).value.trim();
    const nameOfContractor = (
      document.getElementById("nameOfContractor") as HTMLInputElement
    ).value.trim();
    const inchargePerson = (
      document.getElementById("inchargePerson") as HTMLInputElement
    ).value.trim();
    const phoneNo = (
      document.getElementById("phoneNo") as HTMLInputElement
    ).value.trim();
    const orgId = orgsRef.current.selectedValue.id;
    const contractorAddress = (
      document.getElementById("contractorAddress") as HTMLInputElement
    ).value.trim();
    const contractorType = contractorTypeRef.current.selectedValue.id;

    let args: any = updateForm();
    args = {
      ...args,
      id: selectedRow.id || -1,
      files: uploads,
      applicationNo,
      nameOfContractor,
      inchargePerson,
      contractorType,
      phoneNo,
      orgId,
      contractorAddress,
    };

    console.log("manageContractor: ", args);
    // Validations

    if (!contractorType) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        "Please select contractor type..!"
      );
      return;
    }

    if (!args.orgId) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.ORG_EMPTY);
      return;
    }
    if (!args.nameOfContractor) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        WARNINGS.CONTRACTOR_NAME_EMPTY
      );
      return;
    }
    if (!args.contractorAddress) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.ADDRESS_EMPTY);
      return;
    }
    if (!args.inchargePerson) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.INCHARGE_IS_EMPTY);
      return;
    }
    if (!args.phoneNo) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.PHONE_EMPTY);
      return;
    }
    if (
      !args.safetyDetails[0].checkBoxYes &&
      !args.safetyDetails[0].checkBoxNo
    ) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        "Please answer Question No.(1.1) ..!"
      );
      return;
    }
    if (
      !args.safetyDetails[1].checkBoxYes &&
      !args.safetyDetails[1].checkBoxNo
    ) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        "Please answer Question No.(1.2) ..!"
      );
      return;
    }
    if (
      !args.safetyDetails[2].checkBoxYes &&
      !args.safetyDetails[2].checkBoxNo
    ) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        "Please answer Question No.(1.3) ..!"
      );
      return;
    }
    if (
      !args.safetyDetails[3].checkBoxYes &&
      !args.safetyDetails[3].checkBoxNo
    ) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        "Please answer Question No.(1.4) ..!"
      );
      return;
    }
    if (
      !args.safetyDetails[4].checkBoxYes &&
      !args.safetyDetails[4].checkBoxNo
    ) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        "Please answer Question No.(2.1) ..!"
      );
      return;
    }
    if (
      !args.safetyDetails[5].checkBoxYes &&
      !args.safetyDetails[5].checkBoxNo
    ) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        "Please answer Question No.(2.2) ..!"
      );
      return;
    }
    if (
      !args.safetyDetails[6].checkBoxYes &&
      !args.safetyDetails[6].checkBoxNo
    ) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        "Please answer Question No.(2.3) ..!"
      );
      return;
    }
    if (
      !args.leadershipDetails[0].checkBoxYes &&
      !args.leadershipDetails[0].checkBoxNo
    ) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        "Please answer Question No.(3.1) ..!"
      );
      return;
    }
    if (
      !args.leadershipDetails[1].checkBoxYes &&
      !args.leadershipDetails[1].checkBoxNo
    ) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        "Please answer Question No.(3.2) ..!"
      );
      return;
    }
    if (
      !args.leadershipDetails[2].checkBoxYes &&
      !args.leadershipDetails[2].checkBoxNo &&
      !args.leadershipDetails[2].comments
    ) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        "Please answer Question No.(4.1) ..!"
      );
      return;
    }
    if (
      !args.ehsobjDetails[0].checkBoxYes &&
      !args.ehsobjDetails[0].checkBoxNo
    ) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        "Please answer Question No.(5.1) ..!"
      );
      return;
    }
    if (
      !args.ehsobjDetails[1].checkBoxYes &&
      !args.ehsobjDetails[1].checkBoxNo
    ) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        "Please answer Question No.(5.2) ..!"
      );
      return;
    }
    if (
      !args.ehsobjDetails[2].checkBoxYes &&
      !args.ehsobjDetails[2].checkBoxNo
    ) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        "Please answer Question No.(6.1) ..!"
      );
      return;
    }
    if (
      !args.ehsobjDetails[3].checkBoxYes &&
      !args.ehsobjDetails[3].checkBoxNo
    ) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        "Please answer Question No.(6.2) ..!"
      );
      return;
    }
    if (
      !args.protectiveDetails[0].checkBoxYes &&
      !args.protectiveDetails[0].checkBoxNo
    ) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        "Please answer Question No.(7.1) ..!"
      );
      return;
    }
    if (
      !args.protectiveDetails[1].checkBoxYes &&
      !args.protectiveDetails[1].checkBoxNo
    ) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        "Please answer Question No.(8.1) ..!"
      );
      return;
    }
    if (
      !args.protectiveDetails[2].checkBoxYes &&
      !args.protectiveDetails[2].checkBoxNo
    ) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        "Please answer Question No.(9.1) ..!"
      );
      return;
    }
    if (
      !args.protectiveDetails[3].checkBoxYes &&
      !args.protectiveDetails[3].checkBoxNo
    ) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        "Please answer Question No.(9.2) ..!"
      );
      return;
    }
    if (!args.ramDetails[0].checkBoxYes && !args.ramDetails[0].checkBoxNo) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        "Please answer Question No.(10.1) ..!"
      );
      return;
    }
    if (!args.ramDetails[1].checkBoxYes && !args.ramDetails[1].checkBoxNo) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        "Please answer Question No.(10.2) ..!"
      );
      return;
    }
    if (!args.ramDetails[2].checkBoxYes && !args.ramDetails[2].checkBoxNo) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        "Please answer Question No.(11.1) ..!"
      );
      return;
    }
    setEditRows(null);
    API_HANDLER_EHS.manageContractor(args).then((res) => {
      setEditRows([]);
      if (res.status === 200) {
        COMMON_JS.showNotify(props, NOTIFY.SUCCESS, SUCCESS.RECORD_SAVED);
        setSelectedRow({});
        setManageDialog(false);
        getRows();
      } else {
        COMMON_JS.showNotify(props, NOTIFY.ERROR, res.error);
      }
    });
  };

  const updateForm = () => {
    let args: any = {
      items: [],
      files: [],
      comments: "",
      safetyDetails: [],
      leadershipDetails: [],
      ehsobjDetails: [],
      protectiveDetails: [],
      ramDetails: [],
    };
    if (part1Ref.saveRecord) {
      let tmp = part1Ref.saveRecord();
      if (tmp.items) {
        let tmpRows = Object.values(tmp.items);
        for (let i in tmpRows) {
          args.safetyDetails.push(tmpRows[i]);
        }
      }
    }
    if (part2Ref.saveRecord) {
      let tmp = part2Ref.saveRecord();
      if (tmp.items) {
        let tmpRows = Object.values(tmp.items);
        for (let i in tmpRows) {
          args.leadershipDetails.push(tmpRows[i]);
        }
      }
    }
    if (part3Ref.saveRecord) {
      let tmp = part3Ref.saveRecord();
      if (tmp.items) {
        let tmpRows = Object.values(tmp.items);
        for (let i in tmpRows) {
          args.ehsobjDetails.push(tmpRows[i]);
        }
      }
    }
    if (part4Ref.saveRecord) {
      let tmp = part4Ref.saveRecord();
      if (tmp.items) {
        let tmpRows = Object.values(tmp.items);
        for (let i in tmpRows) {
          args.protectiveDetails.push(tmpRows[i]);
        }
      }
    }
    if (part5Ref.saveRecord) {
      let tmp = part5Ref.saveRecord();
      if (tmp.items) {
        let tmpRows = Object.values(tmp.items);
        for (let i in tmpRows) {
          args.ramDetails.push(tmpRows[i]);
        }
      }
    }
    if (part6Ref.saveRecord) {
      let tmp = part6Ref.saveRecord();
      if (tmp.files) {
        args.files = tmp.files;
      }
      if (tmp.comments) {
        args.comments = tmp.comments;
      }
      if (tmp.items) {
        let tmpRows = Object.values(tmp.items);
        for (let i in tmpRows) {
          args.items.push(tmpRows[i]);
        }
      }
    }
    setFormData(args);
    return args;
  };

  const onReload = () => {
    getRows();
  };

  const onCloseForm = () => {
    setManageDialog(false);
  };

  console.log("filteredRows: filters:", rows);

  return (
    <React.Fragment>
      <div className="pageInfo">
        <ul className="breadcrumbs">
          <li>{LABELS.HOME}</li>
          <li className="dim">{" / "}</li>
          <li onClick={() => props.setDashMenu(LABELS.HOME)}>
            {LABELS.EVALUATION}
          </li>
          <li className="dim">{" / "}</li>
          <li className="active">{LABELS.QUESTIONNAIRE}</li>
        </ul>
        <h4>{LABELS.QUESTIONNAIRE}</h4>
      </div>

      {/* Graphs */}
      {pieChart.series && (
        <div className="graphs">
          <HGraph
            width={"50%"}
            type={"column"}
            xAxis={columnChart.xAxis}
            yAxis={columnChart.yAxis}
            title={" "}
            exportTitle={" "}
          />
          <HGraph
            width={"50%"}
            type={"pie"}
            float={"right"}
            series={pieChart.series}
            title={" "}
            exportTitle={" "}
          />
        </div>
      )}

      {/* Master Grid */}
      {rows ? (
        <ExcelGrid
          id={"contractorForm"}
          height={window.innerHeight * 0.8}
          cols={cols}
          rows={rows}
          exportOption={true}
          newRowOption={false}
          exportName={LABELS.QUESTIONNAIRE}
          onReload={onReload}
          onAdd={onAdd}
        />
      ) : (
        <div className="pageCenter">
          <Loader />
        </div>
      )}

      {/* Form Dialog Box */}
      {manageDialog && (
        <UiDialog
          title={
            selectedRow.id
              ? "Edit " + LABELS.QUESTIONNAIRE + ": " + selectedRow.projectName
              : "Add New " + LABELS.QUESTIONNAIRE
          }
          open={true}
          size={"xl"}
          onClose={() => {
            setManageDialog(false);
            setFormData({});
            setPageNo(1);
          }}
        >
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              <div
                className="formGroup"
                style={{ width: "30%", marginRight: 20 }}
              >
                <label className="ehsHeading1 red">
                  {"Application Number * "}
                </label>
                {applicationNo ? (
                  <TxtBox
                    id={"applicationNo"}
                    disabled={true}
                    defaultValue={applicationNo}
                  />
                ) : (
                  <div className="flexCenter">
                    <Loader size={20} />
                  </div>
                )}
              </div>
              <div
                className="formGroup"
                style={{ width: "30%", marginRight: 20 }}
              >
                <label className="ehsHeading1 red">{"Contractor Name *"}</label>
                <TxtBox
                  type={"text"}
                  id="nameOfContractor"
                  defaultValue={
                    selectedRow.id ? selectedRow.nameOfContractor : ""
                  }
                />
              </div>
              <div
                className="formGroup"
                style={{ width: "30%", marginRight: 20 }}
              >
                <label className="ehsHeading1 red">{"Incharge Person *"}</label>
                <TxtBox
                  type={"text"}
                  id="inchargePerson"
                  defaultValue={
                    selectedRow.id ? selectedRow.inchargePerson : ""
                  }
                />
              </div>
              <div
                className="formGroup"
                style={{ width: "30%", marginRight: 20 }}
              >
                <label className="ehsHeading1 red">{"Phone Number *"}</label>
                <TxtBox
                  type={"text"}
                  id="phoneNo"
                  defaultValue={selectedRow.id ? selectedRow.phoneNo : ""}
                />
              </div>
              <div
                className="formGroup"
                style={{ width: "30%", marginRight: 20 }}
              >
                <label className="red">
                  {"Select " + LABELS.ORGANIZATION + " *"}
                </label>
                <AutoComplete
                  id="ogrId"
                  list={orgList}
                  childRef={orgsRef}
                  defaultValue={
                    selectedRow.id
                      ? {
                          id: selectedRow.orgId,
                          label: selectedRow.orgName,
                        }
                      : null
                  }
                />
              </div>
              <div className="formGroup" style={{ width: "30%" }}>
                <label className="red">{"Contractor Type *"}</label>
                <AutoComplete
                  list={[
                    {
                      id: "CSA",
                      label: "CSA",
                    },
                    {
                      id: "Electrical",
                      label: "Electrical",
                    },
                    {
                      id: "Mechanical",
                      label: "Mechanical",
                    },
                    {
                      id: "Sprinkler",
                      label: "Sprinkler",
                    },
                  ]}
                  childRef={contractorTypeRef}
                  defaultValue={
                    selectedRow.contractorType
                      ? {
                          id: selectedRow.contractorType,
                          label: selectedRow.contractorType,
                        }
                      : null
                  }
                />
              </div>
            </div>

            <div
              className="formGroup"
              style={{ width: "30%", marginRight: 20 }}
            >
              <label className="ehsHeading1 red">
                {"Contractor Address *"}
              </label>
              <TxtBox
                type={"text"}
                id="contractorAddress"
                rows={3}
                defaultValue={
                  selectedRow.id ? selectedRow.contractorAddress : ""
                }
              />
            </div>
            <div style={{ width: "100%", flexDirection: "column" }}>
              <Part1 childRef={part1Ref} editRows={editRows} />

              <Part2 childRef={part2Ref} editRows={editRows} />

              <Part3 childRef={part3Ref} editRows={editRows} />

              <Part4 childRef={part4Ref} editRows={editRows} />

              <Part5 childRef={part5Ref} editRows={editRows} />

              <ModalBtmBtns
                onSave={manageContractor}
                onCancel={onCloseForm}
                disabled={editRows == null}
              />
            </div>
          </>
        </UiDialog>
      )}
    </React.Fragment>
  );
};

export default withSnackbar(ContractForm);
