import React, { useState, useEffect, useRef } from "react";

import ChkBox from "components/Custom/ChkBox";
import ExcelGrid from "components/Custom/ExcelGrid";
import Loader from "components/Custom/Loader";
import { DEFAULTS } from "common/config";

const dataJson = require("components/Evaluation/ContractForm/gridData.json");

interface defaultProps {
  childRef: any;
  editRows: any[];
}

const Part1 = (props: defaultProps) => {
  const { childRef, editRows } = props;
  const [healthAndSAfetyCols, setHealthAndSAfetyCols] = useState<any[]>([
    {
      key: "question",
      name: "Question",
      type: "text",
      editable: false,
      width: "65%",
    },
    {
      key: "checkBoxYes",
      name: "YES",
      inputValue: "true",
      type: "radiobutton",
      editable: false,
      sortable: false,
      width: "5%",
      className: "textCenter",
    },
    {
      key: "checkBoxNo",
      name: "NO",
      inputValue: "true",
      type: "radiobutton",
      editable: false,
      sortable: false,
      width: "5%",
      className: "textCenter",
    },
    {
      key: "comments",
      name: "Comments",
      type: "text",
      editable: false,
      width: "15%",
    },
    {
      key: "score",
      name: "Score",
      type: "dropdown",
      list: DEFAULTS.SCORES,
      editable: true,
      width: "10%",
    },
  ]);

  let tmpHealthAndSafetyRows = dataJson["1"]["1.1"];
  let tmpCompetenceAndCapabilityRows = dataJson["2"]["2.1"];
  for (let key in tmpHealthAndSafetyRows) {
    tmpHealthAndSafetyRows[key].checkBoxYes = "";
    tmpHealthAndSafetyRows[key].checkBoxNo = "";
    tmpHealthAndSafetyRows[key].score = "";
    tmpHealthAndSafetyRows[key].comments = "";
  }
  for (let key in tmpCompetenceAndCapabilityRows) {
    tmpCompetenceAndCapabilityRows[key].checkBoxYes = "";
    tmpCompetenceAndCapabilityRows[key].checkBoxNo = "";
    tmpCompetenceAndCapabilityRows[key].score = "";
    tmpCompetenceAndCapabilityRows[key].comments = "";
  }

  if (!editRows) {
    tmpHealthAndSafetyRows = null;
    tmpCompetenceAndCapabilityRows = null;
  } else if (editRows["records"]) {
    const safetyDetails = JSON.parse(editRows["records"][0]["safetyDetails"]);
    for (let i = 0; i < tmpHealthAndSafetyRows.length; i++) {
      const jsonData = {
        question: tmpHealthAndSafetyRows[i].question,
        ...safetyDetails[i],
      };
      tmpHealthAndSafetyRows[i] = jsonData;
    }
    let safetyLen = tmpHealthAndSafetyRows.length;
    for (let i = 0; i < tmpCompetenceAndCapabilityRows.length; i++) {
      const jsonData = {
        question: tmpCompetenceAndCapabilityRows[i].question,
        ...safetyDetails[safetyLen + i],
      };
      tmpCompetenceAndCapabilityRows[i] = jsonData;
    }
  }
  const [competenceAndCapabilityCols, setCompetenceAndCapabilityCols] =
    useState<any[]>([
      {
        key: "question",
        name: "Question",
        type: "text",
        editable: false,
        width: "65%",
      },
      {
        key: "checkBoxYes",
        name: "YES",
        inputValue: "true",
        type: "radiobutton",
        className: "textCenter",
        sortable: true,
        editable: false,
        width: "5%",
        renderCell: ({
          competenceAndCapabilityRows,
          onRowChange,
          tabIndex,
        }) => {
          return (
            <div className="excelChkWrapper">
              <ChkBox
                checked={competenceAndCapabilityRows.check}
                onChange={(val: any) => {
                  onRowChange({
                    ...competenceAndCapabilityRows,
                    check: !competenceAndCapabilityRows.check,
                  });
                }}
              />
            </div>
          );
        },
      },
      {
        key: "checkBoxNo",
        name: "NO",
        inputValue: "true",
        type: "radiobutton",
        className: "textCenter",
        sortable: true,
        editable: false,
        width: "5%",
        renderCell: ({
          competenceAndCapabilityRows,
          onRowChange,
          tabIndex,
        }) => {
          return (
            <div className="excelChkWrapper">
              <ChkBox
                checked={competenceAndCapabilityRows.check}
                onChange={(val: any) => {
                  onRowChange({
                    ...competenceAndCapabilityRows,
                    check: !competenceAndCapabilityRows.check,
                  });
                }}
              />
            </div>
          );
        },
      },
      {
        key: "comments",
        name: "Comments",
        type: "text",
        editable: false,
        width: "20%",
      },
      {
        key: "score",
        name: "Score",
        type: "dropdown",
        list: DEFAULTS.SCORES,
        editable: true,
        width: "5%",
      },
    ]);

  const grid1Ref: any = useRef();
  const grid2Ref: any = useRef();

  useEffect(() => {
    childRef.saveRecord = saveRecord;
    return () => {
      childRef.saveRecord = null;
    };
  }, []);

  const saveRecord = () => {
    const section1 = grid1Ref.getRowData().map((s1) => {
      return {
        id: s1.id,
        checkBoxYes: s1.checkBoxYes,
        checkBoxNo: s1.checkBoxNo,
        comments: s1.comments,
        score: s1.score,
      };
    });
    const section2 = grid2Ref.getRowData().map((s1) => {
      return {
        id: s1.id,
        checkBoxYes: s1.checkBoxYes,
        checkBoxNo: s1.checkBoxNo,
        comments: s1.comments,
        score: s1.score,
      };
    });
    const items = [...section1, ...section2];
    return { items };
  };

  console.log("tmpHealthAndSafetyRows: ", editRows, tmpHealthAndSafetyRows);

  return (
    <>
      <h4 className="ehsHeading1">{dataJson["1"]["heading1"]}</h4>
      <h5 className="ehsHeading2">{dataJson["1"]["heading2"]}</h5>
      {tmpHealthAndSafetyRows && (
        <ExcelGrid
          childRef={grid1Ref}
          cols={healthAndSAfetyCols}
          rows={tmpHealthAndSafetyRows}
          noTotalRows={true}
        />
      )}

      <h4 className="ehsHeading1">{dataJson["2"]["heading1"]}</h4>
      <h5 className="ehsHeading2">{dataJson["2"]["heading2"]}</h5>
      {tmpCompetenceAndCapabilityRows && (
        <ExcelGrid
          childRef={grid2Ref}
          cols={competenceAndCapabilityCols}
          rows={tmpCompetenceAndCapabilityRows}
          noTotalRows={true}
        />
      )}
    </>
  );
};

export default Part1;
