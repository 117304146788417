import React, { useState, useEffect, useRef } from "react";

import ChkBox from "components/Custom/ChkBox";
import ExcelGrid from "components/Custom/ExcelGrid";
import Loader from "components/Custom/Loader";
import { DEFAULTS } from "common/config";

const dataJson = require("components/Evaluation/ContractForm/gridData.json");

interface defaultProps {
  childRef: any;
  editRows: any[];
}

const Part5 = (props: defaultProps) => {
  const { childRef, editRows } = props;
  const [riskAssesmentCols, setRiskAssesmentColsCols] = useState<any[]>([
    {
      key: "question",
      name: "Question",
      type: "text",
      editable: false,
      width: "65%",
    },
    {
      key: "checkBoxYes",
      name: "YES",
      inputValue: "true",
      type: "radiobutton",
      sortable: true,
      editable: false,
      width: "5%",
      className: "textCenter",
    },
    {
      key: "checkBoxNo",
      name: "NO",
      inputValue: "true",
      type: "radiobutton",
      sortable: true,
      editable: false,
      width: "5%",
      className: "textCenter",
    },
    {
      key: "comments",
      name: "Comments",
      type: "text",
      editable: false,
      width: "20%",
    },
    {
      key: "score",
      name: "Score",
      type: "dropdown",
      list: DEFAULTS.SCORES,
      editable: true,
      width: "5%",
    },
  ]);

  let tmpRiskAssesmentRows = dataJson["10"]["10.1"];
  let tmpReferenceRows = dataJson["11"]["11.1"];
  for (let key in tmpRiskAssesmentRows) {
    tmpRiskAssesmentRows[key].checkBoxYes = "";
    tmpRiskAssesmentRows[key].checkBoxNo = "";
    tmpRiskAssesmentRows[key].score = "";
    tmpRiskAssesmentRows[key].comments = "";
  }
  for (let key in tmpReferenceRows) {
    tmpReferenceRows[key].checkBoxYes = "";
    tmpReferenceRows[key].checkBoxNo = "";
    tmpReferenceRows[key].score = "";
    tmpReferenceRows[key].comments = "";
  }

  if (!editRows) {
    tmpRiskAssesmentRows = null;
    tmpReferenceRows = null;
  } else if (editRows["records"]) {
    const safetyDetails = JSON.parse(editRows["records"][0]["ramDetails"]);
    for (let i = 0; i < tmpRiskAssesmentRows.length; i++) {
      const jsonData = {
        question: tmpRiskAssesmentRows[i].question,
        ...safetyDetails[i],
      };
      tmpRiskAssesmentRows[i] = jsonData;
    }
    let safetyLen = tmpRiskAssesmentRows.length;
    for (let i = 0; i < tmpReferenceRows.length; i++) {
      const jsonData = {
        question: tmpReferenceRows[i].question,
        ...safetyDetails[safetyLen + i],
      };
      tmpReferenceRows[i] = jsonData;
    }
  }

  const [riskAssesmentRows, setRiskAssesmentRows] =
    useState<any[]>(tmpRiskAssesmentRows);
  const [referenceCols, setReferenceCols] = useState<any[]>([
    {
      key: "question",
      name: "Question",
      type: "text",
      editable: false,
      width: "65%",
    },
    {
      key: "checkBoxYes",
      name: "YES",
      inputValue: "true",
      type: "radiobutton",
      className: "textCenter",
      sortable: true,
      editable: false,
      width: "5%",
      renderCell: ({ referenceRows, onRowChange, tabIndex }) => {
        return (
          <div className="excelChkWrapper">
            <ChkBox
              checked={referenceRows.check}
              onChange={(val: any) => {
                onRowChange({
                  ...referenceRows,
                  check: !referenceRows.check,
                });
              }}
            />
          </div>
        );
      },
    },
    {
      key: "checkBoxNo",
      name: "NO",
      inputValue: "true",
      type: "radiobutton",
      className: "textCenter",
      sortable: true,
      editable: false,
      width: "5%",
      renderCell: ({ referenceRows, onRowChange, tabIndex }) => {
        return (
          <div className="excelChkWrapper">
            <ChkBox
              checked={referenceRows.check}
              onChange={(val: any) => {
                onRowChange({
                  ...referenceRows,
                  check: !referenceRows.check,
                });
              }}
            />
          </div>
        );
      },
    },
    {
      key: "comments",
      name: "Comments",
      type: "text",
      editable: false,
      width: "20%",
    },
    {
      key: "score",
      name: "Score",
      type: "dropdown",
      list: DEFAULTS.SCORES,
      editable: true,
      width: "5%",
    },
  ]);
  const [referenceRows, setReferenceRows] = useState<any[]>(tmpReferenceRows);

  const grid1Ref: any = useRef();
  const grid2Ref: any = useRef();

  useEffect(() => {
    childRef.saveRecord = saveRecord;
    return () => {
      childRef.saveRecord = null;
    };
  }, []);

  const saveRecord = () => {
    const section1 = grid1Ref.getRowData().map((s1) => {
      return {
        id: s1.id,
        checkBoxYes: s1.checkBoxYes,
        checkBoxNo: s1.checkBoxNo,
        comments: s1.comments,
        score: s1.score,
      };
    });
    const section2 = grid2Ref.getRowData().map((s1) => {
      return {
        id: s1.id,
        checkBoxYes: s1.checkBoxYes,
        checkBoxNo: s1.checkBoxNo,
        comments: s1.comments,
        score: s1.score,
      };
    });
    const items = [...section1, ...section2];
    return { items };
  };

  return (
    <>
      <h4 className="ehsHeading1">{dataJson["10"]["heading1"]}</h4>
      <h5 className="ehsHeading2">{dataJson["10"]["heading2"]}</h5>
      {tmpRiskAssesmentRows ? (
        <ExcelGrid
          childRef={grid1Ref}
          cols={riskAssesmentCols}
          rows={tmpRiskAssesmentRows}
          noTotalRows={true}
        />
      ) : (
        <div className="pageCenter">
          <Loader />
        </div>
      )}

      <h4 className="ehsHeading1">{dataJson["11"]["heading1"]}</h4>
      <h5 className="ehsHeading2">{dataJson["11"]["heading2"]}</h5>
      {tmpReferenceRows ? (
        <ExcelGrid
          childRef={grid2Ref}
          cols={referenceCols}
          rows={tmpReferenceRows}
          noTotalRows={true}
        />
      ) : (
        <div className="pageCenter">
          <Loader />
        </div>
      )}
    </>
  );
};

export default Part5;
