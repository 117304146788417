/*
 * Safety Monitoring & Measurement Form
 */

import React, { useRef, useEffect, useState } from "react";
import { withSnackbar } from "notistack";
import { IconButton, Tooltip } from "@mui/material";
import { Edit as IconEdit, Print as IconPrint } from "@mui/icons-material";

import UiDialog from "components/Custom/UiDialog";
import ExcelGrid from "components/Custom/ExcelGrid";
import AutoComplete from "components/Custom/AutoComplete";
import SkeletonBox from "components/Custom/SkeletonBox";
import ModalBtmBtns from "components/Custom/ModalBtmBtns";
import Loader from "components/Custom/Loader";
import HGraph from "components/Custom/HGraph";
import PartLast from "./sections/PartLast";
import Btn from "components/Custom/Btn";
import TxtBox from "components/Custom/TxtBox";
import ChkBox from "components/Custom/ChkBox";

import { FORMATS, LABELS, NOTIFY, SUCCESS, WARNINGS } from "common/config";
import { ApiHome } from "apihandlers/home";
import { ApiEhs } from "apihandlers/ehs";
import { ApiAudit } from "apihandlers/audit";
import { COMMON_JS } from "common/scripts";
import { DateFunctions } from "common/datefunctions";

const API_HANDLER_HOME = new ApiHome();
const API_HANDLER_EHS = new ApiEhs();
const API_HANDLER_AUDIT = new ApiAudit();

const WorkPermit = (props: any) => {
  const {} = props;

  const mainGridCols = [
    {
      key: "slNo",
      name: "Sl No.",
      type: "number",
      width: "5%",
      editable: false,
      sortable: true,
      align: "center",
      searchable: true,
      frozen: true,
    },
    {
      key: "projectName",
      name: "Project Name",
      type: "text",
      width: "25%",
      editable: false,
      sortable: true,
      searchable: true,
      frozen: true,
    },
    {
      key: "permitNo",
      name: "Permit Number",
      type: "text",
      width: "15%",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "createdBy",
      name: "Generator Name",
      type: "text",
      width: "30%",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "typeOfPermit",
      name: "Type of Work permit",
      type: "dropdown",
      width: "10%",
      editable: false,
      sortable: true,
      searchable: true,
      filters: [
        {
          id: "Hot Permit",
          name: "Hot Permit",
        },
        {
          id: "Cold Permit",
          name: "Cold Permit",
        },
      ],
    },
    {
      key: "permitDate",
      name: "Date of Generation",
      type: "text",
      width: "15%",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "status",
      name: "Status",
      type: "dropdown",
      width: "10%",
      editable: false,
      sortable: true,
      searchable: true,
      filters: [
        {
          id: "Open",
          name: "Open",
        },
        {
          id: "Close",
          name: "Close",
        },
      ],
    },
    {
      key: "options",
      name: "Options",
      type: "html",
      width: "10%",
      editable: false,
      sortable: false,
      renderCell: ({ row }: any) => (
        <div className="options">
          <Tooltip title={LABELS.EDIT} placement="bottom">
            <IconButton className="iconBtn" onClick={() => onEditRow(row)}>
              <IconEdit />
            </IconButton>
          </Tooltip>
          <Tooltip title={LABELS.PRINT} placement="bottom">
            <IconButton
              className="iconBtn"
              onClick={() => printWorkPermit(row)}
            >
              <IconPrint />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];
  const [loading, setLoading] = useState<boolean>(true);
  const [projects, setProjects] = useState<any[]>([]);
  const [cols, setCols] = useState<any[]>(mainGridCols);
  const [rows, setRows] = useState<any[]>(null);
  const [columnChart, setColumnChart] = useState<any>({});
  const [pieChart, setPieChart] = useState<any>({});
  const [manageDialog, setManageDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [editRows, setEditRows] = useState<any[]>(null);
  const [pageNo, setPageNo] = useState(1);
  const [totalPages, setTotalPages] = useState(6);
  const [formData, setFormData] = useState<any>({});
  const [uploads, setUploads] = useState<any[]>([]);
  const [permitTypes, setPermitTypes] = useState<any[]>([
    {
      id: "Hot Permit",
      label: "Hot Permit",
    },
    {
      id: "Cold Permit",
      label: "Cold Permit",
    },
  ]);
  const [permitNo, setPermitNo] = useState("");
  const [prepChecklist, setPrepChecklist] = useState([
    {
      id: "1.1",
      checked: false,
      label: "Fire watch Assigned (remain for 1 Hour after works complete)",
    },
    {
      id: "1.2",
      checked: false,
      label: "Safe Plan of Action completed",
    },
    {
      id: "1.3",
      checked: false,
      label: "Vents/Inlets Blanked Off",
    },
    {
      id: "1.4",
      checked: false,
      label: "Area Cordoned Off",
    },
    {
      id: "1.5",
      checked: false,
      label: "Electrical Isolation",
    },
    {
      id: "1.6",
      checked: false,
      label: "Signs in Place",
    },
    {
      id: "1.7",
      checked: false,
      label: "Area free of combustibles",
    },
    {
      id: "1.8",
      checked: false,
      label: "Process Isolation",
    },
    {
      id: "1.9",
      checked: false,
      label: "Area protected with blanks where required",
    },
    {
      id: "1.10",
      checked: false,
      label: "Drains sealed off",
    },
    {
      id: "1.11",
      checked: false,
      label: "Gas Free Area",
    },
    {
      id: "1.12",
      checked: false,
      label: "Other",
    },
    {
      id: "1.13",
      checked: false,
      label: "Continuous running water required",
    },
    {
      id: "1.14",
      checked: false,
      label: "Fire Detection Isolation",
    },
  ]);
  const [reqEquipsChecklist, setReqEquipsChecklist] = useState([
    {
      id: "2.1",
      checked: false,
      label: "Fire Blanket (Industrial Quality)",
    },
    {
      id: "2.2",
      checked: false,
      label: "Carbon Dioxide",
    },
    {
      id: "2.3",
      checked: false,
      label: "Foam",
    },
    {
      id: "2.4",
      checked: false,
      label: "Dry Powder",
    },
    {
      id: "2.5",
      checked: false,
      label: "Running Hose",
    },
    {
      id: "2.6",
      checked: false,
      label: "Water",
    },
    {
      id: "2.7",
      checked: false,
      label: "Has the area been sealed off?",
    },
    {
      id: "2.8",
      checked: false,
      label: "If working at a height, has the area below been assessed?",
    },
    {
      id: "2.9",
      checked: false,
      label: "Has the area been tested free of flammable vapour/dust?",
    },
    {
      id: "2.10",
      checked: false,
      label:
        "Welding/Plant/Bottles / Torches to be secured and at the same level/location of Hot Work and flash back arrestors fitted",
    },
  ]);
  const [additionalEquips, setAdditionalEquips] = useState([
    {
      id: "3.1",
      checked: false,
      label: "Fire Blanket",
    },
    {
      id: "3.2",
      checked: false,
      label: "Gas Monitor",
    },
    {
      id: "3.3",
      checked: false,
      label: "Signs",
    },
    {
      id: "3.4",
      checked: false,
      label: "Fire Hose",
    },
    {
      id: "3.5",
      checked: false,
      label: "Screens",
    },
    {
      id: "3.6",
      checked: false,
      label: "Other",
    },
  ]);
  const [personalProtectiveList, setPersonalProtectiveList] = useState([
    {
      id: "4.1",
      checked: false,
      label: "Helmet",
    },
    {
      id: "4.2",
      checked: false,
      label: "Eye/Face Protection",
    },
    {
      id: "4.3",
      checked: false,
      label: "Hi-viz Clothing/Flame/retardant vest/ overalls",
    },
    {
      id: "4.4",
      checked: false,
      label: "Boots",
    },
    {
      id: "4.5",
      checked: false,
      label: "Respiratory Protection",
    },
    {
      id: "4.6",
      checked: false,
      label: "Welding mask",
    },
    {
      id: "4.7",
      checked: false,
      label: "Gloves/Gauntlets/Flame retardant gloves",
    },
    {
      id: "4.8",
      checked: false,
      label: "Ear Protection",
    },
    {
      id: "4.9",
      checked: false,
      label: "Other",
    },
  ]);

  const projectsRef: any = useRef();
  const part6Ref: any = useRef();

  useEffect(() => {
    return () => {
      cleanUp();
    };
  }, []);

  useEffect(() => {
    if (loading) {
      getProjects();
    }
  }, [loading]);

  const getProjects = () => {
    const args = { dropdown: true };
    API_HANDLER_HOME.getProjects(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        setProjects(response.records);
        getRows();
      }
    });
  };

  const cleanUp = () => {
    setRows(null);
    setLoading(true);
    setManageDialog(false);
    setSelectedRow({});
    console.log("Cleaned up");
  };

  const getRows = () => {
    setRows(null);
    const args = {};
    API_HANDLER_EHS.getWorkPermits(args).then((res: any) => {
      const { status, response } = res;
      if (status === 200) {
        setRows(response.records);
        setColumnChart(response.columnChart);
        setPieChart(response.pieChart);
        setLoading(false);
      }
    });
  };

  const onAdd = () => {
    const projectId = projectsRef.current.selectedValue.id;
    if (!projectId) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.PROJECT_EMPTY);
      return;
    }
    setManageDialog(!manageDialog);
    setEditRows([]);
    setPageNo(1);
    setSelectedRow({});
    setUploads([]);
    const args = {};
    setPermitNo("");
    API_HANDLER_EHS.getWorkPermitNumber(args).then((res: any) => {
      const { status, response } = res;
      if (status === 200) {
        setPermitNo(response.permitNo);
      }
    });
  };

  const onEditRow = (row: any) => {
    setPermitNo(row.permitNo);
    setPageNo(1);
    setSelectedRow(row);
    setManageDialog(true);
    setEditRows(null);
    getRowInfo(row.id);
  };

  const getRowInfo = (workPermitId) => {
    const args = {
      workPermitId,
    };
    console.log("onEditRow: ", args);
    setUploads(null);
    API_HANDLER_EHS.getWorkPermitInfo(args).then((res: any) => {
      const { status, response } = res;
      if (status === 200) {
        setEditRows(response);
        setUploads(response.files);
      }
    });
  };

  const getProjectInfo = () => {
    const projectId = projectsRef.current.selectedValue.id;
    const args = {
      projectId,
    };
    API_HANDLER_EHS.getWorkPermits(args).then((res: any) => {
      const { status, response } = res;
      if (status === 200) {
        setColumnChart(response.columnChart);
        setPieChart(response.pieChart);
      }
    });
  };

  const manageWorkPermit = () => {
    let args: any = updateForm();
    const typeOfPermit = permitTypeRef.current.selectedValue.id;
    const status = statusRef.current.selectedValue.id;
    if (!typeOfPermit) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, "Select permit type..!");
      return;
    }
    let chklistPre = {};
    let chkReqEquips = {};
    let chkAdditional = {};
    let chkPersonal = {};
    for (let i in prepChecklist) {
      let chkStatus = (
        document.getElementById(
          "chk-" + prepChecklist[i].id
        ) as HTMLInputElement
      ).checked;
      chklistPre[prepChecklist[i].id] = chkStatus;
    }
    for (let i in reqEquipsChecklist) {
      let chkStatus = (
        document.getElementById(
          "chk-" + reqEquipsChecklist[i].id
        ) as HTMLInputElement
      ).checked;
      chkReqEquips[reqEquipsChecklist[i].id] = chkStatus;
    }
    for (let i in additionalEquips) {
      let chkStatus = (
        document.getElementById(
          "chk-" + additionalEquips[i].id
        ) as HTMLInputElement
      ).checked;
      chkAdditional[additionalEquips[i].id] = chkStatus;
    }
    for (let i in personalProtectiveList) {
      let chkStatus = (
        document.getElementById(
          "chk-" + personalProtectiveList[i].id
        ) as HTMLInputElement
      ).checked;
      chkPersonal[personalProtectiveList[i].id] = chkStatus;
    }

    args = {
      ...args,
      workPermitId: selectedRow.id || -1,
      projectId: selectedRow.id
        ? selectedRow.projectId
        : projectsRef.current.selectedValue.id,
      permitDate: (document.getElementById("permitDate") as HTMLInputElement)
        .value,
      permitStartDt: (
        document.getElementById("permitStartDt") as HTMLInputElement
      ).value,
      permitEndDt: (document.getElementById("permitEndDt") as HTMLInputElement)
        .value,
      typeOfPermit,
      coveredLoc: (document.getElementById("coveredLoc") as HTMLInputElement)
        .value,
      descriptionOfWOrk: (
        document.getElementById("descriptionOfWOrk") as HTMLInputElement
      ).value,
      permitNo: (document.getElementById("permitNo") as HTMLInputElement).value,
      files: uploads,
      coveredCompany: (
        document.getElementById("coveredCompany") as HTMLInputElement
      ).value,
      totPersons: (document.getElementById("totPersons") as HTMLInputElement)
        .value,
      prepChecklist: chklistPre,
      reqEquips: chkReqEquips,
      additionalEquips: chkAdditional,
      personalProtectiveDetails: chkPersonal,
      status: status === "Open" ? 1 : 0,
    };

    console.log("manageWorkPermit: ", args);

    // Validations
    if (!args.projectId) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.PROJECT_EMPTY);
      return;
    }
    setEditRows(null);
    API_HANDLER_EHS.manageWorkPermit(args).then((res) => {
      setEditRows([]);
      if (res.status === 200) {
        COMMON_JS.showNotify(props, NOTIFY.SUCCESS, SUCCESS.RECORD_SAVED);
        setSelectedRow({});
        setManageDialog(false);
        getRows();
      } else {
        COMMON_JS.showNotify(props, NOTIFY.ERROR, res.error);
      }
    });
  };

  const updateForm = () => {
    let args: any = {
      files: [],
    };
    if (part6Ref.saveRecord) {
      let tmp = part6Ref.saveRecord();
      if (tmp.files) {
        args.files = tmp.files;
      }
    }
    setFormData(args);
    return args;
  };

  const onReload = () => {
    getRows();
  };

  const onCloseForm = () => {
    setManageDialog(false);
  };

  const printWorkPermit = async (row) => {
    const workPermitId = row.id;
    const args = {
      workPermitId,
    };
    try {
      const res = await API_HANDLER_EHS.printWorkPermit(args);
      if (res) {
        let base64 = "data:application/pdf;base64, " + res;
        fetch(base64)
          .then((r) => {
            r.blob().then((data) => {
              let objUrl = window.URL.createObjectURL(data);
              window.open(objUrl, "_blank");
              window.URL.revokeObjectURL(objUrl);
            });
          })
          .catch((err) => {});
      } else {
        COMMON_JS.showNotify(props, NOTIFY.ERROR, "Print Error..!");
      }
    } catch (err) {
      console.warn("print err:", err);
      COMMON_JS.showNotify(props, NOTIFY.ERROR, "Invoice Copy Not Found..!");
    }
  };

  const permitTypeRef: any = useRef();
  const statusRef: any = useRef();

  let chklist1 = prepChecklist;
  let chklist2 = reqEquipsChecklist;
  let chklist3 = additionalEquips;
  let chklist4 = personalProtectiveList;
  if (selectedRow.id) {
    let tmpList1 = JSON.parse(selectedRow.prepChecklist);
    let tmpList2 = JSON.parse(selectedRow.reqEquips);
    let tmpList3 = JSON.parse(selectedRow.additionalEquips);
    let tmpList4 = JSON.parse(selectedRow.personalProtectiveDetails);
    for (let i = 0; i < chklist1.length; i++) {
      chklist1[i].checked = tmpList1[chklist1[i].id];
    }
    for (let i = 0; i < chklist2.length; i++) {
      chklist2[i].checked = tmpList2[chklist2[i].id];
    }
    for (let i = 0; i < chklist3.length; i++) {
      chklist3[i].checked = tmpList3[chklist3[i].id];
    }
    for (let i = 0; i < chklist4.length; i++) {
      chklist4[i].checked = tmpList4[chklist4[i].id];
    }
  } else {
    chklist1 = chklist1.map((chk) => {
      chk.checked = false;
      return chk;
    });
    chklist2 = chklist2.map((chk) => {
      chk.checked = false;
      return chk;
    });
    chklist3 = chklist3.map((chk) => {
      chk.checked = false;
      return chk;
    });
    chklist4 = chklist4.map((chk) => {
      chk.checked = false;
      return chk;
    });
  }

  console.log("chklist1:", chklist1);

  return (
    <React.Fragment>
      <div className="pageInfo">
        <ul className="breadcrumbs">
          <li>{LABELS.HOME}</li>
          <li className="dim">{" / "}</li>
          <li onClick={() => props.setDashMenu(LABELS.HOME)}>{LABELS.EHS}</li>
          <li className="dim">{" / "}</li>
          <li className="active">{LABELS.WORK_PERMIT}</li>
        </ul>
        <h4>{LABELS.WORK_PERMIT}</h4>
      </div>
      <div className="formGroup" style={{ width: "100%" }}>
        <label>{LABELS.PROJECT_NAME}</label>
        <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
          <div style={{ width: "30%", marginRight: 10 }}>
            <AutoComplete
              id={"projects"}
              list={projects}
              childRef={projectsRef}
              defaultValue={
                selectedRow.id
                  ? {
                      id: selectedRow.projectId,
                      label: selectedRow.projectName,
                    }
                  : null
              }
            />
          </div>
          <Btn text={LABELS.LOAD_GRAPH} onClick={getProjectInfo} />
        </div>
      </div>

      {/* Graphs */}
      {pieChart.series && (
        <div className="graphs">
          <HGraph
            width={"50%"}
            type={"column"}
            xAxis={columnChart.xAxis}
            yAxis={columnChart.yAxis}
            title={" "}
            exportTitle={" "}
            colors={["#F28C28", "#009E60"]}
          />
          <HGraph
            width={"50%"}
            type={"pie"}
            float={"right"}
            series={pieChart.series}
            title={" "}
            exportTitle={" "}
            colors={["#F28C28", "#009E60"]}
          />
        </div>
      )}

      {/* Master Grid */}
      {rows ? (
        <ExcelGrid
          id={"workPermit"}
          height={window.innerHeight * 0.6}
          cols={cols}
          rows={rows}
          exportOption={true}
          newRowOption={false}
          exportName={LABELS.WORK_PERMIT}
          onReload={onReload}
          onAdd={onAdd}
        />
      ) : (
        <div className="pageCenter">
          <Loader />
        </div>
      )}

      {/* Form Dialog Box */}
      {manageDialog && (
        <UiDialog
          title={
            selectedRow.id
              ? "Edit " + LABELS.WORK_PERMIT + ": " + selectedRow.projectName
              : "Add New " + LABELS.WORK_PERMIT
          }
          open={true}
          size={"xl"}
          onClose={() => {
            setManageDialog(false);
            setFormData({});
            setPageNo(1);
            setSelectedRow({});
            setPermitNo("");
          }}
        >
          <>
            <div style={{ width: "100%", flexDirection: "column" }}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="formGroup" style={{ width: "30%" }}>
                  <label>{"Permit No."}</label>
                  {permitNo ? (
                    <TxtBox
                      id={"permitNo"}
                      disabled={true}
                      defaultValue={permitNo}
                    />
                  ) : (
                    <div className="flexCenter">
                      <Loader size={20} />
                    </div>
                  )}
                </div>
                <div className="formGroup" style={{ width: "30%" }}>
                  <label>{"Permit Date"}</label>
                  <TxtBox
                    type={"date"}
                    id={"permitDate"}
                    defaultValue={
                      selectedRow.permitDate ||
                      DateFunctions.getDate(
                        "today",
                        "from",
                        FORMATS.SQL_DATE_ONLY
                      )
                    }
                  />
                </div>
                <div className="formGroup" style={{ width: "30%" }}>
                  <label>{"Permit Type"}</label>
                  <AutoComplete
                    id={"typeOfPermit"}
                    list={permitTypes}
                    childRef={permitTypeRef}
                    defaultValue={
                      selectedRow.id
                        ? {
                            id: selectedRow.typeOfPermit,
                            label: selectedRow.typeOfPermit,
                          }
                        : null
                    }
                  />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div className="formGroup" style={{ width: "30%" }}>
                  <label>{"Period Covered"}</label>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <TxtBox
                      type={"date"}
                      id={"permitStartDt"}
                      defaultValue={
                        selectedRow.permitStartDt ||
                        DateFunctions.getDate(
                          "thismonth",
                          "from",
                          FORMATS.SQL_DATE_ONLY
                        )
                      }
                    />
                    <div style={{ width: "10%", textAlign: "center" }}>
                      {" - "}
                    </div>
                    <TxtBox
                      type={"date"}
                      id={"permitEndDt"}
                      defaultValue={
                        selectedRow.permitEndDt ||
                        DateFunctions.getDate(
                          "thismonth",
                          "to",
                          FORMATS.SQL_DATE_ONLY
                        )
                      }
                    />
                  </div>
                </div>

                <div className="formGroup" style={{ width: "30%" }}>
                  <label>{"Location Covered"}</label>
                  <TxtBox
                    id={"coveredLoc"}
                    defaultValue={selectedRow.coveredLoc || ""}
                  />
                </div>

                <div className="formGroup" style={{ width: "30%" }}>
                  <label>{"Company Covered"}</label>
                  <TxtBox
                    id={"coveredCompany"}
                    defaultValue={selectedRow.coveredCompany || ""}
                  />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div className="formGroup" style={{ width: "65%" }}>
                  <label>{"Description of work"}</label>
                  <TxtBox
                    id={"descriptionOfWOrk"}
                    defaultValue={selectedRow.descriptionOfWOrk || ""}
                    rows={4}
                  />
                </div>
                <div className="formGroup" style={{ width: "30%" }}>
                  <div className="formGroup">
                    <label>{"No. of persons covered"}</label>
                    <TxtBox
                      id={"totPersons"}
                      defaultValue={selectedRow.totPersons || ""}
                    />
                  </div>
                  <div className="formGroup" style={{ width: "30%" }}>
                    <label>{"Status"}</label>
                    <AutoComplete
                      id={"status"}
                      list={[
                        {
                          id: "Open",
                          label: "Open",
                        },
                        {
                          id: "Close",
                          label: "Close",
                        },
                      ]}
                      childRef={statusRef}
                      defaultValue={
                        selectedRow.id
                          ? {
                              id: selectedRow.status,
                              label: selectedRow.status,
                            }
                          : {
                              id: "Open",
                              label: "Open",
                            }
                      }
                    />
                  </div>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: 10,
                  marginBottom: 15,
                }}
              >
                <div
                  style={{ marginBottom: 10, borderBottom: "1px solid #ccc" }}
                >
                  <h4 className="ehsHeading1" style={{ marginBottom: 5 }}>
                    {
                      "Area preparation checklist: (Tick if completed - If not applicable insert N/A)"
                    }
                  </h4>
                  <div className="checkList">
                    {chklist1.map((chk, i) => {
                      return (
                        <ChkBox
                          id={"chk-" + chk.id}
                          text={chk.label}
                          checked={chk.checked}
                        />
                      );
                    })}
                  </div>
                </div>

                <div
                  style={{
                    marginBottom: 20,
                    borderBottom: "1px solid #ccc",
                    paddingBottom: 5,
                  }}
                >
                  <h4 className="ehsHeading1" style={{ marginBottom: 5 }}>
                    {
                      "Firefighting Equipment Required: (Tick if completed - If not applicable insert N/A)"
                    }
                  </h4>
                  <div className="checkList">
                    {chklist2.map((chk, i) => {
                      return (
                        <ChkBox
                          id={"chk-" + chk.id}
                          text={chk.label}
                          checked={chk.checked}
                        />
                      );
                    })}
                  </div>
                </div>

                <div
                  style={{ marginBottom: 15, borderBottom: "1px solid #ccc" }}
                >
                  <h4 className="ehsHeading1" style={{ marginBottom: 5 }}>
                    {"Additional Equipment if required:"}
                  </h4>
                  <div className="checkList">
                    {chklist3.map((chk, i) => {
                      return (
                        <ChkBox
                          id={"chk-" + chk.id}
                          text={chk.label}
                          checked={chk.checked}
                        />
                      );
                    })}
                  </div>
                </div>

                <div>
                  <h4 className="ehsHeading1" style={{ marginBottom: 5 }}>
                    {"Personal Protective Equipment Required:"}
                  </h4>
                  <div className="checkList">
                    {chklist4.map((chk, i) => {
                      return (
                        <ChkBox
                          id={"chk-" + chk.id}
                          text={chk.label}
                          checked={chk.checked}
                        />
                      );
                    })}
                  </div>
                </div>
              </div>

              {uploads && (
                <PartLast
                  childRef={part6Ref}
                  selectedRow={selectedRow}
                  uploads={uploads}
                  setUploads={(val) => {
                    setUploads(val);
                  }}
                />
              )}

              <ModalBtmBtns
                onSave={manageWorkPermit}
                onCancel={onCloseForm}
                disabled={editRows === null}
              />
            </div>
          </>
        </UiDialog>
      )}
    </React.Fragment>
  );
};

export default withSnackbar(WorkPermit);
