import React, { useState, useEffect, useRef } from "react";

import ChkBox from "components/Custom/ChkBox";
import ExcelGrid from "components/Custom/ExcelGrid";
import Loader from "components/Custom/Loader";
import { FORMATS, LABELS, NOTIFY } from "common/config";
import TxtBox from "components/Custom/TxtBox";
import AutoComplete from "components/Custom/AutoComplete";
import { DateFunctions } from "common/datefunctions";
import ModalBtmBtns from "components/Custom/ModalBtmBtns";

const inductionImange = require("../InductionTraining.png");

interface defaultProps {
  childRef: any;
  editRows: any[];
  orgList: any[];
  contractorList: any[];
  selectedRow: any;
  showNotify: Function;
}

const Part1 = (props: any) => {
  const {
    childRef,
    editRows,
    orgList,
    contractorList,
    selectedRow,
    showNotify,
  } = props;
  const [dashMenu, setDashMenu] = useState(LABELS.HOME);
  const setMenu = () => {
    setDashMenu(LABELS.HOME);
    childRef.setMenu = setMenu;
  };

  const contractorsRef: any = useRef();
  const orgNatureRef: any = useRef();
  const categoryRef: any = useRef();

  useEffect(() => {
    childRef.saveRecord = saveRecord;
    return () => {
      childRef.saveRecord = null;
    };
  }, []);

  const saveRecord = () => {
    const contractorId = contractorsRef.current.selectedValue.id;
    const safePassNum = (
      document.getElementById("safePassNum") as HTMLInputElement
    ).value.trim();
    const safePassValidity = (
      document.getElementById("safePassValidity") as HTMLInputElement
    ).value.trim();
    const cscsTkts = (
      document.getElementById("cscsTkts") as HTMLInputElement
    ).value.trim();
    const cscsValidity = (
      document.getElementById("cscsValidity") as HTMLInputElement
    ).value.trim();
    const empName = (
      document.getElementById("empName") as HTMLInputElement
    ).value.trim();
    const contactNumber = (
      document.getElementById("contactNumber") as HTMLInputElement
    ).value.trim();
    const nextOfKin = (
      document.getElementById("nextOfKin") as HTMLInputElement
    ).value.trim();
    const kinContactNumber = (
      document.getElementById("kinContactNumber") as HTMLInputElement
    ).value.trim();
    const trainingDate = (
      document.getElementById("trainingDate") as HTMLInputElement
    ).value;

    if (!contractorId) {
      showNotify(NOTIFY.WARNING, "Please select contractor..!");
      return null;
    }
    if (kinContactNumber === "") {
      showNotify(NOTIFY.WARNING, "Please enter kin contact number..!");
      return null;
    }
    if (nextOfKin === "") {
      showNotify(NOTIFY.WARNING, "Please enter next of kin..!");
      return null;
    }
    if (contactNumber === "") {
      showNotify(NOTIFY.WARNING, "Please enter contact number..!");
      return null;
    }
    if (empName === "") {
      showNotify(NOTIFY.WARNING, "Please enter employee name..!");
      return null;
    }
    if (cscsTkts === "") {
      showNotify(NOTIFY.WARNING, "Please enter cscs tickets..!");
      return null;
    }
    if (safePassNum === "") {
      showNotify(NOTIFY.WARNING, "Please enter safe pass number..!");
      return null;
    }
    if (safePassValidity === "") {
      showNotify(NOTIFY.WARNING, "Please enter safe pass validity detail..!");
      return null;
    }
    if (cscsValidity === "") {
      showNotify(NOTIFY.WARNING, "Please enter cscs validity detail..!");
      return null;
    }

    return {
      contractorId,
      safePassNum,
      safePassValidity,
      cscsTkts,
      cscsValidity,
      empName,
      contactNumber,
      nextOfKin,
      kinContactNumber,
      trainingDate,
    };
  };

  return (
    <React.Fragment>
      <div className="formGroup" style={{ width: "100%" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            height: "50%",
          }}
        >
          <img src={inductionImange} width={"100%"} height={"0%"} />
        </div>
        <div style={{ marginTop: 30 }}>
          <div className="flexRow">
            <div
              className="formGroup"
              style={{ width: "30%", marginRight: 20 }}
            >
              <label className="red">{"Training Date *"}</label>
              <TxtBox
                type={"Date"}
                id="trainingDate"
                defaultValue={
                  selectedRow.id
                    ? selectedRow.trainingDate
                    : DateFunctions.getDate(
                        "today",
                        "from",
                        FORMATS.SQL_DATE_ONLY
                      )
                }
              />
            </div>
          </div>
          <div
            style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
          >
            <div
              className="formGroup"
              style={{ width: "30%", marginRight: 20 }}
            >
              <label className="red">{"Select Contractor *"}</label>
              <AutoComplete
                childRef={contractorsRef}
                id="contractorId"
                list={contractorList}
                defaultValue={
                  selectedRow.id
                    ? {
                        id: selectedRow.id,
                        label: selectedRow.nameOfContractor,
                      }
                    : null
                }
              />
            </div>
            <div
              className="formGroup"
              style={{ width: "30%", marginRight: 20 }}
            >
              <label className="red">{"Safe Pass /CSCS *"}</label>
              <TxtBox
                type={"text"}
                id="safePassNum"
                defaultValue={selectedRow.id ? selectedRow.safePassNum : ""}
              />
            </div>
            <div
              className="formGroup"
              style={{ width: "30%", marginRight: 20 }}
            >
              <label className="red">{"Expiry Date *"}</label>
              <TxtBox
                type={"Date"}
                id="safePassValidity"
                defaultValue={
                  selectedRow.id ? selectedRow.safePassValidity : ""
                }
              />
            </div>
            <div
              className="formGroup"
              style={{ width: "30%", marginRight: 20 }}
            >
              <label className="red">{"CSCS Tickets *"}</label>
              <TxtBox
                type={"text"}
                id="cscsTkts"
                defaultValue={selectedRow.id ? selectedRow.cscsTkts : ""}
              />
            </div>
            <div
              className="formGroup"
              style={{ width: "30%", marginRight: 20 }}
            >
              <label className="red">{" Ticket Expiry Date *"}</label>
              <TxtBox
                type={"Date"}
                id="cscsValidity"
                defaultValue={selectedRow.id ? selectedRow.cscsValidity : ""}
              />
            </div>
            <div
              className="formGroup"
              style={{ width: "30%", marginRight: 20 }}
            >
              <label className="red">{"Employee Name *"}</label>
              <TxtBox
                type={"text"}
                id="empName"
                defaultValue={selectedRow.id ? selectedRow.empName : ""}
              />
            </div>
            <div
              className="formGroup"
              style={{ width: "30%", marginRight: 20 }}
            >
              <label className="red">{"Contact No *"}</label>
              <TxtBox
                type={"text"}
                id="contactNumber"
                defaultValue={selectedRow.id ? selectedRow.contactNumber : ""}
              />
            </div>
            <div
              className="formGroup"
              style={{ width: "30%", marginRight: 20 }}
            >
              <label className="red">{"Next Of Kin *"}</label>
              <TxtBox
                type={"text"}
                id="nextOfKin"
                defaultValue={selectedRow.id ? selectedRow.nextOfKin : ""}
              />
            </div>
            <div
              className="formGroup"
              style={{ width: "30%", marginRight: 20 }}
            >
              <label className="red">{" Kin Contact No *"}</label>
              <TxtBox
                type={"text"}
                id="kinContactNumber"
                defaultValue={
                  selectedRow.id ? selectedRow.kinContactNumber : ""
                }
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Part1;
