import React, { useState } from "react";
import { MenuList, MenuItem } from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";

import Audit_1 from "components/Audits/Audit_1";
import Audit_2 from "components/Audits/Audit_2";

import { CONFIG, LABELS } from "common/config";

import "devextreme/dist/css/dx.light.css";

const chart1 = require("assets/images/reports/chart1.png");
const chartResourceHistogram = require("assets/images/reports/Resource_Histogram.png");
const chartResourceTable = require("assets/images/reports/Resource_Table.png");
const chartManHoursPlan = require("assets/images/reports/Man_Hours_Distribution.png");
const chartProgressCurve = require("assets/images/reports/Progress_S_Curve.png");
const chartProjectCashFlow = require("assets/images/reports/Project_Cashflow.png");
const chartProgressStatus = require("assets/images/reports/Project_Progress_Pie.png");
const chartProjectOverview = require("assets/images/reports/Project_Overview.png");
const auditsIcon = require("assets/images/reports/audit.png");

const Audits = (props: any = {}) => {
  const { childRef } = props;
  const [dashMenu, setDashMenu] = useState(LABELS.HOME);
  const setMenu = () => {
    setDashMenu(LABELS.HOME);
  };
  childRef.setMenu = setMenu;

  return (
    <React.Fragment>
      {dashMenu == LABELS.HOME && (
        <div className="userDashboard">
          <div className="pageInfo">
            <ul className="breadcrumbs">
              <li>{LABELS.HOME}</li>
              <li className="dim">{" / "}</li>
              <li className="active">{LABELS.AUDITS}</li>
            </ul>
            <h4>{LABELS.AUDITS}</h4>
          </div>
          <MenuList dense className="dashboardMenuWrap">
            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.AUDIT_2);
              }}
            >
              <img src={auditsIcon} />
              <h4>{LABELS.AUDIT}</h4>
            </MenuItem>

            {/* <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setAuditDashMenu(LABELS.AUDIT_2);
              }}
            >
              <img src={auditsIcon} />
              <h4>{LABELS.AUDIT_2}</h4>
            </MenuItem> */}

            {/* <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setAuditDashMenu("Audit 2");
              }}
            >
              <img src={auditsIcon} />
              <h4>{"Audit 2"}</h4>
            </MenuItem>

            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setAuditDashMenu("Audit 3");
              }}
            >
              <img src={auditsIcon} />
              <h4>{"Audit 3"}</h4>
            </MenuItem>
            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setAuditDashMenu("Audit 4");
              }}
            >
              <img src={auditsIcon} />
              <h4>{"Audit 4"}</h4>
            </MenuItem>
            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setAuditDashMenu("Audit 5");
              }}
            >
              <img src={auditsIcon} />
              <h4>{"Audit 5"}</h4>
            </MenuItem>
            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setAuditDashMenu("Audit 6");
              }}
            >
              <img src={auditsIcon} />
              <h4>{"Audit 6"}</h4>
            </MenuItem>
            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setAuditDashMenu("Audit 7");
              }}
            >
              <img src={auditsIcon} />
              <h4>{"Audit 7"}</h4>
            </MenuItem> */}
          </MenuList>
        </div>
      )}

      {dashMenu == LABELS.AUDIT && <Audit_1 setDashMenu={setDashMenu} />}
      {dashMenu == LABELS.AUDIT_2 && <Audit_2 setDashMenu={setDashMenu} />}
    </React.Fragment>
  );
};

export default Audits;
