import React, { useEffect, useRef, useState } from "react";
import { withSnackbar } from "notistack";
// Data Tables
import jQuery from "jquery";
import "datatables.net-bs4";
import "datatables.net-buttons-bs4";
import "datatables.net-buttons/js/buttons.html5";
import "datatables.net-buttons/js/buttons.print";
import "datatables.net-buttons/js/buttons.flash";
import "pdfmake/build/pdfmake";
import "pdfmake/build/vfs_fonts";

import Loader from "components/Custom/Loader";
import AutoComplete from "components/Custom/AutoComplete";
import TxtBox from "components/Custom/TxtBox";
import Btn from "components/Custom/Btn";

import { LABELS, NOTIFY, WARNINGS } from "common/config";
import { DateFunctions } from "common/datefunctions";
import { ApiDashboard } from "apihandlers/dashboard";
import { ApiHome } from "apihandlers/home";
import { COMMON_JS } from "common/scripts";

const API_HANDLER_DASHBOARD = new ApiDashboard();
const API_HANDLER_HOME = new ApiHome();

const $ = jQuery;

const ReportConsolidated = (props: any) => {
  const [loading, setLoading] = useState(true);
  const [chartData, setChartData] = useState<any>({});
  const [clients, setClients] = useState<any>([]);

  useEffect(() => {
    if (loading) {
      API_HANDLER_HOME.getOrganizations({}).then((res: any) => {
        if (res.status === 200) {
          setClients(
            res.response.records.map((r) => {
              return {
                id: r.id,
                label: r.orgName + " (" + r.totalProjects + ")",
              };
            })
          );
          setLoading(false);
        }
      });
    }
  }, [loading]);

  const generateReportGrid = (res: any) => {
    let title = LABELS.CONSOLIDATED_REPORT;
    if (projectRef.current) {
      title += " - " + projectRef.current.selectedValue.label;
    }
    $("#reportGrid").DataTable({
      dom: "Bfrtip",
      buttons: [
        {
          extend: "csv",
          className: "reportBtn",
          title: title,
          text: "<i class='fa fa-download'></i>",
        },
      ],
      paging: true,
      pageLength: 15,
      columns: res.cols,
      data: res.rows,
      order: [],
    });
  };

  const loadChart = () => {
    const orgId = clientsRef.current.selectedValue.id;
    const statusDt = (document.getElementById("statusDt") as HTMLInputElement)
      .value;
    if (!orgId) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.ORG_EMPTY);
      return;
    }
    const args = {
      orgId,
      statusDt,
    };
    if ($.fn.dataTable.isDataTable("#reportGrid")) {
      let table = $("#reportGrid").DataTable();
      table.destroy();
    }
    if ($("#reportGrid")) {
      $("#reportGrid").empty();
    }
    setChartData(null);
    API_HANDLER_DASHBOARD.getConsolidatedReport(args).then((res) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        setChartData(response);
        setTimeout(() => {
          generateReportGrid(response);
        }, 500);
      }
    });
  };

  const projectRef: any = useRef();
  const clientsRef: any = useRef();

  console.log("CONSOLIDATED_REPORT: ", clients, chartData);

  return (
    <React.Fragment>
      <div className="pageInfo">
        <ul className="breadcrumbs">
          <li>{LABELS.HOME}</li>
          <li className="dim">{" / "}</li>
          <li onClick={() => props.setDashMenu(LABELS.HOME)}>
            {LABELS.DASHBOARD}
          </li>
          <li className="dim">{" / "}</li>
          <li className="active">{LABELS.CONSOLIDATED_REPORT}</li>
        </ul>
        <h4>{LABELS.CONSOLIDATED_REPORT}</h4>
      </div>
      {loading ? (
        <div className="flexCenter" style={{ minHeight: 450 }}>
          <Loader />
        </div>
      ) : (
        <div className="hChart">
          <div id="chartForm" style={{ width: "100%", marginTop: 0 }}>
            <div
              className="formGroup"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-end",
                marginTop: 5,
                marginBottom: 20,
                width: "100%",
              }}
            >
              <div
                style={{
                  width: 250,
                  marginRight: 15,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "flex-start",
                }}
              >
                <label
                  style={{
                    fontWeight: "500",
                    fontSize: 14,
                    marginTop: 6,
                    marginRight: 8,
                  }}
                >
                  {LABELS.ORGANIZATION}
                </label>
                <AutoComplete
                  id={"clients"}
                  list={clients}
                  childRef={clientsRef}
                  className={"width100"}
                />
              </div>

              <div
                style={{
                  width: 150,
                  marginRight: 15,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "flex-start",
                }}
              >
                <label
                  style={{
                    fontWeight: "500",
                    fontSize: 14,
                    marginTop: 6,
                    marginRight: 8,
                  }}
                >
                  {LABELS.SELECT_DATE}
                </label>
                <TxtBox
                  type={"date"}
                  id="statusDt"
                  defaultValue={DateFunctions.getDate(
                    "today",
                    "from",
                    "YYYY-MM-DD"
                  )}
                />
              </div>

              <div
                style={{
                  display: "flex",
                }}
              >
                <Btn
                  text={LABELS.LOAD}
                  onClick={loadChart}
                  loading={!chartData}
                />
              </div>
            </div>
          </div>

          {chartData ? (
            <div className="reportGridWrapper">
              <table
                id="reportGrid"
                className="mdl-data-table width100"
                cellSpacing="0"
                cellPadding="3"
              />
            </div>
          ) : (
            <div
              className="flexCenter"
              style={{ minHeight: 400, width: "100%" }}
            >
              <Loader />
            </div>
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default withSnackbar(ReportConsolidated);
