import React, { useEffect, useState } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { withSnackbar } from "notistack";
import { IconButton, Tooltip } from "@mui/material";
import {
  Replay as IconReload,
  AddBox as IconAdd,
  Edit as IconEdit,
  Check as IconCheck,
  Close as IconClose,
} from "@mui/icons-material";

import TxtBox from "components/Custom/TxtBox";
import UiDialog from "components/Custom/UiDialog";
import Btn from "components/Custom/Btn";
import SwitchBtn from "components/Custom/SwitchBtn";
import Loader from "components/Custom/Loader";

import {
  API_CONFIG,
  COLORS,
  CONFIG,
  ERRORS,
  LABELS,
  NOTIFY,
  SUCCESS,
  WARNINGS,
} from "common/config";
import { ApiHome } from "apihandlers/home";
import { COMMON_JS } from "common/scripts";
import ExcelGrid from "components/Custom/ExcelGrid";

const thumbnailImg = require("assets/images/thumbnail.jpg");

const API_HANDLER_HOME = new ApiHome();

let _timer = null;

const OrgsPage = (props: any) => {
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [rows, setRows] = useState<any>(null);
  const [filteredRows, setFilteredRows] = useState([]);
  const [manageDialog, setManageDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [uploadPhoto, setUploadPhoto] = useState(true);
  const [editRows, setEditRows] = useState<any[]>(null);

  useEffect(() => {
    if (loading) {
      getUsers();
    }
  }, [loading]);

  const getUsers = () => {
    const args = { dropdown: true };
    API_HANDLER_HOME.getUsers(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        setUsers(response.records);
        getOrgs();
      }
    });
  };

  const getOrgs = () => {
    const args = {};
    API_HANDLER_HOME.getOrganizations(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        setRows(response.records);
        setLoading(false);
      }
    });
  };

  const onEditRow = (row: any) => {
    setSelectedRow(row);
    setManageDialog(true);
  };

  const onDeleteRow = (row: any) => {
    setSelectedRow({ row, delete: true });
  };

  const columns = [
    {
      key: "slNo",
      name: "Sl No.",
      width: "5%",
      type: "number",
      align: "center",
      headerAlign: "center",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "logo",
      name: "Logo",
      width: "10%",
      align: "center",
      renderCell: (params: any) =>
        params.row.logo && (
          <div>
            <img
              src={API_CONFIG.API_URL + params.row.logo}
              height={60}
              style={{
                width: "70%",
                marginLeft: "1rem",
                marginTop: "0%",
              }}
            />
          </div>
        ),
    },
    {
      key: "orgName",
      name: "Organization",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      width: "20%",
    },
    {
      key: "incharge",
      name: "Incharge",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      width: "20%",
    },
    {
      key: "emailId",
      name: "Email ID",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      width: "20%",
    },
    // { key: "totalUsers", name: "Total Users", width: 130 },
    {
      key: "totalProjects",
      name: "Total Projects",
      width: "10%",
      align: "center",
      headerAlign: "center",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "active",
      name: "Active",
      width: "10%",
      align: "center",
      type: "dropdown",
      editable: false,
      sortable: true,
      searchable: true,
      filters: [
        { id: "true", name: "Yes" },
        { id: "false", name: "No" },
      ],
      headerAlign: "center",
      renderCell: (params: any) =>
        params.row.active ? (
          <div className="activeColumn" style={{ height: "100%" }}>
            <Tooltip title={LABELS.ACTIVE} placement="bottom">
              <IconCheck className="green" />
            </Tooltip>
          </div>
        ) : (
          <div className="activeColumn" style={{ height: "100%" }}>
            <Tooltip title={LABELS.INACTIVE} placement="bottom">
              <IconClose className="red" />
            </Tooltip>
          </div>
        ),
    },
    {
      key: "options",
      name: "Options",
      align: "center",
      width: "5%",
      renderCell: (params) => (
        <div className="options">
          <Tooltip title={LABELS.EDIT} placement="bottom">
            <IconButton
              className="iconBtn"
              onClick={() => onEditRow(params.row)}
            >
              <IconEdit />
            </IconButton>
          </Tooltip>
          {/* <Tooltip title={LABELS.DELETE} placement="bottom">
            <IconButton
              className="iconBtn"
              onClick={() => onDeleteRow(params.row)}
            >
              <IconDelete />
            </IconButton>
          </Tooltip> */}
        </div>
      ),
    },
  ];

  const manageOrg = () => {
    const orgName = (
      document.getElementById("orgName") as HTMLInputElement
    ).value.trim();
    const incharge = (
      document.getElementById("incharge") as HTMLInputElement
    ).value.trim();
    const active = (document.getElementById("isActive") as HTMLInputElement)
      .checked;
    const address1 = (
      document.getElementById("address1") as HTMLInputElement
    ).value.trim();
    const address2 = (
      document.getElementById("address2") as HTMLInputElement
    ).value.trim();
    const zipcode = (
      document.getElementById("zipcode") as HTMLInputElement
    ).value.trim();
    const contactNumber = (
      document.getElementById("contactNumber") as HTMLInputElement
    ).value.trim();
    const emailId = (
      document.getElementById("emailId") as HTMLInputElement
    ).value.trim();

    // Validations
    if (!orgName) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.ORG_NAME_IS_EMPTY);
      return;
    }
    if (!incharge) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.INCHARGE_IS_EMPTY);
      return;
    }
    if (!address1) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.ADDRESS_IS_EMPTY);
      return;
    }
    if (!zipcode) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.ZIPCODE_IS_EMPTY);
      return;
    }
    if (!contactNumber) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.CONTACT_NO_IS_EMPTY);
      return;
    }
    if (emailId != "") {
      let isValidEmail = String(emailId)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
      if (!isValidEmail) {
        COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.INVALID_EMAIL);
        return;
      }
    }

    const args = {
      orgId: selectedRow.id,
      incharge,
      active,
      orgName,
      address1,
      address2,
      zipcode,
      contactNumber,
      emailId,
      logo: (
        document.getElementById("orgLogo") as HTMLInputElement
      ).getAttribute("file"),
    };
    console.log("manageOrg: ", args);
    API_HANDLER_HOME.manageOrganization(args).then((res) => {
      if (res.status === 200) {
        COMMON_JS.showNotify(
          props,
          NOTIFY.SUCCESS,
          SUCCESS.ORGANIZATION_SUCCESS
        );
        setSelectedRow({});
        setManageDialog(false);
        onReload();
      } else {
        COMMON_JS.showNotify(props, NOTIFY.ERROR, ERRORS.ORGANIZATION_FAILED);
      }
    });
  };

  const onReload = () => {
    setRows(null);
    getUsers();
    // setTimeout(() => {
    // }, 2000);
  };

  const onAdd = () => {
    setManageDialog(!manageDialog);
    setEditRows([]);
    setSelectedRow({});
  };

  const onSearch = (e: any) => {
    const qry = e.target.value.trim().toUpperCase();
    clearTimeout(_timer);
    _timer = setTimeout(() => {
      let flist = rows.filter((r: any) => {
        return r.orgName.toUpperCase().indexOf(qry) > -1;
      });
      console.log("flist: ", rows, flist, qry);
      setFilteredRows(flist);
    }, 150);
  };

  const uploadLogo = (e: any, f: any) => {
    const file: any = e.target.files[0];
    if (file.size / 1024 / 1024 > 2) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        "File size should not exceed more than 2MB..!"
      );
      return;
    }
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let base64: any = reader.result;
      (document.getElementById("orgLogo") as HTMLInputElement).setAttribute(
        "src",
        base64
      );
      setUploadPhoto(true);
      uploadFile(file, base64).then((res) => {
        console.log("res: ", res);
        setUploadPhoto(false);
        (document.getElementById("orgLogo") as HTMLInputElement).setAttribute(
          "file",
          res.id
        );
        (
          document.getElementById("orgLogoImg") as HTMLInputElement
        ).setAttribute("src", API_CONFIG.API_URL + res.path);
      });
    };
    reader.onerror = (error) => {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, "File upload failed..!");
      // console.log("uploadFile: Error:", error);
    };
  };

  const uploadFile = async (file: any, base64 = "") => {
    const args = {
      recordRefId: 1,
      file: {
        name: file.name,
        data: base64.replace("data:", "").replace(/^.+,/, ""),
        type: file.name.split(".")[1],
        size: file.size,
      },
      category: "org",
    };
    let fileInfo = null;
    try {
      const res = await API_HANDLER_HOME.fileUpload(args);
      console.log("uploadFile: ", args, res);
      const response: any = res.response;
      if (res.status === 200) {
        fileInfo = response;
      }
    } catch (error) {}
    return fileInfo;
  };

  console.log("Users: ", loading, selectedRow.active);

  return (
    <React.Fragment>
      <div className="pageInfo">
        <ul className="breadcrumbs">
          <li>{LABELS.HOME}</li>
          <li className="dim">{" / "}</li>
          <li className="active">{LABELS.ORGANIZATIONS}</li>
        </ul>
        <h4>{LABELS.ORGANIZATIONS}</h4>
        {/* <p>{SUB_TITLES.USERS}</p> */}
      </div>

      {rows ? (
        <ExcelGrid
          id={"organization"}
          height={window.innerHeight * 0.6}
          cols={columns}
          rows={rows}
          exportOption={true}
          //newRowOption={false}
          exportName={LABELS.ORGANIZATIONS}
          onReload={onReload}
          onAdd={onAdd}
        />
      ) : (
        <div className="pageCenter">
          <Loader />
        </div>
      )}

      {manageDialog && (
        <UiDialog
          title={
            selectedRow.id
              ? "Edit Organization: " + selectedRow.orgName
              : "Add New Organization"
          }
          open={true}
          size={"sm"}
          onClose={() => {
            setManageDialog(false);
          }}
        >
          <div
            className="formGroup"
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <label>{LABELS.ORGANIZATION_LOGO}</label>
            {/* <ThumbnailImg /> */}
            <img
              src={
                selectedRow.id
                  ? API_CONFIG.API_URL + selectedRow.logo
                  : thumbnailImg
              }
              id={"orgLogoImg"}
              style={{ width: "30%" }}
            />
            <TxtBox
              id={"orgLogo"}
              type={"file"}
              className="uploadFile"
              accept="image/*"
              onChange={uploadLogo}
            />
          </div>
          <div className="formGroup">
            <label style={{ color: "red" }}>{LABELS.ORGANIZATION} *</label>
            <TxtBox
              id={"orgName"}
              placeholder={LABELS.ORGANIZATION}
              defaultValue={selectedRow.orgName}
            />
          </div>
          <div className="formGroup">
            <label style={{ color: "red" }}>{LABELS.INCHARGE} *</label>
            <TxtBox
              id={"incharge"}
              placeholder={LABELS.INCHARGE}
              defaultValue={selectedRow.incharge}
            />
          </div>
          <div className="formGroup">
            <label style={{ color: "red" }}>{LABELS.ADDRESS_1} *</label>
            <TxtBox
              id={"address1"}
              placeholder={LABELS.ADDRESS_1}
              defaultValue={selectedRow.address1}
            />
          </div>
          <div className="formGroup">
            <label>{LABELS.ADDRESS_2}</label>
            <TxtBox
              id={"address2"}
              placeholder={LABELS.ADDRESS_2}
              defaultValue={selectedRow.address2}
            />
          </div>
          <div className="formGroup">
            <label style={{ color: "red" }}>{LABELS.ZIPCODE} *</label>
            <TxtBox
              id={"zipcode"}
              placeholder={LABELS.ZIPCODE}
              defaultValue={selectedRow.zipcode}
            />
          </div>
          <div className="formGroup">
            <label style={{ color: "red" }}>{LABELS.CONTACT_NUMBER} *</label>
            <TxtBox
              id={"contactNumber"}
              placeholder={LABELS.CONTACT_NUMBER}
              defaultValue={selectedRow.contactNumber}
            />
          </div>
          <div className="formGroup">
            <label>{LABELS.EMAIL_ID}</label>
            <TxtBox
              id={"emailId"}
              placeholder={LABELS.EMAIL_ID}
              defaultValue={selectedRow.emailId}
            />
          </div>
          <div className="formGroup">
            <SwitchBtn
              text={LABELS.ACTIVE}
              id={"isActive"}
              checked={selectedRow.id ? selectedRow.active : true}
            />
          </div>
          <div
            className="formGroup"
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                width: "45%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Btn text={LABELS.SAVE} onClick={manageOrg} />
              <Btn
                text={LABELS.CANCEL}
                bgColor={COLORS.DIM_DARK_GRAY}
                onClick={() => setManageDialog(false)}
              />
            </div>
          </div>
        </UiDialog>
      )}
    </React.Fragment>
  );
};

export default withSnackbar(OrgsPage);
