import React from "react";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";

import { COLORS, DEFAULTS } from "common/config";

const Btn = (props: any) => {
  const {
    id,
    className,
    variant,
    size,
    text,
    bgColor,
    color,
    fontSize,
    disabled,
    shadow,
    href,
    startIcon,
    endIcon,
    loading,
    marginRight,
    paddingLeft,
    paddingRight,
    onClick,
  } = props;
  let backgroundColor = bgColor || COLORS.PRIMARY;
  let colorVal = color || COLORS.WHITE;
  if (disabled) {
    backgroundColor = COLORS.DIM_GRAY;
    colorVal = COLORS.WHITE;
  }
  return !loading ? (
    <Button
      id={id}
      style={{
        backgroundColor: backgroundColor,
        color: colorVal,
        fontSize: fontSize || 14,
        paddingLeft: paddingLeft || 45,
        paddingRight: paddingRight || 45,
        paddingTop: 6,
        marginRight: marginRight || 0,
      }}
      className={"uiBtn " + (className ? className : "")}
      variant={variant || DEFAULTS.BTN_TYPE}
      size={size || "small"}
      // disableElevation={shadow}
      disabled={disabled}
      href={href}
      startIcon={startIcon}
      endIcon={endIcon}
      onClick={onClick}
    >
      {text}
    </Button>
  ) : (
    <LoadingButton
      loading
      // loadingPosition="start"
      startIcon={startIcon}
      variant="outlined"
      size={size || "small"}
      className={className || ""}
      style={{
        fontSize: fontSize || 12,
        paddingLeft: 45,
        paddingRight: 45,
        paddingTop: 6,
        color: COLORS.DIM_DARK_GRAY,
      }}
    >
      {text}
    </LoadingButton>
  );
};

export default Btn;
