/*
 * Safety Monitoring & Measurement Form
 */

import React, { useRef, useEffect, useState } from "react";
import { withSnackbar } from "notistack";

import ExcelGrid from "components/Custom/ExcelGrid";
import Loader from "components/Custom/Loader";

import { LABELS, NOTIFY, SUCCESS } from "common/config";
import { ApiEhs } from "apihandlers/ehs";
import { COMMON_JS } from "common/scripts";
import Btn from "components/Custom/Btn";
import HGraph from "components/Custom/HGraph";

const API_HANDLER_EHS = new ApiEhs();

const Capa = (props: any) => {
  const {} = props;
  const mainGridCols = [
    {
      key: "slNo",
      name: "Sl No.",
      type: "number",
      width: "5%",
      editable: false,
      sortable: true,
      align: "center",
      searchable: true,
      frozen: true,
    },
    {
      key: "categoryname",
      name: "Category",
      type: "text",
      width: "20%",
      editable: false,
      sortable: true,
      searchable: true,
      frozen: true,
    },
    {
      key: "createdby",
      name: " Created By",
      type: "text",
      width: "10%",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "itemcategory",
      name: " Item Category",
      type: "text",
      width: "20%",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "correctiveplan",
      name: " Action/ Corrective Plan",
      type: "text",
      width: "20%",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "createdon",
      name: "Created On",
      type: "text",
      width: "10%",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "priority",
      name: "Priority",
      type: "dropdown",
      list: [
        { id: "none", label: "None" },
        { id: "high", label: "High" },
        { id: "low", label: "Low" },
        { id: "medium", label: "Medium" },
      ],
      editable: true,
      sortable: true,
      searchable: true,
      filters: [
        {
          id: "none",
          name: "None",
        },
        {
          id: "high",
          name: "High",
        },
        {
          id: "low",
          name: "Low",
        },
        {
          id: "medium",
          name: "Medium",
        },
      ],
      width: "5%",
    },
    {
      key: "comments",
      name: "Comments",
      type: "text",
      editable: true,
      sortable: true,
      searchable: true,
      width: "30%",
    },
    {
      key: "status",
      name: "Status",
      type: "dropdown",
      list: [
        { id: "none", label: "None" },
        { id: "open", label: "Open" },
        { id: "closed", label: "Closed" },
        { id: "inprogress", label: "In-Progress" },
      ],
      editable: true,
      sortable: true,
      searchable: true,
      filters: [
        {
          id: "none",
          name: "None",
        },
        {
          id: "open",
          name: "Open",
        },
        {
          id: "closed",
          name: "Closed",
        },
        {
          id: "inprogress",
          name: "In-Progress",
        },
      ],

      width: "5%",
    },
  ];
  const [loading, setLoading] = useState<boolean>(true);
  const [cols, setCols] = useState<any[]>(mainGridCols);
  const [rows, setRows] = useState<any[]>(null);
  const [pieChart, setPieChart] = useState<any>({});
  useEffect(() => {
    if (loading) {
      getRows();
    }
  }, [loading]);

  const getRows = () => {
    setRows(null);
    const args = {};
    API_HANDLER_EHS.getCapas(args).then((res: any) => {
      const { status, response } = res;
      if (status === 200) {
        const list = response.records;
        for (let i = 0; i < response.records.length; i++) {
          list[i]["slNo"] = i + 1;
          const capa = list.find((r: any) => r.capaid === list[i].capaid);
          // console.log("capa: ", i, capa, list[i], list);
          list[i].comments = capa ? capa.comments : "";
          list[i].priority = capa ? capa.priority : "None";
          list[i].status = capa ? capa.status : "None";
        }
        setRows(list);
        setPieChart(response.pieChart);
        setLoading(false);
      }
    });
  };

  const onManageCapa = async () => {
    try {
      setLoading(true);
      const rows = gridApprovalRef.getRowData();
      let successCount = 0;
      for (let i in rows) {
        const args = {
          ehsId: rows[i].ehsid,
          categoryName: rows[i].categoryname,
          capaId: rows[i].capaid || -1,
          comment: rows[i].comments,
          // correctiveplan: rows[i].correctiveplan,
          // createdOn: rows[i].createdOn,
          // createdby: rows[i].createdby,
          // itemcategory: rows[i].itemcategory,
          priority: rows[i].priority,
          status: rows[i].status,
        };
        //console.log("args:", args);
        const res = await API_HANDLER_EHS.manageCapa(args);
        if (res.status === 200) {
          successCount++;
        }
      }
      setLoading(false);
      if (successCount === rows.length) {
        COMMON_JS.showNotify(props, NOTIFY.SUCCESS, SUCCESS.RECORD_SAVED);
      }
    } catch (error) {
      console.error("manageCapa: ", error);
    }
  };

  const onReload = () => {
    getRows();
  };
  const gridApprovalRef: any = useRef(null);

  return (
    <React.Fragment>
      <div className="pageInfo">
        <ul className="breadcrumbs">
          <li>{LABELS.HOME}</li>
          <li className="dim">{" / "}</li>
          <li onClick={() => props.setDashMenu(LABELS.HOME)}>{LABELS.EHS}</li>
          <li className="dim">{" / "}</li>
          <li className="active">{LABELS.CAPA}</li>
        </ul>
        <h4>{LABELS.CAPA}</h4>
      </div>

      {pieChart.series1 && (
        <div className="graphs">
          <HGraph
            height={window.innerHeight * 0.3}
            width={"25%"}
            type={"pie"}
            float={"right"}
            series={pieChart.series1}
            title={"Safety  Monitor"}
            exportTitle={""}
          />
          <HGraph
            height={window.innerHeight * 0.3}
            width={"25%"}
            type={"pie"}
            float={"right"}
            series={pieChart.series2}
            title={"SOR"}
            exportTitle={" "}
          />
          <HGraph
            height={window.innerHeight * 0.3}
            width={"25%"}
            type={"pie"}
            float={"right"}
            series={pieChart.series3}
            title={"Near Miss Reports"}
            exportTitle={" "}
          />
          <HGraph
            height={window.innerHeight * 0.3}
            width={"25%"}
            type={"pie"}
            float={"right"}
            series={pieChart.series4}
            title={"Performance Audit"}
            exportTitle={" "}
          />
        </div>
      )}
      {/* Master Grid */}
      {rows ? (
        <>
          <ExcelGrid
            id={"capa"}
            height={window.innerHeight * 0.5}
            cols={cols}
            rows={rows}
            childRef={gridApprovalRef}
            exportOption={true}
            newRowOption={false}
            exportName={LABELS.CAPA}
            onReload={onReload}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "right",
              width: "98%",
            }}
          >
            <div
              style={{
                width: "35%",
                display: "flex",

                justifyContent: "right",
              }}
            >
              {/* <Btn
                text={LABELS.CANCEL}
                bgColor={COLORS.DIM_DARK_GRAY}
                marginRight={15}
              /> */}
              <Btn text={LABELS.SAVE} onClick={onManageCapa} />
            </div>
          </div>
        </>
      ) : (
        <div className="pageCenter">
          <Loader />
        </div>
      )}
    </React.Fragment>
  );
};

export default withSnackbar(Capa);
