// import React, { useRef, useState } from "react";
// import { MenuList, MenuItem } from "@mui/material";
// import { Menu as MenuIcon } from "@mui/icons-material";

// import Generate from "./Generate";
// import ReviewAndComments from "./ReviewAndComments";
// import CommentUpdates from "./CommentUpdates";
// import PreApproval from "./PreApproval";
// import Execution from "./Execution";
// import PostApproval from "./PostApproval";

// import { CONFIG, LABELS, TITLES } from "common/config";

// import "devextreme/dist/css/dx.light.css";

// const cqvIcon = require("assets/images/reports/list.png");
// const generateIcon = require("assets/images/cqv/generate.png");
// const commentsIcon = require("assets/images/cqv/comments.png");
// const commentsUpdateIcon = require("assets/images/cqv/comments_update.png");
// const preApprovalIcon = require("assets/images/cqv/pre_approval.png");
// const executionIcon = require("assets/images/cqv/execution.png");
// const postApprovalIcon = require("assets/images/cqv/post_approval.png");

// const Cqv = (props: any = {}) => {
//   const [dashMenu, setDashMenu] = useState(LABELS.HOME);
//   const { childRef, userInfo } = props;
//   const setMenu = () => {
//     setDashMenu(LABELS.HOME);
//   };
//   childRef.setMenu = setMenu;

//   const trainingRef: any = useRef(null);

//   return (
//     <React.Fragment>
//       {dashMenu == LABELS.HOME && (
//         <div className="userDashboard">
//           <div className="pageInfo">
//             <ul className="breadcrumbs">
//               <li>{LABELS.HOME}</li>
//               <li className="dim">{" / "}</li>
//               <li className="active">{TITLES.CQV}</li>
//             </ul>
//             <h4>{TITLES.CQV}</h4>
//           </div>
//           <MenuList dense className="dashboardMenuWrap">
//             <MenuItem
//               className="dashboardMenu"
//               onClick={() => {
//                 setDashMenu(TITLES.CQV_GENERATE);
//               }}
//             >
//               <img src={generateIcon} />
//               <h4>{TITLES.CQV_GENERATE}</h4>
//             </MenuItem>

//             <MenuItem
//               className="dashboardMenu"
//               onClick={() => {
//                 setDashMenu(TITLES.CQV_REVIEW_AND_COMMENTS);
//               }}
//             >
//               <img src={commentsIcon} />
//               <h4>{TITLES.CQV_REVIEW_AND_COMMENTS}</h4>
//             </MenuItem>

//             <MenuItem
//               className="dashboardMenu"
//               onClick={() => {
//                 setDashMenu(TITLES.CQV_COMMENTS_UPDATE);
//               }}
//             >
//               <img src={commentsUpdateIcon} />
//               <h4>{TITLES.CQV_COMMENTS_UPDATE}</h4>
//             </MenuItem>

//             <MenuItem
//               className="dashboardMenu"
//               onClick={() => {
//                 setDashMenu(TITLES.CQV_PRE_APPROVAL);
//               }}
//             >
//               <img src={preApprovalIcon} />
//               <h4>{TITLES.CQV_PRE_APPROVAL}</h4>
//             </MenuItem>

//             <MenuItem
//               className="dashboardMenu"
//               onClick={() => {
//                 setDashMenu(TITLES.CQV_EXECUTION);
//               }}
//             >
//               <img src={executionIcon} />
//               <h4>{TITLES.CQV_EXECUTION}</h4>
//             </MenuItem>

//             <MenuItem
//               className="dashboardMenu"
//               onClick={() => {
//                 setDashMenu(TITLES.CQV_POST_APPROVAL);
//               }}
//             >
//               <img src={postApprovalIcon} />
//               <h4>{TITLES.CQV_POST_APPROVAL}</h4>
//             </MenuItem>
//           </MenuList>
//         </div>
//       )}

//       {dashMenu == TITLES.CQV_GENERATE && (
//         <Generate setDashMenu={setDashMenu} />
//       )}

//       {dashMenu == TITLES.CQV_REVIEW_AND_COMMENTS && (
//         <ReviewAndComments setDashMenu={setDashMenu} />
//       )}

//       {dashMenu == TITLES.CQV_COMMENTS_UPDATE && (
//         <CommentUpdates setDashMenu={setDashMenu} />
//       )}

//       {dashMenu == TITLES.CQV_PRE_APPROVAL && (
//         <PreApproval setDashMenu={setDashMenu} />
//       )}

//       {dashMenu == TITLES.CQV_EXECUTION && (
//         <Execution setDashMenu={setDashMenu} />
//       )}

//       {dashMenu == TITLES.CQV_POST_APPROVAL && (
//         <PostApproval setDashMenu={setDashMenu} />
//       )}
//     </React.Fragment>
//   );
// };

// export default Cqv;

import React, { useEffect, useRef, useState } from "react";
import { withSnackbar } from "notistack";
import { IconButton, Tooltip } from "@mui/material";
import {
  Replay as IconReload,
  AddBox as IconAdd,
  Edit as IconEdit,
  Delete as IconDelete,
  Check as IconCheck,
  Close as IconClose,
} from "@mui/icons-material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

import TxtBox from "components/Custom/TxtBox";
import UiDialog from "components/Custom/UiDialog";
import Btn from "components/Custom/Btn";
import SwitchBtn from "components/Custom/SwitchBtn";
import AutoComplete from "components/Custom/AutoComplete";
import ConfirmDialog from "components/Custom/ConfirmDialog";
import Loader from "components/Custom/Loader";

import {
  COLORS,
  CONFIG,
  CONFIRMS,
  ERRORS,
  LABELS,
  NOTIFY,
  SUCCESS,
  TITLES,
  WARNINGS,
} from "common/config";
import { ApiLogin } from "apihandlers/login";
import { ApiHome } from "apihandlers/home";
import { COMMON_JS } from "common/scripts";
import ExcelGrid from "components/Custom/ExcelGrid";

const API_HANDLER_HOME = new ApiHome();

let _timer = null;

const Cqv = (props: any) => {
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState<any>([]);
  const [projects, setProjects] = useState<any>([]);

  const [filteredRows, setFilteredRows] = useState([]);
  const [userDialog, setAddUserDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [organizations, setOrganizations] = useState([]);
  const [editRows, setEditRows] = useState<any[]>(null);
  const [iocDraft, setIocDraft] = useState(
    selectedRow.iocPreDraftId ? selectedRow.iocPreDraftId : ""
  );
  const [ioqDraft, setIoqDraft] = useState(
    selectedRow.ioqPreDraftId ? selectedRow.ioqPreDraftId : ""
  );

  const status = [
    { id: "1", label: "Not Started" },
    { id: "2", label: "In Progress" },
    { id: "3", label: "Completed" },
  ];

  const systemNo = [
    { id: "002", label: "002" },
    { id: "005", label: "005" },
    { id: "951", label: "951" },
    { id: "1000", label: "1000" },
    { id: "456", label: "456" },
    { id: "289", label: "289" },
  ];

  const systemName = [
    { id: "Suspension", label: "Suspension Prep & Supply" },
    { id: "VentilatedBalance", label: "Ventilated Balance" },
    { id: "TabletInspection", label: "Tablet Inspection Isolator" },
    { id: "WIPSkid", label: "WIP Skid" },
    { id: "VBEBenchScales", label: "VBE Bench Scales" },
    { id: "ExcipeintDownflowBooth", label: "Excipeint Downflow Booth" },
  ];

  const suiteTeam = [
    { id: "Suite 1", label: "Suite 1" },
    { id: "Suite 2", label: "Suite 2" },
    { id: "Suite 3", label: "Suite 3" },
    { id: "Suite 4", label: "Suite 4" },
    { id: "Suite 5", label: "Suite 5" },
    { id: "Suite 6", label: "Suite 6" },
  ];

  useEffect(() => {
    if (loading) {
      getProjects();
      getRows();
    }
  }, [loading]);

  const getProjects = () => {
    const args = { dropdown: true };
    API_HANDLER_HOME.getProjects(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        setProjects(response.records);
        setLoading(false);
      }
    });
  };

  const getRows = () => {
    setRows(null);
    setTimeout(() => {
      let tmpRows: any = localStorage.getItem("cqv") || "[]";
      tmpRows = JSON.parse(tmpRows);
      for (let i = 0; i < tmpRows.length; i++) {
        tmpRows[i]["slNo"] = i + 1;
      }

      setRows(tmpRows);
      setLoading(false);
    }, 500);
  };

  const onEditUser = (row: any) => {
    setSelectedRow(row);
    setAddUserDialog(true);
  };

  const onDeleteUser = (row: any) => {
    setSelectedRow({ row, delete: true });
  };

  const columns = [
    {
      key: "slNo",
      name: "Sl No.",
      width: "5%",
      type: "number",
      align: "center",
      editable: false,
      sortable: true,
      searchable: true,
      headerAlign: "center",
    },
    {
      key: "projectName",
      name: "Project Name",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      width: "25%",
    },
    {
      key: "systemNoName",
      name: "System Number",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      width: "20%",
    },
    {
      key: "systemName",
      name: "System Name",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      width: "20%",
    },
    {
      key: "suitTeamName",
      name: "Suite Team",
      width: "20%",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
    },
    // {
    //   key: "phoneNumber",
    //   name: "Phone",
    //   width: "10%",
    //   type: "text",
    //   editable: false,
    //   sortable: true,
    //   searchable: true,
    // },
    // {
    //   key: "userType",
    //   name: "User Type",
    //   width: "15%",
    //   type: "text",
    //   editable: false,
    //   sortable: true,
    //   searchable: true,
    // },
    // {
    //   key: "orgName",
    //   name: "Organization",
    //   width: "10%",
    //   type: "text",
    //   editable: false,
    //   sortable: true,
    //   searchable: true,
    // },
    // {
    //   key: "active",
    //   name: "Active",
    //   type: "dropdown",
    //   filters: [
    //     { id: "yes", name: "Yes" },
    //     { id: "no", name: "No" },
    //   ],
    //   editable: false,
    //   sortable: true,
    //   searchable: true,
    //   width: "10%",
    //   align: "center",
    //   headerAlign: "center",
    //   renderCell: (params: any) =>
    //     params.row.active ? (
    //       <div className="activeColumn" style={{ height: "100%" }}>
    //         <Tooltip title={LABELS.ACTIVE} placement="bottom">
    //           <IconCheck className="green" />
    //         </Tooltip>
    //       </div>
    //     ) : (
    //       <div className="activeColumn" style={{ height: "100%" }}>
    //         <Tooltip title={LABELS.INACTIVE} placement="bottom">
    //           <IconClose className="dim" />
    //         </Tooltip>
    //       </div>
    //     ),
    // },
    {
      key: "options",
      name: "Options",
      width: "10%",
      renderCell: (params) => (
        <div className="options">
          <Tooltip title={LABELS.EDIT} placement="bottom">
            <IconButton
              className="iconBtn"
              onClick={() => onEditUser(params.row)}
            >
              <IconEdit />
            </IconButton>
          </Tooltip>
          {/* <Tooltip title={LABELS.DELETE} placement="bottom">
            <IconButton
              className="iconBtn"
              onClick={() => onDeleteUser(params.row)}
            >
              <IconDelete />
            </IconButton>
          </Tooltip> */}
        </div>
      ),
    },
  ];

  const manageUser = () => {
    const projectId = proectRef.current.selectedValue.id;
    const projectName = proectRef.current.selectedValue.label;
    const sysytemNoId = systemNoRef.current.selectedValue.id;
    const systemNoName = systemNoRef.current.selectedValue.label;
    const systemNameId = systemNameRef.current.selectedValue.id;
    const systemName = systemNameRef.current.selectedValue.label;
    const suitTeamId = suitTeamRef.current.selectedValue.id;
    const suitTeamName = suitTeamRef.current.selectedValue.label;
    // IOC
    const iocPreDraftId = iocPreDraftRef.current.selectedValue.id;
    const iocPreDraftName = iocPreDraftRef.current.selectedValue.label;
    const iocPreDraftFinishDt = (
      document.getElementById("iocPreDraftFinishDt") as HTMLInputElement
    ).value.trim();
    const iocPreReviewId = iocPreReviewRef.current.selectedValue.id;
    const iocPreReviewName = iocPreReviewRef.current.selectedValue.label;
    const iocPreReviewFinishDt = (
      document.getElementById("iocPreReviewFinishDt") as HTMLInputElement
    ).value.trim();
    const iocPrecommentsId = iocPreCommentsRef.current.selectedValue.id;
    const iocPrecommentsName = iocPreCommentsRef.current.selectedValue.label;
    const iocPreCommentFinishDt = (
      document.getElementById("iocPreCommentFinishDt") as HTMLInputElement
    ).value.trim();
    const iocPrePreApprovalId = iocPrePreApprovalRef.current.selectedValue.id;
    const iocPrePreApprovalName =
      iocPrePreApprovalRef.current.selectedValue.label;
    const iocPreApprovalFinishDt = (
      document.getElementById("iocPreApprovalFinishDt") as HTMLInputElement
    ).value.trim();

    const iocExecutionId = iocExecutionRef.current.selectedValue.id;
    const iocExecutionName = iocExecutionRef.current.selectedValue.label;
    const iocExecutionFinishDt = (
      document.getElementById("iocExecutionFinishDt") as HTMLInputElement
    ).value.trim();

    const iocPostDraftId = iocPostDraftRef.current.selectedValue.id;
    const iocPostDraftName = iocPostDraftRef.current.selectedValue.label;
    const iocpostDraftFinishDt = (
      document.getElementById("iocpostDraftFinishDt") as HTMLInputElement
    ).value.trim();

    const iocPostReviewId = iocPostReviewRef.current.selectedValue.id;
    const iocPostReviewName = iocPostReviewRef.current.selectedValue.label;
    const iocPostReviewFinishDt = (
      document.getElementById("iocPostReviewFinishDt") as HTMLInputElement
    ).value.trim();
    const iocPostcommentsId = iocPostCommentsRef.current.selectedValue.id;
    const iocPostcommentsName = iocPostCommentsRef.current.selectedValue.label;
    const iocPostcommentsFinishDt = (
      document.getElementById("iocPostcommentsFinishDt") as HTMLInputElement
    ).value.trim();

    const iocPostApprovalId = iocPostApprovalRef.current.selectedValue.id;
    const iocPostApprovalName = iocPostApprovalRef.current.selectedValue.label;
    const iocPostApprovalFinishDt = (
      document.getElementById("iocPostApprovalFinishDt") as HTMLInputElement
    ).value.trim();

    // IOQ

    const ioqPreDraftId = ioqPreDraftRef.current.selectedValue.id;
    const ioqPreDraftName = ioqPreDraftRef.current.selectedValue.label;
    const ioqPreDraftFinishDt = (
      document.getElementById("ioqPreDraftFinishDt") as HTMLInputElement
    ).value.trim();
    const ioqPreReviewId = ioqPreReviewRef.current.selectedValue.id;
    const ioqPreReviewName = ioqPreReviewRef.current.selectedValue.label;
    const ioqPreReviewFinishDt = (
      document.getElementById("ioqPreReviewFinishDt") as HTMLInputElement
    ).value.trim();
    const ioqPrecommentsId = ioqPreCommentsRef.current.selectedValue.id;
    const ioqPrecommentsName = ioqPreCommentsRef.current.selectedValue.label;
    const ioqPreCommentsFinishDt = (
      document.getElementById("ioqPreCommentsFinishDt") as HTMLInputElement
    ).value.trim();
    const ioqPrePreApprovalId = ioqPrePreApprovalRef.current.selectedValue.id;
    const ioqPrePreApprovalName =
      ioqPrePreApprovalRef.current.selectedValue.label;
    const ioqPreApprovalFinishDt = (
      document.getElementById("ioqPreApprovalFinishDt") as HTMLInputElement
    ).value.trim();

    const ioqExecutionId = ioqExecutionRef.current.selectedValue.id;
    const ioqExecutionName = ioqExecutionRef.current.selectedValue.label;
    const ioqExecutionFinishDt = (
      document.getElementById("ioqExecutionFinishDt") as HTMLInputElement
    ).value.trim();

    const ioqPostDraftId = ioqPostDraftRef.current.selectedValue.id;
    const ioqPostDraftName = ioqPostDraftRef.current.selectedValue.label;
    const ioqpostDraftFinishDt = (
      document.getElementById("ioqpostDraftFinishDt") as HTMLInputElement
    ).value.trim();

    const ioqPostReviewId = ioqPostReviewRef.current.selectedValue.id;
    const ioqPostReviewName = ioqPostReviewRef.current.selectedValue.label;
    const ioqPostReviewFinishDt = (
      document.getElementById("ioqPostReviewFinishDt") as HTMLInputElement
    ).value.trim();
    const ioqPostcommentsId = ioqPostCommentsRef.current.selectedValue.id;
    const ioqPostcommentsName = ioqPostCommentsRef.current.selectedValue.label;
    const ioqPostcommentsFinishDt = (
      document.getElementById("ioqPostcommentsFinishDt") as HTMLInputElement
    ).value.trim();

    const ioqPostApprovalId = ioqPostApprovalRef.current.selectedValue.id;
    const ioqPostApprovalName = ioqPostApprovalRef.current.selectedValue.label;
    const ioqPostApprovalFinishDt = (
      document.getElementById("ioqPostApprovalFinishDt") as HTMLInputElement
    ).value.trim();
    //PSSR

    const walkDown1Id = walkDown1Ref.current.selectedValue.id;
    const walkDown1Name = walkDown1Ref.current.selectedValue.label;
    const walkDown1FinishDt = (
      document.getElementById("walkDown1FinishDt") as HTMLInputElement
    ).value.trim();

    const walkDown2Id = walkDown2Ref.current.selectedValue.id;
    const walkDown2Name = walkDown2Ref.current.selectedValue.label;
    const walkDown2FinishDt = (
      document.getElementById("walkDown2FinishDt") as HTMLInputElement
    ).value.trim();
    const walkDown3Id = walkDown3Ref.current.selectedValue.id;
    const walkDown3Name = walkDown3Ref.current.selectedValue.label;
    const walkDown3FinishDt = (
      document.getElementById("walkDown3FinishDt") as HTMLInputElement
    ).value.trim();

    // Validations
    // if (!firstName) {
    //   COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.FIRST_NAME_IS_EMPTY);
    //   return;
    // }
    // if (!userName) {
    //   COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.USER_NAME_IS_EMPTY);
    //   return;
    // }
    // if (!selectedRow.id) {
    //   if (!password) {
    //     COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.PASSWORD_IS_EMPTY);
    //     return;
    //   }
    // }
    // if (!userTypeId) {
    //   COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.USER_TYPE_EMPTY);
    //   return;
    // }
    // if (!orgId) {
    //   COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.ORG_EMPTY);
    //   return;
    // }

    const args = {
      // userId: selectedRow.id,
      // firstName,
      // lastName,
      // userName,
      // password,
      // emailId,
      // phoneNumber,
      // userTypeId,
      // active,
      // orgId,
      id: selectedRow.id ? selectedRow.id : rows.length + 1,
      projectId,
      projectName,
      sysytemNoId,
      systemNoName,
      systemNameId,
      systemName,
      suitTeamId,
      suitTeamName,
      //IOC
      iocPreDraftId,
      iocPreDraftName,
      iocPreDraftFinishDt,
      iocPrecommentsId,
      iocPrecommentsName,
      iocPreCommentFinishDt,
      iocPreReviewId,
      iocPreReviewName,
      iocPreReviewFinishDt,
      iocPrePreApprovalId,
      iocPrePreApprovalName,
      iocPreApprovalFinishDt,
      iocExecutionId,
      iocExecutionName,
      iocExecutionFinishDt,
      iocPostDraftId,
      iocPostDraftName,
      iocpostDraftFinishDt,
      iocPostReviewId,
      iocPostReviewName,
      iocPostReviewFinishDt,
      iocPostcommentsId,
      iocPostcommentsName,
      iocPostcommentsFinishDt,
      iocPostApprovalId,
      iocPostApprovalName,
      iocPostApprovalFinishDt,

      //IOQ
      ioqPreDraftId,
      ioqPreDraftName,
      ioqPreDraftFinishDt,
      ioqPreReviewId,
      ioqPreReviewName,
      ioqPreReviewFinishDt,
      ioqPrecommentsId,
      ioqPrecommentsName,
      ioqPreCommentsFinishDt,
      ioqPrePreApprovalId,
      ioqPrePreApprovalName,
      ioqPreApprovalFinishDt,
      ioqExecutionId,
      ioqExecutionName,
      ioqExecutionFinishDt,
      ioqPostDraftId,
      ioqPostDraftName,
      ioqpostDraftFinishDt,
      ioqPostReviewId,
      ioqPostReviewName,
      ioqPostReviewFinishDt,
      ioqPostcommentsId,
      ioqPostcommentsName,
      ioqPostcommentsFinishDt,
      ioqPostApprovalId,
      ioqPostApprovalName,
      ioqPostApprovalFinishDt,
      //PSSR
      walkDown1Id,
      walkDown1Name,
      walkDown1FinishDt,
      walkDown2Id,
      walkDown2Name,
      walkDown2FinishDt,
      walkDown3Id,
      walkDown3Name,
      walkDown3FinishDt,
    };

    let tmpRows = [];
    if (selectedRow.id) {
      rows[selectedRow.id - 1] = args;
      tmpRows = [...rows];
    } else {
      tmpRows = [...rows, args];
    }
    console.log("manageUser: ", tmpRows);
    localStorage.setItem("cqv", JSON.stringify(tmpRows));

    // API_HANDLER_HOME.manageUser(args).then((res) => {
    //   if (res.status === 200) {
    COMMON_JS.showNotify(props, NOTIFY.SUCCESS, SUCCESS.USER_SUCCESS);
    setSelectedRow({});
    setAddUserDialog(false);
    onReload();
    // } else {
    //   COMMON_JS.showNotify(props, NOTIFY.ERROR, res.error);
    // }
    // });
  };

  const deleteUser = ({ id }) => {
    console.log("deleteUser: ", id);
    setRows(null);
    getRows();
  };

  const onReload = () => {
    setRows(null);
    getRows();
  };

  const onAdd = () => {
    setAddUserDialog(!userDialog);
    setEditRows([]);
    setSelectedRow({});
  };

  const onSearch = (e: any) => {
    const qry = e.target.value.trim().toUpperCase();
    clearTimeout(_timer);
    _timer = setTimeout(() => {
      let flist = rows.filter((r: any) => {
        return r.userName.toUpperCase().indexOf(qry) > -1;
      });
      console.log("flist: ", rows, flist, qry);
      setFilteredRows(flist);
    }, 150);
  };

  const proectRef: any = useRef();
  const systemNoRef: any = useRef();
  const systemNameRef: any = useRef();
  const suitTeamRef: any = useRef();

  const iocPreDraftRef: any = useRef();
  const iocPreReviewRef: any = useRef();
  const iocPreCommentsRef: any = useRef();
  const iocPrePreApprovalRef: any = useRef();
  const iocExecutionRef: any = useRef();
  const iocPostDraftRef: any = useRef();
  const iocPostReviewRef: any = useRef();
  const iocPostCommentsRef: any = useRef();
  const iocPostApprovalRef: any = useRef();

  const ioqPreDraftRef: any = useRef();
  const ioqPreReviewRef: any = useRef();
  const ioqPreCommentsRef: any = useRef();
  const ioqPrePreApprovalRef: any = useRef();
  const ioqExecutionRef: any = useRef();
  const ioqPostDraftRef: any = useRef();
  const ioqPostReviewRef: any = useRef();
  const ioqPostCommentsRef: any = useRef();
  const ioqPostApprovalRef: any = useRef();

  const walkDown1Ref: any = useRef();
  const walkDown2Ref: any = useRef();
  const walkDown3Ref: any = useRef();

  console.log("iocPreDraftRef: ", rows);

  return (
    <React.Fragment>
      <div className="pageInfo">
        <ul className="breadcrumbs">
          <li>{LABELS.HOME}</li>
          <li className="dim">{" / "}</li>
          <li className="active">{TITLES.CQV}</li>
        </ul>
        <h4>{TITLES.CQV}</h4>
        {/* <p>{SUB_TITLES.USERS}</p> */}
      </div>

      {rows ? (
        <ExcelGrid
          id={"cqv"}
          height={window.innerHeight * 0.6}
          cols={columns}
          rows={rows}
          exportOption={true}
          //newRowOption={false}
          exportName={TITLES.CQV}
          onReload={onReload}
          onAdd={onAdd}
        />
      ) : (
        <div className="pageCenter">
          <Loader />
        </div>
      )}

      {userDialog && (
        <UiDialog
          title={
            // selectedRow.id
            //   ? "Edit User: " + selectedRow.userName
            "Add New CQV"
          }
          open={true}
          size={"md"}
          onClose={() => {
            setAddUserDialog(false);
          }}
        >
          <div
            className="flexRow"
            style={{ justifyContent: "space-between", width: "100%" }}
          >
            <div className="formGroup width30">
              <label style={{ color: "red" }}>{LABELS.PROJECTS} *</label>
              <AutoComplete
                id={"project"}
                childRef={proectRef}
                list={projects}
                defaultValue={
                  selectedRow.projectId
                    ? {
                        id: selectedRow.projectId,
                        label: selectedRow.projectName,
                      }
                    : null
                }
              />
            </div>
            <div className="formGroup width30">
              <label style={{ color: "red" }}>{LABELS.SYSTEM_NO} *</label>
              <AutoComplete
                id={"systemNo"}
                childRef={systemNoRef}
                list={systemNo}
                defaultValue={
                  selectedRow.sysytemNoId
                    ? {
                        id: selectedRow.sysytemNoId,
                        label: selectedRow.systemNoName,
                      }
                    : null
                }
              />
            </div>
            <div className="formGroup width30">
              <label style={{ color: "red" }}>{LABELS.SYSTEM_NAME} *</label>
              <AutoComplete
                id={"sysytemName"}
                childRef={systemNameRef}
                list={systemName}
                defaultValue={
                  selectedRow.systemNameId
                    ? {
                        id: selectedRow.systemNameId,
                        label: selectedRow.systemName,
                      }
                    : null
                }
              />
            </div>
          </div>

          <div
            className="flexRow"
            style={{ justifyContent: "space-between", width: "100%" }}
          >
            <div className="formGroup width30">
              <label style={{ color: "red" }}>{LABELS.SUITE_TEAM} *</label>
              <AutoComplete
                id={"suitTeam"}
                childRef={suitTeamRef}
                list={suiteTeam}
                defaultValue={
                  selectedRow.suitTeamId
                    ? {
                        id: selectedRow.suitTeamId,
                        label: selectedRow.suitTeamName,
                      }
                    : null
                }
              />
            </div>
          </div>

          <div>
            <label
              style={{
                color: COLORS.PRIMARY,
                fontSize: "16",
                fontWeight: "bold",
              }}
            >
              {LABELS.IOC_PREP_PRE_APPROVAL + " :"}
            </label>
            <div
              className="flexRow"
              style={{
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div className="formGroup width45">
                <label>{LABELS.DRAFT} </label>
                <AutoComplete
                  id={"iocPreDraft"}
                  childRef={iocPreDraftRef}
                  list={status}
                  onChange={(e, f) => {
                    setIocDraft(f.id);
                  }}
                  defaultValue={
                    selectedRow.iocPreDraftId
                      ? {
                          id: selectedRow.iocPreDraftId,
                          label: selectedRow.iocPreDraftName,
                        }
                      : null
                  }
                />
              </div>
              <div className="formGroup width45">
                <label>{LABELS.FINISH_DATE} </label>
                <TxtBox
                  id={"iocPreDraftFinishDt"}
                  disabled={iocDraft != "3" ? true : false}
                  type={"date"}
                  defaultValue={selectedRow.iocPreDraftFinishDt}
                />
              </div>
            </div>

            <div
              className="flexRow"
              style={{
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div className="formGroup width45">
                <label>{LABELS.REVIEW} </label>
                <AutoComplete
                  id={"preReview"}
                  childRef={iocPreReviewRef}
                  list={status}
                  disabled={iocDraft != "3" ? true : false}
                  defaultValue={
                    selectedRow.iocPreReviewId
                      ? {
                          id: selectedRow.iocPreReviewId,
                          label: selectedRow.iocPreReviewName,
                        }
                      : { id: "1", label: "Not Started" }
                  }
                />
              </div>
              <div className="formGroup width45">
                <label>{LABELS.FINISH_DATE} </label>
                <TxtBox
                  id={"iocPreReviewFinishDt"}
                  type={"date"}
                  disabled={iocDraft != "3" ? true : false}
                  defaultValue={selectedRow.iocPreReviewFinishDt}
                />
              </div>
            </div>

            <div
              className="flexRow"
              style={{
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div className="formGroup width45">
                <label>{LABELS.COMMENTS_UPDATE} </label>
                <AutoComplete
                  id={"iocPrecomments"}
                  childRef={iocPreCommentsRef}
                  list={status}
                  disabled={iocDraft != "3" ? true : false}
                  defaultValue={
                    selectedRow.iocPrecommentsId
                      ? {
                          id: selectedRow.iocPrecommentsId,
                          label: selectedRow.iocPrecommentsName,
                        }
                      : { id: "1", label: "Not Started" }
                  }
                />
              </div>
              <div className="formGroup width45">
                <label>{LABELS.FINISH_DATE} </label>
                <TxtBox
                  id={"iocPreCommentFinishDt"}
                  disabled={iocDraft != "3" ? true : false}
                  type={"date"}
                  defaultValue={selectedRow.iocPreCommentFinishDt}
                />
              </div>
            </div>

            <div
              className="flexRow"
              style={{
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div className="formGroup width45">
                <label>{LABELS.PRE_APPROVAL} </label>
                <AutoComplete
                  id={"iocPrePreApproval"}
                  childRef={iocPrePreApprovalRef}
                  list={status}
                  disabled={iocDraft != "3" ? true : false}
                  defaultValue={
                    selectedRow.iocPrePreApprovalId
                      ? {
                          id: selectedRow.iocPrePreApprovalId,
                          label: selectedRow.iocPrePreApprovalName,
                        }
                      : { id: "1", label: "Not Started" }
                  }
                />
              </div>
              <div className="formGroup width45">
                <label>{LABELS.FINISH_DATE} </label>
                <TxtBox
                  id={"iocPreApprovalFinishDt"}
                  type={"date"}
                  disabled={iocDraft != "3" ? true : false}
                  defaultValue={selectedRow.iocPreApprovalFinishDt}
                />
              </div>
            </div>
          </div>

          <div>
            <label
              style={{
                color: COLORS.PRIMARY,
                fontSize: "16",
                fontWeight: "bold",
              }}
            >
              {LABELS.IOC_EXICUTION + " :"}
            </label>
            <div
              className="flexRow"
              style={{
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div className="formGroup width45">
                <label>{LABELS.EXECUTION} </label>
                <AutoComplete
                  id={"iocExecution"}
                  childRef={iocExecutionRef}
                  list={status}
                  disabled={iocDraft != "3" ? true : false}
                  defaultValue={
                    selectedRow.iocExecutionId
                      ? {
                          id: selectedRow.iocExecutionId,
                          label: selectedRow.iocExecutionName,
                        }
                      : { id: "1", label: "Not Started" }
                  }
                />
              </div>
              <div className="formGroup width45">
                <label>{LABELS.FINISH_DATE} </label>
                <TxtBox
                  id={"iocExecutionFinishDt"}
                  type={"date"}
                  disabled={iocDraft != "3" ? true : false}
                  defaultValue={selectedRow.iocExecutionFinishDt}
                />
              </div>
            </div>
          </div>

          <div>
            <label
              style={{
                color: COLORS.PRIMARY,
                fontSize: "16",
                fontWeight: "bold",
              }}
            >
              {LABELS.IOC_POST_APPROVAL + " :"}
            </label>
            <div
              className="flexRow"
              style={{
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div className="formGroup width45">
                <label>{LABELS.DRAFT} </label>
                <AutoComplete
                  id={"iocPostDraft"}
                  childRef={iocPostDraftRef}
                  list={status}
                  disabled={iocDraft != "3" ? true : false}
                  defaultValue={
                    selectedRow.iocPostDraftId
                      ? {
                          id: selectedRow.iocPostDraftId,
                          label: selectedRow.iocPostDraftName,
                        }
                      : { id: "1", label: "Not Started" }
                  }
                />
              </div>
              <div className="formGroup width45">
                <label>{LABELS.FINISH_DATE} </label>
                <TxtBox
                  id={"iocpostDraftFinishDt"}
                  disabled={iocDraft != "3" ? true : false}
                  type={"date"}
                  defaultValue={selectedRow.iocpostDraftFinishDt}
                />
              </div>
            </div>

            <div
              className="flexRow"
              style={{
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div className="formGroup width45">
                <label>{LABELS.REVIEW} </label>
                <AutoComplete
                  id={"iocPostReview"}
                  childRef={iocPostReviewRef}
                  list={status}
                  disabled={iocDraft != "3" ? true : false}
                  defaultValue={
                    selectedRow.iocPostReviewId
                      ? {
                          id: selectedRow.iocPostReviewId,
                          label: selectedRow.iocPostReviewName,
                        }
                      : { id: "1", label: "Not Started" }
                  }
                />
              </div>
              <div className="formGroup width45">
                <label>{LABELS.FINISH_DATE} </label>
                <TxtBox
                  id={"iocPostReviewFinishDt"}
                  type={"date"}
                  disabled={iocDraft != "3" ? true : false}
                  defaultValue={selectedRow.iocPostReviewFinishDt}
                />
              </div>
            </div>

            <div
              className="flexRow"
              style={{
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div className="formGroup width45">
                <label>{LABELS.COMMENTS_UPDATE} </label>
                <AutoComplete
                  id={"iocPostcomments"}
                  childRef={iocPostCommentsRef}
                  list={status}
                  disabled={iocDraft != "3" ? true : false}
                  defaultValue={
                    selectedRow.iocPostcommentsId
                      ? {
                          id: selectedRow.iocPostcommentsId,
                          label: selectedRow.iocPostcommentsName,
                        }
                      : { id: "1", label: "Not Started" }
                  }
                />
              </div>
              <div className="formGroup width45">
                <label>{LABELS.FINISH_DATE} </label>
                <TxtBox
                  id={"iocPostcommentsFinishDt"}
                  type={"date"}
                  disabled={iocDraft != "3" ? true : false}
                  defaultValue={selectedRow.iocPostcommentsFinishDt}
                />
              </div>
            </div>

            <div
              className="flexRow"
              style={{
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div className="formGroup width45">
                <label>{LABELS.POST_APPROVAL} </label>
                <AutoComplete
                  id={"iocPostApproval"}
                  childRef={iocPostApprovalRef}
                  list={status}
                  disabled={iocDraft != "3" ? true : false}
                  defaultValue={
                    selectedRow.iocPostApprovalId
                      ? {
                          id: selectedRow.iocPostApprovalId,
                          label: selectedRow.iocPostApprovalName,
                        }
                      : { id: "1", label: "Not Started" }
                  }
                />
              </div>
              <div className="formGroup width45">
                <label>{LABELS.FINISH_DATE} </label>
                <TxtBox
                  id={"iocPostApprovalFinishDt"}
                  type={"date"}
                  disabled={iocDraft != "3" ? true : false}
                  defaultValue={selectedRow.iocPostApprovalFinishDt}
                />
              </div>
            </div>
          </div>
          {/* IOQ */}
          <div>
            <label
              style={{
                color: COLORS.PRIMARY,
                fontSize: "16",
                fontWeight: "bold",
              }}
            >
              {LABELS.IOQ_PREP_PRE_APPROVAL + " :"}
            </label>
            <div
              className="flexRow"
              style={{
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div className="formGroup width45">
                <label>{LABELS.DRAFT} </label>
                <AutoComplete
                  id={"ioqPreDraft"}
                  childRef={ioqPreDraftRef}
                  list={status}
                  onChange={(e, f) => {
                    setIoqDraft(f.id);
                  }}
                  defaultValue={
                    selectedRow.ioqPreDraftId
                      ? {
                          id: selectedRow.ioqPreDraftId,
                          label: selectedRow.ioqPreDraftName,
                        }
                      : { id: "1", label: "Not Started" }
                  }
                />
              </div>
              <div className="formGroup width45">
                <label>{LABELS.FINISH_DATE} </label>
                <TxtBox
                  id={"ioqPreDraftFinishDt"}
                  type={"date"}
                  disabled={ioqDraft != "3" ? true : false}
                  defaultValue={selectedRow.ioqPreDraftFinishDt}
                />
              </div>
            </div>

            <div
              className="flexRow"
              style={{
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div className="formGroup width45">
                <label>{LABELS.REVIEW} </label>
                <AutoComplete
                  id={"ioqpreReview"}
                  childRef={ioqPreReviewRef}
                  list={status}
                  disabled={ioqDraft != "3" ? true : false}
                  defaultValue={
                    selectedRow.ioqPreReviewId
                      ? {
                          id: selectedRow.ioqPreReviewId,
                          label: selectedRow.ioqPreReviewName,
                        }
                      : { id: "1", label: "Not Started" }
                  }
                />
              </div>
              <div className="formGroup width45">
                <label>{LABELS.FINISH_DATE} </label>
                <TxtBox
                  id={"ioqPreReviewFinishDt"}
                  type={"date"}
                  disabled={ioqDraft != "3" ? true : false}
                  defaultValue={selectedRow.ioqPreReviewFinishDt}
                />
              </div>
            </div>

            <div
              className="flexRow"
              style={{
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div className="formGroup width45">
                <label>{LABELS.COMMENTS_UPDATE} </label>
                <AutoComplete
                  id={"ioqPrecomments"}
                  childRef={ioqPreCommentsRef}
                  list={status}
                  disabled={ioqDraft != "3" ? true : false}
                  defaultValue={
                    selectedRow.ioqPrecommentsId
                      ? {
                          id: selectedRow.ioqPrecommentsId,
                          label: selectedRow.ioqPrecommentsName,
                        }
                      : { id: "1", label: "Not Started" }
                  }
                />
              </div>
              <div className="formGroup width45">
                <label>{LABELS.FINISH_DATE} </label>
                <TxtBox
                  id={"ioqPreCommentsFinishDt"}
                  type={"date"}
                  disabled={ioqDraft != "3" ? true : false}
                  defaultValue={selectedRow.ioqPreCommentsFinishDt}
                />
              </div>
            </div>

            <div
              className="flexRow"
              style={{
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div className="formGroup width45">
                <label>{LABELS.PRE_APPROVAL} </label>
                <AutoComplete
                  id={"ioqPrePreApproval"}
                  childRef={ioqPrePreApprovalRef}
                  list={status}
                  disabled={ioqDraft != "3" ? true : false}
                  defaultValue={
                    selectedRow.ioqPrePreApprovalId
                      ? {
                          id: selectedRow.ioqPrePreApprovalId,
                          label: selectedRow.ioqPrePreApprovalName,
                        }
                      : { id: "1", label: "Not Started" }
                  }
                />
              </div>
              <div className="formGroup width45">
                <label>{LABELS.FINISH_DATE} </label>
                <TxtBox
                  id={"ioqPreApprovalFinishDt"}
                  type={"date"}
                  disabled={ioqDraft != "3" ? true : false}
                  defaultValue={selectedRow.ioqPreApprovalFinishDt}
                />
              </div>
            </div>
          </div>

          <div>
            <label
              style={{
                color: COLORS.PRIMARY,
                fontSize: "16",
                fontWeight: "bold",
              }}
            >
              {LABELS.IOQ_EXICUTION + " :"}
            </label>
            <div
              className="flexRow"
              style={{
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div className="formGroup width45">
                <label>{LABELS.EXECUTION} </label>
                <AutoComplete
                  id={"ioqExecution"}
                  childRef={ioqExecutionRef}
                  list={status}
                  disabled={ioqDraft != "3" ? true : false}
                  defaultValue={
                    selectedRow.ioqExecutionId
                      ? {
                          id: selectedRow.ioqExecutionId,
                          label: selectedRow.ioqExecutionName,
                        }
                      : { id: "1", label: "Not Started" }
                  }
                />
              </div>
              <div className="formGroup width45">
                <label>{LABELS.FINISH_DATE} </label>
                <TxtBox
                  id={"ioqExecutionFinishDt"}
                  type={"date"}
                  disabled={ioqDraft != "3" ? true : false}
                  defaultValue={selectedRow.ioqExecutionFinishDt}
                />
              </div>
            </div>
          </div>

          <div>
            <label
              style={{
                color: COLORS.PRIMARY,
                fontSize: "16",
                fontWeight: "bold",
              }}
            >
              {LABELS.IOQ_POST_APPROVAL + " :"}
            </label>
            <div
              className="flexRow"
              style={{
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div className="formGroup width45">
                <label>{LABELS.DRAFT} </label>
                <AutoComplete
                  id={"ioqPostDraft"}
                  childRef={ioqPostDraftRef}
                  list={status}
                  disabled={ioqDraft != "3" ? true : false}
                  defaultValue={
                    selectedRow.ioqPostDraftId
                      ? {
                          id: selectedRow.ioqPostDraftId,
                          label: selectedRow.ioqPostDraftName,
                        }
                      : { id: "1", label: "Not Started" }
                  }
                />
              </div>
              <div className="formGroup width45">
                <label>{LABELS.FINISH_DATE} </label>
                <TxtBox
                  id={"ioqpostDraftFinishDt"}
                  type={"date"}
                  disabled={ioqDraft != "3" ? true : false}
                  defaultValue={selectedRow.ioqpostDraftFinishDt}
                />
              </div>
            </div>

            <div
              className="flexRow"
              style={{
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div className="formGroup width45">
                <label>{LABELS.REVIEW} </label>
                <AutoComplete
                  id={"ioqPostReview"}
                  childRef={ioqPostReviewRef}
                  list={status}
                  disabled={ioqDraft != "3" ? true : false}
                  defaultValue={
                    selectedRow.ioqPostReviewId
                      ? {
                          id: selectedRow.ioqPostReviewId,
                          label: selectedRow.ioqPostReviewName,
                        }
                      : { id: "1", label: "Not Started" }
                  }
                />
              </div>
              <div className="formGroup width45">
                <label>{LABELS.FINISH_DATE} </label>
                <TxtBox
                  id={"ioqPostReviewFinishDt"}
                  type={"date"}
                  disabled={ioqDraft != "3" ? true : false}
                  defaultValue={selectedRow.ioqPostReviewFinishDt}
                />
              </div>
            </div>

            <div
              className="flexRow"
              style={{
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div className="formGroup width45">
                <label>{LABELS.COMMENTS_UPDATE} </label>
                <AutoComplete
                  id={"ioqPostcomments"}
                  childRef={ioqPostCommentsRef}
                  list={status}
                  disabled={ioqDraft != "3" ? true : false}
                  defaultValue={
                    selectedRow.ioqPostcommentsId
                      ? {
                          id: selectedRow.ioqPostcommentsId,
                          label: selectedRow.ioqPostcommentsName,
                        }
                      : { id: "1", label: "Not Started" }
                  }
                />
              </div>
              <div className="formGroup width45">
                <label>{LABELS.FINISH_DATE} </label>
                <TxtBox
                  id={"ioqPostcommentsFinishDt"}
                  type={"date"}
                  disabled={ioqDraft != "3" ? true : false}
                  defaultValue={selectedRow.ioqPostcommentsFinishDt}
                />
              </div>
            </div>

            <div
              className="flexRow"
              style={{
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div className="formGroup width45">
                <label>{LABELS.POST_APPROVAL} </label>
                <AutoComplete
                  id={"ioqPostApproval"}
                  childRef={ioqPostApprovalRef}
                  list={status}
                  disabled={ioqDraft != "3" ? true : false}
                  defaultValue={
                    selectedRow.ioqPostApprovalId
                      ? {
                          id: selectedRow.ioqPostApprovalId,
                          label: selectedRow.ioqPostApprovalName,
                        }
                      : { id: "1", label: "Not Started" }
                  }
                />
              </div>
              <div className="formGroup width45">
                <label>{LABELS.FINISH_DATE} </label>
                <TxtBox
                  id={"ioqPostApprovalFinishDt"}
                  type={"date"}
                  disabled={ioqDraft != "3" ? true : false}
                  defaultValue={selectedRow.ioqPostApprovalFinishDt}
                />
              </div>
            </div>
          </div>

          {/* PSSR */}

          <div>
            <label
              style={{
                color: COLORS.PRIMARY,
                fontSize: "16",
                fontWeight: "bold",
              }}
            >
              {LABELS.PSSR + " :"}
            </label>
            <div
              className="flexRow"
              style={{
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div className="formGroup width45">
                <label>{LABELS.WALK_DOWN_1} </label>
                <AutoComplete
                  id={"walkDown1"}
                  childRef={walkDown1Ref}
                  list={status}
                  defaultValue={
                    selectedRow.walkDown1Id
                      ? {
                          id: selectedRow.walkDown1Id,
                          label: selectedRow.walkDown1Name,
                        }
                      : { id: "1", label: "Not Started" }
                  }
                />
              </div>
              <div className="formGroup width45">
                <label>{LABELS.FINISH_DATE} </label>
                <TxtBox
                  id={"walkDown1FinishDt"}
                  type={"date"}
                  defaultValue={selectedRow.walkDown1FinishDt}
                />
              </div>
            </div>

            <div
              className="flexRow"
              style={{
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div className="formGroup width45">
                <label>{LABELS.WALK_DOWN_2} </label>
                <AutoComplete
                  id={"walkDown2"}
                  childRef={walkDown2Ref}
                  list={status}
                  defaultValue={
                    selectedRow.walkDown2Id
                      ? {
                          id: selectedRow.walkDown2Id,
                          label: selectedRow.walkDown2Name,
                        }
                      : { id: "1", label: "Not Started" }
                  }
                />
              </div>
              <div className="formGroup width45">
                <label>{LABELS.FINISH_DATE} </label>
                <TxtBox
                  id={"walkDown2FinishDt"}
                  type={"date"}
                  defaultValue={selectedRow.walkDown2FinishDt}
                />
              </div>
            </div>

            <div
              className="flexRow"
              style={{
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div className="formGroup width45">
                <label>{LABELS.WALK_DOWN_3} </label>
                <AutoComplete
                  id={"walkDown3"}
                  childRef={walkDown3Ref}
                  list={status}
                  defaultValue={
                    selectedRow.walkDown3Id
                      ? {
                          id: selectedRow.walkDown3Id,
                          label: selectedRow.walkDown3Name,
                        }
                      : { id: "1", label: "Not Started" }
                  }
                />
              </div>
              <div className="formGroup width45">
                <label>{LABELS.FINISH_DATE} </label>
                <TxtBox
                  id={"walkDown3FinishDt"}
                  type={"date"}
                  defaultValue={selectedRow.walkDown3FinishDt}
                />
              </div>
            </div>
          </div>
          {/* <div className="formGroup width45">
            <label style={{ color: "red" }}>{LABELS.FIRST_NAME} *</label>
            <TxtBox
              id={"firstName"}
              placeholder={LABELS.FIRST_NAME}
              defaultValue={selectedRow.firstName}
            />
          </div>
          <div className="formGroup">
            <label>{LABELS.LAST_NAME}</label>
            <TxtBox
              id={"lastName"}
              placeholder={LABELS.LAST_NAME}
              defaultValue={selectedRow.lastName}
            />
          </div>
          <div className="formGroup">
            <label style={{ color: "red" }}>{LABELS.USER_NAME} *</label>
            <TxtBox
              id={"userName"}
              placeholder={LABELS.USER_NAME}
              defaultValue={selectedRow.userName}
            />
          </div>
          <div className="formGroup">
            <label style={{ color: "red" }}>{LABELS.PASSWORD} *</label>
            <TxtBox id={"password"} placeholder={LABELS.PASSWORD} />
          </div>
          <div className="formGroup">
            <label>{LABELS.EMAIL_ID}</label>
            <TxtBox
              id={"emailId"}
              placeholder={LABELS.EMAIL_ID}
              defaultValue={selectedRow.emailId}
            />
          </div>
          <div className="formGroup">
            <label>{LABELS.PHONE}</label>
            <TxtBox
              id={"phoneNumber"}
              placeholder={LABELS.PHONE}
              defaultValue={selectedRow.phoneNumber}
            />
          </div>
          <div className="formGroup">
            <label style={{ color: "red" }}>{LABELS.USER_TYPE} *</label>
            <AutoComplete
              id={"userRole"}
              list={userTypes}
              childRef={userTypeRef}
              defaultValue={
                selectedRow.userType
                  ? {
                      id: selectedRow.userTypeId,
                      label: selectedRow.userType,
                    }
                  : null
              }
            />
          </div>

          <div className="formGroup">
            <SwitchBtn
              text={LABELS.ACTIVE}
              id={"isActive"}
              checked={selectedRow.id ? selectedRow.active : true}
            />
          </div> */}
          <div
            className="formGroup"
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                width: "45%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Btn text={LABELS.SAVE} onClick={manageUser} />
              <Btn
                text={LABELS.CANCEL}
                bgColor={COLORS.DIM_DARK_GRAY}
                onClick={() => setAddUserDialog(false)}
              />
            </div>
          </div>
        </UiDialog>
      )}

      {selectedRow.delete && (
        <ConfirmDialog
          title={"Delete User: " + selectedRow.row.userName}
          msg={CONFIRMS.DELETE_USER}
          open={true}
          onClose={() => {
            setSelectedRow({});
          }}
          onOk={() => deleteUser(selectedRow.row)}
        />
      )}
    </React.Fragment>
  );
};

export default withSnackbar(Cqv);
