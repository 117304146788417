import React, { useState } from "react";
import { AccountCircle as IconUser } from "@mui/icons-material";
import { Edit as IconEdit } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { withSnackbar } from "notistack";

import Btn from "components/Custom/Btn";
import Loader from "components/Custom/Loader";
import UiDialog from "components/Custom/UiDialog";
import TxtBox from "components/Custom/TxtBox";

import { LABELS, API_CONFIG, NOTIFY } from "common/config";
import { COMMON_JS } from "common/scripts";
import { ApiHome } from "apihandlers/home";

const imagePdf = require("assets/images/pdf.png");

const API_HANDLER_HOME = new ApiHome();

const RfiResponses = (props: any) => {
  const {
    selectedRow,
    responses,
    selectedRowFiles,
    uploadedFiles,
    setUploadedFiles,
    userInfo,
    onChangeFile,
    setSelectedRow,
    saveResponse,
    getRfiResponses,
  } = props;

  const [selectedReponse, setSelectedReponse] = useState<any>({});

  const onEditResponse = (r: any) => {
    setSelectedReponse(r);
  };

  const updateResponse = () => {
    const response = (
      document.getElementById("response") as HTMLInputElement
    ).value.trim();
    if (!response) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, "Please enter response..!");
      return;
    }
    const args = {
      responseId: selectedReponse.id,
      id: selectedRow.id,
      response,
      files: uploadedFiles,
    };
    API_HANDLER_HOME.manageRfiResponse(args).then((res) => {
      if (res.status === 200) {
        COMMON_JS.showNotify(
          props,
          NOTIFY.SUCCESS,
          "Response Saved Successfully..!"
        );
        setSelectedReponse({});
        getRfiResponses(selectedRow.id);
        setUploadedFiles([]);
      } else {
        COMMON_JS.showNotify(props, NOTIFY.ERROR, "Error: " + res.error);
      }
    });
  };

  console.log("rfi: RfiResponses:", selectedRowFiles);

  return (
    <UiDialog
      open={true}
      size={"lg"}
      title={"RFI Responses: "}
      onClose={() => {
        setSelectedRow({});
      }}
    >
      {selectedRow.subject && (
        <div
          className="contentCard"
          style={{
            display: "flex",
          }}
        >
          <h4>{"Subject : " + selectedRow.subject}</h4>
        </div>
      )}

      {selectedRow.constraints && (
        <div className="contentCard">
          <h4>{"Constraints :"}</h4>
          <p>{selectedRow.constraints}</p>
        </div>
      )}

      {responses && responses.length > 0 && (
        <div className="contentCard" style={{ marginTop: 10 }}>
          <p>
            <span style={{ fontSize: 16 }} className="bold">
              {"Recipient : "}
            </span>
            {responses[0].user}
          </p>
        </div>
      )}

      <div className="filesList" style={{ marginTop: 10 }}>
        {selectedRowFiles.map((f: any, i: number) => {
          return (
            <div
              className="fileBox"
              key={"file-" + i}
              onClick={() => {
                window.open(API_CONFIG.API_URL + f.path, "_blank");
              }}
            >
              <img
                src={
                  f.mimeType.indexOf("image/") > -1
                    ? API_CONFIG.API_URL + f.path
                    : imagePdf
                }
              />
            </div>
          );
        })}
      </div>

      {!responses ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            height: 150,
            width: "100%",
          }}
        >
          <Loader />
        </div>
      ) : (
        <div id="responseList">
          {responses.map((r: any, i: number) => {
            return (
              <div key={"res-" + i}>
                <div>
                  <div className="responseHeader">
                    <div className="userBox">
                      <div>
                        <IconUser />
                      </div>
                      <p>{r.user}</p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                      }}
                    >
                      <p
                        style={{
                          marginRight: 10,
                        }}
                      >
                        {r.logTime}
                      </p>
                      {!selectedReponse.id && r.userId === userInfo.userId && (
                        <IconButton
                          onClick={() => {
                            onEditResponse(r);
                          }}
                        >
                          <IconEdit />
                        </IconButton>
                      )}
                    </div>
                  </div>
                  <div className="responseText">
                    {selectedReponse.id && selectedReponse.id === r.id ? (
                      <div id="responseBox">
                        <div className="formGroup">
                          <label>{"Response"}</label>
                          <TxtBox
                            placeholder={"Response"}
                            id={"response"}
                            type={"text"}
                            defaultValue={selectedReponse.response || ""}
                            className={"multiLine"}
                            rows={4}
                          />
                        </div>
                        <div className="formGroup">
                          <label>{"Upload Files"}</label>
                          <TxtBox
                            style={{ height: 50, backgroundColor: "red" }}
                            type="file"
                            className="uploadFile"
                            accept=".png, .jpg, .jpeg, .pdf"
                            onChange={onChangeFile}
                          />
                        </div>
                        {uploadedFiles.length > 0 && (
                          <div className="formGroup">
                            <label>{"Files:"}</label>
                            <div className="filesList">
                              {uploadedFiles.map((f: any, i: number) => {
                                return (
                                  <div
                                    key={"file-" + i}
                                    className="fileBox"
                                    onClick={() => {
                                      window.open(
                                        API_CONFIG.API_URL + f.path,
                                        "_blank"
                                      );
                                    }}
                                  >
                                    <img
                                      src={
                                        f.mimeType.indexOf("image/") > -1
                                          ? API_CONFIG.API_URL + f.path
                                          : imagePdf
                                      }
                                    />
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                        <div className="formGroup">
                          <Btn text={LABELS.SAVE} onClick={updateResponse} />
                        </div>
                      </div>
                    ) : (
                      r.response
                    )}
                  </div>
                  <div className="filesList">
                    {r.files.map((f: any, i: number) => {
                      return (
                        <div
                          className="fileBox"
                          key={"file-" + i}
                          onClick={() => {
                            window.open(API_CONFIG.API_URL + f.path, "_blank");
                          }}
                        >
                          <img
                            src={
                              f.mimeType.indexOf("image/") > -1
                                ? API_CONFIG.API_URL + f.path
                                : imagePdf
                            }
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}

      {(selectedRow.recipient === userInfo.userId ||
        selectedRow.uploadById === userInfo.userId) &&
        !selectedReponse.id && (
          <div id="responseBox">
            <div className="formGroup">
              <label>{"Response"}</label>
              <TxtBox
                placeholder={"Response"}
                id={"response"}
                type={"text"}
                className={"multiLine"}
                rows={4}
              />
            </div>
            <div className="formGroup">
              <label>{"Upload Files"}</label>
              <TxtBox
                style={{ height: 50, backgroundColor: "red" }}
                type="file"
                className="uploadFile"
                accept=".png, .jpg, .jpeg, .pdf"
                onChange={onChangeFile}
              />
            </div>
            {uploadedFiles.length > 0 && (
              <div className="formGroup">
                <label>{"Files:"}</label>
                <div className="filesList">
                  {uploadedFiles.map((f: any, i: number) => {
                    return (
                      <div
                        className="fileBox"
                        onClick={() => {
                          window.open(API_CONFIG.API_URL + f.path, "_blank");
                        }}
                      >
                        <img
                          src={
                            f.mimeType.indexOf("image/") > -1
                              ? API_CONFIG.API_URL + f.path
                              : imagePdf
                          }
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            <div className="formGroup">
              <Btn text={LABELS.SAVE} onClick={saveResponse} />
            </div>
          </div>
        )}
    </UiDialog>
  );
};

export default withSnackbar(RfiResponses);
