import React, { useEffect, useRef, useState } from "react";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { withSnackbar } from "notistack";
import { IconButton, Tooltip } from "@mui/material";
import {
  Replay as IconReload,
  AddBox as IconAdd,
  Edit as IconEdit,
  Check as IconCheck,
  Close as IconClose,
  Article as IconFile,
} from "@mui/icons-material";

import TxtBox from "components/Custom/TxtBox";
import UiDialog from "components/Custom/UiDialog";
import RadioBtn from "components/Custom/RadioBtn";
import Btn from "components/Custom/Btn";
import SwitchBtn from "components/Custom/SwitchBtn";
import AutoComplete from "components/Custom/AutoComplete";
import ConfirmDialog from "components/Custom/ConfirmDialog";
import Loader from "components/Custom/Loader";
import UsersSkeleton from "./UsersSkeleton";

import "./styles/file.css";

import {
  COLORS,
  CONFIG,
  CONFIRMS,
  DEFAULTS,
  ERRORS,
  LABELS,
  NOTIFY,
  SUB_TITLES,
  SUCCESS,
  WARNINGS,
} from "common/config";
import { ApiLogin } from "apihandlers/login";
import { ApiHome } from "apihandlers/home";
import { COMMON_JS } from "common/scripts";

const API_HANDLER_HOME = new ApiHome();

let _timer = null;

const ApproveFile = (props: any) => {
  const [loading, setLoading] = useState(true);
  const [uploading, setUploading] = useState(false);
  const [orgs, setOrgs] = useState([]);
  const [projects, setProjects] = useState([]);
  const [rows, setRows] = useState<any>([]);
  const [manageDialog, setManageDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [apiArgs, setApiArgs] = useState<any>({});
  const [gridType, setGridType] = useState("file");
  const [filteredRows, setFilteredRows] = useState([]);
  const [cols, setColumns] = useState([]);

  useEffect(() => {
    if (loading) {
      getProjects();
    }
  }, [loading]);

  const getProjects = () => {
    const args = { dropdown: true };
    API_HANDLER_HOME.getProjects(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        setProjects(response.records);
        setLoading(false);
      }
    });
  };

  const getApprovePendings = () => {
    const projectId = projectRef.current.selectedValue.id;
    if (!projectId) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        "Please select the project..!"
      );
      return;
    }
    const args = {
      projectId,
      type: gridType,
    };
    setRows(null);
    API_HANDLER_HOME.getApprovePendings(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list: any = response.records.map((r: any, i: number) => {
          r.slNo = i + 1;
          return r;
        });
        let c = columns;
        if (gridType === "data") {
          c = columns2;
        } else if (
          gridType === "constraints" ||
          gridType === "constraintsfile"
        ) {
          c = columns3;
        }
        setColumns(c);
        setRows(list);
        setLoading(false);
      }
    });
  };

  const onEditRow = (row: any) => {
    setSelectedRow(row);
    setManageDialog(true);
  };

  const onDeleteRow = (row: any) => {
    setSelectedRow({ row, delete: true });
  };

  const columns: GridColDef[] = [
    { field: "slNo", headerName: "Sl No.", width: 100, type: "number" },
    { field: "actualFileName", headerName: "File", width: 300 },
    {
      field: "uname",
      headerName: "Uploaded By",
      width: 200,
    },
    {
      field: "logTime",
      headerName: "Logtime",
      width: 200,
    },
    {
      field: "updateDate",
      headerName: LABELS.SELECT_DATE,
      width: 200,
    },
    {
      field: "options",
      headerName: "Options",
      width: 200,
      renderCell: (params: any) => (
        <div className="options">
          <Tooltip title={LABELS.VIEW} placement="bottom">
            <IconButton
              className="iconBtn"
              onClick={() => viewProjectFile(params.row.fileId)}
            >
              <IconFile />
            </IconButton>
          </Tooltip>
          <Tooltip title={LABELS.APPROVE} placement="bottom">
            <IconButton
              className="iconBtn"
              onClick={() => manageFileApprove(params.row.fileId, "approved")}
            >
              <IconCheck className="green" />
            </IconButton>
          </Tooltip>
          <Tooltip title={LABELS.REJECT} placement="bottom">
            <IconButton
              className="iconBtn"
              onClick={() => manageFileApprove(params.row.fileId, "rejected")}
            >
              <IconClose className="red" />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  const columns2: GridColDef[] = [
    { field: "slNo", headerName: "Sl No.", width: 100, type: "number" },
    {
      field: "wbs",
      headerName: "WBS",
      width: 100,
    },
    {
      field: "refId",
      headerName: "Ref. ID",
      width: 100,
    },
    {
      field: "taskName",
      headerName: "Task Name",
      width: 500,
    },
    // {
    //   field: "employeeId",
    //   headerName: "Employee ID",
    //   width: 150,
    // },
    {
      field: "startDate",
      headerName: "Start Date",
      width: 150,
    },
    {
      field: "finishDate",
      headerName: "Finish Date",
      width: 150,
    },
    {
      field: "percent",
      headerName: "Percent",
      width: 150,
    },
    {
      field: "actualQty",
      headerName: "Actual Qty",
      width: 150,
    },
    {
      field: "unit",
      headerName: "Unit",
      width: 150,
    },
    {
      field: "spentHours",
      headerName: "Spent Hours",
      width: 150,
    },
    // {
    //   field: "constraints",
    //   headerName: "Constraints",
    //   width: 150,
    // },
    {
      field: "spentCost",
      headerName: "Spent Cost",
      width: 150,
    },
    {
      field: "uname",
      headerName: "Edited By",
      width: 150,
    },
    {
      field: "options",
      headerName: "Options",
      width: 200,
      renderCell: (params: any) => (
        <div className="options">
          <Tooltip title={LABELS.APPROVE} placement="bottom">
            <IconButton
              className="iconBtn"
              onClick={() => manageFileApprove(params.row.refId, "approved")}
            >
              <IconCheck className="green" />
            </IconButton>
          </Tooltip>
          <Tooltip title={LABELS.REJECT} placement="bottom">
            <IconButton
              className="iconBtn"
              onClick={() => manageFileApprove(params.row.refId, "rejected")}
            >
              <IconClose className="red" />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  const columns3: GridColDef[] = [
    { field: "slNo", headerName: "Sl No.", width: 100, type: "number" },
    {
      field: "wbs",
      headerName: "WBS",
      width: 100,
    },
    {
      field: "refId",
      headerName: "Ref. ID",
      width: 100,
    },
    {
      field: "taskName",
      headerName: "Task Name",
      width: 500,
    },
    {
      field: "constraints",
      headerName: "Constraints",
      width: 400,
    },
    {
      field: "taskOwner",
      headerName: "Task Owner",
      width: 150,
    },
    {
      field: "employeeId",
      headerName: "Employee ID",
      width: 150,
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
    },
    {
      field: "uploadDt",
      headerName: "Upload Date",
      width: 150,
    },
    {
      field: "uploadedBy",
      headerName: "Uploaded By",
      width: 150,
    },
    {
      field: "approveDt",
      headerName: "Approve Date",
      width: 150,
    },
    {
      field: "approveBy",
      headerName: "Approve By",
      width: 150,
    },
    {
      field: "options",
      headerName: "Options",
      width: 200,
      renderCell: (params: any) => (
        <div className="options">
          <Tooltip title={LABELS.APPROVE} placement="bottom">
            <IconButton
              className="iconBtn"
              onClick={() => manageFileApprove(params.row.refId, "approved")}
            >
              <IconCheck className="green" />
            </IconButton>
          </Tooltip>
          <Tooltip title={LABELS.REJECT} placement="bottom">
            <IconButton
              className="iconBtn"
              onClick={() => manageFileApprove(params.row.refId, "rejected")}
            >
              <IconClose className="red" />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  const manageFileApprove = (id = "", status = "") => {
    // Set Args
    const projectId = projectRef.current.selectedValue.id;
    setApiArgs({
      projectId,
      fileId: id,
      status,
      type: gridType,
    });
    setConfirmDialog(true);
  };

  const onConfirmApprove = () => {
    setUploading(true);
    if (apiArgs.type.indexOf("constraints") > -1) {
      API_HANDLER_HOME.approveConstraints(apiArgs).then((res: any) => {
        if (res.status === 200) {
          COMMON_JS.showNotify(props, NOTIFY.SUCCESS, SUCCESS.FILE_APPROVED);
        } else {
          COMMON_JS.showNotify(props, NOTIFY.ERROR, ERRORS.FILE_APPROVE_FAILED);
        }
        setUploading(false);
        setConfirmDialog(false);
        getApprovePendings();
      });
    } else {
      API_HANDLER_HOME.approveFile(apiArgs).then((res: any) => {
        if (res.status === 200) {
          COMMON_JS.showNotify(props, NOTIFY.SUCCESS, SUCCESS.FILE_APPROVED);
        } else {
          COMMON_JS.showNotify(props, NOTIFY.ERROR, ERRORS.FILE_APPROVE_FAILED);
        }
        setUploading(false);
        setConfirmDialog(false);
        getApprovePendings();
      });
    }
  };

  const viewProjectFile = (fileId = "") => {
    const args = {
      fileId,
    };
    API_HANDLER_HOME.getProjectFile(args).then((res: any) => {
      setLoading(false);
      let base64 = "data:application/vnd.ms-excel;base64, " + res;
      window.open(base64, "_blank");
    });
  };

  const reloadGrid = () => {
    setRows(null);
    setTimeout(() => {
      getApprovePendings();
    }, 2000);
  };

  const onSearch = (e: any) => {
    const qry = e.target.value.trim().toUpperCase();
    clearTimeout(_timer);
    _timer = setTimeout(() => {
      let flist = rows.filter((r: any) => {
        return r.taskName.toUpperCase().indexOf(qry) > -1;
      });
      if (flist.length === 0) {
        flist = null;
      }
      setFilteredRows(flist);
    }, 150);
  };

  const onChangeGridType = (val) => {
    setGridType(val);
  };

  const projectRef: any = useRef();

  console.log("Approve File: ", projects);

  return (
    <React.Fragment>
      <div className="pageInfo">
        <ul className="breadcrumbs">
          <li>{LABELS.HOME}</li>
          <li className="dim">{" / "}</li>
          <li className="active">{LABELS.APPROVE_FILE}</li>
        </ul>
        <h4>{LABELS.APPROVE_FILE}</h4>
      </div>

      <div id="fileUploadForm" style={{ marginTop: 15 }}>
        <div className="formGroup" style={{ width: "60%" }}>
          <label>{LABELS.PROJECT_NAME}</label>
          <AutoComplete id={"projects"} list={projects} childRef={projectRef} />
        </div>
        {/* <div className="formGroup">
          <label>{LABELS.FILE_UPLOAD_TYPE}</label>
          <RadioBtn
            name={"uploadType"}
            list={[
              {
                id: "progress",
                label: "Progress",
                checked: true,
              },
            ]}
            row={true}
          />
        </div> */}
        <div className="formGroup">
          <label>{"Type:"}</label>
          <RadioBtn
            name={"type"}
            list={[
              {
                id: "file",
                label: "File Uploads",
                checked: true,
              },
              {
                id: "data",
                label: "Gantt Chart Updates",
              },
            ]}
            row={true}
            onChange={onChangeGridType}
          />
        </div>
        <div
          className="formGroup"
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: 0,
            marginBottom: 15,
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <Btn
              text={LABELS.VIEW}
              onClick={getApprovePendings}
              loading={loading}
            />
          </div>
          {/* <div
            style={{
              width: "70%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Btn
              text={LABELS.APPROVE}
              onClick={manageFileApprove}
              loading={uploading}
            />
          </div> */}
        </div>
      </div>

      {rows && cols.length > 0 && (
        <div className="gridOption">
          <TxtBox placeholder={LABELS.SEARCH} onKeyPress={onSearch} />
          <IconButton className="iconBtn" onClick={reloadGrid}>
            <IconReload />
          </IconButton>
        </div>
      )}

      {cols.length > 0 && (
        <div
          className="grid"
          style={{
            width: "99%",
            height: "60%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {rows ? (
            <DataGrid
              rows={
                filteredRows
                  ? filteredRows.length > 0
                    ? filteredRows
                    : rows
                  : []
              }
              columns={cols}
              // pageSize={5}
              // rowsPerPageOptions={[5]}
              // pagination={false}
              disableColumnMenu={true}
              disableSelectionOnClick={true}
            />
          ) : (
            <Loader />
          )}
        </div>
      )}

      <ConfirmDialog
        open={confirmDialog}
        title={LABELS.CONFIRM}
        msg={CONFIRMS.SURE_TO_APPROVE}
        size={DEFAULTS.DIALOG_SIZE}
        onClose={() => setConfirmDialog(false)}
        onOk={onConfirmApprove}
      />
    </React.Fragment>
  );
};

export default withSnackbar(ApproveFile);
