/*
 * Contractor Form
 */

import React, {
  useRef,
  useEffect,
  useState,
  useContext,
  createContext,
  useMemo,
} from "react";
import { withSnackbar } from "notistack";
import { IconButton, Tooltip } from "@mui/material";
import { Edit as IconEdit } from "@mui/icons-material";

import UiDialog from "components/Custom/UiDialog";
import ExcelGrid from "components/Custom/ExcelGrid";
import AutoComplete from "components/Custom/AutoComplete";
import ModalBtmBtns from "components/Custom/ModalBtmBtns";
import Loader from "components/Custom/Loader";
import HGraph from "components/Custom/HGraph";
import TxtBox from "components/Custom/TxtBox";

import {
  LABELS,
  LOCAL_STORAGE,
  NOTIFY,
  SUCCESS,
  TITLES,
  WARNINGS,
} from "common/config";
import { ApiHome } from "apihandlers/home";
import { ApiEhs } from "apihandlers/ehs";
import { COMMON_JS } from "common/scripts";
import RadioBtn from "components/Custom/RadioBtn";
import "./style.css";

const API_HANDLER_HOME = new ApiHome();
const API_HANDLER_EHS = new ApiEhs();

const EquipmentRegister = (props: any) => {
  const mainGridCols = [
    {
      key: "slNo",
      name: "Sl No.",
      type: "number",
      width: "5%",
      align: "center",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "owner",
      name: "Owner",
      type: "text",
      width: "10%",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "equipmentId",
      name: "Equiment ID",
      type: "text",
      width: "10%",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "equipmetCategory",
      name: "Equipment Category",
      type: "text",
      width: "15%",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "equipmentType",
      name: "Equipment Type",
      type: "text",
      width: "10%",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "serialNumber",
      name: "Serial Number",
      type: "text",
      width: "10%",
      editable: false,
      sortable: true,
      searchable: true,
    },

    {
      key: "Manufacturer",
      name: "Manufacturer",
      type: "text",
      width: "10%",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "operator",
      name: "Operator",
      type: "text",
      width: "10%",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "contactNo",
      name: "Contact No",
      type: "text",
      width: "10%",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "purchasedate",
      name: "Date of Purchase",
      type: "text",
      width: "10%",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "lastServiceDate",
      name: "Last Service Date",
      type: "text",
      width: "10%",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "serviceDueDate",
      name: "Service Due date",
      type: "text",
      width: "10%",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "frequencyofService",
      name: "Frequency of Service",
      type: "text",
      width: "10%",
      editable: false,
      sortable: true,
      searchable: true,
    },
    // {
    //   key: "availableStatus",
    //   name: "Available for Use",
    //   type: "text",
    //   width: "10%",
    //   editable: false,
    //   sortable: true,
    //   searchable: true,
    // },
    {
      key: "remarks",
      name: "Remarks",
      type: "text",
      width: "10%",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "options",
      name: "Options",
      type: "html",
      width: "5%",
      editable: false,
      sortable: false,
      renderCell: ({ row }: any) => (
        <div className="options">
          <Tooltip title={LABELS.EDIT} placement="bottom">
            <IconButton className="iconBtn" onClick={() => onEditRow(row)}>
              <IconEdit />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];
  const [loading, setLoading] = useState<boolean>(true);
  const [cols, setCols] = useState<any[]>(mainGridCols);
  const [rows, setRows] = useState<any[]>(null);
  const [index, setIndex] = useState(0);
  const [columnChart, setColumnChart] = useState<any>({});
  const [pieChart, setPieChart] = useState<any>({});
  const [manageDialog, setManageDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [editRows, setEditRows] = useState<any[]>(null);
  const [pageNo, setPageNo] = useState(1);
  const [totalPages, setTotalPages] = useState(6);
  const [formData, setFormData] = useState<any>({});
  const [uploads, setUploads] = useState<any[]>([]);
  const [equipmetCategory, setEquipmetCategory] = useState<any>([]);

  const equipmetCategoryRef: any = useRef();
  const equipmentTypeRef: any = useRef(null);
  const orgsRef: any = useRef();

  useEffect(() => {
    return () => {
      cleanUp();
    };
  }, []);

  useEffect(() => {
    if (loading) {
      getRows();
    }
  }, [loading]);

  // const getOrgs = () => {
  //   const args = {};
  //   API_HANDLER_HOME.getOrganizations(args).then((res: any) => {
  //     const response: any = res.response;
  //     const { status } = res;
  //     if (status === 200) {
  //       setOrgList(
  //         response.records.map((o: any) => {
  //           return {
  //             id: o.id,
  //             label: o.orgName,
  //           };
  //         })
  //       );
  //       getRows();
  //     }
  //   });
  // };

  const cleanUp = () => {
    setRows(null);
    setLoading(true);
    setManageDialog(false);
    setSelectedRow({});
    console.log("Cleaned up");
  };

  const getRows = () => {
    const rowData = localStorage.getItem(LOCAL_STORAGE.EUIPMENT_REGISTER);
    let list = JSON.parse(rowData);
    if (rowData && list.length > 0) {
      for (let i = 0; i < list.length; i++) {
        list[i]["slNo"] = i + 1;
      }
      setRows(list);
    } else {
      setRows([]);
    }

    // const args = {};
    // API_HANDLER_EHS.getContractor(args).then((res: any) => {
    //   const { status, response } = res;
    //   if (status === 200) {
    //     setRows(
    //       response.records.map((r, i) => {
    //         r.isActive = i % 2 === 0 ? "true" : "false";
    //         return r;
    //       })
    //     );
    //     setColumnChart(response.columnChart);
    //     setPieChart(response.pieChart);
    //     setLoading(false);
    //   }
    // });
  };

  const onAdd = () => {
    setManageDialog(!manageDialog);
    setPageNo(1);
    setSelectedRow({});
  };

  const onEditRow = (row: any) => {
    setPageNo(1);
    setSelectedRow(row);
    // console.log("rowIndex", rowIndex);
    setManageDialog(true);
    setEditRows(null);
  };

  // const getRowInfo = (contractorId) => {
  //   const args = {
  //     contractorId,
  //   };
  //   API_HANDLER_EHS.getContractorInfo(args).then((res: any) => {
  //     const { status, response } = res;
  //     if (status === 200) {
  //       setEditRows(response);
  //     }
  //   });
  // };

  const manageEquipmentRegister = () => {
    let equipmentRegister = [];
    const rowData = localStorage.getItem(LOCAL_STORAGE.EUIPMENT_REGISTER);
    if (!rowData) {
      equipmentRegister = [];
    } else {
      equipmentRegister = JSON.parse(rowData);
    }

    // get local storage => if null => []
    // else => parse the data
    const owner = (
      document.getElementById("owner") as HTMLInputElement
    ).value.trim();
    const equipmentId = (
      document.getElementById("equipmentId") as HTMLInputElement
    ).value.trim();
    const serialNumber = (
      document.getElementById("serialNumber") as HTMLInputElement
    ).value.trim();
    const Manufacturer = (
      document.getElementById("Manufacturer") as HTMLInputElement
    ).value.trim();
    // const equipmetCategoryId = equipmetCategoryRef.current.selectedValue.id; //todo: if equipmetCategory and equipmentType id dropdown
    // const equipmentTypeId = equipmentTypeRef.current.selectedValue.id;
    const operator = (
      document.getElementById("operator") as HTMLInputElement
    ).value.trim();
    const contactNo = (
      document.getElementById("contactNo") as HTMLInputElement
    ).value.trim();
    const equipmetCategory = (
      document.getElementById("equipmetCategory") as HTMLInputElement
    ).value.trim();
    const lastServiceDate = (
      document.getElementById("lastServiceDate") as HTMLInputElement
    ).value.trim();
    const purchasedate = (
      document.getElementById("purchasedate") as HTMLInputElement
    ).value.trim();
    const equipmentType = (
      document.getElementById("equipmentType") as HTMLInputElement
    ).value.trim();
    const serviceDueDate = (
      document.getElementById("serviceDueDate") as HTMLInputElement
    ).value.trim();
    const frequencyofService = (
      document.getElementById("frequencyofService") as HTMLInputElement
    ).value.trim();
    const remarks = (
      document.getElementById("remarks") as HTMLInputElement
    ).value.trim();
    // const active = document.querySelector(
    //   'input[name="availableStatus"]:checked'
    // ) as HTMLInputElement;
    // const availableStatus = active ? active.value : "";

    const args = {
      owner,
      equipmentId,
      equipmetCategory,
      equipmentType,
      serialNumber,
      Manufacturer,
      operator,
      contactNo,
      purchasedate,
      lastServiceDate,
      serviceDueDate,
      frequencyofService,
      remarks,
      // availableStatus,
    };

    if (selectedRow.slNo) {
      // update record
      //  let register = equipmentRegister[selectedRow.slNo - 1];
      equipmentRegister[selectedRow.slNo - 1] = args;
    } else {
      equipmentRegister.push(args);
    }

    localStorage.setItem(
      LOCAL_STORAGE.EUIPMENT_REGISTER,
      JSON.stringify(equipmentRegister)
    );

    setSelectedRow({});
    setManageDialog(false);
    onReload();
    console.log(
      "manageRegister: ",
      equipmentRegister,
      JSON.stringify(equipmentRegister)
    );

    return;
  };

  const onReload = () => {
    setRows(null);
    setTimeout(() => {
      getRows();
    }, 500);
  };

  const onCloseForm = () => {
    setManageDialog(false);
  };

  console.log("filteredRows: filters:", rows);
  console.log("selectedRow:", selectedRow);

  return (
    <React.Fragment>
      <div className="pageInfo">
        <ul className="breadcrumbs">
          <li onClick={() => props.setDashMenu(LABELS.HOME)}>{LABELS.HOME}</li>
          <li className="dim">{" / "}</li>
          <li className="active">{TITLES.EQUIPMENT_REGISTER}</li>
        </ul>
        <h4>{LABELS.EQUIPMENT_REGISTER}</h4>
      </div>

      {/* Master Grid */}
      {rows ? (
        <ExcelGrid
          id={"equipmetRegister"}
          height={window.innerHeight * 0.8}
          cols={cols}
          rows={rows}
          exportOption={true}
          newRowOption={false}
          exportName={LABELS.EQUIPMENT_REGISTER}
          onReload={onReload}
          onAdd={onAdd}
        />
      ) : (
        <div className="pageCenter">
          <Loader />
        </div>
      )}

      {/* Form Dialog Box */}
      {manageDialog && (
        <UiDialog
          title={
            selectedRow.id
              ? "Edit " + LABELS.EQUIPMENT_REGISTER + ": " + selectedRow.id
              : "Add New " + LABELS.EQUIPMENT_REGISTER
          }
          open={true}
          size={"xl"}
          onClose={() => {
            setManageDialog(false);
            setFormData({});
            setPageNo(1);
          }}
        >
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              <div
                className="formGroup"
                style={{ width: "30%", marginRight: 20 }}
              >
                <label className="ehsHeading1 ">{"Owner"}</label>
                <TxtBox
                  type={"text"}
                  id="owner"
                  defaultValue={selectedRow.owner ? selectedRow.owner : ""}
                />
              </div>
              <div
                className="formGroup"
                style={{ width: "30%", marginRight: 20 }}
              >
                <label className="ehsHeading1 ">{"Equipment ID"}</label>
                <TxtBox
                  type={"text"}
                  id="equipmentId"
                  defaultValue={
                    selectedRow.equipmentId ? selectedRow.equipmentId : ""
                  }
                />
              </div>
              <div
                className="formGroup"
                style={{ width: "30%", marginRight: 20 }}
              >
                <label className="ehsHeading1">{"Equipment Category "}</label>
                {/* <AutoComplete
                  id="equipmetCategory"
                  list={equipmetCategory}
                  childRef={equipmetCategoryRef}
                  // defaultValue={
                  //   selectedRow.id
                  //     ? {
                  //         id: selectedRow.orgId,
                  //         label: selectedRow.orgName,
                  //       }
                  //     : null
                  // }
                /> */}

                <TxtBox
                  type={"text"}
                  id="equipmetCategory"
                  defaultValue={
                    selectedRow.equipmetCategory
                      ? selectedRow.equipmetCategory
                      : ""
                  }
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              <div
                className="formGroup"
                style={{ width: "30%", marginRight: 20 }}
              >
                <label className="ehsHeading1">{" Equipment Type "}</label>
                {/* <AutoComplete
                  id="equipmentType"
                  list={[
                    {
                      id: "CSA",
                      label: "CSA",
                    },
                    {
                      id: "Electrical",
                      label: "Electrical",
                    },
                    {
                      id: "Mechanical",
                      label: "Mechanical",
                    },
                    {
                      id: "Sprinkler",
                      label: "Sprinkler",
                    },
                  ]}
                  childRef={equipmentTypeRef}
                  // defaultValue={
                  //   selectedRow.contractorType
                  //     ? {
                  //         id: selectedRow.contractorType,
                  //         label: selectedRow.contractorType,
                  //       }
                  //     : null
                  // }
                /> */}
                <TxtBox
                  type={"text"}
                  id="equipmentType"
                  defaultValue={
                    selectedRow.equipmentType ? selectedRow.equipmentType : ""
                  }
                />
              </div>
              <div
                className="formGroup"
                style={{ width: "30%", marginRight: 20 }}
              >
                <label className="ehsHeading1 ">{"Serial Number "}</label>
                <TxtBox
                  type={"number"}
                  id="serialNumber"
                  defaultValue={
                    selectedRow.serialNumber ? selectedRow.serialNumber : ""
                  }
                />
              </div>
              <div
                className="formGroup"
                style={{ width: "30%", marginRight: 20 }}
              >
                <label className="ehsHeading1">{"Manufacturer "}</label>
                <TxtBox
                  type={"text"}
                  id="Manufacturer"
                  defaultValue={
                    selectedRow.Manufacturer ? selectedRow.Manufacturer : ""
                  }
                />
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              <div
                className="formGroup"
                style={{ width: "30%", marginRight: 20 }}
              >
                <label className="ehsHeading1 ">{"Operator "}</label>
                <TxtBox
                  type={"text"}
                  id="operator"
                  defaultValue={
                    selectedRow.operator ? selectedRow.operator : ""
                  }
                />
              </div>
              <div
                className="formGroup"
                style={{ width: "30%", marginRight: 20 }}
              >
                <label className="ehsHeading1 ">{"Contact No "}</label>
                <TxtBox
                  type={"text"}
                  id="contactNo"
                  defaultValue={
                    selectedRow.contactNo ? selectedRow.contactNo : ""
                  }
                />
              </div>
              <div
                className="formGroup"
                style={{ width: "30%", marginRight: 20 }}
              >
                <label className="ehsHeading1 ">{"Date of Purchase "}</label>
                <TxtBox
                  type={"date"}
                  id="purchasedate"
                  defaultValue={
                    selectedRow.purchasedate ? selectedRow.purchasedate : ""
                  }
                />
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              <div
                className="formGroup"
                style={{ width: "30%", marginRight: 20 }}
              >
                <label className="ehsHeading1 ">{"Last Service Date "}</label>
                <TxtBox
                  type={"date"}
                  id="lastServiceDate"
                  defaultValue={
                    selectedRow.lastServiceDate
                      ? selectedRow.lastServiceDate
                      : ""
                  }
                />
              </div>
              <div
                className="formGroup"
                style={{ width: "30%", marginRight: 20 }}
              >
                <label className="ehsHeading1 ">{"Service Due Date "}</label>
                <TxtBox
                  type={"date"}
                  id="serviceDueDate"
                  defaultValue={
                    selectedRow.serviceDueDate ? selectedRow.serviceDueDate : ""
                  }
                />
              </div>
              <div
                className="formGroup"
                style={{ width: "30%", marginRight: 20 }}
              >
                <label className="ehsHeading1 ">
                  {"Frequency of Service "}
                </label>
                <TxtBox
                  type={"text"}
                  id="frequencyofService"
                  defaultValue={
                    selectedRow.frequencyofService
                      ? selectedRow.frequencyofService
                      : ""
                  }
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              <div
                className="formGroup"
                style={{ width: "61%", marginRight: 30 }}
              >
                <label className="ehsHeading1">{"Remarks "}</label>

                <textarea id="remarks" name="remarks" rows={5}>
                  {selectedRow.remarks ? selectedRow.remarks : ""}
                </textarea>
              </div>
              <div
                className="formGroup"
                style={{
                  width: "30%",
                  marginRight: 20,
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                }}
              >
                {/* <label className="ehsHeading1 ">{" Available for Use"}</label>
                <RadioBtn
                  list={[
                    {
                      id: "true",
                      value: "true",
                      label: "Yes",
                      checked: "true",
                      // selectedRow.owner
                      //   ? selectedRow.availableStatus === "true"
                      //   : true,
                      //  disabled: false,
                    },
                    {
                      id: "false",
                      value: "false",
                      label: "No",
                      checked: false,
                      //  selectedRow.id
                      //   ? selectedRow.availableStatus === "false"
                      //   : false,
                      // disabled: false,
                    },
                  ]}
                  row={true}
                  name={"availableStatus"}
                /> */}
              </div>
            </div>

            <div style={{ width: "100%", flexDirection: "column" }}>
              <ModalBtmBtns
                onSave={manageEquipmentRegister}
                onCancel={onCloseForm}
                disabled={false}
              />
            </div>
          </>
        </UiDialog>
      )}
    </React.Fragment>
  );
};

export default withSnackbar(EquipmentRegister);
