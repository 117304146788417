/*
 * Safety Monitoring & Measurement Form
 */

import React, { useRef, useEffect, useState } from "react";
import { withSnackbar } from "notistack";
import { IconButton, Tooltip } from "@mui/material";
import { Edit as IconEdit } from "@mui/icons-material";

import UiDialog from "components/Custom/UiDialog";
import ExcelGrid from "components/Custom/ExcelGrid";
import AutoComplete from "components/Custom/AutoComplete";
import SkeletonBox from "components/Custom/SkeletonBox";
import ModalBtmBtns from "components/Custom/ModalBtmBtns";
import Loader from "components/Custom/Loader";
import HGraph from "components/Custom/HGraph";
import PartLast from "./sections/PartLast";

import { LABELS, NOTIFY, SUCCESS, WARNINGS } from "common/config";
import { ApiHome } from "apihandlers/home";
import { ApiEhs } from "apihandlers/ehs";
import { COMMON_JS } from "common/scripts";
import Btn from "components/Custom/Btn";
import TxtBox from "components/Custom/TxtBox";

const API_HANDLER_HOME = new ApiHome();
const API_HANDLER_EHS = new ApiEhs();

const dataJson = require("./gridData.json");

const NearMissReport = (props: any) => {
  const {} = props;
  const mainGridCols = [
    {
      key: "slNo",
      name: "Sl No.",
      type: "number",
      width: "5%",
      editable: false,
      sortable: true,
      align: "center",
      searchable: true,
    },
    {
      key: "nearmissTitle",
      name: "Event Title",
      type: "text",
      width: "20%",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "locationName",
      name: "Location",
      type: "text",
      width: "20%",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "descriptionDetails",
      name: "Event Description",
      type: "text",
      width: "20%",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "eventOn",
      name: "Incident date",
      type: "text",
      width: "10%",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "createdBy",
      name: "created By",
      type: "text",
      width: "20%",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "options",
      name: "Options",
      type: "html",
      width: "5%",
      editable: false,
      sortable: false,
      renderCell: ({ row }: any) => (
        <div className="options">
          <Tooltip title={LABELS.EDIT} placement="bottom">
            <IconButton className="iconBtn" onClick={() => onEditRow(row)}>
              <IconEdit />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];
  const [loading, setLoading] = useState<boolean>(true);
  const [projects, setProjects] = useState<any[]>([]);
  const [cols, setCols] = useState<any[]>(mainGridCols);
  const [rows, setRows] = useState<any[]>(null);
  const [columnChart, setColumnChart] = useState<any>({});
  const [pieChart, setPieChart] = useState<any>({});
  const [manageDialog, setManageDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [editRows, setEditRows] = useState<any[]>(null);
  const [pageNo, setPageNo] = useState(1);
  const [totalPages, setTotalPages] = useState(6);
  const [formData, setFormData] = useState<any>({});
  const [uploads, setUploads] = useState<any[]>([]);
  const [orgList, setOrgList] = useState<any[]>([]);
  const [contractorList, setContractorList] = useState<any[]>([]);
  const [taskList, setTaskList] = useState([
    {
      id: "Yes",
      label: "Yes",
    },
    {
      id: "No",
      label: "No",
    },
  ]);
  const [hazardList, setHazardList] = useState([
    {
      id: "Yes",
      label: "Yes",
    },
    {
      id: "No",
      label: "No",
    },
  ]);
  const [statusList, setStatusList] = useState([
    {
      id: "Open",
      label: "Open",
    },
    {
      id: "Close",
      label: "Close",
    },
  ]);

  const taskRef: any = useRef();
  const hazardRef: any = useRef();
  const statusRef: any = useRef();
  const contractorRef: any = useRef();
  const projectsRef: any = useRef();
  const part1Ref: any = useRef();
  const part2Ref: any = useRef();
  const part3Ref: any = useRef();
  const part4Ref: any = useRef();
  const part5Ref: any = useRef();
  const part6Ref: any = useRef();

  useEffect(() => {
    return () => {
      cleanUp();
    };
  }, []);

  useEffect(() => {
    if (loading) {
      // getContractors();
      getRows();
    }
  }, [loading]);

  const getContractors = () => {
    const args = { dropdown: true };
    API_HANDLER_EHS.getContractor(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        const list = response.records.map((c: any) => {
          return {
            id: c.id,
            label: c.nameOfContractor,
          };
        });
        console.log("formData: list:", list);
        setContractorList(list);
        getRows();
      }
    });
  };
  const getOrgs = () => {
    const args = {};
    API_HANDLER_HOME.getOrganizations(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        setOrgList(
          response.records.map((o: any) => {
            return {
              id: o.id,
              label: o.orgName,
            };
          })
        );
        getRows();
      }
    });
  };

  const getProjects = () => {
    const args = { dropdown: true };
    API_HANDLER_HOME.getProjects(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        setProjects(response.records);
        getRows();
      }
    });
  };

  const cleanUp = () => {
    setRows(null);
    setLoading(true);
    setManageDialog(false);
    setSelectedRow({});
    console.log("Cleaned up");
  };

  const getRows = () => {
    setRows(null);
    const args = {};
    API_HANDLER_EHS.getNearMisses(args).then((res: any) => {
      const { status, response } = res;
      if (status === 200) {
        setRows(response.records);
        setColumnChart(response.columnChart);
        setPieChart(response.pieChart);
        setLoading(false);
      }
    });
  };

  const onAdd = () => {
    setManageDialog(!manageDialog);
    setEditRows([]);
    setPageNo(1);
    setSelectedRow({});
    setUploads([]);
  };

  const onEditRow = (row: any) => {
    setPageNo(1);
    setSelectedRow(row);
    setManageDialog(true);
    setEditRows(null);
    getRowInfo(row.id);
  };

  const getRowInfo = (nearmissId) => {
    const args = { nearmissId };
    setUploads(null);
    API_HANDLER_EHS.getNearMissInfo(args).then((res: any) => {
      const { status, response } = res;
      if (status === 200) {
        setUploads(response.files);
      }
    });
  };

  const getProjectInfo = () => {
    const projectId = projectsRef.current.selectedValue.id;
    if (!projectId) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.PROJECT_EMPTY);
      return;
    }
    const args = {
      projectId,
    };
    API_HANDLER_EHS.getNearMisses(args).then((res: any) => {
      const { status, response } = res;
      if (status === 200) {
        setColumnChart(response.columnChart);
        setPieChart(response.pieChart);
      }
    });
  };

  const manageNearMiss = () => {
    const locationName = (
      document.getElementById("locationName") as HTMLInputElement
    ).value.trim();
    const nearmissTitle = (
      document.getElementById("nearmissTitle") as HTMLInputElement
    ).value.trim();
    const eventOn = (
      document.getElementById("eventOn") as HTMLInputElement
    ).value.trim();
    const descriptionDetails = (
      document.getElementById("descriptionDetails") as HTMLInputElement
    ).value.trim();
    const person = (
      document.getElementById("person") as HTMLInputElement
    ).value.trim();
    const contact = (
      document.getElementById("contact") as HTMLInputElement
    ).value.trim();
    const time = (
      document.getElementById("time") as HTMLInputElement
    ).value.trim();
    const email = (
      document.getElementById("email") as HTMLInputElement
    ).value.trim();
    const cardNum = (
      document.getElementById("cardNum") as HTMLInputElement
    ).value.trim();
    const ppe = (
      document.getElementById("ppe") as HTMLInputElement
    ).value.trim();
    const rootCauseAnalysis = (
      document.getElementById("rootCauseAnalysis") as HTMLInputElement
    ).value.trim();
    const accident = (
      document.getElementById("accident") as HTMLInputElement
    ).value.trim();
    const resolve = (
      document.getElementById("resolve") as HTMLInputElement
    ).value.trim();
    const contractorResolve = (
      document.getElementById("contractorResolve") as HTMLInputElement
    ).value.trim();
    const task = taskRef.current.selectedValue.id;
    const incidentStatus = statusRef.current.selectedValue.id;
    const isHazard = hazardRef.current.selectedValue.id;

    if (nearmissTitle === "") {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, "Please enter title..!");
      return null;
    }
    if (locationName === "") {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        "Please enter location details..!"
      );
      return null;
    }
    if (eventOn === "") {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, "Please enter event date..!");
      return null;
    }
    if (descriptionDetails === "") {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        "Please enter event details..!"
      );
      return null;
    }

    let args: any = updateForm();
    args = {
      ...args,
      nearmissId: selectedRow.id || -1,
      locationName,
      nearmissTitle,
      eventOn,
      descriptionDetails,
      nearmissDetails: {
        "1.4": time,
        "1.7": { person, contact, email },
        "1.8": cardNum,
        "1.9": ppe,
        "1.10": task,
        "1.11": isHazard,
        "1.12": rootCauseAnalysis,
        "1.13": accident,
        "1.14": resolve,
        "1.15": contractorResolve,
        "1.16": incidentStatus,
      },
      files: uploads,
    };

    console.log("manageNearMiss: ", args);

    // Validations
    setEditRows(null);
    API_HANDLER_EHS.manageNearMiss(args).then((res) => {
      if (res.status === 200) {
        COMMON_JS.showNotify(
          props,
          NOTIFY.SUCCESS,
          SUCCESS.SAFETY_MONITOR_SAVED
        );
        setSelectedRow({});
        setManageDialog(false);
        getRows();
      } else {
        COMMON_JS.showNotify(props, NOTIFY.ERROR, res.error);
      }
    });
  };

  const updateForm = () => {
    let args: any = {};
    setFormData(args);
    return args;
  };

  const onReload = () => {
    getRows();
  };

  const onCloseForm = () => {
    setManageDialog(false);
  };

  let nearmissDetails: any = {};
  if (selectedRow.id) {
    nearmissDetails = JSON.parse(selectedRow.nearmissDetails);
  }

  console.log("selectedRow:", selectedRow, nearmissDetails);

  return (
    <React.Fragment>
      <div className="pageInfo">
        <ul className="breadcrumbs">
          <li>{LABELS.HOME}</li>
          <li className="dim">{" / "}</li>
          <li onClick={() => props.setDashMenu(LABELS.HOME)}>{LABELS.EHS}</li>
          <li className="dim">{" / "}</li>
          <li className="active">{LABELS.NEAR_MISS}</li>
        </ul>
        <h4>{LABELS.NEAR_MISS}</h4>
      </div>

      {/* Graphs */}
      {pieChart.series && (
        <div className="graphs">
          <HGraph
            width={"50%"}
            type={"column"}
            xAxis={columnChart.xAxis}
            yAxis={columnChart.yAxis}
            title={" "}
            exportTitle={" "}
          />
          <HGraph
            width={"50%"}
            type={"pie"}
            float={"right"}
            series={pieChart.series}
            title={" "}
            exportTitle={" "}
          />
        </div>
      )}

      {/* Master Grid */}
      {rows ? (
        <ExcelGrid
          id={"nearMissReport"}
          height={window.innerHeight * 0.6}
          cols={cols}
          rows={rows}
          exportOption={true}
          newRowOption={false}
          exportName={LABELS.NEAR_MISS}
          onReload={onReload}
          onAdd={onAdd}
        />
      ) : (
        <div className="pageCenter">
          <Loader />
        </div>
      )}

      {/* Form Dialog Box */}
      {manageDialog && (
        <UiDialog
          title={
            selectedRow.nearmissTitle
              ? "Edit " + LABELS.NEAR_MISS + ": " + selectedRow.locationName
              : "Add New " + LABELS.NEAR_MISS
          }
          open={true}
          size={"xl"}
          onClose={() => {
            setManageDialog(false);
            setFormData({});
            setPageNo(1);
          }}
        >
          <>
            <div style={{ width: "100%", flexDirection: "column" }}>
              {/* 1.1 */}
              <>
                <div className="formRow">
                  <div className="formGroup" style={{ width: "30%" }}>
                    <label className="red">{"1.1 Title *"}</label>
                    <TxtBox
                      type={"text"}
                      id="nearmissTitle"
                      defaultValue={
                        selectedRow.id ? selectedRow.nearmissTitle : ""
                      }
                    />
                  </div>
                  <div className="formGroup" style={{ width: "30%" }}>
                    <label className="red">{"1.2 Location *"}</label>
                    <TxtBox
                      type={"text"}
                      id="locationName"
                      defaultValue={
                        selectedRow.id ? selectedRow.locationName : ""
                      }
                    />
                  </div>
                  <div className="formGroup" style={{ width: "30%" }}>
                    <label className="red">{"1.3 Event Date *"}</label>
                    <TxtBox
                      type={"date"}
                      id="eventOn"
                      defaultValue={selectedRow.id ? selectedRow.eventOn : ""}
                    />
                  </div>
                </div>
                <div className="formRow">
                  <div className="formGroup" style={{ width: "30%" }}>
                    <label>{"1.4 Event Time"}</label>
                    <TxtBox
                      type={"time"}
                      id="time"
                      defaultValue={
                        selectedRow.id ? nearmissDetails["1.4"] : ""
                      }
                    />
                  </div>

                  <div className="formGroup" style={{ width: "30%" }}>
                    <label className="red">{"1.5 Event Description *"}</label>
                    <TxtBox
                      type={"text"}
                      id="descriptionDetails"
                      rows={3}
                      defaultValue={
                        selectedRow.id ? selectedRow.descriptionDetails : ""
                      }
                    />
                  </div>
                  <div className="formGroup" style={{ width: "30%" }}>
                    <label>{" 1.7 Affected Person details"}</label>
                    <div className="formGroup">
                      <TxtBox
                        type={"text"}
                        placeholder={"Name"}
                        id="person"
                        defaultValue={
                          selectedRow.id ? nearmissDetails["1.7"]["person"] : ""
                        }
                      />
                    </div>
                    <div className="formGroup">
                      <TxtBox
                        type={"text"}
                        placeholder={" Contact No"}
                        id="contact"
                        defaultValue={
                          selectedRow.id
                            ? nearmissDetails["1.7"]["contact"]
                            : ""
                        }
                      />
                    </div>
                    <div className="formGroup">
                      <TxtBox
                        type={"text"}
                        placeholder={"Email Address"}
                        id="email"
                        defaultValue={
                          selectedRow.id ? nearmissDetails["1.7"]["email"] : ""
                        }
                      />
                    </div>
                  </div>
                </div>

                <div className="formRow">
                  <div className="formGroup" style={{ width: "30%" }}>
                    <label>{"1.8 Card No."}</label>
                    <TxtBox
                      type={"text"}
                      id="cardNum"
                      defaultValue={
                        selectedRow.id ? nearmissDetails["1.8"] : ""
                      }
                    />
                  </div>
                  <div className="formGroup" style={{ width: "30%" }}>
                    <label>{"1.9 What PPE Was worn?."}</label>
                    <TxtBox
                      type={"text"}
                      id="ppe"
                      defaultValue={
                        selectedRow.id ? nearmissDetails["1.9"] : ""
                      }
                    />
                  </div>
                  <div className="formGroup" style={{ width: "30%" }}>
                    <label>
                      {"1.10 Was a Risk Assessment in Place for the Task?:"}
                    </label>
                    <AutoComplete
                      list={taskList}
                      childRef={taskRef}
                      defaultValue={
                        selectedRow.id
                          ? {
                              id: nearmissDetails["1.10"],
                              label: nearmissDetails["1.10"],
                            }
                          : null
                      }
                    />
                  </div>
                </div>

                <div className="formRow">
                  <div className="formGroup" style={{ width: "30%" }}>
                    <label>
                      {"1.11 Did the risk assessment identify the hazard?:"}
                    </label>
                    <AutoComplete
                      list={hazardList}
                      childRef={hazardRef}
                      defaultValue={
                        selectedRow.id
                          ? {
                              id: nearmissDetails["1.11"],
                              label: nearmissDetails["1.11"],
                            }
                          : null
                      }
                    />
                  </div>
                  <div className="formGroup" style={{ width: "30%" }}>
                    <label>
                      {
                        "1.12 ROOT CAUSE ANALYSIS “This must be filled out and completed by the site/project manager and Safety Manager”:"
                      }
                    </label>
                    <TxtBox
                      type={"text"}
                      id="rootCauseAnalysis"
                      rows={3}
                      defaultValue={
                        selectedRow.id ? nearmissDetails["1.12"] : ""
                      }
                    />
                  </div>
                  <div className="formGroup" style={{ width: "30%" }}>
                    <label>
                      {
                        "1.13 Why did the accident/Incident happen? (Include; People involved; Plant and equipment and risk controls that were in place at the time of the accident/incident):"
                      }
                    </label>
                    <TxtBox
                      type={"text"}
                      id="accident"
                      rows={3}
                      defaultValue={
                        selectedRow.id ? nearmissDetails["1.13"] : ""
                      }
                    />
                  </div>
                </div>

                <div className="formRow">
                  <div className="formGroup" style={{ width: "30%" }}>
                    <label>
                      {
                        "1.14 What could have been done differently to avoid this happening again?:"
                      }
                    </label>
                    <TxtBox
                      type={"text"}
                      id="resolve"
                      rows={3}
                      defaultValue={
                        selectedRow.id ? nearmissDetails["1.14"] : ""
                      }
                    />
                  </div>
                  <div className="formGroup" style={{ width: "30%" }}>
                    <label>
                      {
                        "1.15 What changes have the Contractor team made to avoid this happening again?:"
                      }
                    </label>
                    <TxtBox
                      type={"text"}
                      id="contractorResolve"
                      rows={3}
                      defaultValue={
                        selectedRow.id ? nearmissDetails["1.15"] : ""
                      }
                    />
                  </div>
                  <div className="formGroup" style={{ width: "30%" }}>
                    <label>{"1.16 Incident Status"}</label>
                    <AutoComplete
                      list={statusList}
                      childRef={statusRef}
                      defaultValue={
                        selectedRow.id
                          ? {
                              id: nearmissDetails["1.16"],
                              label: nearmissDetails["1.16"],
                            }
                          : null
                      }
                    />
                  </div>
                </div>
              </>

              {uploads && (
                <PartLast
                  childRef={part6Ref}
                  selectedRow={selectedRow}
                  uploads={uploads}
                  setUploads={(val) => {
                    setUploads(val);
                  }}
                />
              )}

              <ModalBtmBtns
                onSave={manageNearMiss}
                onCancel={onCloseForm}
                //disabled={editRows === null}
              />
            </div>
          </>
        </UiDialog>
      )}
    </React.Fragment>
  );
};

export default withSnackbar(NearMissReport);
