import React, { Component, useState } from "react";
import {
  MenuList,
  MenuItem,
  ListItemText,
  ListItemIcon,
  Tooltip,
} from "@mui/material";
import {
  Home as IconHome,
  Menu as IconMenu,
  PowerSettingsNew as IconLogout,
  Group as IconUsers,
  FolderCopy as IconProjects,
  CloudUpload as IconUpload,
  InsertChart as IconChart,
  Dashboard as IconDashboard,
  AccountBalance as IconOrg,
  CheckBoxOutlined as IconApprove,
  Settings as IconSettings,
  PermMedia as IconProjectStatus,
  AppRegistration as IconRiskRegister,
  ContactMail as IconRfi,
  HealthAndSafety as IconEHS,
  EventNote as IconLessonsLearned,
  Download as IconDownload,
  AssignmentTurnedIn as IconCQV,
} from "@mui/icons-material";
import { withSnackbar } from "notistack";

import ConfirmDialog from "components/Custom/ConfirmDialog";

import {
  CONFIRMS,
  DEFAULTS,
  LABELS,
  SUCCESS,
  NOTIFY,
  ERRORS,
  LOCAL_STORAGE,
  CONFIG,
  TITLES,
} from "common/config";
import { ApiLogin } from "apihandlers/login";
import { COMMON_JS } from "common/scripts";

const logoSmall = require("assets/images/logo.png");

const API_HANDLER_LOGIN = new ApiLogin();

const LeftBar = (props: any) => {
  const { page, setPage, userInfo } = props;
  const [leftBar, setLeftBar] = useState(false);
  const [logoutPopup, setLogoutPopup] = useState(false);

  const onUserLogout = () => {
    API_HANDLER_LOGIN.userLogout({}).then((res) => {
      if (res.status === 200) {
        COMMON_JS.showNotify(props, NOTIFY.SUCCESS, SUCCESS.LOGOUT_SUCCESS);
        localStorage.removeItem(LOCAL_STORAGE.USER_INFO);
        localStorage.removeItem(LOCAL_STORAGE.ACCESS_TOKEN);
        setLogoutPopup(false);
        setTimeout(() => {
          window.open("/login", "_self");
        }, 500);
      } else {
        COMMON_JS.showNotify(props, NOTIFY.ERROR, ERRORS.LOGOUT_FAILED);
      }
    });
  };

  const onSetLeftBar = (val: boolean) => {
    setLeftBar(val);
    if (val) {
      (document.querySelector(".content") as HTMLElement).classList.add(
        "active"
      );
    } else {
      (document.querySelector(".content") as HTMLElement).classList.remove(
        "active"
      );
    }
  };

  let menuGanttChart = false;
  if (
    userInfo.role.id === CONFIG.USER_ROLES.Admin ||
    userInfo.role.id === CONFIG.USER_ROLES.Director ||
    userInfo.role.id === CONFIG.USER_ROLES.ProjectEngineer ||
    userInfo.role.id === CONFIG.USER_ROLES.ProjectManager1 ||
    userInfo.role.id === CONFIG.USER_ROLES.ProjectManager2 ||
    userInfo.role.id === CONFIG.USER_ROLES.Supervisor ||
    userInfo.role.id === CONFIG.USER_ROLES.ClearSphere
  ) {
    menuGanttChart = true;
  }

  let menuReports = false;
  if (
    userInfo.role.id === CONFIG.USER_ROLES.Admin ||
    userInfo.role.id === CONFIG.USER_ROLES.Director ||
    userInfo.role.id === CONFIG.USER_ROLES.ProjectEngineer ||
    userInfo.role.id === CONFIG.USER_ROLES.ProjectManager1 ||
    userInfo.role.id === CONFIG.USER_ROLES.ProjectManager2 ||
    userInfo.role.id === CONFIG.USER_ROLES.ClearSphere
  ) {
    menuReports = true;
  }

  let menuOrg = false;
  if (userInfo.role.id === CONFIG.USER_ROLES.Admin) {
    menuOrg = true;
  }

  let menuUsers = false;
  if (userInfo.role.id === CONFIG.USER_ROLES.Admin) {
    menuUsers = true;
  }

  let menuProjects = false;
  if (userInfo.role.id === CONFIG.USER_ROLES.Admin) {
    menuProjects = true;
  }

  let menuApproveFiles = false;
  if (
    userInfo.role.id === CONFIG.USER_ROLES.Admin ||
    userInfo.role.id === CONFIG.USER_ROLES.Director ||
    userInfo.role.id === CONFIG.USER_ROLES.ClearSphere
  ) {
    menuApproveFiles = true;
  }

  let menuFileUpload = true;
  if (userInfo.role.id === CONFIG.USER_ROLES.Ehs) {
    menuFileUpload = false;
  }

  let menuProjectStatus = true;
  if (userInfo.role.id === CONFIG.USER_ROLES.Ehs) {
    menuProjectStatus = false;
  }

  let menuRiskRegister = true;
  if (userInfo.role.id === CONFIG.USER_ROLES.Ehs) {
    menuRiskRegister = false;
  }

  let menuRfi = true;
  if (userInfo.role.id === CONFIG.USER_ROLES.Ehs) {
    menuRfi = false;
  }

  let menuLessonsLearned = true;
  if (userInfo.role.id === CONFIG.USER_ROLES.Ehs) {
    menuLessonsLearned = false;
  }

  let menuConstraintLog = true;
  if (userInfo.role.id === CONFIG.USER_ROLES.Ehs) {
    menuConstraintLog = false;
  }

  let menuEhs = false;
  let menuLototo = false;
  if (
    userInfo.role.id === CONFIG.USER_ROLES.Admin ||
    userInfo.role.id === CONFIG.USER_ROLES.Director ||
    userInfo.role.id === CONFIG.USER_ROLES.Ehs ||
    userInfo.role.id === CONFIG.USER_ROLES.ProjectManager1 ||
    userInfo.role.id === CONFIG.USER_ROLES.ClearSphere
  ) {
    menuEhs = true;
    menuLototo = true;
  }

  let menuPreTender = false;
  if (
    userInfo.role.id === CONFIG.USER_ROLES.Admin ||
    userInfo.role.id === CONFIG.USER_ROLES.Director ||
    userInfo.role.id === CONFIG.USER_ROLES.Ehs ||
    userInfo.role.id === CONFIG.USER_ROLES.ProjectManager1 ||
    userInfo.role.id === CONFIG.USER_ROLES.ClearSphere
  ) {
    menuPreTender = true;
  }

  let menuAudit = false;
  if (
    userInfo.role.id === CONFIG.USER_ROLES.Admin ||
    userInfo.role.id === CONFIG.USER_ROLES.Director ||
    userInfo.role.id === CONFIG.USER_ROLES.Ehs ||
    userInfo.role.id === CONFIG.USER_ROLES.ProjectManager1 ||
    userInfo.role.id === CONFIG.USER_ROLES.ClearSphere
  ) {
    menuAudit = true;
  }

  let menuCqv = false;
  if (
    userInfo.role.id === CONFIG.USER_ROLES.Admin ||
    userInfo.role.id === CONFIG.USER_ROLES.Director ||
    userInfo.role.id === CONFIG.USER_ROLES.Ehs ||
    userInfo.role.id === CONFIG.USER_ROLES.ProjectManager1 ||
    userInfo.role.id === CONFIG.USER_ROLES.ClearSphere
  ) {
    menuCqv = true;
  }

  let menuEqupmentRegister = false;
  if (
    userInfo.role.id === CONFIG.USER_ROLES.Admin ||
    userInfo.role.id === CONFIG.USER_ROLES.Director ||
    userInfo.role.id === CONFIG.USER_ROLES.Ehs ||
    userInfo.role.id === CONFIG.USER_ROLES.ProjectManager1 ||
    userInfo.role.id === CONFIG.USER_ROLES.ClearSphere
  ) {
    menuEqupmentRegister = true;
  }

  let menuPunchDetails = false;
  if (
    userInfo.role.id === CONFIG.USER_ROLES.Admin ||
    userInfo.role.id === CONFIG.USER_ROLES.Director ||
    userInfo.role.id === CONFIG.USER_ROLES.Ehs ||
    userInfo.role.id === CONFIG.USER_ROLES.ProjectManager1 ||
    userInfo.role.id === CONFIG.USER_ROLES.ClearSphere
  ) {
    menuPunchDetails = true;
  }

  // userInfo.role.id CONFIG.USER_ROLES,

  console.log("LeftBar: page: ", userInfo.role);

  return (
    <div
      className={"leftBar " + (leftBar ? "active" : "")}
      // onMouseEnter={() => {
      //   onSetLeftBar(true);
      // }}
      // onMouseLeave={() => {
      //   onSetLeftBar(false);
      // }}
    >
      <MenuList className="leftMenuWrapper">
        <MenuItem
          className={"leftMenu "}
          onClick={() => onSetLeftBar(!leftBar)}
        >
          <ListItemIcon>
            <IconMenu fontSize="small" />
          </ListItemIcon>
          <div>
            <h4>
              {CONFIG.SITE_NAME} <small>{CONFIG.VERSION}</small>
            </h4>
            {/* <img src={logoSmall} className="responsive" /> */}
          </div>
        </MenuItem>

        <MenuItem
          className={
            "leftMenu " +
            (page === LABELS.HOME || page === LABELS.MENU || page === ""
              ? "active"
              : "")
          }
          onClick={() => setPage(LABELS.HOME)}
        >
          <Tooltip title={LABELS.HOME} placement={"right-start"}>
            <div>
              <ListItemIcon>
                <IconHome fontSize="small" />
              </ListItemIcon>
              <ListItemText className="menuText">{LABELS.HOME}</ListItemText>
            </div>
          </Tooltip>
        </MenuItem>

        {menuPreTender && (
          <MenuItem
            className={
              "leftMenu " + (page === LABELS.EVALUATION ? "active" : "")
            }
            onClick={() => setPage(LABELS.EVALUATION)}
          >
            <Tooltip title={LABELS.EVALUATION} placement={"right-start"}>
              <div>
                <ListItemIcon>
                  <IconOrg fontSize="small" />
                </ListItemIcon>
                <ListItemText className="menuText">
                  {LABELS.EVALUATION}
                </ListItemText>
              </div>
            </Tooltip>
          </MenuItem>
        )}

        {menuPreTender && (
          <MenuItem
            className={
              "leftMenu " + (page === LABELS.PRE_TENDER ? "active" : "")
            }
            onClick={() => setPage(LABELS.PRE_TENDER)}
          >
            <Tooltip title={LABELS.PRE_TENDER} placement={"right-start"}>
              <div>
                <ListItemIcon>
                  <IconLessonsLearned fontSize="small" />
                </ListItemIcon>
                <ListItemText className="menuText">
                  {LABELS.PRE_TENDER}
                </ListItemText>
              </div>
            </Tooltip>
          </MenuItem>
        )}

        {menuGanttChart && (
          <MenuItem
            className={
              "leftMenu gantt " + (page === LABELS.GANTT_CHART ? "active" : "")
            }
            onClick={() => setPage(LABELS.GANTT_CHART)}
          >
            <Tooltip title={LABELS.GANTT_CHART} placement={"right-start"}>
              <div>
                <ListItemIcon>
                  <IconChart fontSize="small" />
                </ListItemIcon>
                <ListItemText className="menuText">
                  {LABELS.GANTT_CHART}
                </ListItemText>
              </div>
            </Tooltip>
          </MenuItem>
        )}

        {menuReports && (
          <MenuItem
            className={"leftMenu " + (page === LABELS.REPORTS ? "active" : "")}
            onClick={() => setPage(LABELS.REPORTS)}
          >
            <Tooltip title={LABELS.REPORTS} placement={"right-start"}>
              <div>
                <ListItemIcon>
                  <IconDashboard fontSize="small" />
                </ListItemIcon>
                <ListItemText className="menuText">
                  {LABELS.REPORTS}
                </ListItemText>
              </div>
            </Tooltip>
          </MenuItem>
        )}

        {menuFileUpload && (
          <MenuItem
            className={
              "leftMenu " + (page === LABELS.FILE_UPLOAD ? "active" : "")
            }
            onClick={() => setPage(LABELS.FILE_UPLOAD)}
          >
            <Tooltip title={LABELS.FILE_UPLOAD} placement={"right-start"}>
              <div>
                <ListItemIcon>
                  <IconUpload fontSize="small" />
                </ListItemIcon>
                <ListItemText className="menuText">
                  {LABELS.FILE_UPLOAD}
                </ListItemText>
              </div>
            </Tooltip>
          </MenuItem>
        )}

        {menuFileUpload && (
          <MenuItem
            className={
              "leftMenu " + (page === LABELS.PROJECT_HISTORY ? "active" : "")
            }
            onClick={() => setPage(LABELS.PROJECT_HISTORY)}
          >
            <Tooltip title={LABELS.PROJECT_HISTORY} placement={"right-start"}>
              <div>
                <ListItemIcon>
                  <IconDownload fontSize="small" />
                </ListItemIcon>
                <ListItemText className="menuText">
                  {LABELS.PROJECT_HISTORY}
                </ListItemText>
              </div>
            </Tooltip>
          </MenuItem>
        )}

        {menuApproveFiles && (
          <MenuItem
            className={
              "leftMenu " + (page === LABELS.APPROVE_FILE ? "active" : "")
            }
            onClick={() => setPage(LABELS.APPROVE_FILE)}
          >
            <Tooltip title={LABELS.APPROVE_FILE} placement={"right-start"}>
              <div>
                <ListItemIcon>
                  <IconApprove fontSize="small" />
                </ListItemIcon>
                <ListItemText className="menuText">
                  {LABELS.APPROVE_FILE}
                </ListItemText>
              </div>
            </Tooltip>
          </MenuItem>
        )}

        {menuProjects && (
          <MenuItem
            className={"leftMenu " + (page === LABELS.PROJECTS ? "active" : "")}
            onClick={() => setPage(LABELS.PROJECTS)}
          >
            <Tooltip title={LABELS.PROJECTS} placement={"right-start"}>
              <div>
                <ListItemIcon>
                  <IconProjects fontSize="small" />
                </ListItemIcon>
                <ListItemText className="menuText">
                  {LABELS.PROJECTS}
                </ListItemText>
              </div>
            </Tooltip>
          </MenuItem>
        )}

        {menuOrg && (
          <MenuItem
            className={
              "leftMenu " + (page === LABELS.ORGANIZATION ? "active" : "")
            }
            onClick={() => setPage(LABELS.ORGANIZATION)}
          >
            <Tooltip title={LABELS.ORGANIZATION} placement={"right-start"}>
              <div>
                <ListItemIcon>
                  <IconOrg fontSize="small" />
                </ListItemIcon>
                <ListItemText className="menuText">
                  {LABELS.ORGANIZATION}
                </ListItemText>
              </div>
            </Tooltip>
          </MenuItem>
        )}

        {menuUsers && (
          <MenuItem
            className={"leftMenu " + (page === LABELS.USERS ? "active" : "")}
            onClick={() => setPage(LABELS.USERS)}
          >
            <Tooltip title={LABELS.USERS} placement={"right-start"}>
              <div>
                <ListItemIcon>
                  <IconUsers fontSize="small" />
                </ListItemIcon>
                <ListItemText className="menuText">{LABELS.USERS}</ListItemText>
              </div>
            </Tooltip>
          </MenuItem>
        )}

        {menuProjectStatus && (
          <MenuItem
            className={
              "leftMenu " + (page === LABELS.PROJECT_STATUS ? "active" : "")
            }
            onClick={() => setPage(LABELS.PROJECT_STATUS)}
          >
            <Tooltip title={LABELS.PROJECT_STATUS} placement={"right-start"}>
              <div>
                <ListItemIcon>
                  <IconProjectStatus fontSize="small" />
                </ListItemIcon>
                <ListItemText className="menuText">
                  {LABELS.PROJECT_STATUS}
                </ListItemText>
              </div>
            </Tooltip>
          </MenuItem>
        )}

        {/* {menuLototo && (
          <MenuItem
            className={"leftMenu " + (page === LABELS.LOTOTO ? "active" : "")}
            onClick={() => setPage(LABELS.LOTOTO)}
          >
            <Tooltip title={LABELS.LOTOTO} placement={"right-start"}>
              <div>
                <ListItemIcon>
                  <IconProjectStatus fontSize="small" />
                </ListItemIcon>
                <ListItemText className="menuText">
                  {LABELS.LOTOTO}
                </ListItemText>
              </div>
            </Tooltip>
          </MenuItem>
        )} */}

        {menuRiskRegister && (
          <MenuItem
            className={
              "leftMenu " + (page === LABELS.RISKS_REGISTER ? "active" : "")
            }
            onClick={() => setPage(LABELS.RISKS_REGISTER)}
          >
            <Tooltip title={LABELS.RISKS_REGISTER} placement={"right-start"}>
              <div>
                <ListItemIcon>
                  <IconRiskRegister fontSize="small" />
                </ListItemIcon>
                <ListItemText className="menuText">
                  {LABELS.RISKS_REGISTER}
                </ListItemText>
              </div>
            </Tooltip>
          </MenuItem>
        )}

        {menuRfi && (
          <MenuItem
            className={"leftMenu " + (page === LABELS.RFI ? "active" : "")}
            onClick={() => setPage(LABELS.RFI)}
          >
            <Tooltip title={LABELS.RFI} placement={"right-start"}>
              <div>
                <ListItemIcon>
                  <IconRfi fontSize="small" />
                </ListItemIcon>
                <ListItemText className="menuText">{LABELS.RFI}</ListItemText>
              </div>
            </Tooltip>
          </MenuItem>
        )}

        {menuEhs && (
          <MenuItem
            className={"leftMenu " + (page === LABELS.EHS ? "active" : "")}
            onClick={() => setPage(LABELS.EHS)}
          >
            <Tooltip title={LABELS.EHS} placement={"right-start"}>
              <div>
                <ListItemIcon>
                  <IconEHS fontSize="small" />
                </ListItemIcon>
                <ListItemText className="menuText">{LABELS.EHS}</ListItemText>
              </div>
            </Tooltip>
          </MenuItem>
        )}

        {menuCqv && (
          <MenuItem
            className={"leftMenu " + (page === TITLES.CQV ? "active" : "")}
            onClick={() => setPage(TITLES.CQV)}
          >
            <Tooltip title={TITLES.CQV} placement={"right-start"}>
              <div>
                <ListItemIcon>
                  <IconCQV fontSize="small" />
                </ListItemIcon>
                <ListItemText className="menuText">{TITLES.CQV}</ListItemText>
              </div>
            </Tooltip>
          </MenuItem>
        )}

        {menuPunchDetails && (
          <MenuItem
            className={
              "leftMenu " + (page === LABELS.PUNCHLIST_DETAILS ? "active" : "")
            }
            onClick={() => setPage(LABELS.PUNCHLIST_DETAILS)}
          >
            <Tooltip title={LABELS.PUNCHLIST_DETAILS} placement={"right-start"}>
              <div>
                <ListItemIcon>
                  <IconProjects fontSize="small" />
                </ListItemIcon>
                <ListItemText className="menuText">
                  {LABELS.PUNCHLIST_DETAILS}
                </ListItemText>
              </div>
            </Tooltip>
          </MenuItem>
        )}

        {menuEqupmentRegister && (
          <MenuItem
            className={
              "leftMenu " + (page === TITLES.EQUIPMENT_REGISTER ? "active" : "")
            }
            onClick={() => setPage(TITLES.EQUIPMENT_REGISTER)}
          >
            <Tooltip
              title={TITLES.EQUIPMENT_REGISTER}
              placement={"right-start"}
            >
              <div>
                <ListItemIcon>
                  <IconCQV fontSize="small" />
                </ListItemIcon>
                <ListItemText className="menuText">
                  {TITLES.EQUIPMENT_REGISTER}
                </ListItemText>
              </div>
            </Tooltip>
          </MenuItem>
        )}

        {menuLessonsLearned && (
          <MenuItem
            className={
              "leftMenu " + (page === LABELS.LESSONS_LEARNED ? "active" : "")
            }
            onClick={() => setPage(LABELS.LESSONS_LEARNED)}
          >
            <Tooltip title={LABELS.LESSONS_LEARNED} placement={"right-start"}>
              <div>
                <ListItemIcon>
                  <IconLessonsLearned fontSize="small" />
                </ListItemIcon>
                <ListItemText className="menuText">
                  {LABELS.LESSONS_LEARNED}
                </ListItemText>
              </div>
            </Tooltip>
          </MenuItem>
        )}

        {menuConstraintLog && (
          <MenuItem
            className={
              "leftMenu " +
              (page === LABELS.CONSTRAINT_LOG_REPORT ? "active" : "")
            }
            onClick={() => setPage(LABELS.CONSTRAINT_LOG_REPORT)}
          >
            <Tooltip
              title={LABELS.CONSTRAINT_LOG_REPORT}
              placement={"right-start"}
            >
              <div>
                <ListItemIcon>
                  <IconLessonsLearned fontSize="small" />
                </ListItemIcon>
                <ListItemText className="menuText">
                  {LABELS.CONSTRAINT_LOG_REPORT}
                </ListItemText>
              </div>
            </Tooltip>
          </MenuItem>
        )}

        {menuAudit && (
          <MenuItem
            className={"leftMenu " + (page === LABELS.AUDIT ? "active" : "")}
            onClick={() => setPage(LABELS.AUDIT)}
          >
            <Tooltip title={LABELS.AUDIT} placement={"right-start"}>
              <div>
                <ListItemIcon>
                  <IconApprove fontSize="small" />
                </ListItemIcon>
                <ListItemText className="menuText">{LABELS.AUDIT}</ListItemText>
              </div>
            </Tooltip>
          </MenuItem>
        )}

        <MenuItem
          className={"leftMenu " + (page === LABELS.SETTINGS ? "active" : "")}
          onClick={() => setPage(LABELS.SETTINGS)}
        >
          <Tooltip title={LABELS.SETTINGS} placement={"right-start"}>
            <div>
              <ListItemIcon>
                <IconSettings fontSize="small" />
              </ListItemIcon>
              <ListItemText className="menuText">
                {LABELS.SETTINGS}
              </ListItemText>
            </div>
          </Tooltip>
        </MenuItem>

        <MenuItem
          className={"leftMenu " + (page === LABELS.LOGOUT ? "active" : "")}
          onClick={() => setLogoutPopup(!logoutPopup)}
        >
          <Tooltip title={LABELS.LOGOUT} placement={"right-start"}>
            <div>
              <ListItemIcon>
                <IconLogout fontSize="small" />
              </ListItemIcon>
              <ListItemText className="menuText">{LABELS.LOGOUT}</ListItemText>
            </div>
          </Tooltip>
        </MenuItem>
      </MenuList>

      <ConfirmDialog
        open={logoutPopup}
        title={LABELS.CONFIRM}
        msg={CONFIRMS.SURE_TO_LOGOUT}
        size={DEFAULTS.DIALOG_SIZE}
        onClose={() => setLogoutPopup(false)}
        onOk={onUserLogout}
      />
    </div>
  );
};

export default withSnackbar(LeftBar);
