/*
 * Highcharts Graph: https://www.highcharts.com/demo/highcharts
 * Supported Graphs: Bar, Pie
 */

import React, { useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import { COLORS, GRAPH_TYPES } from "common/config";
import { GRAPHS } from "common/graphs";

interface cProps {
  width: string;
  type: "column" | "pie" | "bar";
  title: string;
  exportTitle: string;
  float?: "left" | "right";
  xAxis?: string[];
  yAxis?: number[];
  series?: any;
  colors?: string[];
  xAxisTitle?: string;
  yAxisTitle?: string;
  height?: number;
}

const HGraph = (props: cProps) => {
  const {
    width,
    type,
    title,
    exportTitle,
    float,
    xAxis,
    yAxis,
    series,
    colors,
    yAxisTitle,
    xAxisTitle,
    height,
  } = props;
  const chartRef: any = useRef(null);

  const getOptions = () => {
    switch (type) {
      case GRAPH_TYPES.COLUMN:
        return GRAPHS.getColumnGraph(title, xAxis, yAxis);
      case GRAPH_TYPES.PIE:
        return GRAPHS.getPieGraph(title, series, height);
      case GRAPH_TYPES.BAR:
        return GRAPHS.getBarGraph(
          title,
          xAxis,
          yAxis,
          xAxisTitle,
          yAxisTitle,
          exportTitle,
          height
        );
      default:
        break;
    }
  };

  const options: any = getOptions();

  if (colors) {
    options.colors = colors;
  }

  return (
    <div
      className="graphBox"
      style={{
        width: width,
        flexDirection: "row",
        justifyContent: float === "right" ? "flex-end" : "flex-start",
      }}
    >
      <div style={{ width: "98%" }}>
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
          ref={chartRef}
        />
      </div>
    </div>
  );
};

export default HGraph;
