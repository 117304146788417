import React, { useEffect, useRef, useState } from "react";
import { withSnackbar } from "notistack";
import { GetApp as IconExport } from "@mui/icons-material";

import Loader from "components/Custom/Loader";
import AutoComplete from "components/Custom/AutoComplete";
import Btn from "components/Custom/Btn";

import { LABELS, NOTIFY, WARNINGS } from "common/config";
import { ApiDashboard } from "apihandlers/dashboard";
import { ApiHome } from "apihandlers/home";
import { COMMON_JS } from "common/scripts";
import { IconButton, Tooltip } from "@mui/material";

const API_HANDLER_DASHBOARD = new ApiDashboard();
const API_HANDLER_HOME = new ApiHome();

const ProjectOverView = (props: any) => {
  const [loading, setLoading] = useState(true);
  const [projects, setProjects] = useState([]);
  const [chartData, setChartData] = useState<any>({});

  useEffect(() => {
    if (loading) {
      getProjects();
    }
  }, [loading]);

  const getProjects = () => {
    const args = { dropdown: true };
    API_HANDLER_HOME.getProjects(args).then((res) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        setProjects(response.records);
      }
      setLoading(false);
    });
  };

  const loadChart = () => {
    const projectId = projectRef.current.selectedValue.id;
    if (!projectId) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.PROJECT_EMPTY);
      return;
    }
    const args = {
      projectId,
    };
    setChartData(null);
    API_HANDLER_DASHBOARD.getProjectOverview(args).then((res) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        setTimeout(() => {
          setChartData(response);
        }, 2000);
      } else {
        setChartData({});
        COMMON_JS.showNotify(props, NOTIFY.ERROR, res.error);
      }
    });
  };

  const printToPdf = () => {
    let projectName = "";
    if (projectRef.current) {
      projectName = projectRef.current.selectedValue.label;
    }
    const title = LABELS.PROJECT_CASH_FLOW + " - " + projectName.toUpperCase();
    let header = "<html>";
    header +=
      "<head><title>" +
      title +
      "</title><style>@page{margin-top: 5;}</style></head>";
    let body = "<body><h2 style='text-align: center;'>" + title + "</h2>";
    body += (document.getElementById("projectOverview") as HTMLElement)
      .innerHTML;
    body += "</html>";
    const html = header + body;
    const printWin = window.open("", "", "toolbar=0,scrollbars=0,status=0");
    printWin.document.write(html);
    printWin.document.close();
    printWin.focus();
    printWin.print();
    printWin.close();
    console.log("printToPdf: ", html);
  };

  const projectRef: any = useRef();

  return (
    <React.Fragment>
      <div className="pageInfo">
        <ul className="breadcrumbs">
          <li>{LABELS.HOME}</li>
          <li className="dim">{" / "}</li>
          <li onClick={() => props.setDashMenu(LABELS.HOME)}>
            {LABELS.DASHBOARD}
          </li>
          <li className="dim">{" / "}</li>
          <li className="active">{LABELS.PROJECT_OVERVIEW}</li>
        </ul>
        <h4>{LABELS.PROJECT_OVERVIEW}</h4>
      </div>
      {loading ? (
        <div className="flexCenter" style={{ minHeight: 450 }}>
          <Loader />
        </div>
      ) : (
        <div className="hChart">
          <div id="chartForm">
            <div className="formGroup">
              <label>{LABELS.PROJECT_NAME}</label>
              <AutoComplete
                id={"projects"}
                list={projects}
                childRef={projectRef}
                disabled={!chartData}
              />
            </div>

            <div
              className="formGroup"
              style={{
                display: "flex",
                marginTop: 15,
                marginBottom: 25,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Btn
                  text={LABELS.LOAD}
                  onClick={loadChart}
                  loading={!chartData}
                />
                {chartData && chartData.series && (
                  <Tooltip title={"Print"}>
                    <IconButton
                      style={{
                        marginLeft: 10,
                      }}
                      onClick={printToPdf}
                    >
                      <IconExport />
                    </IconButton>
                  </Tooltip>
                )}
              </div>
            </div>
          </div>

          {chartData ? (
            chartData.series ? (
              <div style={{ width: "100%" }}>
                {/* <button
                  style={{
                    paddingTop: 5,
                    paddingBottom: 5,
                    paddingLeft: 20,
                    paddingRight: 20,
                    marginBottom: 10,
                    fontSize: 14,
                    fontWeight: "bold",
                  }}
                  onClick={printToPdf}
                >
                  {"Print"}
                </button> */}
                <div id="projectOverview" style={{ marginBottom: 30 }}>
                  <h4
                    style={{
                      fontSize: 20,
                      fontWeight: 500,
                      marginTop: 0,
                      marginBottom: 10,
                    }}
                  >
                    {chartData.startDt + " - " + chartData.finishDt}
                  </h4>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <div style={{ width: "30%" }}>
                      <div
                        style={{
                          width: "70%",
                          border: "1px solid #ccc",
                          marginBottom: 25,
                          display: "flex",
                          flexDirection: "column",
                          padding: 10,
                          background: "#ededed",
                        }}
                      >
                        <p style={{ fontSize: 12, margin: 0 }}>
                          {"% Complete"}
                        </p>
                        <h4
                          style={{
                            textAlign: "center",
                            fontSize: 24,
                          }}
                        >
                          {chartData.percentage + "%"}
                        </h4>
                      </div>
                      <div
                        style={{
                          width: "70%",
                          border: "1px solid #ccc",
                          marginBottom: 15,
                          display: "flex",
                          flexDirection: "column",
                          padding: 10,
                          background: "#ededed",
                        }}
                      >
                        <p
                          style={{
                            fontSize: 12,
                            margin: 0,
                            textAlign: "center",
                          }}
                        >
                          {"Project Remaining Duration"}
                        </p>
                        <h4
                          style={{
                            textAlign: "center",
                            fontSize: 24,
                          }}
                        >
                          {chartData.days + " Days"}
                        </h4>
                      </div>
                    </div>
                    <table
                      className="reportTable"
                      width={"60%"}
                      border={1}
                      cellSpacing={"0"}
                      cellPadding={"5"}
                    >
                      <thead>
                        <tr>
                          <th align="left">{"Task Name"}</th>
                          <th align="left">{"Finish Date"}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {chartData.series.map((s: any, i: number) => {
                          return (
                            <tr
                              style={{
                                backgroundColor: s.highlight
                                  ? "#009A44"
                                  : "white",
                                color: s.highlight ? "white" : "black",
                              }}
                            >
                              <td>{s.task}</td>
                              <td>{s.finishDt}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            ) : (
              <div></div>
            )
          ) : (
            <div
              className="flexCenter"
              style={{ minHeight: 400, width: "100%" }}
            >
              <Loader />
            </div>
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default withSnackbar(ProjectOverView);
