import React, { useState, useEffect, useRef } from "react";

import ExcelGrid from "components/Custom/ExcelGrid";
import Loader from "components/Custom/Loader";

const dataJson = require("../gridData.json");

interface defaultProps {
  childRef: any;
  editRows: any[];
}

const Part7_3_2 = (props: defaultProps) => {
  const { childRef, editRows } = props;
  const [firstAidCols, setFirstAidCols] = useState<any[]>([
    {
      key: "question",
      name: "Question",
      type: "text",
      editable: false,
      width: "65%",
    },
    {
      key: "checkYes",
      name: "Yes",
      inputValue: "true",
      type: "radiobutton",
      sortable: false,
      width: "5%",
      className: "textCenter",
    },
    {
      key: "checkNo",
      name: "No",
      inputValue: "false",
      type: "radiobutton",
      sortable: false,
      width: "5%",
      className: "textCenter",
    },
    {
      key: "checkNa",
      name: "N/A",
      inputValue: "",
      type: "radiobutton",
      sortable: false,
      width: "5%",
      className: "textCenter",
    },
    {
      key: "comments",
      name: "Comments",
      type: "text",
      sortable: false,
      width: "20%",
    },
  ]);

  let tmpFirstAidRows: any = dataJson["7"]["7.3"]["7.3.2"]["7.3.2.1"];
  for (let key in tmpFirstAidRows) {
    tmpFirstAidRows[key].checkYes = false;
    tmpFirstAidRows[key].checkNo = false;
    tmpFirstAidRows[key].checkNa = false;
    tmpFirstAidRows[key].comments = "";
  }

  if (!editRows) {
    tmpFirstAidRows = null;
  } else if (editRows) {
    for (let key in tmpFirstAidRows) {
      const details = editRows[key];
      const jsonData = {
        ...tmpFirstAidRows[key],
        ...details,
      };
      tmpFirstAidRows[key] = jsonData;
    }
  }

  const [firstAidRows, setFirstAidRows] = useState<any[]>(tmpFirstAidRows);

  const grid1Ref: any = useRef();

  useEffect(() => {
    childRef.saveRecord = saveRecord;
    return () => {
      childRef.saveRecord = null;
    };
  }, []);

  const saveRecord = () => {
    const section1 = grid1Ref.getRowData().map((s1) => {
      return {
        id: s1.id,
        checkYes: s1.checkYes,
        checkNo: s1.checkNo,
        checkNa: s1.checkNa,
        comments: s1.comments,
      };
    });
    const items = [...section1];
    return { items };
  };

  return (
    <>
      <h4 className="ehsHeading1">
        {dataJson["7"]["7.3"]["7.3.2"]["heading1"]}
      </h4>
      {/* <h5 className="ehsHeading2">{dataJson["7"]["7.3"]["heading2"]}</h5> */}
      {tmpFirstAidRows ? (
        <ExcelGrid
          childRef={grid1Ref}
          cols={firstAidCols}
          rows={tmpFirstAidRows}
          noTotalRows={true}
        />
      ) : (
        <div className="pageCenter">
          <Loader />
        </div>
      )}
    </>
  );
};

export default Part7_3_2;
