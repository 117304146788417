import React, { useEffect, useRef, useState } from "react";
import { withSnackbar } from "notistack";
import {
  DataGrid,
  GridColDef,
  GridToolbarExport,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import { IconButton, Tooltip } from "@mui/material";
import {
  Edit as IconEdit,
  AccountCircle as IconUser,
  Chat as IconReply,
} from "@mui/icons-material";

import Btn from "components/Custom/Btn";
import AutoComplete from "components/Custom/AutoComplete";
import ConfirmDialog from "components/Custom/ConfirmDialog";
import Loader from "components/Custom/Loader";
import UiDialog from "components/Custom/UiDialog";
import TxtBox from "components/Custom/TxtBox";
import RfiResponses from "./RfiResponses";

import {
  DEFAULTS,
  ERRORS,
  LABELS,
  NOTIFY,
  SUCCESS,
  WARNINGS,
  API_CONFIG,
} from "common/config";
import { ApiHome } from "apihandlers/home";
import { ApiDashboard } from "apihandlers/dashboard";
import { COMMON_JS } from "common/scripts";
import ExcelGrid from "components/Custom/ExcelGrid";

const API_HANDLER_HOME = new ApiHome();
const API_HANDLER_DASHBOARD = new ApiDashboard();

const imagePdf = require("assets/images/pdf.png");

const Rfi = (props: any) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showDialog, setShowDialog] = useState(false);
  const [users, setUsers] = useState([]);
  const [projects, setProjects] = useState([]);
  const [disciplines, setDisciplines] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [selectedRowFiles, setSelectedRowFiles] = useState([]);
  const [rows, setRows] = useState<any>([]);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [responses, setResponses] = useState<any>(null);
  const [selectedEditRow, setSelectedEditRow] = useState<any>({});
  const [editRows, setEditRows] = useState<any[]>(null);
  const [history, setHistory] = useState<any>(null);
  const [apiArgs, setApiArgs] = useState<any>({});
  const [markup, setMarkup] = useState("");
  const [historyId, setHistoryId] = useState("");
  const [htmlLoading, setHtmlLoading] = useState(false);
  const jRef = useRef(null);

  const options = {
    data: [[]],
    minDimensions: [10, 10],
  };

  const columns = [
    {
      key: "slNo",
      name: "Sl No.",
      type: "number",
      align: "center",
      headerAlign: "center",
      editable: false,
      sortable: true,
      searchable: true,
      width: "5%",
    },
    {
      key: "taskName",
      name: "Task Name",
      width: "15%",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "taskOwner",
      name: "Task Owner",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      width: "10%",
    },
    {
      key: "subject",
      name: "Subject",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      width: "20%",
    },
    {
      key: "constraints",
      name: "Constraints",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      width: "15%",
    },
    {
      key: "status",
      name: "Status",
      type: "dropdown",
      editable: false,
      sortable: true,
      searchable: true,
      filters: [
        { id: "open", name: "OPEN" },
        { id: "closed", name: "CLOSED" },
      ],
      width: "10%",
    },
    {
      key: "uploadDate",
      name: "Upload Date",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      width: "10%",
    },
    {
      key: "uploadBy",
      name: "Upload By",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      width: "10%",
    },
    {
      key: "options",
      name: "Options",
      width: "5%",
      disableExport: true,
      renderCell: (params: any) => (
        <div className="options">
          <Tooltip title={LABELS.EDIT} placement="bottom">
            <IconButton
              className="iconBtn"
              onClick={() => onEditRow(params.row)}
            >
              <IconEdit />
            </IconButton>
          </Tooltip>
          <Tooltip title={"Responses"} placement="bottom">
            <IconButton
              className="iconBtn"
              onClick={() => onEditResponses(params.row)}
            >
              <IconReply />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (loading) {
      getUsers();
    }
  }, [users]);

  const onEditResponses = (row: any) => {
    console.log("onEditResponses: ", row);
    setSelectedRow(row);
    setUploadedFiles([]);
    getRfiResponses(row.id);
    setSelectedRowFiles([]);
  };

  const onEditRow = (row: any) => {
    console.log("onEditRow: ", row);
    setSelectedEditRow(row);
    setShowDialog(true);
    const args = {
      rfiId: row.id,
    };
    API_HANDLER_HOME.getRfiFiles(args).then((res: any) => {
      if (res.status === 200) {
        setUploadedFiles(res.response);
      }
    });
  };

  const getUsers = () => {
    const args = { dropdown: true };
    API_HANDLER_HOME.getUsers(args).then((res) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        getProjects(response.records);
      }
    });
  };

  const getProjects = (users = []) => {
    const args = { dropdown: true };
    API_HANDLER_HOME.getProjects(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        setUsers(users);
        setProjects(response.records);
        setLoading(false);
      }
    });
  };

  const onConfirmApprove = () => {
    setLoading(true);
    API_HANDLER_DASHBOARD.saveRisksRegister(apiArgs).then((res) => {
      if (res.status === 200) {
        COMMON_JS.showNotify(props, NOTIFY.SUCCESS, "Saved Successfully..!");
        setLoading(false);
        setApiArgs({});
        getData();
      } else {
        setLoading(false);
        COMMON_JS.showNotify(props, NOTIFY.ERROR, "Not Saved Successfully..!");
      }
    });
  };

  const getData = () => {
    const projectId = projectRef.current.selectedValue.id;
    if (!projectId) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.PROJECT_EMPTY);
      return;
    }
    const args = {
      projectId,
    };
    setRows(null);
    API_HANDLER_HOME.getRfiList(args).then((res: any) => {
      if (res.status === 200) {
        setRows(res.response.records);
        setIsLoaded(true);
      }
    });
  };

  const saveData = () => {
    const projectId = projectRef.current.selectedValue.id;
    const primaryRecipient = primaryRecipientRef.current.selectedValue.id;
    const discipline = disciplineRef.current.selectedValue.label;
    const status = statusRef.current.selectedValue.id;
    const taskOwner = (
      document.getElementById("taskOwner") as HTMLInputElement
    ).value.trim();
    const dueDate = (document.getElementById("dueDate") as HTMLInputElement)
      .value;
    const constraints = (
      document.getElementById("constraints") as HTMLInputElement
    ).value.trim();
    const subject = (
      document.getElementById("subject") as HTMLInputElement
    ).value.trim();

    if (!taskOwner) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, "Please enter task owner..!");
      return;
    }
    if (!primaryRecipient) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        "Please select primary recipient..!"
      );
      return;
    }
    if (!discipline) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        "Please select discipline..!"
      );
      return;
    }
    if (!dueDate) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, "Please enter due date..!");
      return;
    }
    if (!status) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, "Please select status..!");
      return;
    }
    if (!subject) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, "Please enter subject..!");
      return;
    }
    if (!constraints) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        "Please enter constraints..!"
      );
      return;
    }

    const args = {
      id: selectedEditRow.id || -1,
      projectId,
      taskOwner,
      primaryRecipient,
      discipline,
      dueDate,
      status,
      subject,
      constraints,
      constraintNumber: selectedEditRow.constraintNumber || "",
      uploadedFiles: uploadedFiles.filter((f) => {
        if (f.id) {
          return true;
        }
        return false;
      }),
    };
    API_HANDLER_HOME.manageRfi(args).then((res) => {
      console.log("manageRfi: ", args, res);
      if (res.status === 200) {
        COMMON_JS.showNotify(
          props,
          NOTIFY.SUCCESS,
          "Record saved successfully..!"
        );
        setShowDialog(false);
        setUploadedFiles([]);
        getData();
      } else {
        COMMON_JS.showNotify(
          props,
          NOTIFY.ERROR,
          "Record not saved successfully..!"
        );
      }
    });
  };

  const onChangeProject = (e: any, val: any) => {
    let projectId = val.id;
    const args = { dropdown: true, projectId };
    API_HANDLER_HOME.getMainTasks(args).then((res) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        setDisciplines(response.records);
      }
    });
  };

  const getRfiResponses = (id: any) => {
    const args = { id };
    API_HANDLER_HOME.getRfiResponses(args).then((res: any) => {
      if (res.status === 200) {
        setResponses(res.response.records);
        setSelectedRowFiles(res.response.files);
      }
    });
  };

  const onChangeFile = (e: any) => {
    const projectId = projectRef.current.selectedValue.id;
    if (!projectId) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.PROJECT_EMPTY);
      return;
    }
    const file = e.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let base64: any = reader.result;
      const args: any = {
        file: {
          name: file.name,
          data: base64.replace("data:", "").replace(/^.+,/, ""),
          type: file.type,
          size: file.size,
        },
        category: "rfi",
        projectId,
      };
      setLoading(true);
      API_HANDLER_HOME.fileUpload(args).then((res: any) => {
        if (res.status === 200) {
          COMMON_JS.showNotify(props, NOTIFY.SUCCESS, SUCCESS.FILE_UPLOADED);
          let files = uploadedFiles;
          files.push(res.response);
          setUploadedFiles(files);
        } else {
          COMMON_JS.showNotify(props, NOTIFY.ERROR, "Error: " + res.error);
        }
        setLoading(false);
      });
    };
    reader.onerror = (error) => {
      console.log("onChangeFile: Error:", error);
    };
  };

  const onAddNew = () => {
    const projectId = projectRef.current.selectedValue.id;
    if (!projectId) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.PROJECT_EMPTY);
      return;
    }
    setShowDialog(!showDialog);
    setEditRows([]);
    setSelectedEditRow({});
  };

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
      </GridToolbarContainer>
    );
  };

  const saveResponse = () => {
    const response = (
      document.getElementById("response") as HTMLInputElement
    ).value.trim();
    if (!response) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, "Please enter response..!");
      return;
    }
    const args = {
      id: selectedRow.id,
      response,
      files: uploadedFiles,
    };
    API_HANDLER_HOME.manageRfiResponse(args).then((res) => {
      if (res.status === 200) {
        COMMON_JS.showNotify(
          props,
          NOTIFY.SUCCESS,
          "Response Saved Successfully..!"
        );
        setSelectedRow({});
        setUploadedFiles([]);
        setSelectedRowFiles([]);
      } else {
        COMMON_JS.showNotify(props, NOTIFY.ERROR, "Error: " + res.error);
      }
    });
  };

  const projectRef: any = useRef();
  const statusRef: any = useRef();
  const primaryRecipientRef: any = useRef();
  const disciplineRef: any = useRef();

  const statusList = [
    {
      id: "OPEN",
      label: "OPEN",
    },
    {
      id: "CLOSED",
      label: "CLOSED",
    },
  ];

  let defaultRecipient = null;
  if (selectedEditRow.recipient) {
    defaultRecipient = users.find((u) => u.id === selectedEditRow.recipient);
  }
  console.log("rfi selected row:", selectedRow);
  console.log("rfi: files:", selectedRowFiles);

  return (
    <React.Fragment>
      <div className="pageInfo">
        <ul className="breadcrumbs">
          <li>{LABELS.HOME}</li>
          <li className="dim">{" / "}</li>
          <li className="active">{LABELS.RFI}</li>
        </ul>
        <h4>{LABELS.RFI}</h4>
      </div>

      <div id="fileUploadForm" style={{ marginTop: 15 }}>
        <div className="formGroup">
          <label>{LABELS.PROJECT_NAME}</label>
          <AutoComplete
            id={"projects"}
            list={projects}
            childRef={projectRef}
            onChange={onChangeProject}
          />
        </div>
        <div
          className="formGroup"
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: 0,
            marginBottom: 15,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              marginRight: 15,
            }}
          >
            <Btn text={LABELS.LOAD} onClick={getData} loading={loading} />
          </div>
          {isLoaded && (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                marginRight: 15,
              }}
            >
              {/* <Btn text={"Add New"} onClick={onAddNew} /> */}
            </div>
          )}
          {/* <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <Tooltip title={LABELS.EXPORT_TO_PDF}>
              <IconButton
                disabled={loading || markup === ""}
                onClick={exportData}
              >
                <IconExport />
              </IconButton>
            </Tooltip>
          </div> */}
        </div>
      </div>

      {loading ? (
        <div className="flexCenter">
          <Loader />
        </div>
      ) : (
        isLoaded && (
          <div
            id={"gridBox"}
            style={{
              width: "98%",
              height: "75%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            {rows ? (
              <ExcelGrid
                id={"rfi"}
                height={window.innerHeight * 0.6}
                cols={columns}
                rows={rows}
                exportOption={true}
                newRowOption={false}
                exportName={LABELS.RFI}
                onReload={getData}
                onAdd={onAddNew}
              />
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  height: 300,
                  width: "100%",
                }}
              >
                <Loader />
              </div>
            )}
          </div>
        )
      )}

      {showDialog && (
        <UiDialog
          open={true}
          title={
            selectedEditRow.id
              ? "Edit RFI: " + selectedEditRow.taskName
              : "Add New RFI: "
          }
          size={DEFAULTS.DIALOG_SIZE}
          onClose={() => {
            setShowDialog(false);
          }}
        >
          <div className="formGroup">
            <label>{"Task Owner"}</label>
            <TxtBox
              placeholder={"Task Owner"}
              id={"taskOwner"}
              type={"text"}
              defaultValue={selectedEditRow.taskOwner}
            />
          </div>

          <div className="formGroup">
            <label>{"Primary Recipient"}</label>
            <AutoComplete
              id={"primaryRecipient"}
              list={users}
              childRef={primaryRecipientRef}
              defaultValue={defaultRecipient}
            />
          </div>

          <div className="formGroup">
            <label>{"Discipline"}</label>
            <AutoComplete
              id={"discipline"}
              list={disciplines}
              childRef={disciplineRef}
              defaultValue={
                selectedEditRow.id
                  ? {
                      id: selectedEditRow.taskName,
                      label: selectedEditRow.taskName,
                    }
                  : null
              }
            />
          </div>

          <div className="formGroup">
            <label>{"Subject"}</label>
            <TxtBox
              placeholder={"Subject"}
              id={"subject"}
              type={"text"}
              defaultValue={selectedEditRow.subject}
            />
          </div>

          <div className="formGroup">
            <label>{"Constraints"}</label>
            <TxtBox
              placeholder={"Constraints"}
              id={"constraints"}
              type={"text"}
              multiline={true}
              rows={4}
              className={"multiLine"}
              defaultValue={selectedEditRow.constraints}
            />
          </div>

          <div className="formGroup">
            <label>{"Due Date"}</label>
            <TxtBox
              placeholder={"Due Date"}
              id={"dueDate"}
              type={"date"}
              defaultValue={selectedEditRow.dueDate}
            />
          </div>

          <div className="formGroup">
            <label>{"Status"}</label>
            <AutoComplete
              id={"status"}
              list={statusList}
              childRef={statusRef}
              defaultValue={
                selectedEditRow.id
                  ? {
                      id: selectedEditRow.status,
                      label: selectedEditRow.status,
                    }
                  : null
              }
            />
          </div>

          <div className="formGroup">
            <h4 style={{ marginBottom: 0 }}>Files:</h4>
            <TxtBox
              placeholder={"Files"}
              style={{ height: 50 }}
              type="file"
              className="uploadFile"
              accept=".png, .jpg, .jpeg, .pdf"
              onChange={onChangeFile}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: 10,
              }}
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                {uploadedFiles.map((f, i) => {
                  return (
                    <div className="fileBox">
                      <img
                        src={
                          f.mimeType.indexOf("image/") > -1
                            ? API_CONFIG.API_URL + f.path
                            : imagePdf
                        }
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <div className="formGroup">
            <Btn text={LABELS.SAVE} onClick={saveData} />
          </div>
        </UiDialog>
      )}

      {/* RfiResponses */}
      {selectedRow.subject && (
        <RfiResponses
          selectedRow={selectedRow}
          responses={responses}
          selectedRowFiles={selectedRowFiles}
          uploadedFiles={uploadedFiles}
          setUploadedFiles={setUploadedFiles}
          userInfo={props.userInfo}
          onChangeFile={onChangeFile}
          setSelectedRow={setSelectedRow}
          saveResponse={saveResponse}
          getRfiResponses={getRfiResponses}
        />
      )}
    </React.Fragment>
  );
};

export default withSnackbar(Rfi);
