import React, { useEffect, useState } from "react";
import { withSnackbar } from "notistack";
import {
  Visibility as IconEyeOn,
  VisibilityOff as IconEyeOff,
} from "@mui/icons-material";
import { IconButton } from "@mui/material";

import Loader from "components/Custom/Loader";
import TxtBox from "components/Custom/TxtBox";
import Btn from "components/Custom/Btn";
import ChkBox from "components/Custom/ChkBox";

import { ApiLogin } from "apihandlers/login";
import {
  CONFIG,
  ERRORS,
  LABELS,
  LOCAL_STORAGE,
  NOTIFY,
  SUCCESS,
  WARNINGS,
} from "common/config";
import { COMMON_JS } from "common/scripts";

import "./styles/login.css";

const webLogo = require("assets/images/logo.png");

const API_HANDLER_LOGIN = new ApiLogin();

const LoginPage = (props: any) => {
  const [loading, setLoading] = useState(true);
  const [showPassword, setShowPassword] = useState(false);

  const defaultRows = [
    {
      id: 1,
      ownerId: 3,
      owner: "Matthew",
      priorityId: 2,
      priority: "CAT B",
      typeId: 2,
      type: "Mechanical",
      rootCauseId: 1,
      rootCause: "Project",
      statusId: 1,
      status: "Open",
      alternateId: "",
      contractorId: null,
      contractor: null,
      user1Id: 3,
      user1: "David",
      documentId: "",
      roomId: 2,
      room: "Corridor",
      associatedEquipmentId: 2,
      associatedEquipment: "Coating",
      initiationDate: "2024-04-01",
      dueDate: "2024-04-10",
      estCost: "0 CHF",
      attachments: [],
      description: "TAG Missing on the Equipment",
      resolution: "New Design needed to be approved for the TAG manufacturing",
      addAsReviewer: true,
      notifications: true,
    },
    {
      id: 2,
      ownerId: 5,
      owner: "Tom",
      priorityId: 1,
      priority: "CAT A",
      typeId: 1,
      type: "Civil",
      rootCauseId: 1,
      rootCause: "Project",
      statusId: 2,
      status: "In-Review",
      alternateId: "",
      contractorId: 17,
      contractor: "Metac General Contracting Company",
      user1Id: 3,
      user1: "David",
      documentId: "",
      roomId: 1,
      room: "Clean Room",
      associatedEquipmentId: 4,
      associatedEquipment: "Trolleys",
      initiationDate: "2024-04-03",
      dueDate: "2024-04-08",
      estCost: "0 CHF",
      attachments: [],
      description: "Internal Door Alignment",
      resolution: "Doors need to be re-installed",
      addAsReviewer: true,
      notifications: true,
    },
    {
      id: 3,
      ownerId: 2,
      owner: "Daniel",
      priorityId: 3,
      priority: "CAT C",
      typeId: 3,
      type: "Electrical",
      rootCauseId: 1,
      rootCause: "Project",
      statusId: 1,
      status: "Open",
      alternateId: "",
      contractorId: 10,
      contractor: "Conspel Emirates wll",
      user1Id: 1,
      user1: "Sam",
      documentId: "",
      roomId: 4,
      room: "CMA",
      associatedEquipmentId: 1,
      associatedEquipment: "Filtration",
      initiationDate: "2024-04-16",
      dueDate: "2024-04-24",
      estCost: "0 CHF",
      attachments: [],
      description: "Cable Termination fault",
      resolution:
        "2x10 core cable needs to be replaced and termination details required",
      addAsReviewer: true,
      notifications: true,
    },
    {
      id: 4,

      ownerId: 4,
      owner: "Michael",
      priorityId: 1,
      priority: "CAT A",
      typeId: 4,
      type: "Instrumentation",
      rootCauseId: 3,
      rootCause: "Maintenance",
      statusId: 1,
      status: "Open",
      alternateId: "",
      contractorId: 6,
      contractor: "Matrix Conatruction",
      user1Id: 2,
      user1: "Tom",
      documentId: "",
      roomId: 2,
      room: "Corridor",
      associatedEquipmentId: 3,
      associatedEquipment: "Washer",
      initiationDate: "2024-04-23",
      dueDate: "2024-04-30",
      estCost: "0 CHF",
      attachments: [],
      description: "Air filters missing",
      resolution: "P&ID needs to be updated and filters to be installed",
      addAsReviewer: true,
      notifications: true,
    },
  ];

  const cqvData = [
    {
      id: 1,
      iocExecutionFinishDt: "",
      iocExecutionId: "1",
      iocExecutionName: "Not Started",
      iocPostApprovalFinishDt: "",
      iocPostApprovalId: "1",
      iocPostApprovalName: "Not Started",
      iocPostDraftId: "1",
      iocPostDraftName: "Not Started",
      iocPostReviewFinishDt: "",
      iocPostReviewId: "1",
      iocPostReviewName: "Not Started",
      iocPostcommentsFinishDt: "",
      iocPostcommentsId: "1",
      iocPostcommentsName: "Not Started",
      iocPreApprovalFinishDt: "",
      iocPreCommentFinishDt: "",
      iocPreDraftFinishDt: "2024-04-25",
      iocPreDraftId: "3",
      iocPreDraftName: "Completed",
      iocPrePreApprovalId: "2",
      iocPrePreApprovalName: "In Progress",
      iocPreReviewFinishDt: "",
      iocPreReviewId: "2",
      iocPreReviewName: "In Progress",
      iocPrecommentsId: "2",
      iocPrecommentsName: "In Progress",
      iocpostDraftFinishDt: "",
      ioqExecutionFinishDt: "",
      ioqExecutionId: "1",
      ioqExecutionName: "Not Started",
      ioqPostApprovalFinishDt: "",
      ioqPostApprovalId: "1",
      ioqPostApprovalName: "Not Started",
      ioqPostDraftId: "1",
      ioqPostDraftName: "Not Started",
      ioqPostReviewFinishDt: "",
      ioqPostReviewId: "1",
      ioqPostReviewName: "Not Started",
      ioqPostcommentsFinishDt: "",
      ioqPostcommentsId: "1",
      ioqPostcommentsName: "Not Started",
      ioqPreApprovalFinishDt: "",
      ioqPreCommentsFinishDt: "",
      ioqPreDraftFinishDt: "",
      ioqPreDraftId: "2",
      ioqPreDraftName: "In Progress",
      ioqPrePreApprovalId: "1",
      ioqPrePreApprovalName: "Not Started",
      ioqPreReviewFinishDt: "",
      ioqPreReviewId: "1",
      ioqPreReviewName: "Not Started",
      ioqPrecommentsId: "1",
      ioqPrecommentsName: "Not Started",
      ioqpostDraftFinishDt: "",
      projectId: 27,
      projectName: "LEO PHARMA EXTENSION",
      suitTeamId: "Suite 3",
      suitTeamName: "Suite 3",
      systemName: "Ventilated Balance",
      systemNameId: "VentilatedBalance",
      systemNoName: "005",
      sysytemNoId: "005",
      walkDown1FinishDt: "",
      walkDown1Id: "1",
      walkDown1Name: "Not Started",
      walkDown2FinishDt: "",
      walkDown2Id: "1",
      walkDown2Name: "Not Started",
      walkDown3FinishDt: "",
      walkDown3Id: "1",
      walkDown3Name: "Not Started",
    },
    {
      id: 2,
      projectId: 34,
      projectName: "IC4 - MERCK",
      sysytemNoId: "1000",
      systemNoName: "1000",
      systemNameId: "TabletInspection",
      systemName: "Tablet Inspection Isolator",
      suitTeamId: "Suite 4",
      suitTeamName: "Suite 4",
      iocPreDraftId: "3",
      iocPreDraftName: "Completed",
      iocPreDraftFinishDt: "",
      iocPrecommentsId: "1",
      iocPrecommentsName: "Not Started",
      iocPreCommentFinishDt: "",
      iocPreReviewId: "1",
      iocPreReviewName: "Not Started",
      iocPreReviewFinishDt: "",
      iocPrePreApprovalId: "1",
      iocPrePreApprovalName: "Not Started",
      iocPreApprovalFinishDt: "",
      iocExecutionId: "1",
      iocExecutionName: "Not Started",
      iocExecutionFinishDt: "",
      iocPostDraftId: "1",
      iocPostDraftName: "Not Started",
      iocpostDraftFinishDt: "",
      iocPostReviewId: "1",
      iocPostReviewName: "Not Started",
      iocPostReviewFinishDt: "",
      iocPostcommentsId: "1",
      iocPostcommentsName: "Not Started",
      iocPostcommentsFinishDt: "",
      iocPostApprovalId: "1",
      iocPostApprovalName: "Not Started",
      iocPostApprovalFinishDt: "",
      ioqPreDraftId: "1",
      ioqPreDraftName: "Not Started",
      ioqPreDraftFinishDt: "",
      ioqPreReviewId: "1",
      ioqPreReviewName: "Not Started",
      ioqPreReviewFinishDt: "",
      ioqPrecommentsId: "1",
      ioqPrecommentsName: "Not Started",
      ioqPreCommentsFinishDt: "",
      ioqPrePreApprovalId: "1",
      ioqPrePreApprovalName: "Not Started",
      ioqPreApprovalFinishDt: "",
      ioqExecutionId: "1",
      ioqExecutionName: "Not Started",
      ioqExecutionFinishDt: "",
      ioqPostDraftId: "1",
      ioqPostDraftName: "Not Started",
      ioqpostDraftFinishDt: "",
      ioqPostReviewId: "1",
      ioqPostReviewName: "Not Started",
      ioqPostReviewFinishDt: "",
      ioqPostcommentsId: "1",
      ioqPostcommentsName: "Not Started",
      ioqPostcommentsFinishDt: "",
      ioqPostApprovalId: "1",
      ioqPostApprovalName: "Not Started",
      ioqPostApprovalFinishDt: "",
      walkDown1Id: "1",
      walkDown1Name: "Not Started",
      walkDown1FinishDt: "",
      walkDown2Id: "1",
      walkDown2Name: "Not Started",
      walkDown2FinishDt: "",
      walkDown3Id: "1",
      walkDown3Name: "Not Started",
      walkDown3FinishDt: "",
      slNo: 5,
    },
    {
      id: 3,
      projectId: 33,
      projectName: "ABBVIE PHARMA",
      sysytemNoId: "005",
      systemNoName: "005",
      systemNameId: "VentilatedBalance",
      systemName: "Ventilated Balance",
      suitTeamId: "Suite 2",
      suitTeamName: "Suite 2",
      iocPreDraftId: "3",
      iocPreDraftName: "Completed",
      iocPreDraftFinishDt: "",
      iocPrecommentsId: "1",
      iocPrecommentsName: "Not Started",
      iocPreCommentFinishDt: "",
      iocPreReviewId: "1",
      iocPreReviewName: "Not Started",
      iocPreReviewFinishDt: "",
      iocPrePreApprovalId: "1",
      iocPrePreApprovalName: "Not Started",
      iocPreApprovalFinishDt: "",
      iocExecutionId: "1",
      iocExecutionName: "Not Started",
      iocExecutionFinishDt: "",
      iocPostDraftId: "1",
      iocPostDraftName: "Not Started",
      iocpostDraftFinishDt: "",
      iocPostReviewId: "1",
      iocPostReviewName: "Not Started",
      iocPostReviewFinishDt: "",
      iocPostcommentsId: "1",
      iocPostcommentsName: "Not Started",
      iocPostcommentsFinishDt: "",
      iocPostApprovalId: "1",
      iocPostApprovalName: "Not Started",
      iocPostApprovalFinishDt: "",
      ioqPreDraftId: "1",
      ioqPreDraftName: "Not Started",
      ioqPreDraftFinishDt: "",
      ioqPreReviewId: "1",
      ioqPreReviewName: "Not Started",
      ioqPreReviewFinishDt: "",
      ioqPrecommentsId: "1",
      ioqPrecommentsName: "Not Started",
      ioqPreCommentsFinishDt: "",
      ioqPrePreApprovalId: "1",
      ioqPrePreApprovalName: "Not Started",
      ioqPreApprovalFinishDt: "",
      ioqExecutionId: "1",
      ioqExecutionName: "Not Started",
      ioqExecutionFinishDt: "",
      ioqPostDraftId: "1",
      ioqPostDraftName: "Not Started",
      ioqpostDraftFinishDt: "",
      ioqPostReviewId: "1",
      ioqPostReviewName: "Not Started",
      ioqPostReviewFinishDt: "",
      ioqPostcommentsId: "1",
      ioqPostcommentsName: "Not Started",
      ioqPostcommentsFinishDt: "",
      ioqPostApprovalId: "1",
      ioqPostApprovalName: "Not Started",
      ioqPostApprovalFinishDt: "",
      walkDown1Id: "1",
      walkDown1Name: "Not Started",
      walkDown1FinishDt: "",
      walkDown2Id: "1",
      walkDown2Name: "Not Started",
      walkDown2FinishDt: "",
      walkDown3Id: "1",
      walkDown3Name: "Not Started",
      walkDown3FinishDt: "",
      slNo: 4,
    },
  ];

  useEffect(() => {
    if (loading) {
      checkLogin();
    }
  }, [loading]);

  const checkLogin = () => {
    const args = {};
    API_HANDLER_LOGIN.checkLogin(args).then((res) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        if (response.status) {
          window.open("/", "_self");
        }
      }
      setLoading(false);
    });
  };

  const submitLogin = () => {
    // Data
    const userName = (
      document.getElementById("uname") as HTMLInputElement
    ).value.trim();
    const password = (
      document.getElementById("pwd") as HTMLInputElement
    ).value.trim();
    const rememberMe = (
      document.querySelector("input#rememberMe") as HTMLInputElement
    ).checked;

    // Validations
    if (!userName) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.USERNAME_IS_EMPTY);
      return;
    }
    if (!password) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.PASSWORD_IS_EMPTY);
      return;
    }

    // API Call
    const args = {
      userName,
      password,
    };
    if (rememberMe) {
      localStorage.setItem(LOCAL_STORAGE.REMEMBER_ME, JSON.stringify(args));
    } else {
      localStorage.removeItem(LOCAL_STORAGE.REMEMBER_ME);
    }
    API_HANDLER_LOGIN.userLogin(args).then((res: any) => {
      if (res.status === 200) {
        localStorage.setItem(
          LOCAL_STORAGE.USER_INFO,
          JSON.stringify(res.response)
        );
        localStorage.setItem("pnchlstDts", JSON.stringify(defaultRows));
        localStorage.setItem("cqv", JSON.stringify(cqvData));

        localStorage.setItem(LOCAL_STORAGE.ACCESS_TOKEN, res.response.token);
        COMMON_JS.showNotify(props, NOTIFY.SUCCESS, SUCCESS.LOGIN_SUCCESS);
        window.open("/", "_self");
      } else {
        COMMON_JS.showNotify(props, NOTIFY.ERROR, ERRORS.LOGIN_FAILED);
      }
    });
  };

  let userInfo: any = localStorage.getItem(LOCAL_STORAGE.REMEMBER_ME);
  if (userInfo) {
    userInfo = JSON.parse(userInfo);
  } else {
    userInfo = {};
  }

  console.log("LoginPage: ", loading, userInfo);

  return (
    <div className="loginPage">
      {loading ? (
        <div className="flexCenter">
          <Loader />
        </div>
      ) : (
        <div className="loginBox">
          <img src={webLogo} className="responsive" />
          {/* <p style={{ margin: 0, textAlign: "center", fontSize: 10 }}>
            {CONFIG.VERSION}
          </p> */}
          <div
            className="formGroup"
            style={{ marginTop: 30, marginBottom: 25 }}
          >
            <label>{LABELS.USER_NAME}</label>
            <TxtBox
              placeholder={LABELS.USER_NAME}
              id={"uname"}
              defaultValue={userInfo.userName || ""}
            />
          </div>
          <div className="formGroup" style={{ marginBottom: 25 }}>
            <label>{LABELS.PASSWORD}</label>
            <TxtBox
              placeholder={LABELS.PASSWORD}
              type={showPassword ? "text" : "password"}
              id={"pwd"}
              defaultValue={userInfo.password || ""}
              onKeyPress={(e: any) => {
                if (e.keyCode === 13) {
                  submitLogin();
                }
              }}
            />
            <IconButton
              className="viewPassword"
              onClick={() => {
                setShowPassword(!showPassword);
              }}
            >
              {showPassword ? <IconEyeOn /> : <IconEyeOff />}
            </IconButton>
            <ChkBox
              text={LABELS.REMEMBER_ME}
              id={"rememberMe"}
              checked={userInfo.userName ? true : false}
            />
          </div>
          <div
            className="formGroup"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              marginBottom: 30,
            }}
          >
            <Btn text={LABELS.LOGIN} onClick={submitLogin} />
          </div>
        </div>
      )}
    </div>
  );
};

export default withSnackbar(LoginPage);
