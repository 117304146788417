import React, { useEffect, useState } from "react";
import { FormControlLabel, RadioGroup, Radio } from "@mui/material";

import { COLORS } from "common/config";

const RadioBtn = (props: any) => {
  const { list, name, text, disabled, defaultValue, row, onChange } = props;

  const [localList, setList] = useState(list);

  const onChangeValue = (e, val) => {
    const tmpList = localList.map((l: any) => {
      l.checked = false;
      if (l.id == val) {
        l.checked = true;
      }
      return l;
    });
    setList(tmpList);
    if (onChange) {
      onChange(val);
    }
  };

  return (
    <RadioGroup
      className="uiRadioGroup"
      // defaultValue={defaultValue}
      name={name}
      row={row || false}
      defaultValue={defaultValue}
      onChange={onChangeValue}
    >
      {localList.map((l: any) => {
        return (
          <FormControlLabel
            className="uiRadio"
            key={"radiolist-" + l.id}
            value={l.id}
            name={name}
            control={<Radio style={{ color: COLORS.PRIMARY }} />}
            label={l.label}
            // checked={l.checked || false}
            disabled={l.disabled || false}
          />
        );
      })}
    </RadioGroup>
  );
};

export default RadioBtn;
