import React, { useState } from "react";
import { MenuList, MenuItem } from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";

import { CONFIG, LABELS } from "common/config";
import Training from "./Training";

const trainingIcon = require("assets/images/reports/training.png");

const InductionTraining = (props: any = {}) => {
  const [dashMenu, setDashMenu] = useState(LABELS.HOME);
  const { childRef, userInfo } = props;
  const setMenu = () => {
    setDashMenu(LABELS.HOME);
  };
  childRef.setMenu = setMenu;

  const videoUrl = "https://pm.hiwinsystems.net/training.mp4";

  return (
    <React.Fragment>
      {dashMenu == LABELS.HOME && (
        <div className="userDashboard">
          <div className="pageInfo">
            <ul className="breadcrumbs">
              <li>{LABELS.HOME}</li>
              <li className="dim">{" / "}</li>
              <li onClick={() => props.setDashMenu(LABELS.HOME)}>
                {LABELS.EHS}
              </li>
              <li className="dim">{" / "}</li>
              <li className="active">{LABELS.INDUCTION_TRAINING}</li>
            </ul>
            <h4>{LABELS.INDUCTION_TRAINING}</h4>
          </div>
          <MenuList dense className="dashboardMenuWrap">
            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.TRAINING);
              }}
            >
              <img src={trainingIcon} />
              <h4>{LABELS.TRAINING}</h4>
            </MenuItem>

            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                window.open(videoUrl, "_new");
              }}
            >
              <img src={trainingIcon} />
              <h4>{LABELS.TRAINING_VIDEO}</h4>
            </MenuItem>
          </MenuList>
        </div>
      )}

      {dashMenu == LABELS.TRAINING && <Training setDashMenu={setDashMenu} />}
    </React.Fragment>
  );
};

export default InductionTraining;
