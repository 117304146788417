import React, { useRef, useState } from "react";
import { MenuList, MenuItem } from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";

import Risks from "components/Ehs/Risks";
import Audits from "components/Audits";
import SafetyMonitoring from "components/Ehs/SafetyMonitoring";
import SafetyObservationRecords from "components/Ehs/SafetyObservationRecords";
import InductionTraining from "components/Ehs/InductionTraining";
import WorkPermit from "components/Ehs/WorkPermit";
import EmployeeData from "components/Ehs/EmployeeData";
import WasteManagement from "components/Ehs/WasteManagement";
import OccupationalHealth from "components/Ehs/OccupationalHealth";
import Haccp from "components/Ehs/Haccp";
import IncidentManagement from "components/Ehs/IncidentManagement";
import Rams from "components/Ehs/Rams";
import Lototo from "components/Ehs/Lototo";
import Capa from "components/Ehs/CAPA";
import NearMissReport from "components/Ehs/NearMissReport";
import StandardOperatingProcedures from "components/Ehs/StandardOperatingProcedures";

import { CONFIG, LABELS } from "common/config";

import "devextreme/dist/css/dx.light.css";

const riskAssessmentIcon = require("assets/images/reports/risk.png");
const accidentReportingIcon = require("assets/images/reports/injuries.png");
const occupationHealthIcon = require("assets/images/reports/psychotherapy.png");
const auditsIcon = require("assets/images/reports/audit.png");
const incidentIcon = require("assets/images/reports/warning-sign.png");
const monitoringMeasurementIcon = require("assets/images/reports/graphic-design.png");
const wasteManagementIcon = require("assets/images/reports/waste.png");
const environmentRiskicon = require("assets/images/reports/environment_risk.png");
const objectivesPlansIcon = require("assets/images/reports/target.png");
const haccpIcon = require("assets/images/reports/haccp.png");
const employeedataIcon = require("assets/images/reports/employeedata.png");
const listIcon = require("assets/images/reports/list.png");
const questionnaireIcon = require("assets/images/reports/questionnaire.png");
const safetyIcon = require("assets/images/reports/safety.png");
const seoReportIcon = require("assets/images/reports/seo-report.png");
const trainingIcon = require("assets/images/reports/training.png");
const workpermitIcon = require("assets/images/reports/work_permit.png");
const regulationsIcon = require("assets/images/reports/regulations-book.png");
const lototoIcon = require("assets/images/reports/lototo.png");
const capaIcon = require("assets/images/reports/capa.png");

const Ehs = (props: any = {}) => {
  const [dashMenu, setDashMenu] = useState(LABELS.HOME);
  const { childRef, userInfo } = props;
  const setMenu = () => {
    setDashMenu(LABELS.HOME);
  };
  childRef.setMenu = setMenu;

  const trainingRef: any = useRef(null);

  return (
    <React.Fragment>
      {dashMenu == LABELS.HOME && (
        <div className="userDashboard">
          <div className="pageInfo">
            <ul className="breadcrumbs">
              <li>{LABELS.HOME}</li>
              <li className="dim">{" / "}</li>
              <li className="active">{LABELS.EHS}</li>
            </ul>
            <h4>{LABELS.EHS}</h4>
          </div>
          <MenuList dense className="dashboardMenuWrap">
            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.INDUCTION_TRAINING);
              }}
            >
              <img src={trainingIcon} />
              <h4>{"Induction Training"}</h4>
            </MenuItem>

            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.EMPLOYEE_DATA);
              }}
            >
              <img src={employeedataIcon} />
              <h4>{"Employee Data"}</h4>
            </MenuItem>

            {/* <MenuItem
              className="dashboardMenu"
              // onClick={() => {
              //   setDashMenu(LABELS.MAN_HOURS_DISTRIBUTION);
              // }}
            >
              <img src={accidentReportingIcon} />
              <h4>{"Accident Reporting and Investigation"}</h4>
            </MenuItem> */}

            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.OCCUPATIONAL_HEALTH);
              }}
            >
              <img src={occupationHealthIcon} />
              <h4>{LABELS.OCCUPATIONAL_HEALTH}</h4>
            </MenuItem>

            {/* <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.INCIDENT_MANAGEMENT);
              }}
            >
              <img src={incidentIcon} />
              <h4>{LABELS.INCIDENT_MANAGEMENT}</h4>
            </MenuItem> */}

            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.SAFETY_MONITORING);
              }}
            >
              <img src={monitoringMeasurementIcon} />
              <h4>{"Safety Monitoring and Measurement"}</h4>
            </MenuItem>

            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.WASTE_MANAGEMENT);
              }}
            >
              <img src={wasteManagementIcon} />
              <h4>{LABELS.WASTE_MANAGEMENT}</h4>
            </MenuItem>
            {/* <MenuItem
              className="dashboardMenu"
              // onClick={() => {
              //   setDashMenu(LABELS.EARNED_VALUE_REPORT);
              // }}
            >
              <img src={environmentRiskicon} />
              <h4>{"Environmental Risk"}</h4>
            </MenuItem> */}
            {/* <MenuItem
              className="dashboardMenu"
              // onClick={() => {
              //   setDashMenu(LABELS.QTY_REPORT);
              // }}
            >
              <img src={objectivesPlansIcon} />
              <h4>{"Objectives and Plans"}</h4>
            </MenuItem> */}

            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.HACCP);
              }}
            >
              <img src={haccpIcon} />
              <h4>{LABELS.HACCP}</h4>
            </MenuItem>

            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.SOR);
              }}
            >
              <img src={safetyIcon} />
              <h4>{LABELS.SOR}</h4>
            </MenuItem>

            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.NEAR_MISS);
              }}
            >
              <img src={seoReportIcon} />
              <h4>{"Near Miss Reports"}</h4>
            </MenuItem>

            {/* <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.RAMS);
              }}
            >
              <img src={riskAssessmentIcon} />
              <h4>{LABELS.RAMS}</h4>
            </MenuItem> */}

            {/* <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.SOP);
              }}
            >
              <img src={regulationsIcon} />
              <h4>{"Standard Operating Procedures (SOP)"}</h4>
            </MenuItem> */}

            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.WORK_PERMIT);
              }}
            >
              <img src={workpermitIcon} />
              <h4>{"Work Permit Tool"}</h4>
            </MenuItem>

            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.LOTOTO);
              }}
            >
              <img src={lototoIcon} />
              <h4>{"LOTOTO"}</h4>
            </MenuItem>
            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.CAPA);
              }}
            >
              <img src={capaIcon} />
              <h4>{"CAPA"}</h4>
            </MenuItem>

            {/* <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.RESOURCE_TABLE);
              }}
            >
              <img src={chartResourceTable} />
              <h4>{LABELS.RESOURCE_TABLE}</h4>
            </MenuItem> */}

            {/* <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.RISKS_REGISTER);
              }}
            >
              <img src={chartRisksRegister} />
              <h4>{LABELS.RISKS_REGISTER}</h4>
            </MenuItem> */}
          </MenuList>
        </div>
      )}
      {dashMenu == LABELS.INDUCTION_TRAINING && (
        <InductionTraining setDashMenu={setDashMenu} childRef={trainingRef} />
      )}

      {dashMenu == LABELS.RISKS && <Risks setDashMenu={setDashMenu} />}

      {dashMenu == LABELS.AUDITS && <Audits setDashMenu={setDashMenu} />}

      {dashMenu == LABELS.SAFETY_MONITORING && (
        <SafetyMonitoring setDashMenu={setDashMenu} />
      )}

      {dashMenu == LABELS.SOR && (
        <SafetyObservationRecords setDashMenu={setDashMenu} />
      )}

      {dashMenu == LABELS.WORK_PERMIT && (
        <WorkPermit setDashMenu={setDashMenu} />
      )}

      {dashMenu == LABELS.EMPLOYEE_DATA && (
        <EmployeeData setDashMenu={setDashMenu} />
      )}

      {dashMenu == LABELS.INCIDENT_MANAGEMENT && (
        <IncidentManagement setDashMenu={setDashMenu} />
      )}

      {dashMenu == LABELS.NEAR_MISS && (
        <NearMissReport setDashMenu={setDashMenu} />
      )}

      {dashMenu == LABELS.SOP && (
        <StandardOperatingProcedures setDashMenu={setDashMenu} />
      )}

      {dashMenu == LABELS.WASTE_MANAGEMENT && (
        <WasteManagement setDashMenu={setDashMenu} />
      )}

      {dashMenu == LABELS.OCCUPATIONAL_HEALTH && (
        <OccupationalHealth setDashMenu={setDashMenu} />
      )}

      {dashMenu == LABELS.HACCP && <Haccp setDashMenu={setDashMenu} />}

      {dashMenu == LABELS.RAMS && <Rams setDashMenu={setDashMenu} />}

      {dashMenu == LABELS.LOTOTO && <Lototo setDashMenu={setDashMenu} />}
      {dashMenu == LABELS.CAPA && <Capa setDashMenu={setDashMenu} />}
    </React.Fragment>
  );
};

export default Ehs;
