import React, { useState, useEffect, useRef } from "react";
import { withSnackbar } from "notistack";
import { Close as IconClose } from "@mui/icons-material";
import { IconButton } from "@mui/material";

import SkeletonBox from "components/Custom/SkeletonBox";
import ModalBtmBtns from "components/Custom/ModalBtmBtns";
import ExcelGrid from "components/Custom/ExcelGrid";
import Loader from "components/Custom/Loader";
import RichTxtBox from "components/Custom/RichTxtBox";
import TxtBox from "components/Custom/TxtBox";

import { COMMON_JS } from "common/scripts";
import {
  API_CONFIG,
  COLORS,
  LABELS,
  NOTIFY,
  SUCCESS,
  WARNINGS,
} from "common/config";
import { ApiHome } from "apihandlers/home";
import { ApiEhs } from "apihandlers/ehs";
import AutoComplete from "components/Custom/AutoComplete";
import Btn from "components/Custom/Btn";
import ChkBox from "components/Custom/ChkBox";

const API_HANDLER_HOME = new ApiHome();
const API_HANDLER_EHS = new ApiEhs();

const dataJson = require("../gridData.json");
const pdfImg = require("assets/images/pdf.png");

const PunchlistDetailsForm = (props) => {
  const { setManageDialog, docNum, rows, onReload, selectedRow } = props;

  const [uploading, setUploading] = useState<boolean>(false);
  const [uploads, setUploads] = useState<any[]>(
    selectedRow.id
      ? selectedRow.attachments.length > 0
        ? selectedRow.attachments
        : []
      : []
  );
  const [contractor, setContractor] = useState<any>([]);
  const [addAsReviewer, setAddAsReviewer] = useState<any>(
    selectedRow.addAsReviewer ? selectedRow.addAsReviewer : false
  );
  const [notifications, setNotifications] = useState<any>(
    selectedRow.notifications ? selectedRow.notifications : false
  );

  const owner = [
    {
      id: 1,
      label: "Sam",
    },
    {
      id: 2,
      label: "Daniel",
    },
    {
      id: 3,
      label: "Matthew",
    },
    {
      id: 4,
      label: "Michael",
    },
    {
      id: 5,
      label: "Tom",
    },
  ];

  const priority = [
    { id: 1, label: "CAT A" },
    { id: 2, label: "CAT B" },
    { id: 3, label: "CAT C" },
  ];

  const type = [
    { id: 1, label: "Civil" },
    { id: 2, label: "Mechanical" },
    { id: 3, label: "Electrical" },
    { id: 4, label: "Instrumentation" },
    { id: 5, label: "General" },
  ];

  const status = [
    { id: 1, label: "Open" },
    { id: 2, label: "In-Review" },
    { id: 3, label: "Closed" },
  ];

  const rootCause = [
    { id: 1, label: "Project" },
    { id: 2, label: "Production" },
    { id: 3, label: "Maintenance" },
  ];

  const users = [
    { id: 1, label: "Sam" },
    { id: 2, label: "Tom" },
    { id: 3, label: "David" },
    { id: 4, label: "Srikanth" },
  ];

  const room = [
    { id: 1, label: "Clean Room" },
    { id: 2, label: "Corridor" },
    { id: 3, label: "API" },
    { id: 4, label: "CMA" },
  ];

  const associatedEquipment = [
    { id: 1, label: "Filtration" },
    { id: 2, label: "Coating" },
    { id: 3, label: "Washer" },
    { id: 4, label: "Trolleys" },
  ];

  const ownerRef: any = useRef(null);
  const priorityRef: any = useRef(null);
  const typeRef: any = useRef(null);
  const rootCauseRef: any = useRef(null);
  const statusRef: any = useRef(null);
  const responsibleToExecuteRef: any = useRef(null);
  const responsibelToClose1Ref: any = useRef(null);
  const responsibelToClose2Ref: any = useRef(null);
  const roomRef: any = useRef(null);
  const associatedEquipmentRef: any = useRef(null);
  const systemsRef: any = useRef(null);

  useEffect(() => {
    getContractor();
  }, []);

  const getContractor = () => {
    const args = {};
    API_HANDLER_EHS.getContractor(args).then((res: any) => {
      const { status, response } = res;
      if (status === 200) {
        let list = response.records.map((item: any) => {
          return {
            id: item.id,
            label: item.nameOfContractor,
          };
        });
        setContractor(list);
      }
    });
  };

  const saveRecord = () => {
    const ownerId = ownerRef.current.selectedValue.id;
    const owner = ownerRef.current.selectedValue.label;
    const priorityId = priorityRef.current.selectedValue.id;
    const priority = priorityRef.current.selectedValue.label;
    const typeId = typeRef.current.selectedValue.id;
    const type = typeRef.current.selectedValue.label;
    const rootCauseId = rootCauseRef.current.selectedValue.id;
    const rootCause = rootCauseRef.current.selectedValue.label;
    const statusId = statusRef.current.selectedValue.id;
    const status = statusRef.current.selectedValue.label;
    const alternateId = (
      document.getElementById("alternateId") as HTMLInputElement
    ).value.trim();
    const contractorId = responsibleToExecuteRef.current.selectedValue.id;
    const contractor = responsibleToExecuteRef.current.selectedValue.label;
    const user1Id = responsibelToClose1Ref.current.selectedValue.id;
    const user1 = responsibelToClose1Ref.current.selectedValue.label;
    // const user2Id = responsibelToClose2Ref.current.selectedValue.id;
    // const user2 = responsibelToClose2Ref.current.selectedValue.label;
    const documentId = (
      document.getElementById("documentId") as HTMLInputElement
    ).value.trim();
    const roomId = roomRef.current.selectedValue.id;
    const room = roomRef.current.selectedValue.label;
    const associatedEquipmentId =
      associatedEquipmentRef.current.selectedValue.id;
    const associatedEquipment =
      associatedEquipmentRef.current.selectedValue.label;

    const initiationDate = (
      document.getElementById("initiationDate") as HTMLInputElement
    ).value.trim();

    const dueDate = (
      document.getElementById("dueDate") as HTMLInputElement
    ).value.trim();
    const estCost = (
      document.getElementById("estCost") as HTMLInputElement
    ).value.trim();

    const attachments = uploads;
    const description = (
      document.getElementById("description") as HTMLInputElement
    ).value.trim();

    const resolution = (
      document.getElementById("resolution") as HTMLInputElement
    ).value.trim();

    const args = {
      id: selectedRow.id ? selectedRow.id : rows.length + 1,
      ownerId,
      owner,
      priorityId,
      priority,
      type,
      typeId,
      rootCauseId,
      rootCause,
      statusId,
      status,
      alternateId,
      contractorId: contractorId
        ? contractorId === -1
          ? null
          : contractorId
        : null,
      contractor: contractorId
        ? contractorId === -1
          ? null
          : contractor
        : null,
      user1Id,
      user1,
      // user2Id,
      // user2,
      documentId,
      roomId,
      room,
      associatedEquipmentId,
      associatedEquipment,
      initiationDate,
      dueDate,
      estCost: estCost ? estCost + " " + "CHF" : 0 + " " + "CHF",
      attachments,
      description,
      resolution,
      addAsReviewer: addAsReviewer,
      notifications: notifications,
    };

    let tmpRows = [];
    if (selectedRow.id) {
      rows[selectedRow.id - 1] = args;
      tmpRows = [...rows];
    } else {
      tmpRows = [...rows, args];
    }

    console.log("args,", tmpRows);
    localStorage.setItem("pnchlstDts", JSON.stringify(tmpRows));
    COMMON_JS.showNotify(props, NOTIFY.SUCCESS, SUCCESS.RECORD_SAVED);
    setManageDialog(false);
    onReload();
  };

  const onUploadFile = (e: any, f: any) => {
    console.log("onUploadFile: ", e, f);
    const file: any = e.target.files[0];
    if (file.size / 1024 / 1024 > 5) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.FILE_SIZE_EXCEEDED);
      return;
    }
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let base64: any = reader.result;
      setUploading(true);
      uploadFile(file, base64).then((res) => {
        console.log("res: ", res);
        setUploading(false);
      });
    };
    reader.onerror = (error) => {
      console.log("uploadFile: Error:", error);
    };
  };

  const uploadFile = async (file: any, base64 = "") => {
    const args = {
      recordRefId: 1,
      file: {
        name: file.name,
        data: base64.replace("data:", "").replace(/^.+,/, ""),
        type: file.name.split(".")[1],
        size: file.size,
      },
      category: "ehs",
    };
    let fileInfo = null;
    try {
      const res = await API_HANDLER_HOME.fileUpload(args);
      console.log("uploadFile: ", args, res);
      const response: any = res.response;
      if (res.status === 200) {
        fileInfo = response;
        setUploads([...uploads, fileInfo]);
        (document.getElementById("uploadFile") as HTMLInputElement).value =
          null;
      }
    } catch (error) {}
    return fileInfo;
  };

  console.log("addAsReviewer", addAsReviewer);

  return (
    <>
      <h4 className="ehsHeading1 borderBottom">{"Details"}</h4>
      <div
        style={{
          marginTop: 10,
          marginBottom: 10,
        }}
      >
        <div className="formRow">
          <div className="formGroup width30">
            <label>{LABELS.OWNER}</label>
            <AutoComplete
              id={"owner"}
              list={owner}
              childRef={ownerRef}
              defaultValue={
                selectedRow.ownerId
                  ? {
                      id: selectedRow.ownerId,
                      label: selectedRow.owner,
                    }
                  : null
              }
            />
          </div>
          <div className="formGroup width30">
            <label>{LABELS.PRIORITY}</label>
            <AutoComplete
              id={"priority"}
              list={priority}
              childRef={priorityRef}
              defaultValue={
                selectedRow.priorityId
                  ? {
                      id: selectedRow.priorityId,
                      label: selectedRow.priority,
                    }
                  : null
              }
            />
          </div>
          <div className="formGroup width30">
            <label>{LABELS.TYPE}</label>
            <AutoComplete
              id={"type"}
              list={type}
              childRef={typeRef}
              defaultValue={
                selectedRow.typeId
                  ? {
                      id: selectedRow.typeId,
                      label: selectedRow.type,
                    }
                  : null
              }
            />
          </div>
        </div>
        <div className="formRow">
          <div className="formGroup width30">
            <label>{LABELS.ROOT_CAUSE}</label>
            <AutoComplete
              id={"rootCause"}
              list={rootCause}
              childRef={rootCauseRef}
              defaultValue={
                selectedRow.rootCauseId
                  ? {
                      id: selectedRow.rootCauseId,
                      label: selectedRow.rootCause,
                    }
                  : null
              }
            />
          </div>
          <div className="formGroup width30">
            <label>{LABELS.STATUS}</label>
            <AutoComplete
              id={"status"}
              list={status}
              childRef={statusRef}
              defaultValue={
                selectedRow.statusId
                  ? {
                      id: selectedRow.statusId,
                      label: selectedRow.status,
                    }
                  : null
              }
            />
          </div>
          <div className="formGroup width30">
            <label>{LABELS.ALTERNATE_ID}</label>
            <TxtBox
              id={"alternateId"}
              placeholder={LABELS.ALTERNATE_ID}
              defaultValue={selectedRow.alternateId || ""}
            />
          </div>
        </div>
        <div className="formRow">
          <div className="formGroup width30">
            <label>{LABELS.RESPONSIBLE_TO_EXECUTE}</label>
            <AutoComplete
              id={"contractor"}
              list={contractor}
              defaultValue={
                selectedRow.contractorId
                  ? {
                      id: selectedRow.contractorId,
                      label: selectedRow.contractor,
                    }
                  : {
                      id: -1,
                      label: "Select Vendor",
                    }
              }
              childRef={responsibleToExecuteRef}
            />
          </div>
          <div className="formGroup width30">
            <label>{LABELS.RESPONSIBLE_TO_CLOSE}</label>
            <AutoComplete
              id={"users1"}
              list={users}
              defaultValue={
                selectedRow.user1Id
                  ? {
                      id: selectedRow.user1Id,
                      label: selectedRow.user1,
                    }
                  : null
              }
              childRef={responsibelToClose1Ref}
            />
          </div>
          <div className="formGroup width30" style={{ marginTop: 10 }}>
            <ChkBox
              text={LABELS.ADD_AS_REVIEWER}
              id={"addAsReviewer"}
              checked={addAsReviewer}
              onChange={() => {
                setAddAsReviewer(!addAsReviewer);
              }}
            />
          </div>
          {/* <div className="formGroup width30">
            <label>{LABELS.RESPONSIBLE_TO_CLOSE}</label>
            <AutoComplete
              id={"users2"}
              list={users}
              defaultValue={""}
              childRef={responsibelToClose2Ref}
            />
          </div> */}
        </div>
        <div className="formRow">
          <div className="formGroup width30">
            <ChkBox
              text={LABELS.NOTIICATIONS}
              id={"notifications"}
              checked={notifications}
              onChange={() => {
                setNotifications(!notifications);
              }}
            />
          </div>
          <div className="formGroup width30">
            <label>{LABELS.DOCUMENT_ID}</label>
            <TxtBox
              id={"documentId"}
              placeholder={LABELS.DOCUMENT_ID}
              defaultValue={selectedRow.documentId || ""}
            />
          </div>
          <div className="formGroup width30">
            <label>{LABELS.ROOM}</label>
            <AutoComplete
              id={"room"}
              list={room}
              defaultValue={
                selectedRow.roomId
                  ? {
                      id: selectedRow.roomId,
                      label: selectedRow.room,
                    }
                  : null
              }
              childRef={roomRef}
            />
          </div>
        </div>
        <div className="formRow">
          <div className="formGroup width30">
            <label>{LABELS.ASSOCIATED_EQUIPMENT}</label>
            <AutoComplete
              id={"associatedEquipment"}
              list={associatedEquipment}
              defaultValue={
                selectedRow.associatedEquipmentId
                  ? {
                      id: selectedRow.associatedEquipmentId,
                      label: selectedRow.associatedEquipment,
                    }
                  : null
              }
              childRef={associatedEquipmentRef}
            />
          </div>
          <div className="formGroup width30"></div>
        </div>
      </div>
      <h4 className="ehsHeading1 borderBottom">{LABELS.MILESTONE_DATES}</h4>
      <div
        style={{
          marginTop: 10,
          marginBottom: 10,
        }}
      >
        <div className="formRow">
          <div className="formGroup width30">
            <label>{LABELS.INITIATION_DATE}</label>
            <TxtBox
              type={"date"}
              id="initiationDate"
              defaultValue={selectedRow.initiationDate || ""}
            />
          </div>
          <div className="formGroup width30">
            <label>{LABELS.DUE_DATE}</label>
            <TxtBox
              type={"date"}
              id="dueDate"
              defaultValue={selectedRow.dueDate || ""}
            />
          </div>
          <div className="formGroup width30"></div>
        </div>
      </div>
      <h4 className="ehsHeading1 borderBottom">{LABELS.COST_SUMMARY}</h4>
      <div
        style={{
          marginTop: 10,
          marginBottom: 10,
        }}
      >
        <div className="formRow">
          <div className="formGroup width30">
            <label>{LABELS.EST_COST}</label>
            <TxtBox
              id={"estCost"}
              placeholder={LABELS.EST_COST}
              type={"number"}
              defaultValue={
                selectedRow.estCost
                  ? Number(selectedRow.estCost.split(" ")[0]).toString()
                  : ""
              }
            />
          </div>
        </div>
      </div>
      <h4 className="ehsHeading1 borderBottom">{LABELS.ATTACHMENTS}</h4>

      <div
        style={{
          marginTop: 10,
          marginBottom: 10,
        }}
      >
        <div className="formRow">
          <div className="formGroup" style={{ width: "30%" }}>
            <label>
              {LABELS.FILE_UPLOAD}
              {/* <small>{" ( Max File Size: 5 MB )"}</small> */}
            </label>
            <TxtBox
              type={"file"}
              id="uploadFile"
              className="uploadFile"
              accept="image/*,.pdf"
              onChange={onUploadFile}
            />

            <div
              className="formGroup"
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              {uploads.map((u, i) => {
                return (
                  <div className="thumbnailBox width30">
                    <IconButton
                      className="thumbnailBoxIcon"
                      onClick={() => {
                        const list = uploads.filter((a, j) => j !== i);
                        setUploads(list);
                      }}
                    >
                      <IconClose />
                    </IconButton>
                    <img
                      onClick={() =>
                        window.open(API_CONFIG.API_URL + u.path, "_blank")
                      }
                      src={
                        u.mimeType === "pdf"
                          ? pdfImg
                          : API_CONFIG.API_URL + u.path
                      }
                      className="responsive cursor"
                    />
                  </div>
                );
              })}
              {uploading && (
                <div className="thumbnailBox width30">
                  <SkeletonBox height={100} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          marginTop: 10,
          marginBottom: 10,
        }}
      >
        <div className="formRow">
          <div className="formGroup width70">
            <label>{LABELS.DESCRIPTION}</label>
            <TxtBox
              id={"description"}
              placeholder={LABELS.DESCRIPTION}
              type={"text"}
              rows={6}
              defaultValue={selectedRow.description || ""}
            />
          </div>
        </div>
      </div>
      <div
        style={{
          marginTop: 10,
          marginBottom: 10,
        }}
      >
        <div className="formRow">
          <div className="formGroup width70">
            <label>{LABELS.RESOLUTION}</label>
            <TxtBox
              id={"resolution"}
              placeholder={LABELS.RESOLUTION}
              type={"text"}
              rows={6}
              defaultValue={selectedRow.resolution || ""}
            />
          </div>
        </div>
      </div>

      {/* <div
        className="formGroup"
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: 25,
        }}
      >
        <div
          style={{
            width: "45%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Btn text={LABELS.SAVE} onClick={saveRecord} />
          <Btn
            text={LABELS.CANCEL}
            bgColor={COLORS.DIM_DARK_GRAY}
            onClick={() => setManageDialog(false)}
          />
        </div>
      </div> */}
      <ModalBtmBtns
        onSave={saveRecord}
        onCancel={() => setManageDialog(false)}
      />
    </>
  );
};

export default withSnackbar(PunchlistDetailsForm);
