import React, { useEffect, useRef, useState } from "react";
import { withSnackbar } from "notistack";
import { IconButton, Tooltip } from "@mui/material";
import {
  AddBox as IconAdd,
  Folder as IconFolder,
  ArrowBack as IconBack,
  Close as IconDelete,
} from "@mui/icons-material";

import Btn from "components/Custom/Btn";
import AutoComplete from "components/Custom/AutoComplete";
import Loader from "components/Custom/Loader";
import TxtBox from "components/Custom/TxtBox";
import UiDialog from "components/Custom/UiDialog";

import {
  API_CONFIG,
  CONFIG,
  DEFAULTS,
  LABELS,
  NOTIFY,
  SUCCESS,
  WARNINGS,
} from "common/config";
import { ApiHome } from "apihandlers/home";
import { ApiDashboard } from "apihandlers/dashboard";
import { COMMON_JS } from "common/scripts";

const API_HANDLER_HOME = new ApiHome();
const API_HANDLER_DASHBOARD = new ApiDashboard();

const vdoImg = require("assets/images/vdo.png");

const office365Img = require("assets/images/office_365.png");

const Lototo = (props: any) => {
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [folders, setFolders] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [data, setData] = useState<any>(null);
  const [apiArgs, setApiArgs] = useState<any>({});
  const [selectedFolder, setSelectedFolder] = useState<any>({});
  const jRef = useRef(null);
  const options = {
    data: [[]],
    minDimensions: [10, 10],
  };

  useEffect(() => {
    if (loading) {
      getData();
    }
  }, [loading]);

  const getData = () => {
    const args = {};
    setLoading(true);
    API_HANDLER_HOME.getOrgFolders(args).then((res: any) => {
      if (res.status === 200) {
        setFolders(res.response.records);
        setSelectedFolder({});
        setData(null);
        setLoading(false);
      }
    });
  };

  const saveData = () => {
    const folderName = (
      document.getElementById("folderName") as HTMLInputElement
    ).value.trim();

    if (!folderName) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        "Please enter folder name..!"
      );
      return;
    }

    const args = {
      id: selectedRow.id || -1,
      folderName,
    };
    API_HANDLER_HOME.manageOrgFolder(args).then((res) => {
      console.log("manageOrgFolder: ", args, res);
      if (res.status === 200) {
        COMMON_JS.showNotify(
          props,
          NOTIFY.SUCCESS,
          "Record saved successfully..!"
        );
        setShowDialog(false);
        getData();
      } else {
        COMMON_JS.showNotify(
          props,
          NOTIFY.ERROR,
          "Record not saved successfully..!"
        );
      }
    });
  };

  const onSubmitFile = () => {
    const dataFile = (
      document.getElementById("dataFile") as HTMLInputElement
    ).value.trim();
    const fileName = (
      document.getElementById("fileName") as HTMLInputElement
    ).value.trim();
    if (!dataFile) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        "Please enter office 365 file path..!"
      );
      return;
    } else if (
      dataFile.indexOf("https://hiwinsystemsnet.sharepoint.com") === -1
    ) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.ERROR,
        "Invalid Office 365 file path..!"
      );
      return;
    }

    const args = {
      id: selectedRow.id || -1,
      folderId: selectedFolder.id,
      filePath: dataFile,
      fileName,
    };
    API_HANDLER_HOME.manageOrgFile(args).then((res) => {
      if (res.status === 200) {
        COMMON_JS.showNotify(
          props,
          NOTIFY.SUCCESS,
          "Record saved successfully..!"
        );
        setShowDialog(false);
        getOrgFiles(args.folderId);
        (document.getElementById("dataFile") as HTMLInputElement).value = "";
      } else {
        COMMON_JS.showNotify(
          props,
          NOTIFY.ERROR,
          "Record not saved successfully..!"
        );
      }
    });
  };

  const openFolder = (folder: any = {}) => {
    setSelectedFolder(folder);
    getOrgFiles(folder.id);
  };

  const getOrgFiles = (folderId) => {
    const args = {
      folderId,
    };
    setRefresh(true);
    API_HANDLER_HOME.getOrgFiles(args).then((res: any) => {
      if (res.status === 200) {
        setData(res.response);
        setRefresh(false);
      }
    });
  };

  const onAddNewFolder = () => {
    setShowDialog(true);
    setSelectedRow({});
  };

  const onBackPress = () => {
    setData(null);
    setSelectedFolder({});
  };

  const removeFile = (id: any) => {
    const args = {
      id,
      remove: true,
    };
    API_HANDLER_HOME.manageOrgFile(args).then((res) => {
      if (res.status === 200) {
        COMMON_JS.showNotify(
          props,
          NOTIFY.SUCCESS,
          "File deleted successfully..!"
        );
        openFolder(selectedFolder);
      } else {
        COMMON_JS.showNotify(
          props,
          NOTIFY.ERROR,
          "File not deleted successfully..!"
        );
      }
    });
  };

  const projectRef: any = useRef();
  const htmlEditor = useRef(null);

  console.log("selectedRow: ", selectedRow, selectedFolder);

  return (
    <React.Fragment>
      <div className="pageInfo">
        <ul className="breadcrumbs">
          <li>{LABELS.HOME}</li>
          <li className="dim">{" / "}</li>
          <li onClick={() => props.setDashMenu(LABELS.HOME)}>{LABELS.EHS}</li>
          <li className="dim">{" / "}</li>
          <li className="active">{LABELS.LOTOTO}</li>
        </ul>
        <h4>{LABELS.LOTOTO}</h4>
      </div>

      <div id="fileUploadForm" style={{ marginTop: 15 }}>
        <div
          className="formGroup"
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            marginTop: 0,
            marginBottom: 15,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              marginRight: 15,
            }}
          >
            <Btn
              text={LABELS.RELOAD}
              loading={loading}
              marginRight={15}
              onClick={getData}
            />
            <Btn
              text={LABELS.ADD_NEW + " Folder"}
              onClick={onAddNewFolder}
              loading={loading}
            />
          </div>
        </div>
      </div>

      {folders && (
        <div className="drive">
          {selectedFolder.id && (
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                marginBottom: 15,
                background: "#ebebeb",
                cursor: "pointer",
              }}
              onClick={onBackPress}
            >
              <IconButton>
                <Tooltip title={LABELS.BACK} placement="bottom">
                  <IconBack />
                </Tooltip>
              </IconButton>
              <h4 style={{ marginTop: 0, marginBottom: 0 }}>{LABELS.BACK}</h4>
              <h4
                style={{
                  marginTop: 0,
                  marginBottom: 0,
                  textAlign: "center",
                  width: "100%",
                  textTransform: "uppercase",
                }}
              >
                {selectedFolder.folderName}
              </h4>
            </div>
          )}

          {!data && (
            <div className="folders">
              {folders.map((f: any, i) => {
                return (
                  <div
                    key={"folder-" + i}
                    className="folder"
                    onClick={() => {
                      openFolder(f);
                    }}
                  >
                    <IconFolder />
                    <h4>{f.folderName}</h4>
                  </div>
                );
              })}
              {folders.length === 0 && (
                <h4
                  style={{
                    color: "#aaa",
                    fontWeight: "normal",
                    textAlign: "center",
                    width: "100%",
                    fontSize: 20,
                  }}
                >
                  {"Please create a folder to continue"}
                </h4>
              )}
            </div>
          )}

          {selectedFolder.id && (
            <div style={{ width: "100%", paddingLeft: 20, marginBottom: 20 }}>
              {data && data.links.length > 0 && (
                <React.Fragment>
                  <h4 style={{ margin: 0 }}>{"Documents :"}</h4>
                  <div className="projectFiles">
                    {data.links.map((d: any) => {
                      return (
                        <div
                          key={"file-" + d.id}
                          style={{
                            position: "relative",
                            height: 160,
                            width: "10%",
                          }}
                        >
                          <>
                            <img src={office365Img} width={"40%"} />
                            <h4
                              style={{
                                marginTop: 5,
                                marginBottom: 5,
                                marginRight: 0,
                                marginLeft: 0,
                              }}
                            >
                              {d.fileName}
                            </h4>
                            <IconButton
                              style={{
                                position: "absolute",
                                right: -2,
                                top: -4,
                              }}
                              onClick={() => removeFile(d.id)}
                            >
                              <Tooltip title={LABELS.DELETE} placement="bottom">
                                <IconDelete />
                              </Tooltip>
                            </IconButton>
                            <div
                              className="viewBtn"
                              style={{ bottom: 10 }}
                              onClick={() => window.open(d.filePath, "_blank")}
                            >
                              View
                            </div>
                          </>
                        </div>
                      );
                    })}
                  </div>
                </React.Fragment>
              )}

              {data && data.links.length === 0 && (
                <div className="projectFiles">
                  <h4>No Files Found..!</h4>
                </div>
              )}

              {loading ? (
                <div className="flexCenter">
                  <Loader />
                </div>
              ) : (
                <div
                  style={{
                    width: "95%",
                    marginTop: 10,
                  }}
                >
                  <label
                    style={{
                      fontWeight: "500",
                    }}
                  >
                    {"Upload File"}
                  </label>
                  <TxtBox
                    id={"dataFile"}
                    style={{ height: 50, backgroundColor: "red" }}
                    type="text"
                  />
                  <div
                    style={{
                      marginTop: 15,
                      width: "45%",
                    }}
                  >
                    <label
                      style={{
                        fontWeight: "500",
                      }}
                    >
                      {"File Name"}
                    </label>
                    <TxtBox
                      id={"fileName"}
                      style={{ height: 50, backgroundColor: "red" }}
                      type="text"
                    />
                  </div>
                  <div style={{ marginTop: 10 }}>
                    <Btn
                      text={LABELS.SAVE}
                      onClick={onSubmitFile}
                      loading={refresh}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      )}

      {showDialog && (
        <UiDialog
          open={true}
          title={selectedRow.id ? "Edit Folder: " : "Add Folder: "}
          size={DEFAULTS.DIALOG_SIZE}
          onClose={() => {
            setShowDialog(false);
          }}
        >
          <div className="formGroup">
            <label>{"Folder Name"}</label>
            <TxtBox
              placeholder={"Folder Name"}
              id={"folderName"}
              type={"text"}
              defaultValue={selectedRow.folderName}
            />
          </div>
          <div className="formGroup">
            <Btn text={LABELS.ADD} onClick={saveData} />
          </div>
        </UiDialog>
      )}
    </React.Fragment>
  );
};

export default withSnackbar(Lototo);
