import React, { useState, useEffect, useRef } from "react";

import ExcelGrid from "components/Custom/ExcelGrid";

const dataJson = require("../gridData.json");

interface defaultProps {
  childRef: any;
  editRows: any[];
}

const Part5 = (props: defaultProps) => {
  const { childRef, editRows } = props;
  const [safetyMonitoringRecordCols, setSafetyMonitoringRecord] = useState<
    any[]
  >([
    {
      key: "recordDate",
      name: "Date",
      type: "date",
      editable: true,
      width: "10%",
    },
    {
      key: "observation",
      name: "Observation / issues",
      type: "text",
      editable: true,
      width: "35%",
    },
    {
      key: "actionRequired",
      name: "ActionRequired / Taken",
      type: "text",
      editable: true,
      width: "35%",
    },
    {
      key: "signature",
      name: "Signature / Intials",
      type: "text",
      editable: true,
      width: "20%",
    },
  ]);

  let tmpSafetyMonitoringRecordRows: any = dataJson["1.8"]["1.8.1"];

  if (!editRows) {
    tmpSafetyMonitoringRecordRows = null;
  } else if (editRows["records"]) {
    tmpSafetyMonitoringRecordRows = [];
    for (let i in editRows["records"]) {
      if (editRows["records"][i]["rowId"].indexOf("1.8.1") > -1) {
        let jsonData = editRows["records"][i];
        const details = JSON.parse(jsonData.safetyDetails);
        jsonData = {
          ...jsonData,
          ...details,
        };
        tmpSafetyMonitoringRecordRows.push(jsonData);
      }
    }
  }
  const [safetyMonitoringRecordRows, setSafetyMonitoringRecordRows] = useState<
    any[]
  >(tmpSafetyMonitoringRecordRows);

  const gridRef: any = useRef();

  useEffect(() => {
    childRef.saveRecord = saveRecord;
    return () => {
      childRef.saveRecord = null;
    };
  }, []);

  const saveRecord = () => {
    const args = { items: gridRef.getRowData() };
    return args;
  };

  const getDefaultNewRow = () => {
    const newRow: any = {
      id: "1.8.1." + (gridRef.getRowData().length + 1),
      safetyMonitorItemId: -1,
      recordDate: "",
      observation: "",
      actionRequired: "",
      signature: "",
    };
    return newRow;
  };

  return (
    <>
      <h4 className="ehsHeading1">{dataJson["1.8"]["heading1"]}</h4>
      <h5 className="ehsHeading2" style={{ marginBottom: 5 }}>
        {dataJson["1.8"]["heading2"]}
      </h5>
      {tmpSafetyMonitoringRecordRows && (
        <ExcelGrid
          childRef={gridRef}
          cols={safetyMonitoringRecordCols}
          rows={tmpSafetyMonitoringRecordRows}
          getDefaultNewRow={getDefaultNewRow}
          noTotalRows={true}
        />
      )}
    </>
  );
};

export default Part5;
