import React, { useEffect, useRef, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { withSnackbar } from "notistack";
import { GetApp as IconExport } from "@mui/icons-material";

import Loader from "components/Custom/Loader";
import AutoComplete from "components/Custom/AutoComplete";
import Btn from "components/Custom/Btn";

import { COLORS, LABELS, NOTIFY, WARNINGS } from "common/config";
import { ApiDashboard } from "apihandlers/dashboard";
import { ApiHome } from "apihandlers/home";
import { COMMON_JS } from "common/scripts";
import { IconButton, Tooltip } from "@mui/material";

const API_HANDLER_DASHBOARD = new ApiDashboard();
const API_HANDLER_HOME = new ApiHome();

const ManHoursDistribution = (props: any) => {
  const [loading, setLoading] = useState(true);
  const [tasks, setTasks] = useState([]);
  const [projects, setProjects] = useState([]);
  const [chartData, setChartData] = useState<any>({});

  useEffect(() => {
    if (loading) {
      getProjects();
    }
  }, [loading]);

  const getProjects = () => {
    const args = { dropdown: true };
    API_HANDLER_HOME.getProjects(args).then((res) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        setProjects(response.records);
      }
      setLoading(false);
    });
  };

  const onChangeProject = (e: any, val: any) => {
    getMainTasks(val.id);
  };

  const getMainTasks = (projectId: number) => {
    const args = { projectId };
    setTasks(null);
    API_HANDLER_HOME.getMainTasks(args).then((res) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        setTasks(response.records);
      }
    });
  };

  const loadChart = () => {
    const projectId = projectRef.current.selectedValue.id;
    const wbs = taskRef.current.selectedValue.id;
    if (!projectId) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.PROJECT_EMPTY);
      return;
    }
    const args = {
      projectId,
      wbs,
    };
    setChartData(null);
    API_HANDLER_DASHBOARD.getManHoursPlan(args).then((res) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        setTimeout(() => {
          setChartData(response);
        }, 2000);
      } else {
        setChartData({});
        COMMON_JS.showNotify(props, NOTIFY.ERROR, res.error);
      }
    });
  };

  const exportHighchart = () => {
    let projectName = "";
    if (projectRef.current) {
      projectName = projectRef.current.selectedValue.label;
    }
    let title =
      LABELS.MAN_HOURS_DISTRIBUTION + " - " + projectName.toUpperCase();
    if (taskRef.current.selectedValue.label) {
      title += " ( " + taskRef.current.selectedValue.label + " )";
    }
    let header = "<html>";
    header +=
      "<head><title>" +
      title +
      "</title><style>@page{margin-top: 5;size: A3 landscape;}</style></head>";
    let body = "<body><h2 style='text-align: center;'>" + title + "</h2>";
    body += chartRef.current.container.current.innerHTML;
    body += "</html>";
    const html = header + body;
    const printWin = window.open("", "", "toolbar=0,scrollbars=0,status=0");
    printWin.document.write(html);
    printWin.document.close();
    printWin.focus();
    printWin.print();
    printWin.close();
  };

  const projectRef: any = useRef();
  let chartRef: any = useRef();
  const taskRef: any = useRef();
  let projectName = "";
  if (projectRef.current) {
    projectName = projectRef.current.selectedValue.label;
  }

  const chartTitle = " ";
  const options = {
    chart: {
      zoomType: "xy",
      panning: true,
      // width: window.innerWidth * 0.9,
      // height: 550,
      marginTop: 45,
      marginLeft: 100,
      height: 1000,
      width: 1410,
      lang: {
        decimalPoint: ".",
        thousandsSep: ",",
      },
    },
    credits: false,
    title: {
      text: chartTitle,
    },
    exporting: {
      filename: chartTitle,
    },
    xAxis: [
      {
        categories: chartData ? chartData.categories : [],
        crosshair: true,
      },
    ],
    yAxis: [
      {
        // Secondary yAxis
        title: {
          text: "Man Hours",
          style: {
            color: Highcharts.getOptions().colors[0],
          },
        },
        labels: {
          format: "{value} Hours",
          style: {
            color: Highcharts.getOptions().colors[0],
          },
        },
      },
      {
        // Primary yAxis
        labels: {
          format: "{value}",
          style: {
            color: Highcharts.getOptions().colors[1],
          },
        },
        title: {
          text: "Cumulative Man Hours",
          style: {
            color: Highcharts.getOptions().colors[1],
          },
        },
        opposite: true,
      },
    ],
    tooltip: {
      shared: true,
    },
    legend: {
      layout: "vertical",
      align: "left",
      x: 120,
      verticalAlign: "top",
      y: 100,
      floating: true,
      backgroundColor:
        Highcharts.defaultOptions.legend.backgroundColor || // theme
        "rgba(255,255,255,0.25)",
    },
    plotOptions: {
      column: {
        stacking: "normal",
        dataLabels: {
          enabled: true,
        },
      },
      series: {
        dataLabels: {
          enabled: false,
          verticalAlign: "top",
          rotation: 270,
          y: -20,
        },
      },
    },
    series: chartData ? chartData.series : [],
  };

  return (
    <React.Fragment>
      <div className="pageInfo">
        <ul className="breadcrumbs">
          <li>{LABELS.HOME}</li>
          <li className="dim">{" / "}</li>
          <li onClick={() => props.setDashMenu(LABELS.HOME)}>
            {LABELS.DASHBOARD}
          </li>
          <li className="dim">{" / "}</li>
          <li className="active">{LABELS.MAN_HOURS_PLAN}</li>
        </ul>
        <h4>{LABELS.MAN_HOURS_PLAN}</h4>
      </div>
      {loading ? (
        <div className="flexCenter" style={{ minHeight: 450 }}>
          <Loader />
        </div>
      ) : (
        <div className="hChart">
          <div id="chartForm">
            <div className="formGroup">
              <label>{LABELS.PROJECT_NAME}</label>
              <AutoComplete
                id={"projects"}
                list={projects}
                childRef={projectRef}
                disabled={!chartData}
                onChange={onChangeProject}
              />
            </div>

            {tasks ? (
              <div className="formGroup">
                <label>{LABELS.TASK_NAME}</label>
                <AutoComplete
                  id={"tasks"}
                  list={tasks}
                  childRef={taskRef}
                  disabled={!chartData}
                />
              </div>
            ) : (
              <div className="flexCenter">
                <Loader />
              </div>
            )}

            <div
              className="formGroup"
              style={{
                display: "flex",
                marginTop: 15,
                marginBottom: 25,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Btn
                  loading={!chartData}
                  text={LABELS.LOAD}
                  marginRight={15}
                  onClick={loadChart}
                />
                {chartData && chartData.series && (
                  <Tooltip title={LABELS.EXPORT_TO_PDF}>
                    <IconButton
                      disabled={chartData === null}
                      onClick={exportHighchart}
                    >
                      <IconExport />
                    </IconButton>
                  </Tooltip>
                )}
              </div>
            </div>
          </div>

          {chartData ? (
            chartData.series ? (
              <div className="highchartBox">
                <HighchartsReact
                  highcharts={Highcharts}
                  options={options}
                  ref={chartRef}
                />
              </div>
            ) : (
              <div></div>
            )
          ) : (
            <div
              className="flexCenter"
              style={{ minHeight: 400, width: "100%" }}
            >
              <Loader />
            </div>
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default withSnackbar(ManHoursDistribution);
