import React, { useState, useEffect, useRef } from "react";

import ChkBox from "components/Custom/ChkBox";
import ExcelGrid from "components/Custom/ExcelGrid";
import Loader from "components/Custom/Loader";

const dataJson = require("../gridData.json");

interface defaultProps {
  childRef: any;
  editRows: any[];
}

const Part1 = (props: defaultProps) => {
  const { childRef, editRows } = props;
  const [taskCols, setTaskCols] = useState<any[]>([
    {
      key: "taskQuestion",
      name: "Task Review Questions",
      type: "text",
      editable: false,
      width: "60%",
    },
    {
      key: "comments",
      name: "Comments",
      type: "text",
      sortable: true,
      editable: true,
      width: "30%",
      className: "textCenter",
    },
    {
      key: "intial",
      name: "Initial",
      type: "text",
      editable: true,
      width: "10%",
    },
  ]);

  let tmpTaskQuestionRows: any = dataJson["1"]["1.1"];
  for (let key in tmpTaskQuestionRows) {
    tmpTaskQuestionRows[key].checkBox = false;
    tmpTaskQuestionRows[key].action = "";
    tmpTaskQuestionRows[key].safetyMonitorItemId = -1;
  }
  if (!editRows) {
    tmpTaskQuestionRows = null;
  } else if (editRows["records"]) {
    for (let key in tmpTaskQuestionRows) {
      if (editRows["records"][tmpTaskQuestionRows[key].id]) {
        const details = JSON.parse(
          editRows["records"][tmpTaskQuestionRows[key].id].safetyDetails
        );
        const jsonData = {
          ...tmpTaskQuestionRows[key],
          ...details,
          safetyMonitorItemId:
            editRows["records"][tmpTaskQuestionRows[key].id]
              .safetyMonitorItemId,
        };
        tmpTaskQuestionRows[key] = jsonData;
      }
    }
  }
  const [taskRows, setTaskRows] = useState<any[]>(tmpTaskQuestionRows);
  const [reviewCols, setReviewCols] = useState<any[]>([
    {
      key: "reviewQuestion",
      name: "Rewiew Questions",
      type: "text",
      editable: false,
      width: "60%",
    },
    {
      key: "comments",
      name: "Comments",
      type: "text",
      sortable: true,
      editable: true,
      width: "20%",
      className: "textCenter",
    },
    {
      key: "checkBoxSatisfactory",
      name: "Satisfactory",
      type: "checkbox",
      sortable: true,
      width: "10%",
      renderCell: ({ reviewRows, onRowChange, tabIndex }) => {
        return (
          <div className="excelChkWrapper">
            <ChkBox
              checked={reviewRows.check}
              onChange={(val: any) => {
                onRowChange({
                  ...reviewRows,
                  check: !reviewRows.check,
                });
              }}
            />
          </div>
        );
      },
    },
    {
      key: "checkBoxRequiresReview",
      name: "Requires Review",
      type: "checkbox",
      sortable: true,
      width: "10%",
      renderCell: ({ reviewRows, onRowChange, tabIndex }) => {
        return (
          <div className="excelChkWrapper">
            <ChkBox
              checked={reviewRows.check}
              onChange={(val: any) => {
                onRowChange({
                  ...reviewRows,
                  check: !reviewRows.check,
                });
              }}
            />
          </div>
        );
      },
    },
  ]);

  let tmpReviewRows: any = dataJson["2"]["2.1"];
  for (let key in tmpReviewRows) {
    tmpReviewRows[key].checkBox = false;
    tmpReviewRows[key].action = "";
    tmpReviewRows[key].safetyMonitorItemId = -1;
  }
  if (!editRows) {
    tmpReviewRows = null;
  } else if (editRows["records"]) {
    for (let key in tmpReviewRows) {
      if (editRows["records"][tmpReviewRows[key].id]) {
        const details = JSON.parse(
          editRows["records"][tmpReviewRows[key].id].safetyDetails
        );
        const jsonData = {
          ...tmpReviewRows[key],
          ...details,
          safetyMonitorItemId:
            editRows["records"][tmpReviewRows[key].id].safetyMonitorItemId,
        };
        tmpReviewRows[key] = jsonData;
      }
    }
  }
  const [reviewRows, setReviewRows] = useState<any[]>(tmpReviewRows);

  const grid1Ref: any = useRef();
  const grid2Ref: any = useRef();

  useEffect(() => {
    childRef.saveRecord = saveRecord;
    return () => {
      childRef.saveRecord = null;
    };
  }, []);

  const saveRecord = () => {
    const section1 = grid1Ref.getRowData().map((s1) => {
      return {
        comments: s1.comments,
        id: s1.id,
        initial: s1.initial,
        sopId: s1.sopId || -1,
      };
    });
    const section2 = grid2Ref.getRowData().map((s2) => {
      return {
        comments: s2.comments,
        id: s2.id,
        checkBoxcheckBoxSatisfactory: s2.checkBoxSatisfactory,
        checkBoxRequiresReview: s2.checkBoxRequiresReview,
        sopId: s2.sopId || -1,
      };
    });
    const items = [...section1, ...section2];
    return { items };
  };

  console.log("firstAidRows: ", editRows, tmpTaskQuestionRows);

  return (
    <>
      <h4 className="ehsHeading1">{dataJson["1"]["heading1"]}</h4>
      <h5 className="ehsHeading2">{dataJson["1"]["heading2"]}</h5>
      {tmpTaskQuestionRows ? (
        <ExcelGrid
          childRef={grid1Ref}
          cols={taskCols}
          rows={tmpTaskQuestionRows}
        />
      ) : (
        <div className="pageCenter">
          <Loader />
        </div>
      )}
      <h4 className="ehsHeading1">{dataJson["2"]["heading1"]}</h4>
      <h5 className="ehsHeading2">{dataJson["2"]["heading2"]}</h5>
      {tmpReviewRows ? (
        <ExcelGrid childRef={grid2Ref} cols={reviewCols} rows={tmpReviewRows} />
      ) : (
        <div className="pageCenter">
          <Loader />
        </div>
      )}
    </>
  );
};

export default Part1;
