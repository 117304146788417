import { IApiResponse } from "common/models";
import { API_CONFIG } from "common/config";
import { COMMON_JS } from "common/scripts";

interface IApiEhs {}

export class ApiEhs implements IApiEhs {
  manageCapa = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = {
        status: 200,
        response: {},
        error: "",
      };
      resObj = await COMMON_JS.callApi(
        "/v1/ehs/capa",
        API_CONFIG.METHODS.POST,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };
  getCapas = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/ehs/capas",
        API_CONFIG.METHODS.GET,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };

  printWorkPermit = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/ehs/printworkpermit",
        API_CONFIG.METHODS.POST,
        args,
        true
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };

  getAudit2 = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/ehs/getaudit2",
        API_CONFIG.METHODS.GET,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };

  manageSop = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = {
        status: 200,
        response: {},
        error: "",
      };
      resObj = await COMMON_JS.callApi(
        "/v1/ehs/sop",
        API_CONFIG.METHODS.POST,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };
  getSops = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/ehs/sops",
        API_CONFIG.METHODS.GET,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };
  getSopInfo = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/ehs/sopinfo",
        API_CONFIG.METHODS.GET,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };

  manageNearMiss = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = {
        status: 200,
        response: {},
        error: "",
      };
      resObj = await COMMON_JS.callApi(
        "/v1/ehs/nearmiss",
        API_CONFIG.METHODS.POST,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };
  getNearMisses = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/ehs/nearmisses",
        API_CONFIG.METHODS.GET,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };
  getNearMissInfo = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/ehs/nearmissinfo",
        API_CONFIG.METHODS.GET,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };

  manageIncident = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = {
        status: 200,
        response: {},
        error: "",
      };
      resObj = await COMMON_JS.callApi(
        "/v1/ehs/incident",
        API_CONFIG.METHODS.POST,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };
  getIncidents = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/ehs/incidents",
        API_CONFIG.METHODS.GET,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };
  getIncidentInfo = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/ehs/incidentinfo",
        API_CONFIG.METHODS.GET,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };

  manageOccupationalSafety = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = {
        status: 200,
        response: {},
        error: "",
      };
      resObj = await COMMON_JS.callApi(
        "/v1/ehs/occupationalsafety",
        API_CONFIG.METHODS.POST,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };
  getOccupationalSafeties = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/ehs/occupationalsafeties",
        API_CONFIG.METHODS.GET,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };
  getOccupationalSafetyInfo = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/ehs/occupationalsafetyinfo",
        API_CONFIG.METHODS.GET,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };

  manageHaccp = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = {
        status: 200,
        response: {},
        error: "",
      };
      resObj = await COMMON_JS.callApi(
        "/v1/ehs/haccp",
        API_CONFIG.METHODS.POST,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };
  getHaccps = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/ehs/haccps",
        API_CONFIG.METHODS.GET,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };
  getHaccpInfo = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/ehs/haccpinfo",
        API_CONFIG.METHODS.GET,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };

  manageEmpData = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = {
        status: 200,
        response: {},
        error: "",
      };
      resObj = await COMMON_JS.callApi(
        "/v1/ehs/employeedata",
        API_CONFIG.METHODS.POST,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };
  getEmployeeData = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/ehs/employeedatas",
        API_CONFIG.METHODS.GET,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };
  getEmployeeDataInfo = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/ehs/employeedataInfo",
        API_CONFIG.METHODS.GET,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };

  manageInductionTraining = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = {
        status: 200,
        response: {},
        error: "",
      };
      resObj = await COMMON_JS.callApi(
        "/v1/ehs/induction",
        API_CONFIG.METHODS.POST,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };
  getInductionTraining = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/ehs/inductions",
        API_CONFIG.METHODS.GET,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };

  manageWasteManagement = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = {
        status: 200,
        response: {},
        error: "",
      };
      resObj = await COMMON_JS.callApi(
        "/v1/ehs/wastemanagement",
        API_CONFIG.METHODS.POST,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };
  getWasteManagements = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/ehs/wastemanagements",
        API_CONFIG.METHODS.GET,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };
  getWasteManagementInfo = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/ehs/wastemanagementinfo",
        API_CONFIG.METHODS.GET,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };

  manageContractor = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = {
        status: 200,
        response: {},
        error: "",
      };
      resObj = await COMMON_JS.callApi(
        "/v1/audit/contractor",
        API_CONFIG.METHODS.POST,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };
  getContractor = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/audit/contractors",
        API_CONFIG.METHODS.GET,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };
  getContractorInfo = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/audit/contractorinfo",
        API_CONFIG.METHODS.GET,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };
  getNewContractorRegNo = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/audit/newcontractoraplnum",
        API_CONFIG.METHODS.GET,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };

  manageWorkPermit = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = {
        status: 200,
        response: {},
        error: "",
      };
      resObj = await COMMON_JS.callApi(
        "/v1/ehs/workPermit",
        API_CONFIG.METHODS.POST,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };
  getWorkPermits = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/ehs/workPermits",
        API_CONFIG.METHODS.GET,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };
  getWorkPermitInfo = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/ehs/workPermitInfo",
        API_CONFIG.METHODS.GET,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };
  getWorkPermitNumber = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/ehs/workpermitnumber",
        API_CONFIG.METHODS.GET,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };

  manageSor = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = {
        status: 200,
        response: {},
        error: "",
      };
      resObj = await COMMON_JS.callApi(
        "/v1/ehs/sor",
        API_CONFIG.METHODS.POST,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };
  getSors = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/ehs/sors",
        API_CONFIG.METHODS.GET,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };
  getSorInfo = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/ehs/sorInfo",
        API_CONFIG.METHODS.GET,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };

  manageSafetyMonitoring = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = {
        status: 200,
        response: {},
        error: "",
      };
      resObj = await COMMON_JS.callApi(
        "/v1/ehs/safetymonitor",
        API_CONFIG.METHODS.POST,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };
  getSafetyMonitoring = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/ehs/safetymonitors",
        API_CONFIG.METHODS.GET,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };
  getSafetyMonitorInfo = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/ehs/safetymonitorinfo",
        API_CONFIG.METHODS.GET,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };

  manageRam = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = {
        status: 200,
        response: {},
        error: "",
      };
      resObj = await COMMON_JS.callApi(
        "/v1/ehs/ram",
        API_CONFIG.METHODS.POST,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };
  getRams = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/ehs/rams",
        API_CONFIG.METHODS.GET,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };
  getRamInfo = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/ehs/raminfo",
        API_CONFIG.METHODS.GET,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };
}
