import React, { useEffect, useRef, useState } from "react";
import { withSnackbar } from "notistack";
// Data Tables
import jQuery from "jquery";
import "datatables.net-bs4";
import "datatables.net-buttons-bs4";
import "datatables.net-buttons/js/buttons.html5";
import "datatables.net-buttons/js/buttons.print";
import "datatables.net-buttons/js/buttons.flash";
import "pdfmake/build/pdfmake";
import "pdfmake/build/vfs_fonts";
import { GetApp as IconExport } from "@mui/icons-material";

import Loader from "components/Custom/Loader";
import AutoComplete from "components/Custom/AutoComplete";
import Btn from "components/Custom/Btn";

import { LABELS, NOTIFY, WARNINGS } from "common/config";
import { ApiDashboard } from "apihandlers/dashboard";
import { ApiHome } from "apihandlers/home";
import { COMMON_JS } from "common/scripts";

const API_HANDLER_DASHBOARD = new ApiDashboard();
const API_HANDLER_HOME = new ApiHome();

const $ = jQuery;

const ReportManHours = (props: any) => {
  const [loading, setLoading] = useState(true);
  const [projects, setProjects] = useState([]);
  const [chartData, setChartData] = useState<any>({});

  useEffect(() => {
    if (loading) {
      getProjects();
    }
  }, [loading]);

  const getProjects = () => {
    const args = { dropdown: true };
    API_HANDLER_HOME.getProjects(args).then((res) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        setProjects(response.records);
      }
      setLoading(false);
    });
  };

  const generateReportGrid = (res: any) => {
    let title = LABELS.MAN_HOURS_REPORT;
    if (projectRef.current) {
      title += " - " + projectRef.current.selectedValue.label;
    }
    $("#reportGrid").DataTable({
      dom: "Bfrtip",
      buttons: [
        // {
        //   extend: "pdf",
        //   className: "reportBtn",
        //   title: title,
        // },
        // {
        //   extend: "excel",
        //   className: "reportBtn",
        //   title: title,
        // },
        {
          extend: "csv",
          className: "reportBtn",
          title: title,
          // text: "CSV Export",
          text: "<i class='fa fa-download'></i>",
        },
        // {
        //   extend: "print",
        //   className: "reportBtn",
        //   title: title,
        //   customize: function (win) {
        //     COMMON_JS.getExportPrintCss(win);
        //   },
        // },
      ],
      paging: true,
      pageLength: 15,
      columns: res.cols,
      data: res.rows,
      order: [],
    });
  };

  const loadChart = () => {
    const projectId = projectRef.current.selectedValue.id;
    if (!projectId) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.PROJECT_EMPTY);
      return;
    }
    const args = {
      projectId,
    };
    if ($.fn.dataTable.isDataTable("#reportGrid")) {
      let table = $("#reportGrid").DataTable();
      table.destroy();
    }
    if ($("#reportGrid")) {
      $("#reportGrid").empty();
    }
    setChartData(null);
    API_HANDLER_DASHBOARD.getManHoursReport(args).then((res) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        setChartData(response);
        setTimeout(() => {
          generateReportGrid(response);
        }, 500);
      }
    });
  };

  const projectRef: any = useRef();

  return (
    <React.Fragment>
      <div className="pageInfo">
        <ul className="breadcrumbs">
          <li>{LABELS.HOME}</li>
          <li className="dim">{" / "}</li>
          <li onClick={() => props.setDashMenu(LABELS.HOME)}>
            {LABELS.DASHBOARD}
          </li>
          <li className="dim">{" / "}</li>
          <li className="active">{LABELS.MAN_HOURS_REPORT}</li>
        </ul>
        <h4>{LABELS.MAN_HOURS_REPORT}</h4>
      </div>
      {loading ? (
        <div className="flexCenter" style={{ minHeight: 450 }}>
          <Loader />
        </div>
      ) : (
        <div className="hChart">
          <div id="chartForm">
            <div className="formGroup">
              <label>{LABELS.PROJECT_NAME}</label>
              <AutoComplete
                id={"projects"}
                list={projects}
                childRef={projectRef}
                disabled={!chartData}
              />
            </div>

            <div
              className="formGroup"
              style={{
                display: "flex",
                marginTop: 15,
                marginBottom: 20,
              }}
            >
              <div
                style={{
                  display: "flex",
                }}
              >
                <Btn
                  text={LABELS.LOAD}
                  onClick={loadChart}
                  loading={!chartData}
                />
              </div>
            </div>
          </div>

          {chartData ? (
            <div className="reportGridWrapper">
              <table
                id="reportGrid"
                className="mdl-data-table width100"
                cellSpacing="0"
                cellPadding="3"
              />
            </div>
          ) : (
            <div
              className="flexCenter"
              style={{ minHeight: 400, width: "100%" }}
            >
              <Loader />
            </div>
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default withSnackbar(ReportManHours);
