import React, { useEffect, useRef, useState } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { withSnackbar } from "notistack";
import { IconButton, Tooltip, LinearProgress } from "@mui/material";
import {
  Replay as IconReload,
  AddBox as IconAdd,
  Edit as IconEdit,
  Print as IconPrint,
} from "@mui/icons-material";

import TxtBox from "components/Custom/TxtBox";
import UiDialog from "components/Custom/UiDialog";
import Btn from "components/Custom/Btn";
import Loader from "components/Custom/Loader";
import Part1 from "./Part1";
import Part2 from "./Part2";

import {
  API_CONFIG,
  COLORS,
  ERRORS,
  LABELS,
  NOTIFY,
  SUCCESS,
  WARNINGS,
} from "common/config";
import { ApiHome } from "apihandlers/home";
import { ApiAudit } from "apihandlers/audit";
import { COMMON_JS } from "common/scripts";

const API_HANDLER_HOME = new ApiHome();
const API_HANDLER_AUDIT = new ApiAudit();

let _timer = null;

const Audit_1 = (props: any) => {
  const [loading, setLoading] = useState(true);

  const [projects, setProjects] = useState([]);
  const [rows, setRows] = useState<any>(null);
  const [filteredRows, setFilteredRows] = useState([]);
  const [manageDialog, setManageDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [formData, setFormData] = useState<any>({});

  const [pageNo, setPageNo] = useState(1);
  const [totalPages, setTotalPages] = useState(2);

  useEffect(() => {
    if (loading) {
      getRows();
    }
  }, [loading]);

  const getRows = () => {
    const args = {};
    API_HANDLER_AUDIT.getAudits(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        setRows(response.records);
        setLoading(false);
        getProjects();
      }
    });
  };

  const getProjects = () => {
    const args = {};
    API_HANDLER_HOME.getProjects(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        setProjects(
          response.records.map((r) => {
            return {
              id: r.id,
              label: r.projectName,
            };
          })
        );
      }
    });
  };

  const onEditRow = (row: any) => {
    setPageNo(1);
    setSelectedRow(row);
    setManageDialog(true);
    setFormData({
      notes: row.notes,
      actionPlan: row.actionPlan,
      checklist: row.checklist,
    });
  };

  const columns: GridColDef[] = [
    {
      field: "slNo",
      headerName: "Sl No.",
      width: 100,
      type: "number",
      align: "center",
      headerAlign: "center",
    },
    { field: "projectName", headerName: "Project Name", width: 300 },
    { field: "orgName", headerName: "Organization", width: 300 },
    { field: "createdOn", headerName: "Created On", width: 200 },
    {
      field: "options",
      headerName: "Options",
      // width: 150,
      renderCell: (params) => (
        <div className="options">
          <Tooltip title={LABELS.EDIT} placement="bottom">
            <IconButton
              className="iconBtn"
              onClick={() => onEditRow(params.row)}
            >
              <IconEdit />
            </IconButton>
          </Tooltip>
          <Tooltip title={LABELS.PRINT} placement="bottom">
            <IconButton
              className="iconBtn"
              onClick={() => printAudit(params.row)}
            >
              <IconPrint />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  const manageAudit = () => {
    let saveData: any = {};
    console.log("manageAudit:ref:", formData, part1Ref, part2Ref);
    if (part1Ref.saveRecord) {
      saveData = part1Ref.saveRecord();
    } else if (part2Ref.saveRecord) {
      saveData = part2Ref.saveRecord();
    }
    const args = { id: selectedRow.id || -1, ...formData, ...saveData };
    setFormData(args);
    console.log("manageAudit:", args);

    // Validations
    // if (!projectName) {
    //   COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.PROJECT_NAME_EMPTY);
    //   return;
    // }

    setLoading(true);
    API_HANDLER_AUDIT.manageAudit(args).then((res) => {
      if (res.status === 200) {
        COMMON_JS.showNotify(props, NOTIFY.SUCCESS, SUCCESS.AUDIT_SAVED);
        setManageDialog(false);
        reloadGrid();
        setFormData({});
        setPageNo(1);
      } else {
        COMMON_JS.showNotify(props, NOTIFY.ERROR, ERRORS.AUDIT_FAILED);
      }
    });
  };

  const reloadGrid = () => {
    setRows(null);
    setTimeout(() => {
      getRows();
    }, 2000);
  };

  const onSearch = (e: any) => {
    const qry = e.target.value.trim().toUpperCase();
    clearTimeout(_timer);
    _timer = setTimeout(() => {
      let flist = rows.filter((r: any) => {
        return r.projectName.toUpperCase().indexOf(qry) > -1;
      });
      setFilteredRows(flist);
    }, 150);
  };

  const onAddNew = () => {
    setPageNo(1);
    setManageDialog(!manageDialog);
    setSelectedRow({});
    setFormData({});
  };

  const updateForm = () => {
    let args: any = {};
    if (part1Ref.saveRecord) {
      args = part1Ref.saveRecord();
      if (!args) {
        return false;
      }
    } else if (part2Ref.saveRecord) {
      args = part2Ref.saveRecord();
      if (!args) {
        return false;
      }
    }
    setFormData({ ...formData, ...args });
    return true;
  };

  const printAudit = (row) => {
    const auditId = row.id;
    const args = {
      auditId,
    };
    API_HANDLER_AUDIT.printAudit(args)
      .then((res: any) => {
        if (res) {
          let base64 = "data:application/pdf;base64, " + res;
          fetch(base64).then((r) => {
            r.blob().then((data) => {
              let objUrl = window.URL.createObjectURL(data);
              // (
              //   document.getElementById("printView") as HTMLElement
              // ).setAttribute("src", objUrl);
              // (
              //   document.getElementById("printView") as HTMLElement
              // ).style.display = "flex";
              window.open(objUrl, "_blank");
              window.URL.revokeObjectURL(objUrl);
            });
          });
        } else {
          COMMON_JS.showNotify(props, NOTIFY.ERROR, "Print Error..!");
        }
      })
      .catch((err) => {
        COMMON_JS.showNotify(props, NOTIFY.ERROR, "Invoice Copy Not Found..!");
      });
  };

  const part1Ref: any = useRef();
  const part2Ref: any = useRef();

  console.log("formData: ", formData);

  return (
    <React.Fragment>
      <div className="pageInfo">
        <ul className="breadcrumbs">
          <li>{LABELS.HOME}</li>
          <li className="dim">{" / "}</li>
          <li onClick={() => props.setDashMenu(LABELS.HOME)}>{LABELS.EHS}</li>
          <li className="dim">{" / "}</li>
          <li onClick={() => props.setAuditDashMenu(LABELS.HOME)}>
            {LABELS.AUDITS}
          </li>
          <li className="dim">{" / "}</li>
          <li className="active">{LABELS.AUDIT}</li>
        </ul>
        <h4>{LABELS.AUDIT}</h4>
        {/* <p>{SUB_TITLES.USERS}</p> */}
      </div>

      {rows && (
        <div className="gridOption">
          <TxtBox placeholder={LABELS.SEARCH} onKeyPress={onSearch} />
          <IconButton className="iconBtn" onClick={reloadGrid}>
            <IconReload />
          </IconButton>
          <div>
            <Btn text={LABELS.ADD} onClick={onAddNew} />
          </div>
        </div>
      )}

      <div
        className="grid"
        style={{
          width: "98%",
          height: "80%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {rows ? (
          <DataGrid
            rows={filteredRows.length > 0 ? filteredRows : rows}
            columns={columns}
            rowHeight={120}
            // pageSize={5}
            // rowsPerPageOptions={[5]}
            disableColumnMenu={true}
            disableSelectionOnClick={true}
          />
        ) : (
          <Loader />
        )}
      </div>

      {manageDialog && (
        <UiDialog
          title={
            selectedRow.id
              ? "Edit Audit: " + selectedRow.projectName
              : "Add New Audit"
          }
          open={true}
          size={"lg"}
          onClose={() => {
            setManageDialog(false);
          }}
        >
          <div style={{ width: "100%", flexDirection: "column" }}>
            <LinearProgress
              variant="determinate"
              value={pageNo * (100 / totalPages)}
              style={{
                backgroundColor: COMMON_JS.addAlphaToColor(COLORS.PRIMARY, 0.2),
                marginBottom: 15,
              }}
            />
            {/* <h4>{pageNo}</h4> */}
            {pageNo === 1 && (
              <Part1
                childRef={part1Ref}
                data={formData}
                projects={projects}
                selectedRow={selectedRow}
                setData={(d: any) => {
                  setFormData(d);
                }}
              />
            )}
            {pageNo === 2 && (
              <Part2
                childRef={part2Ref}
                data={formData}
                setData={(d: any) => {
                  setFormData(d);
                }}
              />
            )}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <div
              style={{
                width: "35%",
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              {pageNo !== -1 && (
                <Btn
                  text={LABELS.PREVIOUS}
                  disabled={pageNo == 0}
                  bgColor={pageNo == 0 ? COLORS.DIM_GRAY : COLORS.PRIMARY}
                  marginRight={15}
                  onClick={() => {
                    setPageNo(pageNo - 1);
                  }}
                />
              )}
              {pageNo !== -1 && (
                <Btn
                  text={LABELS.NEXT}
                  disabled={pageNo == totalPages}
                  bgColor={
                    pageNo == totalPages ? COLORS.DIM_GRAY : COLORS.PRIMARY
                  }
                  marginRight={15}
                  onClick={() => {
                    console.log("part1Ref: ", part1Ref, part2Ref);
                    const res = updateForm();
                    if (res) {
                      setPageNo(pageNo + 1);
                    }
                  }}
                />
              )}
              <Btn
                text={LABELS.SAVE}
                disabled={pageNo == totalPages ? false : true}
                bgColor={
                  pageNo == totalPages ? COLORS.PRIMARY : COLORS.DIM_GRAY
                }
                onClick={manageAudit}
              />
            </div>
          </div>
        </UiDialog>
      )}
    </React.Fragment>
  );
};

export default withSnackbar(Audit_1);
