/*
 * Manages Export Things => CSV & PDF
 */

import { cloneElement } from "react";
import type { ReactElement } from "react";
import type { DataGridProps } from "react-data-grid";

export async function exportToCsv(
  fileName: string,
  gridCols: any,
  sortedRows: any,
  btmRows: any
) {
  const rowsObj: any = getRowsObj(gridCols, sortedRows, btmRows);
  const { head, body, foot } = rowsObj;
  const content = [...head, ...body, ...foot]
    .map((cells: any) => cells.map(serialiseCellValue).join(","))
    .join("\n");
  downloadFile(
    fileName + ".csv",
    new Blob([content], { type: "text/csv;charset=utf-8;" })
  );
}

const getRowsObj = (gridCols: any, sortedRows: any, btmRows: any) => {
  const cols = [];
  const rows = [];
  const sumRows = [];
  const dontPrint = [];
  for (let i = 0; i < gridCols.length; i++) {
    if (gridCols[i].key) {
      if (
        gridCols[i].headerCellClass &&
        gridCols[i].headerCellClass.indexOf("dontprint") === -1
      ) {
        cols.push(gridCols[i].name);
        if (btmRows.length > 0) {
          if (cols.length === 1) {
            sumRows.push("");
          } else if (cols.length === 2) {
            sumRows.push("Total: " + btmRows[0]["totalRows"] + " records");
          } else {
            sumRows.push(btmRows[0][gridCols[i].key]);
          }
        }
      } else {
        dontPrint.push(i);
      }
    } else {
      dontPrint.push(i);
    }
  }
  for (let i = 0; i < sortedRows.length; i++) {
    const sortedRow = sortedRows[i];
    const tmpRow = [];
    let type = sortedRow._type || "";
    if (type !== "subgrid") {
      for (let j = 0; j < gridCols.length; j++) {
        let key = gridCols[j].key;
        if (dontPrint.indexOf(j) === -1) {
          tmpRow.push(sortedRow[key]);
        }
      }
    }
    if (tmpRow.length > 0) {
      rows.push(tmpRow);
    }
  }
  const rowsObj = {
    head: [cols],
    body: rows,
    foot: [sumRows],
  };
  return rowsObj;
};

export async function exportToPdf(
  fileName: string,
  gridCols: any,
  sortedRows: any,
  btmRows: any,
  orientation = "p"
) {
  const rowsObj = getRowsObj(gridCols, sortedRows, btmRows);
  const [{ jsPDF }, autoTable, { head, body, foot }]: any = await Promise.all([
    import("jspdf"),
    (await import("jspdf-autotable")).default,
    rowsObj,
  ]);
  const doc: any = new jsPDF({
    orientation,
    unit: "px",
    format: "a4",
  });
  autoTable(doc, {
    head,
    body,
    foot,
    horizontalPageBreak: true,
    styles: { cellPadding: 1.5, fontSize: 8, cellWidth: "wrap" },
    tableWidth: "auto",
  });
  const fontSize = 12;
  doc.setFontSize(fontSize);
  // Calculate the text width
  var textWidth = doc.getStringUnitWidth(fileName) * fontSize;
  // Calculate the x-coordinate for centering
  var centerX = (doc.internal.pageSize.width - textWidth) / 2;
  // Add text to the PDF at the centered position
  doc.text(fileName, centerX, 20); // 20 is the y-coordinate
  doc.save(fileName + ".pdf");
}

function serialiseCellValue(value: unknown) {
  if (typeof value === "string") {
    const formattedValue = value.replace(/"/g, '""');
    return formattedValue.includes(",")
      ? `"${formattedValue}"`
      : formattedValue;
  }
  return value;
}

function downloadFile(fileName: string, data: Blob) {
  const downloadLink = document.createElement("a");
  downloadLink.download = fileName;
  const url = URL.createObjectURL(data);
  downloadLink.href = url;
  downloadLink.click();
  URL.revokeObjectURL(url);
}
