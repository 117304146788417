import React, { useEffect, useRef, useState } from "react";
import { withSnackbar } from "notistack";
import { IconButton, Tooltip } from "@mui/material";
import {
  Edit as IconEdit,
  Close as IconClose,
  LocalPrintshop as IconPrint,
} from "@mui/icons-material";

import UiDialog from "components/Custom/UiDialog";
import Btn from "components/Custom/Btn";
import ExcelGrid from "components/Custom/ExcelGrid";
import HGraph from "components/Custom/HGraph";
import FormInput from "components/Custom/FormInput";
import DocView from "components/Custom/DocView";
import Loader from "components/Custom/Loader";
import AutoComplete from "components/Custom/AutoComplete";
import TxtBox from "components/Custom/TxtBox";
import RichTxtBox from "components/Custom/RichTxtBox";
import RadioBtn from "components/Custom/RadioBtn";
import ModalBtmBtns from "components/Custom/ModalBtmBtns";
import SkeletonBox from "components/Custom/SkeletonBox";

import {
  API_CONFIG,
  COLORS,
  CONFIG,
  LABELS,
  NOTIFY,
  SUCCESS,
  WARNINGS,
} from "common/config";
import { COMMON_JS } from "common/scripts";
import { ApiHome } from "apihandlers/home";
import { ApiEhs } from "apihandlers/ehs";

const pdfFile = require("assets/files/file.pdf");
const dataJson = require("./grid.json");
const thumbnailImg = require("assets/images/thumbnail.jpg");

const API_HANDLER_HOME = new ApiHome();
const API_HANDLER_EHS = new ApiEhs();

const PreTender = (props: any) => {
  const [files, setFiles] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [cols, setCols] = useState<any[]>([
    {
      key: "slNo",
      name: "Sl No.",
      type: "number",
      width: "10%",
      editable: false,
      sortable: true,
      align: "center",
      searchable: true,
    },
    {
      key: "contractorNme",
      name: "Contractor Name",
      type: "text",
      width: "15%",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "contractorType",
      name: "Contractot type",
      type: "text",
      width: "15%",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "score",
      name: "Evaluation Score",
      type: "text",
      width: "15%",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "createdOn",
      name: "Created On",
      type: "text",
      width: "15%",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "createdBy",
      name: "Created By",
      type: "text",
      width: "15%",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "options",
      name: "Options",
      type: "html",
      width: "15%",
      editable: false,
      sortable: false,
      renderCell: ({ row }: any) => (
        <div className="options">
          <Tooltip title={LABELS.EDIT} placement="bottom">
            <IconButton className="iconBtn" onClick={() => onEditRow(row)}>
              <IconEdit />
            </IconButton>
          </Tooltip>
          <Tooltip title={LABELS.PRINT} placement="bottom">
            <IconButton
              className="iconBtn"
              // onClick={() => onPrintTrip(params.row)}     // TO DO ( line number 498)
            >
              <IconPrint />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ]);
  const [rows, setRows] = useState<any[]>(null);
  const [uploading, setUploading] = useState<boolean>(false);
  const [manageDialog, setManageDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [columnGraph, setColumnGraph] = useState<any>({});
  const [pieGraph, setPieGraph] = useState<any>({});
  const [contractorList, setContractorList] = useState<any[]>([]);

  const [cols2, setCols2] = useState<any[]>([
    {
      key: "criteria",
      name: "Criteria",
      type: "text",
      width: "60%",
      editable: false,
      sortable: false,
    },
    {
      key: "question",
      name: "PQQ Question",
      type: "text",
      width: "20%",
      editable: false,
      sortable: false,
    },
    {
      key: "marksAvailable",
      name: "Marks Available",
      type: "text",
      width: "20%",
      editable: false,
      sortable: false,
    },
  ]);

  useEffect(() => {
    return () => {
      // cleanUp();
    };
  }, []);

  useEffect(() => {
    if (loading) {
      getContractors();
    }
  }, [loading]);

  const getContractors = () => {
    const args = { dropdown: true };
    API_HANDLER_EHS.getContractor(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        const list = response.records.map((c: any) => {
          return {
            id: c.id,
            label: c.nameOfContractor,
          };
        });
        setContractorList(list);
        getRows();
      }
    });
  };

  const cleanUp = () => {
    setRows(null);
    setLoading(true);
    setManageDialog(false);
    setSelectedRow({});
    setColumnGraph({});
    setPieGraph({});
    console.log("Cleaned up");
  };

  const getRows = () => {
    setRows(null);
    getPreTenders();
  };

  const getPreTenders = () => {
    const args = {};
    API_HANDLER_HOME.getPreRenders(args).then((res: any) => {
      const { status, response } = res;
      if (status === 200) {
        setRows(response.records);
        setLoading(false);
        // setColumnGraph({ xAxis, yAxis });
        // setPieGraph({ series });
      }
    });
  };

  const onEditRow = (row: any) => {
    setSelectedRow(row);
    setManageDialog(true);
  };

  const managePretender = () => {
    const contractorId = contractorRef.current.selectedValue.id;
    // Validations
    if (!contractorId) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        "Please select contractor..!"
      );
      return;
    }

    const args = {
      preTenderId: selectedRow.id || -1,
      contractorId,
      profileDetails: {
        applicationDt: (
          document.getElementById("applicationDt") as HTMLInputElement
        ).value,
        "2.1": (document.getElementById("2.1") as HTMLInputElement).value,
        "2.2.1": (document.getElementById("2.2.1") as HTMLInputElement).value,
        "2.2.2": (document.getElementById("2.2.2") as HTMLInputElement).value,
        "2.2.3": (document.getElementById("2.2.3") as HTMLInputElement).value,
        "2.2.4": (document.getElementById("2.2.4") as HTMLInputElement).value,
        "2.3": (document.getElementById("2.3") as HTMLInputElement).value,
        "2.4": (document.getElementById("2.4") as HTMLInputElement).value,
        score: 0,
      },
      financialDetails: {
        "3.1.1": (document.getElementById("3.1.1") as HTMLInputElement).value,
        "3.1.2": (document.getElementById("3.1.2") as HTMLInputElement).value,
        "3.1.3": (document.getElementById("3.1.3") as HTMLInputElement).value,
        "3.1.4": (document.getElementById("3.1.4") as HTMLInputElement).value,
        "3.2": (document.getElementById("3.2") as HTMLInputElement).value,
        "3.3": (document.getElementById("3.3") as HTMLInputElement).value,
        "3.4": (document.getElementById("3.4") as HTMLInputElement).value,
        "3.3.2": currencyRef.current.selectedValue.id,
      },
      experienceDetails: {
        area4_1: (
          document.querySelector("div#area4_1 .ql-editor") as HTMLElement
        ).innerHTML,
        area4_2: (
          document.querySelector("div#area4_2 .ql-editor") as HTMLElement
        ).innerHTML,
        area4_3: (
          document.querySelector("div#area4_3 .ql-editor") as HTMLElement
        ).innerHTML,
        area4_2_1: (
          document.querySelector("div#area4_2_1 .ql-editor") as HTMLElement
        ).innerHTML,
      },
      qaDetails: {
        "5.1": (document.getElementById("5.1") as HTMLInputElement).value,
        "5.2": (document.querySelector(
          'input[name="5.2"]:checked'
        ) as HTMLInputElement)
          ? (
              document.querySelector(
                'input[name="5.2"]:checked'
              ) as HTMLInputElement
            ).value
          : "",
        "5.3": (document.querySelector(
          'input[name="5.3"]:checked'
        ) as HTMLInputElement)
          ? (
              document.querySelector(
                'input[name="5.3"]:checked'
              ) as HTMLInputElement
            ).value
          : "",
        "5.4": (document.getElementById("5.4") as HTMLInputElement).value,
      },
      ehsDetails: {
        "6.1": (document.querySelector(
          'input[name="6.1"]:checked'
        ) as HTMLInputElement)
          ? (
              document.querySelector(
                'input[name="6.1"]:checked'
              ) as HTMLInputElement
            ).value
          : "",
        "6.2": (document.getElementById("6.2") as HTMLInputElement).value,
        "6.3": (document.getElementById("6.3") as HTMLInputElement).value,
      },
      equalitiesDetails: {
        "7.1": (document.querySelector(
          'input[name="7.1"]:checked'
        ) as HTMLInputElement)
          ? (
              document.querySelector(
                'input[name="7.1"]:checked'
              ) as HTMLInputElement
            ).value
          : "",
        "7.2": (document.getElementById("7.2") as HTMLInputElement).value,
      },
      sustainibilityDetails: {
        "8.1": (document.querySelector(
          'input[name="8.1"]:checked'
        ) as HTMLInputElement)
          ? (
              document.querySelector(
                'input[name="8.1"]:checked'
              ) as HTMLInputElement
            ).value
          : "",
        "8.2": (document.querySelector(
          'input[name="8.2"]:checked'
        ) as HTMLInputElement)
          ? (
              document.querySelector(
                'input[name="8.2"]:checked'
              ) as HTMLInputElement
            ).value
          : "",
        "8.3": (document.querySelector(
          'input[name="8.3"]:checked'
        ) as HTMLInputElement)
          ? (
              document.querySelector(
                'input[name="8.3"]:checked'
              ) as HTMLInputElement
            ).value
          : "",
        "8.4": (document.querySelector(
          'input[name="8.4"]:checked'
        ) as HTMLInputElement)
          ? (
              document.querySelector(
                'input[name="8.4"]:checked'
              ) as HTMLInputElement
            ).value
          : "",
      },
      files: files.length > 0 ? files[0].id : [],
    };
    console.log("managePreRender: ", args);
    API_HANDLER_HOME.managePreRender(args).then((res) => {
      if (res.status === 200) {
        COMMON_JS.showNotify(props, NOTIFY.SUCCESS, SUCCESS.PRE_RENDER_SAVED);
        setSelectedRow({});
        setManageDialog(false);
        getRows();
      } else {
        COMMON_JS.showNotify(props, NOTIFY.ERROR, res.error);
      }
    });
  };

  const contractorRef: any = useRef(null);
  let profileDetails = {};
  let financialDetails = {};
  let experienceDetails = {};
  let sustainibilityDetails = {};
  let equalitiesDetails = {};
  let qaDetails = {};
  let ehsDetails = {};
  if (selectedRow.id) {
    profileDetails = JSON.parse(selectedRow.profileDetails);
  }
  if (selectedRow.id) {
    financialDetails = JSON.parse(selectedRow.financialDetails);
  }
  if (selectedRow.id) {
    experienceDetails = JSON.parse(selectedRow.experienceDetails);
  }
  if (selectedRow.id) {
    sustainibilityDetails = JSON.parse(selectedRow.sustainibilityDetails);
  }
  if (selectedRow.id) {
    equalitiesDetails = JSON.parse(selectedRow.equalitiesDetails);
  }
  if (selectedRow.id) {
    qaDetails = JSON.parse(selectedRow.qaDetails);
  }
  if (selectedRow.id) {
    ehsDetails = JSON.parse(selectedRow.ehsDetails);
  }

  let empHtml = `
  <table>
  <tr>
  <td>Management</td><td></td>
  </tr>
  <tr>
  <td>Staff</td><td></td>
  </tr>
  <tr>
  <td>Admin</td><td></td>
  </tr>
  <tr>
  <td>Operative</td><td></td>
  </tr>
  <tr>
  <td>Supervisor</td><td></td>
  </tr>
  <tr>
  <td>Others</td><td></td>
  </tr>
  </table>
  `;
  const radioList = [
    { id: "Yes", label: "Yes" },
    { id: "No", label: "No" },
  ];
  const grid2Ref: any = useRef(null);
  const currencyRef: any = useRef(null);
  const curList = [
    {
      id: "USD - United States Dollar",
      label: "USD - United States Dollar",
    },
    {
      id: "EUR - Euro",
      label: "EUR - Euro",
    },
    {
      id: "CHf - Franc CHF",
      label: "CHf - Franc CHF",
    },
    {
      id: "INR - Indian Rupee",
      label: "INR - Indian Rupee",
    },
    {
      id: "JPY - Japanese Yen",
      label: "JPY - Japanese Yen",
    },
    {
      id: "GBP - British Pound Sterling",
      label: "GBP - British Pound Sterling",
    },
    {
      id: "CNY - Chinese Yuan Renminbi",
      label: "CNY - Chinese Yuan Renminbi",
    },
  ];

  const onUploadFile = (e: any, f: any) => {
    console.log("onUploadFile: ", e, f);
    const file: any = e.target.files[0];
    if (file.size / 1024 / 1024 > CONFIG.MAX_FILE_SIZE) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.FILE_SIZE_EXCEEDED);
      return;
    }
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let base64: any = reader.result;
      setUploading(true);
      uploadFile(file, base64).then((res) => {
        console.log("response", res);
        setUploading(false);
      });
    };
    reader.onerror = (error) => {
      console.log("uploadFile: Error:", error);
    };
  };

  const uploadFile = async (file: any, base64 = "") => {
    const args = {
      recordRefId: 1,
      file: {
        name: file.name,
        data: base64.replace("data:", "").replace(/^.+,/, ""),
        type: file.type,
        mimeType: file.name.split(".")[1],
        size: file.size,
      },
      category: "ehs",
    };
    let fileInfo = null;
    try {
      const res = await API_HANDLER_HOME.fileUpload(args);
      console.log("uploadFile: ", args, res);
      const response: any = res.response;
      if (res.status === 200) {
        fileInfo = response;
        setFiles([...files, fileInfo]);
      }
    } catch (error) {}
    return fileInfo;
  };

  //  TO DO for print

  // const onPrintTrip = (row: any) => {
  //   const args = {
  //     id: row.id,
  //   };
  //   ApiIndentHandler.getTripPrint(args)
  //     .then((res) => {
  //       if (res) {
  //         let base64 = "data:application/pdf;base64, " + res;
  //         console.log("base64:", base64);
  //         fetch(base64).then((r) => {
  //           r.blob().then((data) => {
  //             let objUrl = window.URL.createObjectURL(data);
  //             window.open(objUrl, "_blank");
  //             window.URL.revokeObjectURL(objUrl);
  //           });
  //         });
  //       } else {
  //         COMMON_JS.showNotify(
  //           props,
  //           NOTIFY.ERROR,
  //           "Print Pay Slip Not Found..!"
  //         );
  //       }
  //     })
  //     .catch((err) => {
  //       COMMON_JS.showNotify(
  //         props,
  //         NOTIFY.ERROR,
  //         "Print Pay Slip Not Found..!"
  //       );
  //     });
  // };

  console.log("Files: ", files);

  console.log("PreTender: ", selectedRow);

  return (
    <React.Fragment>
      <div className="pageInfo">
        <ul className="breadcrumbs">
          <li>{LABELS.HOME}</li>
          <li className="dim">{" / "}</li>
          <li className="active">{LABELS.PRE_TENDER}</li>
        </ul>
        <h4>{LABELS.PRE_TENDER}</h4>
      </div>

      {/* Graphs */}
      {pieGraph.series && (
        <div className="graphs">
          <HGraph
            width={"50%"}
            type={"column"}
            xAxis={columnGraph.xAxis}
            yAxis={columnGraph.yAxis}
            title={" "}
            exportTitle={" "}
          />
          <HGraph
            width={"50%"}
            type={"pie"}
            float={"right"}
            series={pieGraph.series}
            title={" "}
            exportTitle={" "}
          />
        </div>
      )}

      {rows ? (
        <ExcelGrid
          id={"preTender"}
          height={window.innerHeight * 0.8}
          cols={cols}
          rows={rows}
          exportOption={true}
          newRowOption={false}
          exportName={"pretender"}
          onAdd={() => {
            setManageDialog(!manageDialog);
            setSelectedRow({});
            setFiles([]);
          }}
        />
      ) : (
        <div className="pageCenter">
          <Loader />
        </div>
      )}

      {manageDialog && (
        <UiDialog
          title={
            selectedRow.id
              ? "Edit Pre-Tender: " + selectedRow.contractorNme
              : "Add New Pre-Tender"
          }
          open={true}
          size={"xl"}
          onClose={() => {
            setManageDialog(false);
          }}
        >
          {/* <div className="formRow">
            <DocView file={pdfFile} />
          </div> */}

          <div className="formRow">
            <div className="formGroup" style={{ width: "30%" }}>
              <label className="red">{"Contractor Name *"}</label>
              <AutoComplete
                list={contractorList}
                childRef={contractorRef}
                defaultValue={
                  selectedRow.id
                    ? {
                        id: selectedRow.contractorId,
                        label: selectedRow.contractorNme,
                      }
                    : null
                }
              />
            </div>
            <div className="formGroup" style={{ width: "30%" }}>
              <label className="red">{"Date of Application *"}</label>
              <TxtBox
                type={"date"}
                id="applicationDt"
                defaultValue={
                  selectedRow.id ? profileDetails["applicationDt"] : ""
                }
              />
            </div>
            <div className="formGroup" style={{ width: "30%" }}></div>
          </div>

          {/* <h4 className="ehsHeading1">{dataJson["1"]["heading1"]}</h4> */}
          <ExcelGrid
            childRef={grid2Ref}
            cols={cols2}
            rows={dataJson["1"]["1.1"]}
            width={"66%"}
          />

          <h4 className="ehsHeading1 borderBottom">
            {"Organizational Profile"}
          </h4>
          <div className="formRow">
            <div className="formGroup" style={{ width: "45%" }}>
              <label>
                {"2.1	Name of the Organization making the Application"}
              </label>
              <TxtBox
                type={"text"}
                id="2.1"
                defaultValue={selectedRow.id ? profileDetails["2.1"] : ""}
              />
            </div>
            <div className="formGroup" style={{ width: "45%" }}>
              <label>
                {"2.2	Contact for Equiries about this expression of Interest"}
              </label>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div className="formGroup" style={{ width: "45%" }}>
                  <label>{"Name"}</label>
                  <TxtBox
                    type={"text"}
                    id="2.2.1"
                    defaultValue={selectedRow.id ? profileDetails["2.2.1"] : ""}
                  />
                </div>
                <div className="formGroup" style={{ width: "45%" }}>
                  <label>{"Position"}</label>
                  <TxtBox
                    type={"text"}
                    id="2.2.2"
                    defaultValue={selectedRow.id ? profileDetails["2.2.2"] : ""}
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div className="formGroup" style={{ width: "45%" }}>
                  <label>{"Telephone"}</label>
                  <TxtBox
                    type={"text"}
                    id="2.2.3"
                    defaultValue={selectedRow.id ? profileDetails["2.2.3"] : ""}
                  />
                </div>
                <div className="formGroup" style={{ width: "45%" }}>
                  <label>{"Email"}</label>
                  <TxtBox
                    type={"text"}
                    id="2.2.4"
                    defaultValue={selectedRow.id ? profileDetails["2.2.4"] : ""}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="formRow">
            <div className="formGroup" style={{ width: "45%" }}>
              <label>{"2.3 Company Registered address"}</label>
              <TxtBox
                type={"text"}
                id="2.3"
                defaultValue={selectedRow.id ? profileDetails["2.3"] : ""}
                rows={3}
              />
            </div>
            <div className="formGroup" style={{ width: "45%" }}>
              <label>
                {
                  "2.4	Please provide a statement confirming that there are no grounds applicable to to the organization pursuant to which it could be rejected by the council under regulation 23 (4)"
                }
              </label>
              <TxtBox
                type={"text"}
                id="2.4"
                defaultValue={selectedRow.id ? profileDetails["2.4"] : ""}
                rows={3}
              />
            </div>
          </div>

          <h4 className="ehsHeading1 borderBottom">
            {"Financial Information"}
          </h4>
          <div className="formRow">
            <div className="formGroup" style={{ width: "45%" }}>
              <label>
                {
                  "3.1 Who is the person in the Organization responsible for financial matters?"
                }
              </label>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div className="formGroup" style={{ width: "45%" }}>
                  <label>{"Name"}</label>
                  <TxtBox
                    type={"text"}
                    id="3.1.1"
                    defaultValue={
                      selectedRow.id ? financialDetails["3.1.1"] : ""
                    }
                  />
                </div>
                <div className="formGroup" style={{ width: "45%" }}>
                  <label>{"Position"}</label>
                  <TxtBox
                    type={"text"}
                    id="3.1.2"
                    defaultValue={
                      selectedRow.id ? financialDetails["3.1.2"] : ""
                    }
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div className="formGroup" style={{ width: "45%" }}>
                  <label>{"Telephone"}</label>
                  <TxtBox
                    type={"text"}
                    id="3.1.3"
                    defaultValue={
                      selectedRow.id ? financialDetails["3.1.3"] : ""
                    }
                  />
                </div>
                <div className="formGroup" style={{ width: "45%" }}>
                  <label>{"Email"}</label>
                  <TxtBox
                    type={"text"}
                    id="3.1.4"
                    defaultValue={
                      selectedRow.id ? financialDetails["3.1.4"] : ""
                    }
                  />
                </div>
              </div>
            </div>
            <div className="formGroup" style={{ width: "45%" }}>
              <label>
                {
                  "3.2	Please provide on headed paper, details of your principle banker, and authorisation of the council to take up reference from your bank"
                }
              </label>
              <TxtBox
                type={"text"}
                id="3.2"
                defaultValue={selectedRow.id ? financialDetails["3.2"] : ""}
              />
            </div>
          </div>
          <div className="formRow">
            <div className="formGroup" style={{ width: "45%" }}>
              <label>{"3.3	What is your turnover in the last 3 years"}</label>
              <div style={{ display: "flex", flexDirection: "row" }}></div>
              <TxtBox
                type={"text"}
                id="3.3"
                defaultValue={selectedRow.id ? financialDetails["3.3"] : ""}
              />
              <AutoComplete
                list={curList}
                childRef={currencyRef}
                defaultValue={
                  selectedRow.id
                    ? {
                        id: financialDetails["3.3.2"],
                        label: financialDetails["3.3.2"],
                      }
                    : curList[0]
                }
              />
            </div>
            <div className="formGroup" style={{ width: "45%" }}>
              <label>{"3.4	Please state your VAT registration number"}</label>
              <TxtBox
                type={"text"}
                id="3.4"
                defaultValue={selectedRow.id ? financialDetails["3.4"] : ""}
              />
            </div>
          </div>

          <h4 className="ehsHeading1">
            {"4. Relevant Experience and Technical Expertise"}
          </h4>
          <div className="formRow">
            <div className="formGroup" style={{ width: "45%" }}>
              <label>
                {
                  "4.1 Please provide details of three recent contracts that are relevant to the Area's requirement."
                }
              </label>
              <div style={{ marginBottom: 10, marginTop: 5 }}>
                <label>{"Reference 1"}</label>
                <RichTxtBox
                  id={"area4_1"}
                  defaultValue={experienceDetails["area4_1"]}
                />
              </div>
              <div style={{ marginBottom: 10, marginTop: 5 }}>
                <label>{"Reference 2"}</label>
                <RichTxtBox
                  id={"area4_2"}
                  defaultValue={experienceDetails["area4_2"]}
                />
              </div>
              <div>
                <label>{"Reference 3"}</label>
                <RichTxtBox
                  id={"area4_3"}
                  defaultValue={experienceDetails["area4_3"]}
                />
              </div>
            </div>
            <div className="formGroup" style={{ width: "45%" }}>
              <label>
                {
                  "4.2	State the approximate number of employees engaged in your company"
                }
              </label>
              <div style={{ marginTop: 5 }}>
                <RichTxtBox
                  id={"area4_2_1"}
                  defaultValue={
                    selectedRow.id ? experienceDetails["area4_2_1"] : empHtml
                  }
                />
              </div>
            </div>
          </div>

          <h4 className="ehsHeading1 borderBottom">{"5. Quality Assurance"}</h4>
          <div className="formRow">
            <div className="formGroup" style={{ width: "45%" }}>
              <label>
                {
                  "5.1 Does your organisation hold a recognised quality management certification, for example BS/EN/ISO 9000 or equivalent, or a quality management system ?"
                }
              </label>
              <TxtBox
                type={"text"}
                id="5.1"
                defaultValue={selectedRow.id ? qaDetails["5.1"] : ""}
              />
            </div>
            <div className="formGroup" style={{ width: "45%" }}>
              <label>
                {
                  "5.2 Does your organisation have a written health and safety at work policy? Explain"
                }
              </label>
              <RadioBtn
                row={true}
                list={radioList}
                name={"5.2"}
                defaultValue={selectedRow.id ? qaDetails["5.2"] : ""}
              />
            </div>
          </div>
          <div className="formRow">
            <div className="formGroup" style={{ width: "45%" }}>
              <label>
                {
                  "5.3	Does your organisation have a health and safety at work system? Expalain"
                }
              </label>
              <RadioBtn
                row={true}
                list={radioList}
                name={"5.3"}
                defaultValue={selectedRow.id ? qaDetails["5.3"] : ""}
              />
            </div>
            <div className="formGroup" style={{ width: "45%" }}>
              <label>
                {
                  "5.4	Has your company, or any individual director, partner or proprietor been prosecuted under health and safety and related legislation or had enforcement action taken against it/them by the enforcing authorities (this includes the issuing of a prohibition or improvement notice)"
                }
              </label>
              <TxtBox
                type={"text"}
                id="5.4"
                defaultValue={selectedRow.id ? qaDetails["5.4"] : ""}
              />
            </div>
          </div>

          <h4 className="ehsHeading1 borderBottom">{"6. Health and Safety"}</h4>
          <div className="formRow">
            <div className="formGroup" style={{ width: "45%" }}>
              <label>
                {"6.1	Does your company have an HSE policy document?"}
              </label>
              <RadioBtn
                row={true}
                list={radioList}
                name={"6.1"}
                defaultValue={selectedRow.id ? ehsDetails["6.1"] : ""}
              />
            </div>
            <div className="formGroup" style={{ width: "45%" }}>
              <label>
                {
                  "6.2	Who is overall and final responsibility for HSE in your organization?"
                }
              </label>
              <TxtBox
                type={"text"}
                id="6.2"
                defaultValue={selectedRow.id ? ehsDetails["6.2"] : ""}
              />
            </div>
          </div>
          <div className="formRow">
            <div className="formGroup" style={{ width: "45%" }}>
              <label>
                {
                  "6.3	How is management involved in HSE Activities, objective-setting and monitoring?"
                }
              </label>
              <TxtBox
                type={"text"}
                id="6.3"
                defaultValue={selectedRow.id ? ehsDetails["6.3"] : ""}
              />
            </div>
            <div className="formGroup" style={{ width: "45%" }}></div>
          </div>

          <h4 className="ehsHeading1 borderBottom">
            {"7. Equalities and Diversity"}
          </h4>
          <div className="formRow">
            <div className="formGroup" style={{ width: "45%" }}>
              <label>
                {
                  "7.1	Do you have a written Equalities, Equal Opportunities or Equality and Diversity Policy which complies with your legal obligations? "
                }
              </label>
              <RadioBtn
                row={true}
                list={radioList}
                name={"7.1"}
                defaultValue={selectedRow.id ? equalitiesDetails["7.1"] : ""}
              />
            </div>
            <div className="formGroup" style={{ width: "45%" }}>
              <label>
                {
                  "7.2	 Are your staff with managerial responsibilities required to receive equalities training?  If yes, please provide brief details of such training."
                }
              </label>
              <TxtBox
                type={"text"}
                id="7.2"
                defaultValue={selectedRow.id ? equalitiesDetails["7.2"] : ""}
              />
            </div>
          </div>

          <h4 className="ehsHeading1 borderBottom">{"8. Sustainability"}</h4>
          <div className="formRow">
            <div className="formGroup" style={{ width: "45%" }}>
              <label>
                {"8.1	Does your organisation have a business continuity plan?"}
              </label>
              <RadioBtn
                row={true}
                list={radioList}
                name={"8.1"}
                defaultValue={
                  selectedRow.id ? sustainibilityDetails["8.1"] : ""
                }
              />
            </div>
            <div className="formGroup" style={{ width: "45%" }}>
              <label>
                {
                  "8.2	Is your organisation compliant/accredited to BS 25999-1:2006 and BS 25999-2007?"
                }
              </label>
              <RadioBtn
                row={true}
                list={radioList}
                name={"8.2"}
                defaultValue={
                  selectedRow.id ? sustainibilityDetails["8.2"] : ""
                }
              />
            </div>
          </div>
          <div className="formRow">
            <div className="formGroup" style={{ width: "45%" }}>
              <label>
                {
                  "8.3	Has your organisation identified your critical contractors / suppliers?"
                }
              </label>
              <RadioBtn
                row={true}
                list={radioList}
                name={"8.3"}
                defaultValue={
                  selectedRow.id ? sustainibilityDetails["8.3"] : ""
                }
              />
            </div>
            <div className="formGroup" style={{ width: "45%" }}>
              <label>
                {
                  "8.4 Do these critical contractors / suppliers have business continuity plans in place?"
                }
              </label>
              <RadioBtn
                row={true}
                list={radioList}
                name={"8.4"}
                defaultValue={
                  selectedRow.id ? sustainibilityDetails["8.4"] : ""
                }
              />
            </div>
          </div>
          <h4 className="ehsHeading1 borderBottom">{"File Upload"}</h4>
          <div className="formRow">
            <div className="formGroup" style={{ width: "45%" }}>
              <label>
                {LABELS.FILE_UPLOAD}
                <small>
                  {" ( Max File Size: " + CONFIG.MAX_FILE_SIZE + " MB )"}
                </small>
              </label>
              <TxtBox
                type={"file"}
                id="uploadFile"
                // defaultValue={selectedRow.id ? selectedRow.fuelFiles : null}
                className="uploadFile"
                accept="image/*,.pdf"
                onChange={onUploadFile}
              />
              <div>
                {!files ? (
                  <div className="thumbnailBox width20">
                    <SkeletonBox height={300} />
                  </div>
                ) : (
                  files.map((u, i) => {
                    return (
                      <div className="thumbnailBox width20">
                        <IconButton
                          className="thumbnailBoxIcon"
                          onClick={() => {
                            const list = files.filter((a, j) => j !== i);
                            setFiles(list);
                          }}
                        >
                          <IconClose />
                        </IconButton>
                        <img
                          onClick={() =>
                            window.open(API_CONFIG.API_URL + u.path, "_blank")
                          }
                          src={
                            u.mimeType === "pdf"
                              ? thumbnailImg
                              : API_CONFIG.API_URL + u.path
                          }
                          className="responsive cursor"
                        />
                      </div>
                    );
                  })
                )}
              </div>
            </div>
          </div>

          <ModalBtmBtns
            onSave={managePretender}
            onCancel={() => setManageDialog(false)}
          />
        </UiDialog>
      )}
    </React.Fragment>
  );
};

export default withSnackbar(PreTender);
