import React, { useState } from "react";
import { MenuList, MenuItem } from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";

import Btn from "components/Custom/Btn";
import ResourceHistogram from "./ResourceHistogram";
import ManHoursDistribution from "./ManHoursDistribution";
import ProjectProgressStatus from "./ProjectProgressStatus";
import ProjectOverView from "./ProjectOverView";

import ResourceTable from "./ResourceTable";
import ProgressCurve from "./ProgressCurve";
import ProjectCashFlow from "./ProjectCashFlow";

// Reports
import ReportEarnedValue from "./ReportEarnedValue";
import ReportQuantities from "./ReportQuantities";
import ReportManHours from "./ReportManHours";
import ReportCost from "./ReportCost";
// import ReportConstraintLog from "./ReportConstraintLog";
import ReportConsolidated from "./ReportConsolidated";
import ReportUserLog from "./ReportUserLog";
import ReportTimeSheetLog from "./ReportTimeSheetLog";
import ReportApprovalLog from "./ReportApprovalLog";

import { CONFIG, LABELS } from "common/config";

import "./dashboard.css";
import "./styles/reports.css";
import "./styles/datatables.min.css";
import "devextreme/dist/css/dx.light.css";

const chart1 = require("assets/images/reports/chart1.png");
const chartResourceHistogram = require("assets/images/reports/Resource_Histogram.png");
const chartResourceTable = require("assets/images/reports/Resource_Table.png");
const chartManHoursPlan = require("assets/images/reports/Man_Hours_Distribution.png");
const chartProgressCurve = require("assets/images/reports/Progress_S_Curve.png");
const chartProjectCashFlow = require("assets/images/reports/Project_Cashflow.png");
const chartProgressStatus = require("assets/images/reports/Project_Progress_Pie.png");
const chartProjectOverview = require("assets/images/reports/Project_Overview.png");
// const chartRisksRegister = require("assets/images/reports/Risk_Register.png");
const chartEarnedValue = require("assets/images/reports/Earned_Value_Report.png");
const chartQuantitiesReport = require("assets/images/reports/Quantities_Report.png");
const chartManhoursReport = require("assets/images/reports/Manhours_Report.png");
const chartCostReport = require("assets/images/reports/Cost_Report.png");
const chartConstraintLogReport = require("assets/images/reports/Constraint_Log_Report.png");
const chartTimeSheetLog = require("assets/images/reports/Time_Sheet_Log_Report.png");
const chartApprovalLog = require("assets/images/reports/Approval_Log_Report.png");
const chartUserLog = require("assets/images/reports/User_Log_Report.png");

const Dashboard = (props: any = {}) => {
  const [dashMenu, setDashMenu] = useState(LABELS.HOME);
  const { childRef, userInfo } = props;
  const setMenu = () => {
    setDashMenu(LABELS.HOME);
  };
  childRef.setMenu = setMenu;

  let menuUserLog = false;
  let consolidatedReport = false;
  if (userInfo.role.id === CONFIG.USER_ROLES.Admin) {
    menuUserLog = true;
    consolidatedReport = true;
  }

  return (
    <React.Fragment>
      {dashMenu == LABELS.HOME && (
        <div className="userDashboard">
          <div className="pageInfo">
            <ul className="breadcrumbs">
              <li>{LABELS.HOME}</li>
              <li className="dim">{" / "}</li>
              <li className="active">{LABELS.DASHBOARD}</li>
            </ul>
            <h4>{LABELS.DASHBOARD}</h4>
          </div>
          <MenuList dense className="dashboardMenuWrap">
            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.RESOURCE_HISTOGRAM);
              }}
            >
              <img src={chartResourceHistogram} />
              <h4>{LABELS.RESOURCE_HISTOGRAM}</h4>
            </MenuItem>
            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.RESOURCE_TABLE);
              }}
            >
              <img src={chartResourceTable} />
              <h4>{LABELS.RESOURCE_TABLE}</h4>
            </MenuItem>

            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.MAN_HOURS_DISTRIBUTION);
              }}
            >
              <img src={chartManHoursPlan} />
              <h4>{LABELS.MAN_HOURS_DISTRIBUTION}</h4>
            </MenuItem>
            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.PROGRESS_CURVE);
              }}
            >
              <img src={chartProgressCurve} />
              <h4>{LABELS.PROGRESS_CURVE}</h4>
            </MenuItem>
            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.PROJECT_CASH_FLOW);
              }}
            >
              <img src={chartProjectCashFlow} />
              <h4>{LABELS.PROJECT_CASH_FLOW}</h4>
            </MenuItem>
            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.PROJECT_PROGRESS_STATUS);
              }}
            >
              <img src={chartProgressStatus} />
              <h4>{LABELS.PROJECT_PROGRESS_STATUS}</h4>
            </MenuItem>
            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.PROJECT_OVERVIEW);
              }}
            >
              <img src={chartProjectOverview} />
              <h4>{LABELS.PROJECT_OVERVIEW}</h4>
            </MenuItem>
            {consolidatedReport && (
              <MenuItem
                className="dashboardMenu"
                onClick={() => {
                  setDashMenu(LABELS.CONSOLIDATED_REPORT);
                }}
              >
                <img src={chartProjectOverview} />
                <h4>{LABELS.CONSOLIDATED_REPORT}</h4>
              </MenuItem>
            )}
            {/* <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.RISKS_REGISTER);
              }}
            >
              <img src={chartRisksRegister} />
              <h4>{LABELS.RISKS_REGISTER}</h4>
            </MenuItem> */}
          </MenuList>

          <MenuList dense className="dashboardMenuWrap">
            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.EARNED_VALUE_REPORT);
              }}
            >
              <img src={chartEarnedValue} />
              <h4>{LABELS.EARNED_VALUE_REPORT}</h4>
            </MenuItem>
            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.QTY_REPORT);
              }}
            >
              <img src={chartQuantitiesReport} />
              <h4>{LABELS.QTY_REPORT}</h4>
            </MenuItem>
            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.MAN_HOURS_REPORT);
              }}
            >
              <img src={chartManhoursReport} />
              <h4>{LABELS.MAN_HOURS_REPORT}</h4>
            </MenuItem>

            <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.COST_REPORT);
              }}
            >
              <img src={chartCostReport} />
              <h4>{LABELS.COST_REPORT}</h4>
            </MenuItem>
            {/* <MenuItem
              className="dashboardMenu"
              onClick={() => {
                setDashMenu(LABELS.CONSTRAINT_LOG_REPORT);
              }}
            >
              <img src={chartConstraintLogReport} />
              <h4>{LABELS.CONSTRAINT_LOG_REPORT}</h4>
            </MenuItem> */}
            {menuUserLog && (
              <MenuItem
                className="dashboardMenu"
                onClick={() => {
                  setDashMenu(LABELS.USER_LOG_REPORT);
                }}
              >
                <img src={chartUserLog} />
                <h4>{LABELS.USER_LOG_REPORT}</h4>
              </MenuItem>
            )}
            {menuUserLog && (
              <MenuItem
                className="dashboardMenu"
                onClick={() => {
                  setDashMenu(LABELS.TIME_SHEET_LOG_REPORT);
                }}
              >
                <img src={chartTimeSheetLog} />
                <h4>{LABELS.TIME_SHEET_LOG_REPORT}</h4>
              </MenuItem>
            )}
            {menuUserLog && (
              <MenuItem
                className="dashboardMenu"
                onClick={() => {
                  setDashMenu(LABELS.APPROVAL_LOG_REPORT);
                }}
              >
                <img src={chartApprovalLog} />
                <h4>{LABELS.APPROVAL_LOG_REPORT}</h4>
              </MenuItem>
            )}
          </MenuList>
        </div>
      )}

      {dashMenu == LABELS.RESOURCE_HISTOGRAM && (
        <ResourceHistogram setDashMenu={setDashMenu} />
      )}

      {dashMenu == LABELS.RESOURCE_TABLE && (
        <ResourceTable setDashMenu={setDashMenu} />
      )}

      {dashMenu == LABELS.MAN_HOURS_DISTRIBUTION && (
        <ManHoursDistribution setDashMenu={setDashMenu} />
      )}

      {dashMenu == LABELS.PROGRESS_CURVE && (
        <ProgressCurve setDashMenu={setDashMenu} />
      )}

      {dashMenu == LABELS.PROJECT_CASH_FLOW && (
        <ProjectCashFlow setDashMenu={setDashMenu} />
      )}

      {dashMenu == LABELS.PROJECT_PROGRESS_STATUS && (
        <ProjectProgressStatus setDashMenu={setDashMenu} />
      )}

      {dashMenu == LABELS.PROJECT_OVERVIEW && (
        <ProjectOverView setDashMenu={setDashMenu} />
      )}

      {dashMenu == LABELS.EARNED_VALUE_REPORT && (
        <ReportEarnedValue setDashMenu={setDashMenu} />
      )}

      {dashMenu == LABELS.QTY_REPORT && (
        <ReportQuantities setDashMenu={setDashMenu} />
      )}

      {dashMenu == LABELS.MAN_HOURS_REPORT && (
        <ReportManHours setDashMenu={setDashMenu} />
      )}

      {dashMenu == LABELS.COST_REPORT && (
        <ReportCost setDashMenu={setDashMenu} />
      )}

      {dashMenu == LABELS.CONSOLIDATED_REPORT && (
        <ReportConsolidated setDashMenu={setDashMenu} />
      )}

      {dashMenu == LABELS.USER_LOG_REPORT && (
        <ReportUserLog setDashMenu={setDashMenu} />
      )}

      {dashMenu == LABELS.TIME_SHEET_LOG_REPORT && (
        <ReportTimeSheetLog setDashMenu={setDashMenu} />
      )}

      {dashMenu == LABELS.APPROVAL_LOG_REPORT && (
        <ReportApprovalLog setDashMenu={setDashMenu} />
      )}

      {/* {dashMenu == LABELS.RISKS_REGISTER && (
        <RisksRegister setDashMenu={setDashMenu} />
      )} */}
    </React.Fragment>
  );
};

export default Dashboard;
