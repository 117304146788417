import { IApiResponse } from "common/models";
import { API_CONFIG } from "common/config";
import { COMMON_JS } from "common/scripts";

import { IArgsPropHome, IArgsFileUpload } from "./models";

interface IProperty {
  getUsers: (args: IArgsPropHome) => Promise<IApiResponse>;
  getOrganizations: (args: IArgsPropHome) => Promise<IApiResponse>;
  getProjects: (args: IArgsPropHome) => Promise<IApiResponse>;
  fileUpload: (args: IArgsFileUpload) => Promise<IApiResponse>;
  getChartData: (args: any) => Promise<IApiResponse>;
}

export class ApiHome implements IProperty {
  manageOrgFile = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/org/file",
        API_CONFIG.METHODS.POST,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };
  getOrgFiles = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/org/files",
        API_CONFIG.METHODS.GET,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };
  manageOrgFolder = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/org/folder",
        API_CONFIG.METHODS.POST,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };
  getOrgFolders = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/org/folders",
        API_CONFIG.METHODS.GET,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };

  getPreRenders = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/audit/pretenders",
        API_CONFIG.METHODS.GET,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };

  managePreRender = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/audit/pretender",
        API_CONFIG.METHODS.POST,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };

  downloadHistory = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/project/historydownload",
        API_CONFIG.METHODS.GET,
        args,
        true
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };

  getProjectHistory = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/project/history",
        API_CONFIG.METHODS.GET,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };

  manageFile = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/file/delete",
        API_CONFIG.METHODS.POST,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };

  getRfiFiles = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/project/rfifiles",
        API_CONFIG.METHODS.GET,
        args,
        true
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };

  getProjectFolders = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/project/folders",
        API_CONFIG.METHODS.GET,
        args,
        true
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };

  manageFolder = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/project/folder",
        API_CONFIG.METHODS.POST,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };

  getProjectLessons = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/project/lessons",
        API_CONFIG.METHODS.GET,
        args,
        true
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };

  manageProjectLesson = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/project/lesson",
        API_CONFIG.METHODS.POST,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };

  getRfiResponses = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/project/rfiresponses",
        API_CONFIG.METHODS.GET,
        args,
        true
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };

  manageRfiResponse = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/project/managerfiresponse",
        API_CONFIG.METHODS.POST,
        args,
        true
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };

  getRfiList = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/project/rfilist",
        API_CONFIG.METHODS.GET,
        args,
        true
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };

  manageRfi = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/project/managerfi",
        API_CONFIG.METHODS.POST,
        args,
        true
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };

  manageConstraint = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/project/manage-constraint",
        API_CONFIG.METHODS.POST,
        args,
        true
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };

  getProjectLastUpdateInfo = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/project/lastupdateinfo",
        API_CONFIG.METHODS.GET,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };

  getProjectFiles = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/project/files",
        API_CONFIG.METHODS.GET,
        args,
        true
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };

  getApprovePendings = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/project/approvependings",
        API_CONFIG.METHODS.GET,
        args,
        true
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };

  approveFile = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/project/approve",
        API_CONFIG.METHODS.POST,
        args,
        true
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };

  approveConstraints = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/project/approveconstraints",
        API_CONFIG.METHODS.POST,
        args,
        true
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };

  getProjectFile = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/account/projectfile",
        API_CONFIG.METHODS.GET,
        args,
        true
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };

  getUserTypes = async (args: IArgsPropHome): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/usertypes",
        API_CONFIG.METHODS.GET,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };

  getChartData = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/chart",
        API_CONFIG.METHODS.GET,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };

  fileUpload = async (args: IArgsFileUpload): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/upload",
        API_CONFIG.METHODS.POST,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };

  getProjects = async (args: IArgsPropHome): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/projects",
        API_CONFIG.METHODS.GET,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };

  getMainTasks = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/project/maintasks",
        API_CONFIG.METHODS.GET,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };

  getOrganizations = async (args: IArgsPropHome): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/org-list",
        API_CONFIG.METHODS.GET,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };

  getUsers = async (args: IArgsPropHome): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/users",
        API_CONFIG.METHODS.GET,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };

  getFilePath = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/filepath",
        API_CONFIG.METHODS.GET,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };

  getFile = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/file",
        API_CONFIG.METHODS.GET,
        args,
        true
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };

  manageOrganization = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/org",
        API_CONFIG.METHODS.POST,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };

  manageProject = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/project",
        API_CONFIG.METHODS.POST,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };

  manageUser = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/user",
        API_CONFIG.METHODS.POST,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };

  getChartInfo = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/chartinfo",
        API_CONFIG.METHODS.GET,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };

  editChart = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/edit-chart",
        API_CONFIG.METHODS.POST,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };

  getCriticalPaths = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/criticalpaths",
        API_CONFIG.METHODS.GET,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };
}
