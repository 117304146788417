import React, { useEffect, useRef, useState } from "react";
import { withSnackbar } from "notistack";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highchartsGantt from "highcharts/modules/gantt";
import highchartsPattern from "highcharts/modules/pattern-fill";
import highchartsExport from "highcharts/modules/exporting";
import highchartsAcc from "highcharts/modules/accessibility";
import highchartsStock from "highcharts/modules/stock";
import { connect } from "react-redux";
import { GetApp as IconExport } from "@mui/icons-material";
import { IconButton, Pagination, Tooltip } from "@mui/material";

import Loader from "components/Custom/Loader";
import AutoComplete from "components/Custom/AutoComplete";
import Btn from "components/Custom/Btn";
import ChkBox from "components/Custom/ChkBox";
import EditChart from "./EditChart";

import {
  COLORS,
  CONFIG,
  LABELS,
  LOCAL_STORAGE,
  NOTIFY,
  WARNINGS,
} from "common/config";
import { COMMON_JS } from "common/scripts";
import { ApiHome } from "apihandlers/home";
import { CHART_ACTIONS } from "actions/ChartActions";

import "./styles/chart.css";

const API_HANDLER_HOME = new ApiHome();

highchartsGantt(Highcharts);
highchartsAcc(Highcharts);
highchartsStock(Highcharts);
highchartsPattern(Highcharts);
highchartsExport(Highcharts);

const hOptions: any = {
  global: {
    useUTC: false,
  },
};
Highcharts.setOptions(hOptions);

const fixedRows = 20; // Change Gantt Chart Rows Here

const GanttChartPage = (props: any) => {
  const [pageNo, setPageNo] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [updateDate, setUpdateDate] = useState("");
  const [loading, setLoading] = useState(true);
  const [chartSeries, setChartSeries] = useState([]);
  const [orgs, setOrgs] = useState([]);
  const [projects, setProjects] = useState([]);
  const [criticalPaths, setCriticalPaths] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [chartType, setChartType] = useState("treeview");
  const [disableExport, setDisableExport] = useState(true);
  const [pageSizes, setPageSizes] = useState([
    {
      id: "a3",
      label: "Page Size A3",
      size: 1410,
    },
    {
      id: "a2",
      label: "Page Size A2",
      size: 1990,
    },
    {
      id: "a1",
      label: "Page Size A1",
      size: 2815,
    },
  ]);

  let chartRef: any = useRef();

  useEffect(() => {
    if (loading) {
      getProjects();
    }
  }, [loading]);

  const getProjects = () => {
    // const orgId = orgRef.current.selectedValue.id;
    const args = { dropdown: true };
    API_HANDLER_HOME.getProjects(args).then((res) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        setProjects(response.records);
        setLoading(false);
      }
    });
  };

  const getCriticalPaths = () => {
    const projectId = projectRef.current.selectedValue.id;
    const args = { dropdown: true, projectId };
    setCriticalPaths(null);
    API_HANDLER_HOME.getMainTasks(args).then((res) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        setCriticalPaths(response.records);
        setLoading(false);
        setChartSeries([]);
      }
    });
    API_HANDLER_HOME.getProjectLastUpdateInfo({
      projectId,
    }).then((res: any) => {
      if (res.status === 200) {
        setUpdateDate(res.response.updateDate);
      }
    });
  };

  const getChartData = (type = "treeview", pageNumber = 1) => {
    const projectId = projectRef.current.selectedValue.id;
    let wbs = criticalPathsRef.current.selectedValue.id;
    if (!projectId) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.PROJECT_EMPTY);
      return;
    }
    if (type === "treeview") {
      if (!wbs) {
        COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.TASK_EMPTY);
        return;
      }
    }
    let freezeHeader = true;
    let fixHeaderChk = document.querySelector(
      'input[id="freezeHeader"]'
    ) as HTMLInputElement;
    if (fixHeaderChk) {
      freezeHeader = fixHeaderChk.checked;
    }
    setChartType(type);
    const args = {
      projectId,
      wbs,
      type,
      pageNo: pageNumber,
      pageLength: fixedRows,
      freezeHeadings: freezeHeader,
    };
    setChartSeries(null);
    API_HANDLER_HOME.getChartData(args).then((res) => {
      const response: any = res.response;
      const { status, error } = res;
      if (status === 200) {
        setChartSeries(response.series);
        setTotalRows(response.totalRows);
        let freezeHeader = true;
        let fixHeaderChk = document.querySelector(
          'input[id="freezeHeader"]'
        ) as HTMLInputElement;
        if (fixHeaderChk) {
          freezeHeader = fixHeaderChk.checked;
        }
        setDisableExport(freezeHeader);
      } else {
        setChartSeries([]);
        COMMON_JS.showNotify(props, NOTIFY.ERROR, error);
      }
    });
  };

  const onEditChart = (point: any = {}) => {
    if (point.series.userOptions.highlight) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        "Sorry, You can't edit this..!"
      );
      return;
    }
    if (
      props.userInfo.role.id === CONFIG.USER_ROLES.ProjectManager2 ||
      props.userInfo.role.id === CONFIG.USER_ROLES.Supervisor ||
      props.userInfo.role.id === CONFIG.USER_ROLES.Director
    ) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        "Sorry, You Don't have the privilege to edit..!"
      );
      return;
    }
    const projectId = projectRef.current.selectedValue.id;
    if (!projectId) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.PROJECT_EMPTY);
      return;
    }
    const args = { projectId, wbs: point.id };
    API_HANDLER_HOME.getChartInfo(args).then((res) => {
      console.log("getChartInfo: ", res);
      if (res.status === 200) {
        props.setChartInfo({ chartInfo: res.response });
      }
    });
  };

  const exportHighchart = () => {
    let projectName = "";
    if (projectRef.current) {
      projectName = projectRef.current.selectedValue.label;
    }
    const title = LABELS.GANTT_CHART + " - " + projectName;
    let header = "<html>";
    header +=
      "<head><title>" +
      title +
      "</title><style>@page{margin-top: 10;size: A3 landscape;}</style></head>";
    let body =
      "<body style='border:1px solid #ccc;'><h2 style='text-align: center;'>" +
      title +
      "</h2>";
    body += chartRef.current.container.current.innerHTML;
    console.log("body: ", body);
    body += "</html>";
    const html = header + body;
    const printWin = window.open("", "", "toolbar=0,scrollbars=0,status=0");
    printWin.document.write(html);
    printWin.document.close();
    printWin.focus();
    printWin.print();
    printWin.close();
  };

  let userInfo: any = localStorage.getItem(LOCAL_STORAGE.REMEMBER_ME);
  if (userInfo) {
    userInfo = JSON.parse(userInfo);
  }

  const projectRef: any = useRef();
  const criticalPathsRef: any = useRef();
  const pageSizesRef: any = useRef(null);

  let projectName = "";
  if (projectRef.current) {
    projectName = projectRef.current.selectedValue.label;
  }
  const chartTitle = "";

  let freezeHeader = true;
  let fixHeaderChk = document.querySelector(
    'input[id="freezeHeader"]'
  ) as HTMLInputElement;
  if (fixHeaderChk) {
    freezeHeader = fixHeaderChk.checked;
  }

  const dateFormat = Highcharts.dateFormat;
  const isDefined = Highcharts.defined;
  const isObject = Highcharts.isObject;

  const scrollBar = disableExport;
  const rowHeight = 30;

  let format = "%d-%b-%y";
  let yAxis: any = {
    uniqueNames: true,
    staticScale: rowHeight,
    className: "ganttY",
    labels: {
      // format: "{point.name}",
      style: {
        width: "500px",
        fontSize: "12px",
        whiteSpace: "pre-wrap",
        textOverflow: "justify",
        color: "#000",
      },
    },
  };
  if (chartType) {
    let labels: any = {
      align: "left",
      useHTML: true,
      formatter: function () {
        let width = 60;
        let textAlign = "center";
        let fontWeight = "normal";
        let bgColor = "#fff";
        let textColor = "#000";
        let marginLeft = 2;
        let positionLeft = 0;
        if (this.axis.axisTitle) {
          if (this.axis.axisTitle.textStr === "Task Name") {
            width = 300;
            textAlign = "left";
          }
          if (this.axis.axisTitle.textStr === "Task Owner") {
            // positionLeft = -25;
            textAlign = "left";
          }
          if (this.chart.options.series[this.pos]) {
            if (this.chart.options.series[this.pos].highlight === 1) {
              fontWeight = "bold";
              bgColor = "#009A44";
              textColor = "#fff";
            }
          }
          //  else {
          //   console.log("not found: ", this.chart.options.series, this.pos);
          // }
        }
        let val = this.value;
        if (typeof val === "number") {
          if (this.axis.axisTitle) {
            if (this.axis.axisTitle.textStr === "Duration") {
              width = 60;
            } else {
              val = "";
              return "<div style='width:20px;'></div>";
            }
          }
        }
        return (
          "<div style='position:relative;font-size:12px;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;padding:2px;background-color:" +
          bgColor +
          ";color:" +
          textColor +
          ";width:" +
          (width + 10) +
          "px;text-align:" +
          textAlign +
          ";margin-left:" +
          marginLeft +
          "px;left:" +
          positionLeft +
          "px;margin-top:3px;font-weight:" +
          fontWeight +
          ";'>" +
          val +
          "</div>"
        );
      },
    };
    if (chartType === "all" && !freezeHeader) {
      labels = {
        align: "left",
      };
    }
    console.log("labels: ", labels);
    yAxis = {
      type: "category",
      labels: labels,
      staticScale: rowHeight,
      grid: {
        enabled: true,
        columns: [
          {
            title: {
              text: "Task Name",
              style: "",
            },
            labels: {
              // format: "{point.name}",
              style: {
                width: "500px",
                fontSize: "12px",
                // fontWeight: "bold",
                whiteSpace: "pre-wrap",
                textOverflow: "justify",
                color: "#000",
              },
            },
            categories: chartSeries
              ? chartSeries.map(function (s) {
                  return s.name;
                })
              : [],
          },
          {
            title: {
              text: "Base Start",
              style: "",
            },
            labels: {
              align: "center",
              style: {
                fontSize: 12,
                color: "#000",
                width: 25,
              },
            },
            categories: chartSeries
              ? chartSeries.map(function (s) {
                  return dateFormat("%d/%m/%y", s.baseStart);
                })
              : [],
          },
          {
            title: {
              text: "Base Finish",
              style: "",
            },
            labels: {
              align: "center",
              style: {
                fontSize: 12,
                color: "#000",
                width: 25,
              },
            },
            categories: chartSeries
              ? chartSeries.map(function (s) {
                  return dateFormat("%d/%m/%y", s.baseEnd);
                })
              : [],
          },
          {
            title: {
              text: "Start",
              style: "",
            },
            labels: {
              align: "center",
              style: {
                fontSize: 12,
                color: "#000",
                width: 25,
              },
            },
            categories: chartSeries
              ? chartSeries.map(function (s) {
                  return dateFormat("%d/%m/%y", s.start);
                })
              : [],
          },
          {
            title: {
              text: "Finish",
              style: "",
            },
            labels: {
              align: "center",
              style: {
                fontSize: 12,
                color: "#000",
                width: 25,
              },
            },
            categories: chartSeries
              ? chartSeries.map(function (s) {
                  return dateFormat("%d/%m/%y", s.end);
                })
              : [],
          },
          {
            title: {
              text: "Duration",
              style: "",
            },
            labels: {
              align: "center",
              style: {
                fontSize: 12,
                color: "#000",
              },
            },
            categories: chartSeries
              ? chartSeries.map(function (s) {
                  return s.duration;
                })
              : [],
          },
          {
            title: {
              text: "Task Owner",
              style: "",
            },
            labels: {
              align: "center",
              style: {
                fontSize: 12,
                color: "#000",
              },
            },
            categories: chartSeries
              ? chartSeries.map(function (s) {
                  return s.taskOwner;
                })
              : [],
          },
        ],
      },
    };
  } else {
    let labels: any = {
      align: "left",
      useHTML: true,
      formatter: function () {
        let width = 50;
        let textAlign = "left";
        let fontWeight = "normal";
        let bgColor = "#fff";
        let textColor = "#000";
        if (this.chart.options) {
          if (this.chart.options.series[this.pos - 1]) {
            if (this.chart.options.series[this.pos - 1].highlight === 1) {
              fontWeight = "bold";
              bgColor = "#009A44";
              textColor = "#fff";
            }
          }
        }
        let val = this.value;
        if (val.length < 3) {
          val = "";
        }
        return (
          "<div style='font-size:12px;white-space:nowrap;text-overflow:ellipsis;overflow:hidden;padding:2px;background-color:" +
          bgColor +
          ";color:" +
          textColor +
          ";text-align:" +
          textAlign +
          ";margin-left:5px;margin-top:3px;font-weight:" +
          fontWeight +
          ";'>" +
          val +
          "</div>"
        );
      },
    };
    yAxis = {
      uniqueNames: true,
      labels: labels,
      staticScale: 20,
    };
  }
  if (scrollBar) {
    yAxis.scrollbar = {
      enabled: true,
      showFull: false,
    };
    if (chartType !== "all") {
      yAxis.max = fixedRows;
    }
  }

  let chartWidth = 1410;
  if (pageSizesRef.current) {
    chartWidth = pageSizesRef.current.selectedValue.size;
  }

  let options: any = {
    chart: {
      spacingTop: 0,
      spacingBottom: 0,
      events: {
        click: (e: any) => {
          console.log(e);
        },
      },
      // width: window.innerWidth * 0.9,
      // height: 1000,
      width: chartWidth, // A3 => 1410, A2 => 1990, A1 => 2815
    },
    legend: false,
    credits: false,
    title: {
      text: chartTitle,
    },
    exporting: {
      filename: chartTitle,
    },
    xAxis: {
      currentDateIndicator: false,
    },
    yAxis: yAxis,
    plotOptions: {
      series: {
        cursor: "pointer",
        // turboThreshold: 1000000,
        events: {
          click: (event: any) => {
            onEditChart(event.point);
          },
        },
      },
    },
    navigator: {
      enabled: true,
      liveRedraw: true,
      series: {
        type: "gantt",
        pointPlacement: 0.5,
        pointPadding: 0.25,
        accessibility: {
          enabled: true,
        },
      },
    },
    scrollbar: {
      enabled: true,
    },
    rangeSelector: {
      enabled: true,
      selected: 5,
    },
    series: chartSeries,
    tooltip: {
      pointFormatter: function () {
        var point = this,
          format = "%d - %b - %Y",
          options = point.options,
          completed = options.completed,
          amount = isObject(completed) ? completed.amount : completed,
          status = (amount || 0) * 100 + "%",
          lines;

        lines = [
          {
            title: "Base Start",
            value: dateFormat(format, point.baseStart),
          },
          {
            title: "Base End",
            value: dateFormat(format, point.baseEnd),
          },
          {
            title: "Start",
            value: dateFormat(format, point.start),
          },
          {
            visible: !options.milestone,
            title: "End",
            value: dateFormat(format, point.end),
          },
          {
            title: "Completed",
            value: status,
          },
        ];

        return lines.reduce(function (str, line) {
          var s = "",
            style = isDefined(line.style) ? line.style : "font-size: 0.8em;";
          if (line.visible !== false) {
            s =
              '<span style="' +
              style +
              '">' +
              (isDefined(line.title) ? line.title + ": " : "") +
              (isDefined(line.value) ? line.value : "") +
              "</span><br/>";
          }
          return str + s;
        }, "");
      },
    },
  };

  if (chartType) {
    options = {
      chart: {
        spacingTop: 0,
        spacingBottom: 0,
        events: {
          click: (e: any) => {
            console.log(e);
          },
        },
        // height: 1000,
        width: chartWidth, // A3 => 1410, A2 => 1990, A1 => 2815
      },
      legend: false,
      credits: false,
      title: {
        text: chartTitle,
      },
      exporting: {
        filename: chartTitle,
      },
      xAxis: {
        tickPixelInterval: 70,
        // grid: {
        //   cellHeight: 15,
        // },
      },
      yAxis: yAxis,
      plotOptions: {
        series: {
          cursor: "pointer",
          // turboThreshold: 1000000,
          events: {
            click: (event: any) => {
              onEditChart(event.point);
            },
          },
          pointPadding: 0,
        },
      },
      navigator: {
        enabled: true,
        liveRedraw: true,
        series: {
          type: "gantt",
          pointPlacement: 0.5,
          pointPadding: 0.25,
          accessibility: {
            enabled: true,
          },
        },
      },
      scrollbar: {
        enabled: true,
      },
      rangeSelector: {
        enabled: true,
        selected: 5,
      },
      series: chartSeries,
      tooltip: {
        useHTML: true,
        headerFormat:
          "<span style='font-weight:bold;'>{series.name}</span><br/><p style='margin-top:0px;margin-bottom:5px;'></p>",
        pointFormatter: function () {
          var point = this,
            format = "%d - %b - %Y",
            options = point.options,
            completed = options.completed,
            amount = completed.amount ? completed.amount : 0,
            status = (amount || 0) * 100 + "%",
            lines = [];

          lines = [
            {
              title: "Base Start",
              value: dateFormat(format, point.baseStart),
            },
            {
              title: "Base End",
              value: dateFormat(format, point.baseEnd),
            },
            {
              title: "Start",
              value: dateFormat(format, point.start),
            },
            {
              title: "End",
              value: dateFormat(format, point.end),
            },
            {
              title: "Completed",
              value: status,
            },
          ];

          let linesList = lines.reduce(function (str, line) {
            var s = "",
              style = isDefined(line.style) ? line.style : "font-size: 0.9em;";
            if (line.visible !== false && line.title) {
              s =
                '<span style="margin-bottom:5px;' +
                style +
                '">' +
                (isDefined(line.title) ? line.title + ": " : "") +
                (isDefined(line.value) ? line.value : "") +
                "</span><br/>";
            }
            return str + s;
          }, "");
          return linesList;
        },
      },
    };
  }

  let pageCount = Math.ceil(totalRows / fixedRows);
  let showPagination = false;
  if (chartType === "all" || chartType === "treeview") {
    if (freezeHeader) {
      showPagination = true;
    }
  }

  console.log("GanttChart: chartType:", chartType);

  return (
    <React.Fragment>
      {loading ? (
        <div className="flexCenter">
          <Loader />
        </div>
      ) : (
        <div className="ganttChart">
          <div
            style={{
              width: "70%",
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
            }}
          >
            <div
              className="formGroup"
              style={{
                width: "70%",
                marginRight: 10,
              }}
            >
              <label>{LABELS.PROJECT_NAME}</label>
              <AutoComplete
                id={"projects"}
                list={projects}
                childRef={projectRef}
                disabled={chartSeries === null}
                onChange={getCriticalPaths}
              />
            </div>

            {criticalPaths ? (
              <div
                className="formGroup"
                style={{
                  width: "70%",
                  marginRight: 10,
                }}
              >
                <label>{LABELS.TASK_NAME}</label>
                <AutoComplete
                  id={"criticalPaths"}
                  list={criticalPaths}
                  childRef={criticalPathsRef}
                  disabled={chartSeries === null}
                />
              </div>
            ) : (
              <div style={{ width: "71%" }}>
                <Loader size={20} />
              </div>
            )}
          </div>

          <div
            className="formGroup"
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <ChkBox
              id={"freezeHeader"}
              text={"Freeze Headings"}
              checked={freezeHeader}
              disabled={chartSeries === null}
              onChange={(val: any) => {
                setPageNo(1);
                // let data = chartSeries;
                // setChartInfo(null);
                // setFreezeHeader(val);
                // setTimeout(() => {
                //   setChartInfo(data);
                // }, 1000);
              }}
            />
            <div
              style={{
                minWidth: 170,
                marginRight: 15,
                position: "relative",
                top: -2,
              }}
            >
              <AutoComplete
                id={"pages"}
                list={pageSizes}
                childRef={pageSizesRef}
                disabled={chartSeries === null}
                defaultValue={{
                  id: "a3",
                  label: "Page Size A3",
                  size: 1410,
                }}
              />
            </div>
            <Btn
              text={LABELS.LOAD}
              marginRight={15}
              disabled={chartSeries === null}
              bgColor={
                chartType == "treeview" ? COLORS.PRIMARY : COLORS.DIM_GRAY
              }
              color={chartType == "treeview" ? COLORS.WHITE : COLORS.SECONDARY}
              onClick={() => {
                getChartData("treeview");
                setPageNo(1);
              }}
            />
            <Btn
              text={LABELS.SHOW_ALL}
              marginRight={15}
              disabled={chartSeries === null}
              bgColor={chartType == "all" ? COLORS.PRIMARY : COLORS.DIM_GRAY}
              color={chartType == "all" ? COLORS.WHITE : COLORS.SECONDARY}
              onClick={() => {
                getChartData("all");
                setPageNo(1);
              }}
            />
            <Btn
              text={LABELS.ONE_MONTH_VIEW}
              marginRight={15}
              disabled={chartSeries === null}
              bgColor={
                chartType == "nextonemonth" ? COLORS.PRIMARY : COLORS.DIM_GRAY
              }
              color={
                chartType == "nextonemonth" ? COLORS.WHITE : COLORS.SECONDARY
              }
              onClick={() => getChartData("nextonemonth")}
            />
            <Btn
              text={LABELS.SIX_WEEKS_VIEW}
              marginRight={15}
              disabled={chartSeries === null}
              bgColor={
                chartType == "nextsixweeks" ? COLORS.PRIMARY : COLORS.DIM_GRAY
              }
              color={
                chartType == "nextsixweeks" ? COLORS.WHITE : COLORS.SECONDARY
              }
              onClick={() => getChartData("nextsixweeks")}
            />
            <Btn
              text={LABELS.CRITICAL_PATH}
              marginRight={15}
              disabled={chartSeries === null}
              bgColor={
                chartType == "onlycriticalpaths"
                  ? COLORS.PRIMARY
                  : COLORS.DIM_GRAY
              }
              color={
                chartType == "onlycriticalpaths"
                  ? COLORS.WHITE
                  : COLORS.SECONDARY
              }
              onClick={() => getChartData("onlycriticalpaths")}
            />
            <Btn
              text={LABELS.NEAR_TO_CRITICAL_PATH}
              marginRight={15}
              disabled={chartSeries === null}
              bgColor={
                chartType == "neartocriticalpaths"
                  ? COLORS.PRIMARY
                  : COLORS.DIM_GRAY
              }
              color={
                chartType == "neartocriticalpaths"
                  ? COLORS.WHITE
                  : COLORS.SECONDARY
              }
              className={"padH10"}
              onClick={() => getChartData("neartocriticalpaths")}
            />
            <Tooltip title={LABELS.EXPORT_TO_PDF}>
              <IconButton
                disabled={chartSeries === null || disableExport}
                style={{
                  marginRight: 15,
                }}
                onClick={exportHighchart}
              >
                <IconExport />
              </IconButton>
            </Tooltip>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              marginTop: -8,
              marginBottom: 5,
            }}
          >
            <p
              style={{
                margin: 0,
                fontSize: 14,
                fontWeight: "500",
              }}
            >
              {"Last Updated On: " + updateDate}
            </p>
          </div>
          {showPagination && (
            <div
              style={{
                marginTop: 10,
                marginLeft: -15,
                marginBottom: 10,
              }}
            >
              <Pagination
                shape="rounded"
                count={pageCount}
                color="primary"
                page={pageNo}
                onChange={(e, val) => {
                  setPageNo(val);
                  getChartData(chartType, val);
                }}
              />
            </div>
          )}

          {chartSeries ? (
            <div
              id="chart"
              style={{
                width: "100%",
                height: "auto",
                overflow: freezeHeader ? "none" : "auto",
                position: "relative",
              }}
            >
              {chartSeries.length > 0 && (
                <HighchartsReact
                  highcharts={Highcharts}
                  constructorType={"ganttChart"}
                  options={options}
                  ref={chartRef}
                />
              )}
            </div>
          ) : (
            <div className="flexCenter" style={{ height: 400 }}>
              <Loader />
            </div>
          )}
        </div>
      )}

      <EditChart
        userInfo={props.userInfo}
        getChartData={getChartData}
        chartType={chartType}
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state: any) => {
  return {};
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setChartInfo: (values: any) => {
      dispatch({
        type: CHART_ACTIONS.SET_CHART_INFO,
        values,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(GanttChartPage));
