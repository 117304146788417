import { COLORS } from "common/config";

export const GRAPHS = {
  getColumnGraph: (
    title: string,
    xAxis: string[],
    yAxis: number[],
    exportTitle?: string
  ) => {
    return {
      chart: {
        type: "column",
        panning: true,
        marginLeft: 60,
        lang: {
          decimalPoint: ".",
          thousandsSep: ",",
        },
        options3d: {
          enabled: true,
          alpha: 0,
          beta: 10,
          viewDistance: 25,
          depth: 60,
        },
      },
      credits: false,
      title: {
        align: "center",
        text: title || "",
      },
      exporting: {
        filename: exportTitle || new Date().getTime().toString(),
      },
      accessibility: {
        announceNewData: {
          enabled: true,
        },
      },
      xAxis: {
        categories: xAxis,
      },
      yAxis: {
        min: 0,
        title: {
          text: "Number of Reports",
        },
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            format: "{point.y:.0f}",
          },
        },
        column: {
          colorByPoint: true,
        },
      },
      tooltip: {
        headerFormat:
          '<span style="font-size:11px;font-weight:bold;">{point.key}</span><br>',
        pointFormat: "Value: <b>{point.y:.1f}</b><br/>",
      },
      series: [
        {
          name: "Category",
          data: yAxis,
          color: COLORS.PRIMARY,
        },
      ],
    };
  },

  getPieGraph: (
    title: string,
    series: any[],
    height?: number,
    exportTitle?: string
  ) => {
    return {
      chart: {
        type: "pie",
        height,
        marginTop: 40,
        marginLeft: 85,
        options3d: {
          enabled: true,
          alpha: 45,
        },
      },
      credits: false,
      title: {
        text: title,
      },
      exporting: {
        filename: exportTitle,
      },
      tooltip: {
        pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
      },
      accessibility: {
        point: {
          valueSuffix: "%",
        },
      },
      plotOptions: {
        pie: {
          innerSize: 100,
          depth: 45,
        },
      },
      series,
    };
  },

  getBarGraph: (
    title: string,
    xAxis: string[],
    yAxis: number[],
    xAxisTitle: string,
    yAxisTitle: string,
    exportTitle?: string,
    height?: number
  ) => {
    return {
      chart: {
        type: "bar",
        height,
      },
      title: {
        text: title,
      },
      xAxis: {
        categories: xAxis,
        title: {
          text: xAxisTitle,
        },
        gridLineWidth: 1,
        lineWidth: 0,
      },
      yAxis: {
        min: 0,
        title: {
          text: yAxisTitle,
          align: "high",
        },
        labels: {
          overflow: "justify",
        },
        gridLineWidth: 0,
      },
      // tooltip: {
      //     valueSuffix: ' millions'
      // },
      plotOptions: {
        bar: {
          borderRadius: "1.5%",
          dataLabels: {
            enabled: true,
          },
          groupPadding: 0.35,
        },
      },
      exporting: {
        filename: exportTitle || new Date().getTime().toString(),
      },
      legend: false,
      credits: {
        enabled: false,
      },
      series: [
        {
          name: title || " ",
          data: yAxis,
        },
      ],
    };
  },
};
