/*
 * Safety Monitoring & Measurement Form
 */

import React, { useRef, useEffect, useState } from "react";
import { withSnackbar } from "notistack";
import { IconButton, Tooltip } from "@mui/material";
import { Edit as IconEdit } from "@mui/icons-material";

import UiDialog from "components/Custom/UiDialog";
import ExcelGrid from "components/Custom/ExcelGrid";
import AutoComplete from "components/Custom/AutoComplete";
import SkeletonBox from "components/Custom/SkeletonBox";
import ModalBtmBtns from "components/Custom/ModalBtmBtns";
import Loader from "components/Custom/Loader";
import HGraph from "components/Custom/HGraph";
import RichTxtBox from "components/Custom/RichTxtBox";
import PartLast from "./sections/PartLast";
import Part1 from "./sections/Part1";

import { LABELS, NOTIFY, SUCCESS, WARNINGS } from "common/config";
import { ApiHome } from "apihandlers/home";
import { ApiEhs } from "apihandlers/ehs";
import { COMMON_JS } from "common/scripts";
import Btn from "components/Custom/Btn";
import TxtBox from "components/Custom/TxtBox";

const API_HANDLER_HOME = new ApiHome();
const API_HANDLER_EHS = new ApiEhs();

const dataJson = require("./gridData.json");

const StandardOperatingProcedures = (props: any) => {
  const {} = props;
  const mainGridCols = [
    {
      key: "slNo",
      name: "Sl No.",
      type: "number",
      width: "5%",
      editable: false,
      sortable: true,
      align: "center",
    },
    {
      key: "nearmissTitle",
      name: "Event Title",
      type: "text",
      width: "20%",
      editable: false,
      sortable: true,
    },
    {
      key: "locationName",
      name: "Location",
      type: "text",
      width: "20%",
      editable: false,
      sortable: true,
    },
    {
      key: "descriptionDetails",
      name: "Event Description",
      type: "text",
      width: "20%",
      editable: false,
      sortable: true,
    },
    {
      key: "eventOn",
      name: "Incident date",
      type: "text",
      width: "10%",
      editable: false,
      sortable: true,
    },
    {
      key: "createdBy",
      name: "created By",
      type: "text",
      width: "20%",
      editable: false,
      sortable: true,
    },
    {
      key: "options",
      name: "Options",
      type: "html",
      width: "5%",
      editable: false,
      sortable: false,
      renderCell: ({ row }: any) => (
        <div className="options">
          <Tooltip title={LABELS.EDIT} placement="bottom">
            <IconButton className="iconBtn" onClick={() => onEditRow(row)}>
              <IconEdit />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];
  const [loading, setLoading] = useState<boolean>(true);
  const [projects, setProjects] = useState<any[]>([]);
  const [cols, setCols] = useState<any[]>(mainGridCols);
  const [rows, setRows] = useState<any[]>(null);
  const [columnChart, setColumnChart] = useState<any>({});
  const [pieChart, setPieChart] = useState<any>({});
  const [manageDialog, setManageDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [editRows, setEditRows] = useState<any[]>(null);
  const [pageNo, setPageNo] = useState(1);
  const [totalPages, setTotalPages] = useState(6);
  const [formData, setFormData] = useState<any>({});
  const [uploads, setUploads] = useState<any[]>([]);
  const [orgList, setOrgList] = useState<any[]>([]);
  const [contractorList, setContractorList] = useState<any[]>([]);
  const [taskList, setTaskList] = useState([
    {
      id: "true",
      label: "Yes",
    },
    {
      id: "false",
      label: "No",
    },
  ]);
  const [hazardList, setHazardList] = useState([
    {
      id: "true",
      label: "Yes",
    },
    {
      id: "false",
      label: "No",
    },
  ]);
  const [statusList, setStatusList] = useState([
    {
      id: "Open",
      label: "Open",
    },
    {
      id: "Close",
      label: "Close",
    },
  ]);
  const [comment, setComment] = useState<any[]>([]);

  const commentRef: any = useRef();
  const hazardRef: any = useRef();
  const statusRef: any = useRef();
  const contractorRef: any = useRef();
  const projectsRef: any = useRef();
  const part1Ref: any = useRef();
  const part2Ref: any = useRef();
  const part3Ref: any = useRef();
  const part4Ref: any = useRef();
  const part5Ref: any = useRef();
  const part6Ref: any = useRef();

  useEffect(() => {
    return () => {
      cleanUp();
    };
  }, []);

  useEffect(() => {
    if (loading) {
      getContractors();
    }
  }, [loading]);

  const getContractors = () => {
    const args = { dropdown: true };
    API_HANDLER_EHS.getContractor(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        const list = response.records.map((c: any) => {
          return {
            id: c.id,
            label: c.nameOfContractor,
          };
        });
        console.log("formData: list:", list);
        setContractorList(list);
        getRows();
      }
    });
  };
  const getOrgs = () => {
    const args = {};
    API_HANDLER_HOME.getOrganizations(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        setOrgList(
          response.records.map((o: any) => {
            return {
              id: o.id,
              label: o.orgName,
            };
          })
        );
        getRows();
      }
    });
  };

  const getProjects = () => {
    const args = { dropdown: true };
    API_HANDLER_HOME.getProjects(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        setProjects(response.records);
        getRows();
      }
    });
  };

  const cleanUp = () => {
    setRows(null);
    setLoading(true);
    setManageDialog(false);
    setSelectedRow({});
    console.log("Cleaned up");
  };

  const getRows = () => {
    setRows(null);
    const args = {};
    API_HANDLER_EHS.getSops(args).then((res: any) => {
      const { status, response } = res;
      if (status === 200) {
        setRows(response.records);
        setColumnChart(response.columnChart);
        setPieChart(response.pieChart);
        setLoading(false);
      }
    });
  };

  const onAdd = () => {
    // const contractorId = contractorRef.current.selectedValue.id;
    // if (!contractorId) {
    //   COMMON_JS.showNotify(
    //     props,
    //     NOTIFY.WARNING,
    //     "please select contractor name..!"
    //   );
    //   return;
    // }
    setManageDialog(!manageDialog);
    setEditRows([]);
    setPageNo(1);
    setSelectedRow({});
  };

  const onEditRow = (row: any) => {
    setPageNo(1);
    setSelectedRow(row);
    setManageDialog(true);
    setEditRows(null);
    getRowInfo(row.id);
  };

  const getRowInfo = (sopId) => {
    const args = { sopId: selectedRow.id || -1 };

    API_HANDLER_EHS.getSopInfo(args).then((res: any) => {
      const { status, response } = res;
      if (status === 200) {
        setEditRows(response);
      }
    });
  };

  const getContractorInfo = () => {
    const contractorId = contractorRef.current.selectedValue.id;
    if (!contractorId) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.PROJECT_EMPTY);
      return;
    }
    const args = {
      contractorId,
    };
    API_HANDLER_EHS.getSops(args).then((res: any) => {
      const { status, response } = res;
      if (status === 200) {
        setColumnChart(response.columnChart);
        setPieChart(response.pieChart);
      }
    });
  };

  const manageSop = () => {
    const sopTask = (
      document.getElementById("sopTask") as HTMLInputElement
    ).value.trim();
    const sopComments = (
      document.querySelector("#sopComments .ql-editor") as HTMLElement
    ).innerHTML;
    const reponsiblePerson = (
      document.getElementById("reponsiblePerson") as HTMLInputElement
    ).value.trim();
    const responsibleSupervisor = (
      document.getElementById("responsibleSupervisor") as HTMLInputElement
    ).value.trim();
    const reviewQs = part1Ref.current.selectedValue.id;
    // const person = (
    //   document.getElementById("person") as HTMLInputElement
    // ).value.trim();
    // const contact = (
    //   document.getElementById("contact") as HTMLInputElement
    // ).value.trim();
    // const time = (
    //   document.getElementById("time") as HTMLInputElement
    // ).value.trim();
    // const email = (
    //   document.getElementById("email") as HTMLInputElement
    // ).value.trim();
    // const cardNum = (
    //   document.getElementById("cardNum") as HTMLInputElement
    // ).value.trim();
    // const ppe = (
    //   document.getElementById("ppe") as HTMLInputElement
    // ).value.trim();
    // const rootCauseAnalysis = (
    //   document.getElementById("rootCauseAnalysis") as HTMLInputElement
    // ).value.trim();
    // const accident = (
    //   document.getElementById("accident") as HTMLInputElement
    // ).value.trim();
    // const resolve = (
    //   document.getElementById("resolve") as HTMLInputElement
    // ).value.trim();
    // const contractorResolve = (
    //   document.getElementById("contractorResolve") as HTMLInputElement
    // ).value.trim();
    // const task = taskRef.current.selectedValue.id;
    // const incidentStatus = statusRef.current.selectedValue.id;
    // const isHazard = hazardRef.current.selectedValue.id;

    //const statusOfsafetyTraining = safetyTrainRef.current.selectedValue.id;

    // if (nearmissTitle === "") {
    //   COMMON_JS.showNotify(props, NOTIFY.WARNING, "Please enter title..!");
    //   return null;
    // }
    // if (locationName === "") {
    //   COMMON_JS.showNotify(
    //     props,
    //     NOTIFY.WARNING,
    //     "Please enter location details..!"
    //   );
    //   return null;
    // }
    // if (eventOn === "") {
    //   COMMON_JS.showNotify(props, NOTIFY.WARNING, "Please enter event date..!");
    //   return null;
    // }
    // if (descriptionDetails === "") {
    //   COMMON_JS.showNotify(
    //     props,
    //     NOTIFY.WARNING,
    //     "Please enter event details..!"
    //   );
    //   return null;
    // }
    // if (incidentDesciption === "") {
    //   COMMON_JS.showNotify(
    //     props,
    //     NOTIFY.WARNING,
    //     "Please enter incident description..!"
    //   );
    //   return null;
    // }
    // if (!isReportable) {
    //   COMMON_JS.showNotify(
    //     props,
    //     NOTIFY.WARNING,
    //     "Please select reportable feild..!"
    //   );
    //   return null;
    // }
    // if (!incidentStatus) {
    //   COMMON_JS.showNotify(
    //     props,
    //     NOTIFY.WARNING,
    //     "Please select status feild..!"
    //   );
    //   return null;
    // }
    // if (!isPrivate) {
    //   COMMON_JS.showNotify(
    //     props,
    //     NOTIFY.WARNING,
    //     "Please select private feild..!"
    //   );
    //   return null;
    // }
    // if (!isInductionTraining) {
    //   COMMON_JS.showNotify(
    //     props,
    //     NOTIFY.WARNING,
    //     "Please select induction training..!"
    //   );
    //   return null;
    // }
    // if (trainingDt === "") {
    //   COMMON_JS.showNotify(
    //     props,
    //     NOTIFY.WARNING,
    //     "Please enter induction training date..!"
    //   );
    //   return null;
    // }
    // if (trainingNum === "") {
    //   COMMON_JS.showNotify(
    //     props,
    //     NOTIFY.WARNING,
    //     "Please enter induction training number..!"
    //   );
    //   return null;
    // }
    // if (!isSafePassTraining) {
    //   COMMON_JS.showNotify(
    //     props,
    //     NOTIFY.WARNING,
    //     "Please select safepass training..!"
    //   );
    //   return null;
    // }
    // if (safePassTrainingDt === "") {
    //   COMMON_JS.showNotify(
    //     props,
    //     NOTIFY.WARNING,
    //     "Please enter safepass training date..!"
    //   );
    //   return null;
    // }
    // if (safePassTrainingNum === "") {
    //   COMMON_JS.showNotify(
    //     props,
    //     NOTIFY.WARNING,
    //     "Please enter safepass training number..!"
    //   );
    //   return null;
    // }
    // if (safePassValidity === "") {
    //   COMMON_JS.showNotify(
    //     props,
    //     NOTIFY.WARNING,
    //     "Please enter safepass validity details..!"
    //   );
    //   return null;
    // }
    // if (!statusOfSafePass) {
    //   COMMON_JS.showNotify(
    //     props,
    //     NOTIFY.WARNING,
    //     "Please select status of safepass..!"
    //   );
    //   return null;
    // }
    // if (insuranceComp === "") {
    //   COMMON_JS.showNotify(
    //     props,
    //     NOTIFY.WARNING,
    //     "Please enter insurence company..!"
    //   );
    //   return null;
    // }
    // if (insuranceDt === "") {
    //   COMMON_JS.showNotify(
    //     props,
    //     NOTIFY.WARNING,
    //     "Please enter insurence date..!"
    //   );
    //   return null;
    // }
    // if (insuranceExpiry === "") {
    //   COMMON_JS.showNotify(
    //     props,
    //     NOTIFY.WARNING,
    //     "Please enter insurence expiry date..!"
    //   );
    //   return null;
    // }
    // if (insuranceValue === "") {
    //   COMMON_JS.showNotify(
    //     props,
    //     NOTIFY.WARNING,
    //     "Please enter insurence value..!"
    //   );
    //   return null;
    // }
    // if (!statusOfInsurance) {
    //   COMMON_JS.showNotify(
    //     props,
    //     NOTIFY.WARNING,
    //     "Please select status of insurance..!"
    //   );
    //   return null;
    // }
    // if (!isSafetyTraining) {
    //   COMMON_JS.showNotify(
    //     props,
    //     NOTIFY.WARNING,
    //     "Please select safety training..!"
    //   );
    //   return null;
    // }
    // if (safetyTrainingNum === "") {
    //   COMMON_JS.showNotify(
    //     props,
    //     NOTIFY.WARNING,
    //     "Please enter safety training number..!"
    //   );
    //   return null;
    // }
    // if (safetyTrainingDt === "") {
    //   COMMON_JS.showNotify(
    //     props,
    //     NOTIFY.WARNING,
    //     "Please enter safety training date..!"
    //   );
    //   return null;
    // }
    // if (safetyTrainingValidity === "") {
    //   COMMON_JS.showNotify(
    //     props,
    //     NOTIFY.WARNING,
    //     "Please enter safety training validity..!"
    //   );
    //   return null;
    // }
    // if (!statusOfsafetyTraining) {
    //   COMMON_JS.showNotify(
    //     props,
    //     NOTIFY.WARNING,
    //     "Please select status of safety training..!"
    //   );
    //   return null;
    // }
    let args: any = updateForm();
    args = {
      ...args,
      sopId: selectedRow.id || -1,
      sopTask,
      reviewQs,
      sopComments,
      reponsiblePerson,
      responsibleSupervisor,
      // nearmissDetails: [
      //   {
      //     "1.4": time,
      //     "1.7": [{ person, contact, email }],
      //     "1.8": cardNum,
      //     "1.9": ppe,
      //     "1.10": task,
      //     "1.11": isHazard,
      //     "1.12": rootCauseAnalysis,
      //     "1.13": accident,
      //     "1.14": resolve,
      //     "1.15": contractorResolve,
      //     "1.16": incidentStatus,
      //   },
      // ],
      files: uploads,
    };

    console.log("manageSop: ", args);

    // Validations
    setEditRows(null);
    API_HANDLER_EHS.manageSop(args).then((res) => {
      if (res.status === 200) {
        COMMON_JS.showNotify(
          props,
          NOTIFY.SUCCESS,
          SUCCESS.SAFETY_MONITOR_SAVED
        );
        setSelectedRow({});
        setManageDialog(false);
        getRows();
      } else {
        COMMON_JS.showNotify(props, NOTIFY.ERROR, res.error);
      }
    });
  };

  const updateForm = () => {
    let args: any = {};
    setFormData(args);
    return args;
  };

  const onReload = () => {
    getRows();
  };

  const onCloseForm = () => {
    setManageDialog(false);
  };

  console.log("selectedRow:", selectedRow);

  return (
    <React.Fragment>
      <div className="pageInfo">
        <ul className="breadcrumbs">
          <li>{LABELS.HOME}</li>
          <li className="dim">{" / "}</li>
          <li onClick={() => props.setDashMenu(LABELS.HOME)}>{LABELS.EHS}</li>
          <li className="dim">{" / "}</li>
          <li className="active">{LABELS.SOP}</li>
        </ul>
        <h4>{LABELS.SOP}</h4>
      </div>
      <div className="formGroup" style={{ width: "100%" }}>
        <label>{LABELS.CONTRACTOR_NAME}</label>
        <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
          <div style={{ width: "30%", marginRight: 10 }}>
            <AutoComplete
              list={contractorList}
              childRef={contractorRef}
              defaultValue={
                selectedRow.id
                  ? {
                      id: selectedRow.id,
                      label: selectedRow.nameOfContractor,
                    }
                  : null
              }
            />
          </div>
          <Btn text={LABELS.LOAD_GRAPH} onClick={getContractorInfo} />
        </div>
      </div>

      {/* Graphs */}
      {pieChart.series && (
        <div className="graphs">
          <HGraph
            width={"50%"}
            type={"column"}
            xAxis={columnChart.xAxis}
            yAxis={columnChart.yAxis}
            title={" "}
            exportTitle={" "}
          />
          <HGraph
            width={"50%"}
            type={"pie"}
            float={"right"}
            series={pieChart.series}
            title={" "}
            exportTitle={" "}
          />
        </div>
      )}

      {/* Master Grid */}
      {rows ? (
        <ExcelGrid
          cols={cols}
          rows={rows}
          exportOption={true}
          newRowOption={false}
          exportName={LABELS.SOP}
          onReload={onReload}
          onAdd={onAdd}
        />
      ) : (
        <div className="pageCenter">
          <Loader />
        </div>
      )}

      {/* Form Dialog Box */}
      {manageDialog && (
        <UiDialog
          title={
            selectedRow.nearmissTitle
              ? "Edit " + LABELS.SOP + ": " + selectedRow.locationName
              : "Add New " + LABELS.SOP
          }
          open={true}
          size={"xl"}
          onClose={() => {
            setManageDialog(false);
            setFormData({});
            setPageNo(1);
          }}
        >
          <>
            <div style={{ width: "100%", flexDirection: "column" }}>
              <>
                <div className="formRow">
                  <div className="formGroup" style={{ width: "30%" }}>
                    <label>{" Task "}</label>
                    <TxtBox
                      type={"text"}
                      id="sopTask"
                      defaultValue={selectedRow.id ? selectedRow.sopTask : ""}
                    />
                  </div>
                </div>
                <div style={{ width: "100%", flexDirection: "column" }}>
                  <Part1 childRef={part1Ref} editRows={editRows} />
                </div>
                <div className="formGroup" style={{ width: "60%" }}>
                  <label>{"Comments"}</label>
                  <RichTxtBox
                    id="sopComments"
                    defaultValue={selectedRow.id ? selectedRow.sopComments : ""}
                  />
                  {/* <TxtBox
                    type={"text"}
                    id="sopComments"
                    defaultValue={selectedRow.id ? selectedRow.sopComments : ""}
                  /> */}
                </div>

                <div className="formRow">
                  <div className="formGroup" style={{ width: "30%" }}>
                    <label>{"Reponsible Person"}</label>
                    <TxtBox
                      type={"text"}
                      id="reponsiblePerson"
                      defaultValue={
                        selectedRow.id ? selectedRow.reponsiblePerson : ""
                      }
                    />
                  </div>
                  <div className="formGroup" style={{ width: "30%" }}>
                    <label>{"Responsible Supervisor"}</label>
                    <TxtBox
                      type={"text"}
                      id="responsibleSupervisor"
                      defaultValue={
                        selectedRow.id ? selectedRow.responsibleSupervisor : ""
                      }
                    />
                  </div>
                  <div className="formGroup" style={{ width: "30%" }}></div>
                </div>
                {/* 
                <div className="formRow">
                  <div className="formGroup" style={{ width: "30%" }}>
                    <label>{"1.4 Event Time"}</label>
                    <TxtBox
                      type={"time"}
                      id="time"
                      defaultValue={selectedRow.id ? selectedRow.time : ""}
                    />
                  </div>

                  <div className="formGroup" style={{ width: "30%" }}>
                    <label className="red">{"1.5 Event Description *"}</label>
                    <TxtBox
                      type={"text"}
                      id="descriptionDetails"
                      rows={3}
                      defaultValue={
                        selectedRow.id ? selectedRow.descriptionDetails : ""
                      }
                    />
                  </div>
                  <div className="formGroup" style={{ width: "30%" }}>
                    <label>{" 1.7 Affected Person details"}</label>
                    <div className="formGroup">
                      <TxtBox
                        type={"text"}
                        placeholder={"Name"}
                        id="person"
                        defaultValue={selectedRow.id ? selectedRow.person : ""}
                      />
                    </div>
                    <div className="formGroup">
                      <TxtBox
                        type={"text"}
                        placeholder={" Contact No"}
                        id="contact"
                        defaultValue={selectedRow.id ? selectedRow.contact : ""}
                      />
                    </div>
                    <div className="formGroup">
                      <TxtBox
                        type={"text"}
                        placeholder={"Email Address"}
                        id="email"
                        defaultValue={selectedRow.id ? selectedRow.email : ""}
                      />
                    </div>
                  </div>
                </div>

                <div className="formRow">
                  <div className="formGroup" style={{ width: "30%" }}>
                    <label>{"1.8 Card No."}</label>
                    <TxtBox
                      type={"text"}
                      id="cardNum"
                      defaultValue={selectedRow.id ? selectedRow.cardNum : ""}
                    />
                  </div>
                  <div className="formGroup" style={{ width: "30%" }}>
                    <label>{"1.9 What PPE Was worn?."}</label>
                    <TxtBox
                      type={"text"}
                      id="ppe"
                      defaultValue={selectedRow.id ? selectedRow.ppe : ""}
                    />
                  </div>
                  <div className="formGroup" style={{ width: "30%" }}>
                    <label>
                      {"1.10 Was a Risk Assessment in Place for the Task?:"}
                    </label>
                    <AutoComplete
                      list={taskList}
                      childRef={taskRef}
                      defaultValue={
                        selectedRow.id
                          ? selectedRow.task
                            ? taskList[0]
                            : taskList[1]
                          : null
                      }
                    />
                  </div>
                </div>

                <div className="formRow">
                  <div className="formGroup" style={{ width: "30%" }}>
                    <label>
                      {"1.11 Did the risk assessment identify the hazard?:"}
                    </label>
                    <AutoComplete
                      list={hazardList}
                      childRef={hazardRef}
                      defaultValue={
                        selectedRow.id
                          ? selectedRow.isHazard
                            ? hazardList[0]
                            : hazardList[1]
                          : null
                      }
                    />
                  </div>
                  <div className="formGroup" style={{ width: "30%" }}>
                    <label>
                      {
                        "1.12 ROOT CAUSE ANALYSIS “This must be filled out and completed by the site/project manager and Safety Manager”:"
                      }
                    </label>
                    <TxtBox
                      type={"text"}
                      id="rootCauseAnalysis"
                      rows={3}
                      defaultValue={
                        selectedRow.id ? selectedRow.rootCauseAnalysis : ""
                      }
                    />
                  </div>
                  <div className="formGroup" style={{ width: "30%" }}>
                    <label>
                      {
                        "1.13 Why did the accident/Incident happen? (Include; People involved; Plant and equipment and risk controls that were in place at the time of the accident/incident):"
                      }
                    </label>
                    <TxtBox
                      type={"text"}
                      id="accident"
                      rows={3}
                      defaultValue={selectedRow.id ? selectedRow.accident : ""}
                    />
                  </div>
                </div>

                <div className="formRow">
                  <div className="formGroup" style={{ width: "30%" }}>
                    <label>
                      {
                        "1.14 What could have been done differently to avoid this happening again?:"
                      }
                    </label>
                    <TxtBox
                      type={"text"}
                      id="resolve"
                      rows={3}
                      defaultValue={selectedRow.id ? selectedRow.resolve : ""}
                    />
                  </div>
                  <div className="formGroup" style={{ width: "30%" }}>
                    <label>
                      {
                        "1.15 What changes have the Contractor team made to avoid this happening again?:"
                      }
                    </label>
                    <TxtBox
                      type={"text"}
                      id="contractorResolve"
                      rows={3}
                      defaultValue={
                        selectedRow.id ? selectedRow.contractorResolve : ""
                      }
                    />
                  </div>
                  <div className="formGroup" style={{ width: "30%" }}>
                    <label>{"1.16 Incident Status"}</label>
                    <AutoComplete
                      list={statusList}
                      childRef={statusRef}
                      defaultValue={
                        selectedRow.id
                          ? selectedRow.incidentStatus
                            ? statusList[0]
                            : statusList[1]
                          : null
                      }
                    />
                  </div>
                </div> */}
              </>

              <PartLast
                childRef={part6Ref}
                selectedRow={selectedRow}
                uploads={uploads}
                setUploads={(val) => {
                  setUploads(val);
                }}
              />

              <ModalBtmBtns
                onSave={manageSop}
                onCancel={onCloseForm}
                //disabled={editRows === null}
              />
            </div>
          </>
        </UiDialog>
      )}
    </React.Fragment>
  );
};

export default withSnackbar(StandardOperatingProcedures);
