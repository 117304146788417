import React, { useEffect, useRef, useState } from "react";
import { withSnackbar } from "notistack";
import {
  DataGrid,
  GridColDef,
  GridToolbar,
  GridValueGetterParams,
  GridToolbarExportContainer,
  GridToolbarExport,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import { IconButton, Tooltip } from "@mui/material";
import { Edit as IconEdit } from "@mui/icons-material";

import Loader from "components/Custom/Loader";
import AutoComplete from "components/Custom/AutoComplete";
import Btn from "components/Custom/Btn";
import UiDialog from "components/Custom/UiDialog";
import TxtBox from "components/Custom/TxtBox";

import { DEFAULTS, LABELS, NOTIFY, WARNINGS } from "common/config";
import { ApiDashboard } from "apihandlers/dashboard";
import { ApiHome } from "apihandlers/home";
import { COMMON_JS } from "common/scripts";
import ExcelGrid from "components/Custom/ExcelGrid";

const API_HANDLER_DASHBOARD = new ApiDashboard();
const API_HANDLER_HOME = new ApiHome();

const ReportConstraintLog = (props: any) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showDialog, setShowDialog] = useState(false);
  const [projects, setProjects] = useState([]);
  const [chartData, setChartData] = useState<any>([]);
  const [rowData, setRowData] = useState<any>({});
  const [disciplines, setDisciplines] = useState([]);

  const columns = [
    // { key: "empId", name: "Employee ID", width: 300 },
    {
      key: "slNo",
      name: "Sl No.",
      type: "number",
      editable: false,
      sortable: true,
      searchable: true,
      width: "5%",
    },
    {
      key: "constraints",
      name: "Constraints",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      width: "15%",
    },
    {
      key: "taskowner",
      name: "Task Owner",
      width: "7%",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "entryBy",
      name: "Raised By",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      width: "8%",
    },
    {
      key: "entryDate",
      name: "Entry Date",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      width: "10%",
    },
    {
      key: "status",
      name: "Status",
      type: "dropdown",
      editable: false,
      sortable: true,
      searchable: true,
      filters: [
        { id: "OPEN", name: "OPEN" },
        { id: "APPROVED", name: "APPROVED" },
        { id: "REJECTED", name: "REJECTED" },
      ],
      width: "8%",
    },
    {
      key: "actionBy",
      name: "Action Taken By",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      width: "10%",
    },
    {
      key: "actionDate",
      name: "Action Taken Date",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      width: "7%",
    },
    {
      key: "activityId",
      name: "Activity ID",
      width: "10%",
      type: "number",
      align: "center",
      headerAlign: "center",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "description",
      name: "Activity Description",
      type: "text",
      editable: false,
      sortable: true,
      searchable: true,
      width: "15%",
    },
    {
      key: "options",
      name: "Options",
      width: "5%",
      disableExport: true,
      renderCell: (params: any) => (
        <div className="options">
          <Tooltip title={LABELS.EDIT} placement="bottom">
            <IconButton
              className="iconBtn"
              onClick={() => onEditRow(params.row)}
            >
              <IconEdit />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (loading) {
      getProjects();
    }
  }, []);

  const getProjects = () => {
    const args = { dropdown: true };
    API_HANDLER_HOME.getProjects(args).then((res) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        setProjects(response.records);
      }
      setLoading(false);
    });
  };

  const onEditRow = (row: any) => {
    console.log("onEditRow: ", row);
    setRowData(row);
    setShowDialog(true);
  };

  const loadChart = () => {
    const projectId = projectRef.current.selectedValue.id;
    const status = statusRef.current.selectedValue.id;
    if (!projectId) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.PROJECT_EMPTY);
      return;
    }
    const args = {
      projectId,
      status,
    };
    setChartData(null);
    API_HANDLER_DASHBOARD.getConstraintLogReport(args).then((res: any) => {
      if (res.status === 200) {
        setChartData(res.response);
        setIsLoaded(true);
      }
    });
  };

  const saveConstraint = () => {
    const projectId = projectRef.current.selectedValue.id;
    const activityId = (
      document.getElementById("activityId") as HTMLInputElement
    ).value.trim();
    const description = (
      document.getElementById("description") as HTMLInputElement
    ).value.trim();
    const taskowner = (
      document.getElementById("taskowner") as HTMLInputElement
    ).value.trim();
    const constraints = (
      document.getElementById("constraints") as HTMLInputElement
    ).value.trim();
    const entryDate = (
      document.getElementById("entryDate") as HTMLInputElement
    ).value.trim();
    const status = statusRef2.current.selectedValue.id;
    const args = {
      id: rowData.id || -1,
      projectId,
      activityId,
      description,
      taskowner,
      constraints,
      entryDate,
      status,
    };
    API_HANDLER_HOME.manageConstraint(args).then((res) => {
      if (res.status === 200) {
        COMMON_JS.showNotify(
          props,
          NOTIFY.SUCCESS,
          "Record saved successfully..!"
        );
        setRowData({});
        setShowDialog(false);
        loadChart();
      } else {
        COMMON_JS.showNotify(props, NOTIFY.ERROR, "Error: " + res.error);
      }
    });
  };

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
      </GridToolbarContainer>
    );
  };

  const onChangeProject = (e: any, val: any) => {
    let projectId = val.id;
    const args = { dropdown: true, projectId };
    API_HANDLER_HOME.getMainTasks(args).then((res) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        setDisciplines(response.records);
      }
    });
  };

  const onAddNew = () => {
    const projectId = projectRef.current.selectedValue.id;
    if (!projectId) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.PROJECT_EMPTY);
      return;
    }
    setRowData({});
    setShowDialog(!showDialog);
  };

  const projectRef: any = useRef();
  const statusRef: any = useRef();
  const statusRef2: any = useRef();

  let statusList = [
    {
      id: "ALL",
      label: "All",
    },
    {
      id: "OPEN",
      label: "Open",
    },
    {
      id: "APPROVED",
      label: "Closed",
    },
    {
      id: "REJECTED",
      label: "Rejected",
    },
  ];
  let statusList2 = [
    {
      id: "OPEN",
      label: "Open",
    },
    {
      id: "APPROVED",
      label: "Closed",
    },
    {
      id: "REJECTED",
      label: "Rejected",
    },
  ];
  let defaultStatus = null;
  if (rowData.status) {
    defaultStatus = statusList2.find((s) => s.id === rowData.status);
  }

  console.log("rowData: chartData:", chartData);

  return (
    <React.Fragment>
      <div className="pageInfo">
        <ul className="breadcrumbs">
          <li>{LABELS.HOME}</li>
          <li className="dim">{" / "}</li>
          <li className="active">{LABELS.CONSTRAINT_LOG_REPORT}</li>
        </ul>
        <h4>{LABELS.CONSTRAINT_LOG_REPORT}</h4>
      </div>

      <div id="fileUploadForm" style={{ marginTop: 15 }}>
        <div className="formGroup">
          <label>{LABELS.PROJECT_NAME}</label>
          <AutoComplete
            id={"projects"}
            list={projects}
            childRef={projectRef}
            onChange={onChangeProject}
          />
        </div>

        <div className="formGroup">
          <label>{LABELS.STATUS}</label>
          <AutoComplete
            id={"status"}
            list={statusList}
            defaultValue={statusList[0]}
            childRef={statusRef}
          />
        </div>

        <div
          className="formGroup"
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: 0,
            marginBottom: 15,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              marginRight: 15,
            }}
          >
            <Btn
              text={LABELS.LOAD}
              onClick={loadChart}
              loading={loading}
              // loading={!chartData}
            />
          </div>
          {isLoaded && (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                marginRight: 15,
              }}
            >
              {/* <Btn
                    text={LABELS.ADD_NEW}
                    onClick={() => {
                      setShowDialog(true);
                    }}
                  /> */}
            </div>
          )}
        </div>
      </div>

      {loading ? (
        <div className="flexCenter">
          <Loader />
        </div>
      ) : (
        isLoaded && (
          <div
            id={"gridBox"}
            style={{
              width: "98%",
              height: "70%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            {chartData ? (
              <ExcelGrid
                id={"constraitsLog"}
                height={window.innerHeight * 0.6}
                cols={columns}
                rows={chartData}
                exportOption={true}
                newRowOption={false}
                exportName={LABELS.CONSTRAINT_LOG_REPORT}
                onReload={loadChart}
                onAdd={onAddNew}
              />
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  height: 300,
                  width: "100%",
                }}
              >
                <Loader />
              </div>
            )}
          </div>
        )
      )}

      {showDialog && (
        <UiDialog
          open={true}
          title={
            rowData.id
              ? "Edit Constraint: " + rowData.activityId
              : "Add Constraint"
          }
          size={DEFAULTS.DIALOG_SIZE}
          onClose={() => {
            setShowDialog(false);
          }}
        >
          <div className="formGroup">
            <label>{"Activity ID"}</label>
            <TxtBox
              placeholder={"Activity ID"}
              id={"activityId"}
              type={"text"}
              defaultValue={rowData.activityId || ""}
            />
          </div>
          <div className="formGroup">
            <label>{"Activity Description"}</label>
            <TxtBox
              placeholder={"Activity Description"}
              id={"description"}
              className={"multiLine"}
              type={"text"}
              defaultValue={rowData.description || ""}
              rows={4}
            />
          </div>
          <div className="formGroup">
            <label>{"Task Owner"}</label>
            <TxtBox
              placeholder={"Task Owner"}
              id={"taskowner"}
              type={"text"}
              defaultValue={rowData.taskowner || ""}
            />
          </div>
          <div className="formGroup">
            <label>{"Constraints"}</label>
            <TxtBox
              placeholder={"Constraints"}
              id={"constraints"}
              type={"text"}
              defaultValue={rowData.constraints || ""}
            />
          </div>
          <div className="formGroup">
            <label>{"Entry Date"}</label>
            <TxtBox
              placeholder={"Entry Date"}
              id={"entryDate"}
              type={"date"}
              defaultValue={rowData.entryDate || ""}
            />
          </div>
          <div className="formGroup">
            <label>{"Status"}</label>
            <AutoComplete
              id={"status"}
              list={statusList2}
              childRef={statusRef2}
              defaultValue={defaultStatus}
            />
          </div>
          <div className="formGroup">
            <Btn
              text={LABELS.SAVE}
              onClick={saveConstraint}
              // loading={chartInfoLoading}
            />
          </div>
        </UiDialog>
      )}
    </React.Fragment>
  );
};

export default withSnackbar(ReportConstraintLog);
