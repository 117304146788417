import React, { useState, useEffect, useRef } from "react";

import ChkBox from "components/Custom/ChkBox";
import ExcelGrid from "components/Custom/ExcelGrid";
import Loader from "components/Custom/Loader";
import { FORMATS, LABELS, NOTIFY } from "common/config";
import TxtBox from "components/Custom/TxtBox";
import AutoComplete from "components/Custom/AutoComplete";
import { DateFunctions } from "common/datefunctions";

const dataJson = require("../gridData.json");

interface defaultProps {
  childRef: any;
  editRows: any[];
  orgList: any[];
  selectedRow: any;
  showNotify: Function;
}

const Part1 = (props: defaultProps) => {
  const { childRef, editRows, orgList, selectedRow, showNotify } = props;

  const [natureList, setNatureList] = useState([
    {
      id: "Positive",
      label: "Positive",
    },
    {
      id: "Negative",
      label: "Negative",
    },
  ]);
  const [statusList, setStatusList] = useState([
    {
      id: "OPEN",
      label: "OPEN",
    },
    {
      id: "CLOSED",
      label: "CLOSED",
    },
  ]);
  const [categories, setCategories] = useState([
    {
      id: "Communication",
      label: "Communication",
    },
    {
      id: "Environmental",
      label: "Environmental",
    },
    {
      id: "Housekeeping",
      label: "Housekeeping",
    },
    {
      id: "Traffic Management",
      label: "Traffic Management",
    },
    {
      id: "Welfare",
      label: "Welfare",
    },
    {
      id: "Working at Height",
      label: "Working at Height",
    },
    {
      id: "negative",
      label: "Negative",
    },
  ]);

  const orgsRef: any = useRef();
  const orgNatureRef: any = useRef();
  const statusRef: any = useRef();
  const categoryRef: any = useRef();

  useEffect(() => {
    childRef.saveRecord = saveRecord;
    return () => {
      childRef.saveRecord = null;
    };
  }, []);

  const saveRecord = () => {
    const empName = (
      document.getElementById("empName") as HTMLInputElement
    ).value.trim();
    const observedQry = (
      document.getElementById("observedQry") as HTMLInputElement
    ).value.trim();
    const observedDt = (
      document.getElementById("observedDt") as HTMLInputElement
    ).value.trim();
    const orgId = orgsRef.current.selectedValue.id;
    const observedNature = orgNatureRef.current.selectedValue.id;
    const sorStatus = statusRef.current.selectedValue.id;
    const observedCategory = categoryRef.current.selectedValue.id;
    if (empName === "") {
      showNotify(NOTIFY.WARNING, "Please enter employee name..!");
      return null;
    }
    if (!orgId) {
      showNotify(NOTIFY.WARNING, "Please select organization..!");
      return null;
    }
    if (!observedNature) {
      showNotify(NOTIFY.WARNING, "Please select observation nature..!");
      return null;
    }
    if (!sorStatus) {
      showNotify(NOTIFY.WARNING, "Please select status..!");
      return null;
    }
    if (!observedCategory) {
      showNotify(NOTIFY.WARNING, "Please select category..!");
      return null;
    }
    return {
      empName,
      observedDt,
      orgId,
      observedNature,
      sorStatus,
      observedQry,
      observedCategory,
    };
  };

  return (
    <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
      <div className="formGroup" style={{ width: "20%", marginRight: 20 }}>
        <label className="red">{"Employee Name *"}</label>
        <TxtBox
          type={"text"}
          id="empName"
          defaultValue={selectedRow.id ? selectedRow.empName : ""}
        />
      </div>
      <div className="formGroup" style={{ width: "20%", marginRight: 20 }}>
        <label className="red">{"Select " + LABELS.ORGANIZATION + " *"}</label>
        <AutoComplete
          list={orgList}
          childRef={orgsRef}
          defaultValue={
            selectedRow.id
              ? {
                  id: selectedRow.orgId,
                  label: selectedRow.orgName,
                }
              : null
          }
        />
      </div>
      <div className="formGroup" style={{ width: "20%", marginRight: 20 }}>
        <label className="red">{"Date of Generation *"}</label>
        <TxtBox
          type={"date"}
          id="observedDt"
          defaultValue={
            selectedRow.id
              ? selectedRow.observedDt
              : DateFunctions.getDate("today", "from", FORMATS.SQL_DATE_ONLY)
          }
        />
      </div>
      <div className="formGroup" style={{ width: "20%", marginRight: 20 }}>
        <label className="red">{"Observation Nature *"}</label>
        <AutoComplete
          list={natureList}
          childRef={orgNatureRef}
          defaultValue={
            selectedRow.id
              ? {
                  id: selectedRow.observedNature,
                  label: selectedRow.observedNature,
                }
              : null
          }
        />
      </div>
      <div className="formGroup" style={{ width: "20%", marginRight: 20 }}>
        <label className="red">{"SOR Category *"}</label>
        <AutoComplete
          list={categories}
          childRef={categoryRef}
          defaultValue={
            selectedRow.id
              ? {
                  id: selectedRow.observedCategory,
                  label: selectedRow.observedCategory,
                }
              : null
          }
        />
      </div>
      <div className="formGroup" style={{ width: "60%" }}>
        <label className="red">{"Observation Query *"}</label>
        <TxtBox
          type={"text"}
          id="observedQry"
          rows={3}
          defaultValue={selectedRow.id ? selectedRow.observedQuery : ""}
        />
      </div>
      <div className="formGroup" style={{ width: "20%", marginRight: 20 }}>
        <label className="red">{"Status *"}</label>
        <AutoComplete
          list={statusList}
          childRef={statusRef}
          defaultValue={
            selectedRow.id
              ? {
                  id: selectedRow.sorStatus,
                  label: selectedRow.sorStatus,
                }
              : null
          }
        />
      </div>
    </div>
  );
};

export default Part1;
