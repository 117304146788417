/*
 * Safety Observation Records -> SOR
 */

import React, { useRef, useEffect, useState } from "react";
import { withSnackbar } from "notistack";
import { IconButton, Tooltip } from "@mui/material";
import { Edit as IconEdit } from "@mui/icons-material";

import UiDialog from "components/Custom/UiDialog";
import ExcelGrid from "components/Custom/ExcelGrid";
import AutoComplete from "components/Custom/AutoComplete";
import SkeletonBox from "components/Custom/SkeletonBox";
import ModalBtmBtns from "components/Custom/ModalBtmBtns";
import Loader from "components/Custom/Loader";
import HGraph from "components/Custom/HGraph";
import Part1 from "./sections/Part1";
import PartLast from "./sections/PartLast";

import { API_CONFIG, LABELS, NOTIFY, SUCCESS, WARNINGS } from "common/config";
import { ApiHome } from "apihandlers/home";
import { ApiEhs } from "apihandlers/ehs";
import { COMMON_JS } from "common/scripts";
import Btn from "components/Custom/Btn";

const API_HANDLER_HOME = new ApiHome();
const API_HANDLER_EHS = new ApiEhs();

const thumbnailImg = require("assets/images/pdf.png");

const SafetyObservationRecords = (props: any) => {
  const {} = props;
  const mainGridCols = [
    // {
    //   key: "id",
    //   name: "ID",
    //   type: "number",
    //   width: "5%",
    // },
    {
      key: "slNo",
      name: "Sl No.",
      type: "number",
      width: "5%",
      editable: false,
      sortable: true,
      align: "center",
      searchable: true,
    },
    // {
    //   key: "orgName",
    //   name: "Organization Name",
    //   type: "text",
    //   width: "10%",
    //   editable: false,
    //   sortable: true,
    // },
    {
      key: "projectName",
      name: "Project Name",
      type: "text",
      width: "15%",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "empName",
      name: "Employee",
      type: "text",
      width: "8%",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "observedDt",
      name: "Created Date",
      type: "date",
      width: "8%",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "createdBy",
      name: "Created By",
      type: "date",
      width: "8%",
      editable: false,
      sortable: true,
      searchable: true,
    },
    // {
    //   key: "observedNature",
    //   name: "Observation Nature",
    //   type: "text",
    //   width: "15%",
    // },
    {
      key: "sorStatus",
      name: "Status",
      type: "dropdown",
      width: "6%",
      searchable: true,
      filters: [
        {
          id: "OPEN",
          name: "OPEN",
        },
        {
          id: "CLOSED",
          name: "CLOSED",
        },
      ],
    },
    {
      key: "observedCategory",
      name: "SOR Category",
      type: "dropdown",
      width: "12%",
      searchable: true,
      filters: [
        {
          id: "Communication",
          name: "Communication",
        },
        {
          id: "Environmental",
          name: "Environmental",
        },
        {
          id: "Housekeeping",
          name: "Housekeeping",
        },
        {
          id: "Traffic Management",
          name: "Traffic Management",
        },
        {
          id: "Welfare",
          name: "Welfare",
        },
        {
          id: "Working at Height",
          name: "Working at Height",
        },
        {
          id: "negative",
          name: "Negative",
        },
      ],
    },
    {
      key: "orgName",
      name: "Organization Name",
      type: "date",
      width: "15%",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "closedDt",
      name: "Closed Date",
      type: "date",
      width: "8%",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "closedBy",
      name: "Closed By",
      type: "date",
      width: "8%",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "viewFiles",
      name: "Photos",
      type: "html",
      renderCell: (params: any) => {
        console.log("Rowaaaa:", params.row);
        return (
          <div className="flexRowAround">
            {params.row.files &&
              params.row.files.map((u, i) => {
                return (
                  <div
                    className="thumbnailBox"
                    style={{ width: "50%", paddingLeft: "1.2rem" }}
                  >
                    <img
                      onClick={() =>
                        window.open(API_CONFIG.API_URL + u.path, "_blank")
                      }
                      src={
                        u.mimeType === "pdf"
                          ? thumbnailImg
                          : API_CONFIG.API_URL + u.path
                      }
                      className="responsive cursor"
                      height={"50rem"}
                      width={"50rem"}
                    />
                  </div>
                );
              })}
          </div>
        );
      },
      width: "12%",
      editable: true,
      // sortable: true,
      // searchable: true,
    },
    {
      key: "options",
      name: "Options",
      type: "html",
      width: "5%",
      editable: false,
      sortable: false,
      renderCell: ({ row }: any) => (
        <div className="options">
          <Tooltip title={LABELS.EDIT} placement="bottom">
            <IconButton className="iconBtn" onClick={() => onEditRow(row)}>
              <IconEdit />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];
  const [loading, setLoading] = useState<boolean>(true);
  const [projects, setProjects] = useState<any[]>([]);
  const [cols, setCols] = useState<any[]>(mainGridCols);
  const [rows, setRows] = useState<any[]>(null);
  const [columnChart, setColumnChart] = useState<any>({});
  const [pieChart, setPieChart] = useState<any>({});
  const [manageDialog, setManageDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [editRows, setEditRows] = useState<any[]>(null);
  const [pageNo, setPageNo] = useState(1);
  const [totalPages, setTotalPages] = useState(6);
  const [formData, setFormData] = useState<any>({});
  const [uploads, setUploads] = useState<any[]>([]);
  const [orgList, setOrgList] = useState<any[]>([]);

  const projectsRef: any = useRef();
  const part1Ref: any = useRef();
  const part2Ref: any = useRef();
  const part3Ref: any = useRef();
  const part4Ref: any = useRef();
  const part5Ref: any = useRef();
  const part6Ref: any = useRef();

  useEffect(() => {
    return () => {
      cleanUp();
    };
  }, []);

  useEffect(() => {
    if (loading) {
      getProjects();
    }
  }, [loading]);

  const getProjects = () => {
    const args = { dropdown: true };
    API_HANDLER_HOME.getProjects(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        setProjects(response.records);
        getOrgs();
      }
    });
  };

  const getOrgs = () => {
    const args = {};
    API_HANDLER_HOME.getOrganizations(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        setOrgList(
          response.records.map((o: any) => {
            return {
              id: o.id,
              label: o.orgName,
            };
          })
        );
        getRows();
      }
    });
  };

  const cleanUp = () => {
    setRows(null);
    setLoading(true);
    setManageDialog(false);
    setSelectedRow({});
    console.log("Cleaned up");
  };

  const getRows = () => {
    setRows(null);
    const args = {};
    API_HANDLER_EHS.getSors(args).then((res: any) => {
      const { status, response } = res;
      if (status === 200) {
        for (let i = 0; i < response.records.length; i++) {
          response.records[i].viewFiles = (
            <div>
              {response.records[i].files.map((d: any) => {
                const fileUrl = API_CONFIG.API_URL + d.path;
                return (
                  <div
                    className="flexRowAround"
                    style={{
                      borderWidth: 1,
                      borderColor: "black",
                      alignItems: "center",
                      marginRight: 5,
                    }}
                  >
                    <div style={{ position: "relative" }}>
                      <img
                        src={d.mimeType === "pdf" ? thumbnailImg : fileUrl}
                        onClick={() => {
                          window.open(fileUrl, "_blank");
                        }}
                        width={"auto"}
                        height={50}
                        // style={{ marginRight: 5, cursor: "pointer" }}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          );
        }
        setRows(response.records);
        setColumnChart(response.columnChart);
        setPieChart(response.pieChart);
        setLoading(false);
      }
    });
  };

  const onAdd = () => {
    const projectId = projectsRef.current.selectedValue.id;
    if (!projectId) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.PROJECT_EMPTY);
      return;
    }
    setManageDialog(!manageDialog);
    setEditRows([]);
    setPageNo(1);
    setSelectedRow({});
    setUploads([]);
  };

  const onEditRow = (row: any) => {
    setPageNo(1);
    setSelectedRow(row);
    setManageDialog(true);
    setEditRows(null);
    getRowInfo(row.id);
  };

  const getRowInfo = (sorId) => {
    const args = {
      sorId,
    };
    API_HANDLER_EHS.getSorInfo(args).then((res: any) => {
      const { status, response } = res;
      if (status === 200) {
        setEditRows(response);
        setUploads(response.files);
      }
    });
  };

  const getProjectInfo = () => {
    const projectId = projectsRef.current.selectedValue.id;
    if (!projectId) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.PROJECT_EMPTY);
      return;
    }
    const args = {
      projectId,
    };
    API_HANDLER_EHS.getSors(args).then((res: any) => {
      const { status, response } = res;
      if (status === 200) {
        setColumnChart(response.columnChart);
        setPieChart(response.pieChart);
      }
    });
  };

  const manageSor = () => {
    let args: any = updateForm();
    if (!args) {
      return;
    }
    args = {
      ...args,
      sorId: selectedRow.id || -1,
      projectId: selectedRow.id
        ? selectedRow.projectId
        : projectsRef.current.selectedValue.id,
      files: uploads,
    };

    console.log("manageSor: ", args);

    // Validations
    if (!args.projectId) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.PROJECT_EMPTY);
      return;
    }
    setEditRows(null);
    API_HANDLER_EHS.manageSor(args).then((res) => {
      setEditRows([]);
      if (res.status === 200) {
        COMMON_JS.showNotify(props, NOTIFY.SUCCESS, SUCCESS.RECORD_SAVED);
        setSelectedRow({});
        setManageDialog(false);
        getRows();
      } else {
        COMMON_JS.showNotify(props, NOTIFY.ERROR, res.error);
      }
    });
  };

  const updateForm = () => {
    let args: any = {
      files: [],
    };
    if (part1Ref.saveRecord) {
      let tmp = part1Ref.saveRecord();
      if (!tmp) {
        return false;
      }
      args = { ...args, ...tmp };
    }
    if (part6Ref.saveRecord) {
      let tmp = part6Ref.saveRecord();
      if (tmp.files) {
        args.files = tmp.files;
      }
      if (tmp.comments) {
        args.comments = tmp.comments;
      }
      if (tmp.items) {
        let tmpRows = Object.values(tmp.items);
        for (let i in tmpRows) {
          args.items.push(tmpRows[i]);
        }
      }
    }
    setFormData(args);
    return args;
  };

  const onReload = () => {
    getRows();
  };

  const onCloseForm = () => {
    setManageDialog(false);
  };

  console.log("formData:", formData);

  return (
    <React.Fragment>
      <div className="pageInfo">
        <ul className="breadcrumbs">
          <li>{LABELS.HOME}</li>
          <li className="dim">{" / "}</li>
          <li onClick={() => props.setDashMenu(LABELS.HOME)}>{LABELS.EHS}</li>
          <li className="dim">{" / "}</li>
          <li className="active">{LABELS.SOR}</li>
        </ul>
        <h4>{LABELS.SOR}</h4>
      </div>
      <div className="formGroup" style={{ width: "100%" }}>
        <label>{LABELS.PROJECT_NAME}</label>
        <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
          <div style={{ width: "30%", marginRight: 10 }}>
            <AutoComplete
              id={"projects"}
              list={projects}
              childRef={projectsRef}
              defaultValue={
                selectedRow.id
                  ? {
                      id: selectedRow.projectId,
                      label: selectedRow.projectName,
                    }
                  : null
              }
            />
          </div>
          <Btn text={LABELS.LOAD_GRAPH} onClick={getProjectInfo} />
        </div>
      </div>

      {/* Graphs */}
      {pieChart.series && (
        <div className="graphs">
          <HGraph
            width={"50%"}
            type={"column"}
            xAxis={columnChart.xAxis}
            yAxis={columnChart.yAxis}
            title={" "}
            exportTitle={" "}
          />
          <HGraph
            width={"50%"}
            type={"pie"}
            float={"right"}
            series={pieChart.series}
            title={" "}
            exportTitle={" "}
          />
        </div>
      )}

      {/* Master Grid */}
      {rows ? (
        <ExcelGrid
          id={"sor"}
          height={window.innerHeight * 0.6}
          cols={cols}
          rows={rows}
          exportOption={true}
          newRowOption={false}
          exportName={LABELS.SOR}
          onReload={onReload}
          onAdd={onAdd}
        />
      ) : (
        <div className="pageCenter">
          <Loader />
        </div>
      )}

      {/* Form Dialog Box */}
      {manageDialog && (
        <UiDialog
          title={
            selectedRow.id
              ? "Edit " + LABELS.SOR + ": " + selectedRow.projectName
              : "Add New " + LABELS.SOR
          }
          open={true}
          size={"xl"}
          onClose={() => {
            setManageDialog(false);
            setFormData({});
            setPageNo(1);
          }}
        >
          <>
            <div style={{ width: "100%", flexDirection: "column" }}>
              <Part1
                childRef={part1Ref}
                editRows={editRows}
                orgList={orgList}
                selectedRow={selectedRow}
                showNotify={(variant, text) =>
                  COMMON_JS.showNotify(props, variant, text)
                }
              />

              <PartLast
                childRef={part6Ref}
                selectedRow={selectedRow}
                uploads={uploads}
                setUploads={(val) => {
                  setUploads(val);
                }}
              />

              <ModalBtmBtns
                onSave={manageSor}
                onCancel={onCloseForm}
                disabled={editRows ? false : true}
              />
            </div>
          </>
        </UiDialog>
      )}
    </React.Fragment>
  );
};

export default withSnackbar(SafetyObservationRecords);
