import React, { useState, useEffect, useRef } from "react";

import ExcelGrid from "components/Custom/ExcelGrid";
import Loader from "components/Custom/Loader";

const dataJson = require("../gridData.json");

interface defaultProps {
  childRef: any;
  editRows: any[];
}

const Part4 = (props: defaultProps) => {
  const { childRef, editRows } = props;

  const [chemicalCols, setChemicalols] = useState<any[]>([
    {
      key: "chemicals",
      name: "Chemicals ",
      type: "text",
      editable: false,
      width: "60%",
    },
    {
      key: "checkBox",
      name: "Check Box",
      type: "checkbox",
      sortable: true,
      width: "10%",
      className: "textCenter",
    },
    {
      key: "action",
      name: "Action",
      type: "text",
      width: "30%",
    },
  ]);

  let tmpChemicalRows = dataJson["1.7"]["1.7.1"];
  for (let key in tmpChemicalRows) {
    tmpChemicalRows[key].checkBox = tmpChemicalRows[key].checkBox || false;
    tmpChemicalRows[key].action = tmpChemicalRows[key].action || "";
    tmpChemicalRows[key].safetyMonitorItemId =
      tmpChemicalRows[key].safetyMonitorItemId || -1;
  }
  if (!editRows) {
    tmpChemicalRows = null;
  } else if (editRows["records"]) {
    for (let key in tmpChemicalRows) {
      if (editRows["records"][tmpChemicalRows[key].id]) {
        const details = JSON.parse(
          editRows["records"][tmpChemicalRows[key].id].safetyDetails
        );
        const jsonData = {
          ...tmpChemicalRows[key],
          ...details,
          safetyMonitorItemId:
            editRows["records"][tmpChemicalRows[key].id].safetyMonitorItemId,
        };
        tmpChemicalRows[key] = jsonData;
      }
    }
  }
  const [chemicalRows, setChemicalRows] = useState<any[]>(tmpChemicalRows);

  const grid7Ref: any = useRef();

  useEffect(() => {
    childRef.saveRecord = saveRecord;
    return () => {
      childRef.saveRecord = null;
    };
  }, []);

  const saveRecord = () => {
    const section1 = grid7Ref.getRowData().map((s1) => {
      return {
        action: s1.action,
        id: s1.id,
        checkBox: s1.checkBox,
        safetyMonitorItemId: s1.safetyMonitorItemId || -1,
      };
    });

    const items = [...section1];
    const args = { items };
    return args;
  };

  return (
    <>
      <h4 className="ehsHeading1">{dataJson["1.7"]["heading1"]}</h4>
      <h5 className="ehsHeading2">{dataJson["1.7"]["heading2"]}</h5>
      {tmpChemicalRows ? (
        <ExcelGrid
          childRef={grid7Ref}
          cols={chemicalCols}
          rows={tmpChemicalRows}
          noTotalRows={true}
        />
      ) : (
        <div className="pageCenter">
          <Loader />
        </div>
      )}
    </>
  );
};

export default Part4;
