/*
 * Safety Monitoring & Measurement Form
 */

import React, { useRef, useEffect, useState } from "react";
import { withSnackbar } from "notistack";
import { IconButton, Tooltip } from "@mui/material";
import { Edit as IconEdit } from "@mui/icons-material";

import UiDialog from "components/Custom/UiDialog";
import ExcelGrid from "components/Custom/ExcelGrid";
import AutoComplete from "components/Custom/AutoComplete";
import SkeletonBox from "components/Custom/SkeletonBox";
import ModalBtmBtns from "components/Custom/ModalBtmBtns";
import Loader from "components/Custom/Loader";
import HGraph from "components/Custom/HGraph";
import PartLast from "./sections/PartLast";

import { LABELS, NOTIFY, SUCCESS, WARNINGS } from "common/config";
import { ApiHome } from "apihandlers/home";
import { ApiEhs } from "apihandlers/ehs";
import { COMMON_JS } from "common/scripts";
import Btn from "components/Custom/Btn";
import TxtBox from "components/Custom/TxtBox";

const API_HANDLER_HOME = new ApiHome();
const API_HANDLER_EHS = new ApiEhs();

const dataJson = require("./gridData.json");

const IncidentManagement = (props: any) => {
  const {} = props;
  const mainGridCols = [
    {
      key: "slNo",
      name: "Sl No.",
      type: "number",
      width: "5%",
      editable: false,
      sortable: true,
      align: "center",
    },
    {
      key: "incidentNo",
      name: "Incident No",
      type: "text",
      width: "15%",
      editable: false,
      sortable: true,
    },
    {
      key: "locationName",
      name: "Location",
      type: "text",
      width: "30%",
      editable: false,
      sortable: true,
    },
    {
      key: "incidentStatus",
      name: "Incident Status",
      type: "text",
      width: "15%",
      editable: false,
      sortable: true,
    },
    {
      key: "incidentDt",
      name: "Incident date",
      type: "text",
      width: "15%",
      editable: false,
      sortable: true,
    },
    {
      key: "createdBy",
      name: "created By",
      type: "text",
      width: "15%",
      editable: false,
      sortable: true,
    },
    {
      key: "options",
      name: "Options",
      type: "html",
      width: "5%",
      editable: false,
      sortable: false,
      renderCell: ({ row }: any) => (
        <div className="options">
          <Tooltip title={LABELS.EDIT} placement="bottom">
            <IconButton className="iconBtn" onClick={() => onEditRow(row)}>
              <IconEdit />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];
  const [loading, setLoading] = useState<boolean>(true);
  const [projects, setProjects] = useState<any[]>([]);
  const [cols, setCols] = useState<any[]>(mainGridCols);
  const [rows, setRows] = useState<any[]>(null);
  const [columnChart, setColumnChart] = useState<any>({});
  const [pieChart, setPieChart] = useState<any>({});
  const [manageDialog, setManageDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [editRows, setEditRows] = useState<any[]>(null);
  const [pageNo, setPageNo] = useState(1);
  const [totalPages, setTotalPages] = useState(6);
  const [formData, setFormData] = useState<any>({});
  const [uploads, setUploads] = useState<any[]>([]);
  const [orgList, setOrgList] = useState<any[]>([]);
  const [contractorList, setContractorList] = useState<any[]>([]);
  const [privateList, setPrivateList] = useState([
    {
      id: "true",
      label: "Yes",
    },
    {
      id: "false",
      label: "No",
    },
  ]);
  const [reportableList, setReportableList] = useState([
    {
      id: "true",
      label: "Yes",
    },
    {
      id: "false",
      label: "No",
    },
  ]);
  const [statusList, setStatusList] = useState([
    {
      id: "Open",
      label: "Open",
    },
    {
      id: "Close",
      label: "Close",
    },
  ]);

  const privateRef: any = useRef();
  const reportRef: any = useRef();
  const statusRef: any = useRef();
  const contractorRef: any = useRef();
  const projectsRef: any = useRef();
  const part1Ref: any = useRef();
  const part2Ref: any = useRef();
  const part3Ref: any = useRef();
  const part4Ref: any = useRef();
  const part5Ref: any = useRef();
  const part6Ref: any = useRef();

  useEffect(() => {
    return () => {
      cleanUp();
    };
  }, []);

  useEffect(() => {
    if (loading) {
      getContractors();
    }
  }, [loading]);

  const getContractors = () => {
    const args = { dropdown: true };
    API_HANDLER_EHS.getContractor(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        const list = response.records.map((c: any) => {
          return {
            id: c.id,
            label: c.nameOfContractor,
          };
        });
        console.log("formData: list:", list);
        setContractorList(list);
        getRows();
      }
    });
  };
  const getOrgs = () => {
    const args = {};
    API_HANDLER_HOME.getOrganizations(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        setOrgList(
          response.records.map((o: any) => {
            return {
              id: o.id,
              label: o.orgName,
            };
          })
        );
        getRows();
      }
    });
  };

  const getProjects = () => {
    const args = { dropdown: true };
    API_HANDLER_HOME.getProjects(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        setProjects(response.records);
        getRows();
      }
    });
  };

  const cleanUp = () => {
    setRows(null);
    setLoading(true);
    setManageDialog(false);
    setSelectedRow({});
    console.log("Cleaned up");
  };

  const getRows = () => {
    setRows(null);
    const args = {};
    API_HANDLER_EHS.getIncidents(args).then((res: any) => {
      const { status, response } = res;
      if (status === 200) {
        setRows(response.records);
        setColumnChart(response.columnChart);
        setPieChart(response.pieChart);
        setLoading(false);
      }
    });
  };

  const onAdd = () => {
    setManageDialog(!manageDialog);
    setEditRows([]);
    setPageNo(1);
    setSelectedRow({});
  };

  const onEditRow = (row: any) => {
    setPageNo(1);
    setSelectedRow(row);
    setManageDialog(true);
    setEditRows(null);
  };

  const getRowInfo = (incidentId) => {
    const args = { incidentId: selectedRow.id || -1 };

    API_HANDLER_EHS.getIncidentInfo(args).then((res: any) => {
      const { status, response } = res;
      if (status === 200) {
        setEditRows(response);
      }
    });
  };

  const getProjectInfo = () => {
    const projectId = projectsRef.current.selectedValue.id;
    if (!projectId) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.PROJECT_EMPTY);
      return;
    }
    const args = {
      projectId,
    };
    API_HANDLER_EHS.getSafetyMonitoring(args).then((res: any) => {
      const { status, response } = res;
      if (status === 200) {
        setColumnChart(response.columnChart);
        setPieChart(response.pieChart);
      }
    });
  };

  const manageIncident = () => {
    const locationName = (
      document.getElementById("locationName") as HTMLInputElement
    ).value.trim();
    const incidentDt = (
      document.getElementById("incidentDt") as HTMLInputElement
    ).value.trim();
    const incidentDesciption = (
      document.getElementById("incidentDesciption") as HTMLInputElement
    ).value.trim();
    const incidentDetails = (
      document.getElementById("incidentDetails") as HTMLInputElement
    ).value.trim();
    const distributionDetails = (
      document.getElementById("distributionDetails") as HTMLInputElement
    ).value.trim();
    const isPrivate = privateRef.current.selectedValue.id;
    const incidentStatus = statusRef.current.selectedValue.id;
    const isReportable = reportRef.current.selectedValue.id;

    //const statusOfsafetyTraining = safetyTrainRef.current.selectedValue.id;

    if (distributionDetails === "") {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        "Please enter distribution details..!"
      );
      return null;
    }
    if (locationName === "") {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        "Please enter location details..!"
      );
      return null;
    }
    if (incidentDt === "") {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        "Please enter incident date..!"
      );
      return null;
    }
    if (incidentDetails === "") {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        "Please enter incident details..!"
      );
      return null;
    }
    if (incidentDesciption === "") {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        "Please enter incident description..!"
      );
      return null;
    }
    if (!isReportable) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        "Please select reportable feild..!"
      );
      return null;
    }
    if (!incidentStatus) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        "Please select status feild..!"
      );
      return null;
    }
    if (!isPrivate) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        "Please select private feild..!"
      );
      return null;
    }
    // if (!isInductionTraining) {
    //   COMMON_JS.showNotify(
    //     props,
    //     NOTIFY.WARNING,
    //     "Please select induction training..!"
    //   );
    //   return null;
    // }
    // if (trainingDt === "") {
    //   COMMON_JS.showNotify(
    //     props,
    //     NOTIFY.WARNING,
    //     "Please enter induction training date..!"
    //   );
    //   return null;
    // }
    // if (trainingNum === "") {
    //   COMMON_JS.showNotify(
    //     props,
    //     NOTIFY.WARNING,
    //     "Please enter induction training number..!"
    //   );
    //   return null;
    // }
    // if (!isSafePassTraining) {
    //   COMMON_JS.showNotify(
    //     props,
    //     NOTIFY.WARNING,
    //     "Please select safepass training..!"
    //   );
    //   return null;
    // }
    // if (safePassTrainingDt === "") {
    //   COMMON_JS.showNotify(
    //     props,
    //     NOTIFY.WARNING,
    //     "Please enter safepass training date..!"
    //   );
    //   return null;
    // }
    // if (safePassTrainingNum === "") {
    //   COMMON_JS.showNotify(
    //     props,
    //     NOTIFY.WARNING,
    //     "Please enter safepass training number..!"
    //   );
    //   return null;
    // }
    // if (safePassValidity === "") {
    //   COMMON_JS.showNotify(
    //     props,
    //     NOTIFY.WARNING,
    //     "Please enter safepass validity details..!"
    //   );
    //   return null;
    // }
    // if (!statusOfSafePass) {
    //   COMMON_JS.showNotify(
    //     props,
    //     NOTIFY.WARNING,
    //     "Please select status of safepass..!"
    //   );
    //   return null;
    // }
    // if (insuranceComp === "") {
    //   COMMON_JS.showNotify(
    //     props,
    //     NOTIFY.WARNING,
    //     "Please enter insurence company..!"
    //   );
    //   return null;
    // }
    // if (insuranceDt === "") {
    //   COMMON_JS.showNotify(
    //     props,
    //     NOTIFY.WARNING,
    //     "Please enter insurence date..!"
    //   );
    //   return null;
    // }
    // if (insuranceExpiry === "") {
    //   COMMON_JS.showNotify(
    //     props,
    //     NOTIFY.WARNING,
    //     "Please enter insurence expiry date..!"
    //   );
    //   return null;
    // }
    // if (insuranceValue === "") {
    //   COMMON_JS.showNotify(
    //     props,
    //     NOTIFY.WARNING,
    //     "Please enter insurence value..!"
    //   );
    //   return null;
    // }
    // if (!statusOfInsurance) {
    //   COMMON_JS.showNotify(
    //     props,
    //     NOTIFY.WARNING,
    //     "Please select status of insurance..!"
    //   );
    //   return null;
    // }
    // if (!isSafetyTraining) {
    //   COMMON_JS.showNotify(
    //     props,
    //     NOTIFY.WARNING,
    //     "Please select safety training..!"
    //   );
    //   return null;
    // }
    // if (safetyTrainingNum === "") {
    //   COMMON_JS.showNotify(
    //     props,
    //     NOTIFY.WARNING,
    //     "Please enter safety training number..!"
    //   );
    //   return null;
    // }
    // if (safetyTrainingDt === "") {
    //   COMMON_JS.showNotify(
    //     props,
    //     NOTIFY.WARNING,
    //     "Please enter safety training date..!"
    //   );
    //   return null;
    // }
    // if (safetyTrainingValidity === "") {
    //   COMMON_JS.showNotify(
    //     props,
    //     NOTIFY.WARNING,
    //     "Please enter safety training validity..!"
    //   );
    //   return null;
    // }
    // if (!statusOfsafetyTraining) {
    //   COMMON_JS.showNotify(
    //     props,
    //     NOTIFY.WARNING,
    //     "Please select status of safety training..!"
    //   );
    //   return null;
    // }
    let args: any = updateForm();
    args = {
      ...args,
      incidentId: selectedRow.id || -1,
      locationName,
      incidentDt,
      isPrivate,
      incidentStatus,
      isReportable,
      incidentDesciption,
      incidentDetails,
      distributionDetails,
      files: uploads,
    };

    console.log("manageIncident: ", args);

    // Validations
    setEditRows(null);
    API_HANDLER_EHS.manageIncident(args).then((res) => {
      if (res.status === 200) {
        COMMON_JS.showNotify(
          props,
          NOTIFY.SUCCESS,
          SUCCESS.SAFETY_MONITOR_SAVED
        );
        setSelectedRow({});
        setManageDialog(false);
        getRows();
      } else {
        COMMON_JS.showNotify(props, NOTIFY.ERROR, res.error);
      }
    });
  };

  const updateForm = () => {
    let args: any = {};
    setFormData(args);
    return args;
  };

  const onReload = () => {
    getRows();
  };

  const onCloseForm = () => {
    setManageDialog(false);
  };

  console.log("selectedRow:", selectedRow);

  return (
    <React.Fragment>
      <div className="pageInfo">
        <ul className="breadcrumbs">
          <li>{LABELS.HOME}</li>
          <li className="dim">{" / "}</li>
          <li onClick={() => props.setDashMenu(LABELS.HOME)}>{LABELS.EHS}</li>
          <li className="dim">{" / "}</li>
          <li className="active">{LABELS.INCIDENT_MANAGEMENT}</li>
        </ul>
        <h4>{LABELS.INCIDENT_MANAGEMENT}</h4>
      </div>

      {/* Graphs */}
      {pieChart.series && (
        <div className="graphs">
          <HGraph
            width={"50%"}
            type={"column"}
            xAxis={columnChart.xAxis}
            yAxis={columnChart.yAxis}
            title={" "}
            exportTitle={" "}
          />
          <HGraph
            width={"50%"}
            type={"pie"}
            float={"right"}
            series={pieChart.series}
            title={" "}
            exportTitle={" "}
          />
        </div>
      )}

      {/* Master Grid */}
      {rows ? (
        <ExcelGrid
          cols={cols}
          rows={rows}
          exportOption={true}
          newRowOption={false}
          exportName={LABELS.INCIDENT_MANAGEMENT}
          onReload={onReload}
          onAdd={onAdd}
        />
      ) : (
        <div className="pageCenter">
          <Loader />
        </div>
      )}

      {/* Form Dialog Box */}
      {manageDialog && (
        <UiDialog
          title={
            selectedRow.id
              ? "Edit " +
                LABELS.INCIDENT_MANAGEMENT +
                ": " +
                selectedRow.locationName
              : "Add New " + LABELS.INCIDENT_MANAGEMENT
          }
          open={true}
          size={"xl"}
          onClose={() => {
            setManageDialog(false);
            setFormData({});
            setPageNo(1);
          }}
        >
          <>
            <div style={{ width: "100%", flexDirection: "column" }}>
              {/* 1.1 */}
              <>
                <div className="formRow">
                  <div className="formGroup" style={{ width: "30%" }}>
                    <label>{"Incident Location"}</label>
                    <TxtBox
                      type={"text"}
                      id="locationName"
                      defaultValue={
                        selectedRow.id ? selectedRow.locationName : ""
                      }
                    />
                  </div>
                  <div className="formGroup" style={{ width: "30%" }}>
                    <label>{"Event Date"}</label>
                    <TxtBox
                      type={"date"}
                      id="incidentDt"
                      defaultValue={
                        selectedRow.id ? selectedRow.incidentDt : ""
                      }
                    />
                  </div>
                  <div className="formGroup" style={{ width: "30%" }}>
                    <label>{"Private"}</label>
                    <AutoComplete
                      list={privateList}
                      childRef={privateRef}
                      defaultValue={
                        selectedRow.id
                          ? selectedRow.isPrivate
                            ? privateList[0]
                            : privateList[1]
                          : null
                      }
                    />
                  </div>
                </div>
                <div className="formRow">
                  <div className="formGroup" style={{ width: "30%" }}>
                    <label>{" Incident Status"}</label>
                    <AutoComplete
                      list={statusList}
                      childRef={statusRef}
                      defaultValue={
                        selectedRow.id
                          ? selectedRow.incidentStatus
                            ? statusList[0]
                            : statusList[1]
                          : null
                      }
                    />
                  </div>
                  <div className="formGroup" style={{ width: "30%" }}>
                    <label>{"Reportable"}</label>
                    <AutoComplete
                      list={reportableList}
                      childRef={reportRef}
                      defaultValue={
                        selectedRow.id
                          ? selectedRow.isReportable
                            ? reportableList[0]
                            : reportableList[1]
                          : null
                      }
                    />
                  </div>
                  <div className="formGroup" style={{ width: "30%" }}></div>
                </div>
                <div className="formGroup" style={{ width: "60%" }}>
                  <label>{" Incident Description"}</label>
                  <TxtBox
                    type={"text"}
                    id="incidentDesciption"
                    rows={3}
                    defaultValue={
                      selectedRow.id ? selectedRow.incidentDesciption : ""
                    }
                  />
                </div>
                <div className="formGroup" style={{ width: "60%" }}>
                  <label>{" Incident Details"}</label>
                  <TxtBox
                    type={"text"}
                    id="incidentDetails"
                    rows={3}
                    defaultValue={
                      selectedRow.id ? selectedRow.incidentDetails : ""
                    }
                  />
                </div>
                <div className="formGroup" style={{ width: "60%" }}>
                  <label>{" Distribution Details"}</label>
                  <TxtBox
                    type={"text"}
                    id="distributionDetails"
                    rows={3}
                    defaultValue={
                      selectedRow.id ? selectedRow.distributionDetails : ""
                    }
                  />
                </div>
              </>

              <PartLast
                childRef={part6Ref}
                selectedRow={selectedRow}
                uploads={uploads}
                setUploads={(val) => {
                  setUploads(val);
                }}
              />

              <ModalBtmBtns
                onSave={manageIncident}
                onCancel={onCloseForm}
                //disabled={editRows === null}
              />
            </div>
          </>
        </UiDialog>
      )}
    </React.Fragment>
  );
};

export default withSnackbar(IncidentManagement);
