import React from "react";

import Btn from "components/Custom/Btn";

import { COLORS, LABELS } from "common/config";

interface IModalProps {
  disabled?: boolean;
  onSave: Function;
  onCancel: Function;
}

const ModalBtmBtns = (props: IModalProps) => {
  const { disabled, onSave, onCancel } = props;
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        width: "100%",
      }}
    >
      <div
        style={{
          width: "35%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {onCancel && (
          <Btn
            text={LABELS.CANCEL}
            bgColor={COLORS.DIM_DARK_GRAY}
            marginRight={15}
            disabled={disabled === null ? false : disabled}
            onClick={onCancel}
          />
        )}
        {onSave && (
          <Btn
            text={LABELS.SAVE}
            disabled={disabled}
            bgColor={COLORS.PRIMARY}
            onClick={onSave}
          />
        )}
      </div>
    </div>
  );
};

export default ModalBtmBtns;
