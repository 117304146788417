import { Radio } from "@mui/material";
import React from "react";
import "../styles.css";

const Row2 = ({ data }) => {
  return (
    <>
      <tr>
        <td>{"4.3"}</td>
        <td>{"Management System Scope"}</td>
        <td>{"6"}</td>
        <td className="htmlText">
          {
            "Does your organization determine the boundaries and applicability of the quality management system to establish its scope?"
          }
        </td>
        <td>
          <body>
            <h2>
              Consideration of boundaries and applicability of the QMS includes:
            </h2>
            <ol>
              <li>Range of products and services;</li>
              <li>Different sites and activities;</li>
              <li>External provision of processes, products, and services.</li>
            </ol>
          </body>
        </td>
        <td>
          <div className="radio-button">
            <input
              type="radio"
              id="radio21"
              name="1.6"
              value={"Conforms"}
              defaultChecked={data["6"] === "Conforms"}
            />
            <label htmlFor="radio21">Conforms</label>
            <input
              type="radio"
              id="radio22"
              name="1.6"
              value={"Minor NC"}
              defaultChecked={data["6"] === "Minor NC"}
            />
            <label htmlFor="radio22">Minor NC</label>
            <input
              type="radio"
              id="radio23"
              name="1.6"
              value={"Major NC"}
              defaultChecked={data["6"] === "Major NC"}
            />
            <label htmlFor="radio23">Major NC</label>
            <input
              type="radio"
              id="radio24"
              name="1.6"
              value={"OFI"}
              defaultChecked={data["6"] === "OFI"}
            />
            <label htmlFor="radio24">OFI</label>
          </div>
        </td>
        <td>
          <textarea id="eve7" defaultValue={data["32"]} rows={10}></textarea>
        </td>
        <td>
          <textarea id="opp7" defaultValue={data["56"]} rows={10}></textarea>
        </td>
      </tr>
      <tr>
        <td>{"4.3"}</td>
        <td>{"Management System Scope"}</td>
        <td>{"7"}</td>
        <td className="htmlText">
          {
            "When determining this scope, has your organization considered the external and internal issues referred to in 4.1?"
          }
        </td>
        <td>
          {
            "Ensure that issues relating to organizational context and the needs of interested parties encompassed in the scope. A lack of a documented process will require more reliance on objective evidence from interviews with Top management and the evaluation of external and internal issues (see 4.1)."
          }
        </td>
        <td>
          <div className="radio-button">
            <input
              type="radio"
              id="radio25"
              name="1.7"
              value={"Conforms"}
              defaultChecked={data["7"] === "Conforms"}
            />
            <label htmlFor="radio25">Conforms</label>
            <input
              type="radio"
              id="radio26"
              name="1.7"
              value={"Minor NC"}
              defaultChecked={data["7"] === "Minor NC"}
            />
            <label htmlFor="radio26">Minor NC</label>
            <input
              type="radio"
              id="radio27"
              name="1.7"
              value={"Major NC"}
              defaultChecked={data["7"] === "Major NC"}
            />
            <label htmlFor="radio27">Major N</label>
            <input
              type="radio"
              id="radio28"
              name="1.7"
              value={"OFI"}
              defaultChecked={data["7"] === "OFI"}
            />
            <label htmlFor="radio28">OFI</label>
          </div>
        </td>
        <td>
          <textarea id="eve8" defaultValue={data["33"]} rows={10}></textarea>
        </td>
        <td>
          <textarea id="opp8" defaultValue={data["57"]} rows={10}></textarea>
        </td>
      </tr>
      <tr>
        <td>{"4.3"}</td>
        <td>{"Management System Scope"}</td>
        <td>{"8"}</td>
        <td className="htmlText">
          {
            "When determining this scope, has your organization considered the requirements of relevant interested parties referred to in 4.2?"
          }
        </td>
        <td>
          {
            "Ensure that issues relating to organizational context and the needs of interested parties encompassed in the scope. A lack of a documented process will require more reliance on objective evidence from interviews with Top management and the evaluation to the requirements of relevant interested parties (see 4.2)."
          }
        </td>
        <td>
          <div className="radio-button">
            <input
              type="radio"
              id="radio29"
              name="1.8"
              value={"Conforms"}
              defaultChecked={data["8"] === "Conforms"}
            />
            <label htmlFor="radio29">Conforms</label>
            <input
              type="radio"
              id="radio30"
              name="1.8"
              value={"Minor NC"}
              defaultChecked={data["8"] === "Minor NC"}
            />
            <label htmlFor="radio30">Minor NC</label>
            <input
              type="radio"
              id="radio31"
              name="1.8"
              value={"Major NC"}
              defaultChecked={data["8"] === "Major NC"}
            />
            <label htmlFor="radio31">Major NC</label>
            <input
              type="radio"
              id="radio32"
              name="1.8"
              value={"OFI"}
              defaultChecked={data["8"] === "OFI"}
            />
            <label htmlFor="radio32">OFI </label>
          </div>
        </td>
        <td>
          <textarea id="eve9" defaultValue={data["34"]} rows={10}></textarea>
        </td>
        <td>
          <textarea id="opp9" defaultValue={data["58"]} rows={10}></textarea>
        </td>
      </tr>
      <tr>
        <td>{"4.3"}</td>
        <td>{"Management System Scope"}</td>
        <td>{"9"}</td>
        <td className="htmlText">
          {
            "When determining this scope, has your organization considered all relevant products, services and work-related activities, functions and physical boundaries to the quality management system?"
          }
        </td>
        <td>
          {
            "Obtain evidence that clearly defines what your organisation sells, produces, or provides services for. Link this to the relevant standards or ACOPs that they are governed by."
          }
        </td>
        <td>
          <div className="radio-button">
            <input
              type="radio"
              id="radio33"
              name="1.9"
              value={"Conforms"}
              defaultChecked={data["9"] === "Conforms"}
            />
            <label htmlFor="radio33">Conforms</label>
            <input
              type="radio"
              id="radio34"
              name="1.9"
              value={"Minor NC"}
              defaultChecked={data["9"] === "Minor NC"}
            />
            <label htmlFor="radio34">Minor NC</label>
            <input
              type="radio"
              id="radio35"
              name="1.9"
              value={"Major NC"}
              defaultChecked={data["9"] === "Major NC"}
            />
            <label htmlFor="radio35">Major NC</label>
            <input
              type="radio"
              id="radio36"
              name="1.9"
              value={"OFI"}
              defaultChecked={data["9"] === "OFI"}
            />
            <label htmlFor="radio36">OFI</label>
          </div>
        </td>
        <td>
          <textarea id="eve10" defaultValue={data["35"]} rows={10}></textarea>
        </td>
        <td>
          <textarea id="opp10" defaultValue={data["59"]} rows={10}></textarea>
        </td>
      </tr>
      <tr>
        <td>{"4.3"}</td>
        <td>{"Management System Scope"}</td>
        <td>{"10"}</td>
        <td className="htmlText">
          {
            "Has your organization applied all the requirements of ISO 9001:2015 if they are applicable within the determined scope of the quality management system?"
          }
        </td>
        <td>
          {
            "Describe the application of ISO 9001 within the scope was determined, and how has it been applied by your organization."
          }
        </td>
        <td>
          <div className="radio-button">
            <input
              type="radio"
              id="radio37"
              name="1.10"
              value={"Conforms"}
              defaultChecked={data["10"] === "Conforms"}
            />{" "}
            <label htmlFor="radio37">Conforms</label>
            <input
              type="radio"
              id="radio38"
              name="1.10"
              value={"Minor NC"}
              defaultChecked={data["10"] === "Minor NC"}
            />
            <label htmlFor="radio38">Minor NC</label>
            <input
              type="radio"
              id="radio39"
              name="1.10"
              value={"Major NC"}
              defaultChecked={data["10"] === "Major NC"}
            />
            <label htmlFor="radio39">Major NC</label>
            <input
              type="radio"
              id="radio40"
              name="1.10"
              value={"OFI"}
              defaultChecked={data["10"] === "OFI"}
            />
            <label htmlFor="radio40">OFI</label>
          </div>
        </td>
        <td>
          <textarea id="eve11" defaultValue={data["36"]} rows={10}></textarea>
        </td>
        <td>
          <textarea id="opp11" defaultValue={data["60"]} rows={10}></textarea>
        </td>
      </tr>
      <tr>
        <td>{"4.3"}</td>
        <td>{"Management System Scope"}</td>
        <td>{"11"}</td>
        <td className="htmlText">
          {
            "Does the scope state the types of products and services covered, and provide justification for any requirement of ISO 9001:2015 that your organization determines is not applicable to the scope of its quality management system?"
          }
        </td>
        <td>
          {
            "Describe how the application of ISO 9001 within the scope was determined, and how any clause exclusions are justified. There must be alignment between the documented scope of the organization’s QMS and their agreed scope of certification."
          }
        </td>
        <td>
          <div className="radio-button">
            <input
              type="radio"
              id="radio41"
              name="1.11"
              value={"Conforms"}
              defaultChecked={data["11"] === "Conforms"}
            />
            <label htmlFor="radio41">Conforms</label>
            <input
              type="radio"
              id="radio42"
              name="1.11"
              value={"Minor NC"}
              defaultChecked={data["11"] === "Minor NC"}
            />
            <label htmlFor="radio42">Minor NC</label>
            <input
              type="radio"
              id="radio43"
              name="1.11"
              value={"Major NC"}
              defaultChecked={data["11"] === "Major NC"}
            />
            <label htmlFor="radio43">Major NC</label>
            <input
              type="radio"
              id="radio44"
              name="1.11"
              value={"OFI"}
              defaultChecked={data["11"] === "OFI"}
            />
            <label htmlFor="radio44">OFI</label>
          </div>
        </td>
        <td>
          <textarea id="eve12" defaultValue={data["37"]} rows={10}></textarea>
        </td>
        <td>
          <textarea id="opp12" defaultValue={data["61"]} rows={10}></textarea>
        </td>
      </tr>
      <tr>
        <td>{"4.3"}</td>
        <td>{"Management System Scope"}</td>
        <td>{"12"}</td>
        <td className="htmlText">
          {
            "Is the scope of your organization’s quality management system available and maintained as documented information and available to interested parties and workers? (See 7.5.1a)"
          }
        </td>
        <td>
          {
            "Verify objective evidence that the scope of documented and available to interested parties. A statement from your organization that the scope will be provided upon request may be accepted as objective evidence."
          }
        </td>
        <td>
          <div className="radio-button">
            <input
              type="radio"
              id="radio45"
              name="1.12"
              value={"Conforms"}
              defaultChecked={data["12"] === "Conforms"}
            />
            <label htmlFor="radio45">Conforms</label>
            <input
              type="radio"
              id="radio46"
              name="1.12"
              value={"Minor NC"}
              defaultChecked={data["12"] === "Minor NC"}
            />
            <label htmlFor="radio46">Minor NC</label>
            <input
              type="radio"
              id="radio47"
              name="1.12"
              value={"Major NC"}
              defaultChecked={data["12"] === "Major NC"}
            />
            <label htmlFor="radio47">Major NC</label>
            <input
              type="radio"
              id="radio48"
              name="1.12"
              value={"OFI"}
              defaultChecked={data["12"] === "OFI"}
            />{" "}
            <label htmlFor="radio48">OFI</label>
          </div>
        </td>
        <td>
          <textarea id="eve13" defaultValue={data["38"]} rows={10}></textarea>
        </td>
        <td>
          <textarea id="opp13" defaultValue={data["62"]} rows={10}></textarea>
        </td>
      </tr>
    </>
  );
};

export default Row2;
