import { IApiResponse } from "common/models";
import {
  IArgsUserLogin,
  IArgsCheckLogin,
  IArgsUserLogout,
  IArgsSignup,
} from "./models";
import { COMMON_JS } from "common/scripts";
import { API_CONFIG } from "common/config";

interface ILogin {
  userLogin: (args: IArgsUserLogin) => Promise<IApiResponse>;
  checkLogin: (args: IArgsUserLogin) => Promise<IApiResponse>;
  userLogout: (args: IArgsUserLogin) => Promise<IApiResponse>;
}

export class ApiLogin implements ILogin {
  /*
   * INFO: User Login
   * LOGIC: User get authenticated
   * METHOD: POST
   * DATA: {
   *   "userName": "admin",
   *   "password": "123456", // Min: 6 Characters
   * }
   * RESPONSE: {
   *  "userId": 1234,
   *  "userName": "admin",
   *  "fullName": "Abc Xyz",
   *  "role": {
   *    "id": 1,
   *    "name": "admin",
   *   }
   * }
   * QUERY: "SELECT * FROM XYZ"
   */
  userLogin = async (args: IArgsUserLogin): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/login",
        API_CONFIG.METHODS.POST,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };

  changePassword = async (args: {}): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/change-password",
        API_CONFIG.METHODS.POST,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };

  /*
   * INFO: Checking User Login Session
   * LOGIC: Getting the user login status
   * METHOD: GET
   * DATA: {}
   * RESPONSE: {
   *  "status": true
   * }
   * QUERY: "SELECT * FROM XYZ"
   */
  checkLogin = async (args: IArgsCheckLogin): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/checklogin",
        API_CONFIG.METHODS.POST,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };

  /*
   * INFO: User Logout
   * LOGIC: User session should be cleared both in local & server
   * METHOD: POST
   * DATA: {}
   * RESPONSE: {}
   * QUERY: "SELECT * FROM XYZ"
   */
  userLogout = async (args: IArgsUserLogout): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/logout",
        API_CONFIG.METHODS.POST,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };
}
