import { Radio } from "@mui/material";
import React from "react";
import "../styles.css";

const Row3 = ({ data }) => {
  return (
    <>
      <tr>
        <td>{"4.4"}</td>
        <td>{"Management System Processes"}</td>
        <td>{"13"}</td>
        <td className="htmlText">
          {
            "Has your organization established, implemented, maintained and continually improved its quality management system, including the processes needed and their interactions, in accordance with the requirements of ISO 9001:2015?"
          }
        </td>
        <td>
          {
            "ISO 9001 includes specific requirements necessary for the adoption of processes when developing, implementing and improving your QMS. This requires your organization to systematically define and manage its processes, and their interactions, in order to achieve the intended results in accordance with both the policy and strategic direction of your organization."
          }
        </td>
        <td>
          <div className="radio-button">
            <input
              type="radio"
              name="1.13"
              id="radio49"
              value={"Conforms"}
              defaultChecked={data["13"] === "Conforms"}
            />
            <label htmlFor="radio49">Conforms</label>
            <input
              type="radio"
              id="radio50"
              name="1.13"
              value={"Minor NC"}
              defaultChecked={data["13"] === "Minor NC"}
            />
            <label htmlFor="radio50">Minor NC</label>
            <input
              type="radio"
              id="radio51"
              name="1.13"
              value={"Major NC"}
              defaultChecked={data["13"] === "Major NC"}
            />
            <label htmlFor="radio51">Major NC</label>
            <input
              type="radio"
              id="radio52"
              name="1.13"
              value={"OFI"}
              defaultChecked={data["13"] === "OFI"}
            />
            <label htmlFor="radio52">OFI</label>
          </div>
        </td>
        <td>
          <textarea id="eve14" defaultValue={data["39"]} rows={10}></textarea>
        </td>
        <td>
          <textarea id="opp14" defaultValue={data["63"]} rows={10}></textarea>
        </td>
      </tr>
      <tr>
        <td>{"4.4"}</td>
        <td>{"Management System Processes"}</td>
        <td>{"14"}</td>
        <td className="htmlText">
          {
            "Has your organization determined the process required for the quality management system,  including their interactions, in accordance with requirements and their application throughout the organization?"
          }
        </td>
        <td>
          {
            "A process is set of interrelated or interacting activities which transforms inputs into outputs. A procedure is a specified way of fulfilling an activity within a process. QMS processes should be defined to address: suppliers, manufacturers, internal or external customer issues, resources, design, operation, production, logistics, products, and services, customers and end-users."
          }
        </td>
        <td>
          <div className="radio-button">
            <input
              type="radio"
              id="radio53"
              name="1.14"
              value={"Conforms"}
              defaultChecked={data["14"] === "Conforms"}
            />{" "}
            <label htmlFor="radio53">Conforms</label>
            <input
              type="radio"
              id="radio54"
              name="1.14"
              value={"Minor NC"}
              defaultChecked={data["14"] === "Minor NC"}
            />
            <label htmlFor="radio54">Minor NC</label>
            <input
              type="radio"
              id="radio55"
              name="1.14"
              value={"Major NC"}
              defaultChecked={data["14"] === "Major NC"}
            />
            <label htmlFor="radio55">Major NC</label>
            <input
              type="radio"
              id="radio56"
              name="1.14"
              value={"OFI"}
              defaultChecked={data["14"] === "OFI"}
            />
            <label htmlFor="radio56">OFI</label>
          </div>
        </td>
        <td>
          <textarea id="eve15" defaultValue={data["40"]} rows={10}></textarea>
        </td>
        <td>
          <textarea id="opp15" defaultValue={data["64"]} rows={10}></textarea>
        </td>
      </tr>
      <tr>
        <td>{"4.4"}</td>
        <td>{"Management System Processes"}</td>
        <td>{"15"}</td>
        <td className="htmlText">
          {
            "Has your organization determined the inputs required and the outputs expected from these processes?"
          }
        </td>
        <td>
          {
            "What are the expected inputs and outputs from each of the identified processes, together with assignment of responsibilities and authorities e.g. Process Owner, Process Champion, Lead Process User and Process User?"
          }
        </td>
        <td>
          <div className="radio-button">
            <input
              type="radio"
              id="radio57"
              name="1.15"
              value={"Conforms"}
              defaultChecked={data["15"] === "Conforms"}
            />
            <label htmlFor="radio57">Conforms</label>
            <input
              type="radio"
              id="radio58"
              name="1.15"
              value={"Minor NC"}
              defaultChecked={data["15"] === "Minor NC"}
            />
            <label htmlFor="radio58">Minor NC</label>
            <input
              type="radio"
              id="radio59"
              name="1.15"
              value={"Major NC"}
              defaultChecked={data["15"] === "Major NC"}
            />
            <label htmlFor="radio59">Major NC</label>
            <input
              type="radio"
              id="radio60"
              name="1.15"
              value={"OFI"}
              defaultChecked={data["15"] === "OFI"}
            />
            <label htmlFor="radio60">OFI</label>
          </div>
        </td>
        <td>
          <textarea id="eve16" defaultValue={data["41"]} rows={10}></textarea>
        </td>
        <td>
          <textarea id="opp16" defaultValue={data["65"]} rows={10}></textarea>
        </td>
      </tr>
      <tr>
        <td>{"4.4"}</td>
        <td>{"Management System Processes"}</td>
        <td>{"16"}</td>
        <td className="htmlText">
          {
            "Has your organization determined the sequence and interaction of these processes?"
          }
        </td>
        <td>
          {
            "Describe the identification of the processes needed for the QMS, including their sequence and interaction, e.g. E.g. process framework, process model, process groupings, process flow diagram, process mapping, value stream mapping, Turtle diagrams, SIPOC (Supplier, Input, Process, Output, and Customer) charts and process cards."
          }
        </td>
        <td>
          <div className="radio-button">
            <input
              type="radio"
              id="radio61"
              name="1.16"
              value={"Conforms"}
              defaultChecked={data["16"] === "Conforms"}
            />
            <label htmlFor="radio61">Conforms</label>
            <input
              type="radio"
              id="radio62"
              name="1.16"
              value={"Minor NC"}
              defaultChecked={data["16"] === "Minor NC"}
            />
            <label htmlFor="radio62">Minor NC</label>
            <input
              type="radio"
              id="radio63"
              name="1.16"
              value={"Major NC"}
              defaultChecked={data["16"] === "Major NC"}
            />
            <label htmlFor="radio63">Major NC</label>
            <input
              type="radio"
              id="radio64"
              name="1.16"
              value={"OFI"}
              defaultChecked={data["16"] === "OFI"}
            />
            <label htmlFor="radio64">OFI</label>
          </div>
        </td>
        <td>
          <textarea id="eve17" defaultValue={data["42"]} rows={10}></textarea>
        </td>
        <td>
          <textarea id="opp17" defaultValue={data["66"]} rows={10}></textarea>
        </td>
      </tr>
      <tr>
        <td>{"4.4"}</td>
        <td>{"Management System Processes"}</td>
        <td>{"17"}</td>
        <td className="htmlText">
          {
            "Has your organization determined and applied the criteria and methods (including monitoring, measurements and related performance indicators) needed to ensure the effective operation and control of these processes?"
          }
        </td>
        <td>
          {
            "Describe how what are the criteria, methods, measurement and related performance indicators needed to operate and control those processes? Criteria and methods to ensure effective operation and control of the identified processes, e.g. process monitoring indicators, process performance indicators, target setting, data collection, performance trends, and internal or external audit results."
          }
        </td>
        <td>
          <div className="radio-button">
            <input
              type="radio"
              id="radio65"
              name="1.17"
              value={"Conforms"}
              defaultChecked={data["17"] === "Conforms"}
            />
            <label htmlFor="radio65">Conforms</label>
            <input
              type="radio"
              id="radio66"
              name="1.17"
              value={"Minor NC"}
              defaultChecked={data["17"] === "Minor NC"}
            />
            <label htmlFor="radio66">Minor NC</label>
            <input
              type="radio"
              id="radio67"
              name="1.17"
              value={"Major NC"}
              defaultChecked={data["17"] === "Major NC"}
            />{" "}
            <label htmlFor="radio67">Major NC</label>
            <input
              type="radio"
              id="radio68"
              name="1.17"
              value={"OFI"}
              defaultChecked={data["17"] === "OFI"}
            />
            <label htmlFor="radio68">OFI</label>
          </div>
        </td>
        <td>
          <textarea id="eve18" defaultValue={data["43"]} rows={10}></textarea>
        </td>
        <td>
          <textarea id="opp18" defaultValue={data["67"]} rows={10}></textarea>
        </td>
      </tr>
      <tr>
        <td>{"4.4"}</td>
        <td>{"Management System Processes"}</td>
        <td>{"18"}</td>
        <td className="htmlText">
          {
            "Has your organization determined the resources needed for these processes and ensure their availability?"
          }
        </td>
        <td>
          {
            "Describe how resources are determined and how they are made available, this might duing operational planning or management reviews."
          }
        </td>
        <td>
          <div className="radio-button">
            <input
              type="radio"
              id="radio69"
              name="1.18"
              value={"Conforms"}
              defaultChecked={data["18"] === "Conforms"}
            />
            <label htmlFor="radio69">Conforms</label>
            <input
              type="radio"
              id="radio70"
              name="1.18"
              value={"Minor NC"}
              defaultChecked={data["18"] === "Minor NC"}
            />
            <label htmlFor="radio70">Minor NC</label>
            <input
              type="radio"
              id="radio71"
              name="1.18"
              value={"Major NC"}
              defaultChecked={data["18"] === "Major NC"}
            />
            <label htmlFor="radio71">Major NC</label>
            <input
              type="radio"
              id="radio72"
              name="1.18"
              value={"OFI"}
              defaultChecked={data["18"] === "OFI"}
            />
            <label htmlFor="radio72">OFI</label>
          </div>
        </td>
        <td>
          <textarea id="eve19" defaultValue={data["44"]} rows={10}></textarea>
        </td>
        <td>
          <textarea id="opp19" defaultValue={data["68"]} rows={10}></textarea>
        </td>
      </tr>
      <tr>
        <td>{"4.4"}</td>
        <td>{"Management System Processes"}</td>
        <td>{"19"}</td>
        <td className="htmlText">
          {
            "Has your organization assigned responsibilities and authorities for these processes?"
          }
        </td>
        <td>
          {
            "Describe how are responsibilities and authorities assigned for those processes. Information needed to ensure effective operation and control of the processes, e.g. defined process requirements (shall), good practice (should), defined roles, required competencies, associated training, and guidance."
          }
        </td>
        <td>
          <div className="radio-button">
            <input
              type="radio"
              id="radio73"
              name="1.19"
              value={"Conforms"}
              defaultChecked={data["19"] === "Conforms"}
            />
            <label htmlFor="radio73">Conforms</label>
            <input
              type="radio"
              id="radio74"
              name="1.19"
              value={"Minor NC"}
              defaultChecked={data["19"] === "Minor NC"}
            />
            <label htmlFor="radio74">Minor NC</label>
            <input
              type="radio"
              id="radio75"
              name="1.19"
              value={"Major NC"}
              defaultChecked={data["19"] === "Major NC"}
            />
            <label htmlFor="radio75">Major NC</label>
            <input
              type="radio"
              id="radio76"
              name="1.19"
              value={"OFI"}
              defaultChecked={data["19"] === "OFI"}
            />
            <label htmlFor="radio76">OFI</label>
          </div>
        </td>
        <td>
          <textarea id="eve20" defaultValue={data["45"]} rows={10}></textarea>
        </td>
        <td>
          <textarea id="opp20" defaultValue={data["69"]} rows={10}></textarea>
        </td>
      </tr>
      <tr>
        <td>{"4.4"}</td>
        <td>{"Management System Processes"}</td>
        <td>{"20"}</td>
        <td className="htmlText">
          {
            "Has your organization addressed the risks and opportunities as determined in accordance with the requirements of 6.1?"
          }
        </td>
        <td>
          {
            "Describe how risks and opportunities are considered and what plans are made to implement actions to address them? Risks and opportunities relating to the process, resource needs, user training/competency, continual improvement initiatives, frequency of reviews, agenda, minutes, and actions."
          }
        </td>
        <td>
          <div className="radio-button">
            <input
              type="radio"
              id="radio77"
              name="1.20"
              value={"Conforms"}
              defaultChecked={data["20"] === "Conforms"}
            />
            <label htmlFor="radio77">Conforms</label>
            <input
              type="radio"
              id="radio78"
              name="1.20"
              value={"Minor NC"}
              defaultChecked={data["20"] === "Minor NC"}
            />
            <label htmlFor="radio78">Minor NC</label>
            <input
              type="radio"
              id="radio79"
              name="1.20"
              value={"Major NC"}
              defaultChecked={data["20"] === "Major NC"}
            />
            <label htmlFor="radio79">Major NC</label>
            <input
              type="radio"
              id="radio80"
              name="1.20"
              value={"OFI"}
              defaultChecked={data["20"] === "OFI"}
            />
            <label htmlFor="radio80">OFI</label>
          </div>
        </td>
        <td>
          <textarea id="eve21" defaultValue={data["46"]} rows={10}></textarea>
        </td>
        <td>
          <textarea id="opp21" defaultValue={data["70"]} rows={10}></textarea>
        </td>
      </tr>
      <tr>
        <td>{"4.4"}</td>
        <td>{"Management System Processes"}</td>
        <td>{"21"}</td>
        <td className="htmlText">
          {
            "Has your organization evaluated these processes and implement any changes needed to ensure that these processes achieve their intended results?"
          }
        </td>
        <td>
          {
            "Describe the methods that are used to monitor, measure and evaluate processes and, if needed, what changes are made to achieve intended results? "
          }
        </td>
        <td>
          <div className="radio-button">
            <input
              type="radio"
              id="radio81"
              name="1.21"
              value={"Conforms"}
              defaultChecked={data["21"] === "Conforms"}
            />
            <label htmlFor="radio81">Conforms</label>
            <input
              type="radio"
              id="radio82"
              name="1.21"
              value={"Minor NC"}
              defaultChecked={data["21"] === "Minor NC"}
            />
            <label htmlFor="radio82">Minor NC</label>
            <input
              type="radio"
              id="radio83"
              name="1.21"
              value={"Major NC"}
              defaultChecked={data["21"] === "Major NC"}
            />
            <label htmlFor="radio83">Major NC</label>
            <input
              type="radio"
              id="radio84"
              name="1.21"
              value={"OFI"}
              defaultChecked={data["21"] === "OFI"}
            />
            <label htmlFor="radio84">OFI</label>
          </div>
        </td>
        <td>
          <textarea id="eve22" defaultValue={data["47"]} rows={10}></textarea>
        </td>
        <td>
          <textarea id="opp22" defaultValue={data["71"]} rows={10}></textarea>
        </td>
      </tr>
      <tr>
        <td>{"4.4"}</td>
        <td>{"Management System Processes"}</td>
        <td>{"22"}</td>
        <td className="htmlText">
          {
            "Does your organization improve the processes and the quality management system?"
          }
        </td>
        <td>
          {
            "Describe how opportunities to improve the processes and the QMS are determined. Examples include risk and opportunity matrices, corrective action and non-conformance records. Describe the approach towards improvement and action taken when process performance is not meeting intended results."
          }
        </td>
        <td>
          <div className="radio-button">
            <input
              type="radio"
              id="radio85"
              name="1.22"
              value={"Conforms"}
              defaultChecked={data["22"] === "Conforms"}
            />
            <label htmlFor="radio85">Conforms</label>
            <input
              type="radio"
              id="radio86"
              name="1.22"
              value={"Minor NC"}
              defaultChecked={data["22"] === "Minor NC"}
            />
            <label htmlFor="radio86">Minor NC</label>
            <input
              type="radio"
              id="radio87"
              name="1.22"
              value={"Major NC"}
              defaultChecked={data["22"] === "Major NC"}
            />
            <label htmlFor="radio87">Major NC</label>
            <input
              type="radio"
              id="radio88"
              name="1.22"
              value={"OFI"}
              defaultChecked={data["22"] === "OFI"}
            />
            <label htmlFor="radio88">OFI</label>
          </div>
        </td>
        <td>
          <textarea id="eve23" defaultValue={data["48"]} rows={10}></textarea>
        </td>
        <td>
          <textarea id="opp23" defaultValue={data["72"]} rows={10}></textarea>
        </td>
      </tr>
      <tr>
        <td>{"4.4"}</td>
        <td>{"Management System Processes"}</td>
        <td>{"23"}</td>
        <td className="htmlText">
          {
            "To the extent necessary, does your organization maintain documented information to support the operation of its processes?"
          }
        </td>
        <td>
          {
            "Documentation identified and retained by the organization to show that processes are carried it as planned, e.g. physical hard copy records, electronic media (data servers, hard drives, CDs)."
          }
        </td>
        <td>
          <div className="radio-button">
            <input
              type="radio"
              id="radio89"
              name="1.23"
              value={"Conforms"}
              defaultChecked={data["23"] === "Conforms"}
            />
            <label htmlFor="radio89">Conforms</label>
            <input
              type="radio"
              id="radio90"
              name="1.23"
              value={"Minor NC"}
              defaultChecked={data["23"] === "Minor NC"}
            />
            <label htmlFor="radio90">Minor NC</label>
            <input
              type="radio"
              id="radio91"
              name="1.23"
              value={"Major NC"}
              defaultChecked={data["23"] === "Major NC"}
            />
            <label htmlFor="radio91">Major NC</label>
            <input
              type="radio"
              id="radio92"
              name="1.23"
              value={"OFI"}
              defaultChecked={data["23"] === "OFI"}
            />
            <label htmlFor="radio92">OFI</label>
          </div>
        </td>
        <td>
          <textarea id="eve24" defaultValue={data["49"]} rows={10}></textarea>
        </td>
        <td>
          <textarea id="opp24" defaultValue={data["73"]} rows={10}></textarea>
        </td>
      </tr>
      <tr>
        <td>{"4.4"}</td>
        <td>{"Management System Processes"}</td>
        <td>{"24"}</td>
        <td className="htmlText">
          {
            "To the extent necessary, does your organization retain documented information to have confidence that the processes are being carried out as planned?"
          }
        </td>
        <td>
          {
            "Documentation created and maintained that includes a description of relevant interested parties (4.2), scope of the QMS including boundaries and applicability (4.3), description of the processes needed for the QMS, their sequence, interaction and application and assignment of responsibilities for the processes."
          }
        </td>
        <td>
          <div className="radio-button">
            <input
              type="radio"
              id="radio93"
              name="1.24"
              value={"Conforms"}
              defaultChecked={data["24"] === "Conforms"}
            />
            <label htmlFor="radio93">Conforms</label>
            <input
              type="radio"
              id="radio94"
              name="1.24"
              value={"Minor NC"}
              defaultChecked={data["24"] === "Minor NC"}
            />
            <label htmlFor="radio94">Minor NC</label>
            <input
              type="radio"
              id="radio95"
              name="1.24"
              value={"Major NC"}
              defaultChecked={data["24"] === "Major NC"}
            />
            <label htmlFor="radio95">Major NC</label>
            <input
              type="radio"
              id="radio96"
              name="1.24"
              value={"OFI"}
              defaultChecked={data["24"] === "OFI"}
            />
            <label htmlFor="radio96">OFI</label>
          </div>
        </td>
        <td>
          <textarea id="eve25" defaultValue={data["50"]} rows={10}></textarea>
        </td>
        <td>
          <textarea id="opp25" defaultValue={data["74"]} rows={10}></textarea>
        </td>
      </tr>
    </>
  );
};

export default Row3;
