import React, { useState, useEffect, useRef } from "react";

import ChkBox from "components/Custom/ChkBox";
import ExcelGrid from "components/Custom/ExcelGrid";
import Loader from "components/Custom/Loader";

const dataJson = require("../gridData.json");

interface defaultProps {
  childRef: any;
  editRows: any[];
}

const Part2 = (props: defaultProps) => {
  const { childRef, editRows } = props;

  const [workingAreaCols, setWorkingAreaCols] = useState<any[]>([
    {
      key: "workingArea",
      name: "Office / Write Up Areas ",
      type: "text",
      editable: false,
      width: "60%",
    },
    {
      key: "checkBox",
      name: "Check Box",
      // inputValue: "true",
      type: "checkbox",
      sortable: true,
      width: "10%",
      className: "textCenter",
    },
    {
      key: "action",
      name: "Action",
      type: "text",
      width: "30%",
    },
  ]);

  let tmpWorkingAreaRows: any = dataJson["1.3"]["1.3.1"];
  let tmpElectricSafetyRows: any = dataJson["1.4"]["1.4.1"];
  for (let key in tmpWorkingAreaRows) {
    tmpWorkingAreaRows[key].checkBox =
      tmpWorkingAreaRows[key].checkBox || false;
    tmpWorkingAreaRows[key].action = tmpWorkingAreaRows[key].action || "";
    tmpWorkingAreaRows[key].safetyMonitorItemId =
      tmpWorkingAreaRows[key].safetyMonitorItemId || -1;
  }
  for (let key in tmpElectricSafetyRows) {
    tmpElectricSafetyRows[key].checkBox =
      tmpElectricSafetyRows[key].checkBox || false;
    tmpElectricSafetyRows[key].action = tmpElectricSafetyRows[key].action || "";
    tmpElectricSafetyRows[key].safetyMonitorItemId =
      tmpElectricSafetyRows[key].safetyMonitorItemId || -1;
  }
  if (!editRows) {
    tmpWorkingAreaRows = null;
    tmpElectricSafetyRows = null;
  } else if (editRows["records"]) {
    for (let key in tmpWorkingAreaRows) {
      if (editRows["records"][tmpWorkingAreaRows[key].id]) {
        const details = JSON.parse(
          editRows["records"][tmpWorkingAreaRows[key].id].safetyDetails
        );
        const jsonData = {
          ...tmpWorkingAreaRows[key],
          ...details,
          safetyMonitorItemId:
            editRows["records"][tmpWorkingAreaRows[key].id].safetyMonitorItemId,
        };
        tmpWorkingAreaRows[key] = jsonData;
      }
    }
    for (let key in tmpElectricSafetyRows) {
      if (editRows["records"][tmpElectricSafetyRows[key].id]) {
        const details = JSON.parse(
          editRows["records"][tmpElectricSafetyRows[key].id].safetyDetails
        );
        const jsonData = {
          ...tmpElectricSafetyRows[key],
          ...details,
          safetyMonitorItemId:
            editRows["records"][tmpElectricSafetyRows[key].id]
              .safetyMonitorItemId,
        };
        tmpElectricSafetyRows[key] = jsonData;
      }
    }
  } else {
  }

  const [workingAreaRows, setWorkingAreaRows] =
    useState<any[]>(tmpWorkingAreaRows);
  const [electricSafetyCols, setFireSafetyCols] = useState<any[]>([
    {
      key: "electricSafety",
      name: "Electrical Safety in Labs. / Offices",
      type: "text",
      editable: false,
      width: "60%",
    },
    {
      key: "checkBox",
      name: "Check Box",
      //inputValue: "true",
      type: "checkbox",
      sortable: true,
      width: "10%",
    },
    {
      key: "action",
      name: "Action",
      type: "text",
      width: "30%",
    },
  ]);
  const [electricSafetyRows, setFireSafetyRows] = useState<any[]>(
    tmpElectricSafetyRows
  );

  const grid3Ref: any = useRef();
  const grid4Ref: any = useRef();

  useEffect(() => {
    childRef.saveRecord = saveRecord;
    return () => {
      childRef.saveRecord = null;
    };
  }, []);

  const saveRecord = () => {
    const section3 = grid3Ref.getRowData().map((s1) => {
      return {
        action: s1.action,
        id: s1.id,
        checkBox: s1.checkBox,
        safetyMonitorItemId: s1.safetyMonitorItemId || -1,
      };
    });
    const section4 = grid4Ref.getRowData().map((s1) => {
      return {
        action: s1.action,
        id: s1.id,
        checkBox: s1.checkBox,
        safetyMonitorItemId: s1.safetyMonitorItemId || -1,
      };
    });
    const items = [...section3, ...section4];
    return { items };
  };

  return (
    <>
      <h4 className="ehsHeading1">{dataJson["1.3"]["heading1"]}</h4>
      <h5 className="ehsHeading2">{dataJson["1.3"]["heading2"]}</h5>
      {tmpWorkingAreaRows ? (
        <ExcelGrid
          childRef={grid3Ref}
          cols={workingAreaCols}
          rows={tmpWorkingAreaRows}
          noTotalRows={true}
        />
      ) : (
        <div className="pageCenter">
          <Loader />
        </div>
      )}

      <h4 className="ehsHeading1">{dataJson["1.4"]["heading1"]}</h4>
      <h5 className="ehsHeading2">{dataJson["1.4"]["heading2"]}</h5>
      {tmpElectricSafetyRows ? (
        <ExcelGrid
          childRef={grid4Ref}
          cols={electricSafetyCols}
          rows={tmpElectricSafetyRows}
          noTotalRows={true}
        />
      ) : (
        <div className="pageCenter">
          <Loader />
        </div>
      )}
    </>
  );
};

export default Part2;
