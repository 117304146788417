import React, { useEffect, useState } from "react";

import ExcelGrid from "components/Custom/ExcelGrid";
import TestcaseForm from "./PunchlistDetailsForm";
import SkeletonBox from "components/Custom/SkeletonBox";
import TxtBox from "components/Custom/TxtBox";
import UiDialog from "components/Custom/UiDialog";
import { IconButton, Tooltip } from "@mui/material";
import { Edit as IconEdit } from "@mui/icons-material";

import { LABELS, TITLES } from "common/config";
import PunchlistDetailsForm from "./PunchlistDetailsForm";

const PunchlistDetails = (props: any) => {
  const [cols, setCols] = useState<any>([
    {
      key: "slNo",
      name: "Sl No.",
      type: "number",
      width: "10%",
      editable: false,
      sortable: true,
      align: "center",
      searchable: true,
      frozen: false,
    },
    {
      key: "owner",
      name: "Owner",
      type: "text",
      width: "15%",
      editable: false,
      sortable: true,
      align: "center",
      searchable: true,
      frozen: false,
    },
    {
      key: "priority",
      name: "Priority",
      type: "text",
      width: "10%",
      editable: false,
      sortable: true,
      searchable: true,
      frozen: false,
    },
    {
      key: "type",
      name: "Type",
      type: "text",
      width: "15%",
      editable: false,
      sortable: true,
      searchable: true,
      frozen: false,
    },
    {
      key: "rootCause",
      name: "Root Cause",
      type: "text",
      width: "15%",
      editable: false,
      sortable: true,
      searchable: true,
      frozen: false,
    },
    {
      key: "status",
      name: "Status",
      type: "text",
      width: "15%",
      editable: false,
      sortable: true,
      searchable: true,
      frozen: false,
    },
    {
      key: "estCost",
      name: "Cost",
      type: "text",
      width: "15%",
      editable: false,
      sortable: true,
      searchable: true,
      frozen: false,
    },
    {
      key: "options",
      name: "Options",
      align: "center",
      width: "5%",
      renderCell: (params) => (
        <div className="options">
          <Tooltip title={LABELS.EDIT} placement="bottom">
            <IconButton
              className="iconBtn"
              onClick={() => onEditRow(params.row)}
            >
              <IconEdit />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ]);
  const [rows, setRows] = useState<any>([]);
  const [manageDialog, setManageDialog] = useState(false);
  const [loading, setLoading] = useState(true);
  const [docNum, setDocNum] = useState("");
  const [selectedRow, setSelectedRow] = useState<any>(null);
  // const defaultRows = [
  //   {
  //     ownerId: 3,
  //     owner: "Matthew",
  //     priorityId: 2,
  //     priority: "CAT B",
  //     type: 2,
  //     typeId: "Mechanical",
  //     rootCauseId: 1,
  //     rootCause: "Project",
  //     statusId: 1,
  //     status: "Open",
  //     alternateId: "",
  //     contractorId: null,
  //     contractor: null,
  //     user1Id: 3,
  //     user1: "David",
  //     documentId: "",
  //     roomId: 2,
  //     room: "Corridor",
  //     associatedEquipmentId: 2,
  //     associatedEquipment: "Coating",
  //     initiationDate: "2024-04-01",
  //     dueDate: "2024-04-10",
  //     estCost: "0 CHF",
  //     attachments: [],
  //     description: "TAG Missing on the Equipment",
  //     resolution: "New Design needed to be approved for the TAG manufacturing",
  //     addAsReviewer: true,
  //     notifications: true,
  //   },
  //   {
  //     ownerId: 5,
  //     owner: "Tom",
  //     priorityId: 1,
  //     priority: "CAT A",
  //     type: 1,
  //     typeId: "Civil",
  //     rootCauseId: 1,
  //     rootCause: "Project",
  //     statusId: 2,
  //     status: "In-Review",
  //     alternateId: "",
  //     contractorId: 17,
  //     contractor: "Metac General Contracting Company",
  //     user1Id: 3,
  //     user1: "David",
  //     documentId: "",
  //     roomId: 1,
  //     room: "Clean Room",
  //     associatedEquipmentId: 4,
  //     associatedEquipment: "Trolleys",
  //     initiationDate: "2024-04-03",
  //     dueDate: "2024-04-08",
  //     estCost: "0 CHF",
  //     attachments: [],
  //     description: "Internal Door Alignment",
  //     resolution: "Doors neeed to be re-installed",
  //     addAsReviewer: true,
  //     notifications: true,
  //   },
  //   {
  //     ownerId: 2,
  //     owner: "Daniel",
  //     priorityId: 3,
  //     priority: "CAT C",
  //     type: 3,
  //     typeId: "Electrical",
  //     rootCauseId: 1,
  //     rootCause: "Project",
  //     statusId: 1,
  //     status: "Open",
  //     alternateId: "",
  //     contractorId: 10,
  //     contractor: "Conspel Emirates wll",
  //     user1Id: 1,
  //     user1: "Sam",
  //     documentId: "",
  //     roomId: 4,
  //     room: "CMA",
  //     associatedEquipmentId: 1,
  //     associatedEquipment: "Filtration",
  //     initiationDate: "2024-04-16",
  //     dueDate: "2024-04-24",
  //     estCost: "0 CHF",
  //     attachments: [],
  //     description: "Cable Termination fault",
  //     resolution:
  //       "2x10 core cable needs to be replaced and termination details required",
  //     addAsReviewer: true,
  //     notifications: true,
  //   },
  //   {
  //     ownerId: 4,
  //     owner: "Michael",
  //     priorityId: 1,
  //     priority: "CAT A",
  //     type: 4,
  //     typeId: "Instrumentation",
  //     rootCauseId: 3,
  //     rootCause: "Maintenance",
  //     statusId: 1,
  //     status: "Open",
  //     alternateId: "",
  //     contractorId: 6,
  //     contractor: "Matrix Conatruction",
  //     user1Id: 2,
  //     user1: "Tom",
  //     documentId: "",
  //     roomId: 2,
  //     room: "Corridor",
  //     associatedEquipmentId: 3,
  //     associatedEquipment: "Washer",
  //     initiationDate: "2024-04-23",
  //     dueDate: "2024-04-30",
  //     estCost: "0 CHF",
  //     attachments: [],
  //     description: "Air filters missing",
  //     resolution: "P&ID needs to be updated and filters to be installed",
  //     addAsReviewer: true,
  //     notifications: true,
  //   },
  // ];

  useEffect(() => {
    if (loading) {
      getRows();
    }
  }, [loading]);

  const getRows = () => {
    setRows(null);
    setTimeout(() => {
      let tmpRows: any = localStorage.getItem("pnchlstDts") || "[]";
      tmpRows = JSON.parse(tmpRows);
      for (let i = 0; i < tmpRows.length; i++) {
        tmpRows[i]["slNo"] = i + 1;
        // tmpRows[i]["estCost"] = tmpRows[i].estCost + " "
      }
      console.log("tmpRows estCost", tmpRows);
      setRows(tmpRows);
      setLoading(false);
    }, 500);
  };

  const onReload = () => {
    setLoading(true);
  };

  const onAdd = () => {
    setSelectedRow({});
    setDocNum("000" + (rows.length + 1));
    setManageDialog(!manageDialog);
  };

  const onEditRow = (row: any) => {
    setSelectedRow(row);
    setManageDialog(true);
  };

  return (
    <React.Fragment>
      <div className="pageInfo">
        <ul className="breadcrumbs">
          <li>{LABELS.HOME}</li>
          <li className="dim">{" / "}</li>
          <li onClick={() => props.setDashMenu(LABELS.HOME)}>
            {TITLES.PUNCHLIST_DETAILS}
          </li>
        </ul>
        <h4>{TITLES.PUNCHLIST_DETAILS}</h4>
      </div>
      {rows ? (
        <ExcelGrid
          id={"punchlistDetails"}
          height={window.innerHeight * 0.6}
          cols={cols}
          rows={rows}
          exportOption={true}
          newRowOption={false}
          exportName={TITLES.PUNCHLIST_DETAILS}
          onReload={onReload}
          onAdd={onAdd}
        />
      ) : (
        <div className="flexRowCenter">
          <SkeletonBox height={window.innerHeight * 0.6} width={"98%"} />
        </div>
      )}

      {manageDialog && (
        <>
          <UiDialog
            title={LABELS.PUNCHLIST_DETAILS}
            open={true}
            size={"xl"}
            onClose={() => {
              setManageDialog(false);
            }}
          >
            <PunchlistDetailsForm
              setManageDialog={setManageDialog}
              docNum={docNum}
              rows={rows}
              onReload={onReload}
              selectedRow={selectedRow}
            />
          </UiDialog>
        </>
      )}
    </React.Fragment>
  );
};

export default PunchlistDetails;
