export const PRODUCTION = process.env.REACT_APP_PRODUCTION === "true";
const LOCAL_URL = process.env.REACT_APP_LOCAL_URL;
const PRODUCTION_URL = process.env.REACT_APP_PRODUCTION_URL;

console.log("LOCAL_URL: ", LOCAL_URL, PRODUCTION_URL);

export const CONFIG = {
  SITE_NAME: process.env.REACT_APP_SITE_NAME,
  VERSION: process.env.REACT_APP_VERSION,
  // DON'T CHANGE USER ROLES VALUES
  USER_ROLES: {
    Director: 10,
    ProjectEngineer: 11,
    Supervisor: 12,
    ProjectManager1: 13,
    ProjectManager2: 14,
    Admin: 15,
    Ehs: 16,
    ClearSphere: 18,
  },
  MAX_FILE_SIZE: 2, // In MB
  MAX_VDO_FILE_SIZE: 20, // In MB
};

export const API_CONFIG = {
  API_URL: PRODUCTION ? PRODUCTION_URL : LOCAL_URL,
  METHODS: {
    GET: "GET",
    POST: "POST",
    PUT: "PUT",
    DELETE: "DELETE",
  },
  TIMEOUT: 60 * 1000,
};

export const COLORS = {
  PRIMARY: "#0a6cc9",
  SECONDARY: "#06283D",
  WHITE: "#fff",
  DIM_GRAY: "#e1e1e1",
  DIM_DARK_GRAY: "#6e6e6e",
  GRID_ADD_NEW: "#22b378",
  RELOAD_EXPORT: "#e9e9e9",
  RELOAD_EXPORT_TEXT: "#3f3f3f",
};

export const DEFAULTS = {
  BTN_TYPE: "outlined",
  DIALOG_SIZE: "sm",
  MAP_TYPE: 1,
  SCORES: [
    {
      id: "1",
      label: "1",
    },
    {
      id: "2",
      label: "2",
    },
    {
      id: "3",
      label: "3",
    },
    {
      id: "4",
      label: "4",
    },
    {
      id: "5",
      label: "5",
    },
  ],
  SUB_GRID_ROW_HEIGHT: 400,
  GRID_ROW_HEIGHT: 50,
};

export const LOCAL_STORAGE = {
  USER_INFO: "userInfo",
  ACCESS_TOKEN: "token",
  REMEMBER_ME: "remember",
  EUIPMENT_REGISTER: "EquipmentRegister",
};

export const SUB_TITLES = {
  USERS: "Manage Users",
};

export const LABELS = {
  LOTOTO: "LOTOTO",
  TRAINING: "Training Application Form",
  TRAINING_VIDEO: "Training Video",
  AUDIT_2: "Audit 2",
  CONTRACTOR_NAME: "Contractor Name",
  OCCUPATIONAL_HEALTH: "Occupational Health",
  INCIDENT_MANAGEMENT: "Incident Management",
  HACCP: "Hazard Analysis and Critical Control Points (HACCP)",
  WASTE_MANAGEMENT: "Waste Management",
  LOAD_GRAPH: "Load Graph",
  RELOAD: "Reload",
  CAPA: "CAPA",
  COMMENTS: "Comments",
  SAFETY_MONITORING: "Safety Monitoring & Measurement",
  PRINT: "Print",
  AUDITS: "Audits",
  ORGANIZATION_LOGO: "Organization Logo",
  PREVIOUS: "Prev.",
  NEXT: "Next",
  AUDIT: "Audit",
  RISKS: "Risks",
  INDUCTION_TRAINING: "Induction Training",
  EMPLOYEE_DATA: "Employee Data",
  SOR: "Safety Observation Records",
  NEAR_MISS: "Near Miss Reports",
  RAMS: "Risk Assessment and Method Statement (RAMS)",
  SOP: "Standard Operating Procedures (SOP)",
  WORK_PERMIT: "Work Permit Tool",
  EVALUATION: "Evaluation",
  EHS: "EHS",
  REGULATIONS: "Company Regulations",
  QUESTIONNAIRE: "Contractor Questionnaire",
  CONTRACT_LIST: "Contractor Approval",
  PRE_TENDER: "Pre-Tendering",
  PROJECT_HISTORY: "Project History",
  REMOVE: "Remove",
  BACK: "Back",
  ADD: "Add",
  ADD_NEW: "Add New",
  ADD_NEW_ROW: "Add New Row",
  LESSONS_LEARNED: "Lessons Learned",
  RFI: "RFI",
  STATUS: "Status",
  SCHEDULE_UPDATE_FREQUENCY: "Schedule Update Frequency",
  RISKS_REGISTER: "Risks Register",
  NEW_PASSWORD: "New Password",
  CURRENT_PASSWORD: "Current Password",
  SETTINGS: "Settings",
  CONSTRAINTS: "Constraints",
  SPENT_HRS: "Spent Hours",
  TASK_OWNER: "Task Owner",
  EMP_ID: "Employee ID",
  SELECT_DATE: "Status/Data Date",
  CONSTRAINT_LOG_REPORT: "Constraint Log",
  USER_LOG_REPORT: "User Log Report",
  CONSOLIDATED_REPORT: "Project Summary Report",
  TIME_SHEET_LOG_REPORT: "Time Sheet Log Report",
  APPROVAL_LOG_REPORT: "Approval Log Report",
  COST_REPORT: "Cost Report",
  MAN_HOURS_REPORT: "Man Hours Report",
  QTY_REPORT: "Quantities Report",
  EARNED_VALUE_REPORT: "Earned Value Report",
  FROM_DATE: "From",
  TO_DATE: "To",
  VIEW: "View",
  APPROVE: "Approve",
  REJECT: "Reject",
  APPROVE_FILE: "Project Manager Approval",
  COST: "Cost",
  CURRENCY: "Currency",
  UNIT: "Unit",
  QTY: "Qty.",
  EXPORT_TO_PDF: "Export To PDF",
  SIX_WEEKS_VIEW: "6 Weeks View",
  ONE_MONTH_VIEW: "1 Month View",
  SHOW_ALL: "Show All",
  TASK_NAME: "Discipline",
  REPORTS: "Dashboard",
  PROJECT_MANAGER: "Project Manager",
  PROJECT_CHART: "Project Chart",
  PROJECT_CRITICAL_PATH: "Project Critical Path",
  WEEK_LOOK_AHEAD_SCHEDULE: "Week Look Ahead Schedule",
  PROJECT_REMAINING_DURATION: "Project Remaining Duration",
  EXPAND_ALL: "Expand All",
  PERCENTAGE: "Percentage",
  CRITICAL_PATH: "Critical Path",
  NEAR_TO_CRITICAL_PATH: "Near To Critical Path",
  PROJECT_CASH_FLOW: "Project Cash Flow",
  PROGRESS_CURVE: "Progress S Curve",
  MAN_HOURS_DISTRIBUTION: "Man Hours Distribution",
  RESOURCE_TABLE: "Resource Table",
  FINISH_DATE: "Finish Date",
  START_DATE: "Start Date",
  EDIT_CHART: "Edit Chart",
  PROJECT_OVERVIEW: "Project Overview",
  PROJECT_PROGRESS_STATUS: "Project Progress Status vs Earned",
  MAN_HOURS_PLAN: "Man Hours Distribution",
  RESOURCE_HISTOGRAM: "Resource Histogram",
  VIEW_PROJECT_CHARTER: "View Project Charter",
  PROJECT_CHARTER: "Project Charter",
  BOQ: "Excel BOQ and Costx",
  CONFIRM: "Confirm",
  LOAD: "Load",
  CONTACT_NUMBER: "Contact Number",
  INCHARGE: "Incharge",
  ADDRESS_1: "Address 1",
  ADDRESS_2: "Address 2",
  ZIPCODE: "Zipcode",
  UPLOAD: "Upload",
  SELECT_FILE: "Choose File",
  FILE_UPLOAD_TYPE: "File Upload Type",
  SUPERVISOR: "Supervisor",
  PROJECT_NAME: "Project Name",
  SELECT_PROJECT: "Select Project",
  DELETE: "Delete",
  EDIT: "Edit",
  SAVE: "Save",
  CANCEL: "Cancel",
  HOME: "Home",
  PROJECTS: "Projects",
  SYSTEM_NO: "System No",
  SYSTEM_NAME: "System Name",
  SUITE_TEAM: "Suite Team",
  DRAFT: "Draft",
  REVIEW: "Review",
  COMMENTS_UPDATE: " Comments Update",
  PRE_APPROVAL: "Pre-Approval",
  IOC_PREP_PRE_APPROVAL: "IOC Prep-Pre-Approval",
  IOC_EXICUTION: "IOC Execution",
  IOC_POST_APPROVAL: "IOC Post Approval",
  IOQ_PREP_PRE_APPROVAL: "IOQ Prep-Pre-Approval",
  IOQ_EXICUTION: "IOQ Execution",
  IOQ_POST_APPROVAL: "IOQ Post Approval",
  EXECUTION: "Execution",
  POST_APPROVAL: "Post Approval",

  PSSR: "PSSR",
  WALK_DOWN_1: "Walk-down 1",
  WALK_DOWN_2: "Walk-down 2",
  WALK_DOWN_3: "Walk-down 3",

  DASHBOARD: "Dashboard",
  GANTT_CHART: "Gantt Chart",
  FILE_UPLOAD: "File Upload",
  USERS: "Users",
  ORGANIZATION: "Organization",
  ORGANIZATIONS: "Organizations",
  LOGOUT: "Logout",
  FIRST_NAME: "First Name",
  LAST_NAME: "Last Name",
  USER_NAME: "Username",
  PASSWORD: "Password",
  LOGIN: "Login",
  REMEMBER_ME: "Remember Me",
  MENU: "Menu",
  SEARCH: "Search",
  EMAIL_ID: "Email ID",
  PHONE: "Phone Number",
  USER_TYPE: "User Type",
  ACTIVE: "Active",
  INACTIVE: "In Active",
  DIRECTOR: "Director",
  PROJECT_STATUS: "Project Status",
  FREEZE_COLS: "Freeze Columns",
  HIDE_UNHIDE: "Hide Unhide",
  EQUIPMENT_REGISTER: "Equipment Register",
  PUNCHLIST_DETAILS: "Punchlist Details",
  OWNER: "Owner",
  PRIORITY: "Priority",
  TYPE: "Type",
  ROOT_CAUSE: "Root Cause",
  ALTERNATE_ID: "Alternate Id",
  RESPONSIBLE_TO_EXECUTE: "Responsible To Execute",
  RESPONSIBLE_TO_CLOSE: "Responsible To Close",
  ROOM: "Room",
  ASSOCIATED_EQUIPMENT: "AssociatedEquipment",
  DOCUMENT_ID: "DocumentId",
  MILESTONE_DATES: "Milestone Dates",
  INITIATION_DATE: "Initiation Date",
  DUE_DATE: "Due Date",
  COST_SUMMARY: "Cost Summary",
  EST_COST: "Est. Cost",
  ATTACHMENTS: "Attachments",
  RELATED_ENGINEERING_DATA: "Related Engineering Data",
  SELECT_FIND_SYSTEMS: "Select/Find Systems",
  DESCRIPTION: "Description",
  RESOLUTION: "Resolution",
  ADD_AS_REVIEWER: "Add as Reviewer",
  NOTIICATIONS: "Notifications",
};

export const TITLES = {
  CQV: "CQV",
  CQV_GENERATE: "Generate",
  CQV_REVIEW_AND_COMMENTS: "Review and Comments",
  CQV_COMMENTS_UPDATE: "Comments Update",
  CQV_PRE_APPROVAL: "Pre-Approval",
  CQV_EXECUTION: "Execution",
  CQV_POST_APPROVAL: "Post-Approval",
  EQUIPMENT_REGISTER: "Equipment Register",
  PUNCHLIST_DETAILS: "Punchlist Details",
};

export const TITLE_INFO = {
  PROP_TYPES:
    "The type of properties available for your dream home or investment.",
  TOP_CITIES: "Find Places to Buy in these Cities",
  MOST_VIEWED: "The properties which are most viewed recently",
};

export const NOTIFY = {
  WARNING: "warning",
  SUCCESS: "success",
  ERROR: "error",
  AUTO_HIDE_DURATION: 3000,
};

export const CONFIRMS = {
  SURE_TO_LOGOUT: "Are you sure want to logout..?",
  DELETE_USER: "Are you sure want to delete this user..?",
  SURE_TO_APPROVE: "Do you want continue..?",
  SURE_TO_SAVE: "Are you sure want to save this..?",
};

export const SUCCESS = {
  SAFETY_MONITOR_SAVED: "Safety Monitoring Saved Successfully..!",
  PRE_RENDER_SAVED: "Pre-Render Saved Successfully..!",
  AUDIT_SAVED: "Audit Saved Successfully..!",
  PASSWORD_CHANGED: "Password Changed Successfully..!",
  LOGIN_SUCCESS: "Login Successful, Redirecting to home page..!",
  LOGOUT_SUCCESS: "Logout successful, Redirecting to login page..!",
  SIGNUP_SUCCESS: "Signup successful..!",
  PROPERTY_SAVED: "Property Saved Successfully..!",
  FILE_UPLOADED: "File Uploaded Successfully..!",
  PROJECT_SUCCESS: "Project Saved Successfully..!",
  ORGANIZATION_SUCCESS: "Organization Saved Successfully..!",
  USER_SUCCESS: "User Saved Successfully..!",
  CHART_INFO_UPDATED: "Chart Info Updated Successfully..!",
  FILE_APPROVED: "File Approved Successfully..!",
  RECORD_SAVED: "Record saved successfully..!",
};

export const WARNINGS = {
  FILE_SIZE_EXCEEDED: "File size exceeded..!",
  BOTH_PASSWORDS_SAME: "Current & New Password should not be same..!",
  NPWD_LENGTH_SMALL:
    "New Password Length should be greater than 6 characters..!",
  CPWD_EMPTY: "Current Password is empty..!",
  NPWD_EMPTY: "New Password is empty..!",
  DATE_EMPTY: "Please select date..!",
  TASK_EMPTY: "Please select Task..!",
  FILE_NOT_FOUND: "File Not Found..!",
  INVALID_EMAIL: "Invalid Email ID..!",
  USER_TYPE_EMPTY: "Please Select User Type..!",
  USER_NAME_IS_EMPTY: "Please Enter User Name..!",
  FIRST_NAME_IS_EMPTY: "Please Enter First Name..!",
  CONTACT_NO_IS_EMPTY: "Please Enter Incharge Contact Number..!",
  INCHARGE_IS_EMPTY: "Please Enter Incharge Name..!",
  ORG_NAME_IS_EMPTY: "Please Enter Organization Name..!",
  ZIPCODE_IS_EMPTY: "Please Enter Zipcode..!",
  ADDRESS_IS_EMPTY: "Please Enter Address..!",
  SUPERVISOR_EMPTY: "Please select Supervisor..!",
  ORG_EMPTY: "Please select Organization..!",
  PROJECT_NAME_EMPTY: "Please enter the Project Name..!",
  PROJECT_EMPTY: "Please select project..!",
  FILE_EMPTY: "Please select file..!",
  USERNAME_IS_EMPTY: "Username is mandatory..!",
  PASSWORD_IS_EMPTY: "Password is mandatory..!",
  PROPERTY_TYPE_EMPTY: "Please select property type..!",
  STATE_EMPTY: "Please select state..!",
  CITY_EMPTY: "Please select city..!",
  AREA_EMPTY: "Please select Area..!",
  ADDRESS_EMPTY: "Please enter address..!",
  PROPERTY_NAME_EMPTY: "Please enter address..!",
  PROPERTY_DESC_EMPTY: "Please enter property description..!",
  PROPERTY_OWNER_EMPTY: "Please enter property owner name..!",
  CONTRACTOR_NAME_EMPTY: "Please enter contractor name..!",
  PHONE_EMPTY: "Please enter phone number..!",
};

export const ERRORS = {
  AUDIT_FAILED: "Audit Failed: Form not saved..!",
  LOGIN_FAILED: "Login Failed: Invalid Username or Password..!",
  LOGOUT_FAILED: "Logout failed..!",
  SCORE_FAILED: "Score Calculations Failed..!",
  FILE_NOT_UPLOADED: "File Not Uploaded Successfully..!",
  PROJECT_FAILED: "Project Not Saved..!",
  ORGANIZATION_FAILED: "Organization Not Saved..!",
  USER_FAILED: "User Not Saved..!",
  CHART_INFO_NOT_UPDATED: "Chart Info Not Updated Successfully..!",
  FILE_APPROVE_FAILED: "File Not Approved Successfully..!",
};

export const BTN_VARIENTS = {
  TEXT: "text",
  OUTLINED: "outlined",
  CONTAINED: "contained",
};

export const UNITS = {
  PRICE: " €",
};

export const FORMATS = {
  SQL_DATE: "YYYY-MM-DD HH:mm:ss",
  SQL_DATE_ONLY: "YYYY-MM-DD",
  DATE: "DD-MM-YYYY HH:mm:ss",
  DATE_ONLY: "DD-MM-YYYY",
  DATE_PICKER_FORMAT: "dd-MM-yyyy",
  REPORT_TITLE_DATE: "MMM DD, YYYY",
  REPORT_DATE_WITH_TIME: "MMM DD, YYYY HH:mm:ss",
  MONTH_ONLY: "MMMM",
  MONTH_AND_YEAR_ONLY: "MMMM - YYYY",
  GRAPH_TIME: "HH:mm",
};

export const GRAPH_TYPES = {
  COLUMN: "column",
  PIE: "pie",
  BAR: "bar",
};
