/*
 * Safety Monitoring & Measurement Form
 */

import React, { useRef, useEffect, useState } from "react";
import { withSnackbar } from "notistack";
import { IconButton, Tooltip } from "@mui/material";
import { Edit as IconEdit } from "@mui/icons-material";

import UiDialog from "components/Custom/UiDialog";
import ExcelGrid from "components/Custom/ExcelGrid";
import AutoComplete from "components/Custom/AutoComplete";
import SkeletonBox from "components/Custom/SkeletonBox";
import ModalBtmBtns from "components/Custom/ModalBtmBtns";
import Loader from "components/Custom/Loader";
import HGraph from "components/Custom/HGraph";
import Btn from "components/Custom/Btn";
import Part1 from "./sections/Part1";
import Row1 from "./rows/Row1";
import Row2 from "./rows/Row2";
import Row3 from "./rows/Row3";

import { LABELS, NOTIFY, SUCCESS, WARNINGS } from "common/config";
import { ApiHome } from "apihandlers/home";
import { ApiEhs } from "apihandlers/ehs";
import { COMMON_JS } from "common/scripts";
import { ApiAudit } from "apihandlers/audit";
import { color } from "highcharts";

const API_HANDLER_HOME = new ApiHome();
const API_HANDLER_EHS = new ApiEhs();
const API_HANDLER_AUDIT = new ApiAudit();
//const dataJson = require("./gridData.json");

const Audit_2 = (props: any) => {
  const { setDashMenu } = props;

  const mainGridCols = [
    // {
    //   key: "id",
    //   name: "ID",
    //   type: "number",
    //   width: "5%",
    // },
    {
      key: "slNo",
      name: "Sl No.",
      type: "number",
      width: "5%",
      editable: false,
      sortable: true,
      searchable: true,
      align: "center",
    },
    {
      key: "contractorName",
      name: "Contractor Name",
      type: "text",
      width: "40%",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "createdOn",
      name: "Created On",
      type: "text",
      width: "20%",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "createdBy",
      name: "Created By",
      type: "text",
      width: "20%",
      editable: false,
      sortable: true,
      searchable: true,
    },
    {
      key: "options",
      name: "Options",
      type: "html",
      width: "15%",
      editable: false,
      sortable: false,
      renderCell: ({ row }: any) => (
        <div className="options">
          <Tooltip title={LABELS.EDIT} placement="bottom">
            <IconButton className="iconBtn" onClick={() => onEditRow(row)}>
              <IconEdit />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];
  const [editRows, setEditRows] = useState<any[]>(null);
  const [cols2, setCols2] = useState<any[]>([
    {
      key: "clauseNo",
      name: "Clause No",
      type: "text",
      editable: false,
      width: "5%",
    },
    {
      key: "title",
      name: "Clause Title",
      type: "text",
      sortable: false,
      editable: false,
      width: "10%",
      className: "textCenter",
    },
    {
      key: "questionNo",
      name: "Question No",
      type: "text",
      sortable: false,
      editable: false,
      width: "5%",
      className: "textCenter",
    },
    {
      key: "auditQue",
      name: "Audit Question",
      type: "text",
      sortable: false,
      editable: false,
      width: "20%",
      className: "textCenter",
    },
    {
      key: "suggestion",
      name: "Guidance & Suggestions",
      type: "htmltext",
      sortable: false,
      editable: true,
      width: "20%",
      className: "textCenter",
    },
    {
      key: "options",
      name: "Options",
      type: "text",
      sortable: false,
      editable: true,
      width: "5%",
      className: "textCenter",
    },
    // {
    //   key: "minorNc",
    //   name: "Minor NC",
    //   type: "boolean",
    //   sortable: false,
    //   width: "5%",
    //   className: "textCenter",
    // },
    // {
    //   key: "majorNc",
    //   name: "Major NC",
    //   type: "boolean",
    //   sortable: false,
    //   width: "5%",
    //   className: "textCenter",
    // },
    // {
    //   key: "ofi",
    //   name: "OFI",
    //   type: "boolean",
    //   sortable: false,
    //   width: "5%",
    //   className: "textCenter",
    // },
    {
      key: "evidence",
      name: "Audit Evidence & Notes",
      type: "text",
      editable: true,
      width: "18%",
    },
    {
      key: "opportunities",
      name: "Opportunities to Improve",
      type: "text",
      editable: true,
      width: "17%",
    },
  ]);
  // let tmpFirstAidRows: any = dataJson["1"]["1.1"];
  // for (let key in tmpFirstAidRows) {
  //   tmpFirstAidRows[key].checkYes = false;
  //   tmpFirstAidRows[key].checkNo = false;
  //   tmpFirstAidRows[key].checkNa = false;
  // }

  // if (!editRows) {
  //   tmpFirstAidRows = null;
  // } else if (editRows["records"]) {
  //   for (let key in tmpFirstAidRows) {
  //     const details = editRows["records"][key];
  //     const jsonData = {
  //       ...tmpFirstAidRows[key],
  //       ...details,
  //     };
  //     tmpFirstAidRows[key] = jsonData;
  //   }
  // }

  // const [rows2, setFirstAidRows] = useState<any[]>(tmpFirstAidRows);

  const [loading, setLoading] = useState<boolean>(true);
  const [projects, setProjects] = useState<any[]>([]);
  const [cols, setCols] = useState<any[]>(mainGridCols);
  const [rows, setRows] = useState<any[]>(null);
  const [columnChart, setColumnChart] = useState<any>({});
  const [pieChart, setPieChart] = useState<any>({});
  const [manageDialog, setManageDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>({});
  //const [editRows, setEditRows] = useState<any[]>(null);
  const [pageNo, setPageNo] = useState(1);
  const [totalPages, setTotalPages] = useState(6);
  const [formData, setFormData] = useState<any>({});
  const [uploads, setUploads] = useState<any[]>([]);

  const projectsRef: any = useRef();
  const part1Ref: any = useRef();
  const part2Ref: any = useRef();
  const part3Ref: any = useRef();
  const part4Ref: any = useRef();
  const part5Ref: any = useRef();
  const part6Ref: any = useRef();

  useEffect(() => {
    return () => {
      cleanUp();
    };
  }, []);

  useEffect(() => {
    if (loading) {
      getProjects();
      // setRows([]);
      // setColumnChart([]);
      // setPieChart([]);
      // setLoading(false);
    }
  }, [loading]);

  const getProjects = () => {
    const args = { dropdown: true };
    API_HANDLER_EHS.getContractor(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        setProjects(
          response.records.map((c) => {
            return { id: c.id, label: c.nameOfContractor };
          })
        );
        getRows();
      }
    });
  };

  const cleanUp = () => {
    setRows(null);
    setLoading(true);
    setManageDialog(false);
    setSelectedRow({});
    console.log("Cleaned up");
  };

  const getRows = () => {
    setRows(null);
    const args = {};
    API_HANDLER_EHS.getAudit2(args).then((res: any) => {
      const { status, response } = res;
      if (status === 200) {
        setRows(response.records);
        setColumnChart(response.columnChart);
        setPieChart(response.pieChart);
        setLoading(false);
      }
    });
  };

  const onAdd = () => {
    const projectId = projectsRef.current.selectedValue.id;
    if (!projectId) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.PROJECT_EMPTY);
      return;
    }
    setManageDialog(!manageDialog);
    setEditRows([]);
    setPageNo(1);
    setSelectedRow({});
    setUploads([]);
  };

  const onEditRow = (row: any) => {
    setPageNo(1);
    setSelectedRow(row);
    setManageDialog(true);
    setEditRows(null);
  };

  const getProjectInfo = () => {
    const projectId = projectsRef.current.selectedValue.id;
    const args = {
      projectId,
    };
    API_HANDLER_EHS.getAudit2(args).then((res: any) => {
      const { status, response } = res;
      if (status === 200) {
        setColumnChart(response.columnChart);
        setPieChart(response.pieChart);
      }
    });
  };

  const manageAudit2 = () => {
    let args: any = updateForm();
    args = {
      ...args,
      auditId: selectedRow.id || -1,
      projectId: selectedRow.id
        ? selectedRow.projectId
        : projectsRef.current.selectedValue.id,
      files: uploads,
    };

    console.log("manageAudit2: ", args);

    // Validations
    if (!args.projectId) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.PROJECT_EMPTY);
      return;
    }
    setEditRows(null);
    API_HANDLER_AUDIT.manageAudit2(args).then((res) => {
      setEditRows([]);
      if (res.status === 200) {
        COMMON_JS.showNotify(
          props,
          NOTIFY.SUCCESS,
          SUCCESS.SAFETY_MONITOR_SAVED
        );
        setSelectedRow({});
        setManageDialog(false);
        getRows();
      } else {
        COMMON_JS.showNotify(props, NOTIFY.ERROR, res.error);
      }
    });
  };

  const updateForm = () => {
    let args: any = {
      data: [],
    };
    // 4
    let row1: any = document.querySelector('input[name="1.1"]:checked');
    let row2: any = document.querySelector('input[name="1.2"]:checked');
    let row3: any = document.querySelector('input[name="1.3"]:checked');
    let row4: any = document.querySelector('input[name="1.4"]:checked');
    let row5: any = document.querySelector('input[name="1.5"]:checked');
    let row6: any = document.querySelector('input[name="1.6"]:checked');
    let row7: any = document.querySelector('input[name="1.7"]:checked');
    let row8: any = document.querySelector('input[name="1.8"]:checked');
    let row9: any = document.querySelector('input[name="1.9"]:checked');
    let row10: any = document.querySelector('input[name="1.10"]:checked');
    let row11: any = document.querySelector('input[name="1.11"]:checked');
    let row12: any = document.querySelector('input[name="1.12"]:checked');
    let row13: any = document.querySelector('input[name="1.13"]:checked');
    let row14: any = document.querySelector('input[name="1.14"]:checked');
    let row15: any = document.querySelector('input[name="1.15"]:checked');
    let row16: any = document.querySelector('input[name="1.16"]:checked');
    let row17: any = document.querySelector('input[name="1.17"]:checked');
    let row18: any = document.querySelector('input[name="1.18"]:checked');
    let row19: any = document.querySelector('input[name="1.19"]:checked');
    let row20: any = document.querySelector('input[name="1.20"]:checked');
    let row21: any = document.querySelector('input[name="1.21"]:checked');
    let row22: any = document.querySelector('input[name="1.22"]:checked');
    let row23: any = document.querySelector('input[name="1.23"]:checked');
    let row24: any = document.querySelector('input[name="1.24"]:checked');
    let evedence1: any = document.getElementById("eve1");
    let evedence2: any = document.getElementById("eve2");
    let evedence3: any = document.getElementById("eve3");
    let evedence4: any = document.getElementById("eve4");
    let evedence5: any = document.getElementById("eve5");
    let evedence6: any = document.getElementById("eve6");
    let evedence7: any = document.getElementById("eve7");
    let evedence8: any = document.getElementById("eve8");
    let evedence9: any = document.getElementById("eve9");
    let evedence10: any = document.getElementById("eve10");
    let evedence11: any = document.getElementById("eve11");
    let evedence12: any = document.getElementById("eve12");
    let evedence13: any = document.getElementById("eve13");
    let evedence14: any = document.getElementById("eve14");
    let evedence15: any = document.getElementById("eve15");
    let evedence16: any = document.getElementById("eve16");
    let evedence17: any = document.getElementById("eve17");
    let evedence18: any = document.getElementById("eve18");
    let evedence19: any = document.getElementById("eve19");
    let evedence20: any = document.getElementById("eve20");
    let evedence21: any = document.getElementById("eve21");
    let evedence22: any = document.getElementById("eve22");
    let evedence23: any = document.getElementById("eve23");
    let evedence24: any = document.getElementById("eve24");
    let evedence25: any = document.getElementById("eve25");
    let opportunities1: any = document.getElementById("opp1");
    let opportunities2: any = document.getElementById("opp2");
    let opportunities3: any = document.getElementById("opp3");
    let opportunities4: any = document.getElementById("opp4");
    let opportunities5: any = document.getElementById("opp5");
    let opportunities6: any = document.getElementById("opp6");
    let opportunities7: any = document.getElementById("opp7");
    let opportunities8: any = document.getElementById("opp8");
    let opportunities9: any = document.getElementById("opp9");
    let opportunities10: any = document.getElementById("opp10");
    let opportunities11: any = document.getElementById("opp11");
    let opportunities12: any = document.getElementById("opp12");
    let opportunities13: any = document.getElementById("opp13");
    let opportunities14: any = document.getElementById("opp14");
    let opportunities15: any = document.getElementById("opp15");
    let opportunities16: any = document.getElementById("opp16");
    let opportunities17: any = document.getElementById("opp17");
    let opportunities18: any = document.getElementById("opp18");
    let opportunities19: any = document.getElementById("opp19");
    let opportunities20: any = document.getElementById("opp20");
    let opportunities21: any = document.getElementById("opp21");
    let opportunities22: any = document.getElementById("opp22");
    let opportunities23: any = document.getElementById("opp23");
    let opportunities24: any = document.getElementById("opp24");
    let opportunities25: any = document.getElementById("opp25");

    args.data = {
      "1": row1 ? row1.value : "",
      "2": row2 ? row2.value : "",
      "3": row3 ? row3.value : "",
      "4": row4 ? row4.value : "",
      "5": row5 ? row5.value : "",
      "6": row6 ? row6.value : "",
      "7": row7 ? row7.value : "",
      "8": row8 ? row8.value : "",
      "9": row9 ? row9.value : "",
      "10": row10 ? row10.value : "",
      "11": row11 ? row11.value : "",
      "12": row12 ? row12.value : "",
      "13": row13 ? row13.value : "",
      "14": row14 ? row14.value : "",
      "15": row15 ? row15.value : "",
      "16": row16 ? row16.value : "",
      "17": row17 ? row17.value : "",
      "18": row18 ? row18.value : "",
      "19": row19 ? row19.value : "",
      "20": row20 ? row20.value : "",
      "21": row21 ? row21.value : "",
      "22": row22 ? row22.value : "",
      "23": row23 ? row23.value : "",
      "24": row24 ? row24.value : "",
      "25": evedence1 ? evedence1.value : "",
      "26": opportunities1 ? opportunities1.value : "",
      "27": evedence2 ? evedence2.value : "",
      "28": evedence3 ? evedence3.value : "",
      "29": evedence4 ? evedence4.value : "",
      "30": evedence5 ? evedence5.value : "",
      "31": evedence6 ? evedence6.value : "",
      "32": evedence7 ? evedence7.value : "",
      "33": evedence8 ? evedence8.value : "",
      "34": evedence9 ? evedence9.value : "",
      "35": evedence10 ? evedence10.value : "",
      "36": evedence11 ? evedence11.value : "",
      "37": evedence12 ? evedence12.value : "",
      "38": evedence13 ? evedence13.value : "",
      "39": evedence14 ? evedence14.value : "",
      "40": evedence15 ? evedence15.value : "",
      "41": evedence16 ? evedence16.value : "",
      "42": evedence17 ? evedence17.value : "",
      "43": evedence18 ? evedence18.value : "",
      "44": evedence19 ? evedence19.value : "",
      "45": evedence20 ? evedence20.value : "",
      "46": evedence21 ? evedence21.value : "",
      "47": evedence22 ? evedence22.value : "",
      "48": evedence23 ? evedence23.value : "",
      "49": evedence24 ? evedence24.value : "",
      "50": evedence25 ? evedence25.value : "",
      "51": opportunities2 ? opportunities2.value : "",
      "52": opportunities3 ? opportunities3.value : "",
      "53": opportunities4 ? opportunities4.value : "",
      "54": opportunities5 ? opportunities5.value : "",
      "55": opportunities6 ? opportunities6.value : "",
      "56": opportunities7 ? opportunities7.value : "",
      "57": opportunities8 ? opportunities8.value : "",
      "58": opportunities9 ? opportunities9.value : "",
      "59": opportunities10 ? opportunities10.value : "",
      "60": opportunities11 ? opportunities11.value : "",
      "61": opportunities12 ? opportunities12.value : "",
      "62": opportunities13 ? opportunities13.value : "",
      "63": opportunities14 ? opportunities14.value : "",
      "64": opportunities15 ? opportunities15.value : "",
      "65": opportunities16 ? opportunities16.value : "",
      "66": opportunities17 ? opportunities17.value : "",
      "67": opportunities18 ? opportunities18.value : "",
      "68": opportunities19 ? opportunities19.value : "",
      "69": opportunities20 ? opportunities20.value : "",
      "70": opportunities21 ? opportunities21.value : "",
      "71": opportunities22 ? opportunities22.value : "",
      "72": opportunities23 ? opportunities23.value : "",
      "73": opportunities24 ? opportunities24.value : "",
      "74": opportunities25 ? opportunities25.value : "",
    };
    return args;
  };

  const onReload = () => {
    getRows();
  };

  const onCloseForm = () => {
    setManageDialog(false);
  };

  let gridData = {};
  if (selectedRow.id) {
    gridData = JSON.parse(selectedRow.data);
  }

  console.log("formData:", formData);
  console.log("selectedRow", selectedRow.data);
  return (
    <React.Fragment>
      <div className="pageInfo">
        <ul className="breadcrumbs">
          <li>{LABELS.HOME}</li>
          <li className="dim">{" / "}</li>
          <li onClick={() => setDashMenu(LABELS.HOME)}>{LABELS.AUDITS}</li>
          <li className="dim">{" / "}</li>
          <li className="active">{LABELS.AUDIT}</li>
        </ul>
        <h4>{LABELS.AUDIT}</h4>
      </div>
      <div className="formGroup" style={{ width: "100%" }}>
        <label>{LABELS.CONTRACTOR_NAME}</label>
        <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
          <div style={{ width: "30%", marginRight: 10 }}>
            <AutoComplete
              id={"projects"}
              list={projects}
              childRef={projectsRef}
              defaultValue={
                selectedRow.id
                  ? {
                      id: selectedRow.id,
                      label: selectedRow.nameOfContractor,
                    }
                  : null
              }
            />
          </div>
          <Btn text={LABELS.LOAD_GRAPH} onClick={getProjectInfo} />
        </div>
      </div>

      {/* Graphs */}
      {pieChart.series && (
        <div className="graphs">
          <HGraph
            width={"50%"}
            type={"column"}
            xAxis={columnChart.xAxis}
            yAxis={columnChart.yAxis}
            title={" "}
            exportTitle={" "}
          />
          <HGraph
            width={"50%"}
            type={"pie"}
            float={"right"}
            series={pieChart.series}
            title={" "}
            exportTitle={" "}
          />
        </div>
      )}

      {/* Master Grid */}
      {rows ? (
        <ExcelGrid
          id={"audit2"}
          cols={cols}
          rows={rows}
          exportOption={true}
          newRowOption={false}
          exportName={LABELS.AUDIT}
          onReload={onReload}
          onAdd={onAdd}
        />
      ) : (
        <div className="pageCenter">
          <Loader />
        </div>
      )}

      {/* Form Dialog Box */}
      {manageDialog && (
        <UiDialog
          title={
            selectedRow.id
              ? "Edit " + LABELS.AUDIT + ": " + selectedRow.contractorName
              : "Add New " + LABELS.AUDIT
          }
          open={true}
          size={"xl"}
          onClose={() => {
            setManageDialog(false);
            setFormData({});
            setPageNo(1);
          }}
        >
          <>
            <div style={{ width: "100%", flexDirection: "column" }}>
              <table
                className="htmlExcel"
                width={"100%"}
                border={1}
                cellPadding={3}
                cellSpacing={0}
                style={{ border: " 1px solid  #e8e8e8" }}
              >
                <thead
                  style={{
                    background: "var(--primaryColor)",
                    color: " #fff",
                  }}
                >
                  <tr>
                    {cols2.map((col) => {
                      return <th>{col.name}</th>;
                    })}
                  </tr>
                </thead>
                <tbody style={{ border: " 1px solid  #e8e8e8" }}>
                  <tr style={{ border: " 1px solid  #e8e8e8" }}>
                    <td width={50}>{"4"}</td>
                    <td width={100}>{"Context of the Organization"}</td>
                    <td width={50}></td>
                    <td width={150}></td>
                    <td width={250}></td>
                    <td width={50}></td>
                    <td width={150}>
                      <textarea
                        id="eve1"
                        defaultValue={gridData["25"]}
                      ></textarea>
                    </td>
                    <td width={200}>
                      <textarea
                        id="opp1"
                        defaultValue={gridData["26"]}
                      ></textarea>
                    </td>
                  </tr>

                  <Row1 data={gridData} />
                  <Row2 data={gridData} />
                  <Row3 data={gridData} />
                </tbody>
              </table>

              {/* <Part1 childRef={part1Ref} editRows={editRows} /> */}

              {/* <PartLast
                childRef={part6Ref}
                selectedRow={selectedRow}
                uploads={uploads}
                setUploads={(val) => {
                  setUploads(val);
                }}
              /> */}

              <ModalBtmBtns
                onSave={manageAudit2}
                onCancel={onCloseForm}
                //disabled={editRows === null}
              />
            </div>
          </>
        </UiDialog>
      )}
    </React.Fragment>
  );
};

export default withSnackbar(Audit_2);
