import React, { useEffect, useRef, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { withSnackbar } from "notistack";
import { GetApp as IconExport } from "@mui/icons-material";
import DataGrid, {
  Column,
  Editing,
  Paging,
  Scrolling,
  GroupPanel,
  SearchPanel,
  Grouping,
  Toolbar,
  Item,
  Button,
  Lookup,
} from "devextreme-react/data-grid";
import Guid from "devextreme/core/guid";
import highcharts3d from "highcharts/highcharts-3d";

import Loader from "components/Custom/Loader";
import AutoComplete from "components/Custom/AutoComplete";
import Btn from "components/Custom/Btn";

import { COLORS, LABELS, NOTIFY, WARNINGS } from "common/config";
import { ApiDashboard } from "apihandlers/dashboard";
import { ApiHome } from "apihandlers/home";
import { COMMON_JS } from "common/scripts";
import { IconButton, Tooltip } from "@mui/material";

const API_HANDLER_DASHBOARD = new ApiDashboard();
const API_HANDLER_HOME = new ApiHome();
highcharts3d(Highcharts);

const Risks = (props: any) => {
  const [loading, setLoading] = useState(true);
  const [projects, setProjects] = useState([]);
  const [chartData, setChartData] = useState<any>({});

  const [rows, setRows] = useState<any>(null);
  const [newRowPosition, setNewRowPosition] = React.useState("viewportTop");
  const [changes, setChanges] = React.useState([]);
  const [editRowKey, setEditRowKey] = React.useState(null);
  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    if (loading) {
      getProjects();
    }
  }, [loading]);

  const getProjects = () => {
    const args = { dropdown: true };
    API_HANDLER_HOME.getProjects(args).then((res) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        setProjects(response.records);
      }
      setLoading(false);
    });
  };

  const loadChart = () => {
    const projectId = projectRef.current.selectedValue.id;
    if (!projectId) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.PROJECT_EMPTY);
      return;
    }
    const args = {
      projectId,
    };
    setChartData(null);
    API_HANDLER_DASHBOARD.getProjectProgressStatus(args).then((res) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        setTimeout(() => {
          setChartData(response);
        }, 2000);
      } else {
        setChartData({});
        COMMON_JS.showNotify(props, NOTIFY.ERROR, res.error);
      }
    });
  };

  const exportHighchart = () => {
    let projectName = "";
    if (projectRef.current) {
      projectName = projectRef.current.selectedValue.label;
    }
    let title =
      LABELS.PROJECT_PROGRESS_STATUS + " - " + projectName.toUpperCase();
    let header = "<html>";
    header +=
      "<head><title>" +
      title +
      "</title><style>@page{margin-top: 5;size: A3 landscape;}</style></head>";
    let body = "<body><h2 style='text-align: center;'>" + title + "</h2>";
    body += chartRef.current.container.current.innerHTML;
    body += "</html>";
    const html = header + body;
    const printWin = window.open("", "", "toolbar=0,scrollbars=0,status=0");
    printWin.document.write(html);
    printWin.document.close();
    printWin.focus();
    printWin.print();
    printWin.close();
  };

  const getRows = () => {
    const planDt = (document.getElementById("planDt") as HTMLInputElement)
      .value;
    setRows([]);
    // const args = {
    //   planDt,
    // };
    // API_HANDLER_HOME.getRows(args).then((res: any) => {
    //   const response: any = res.response;
    //   const { status } = res;
    //   if (status === 200) {
    //     setRows(response.records);
    //   }
    // });
  };

  const onAddButtonClick = React.useCallback((e: any) => {
    const key = new Guid().toString();
    setChanges([
      {
        key,
        type: "insert",
        insertAfterKey: e.row.key,
      },
    ]);
    setEditRowKey(key);
  }, []);

  const isAddButtonVisible = React.useCallback(({ row }) => !row.isEditing, []);

  const onRowInserted = React.useCallback((e: any) => {
    e.component.navigateToRow(e.key);
  }, []);

  const onSaving = React.useCallback(
    (e: any) => {
      e.cancel = true;

      console.log("onSaving: ", changes);

      let rowId = changes[0].key;
      let saveType = changes[0].type;
      let chng = changes;
      if (saveType === "update") {
        let changesRow = rows.filter((r: any) => {
          return r.ID === rowId;
        });
        if (changesRow.length > 0) {
          for (let key in changesRow[0]) {
            if (key !== "ID") {
              if (!(key in chng[0]["data"])) {
                chng[0]["data"][key] = changesRow[0][key];
              }
            }
          }
        }
      }

      console.log("onSaving: ", changes, rowId);

      let tmpRows = rows;
      let data = changes[0].data;
      if (rowId.indexOf("ID-") === -1) {
        // New Record
        let parentRowId = changes[0].insertAfterKey;
        if (!parentRowId) {
          parentRowId = changes[0].key;
        }
        // ReGenerate Rows
        let tRows = [];
        for (let indx in rows) {
          tRows.push(rows[indx]);
          if (tRows[indx]["ID"] === parentRowId) {
            if (saveType === "update") {
              for (let key in data) {
                tRows[indx][key] = data[key];
              }
            } else {
              // let empArr = employees.filter((e) => {
              //   return e.name === tRows[indx]["resource"];
              // });
              let row = {
                ID: rowId,
                // resource: empArr.length > 0 ? empArr[0].name : "",
              };
              for (let key in data) {
                row[key] = data[key];
              }
              tRows.push(row);
            }
          }
        }
        setRows(tRows);
      } else {
        // Existing Record Update
        for (let indx in rows) {
          if (tmpRows[indx]["ID"] === rowId) {
            for (let key in data) {
              tmpRows[indx][key] = data[key];
            }
            break;
          }
        }
        setRows(tmpRows);
      }
      // console.log("final: ", rows, tmpRows, rowId);
      setChanges([]);
      setEditRowKey(null);
      setHasChanges(true);
      // e.promise = saveChange(dispatch, e.changes[0]);
    },
    [changes, rows]
  );

  const saveRecords = () => {
    const args = {
      items: rows.filter((s) => {
        return s.skuOfferId;
      }),
    };
    console.log("manageScheme: ", args);
  };

  // const orgRef: any = useRef();
  const projectRef: any = useRef();
  let chartRef: any = useRef();
  const taskRef: any = useRef();
  const gridRef: any = useRef(null);
  let projectName = "";
  if (projectRef.current) {
    projectName = projectRef.current.selectedValue.label;
  }

  const chartTitle1 = "Hazards By Category";
  const chartTitle2 = "Risks";

  const options1 = {
    chart: {
      type: "column",
      panning: true,
      // width: window.innerWidth * 0.9,
      // height: 550,
      marginLeft: 60,
      // height: 1000,
      // width: 1410,
      lang: {
        decimalPoint: ".",
        thousandsSep: ",",
      },

      options3d: {
        enabled: true,
        alpha: 0,
        beta: 10,
        viewDistance: 25,
        depth: 60,
      },
    },
    credits: false,
    title: {
      align: "center",
      text: chartTitle1,
    },
    exporting: {
      filename: chartTitle2,
    },
    accessibility: {
      announceNewData: {
        enabled: true,
      },
    },
    xAxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    yAxis: {
      min: 0,
      title: {
        text: "Number of Reports",
      },
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      series: {
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          format: "{point.y:.0f}",
        },
      },
      column: {
        colorByPoint: true,
      },
    },
    // colors: ["#ff0000", "#00ff00", "#0000ff"],
    tooltip: {
      headerFormat:
        '<span style="font-size:11px;font-weight:bold;">{point.key}</span><br>',
      pointFormat: "Value: <b>{point.y:.1f}</b><br/>",
    },
    series: [
      {
        name: "Category",
        data: [49, 71, 106, 129, 144, 176, 135, 148, 216, 194, 95, 54],
        color: COLORS.PRIMARY,
      },
    ],
  };

  const options2 = {
    chart: {
      type: "pie",
      marginTop: 40,
      marginLeft: 85,
      options3d: {
        enabled: true,
        alpha: 45,
      },
    },
    credits: false,
    title: {
      text: chartTitle2,
    },
    exporting: {
      filename: chartTitle2,
    },
    tooltip: {
      pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    plotOptions: {
      pie: {
        innerSize: 100,
        depth: 45,
      },
    },
    series: [
      {
        innerSize: "30%",
        data: [
          { name: "Medium Risk", y: 13, color: "orange" },
          { name: "Low Risk", y: 37, color: "grey" },
          { name: "High Risk", y: 50, color: "#da012d" },
          // { name: "Green Slice", y: 20 },
        ],
      },
    ],
  };

  return (
    <React.Fragment>
      <div className="pageInfo">
        <ul className="breadcrumbs">
          <li>{LABELS.HOME}</li>
          <li className="dim">{" / "}</li>
          <li onClick={() => props.setDashMenu(LABELS.HOME)}>
            {LABELS.DASHBOARD}
          </li>
          <li className="dim">{" / "}</li>
          <li className="active">{"Risk Assessment"}</li>
        </ul>
        <h4>{"Risk Assessment"}</h4>
      </div>
      {loading ? (
        <div className="flexCenter" style={{ minHeight: 450 }}>
          <Loader />
        </div>
      ) : (
        <div
          className="hChart"
          style={{
            paddingBottom: 50,
          }}
        >
          {/* <div id="chartForm">
            <div className="formGroup">
              <label>{LABELS.PROJECT_NAME}</label>
              <AutoComplete
                id={"projects"}
                list={projects}
                childRef={projectRef}
                disabled={!chartData}
              />
            </div>

            <div
              className="formGroup"
              style={{
                display: "flex",
                marginTop: 15,
                marginBottom: 25,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Btn
                  text={LABELS.LOAD}
                  onClick={loadChart}
                  loading={!chartData}
                  marginRight={15}
                />
                {chartData && chartData.series1 && (
                  <Tooltip title={LABELS.EXPORT_TO_PDF}>
                    <IconButton
                      disabled={chartData === null}
                      onClick={exportHighchart}
                    >
                      <IconExport />
                    </IconButton>
                  </Tooltip>
                )}
              </div>
            </div>
          </div> */}

          {chartData ? (
            <div
              style={{
                width: "98%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              {/* <div
                style={{
                  width: "98%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ width: "49%" }}>
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={options1}
                    ref={chartRef}
                  />
                </div>
                <div style={{ width: "49%" }}>
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={options2}
                    ref={chartRef}
                  />
                </div>
              </div> */}

              <div
                style={{
                  width: "98%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  {/* <div style={{ width: "30%" }}>
                    <div className="formGroup">
                      <label>{LABELS.PROJECT_NAME}</label>
                      <AutoComplete
                        id={"projects"}
                        list={projects}
                        childRef={projectRef}
                      />
                    </div>
                    <div className="formGroup">
                      <Btn
                        text={LABELS.LOAD}
                        onClick={getRows}
                        loading={loading}
                      />
                    </div>
                  </div> */}

                  {rows && (
                    <div
                      style={{
                        flexDirection: "column",
                        width: "100%",
                        marginTop: 15,
                      }}
                    >
                      <DataGrid
                        id="gridContainer"
                        className="resourcePlanning"
                        style={{
                          overflow: "auto",
                        }}
                        ref={gridRef}
                        dataSource={rows}
                        keyExpr="ID"
                        showBorders={true}
                        allowColumnResizing={true}
                        columnMinWidth={150}
                        columnAutoWidth={true}
                        onRowInserted={onRowInserted}
                        onSaving={onSaving}
                      >
                        <Scrolling columnRenderingMode="virtual" />
                        <Paging enabled={false} />
                        <Editing
                          mode="row"
                          allowAdding={true}
                          allowDeleting={true}
                          allowUpdating={true}
                          confirmDelete={true}
                          useIcons={true}
                          newRowPosition={newRowPosition}
                          changes={changes}
                          onChangesChange={setChanges}
                          editRowKey={editRowKey}
                          onEditRowKeyChange={setEditRowKey}
                        />
                        <Column
                          type="buttons"
                          caption="Options"
                          alignment={"left"}
                        >
                          <Button
                            icon="add"
                            onClick={onAddButtonClick}
                            visible={isAddButtonVisible}
                          />
                          <Button name="edit" />
                          {/* <Button name="delete" /> */}
                          <Button name="save" />
                          <Button name="cancel" />
                        </Column>
                        {/* <Column
                          dataField="slNo"
                          caption="Item No."
                          minWidth={80}
                          width={80}
                        /> */}
                        <Column
                          dataField="classifications"
                          caption="Hazard Classification"
                          width={200}
                        >
                          <Lookup
                            dataSource={[
                              {
                                id: "Fire",
                                name: "Fire",
                              },
                              {
                                id: "Falling",
                                name: "Falling",
                              },
                              {
                                id: "Chemical Sustance",
                                name: "Chemical Sustance",
                              },
                              {
                                id: "Electricity",
                                name: "Electricity",
                              },
                              {
                                id: "Noise",
                                name: "Noise",
                              },
                              {
                                id: "Chemical Sustance",
                                name: "Chemical Sustance",
                              },
                              {
                                id: "Electricity",
                                name: "Electricity",
                              },
                              {
                                id: "Noise",
                                name: "Noise",
                              },
                            ]}
                            displayExpr="name"
                            valueExpr="id"
                          />
                        </Column>
                        <Column
                          dataField="category"
                          caption={"Hazard Category"}
                          dataType="text"
                          minWidth={250}
                          width={250}
                        />
                        <Column
                          dataField="cause"
                          caption={"Cause/Description"}
                          dataType="text"
                          minWidth={400}
                          width={400}
                        />
                        <Column
                          dataField="cons"
                          caption={"Consequence(s)"}
                          dataType="text"
                          minWidth={400}
                          width={400}
                        />
                        <Column
                          dataField="peopleAtRisk"
                          caption={"People at Risk"}
                          dataType="text"
                          minWidth={200}
                          width={200}
                        />
                        <Column
                          dataField="currentControlMeasure"
                          caption={"Current Control Measure"}
                          dataType="text"
                          minWidth={450}
                          width={450}
                        />
                        <Column
                          dataField="currentRisk"
                          caption={"Current Risk"}
                          dataType="text"
                          minWidth={200}
                          width={200}
                        />
                        <Column
                          dataField="additionalControlMeasure"
                          caption={"Additional Control Measure"}
                          dataType="text"
                          minWidth={450}
                          width={450}
                        />
                        <Column
                          dataField="parentalResponsible"
                          caption={"Parental Responsible"}
                          dataType="text"
                          minWidth={200}
                          width={200}
                        />
                        <Column
                          dataField="residualRisk"
                          caption={"Residual Risk"}
                          dataType="text"
                          minWidth={200}
                          width={200}
                        />
                        <Column
                          dataField="dateOfIdentification"
                          caption={"Date of Identification"}
                          dataType="date"
                          minWidth={200}
                          width={200}
                          format={"dd/MM/yyyy"}
                        />
                        <Column
                          dataField="riskLevel"
                          caption={"Risk Level"}
                          dataType="text"
                          minWidth={150}
                          width={150}
                        />
                        <Column
                          dataField="attachement"
                          caption={"Attachement"}
                          dataType="text"
                          minWidth={150}
                          width={150}
                        />
                        <GroupPanel visible={true} />
                        <SearchPanel visible={true} />
                        <Grouping autoExpandAll={true} />
                      </DataGrid>

                      <div
                        className="formGroup"
                        style={{
                          marginTop: 15,
                        }}
                      >
                        {hasChanges && (
                          <Btn
                            text={LABELS.SAVE}
                            onClick={saveRecords}
                            loading={loading}
                          />
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div
              className="flexCenter"
              style={{ minHeight: 400, width: "100%" }}
            >
              <Loader />
            </div>
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default withSnackbar(Risks);
